import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as $ from 'jquery';
import { MatSort } from '@angular/material/sort';
import { TooltipService } from '@_services/tooltip.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
export class Group {
    level = 0;
    expanded = false;
    totalCounts = 0;
}
@Component({
    selector: 'app-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoiceDetailComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    invoiceDetailForm: FormGroup;
    invoiceDatasor: any;
    IntersetChatgesData: any;
    ReceiptsData: any;
    isspiner: boolean;
    isLoadingResults: boolean;
    testBool: boolean = false;
    toolTipList: any;
    // displayedColumnsTime: string[] = ['ITEMDATE', 'FEEEARNER', 'ADDITIONALTEXT', 'PRICE', 'GST'];
    //  displayedColumnsTime: string[] = ['ITEMDATE', 'FEEEARNER', 'ADDITIONALTEXT', 'PRICEINCGST', 'PRICE', 'GST'];
    displayedColumnsRecipt: string[] = ['RECEIPTCODE', 'RECEIPTDATE', 'Type', 'AMOUNT'];
    displayedColumnsInterest: string[] = ['INVOICECODE', 'INVOICEDATE', 'INVOICETOTAL', 'AMOUNTOUTSTANDINGEXGST', 'COMMENT'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatPaginator, { static: false }) paginator1: MatPaginator;
    @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatSort, { static: false }) sort1: MatSort;
    @ViewChild(MatSort, { static: false }) sort2: MatSort;
    isView: any;
    isDisable: boolean = false;
    tabingVal: string;
    displayedColumns: any;
    highlightedRows: any;
    theme_type = localStorage.getItem('theme_type');
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    allData: any = [];
    _allGroup: any[];
    expandedSubCar: any[] = [];
    tempTotalData = {};
    expandedCar: any[] = [];
    groupByColumns = ['ITEMTYPEDESC'];
    tootipData: any = {};
    displayedColumnsTime: string[]
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    sortactive: any;
    sortDirection: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<InvoiceDetailComponent>,
        public datepipe: DatePipe,
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public tooltipService: TooltipService,
    ) {
        localStorage.setItem('istrackid', 'Invoice_InvoiceDetailComponent');
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'Invoice_InvoiceDetailComponent' && result['click'] && (result['data']['invoice'] || result['data']['workitem'] || result['data']['receipt-allocation'])) {
                this.ngOnInit();
                // result['click']=false;
            }
            if (localStorage.getItem('istrackid') == 'Invoice_InvoiceDetailComponet' && result['click'] && result['data']['SetInvoice']) {
                this.updateInvoice();
                // result['click']=false;
            }

        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.sortactive = "ITEMDATE";
        this.sortDirection = "asc";
        if (this.currentUser.PRODUCTTYPE == "Solicitor") {
            this.displayedColumnsTime = ['ITEMDATE', 'FEEEARNER', 'ADDITIONALTEXT', 'PRICEINCGST', 'PRICE', 'GST'];
        } else {
            this.displayedColumnsTime = ['ITEMDATE', 'ADDITIONALTEXT', 'PRICEINCGST', 'PRICE', 'GST'];
        }
        this.isView = this._data.type;
        this.isDisable = this._data.type == 'pre_view' ? true : false;
        this.invoiceDetailForm = this._formBuilder.group({
            CLIENTNAME: [''],
            SHORTNAME: [''],
            MATTERGUID: [''],
            INVOICEGUID: [''],
            INVOICECODE: [''],
            INVOICEDATE: [''],
            INVOICEDATETEXT: [],
            DUEDATE: [''],
            DUEDATETEXT: [''],
            COMMENT: [''],
            GST: [''],
            INVOICETOTAL: [''],
            AMOUNTOUTSTANDINGINCGST: [''],
            AMOUNTTOTAL: [''],
        });
        this.TabingClick('Time Entries');
        if (this._data.type == 'edit' || this._data.type == 'view') {
            this.isLoadingResults = true;
            this._mainAPiServiceService.getSetData({ "Action": "GetData", Filters: { 'INVOICEGUID': this._data.INVOICEGUID } }, 'invoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
                    let invoiceData = response.DATA.RECORDS[0];
                    // this.tootipData =  response.DATA.FIELDTIPS;
                    if(invoiceData) {
                        this.invoiceDetailForm.controls['CLIENTNAME'].setValue(invoiceData?.CLIENTNAME);
                        this.invoiceDetailForm.controls['SHORTNAME'].setValue(invoiceData?.SHORTNAME);
                        this.invoiceDetailForm.controls['MATTERGUID'].setValue(invoiceData?.MATTERGUID);
                        this.invoiceDetailForm.controls['INVOICEGUID'].setValue(invoiceData?.INVOICEGUID);
                        let temInvoice = invoiceData?.INVOICECODE;
                        if(temInvoice){
                            this.invoiceDetailForm.controls['INVOICECODE'].setValue(temInvoice.toString().padStart(8, "0"));
                        }
                        if (invoiceData?.INVOICEDATE) {
                            this.invoiceDetailForm.controls['INVOICEDATE'].setValue(invoiceData?.INVOICEDATE);
                            let INVOICEDATET = invoiceData?.INVOICEDATE.split("/");
                            this.invoiceDetailForm.controls['INVOICEDATETEXT'].setValue(new Date(INVOICEDATET[1] + '/' + INVOICEDATET[0] + '/' + INVOICEDATET[2]));
                        }
                        if (invoiceData?.DUEDATE) {
                            this.invoiceDetailForm.controls['DUEDATE'].setValue(invoiceData?.DUEDATE);
                            let DUEDATE1 = invoiceData?.DUEDATE.split("/");
                            this.invoiceDetailForm.controls['DUEDATETEXT'].setValue(new Date(DUEDATE1[1] + '/' + DUEDATE1[0] + '/' + DUEDATE1[2]));
                        }
                        this.invoiceDetailForm.controls['COMMENT'].setValue(invoiceData?.COMMENT);
                        this.invoiceDetailForm.controls['GST'].setValue(invoiceData?.GST);
                        this.invoiceDetailForm.controls['INVOICETOTAL'].setValue(invoiceData?.INVOICETOTAL);
                        this.invoiceDetailForm.controls['AMOUNTOUTSTANDINGINCGST'].setValue(invoiceData?.AMOUNTOUTSTANDINGINCGST);
                        let FinalTotal = Number(invoiceData?.INVOICETOTALINCGST);
                        this.invoiceDetailForm.controls['AMOUNTTOTAL'].setValue(FinalTotal.toFixed(2));
                        // get time entry data for specifc invoice

                        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": { 'INVOICEGUID': this._data.INVOICEGUID } }, 'workitem').subscribe(response => {
                            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                this.invoiceDatasor = new MatTableDataSource();
                                this.invoiceDatasor.paginator = this.paginator;
                                this.invoiceDatasor.sort = this.sort;
                                let tempData = [];
                                response.DATA.WORKITEMS.forEach(group => {
                                    if (!group.ISGROUPBY) {
                                        tempData.push(group);
                                    } else {
                                        this.tempTotalData[group.ITEMTYPEDESC] = group;
                                    }
                                });
                                this.allData = tempData;
                                this.invoiceDatasor.data = this.getGroups(this.allData, this.groupByColumns);
                                if (this.invoiceDatasor.data[0]) {
                                    this.groupHeaderClick(this.invoiceDatasor.data[0]);
                                    let shortingData: any = { active: this.sortactive, direction: this.sortDirection };
                                    this.sortingCLM(shortingData);
                                }
                            } else if (response.MESSAGE == 'Not logged in') {
                                this.dialogRef.close(false);
                            }
                        }, error => {
                            this.toastr.error(error);
                        });
                        // get Receipts data.
                        this._mainAPiServiceService.getSetData({Action: "GetData", Filters:{ 'INVOICEGUID': this._data.INVOICEGUID }}, 'receipt-allocation').subscribe(ReceiptAllocationData => {
                            if (ReceiptAllocationData.CODE == 200 && ReceiptAllocationData.STATUS == "success") {
                                this.ReceiptsData = new MatTableDataSource(ReceiptAllocationData.DATA.RECORDS);
                                this.ReceiptsData.paginator = this.paginator1;
                                this.ReceiptsData.sort = this.sort1;
                            } else if (response.MESSAGE == 'Not logged in') {
                                this.dialogRef.close(false);
                            }
                        }, error => {
                            this.toastr.error(error);
                        });
                        // get Interest Charges data.

                        this._mainAPiServiceService.getSetData({ "Action": "GetData", Filters: { 'ParentInvoiceGuid': this._data.INVOICEGUID } }, 'invoice').subscribe(response => {
                            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                this.IntersetChatgesData = new MatTableDataSource(response.DATA.RECORDS);
                                this.IntersetChatgesData.paginator = this.paginator2;
                                this.IntersetChatgesData.sort = this.sort2;
                            } else if (response.MESSAGE == 'Not logged in') {
                                this.dialogRef.close(false);
                            }
                        }, error => {
                            this.toastr.error(error);
                        });
                    }
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, error => {
                this.toastr.error(error);
            });
        }
    }

    /**
     * This function is used to set the tool tip data value
     */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['Invoice']) {
            this.toolTipList = TooltipData['Invoice'];
        } else {
            this.tooltipService.setToolTipData = ('Invoice');
        }
    };

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to Toggle for the update the data value
     */
    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };
    //grouping coding start
    getGroups(data: any[], groupByColumns: string[]): any[] {
        return this.getGroupList(data, 0, groupByColumns);
    }

    /**
     * THis function is used to get the group list
     */
    getGroupList(data: any[], level: number = 0, groupByColumns: string[]): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        let groups = this.uniqueBy(data.map(row => {
            const result = new Group();
            result.level = level + 1;
            for (let i = 0; i <= level; i++) {
                result[groupByColumns[i]] = row[groupByColumns[i]];
            }
            return result;
        }), JSON.stringify);
        const currentColumn = groupByColumns[level];
        let subGroups = [];
        groups.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            group.totalCounts = rowsInGroup.length;
            this.expandedSubCar = [];
        });
        this._allGroup = groups;
        return groups;
    }

    /**
     * This function is used to sort the CLM
     */
    sortingCLM(sort: MatSort) {
        let data = this.allData;
        const index = data.findIndex(x => x['level'] == 1);
        if (sort.active && sort.direction !== '') {
            if (index > -1) {
                data.splice(index, 1);
            }
            data = data.sort((a: any, b: any) => {
                let propertyA: number | Date | string = '';
                let propertyB: number | Date | string = '';
                let FildeValue = ['ITEMDATE'];
                if (sort.active) {
                    if (FildeValue.includes(sort.active)) {
                        let tempDate = a[sort.active].split("/");
                        let tempDateb = b[sort.active].split("/");
                        [propertyA, propertyB] = [new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]), new Date(tempDateb[1] + '/' + tempDateb[0] + '/' + tempDateb[2])];
                        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                        return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
                    } else {
                        [propertyA, propertyB] = [a[sort.active], b[sort.active]];
                        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                        return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
                    }
                } else {
                    return 0;
                }

            });
        }


        this.invoiceDatasor = this.addGroupsNew(this._allGroup, data, this.groupByColumns, this.expandedCar);
    }

/**
 * This function is used to add the new Group 
 */
    addGroupsNew(allGroup: any[], data: any[], groupByColumns: string[], dataRow: any): any[] {
        const rootGroup = new Group();
        rootGroup.expanded = true;
        return this.getSublevelNew(allGroup, data, 0, groupByColumns, rootGroup, dataRow);
    }

    /**
     * This function is used to isGroup set
     */
    isGroup(index, item): boolean {
        return item.level;
    }

    /**
     * This function is used to get the Sublevel New
     */
    getSublevelNew(allGroup: any[], data: any[], level: number, groupByColumns: string[], parent: Group, dataRow: any): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        const currentColumn = groupByColumns[level];
        let subGroups = [];
        allGroup.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            group.totalCounts = rowsInGroup.length;
            // if (group.ITEMTYPEDESC == dataRow.ITEMTYPEDESC.toString()) {
            //   group.expanded = dataRow.expanded;
            const subGroup = this.getSublevelNew(allGroup, rowsInGroup, level + 1, groupByColumns, group, group.ITEMTYPEDESC.toString());
            this.expandedSubCar = subGroup;
            subGroup.unshift(group);
            subGroups = subGroups.concat(subGroup);
            // } else {
            //   subGroups = subGroups.concat(group);
            //
            // }
        });
        return subGroups;
    }

    /**
     * This function is used to click on the group header
     */
    groupHeaderClick(row) {
        if (row.expanded) {
            row.expanded = false;
            this.invoiceDatasor.data = this.getGroups(this.allData, this.groupByColumns);
        } else {
            row.expanded = true;
            this.expandedCar = row;
            this.invoiceDatasor.data = this.addGroupsNew(this._allGroup, this.allData, this.groupByColumns, row);
        }
    }

    /**
     * This function is used to set the unique by
     */
    uniqueBy(a, key) {
        const seen = {};
        return a.filter((item) => {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    /**
     * This function is used to choosed Invoice the date.
     */
    choosedInvoiceDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.invoiceDetailForm.controls['INVOICEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to choosed the Due Date.
     */
    choosedDueDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.invoiceDetailForm.controls['DUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    get f() {
        return this.invoiceDetailForm.controls;
    }

    /**
     * This function is used to update the Invoice
     */
    updateInvoice() {
        this.isspiner = true;
        let PostData: any = {
            "INVOICEGUID": this.f.INVOICEGUID.value,
            "COMMENT": this.f.COMMENT.value,
            "DUEDATE": this.f.DUEDATE.value,
        }
        let PostInvoiceEntryData: any = { FormAction: 'update', VALIDATEONLY: true, Data: PostData };
        this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }

        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check the validation data.
     */
    checkValidation(bodyData: any, PostInvoiceEntryData: any)  {
        let errorData: any = [];
        let warningData: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'NO') {
                errorData.push(value.ERRORDESCRIPTION);
            } else if (value.VALUEVALID == 'WARNING') {
                warningData.push(value.ERRORDESCRIPTION);
            }
        });

        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.isspiner = false;
        } else if (Object.keys(warningData).length != 0) {
            this.isspiner = false;
            this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isspiner = true;
                    this.saveInvoice(PostInvoiceEntryData);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.saveInvoice(PostInvoiceEntryData);
        }
    }

    /**
     * This function is used to set the invoice
     */
    saveInvoice(PostInvoiceEntryData: any) {
        PostInvoiceEntryData.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                $('#refreshInvoiceTab').click();
                $('#refreshWorkInprogress').click();
                $("#getmatterinvoice").click();
                this.toastr.success('Update Success');
                this.dialogRef.close(true);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.isspiner = false;
                this.toastr.warning(res.MESSAGE);
            } else if ((res.CODE == 450 || res.CODE == 410) && res.STATUS == 'error') {
                this.isspiner = false;
                this.toastr.error(res.MESSAGE);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to click on the tabing click
     */
    TabingClick(val) {
        if (val == 'Time Entries') {
            this.tabingVal = 'Time Entries';
        } else if (val == 'Receipts') {
            this.tabingVal = 'Receipts';
        } else {
            this.tabingVal = 'InterChange';
        }
    }
    RowClick(row) {
    }

    /**
     * This function is used change the total.
     */
    totalchange() {
        let ExGst = Number(this.f.INVOICETOTAL.value) / 1.1;
        let Gst = Number(this.f.INVOICETOTAL.value) - Number(ExGst);
        let InGst = Number(this.f.INVOICETOTAL.value) - Number(Gst);
        this.invoiceDetailForm.controls['INVOICETOTAL'].setValue(InGst);

    }
}
