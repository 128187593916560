import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { DropDownTreeComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import * as $ from 'jquery';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';

@Component({
  selector: 'app-document-dailog',
  templateUrl: './document-dailog.component.html',
  styleUrls: ['./document-dailog.component.scss']
})
export class DocumentDailogComponent implements OnInit, OnDestroy {
  @ViewChild('uploader', { static: false }) uploader:ElementRef;
  isLoadingResults: boolean = false;
  action: string;
  DocRegData: any;
  fileToUpload: any = [];
  errorWarningData: any = {};
  SendDataArray: any = {};
  dialogTitle: string;
  generatDate: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  userType: any = JSON.parse(localStorage.getItem('currentUser'));
  isspiner: boolean = false;
  public DocumentRegiData: any = {
    "GENERATEDATE": "", "GENERATETIME": "", "DOCUMENTCLASS": 0, "DESCRIPTION": "", "DRAFTSTATUS": 0, "DOCUMENTNUMBER": 0, "DOCUMENTTYPE": 0, "DOCUMENTNAME": '',
    "KEYWORDS": '', "GENERATEDATESEND": " ", "DOCUMENTAUTHOR": "", "DOCUMENTRECIPIENTS": ""
  };
  FormAction: string;
  DocGUID: string;
  startingTime: any = '00:00';
  timeslot: any = [];
  is_inboxAttech: boolean = false;
  FolderData: any = [];
  fields: any;
  selectedFolder: any = '';
  @ViewChild("folderlistdoc", { static: false }) dropdownTree: DropDownTreeComponent;
  treeSettings: any = { loadOnDemand: true };
  FolderListArray: any = [];
  expandexNodelist: any = [];
  windowNameId:any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub1: Subscription;
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<DocumentDailogComponent>,
    private router: Router,
    private _formBuilder: FormBuilder,
    private behaviorService: BehaviorService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public _matDialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService
  ) {
    localStorage.setItem('istrackid', 'DocumentRegisterDialogComponent');
    this.windowNameId  = window.name.split("_").pop();
    this.action = data.action;
    if (this.action === 'new') {
      this.dialogTitle = 'New Document';
      if (data.is_inboxAttech) {
        this.is_inboxAttech = data.is_inboxAttech;
        this.LoadDataFolderList({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
      }
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Document';
    } else {
      this.dialogTitle = 'Duplicate Document';
    }
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.DocumentRegisterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.DocRegData = result;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'DocumentRegisterDialogComponent' && result['click'] && result['data']['document']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'DocumentRegisterDialogComponent' && result['click'] && result['data']['document']) {
        this.DocumentSave();
      }
    });
  }

  /**
   * This function is used to create the dropdown tree
   */
  created($event: any) {
    (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);
  }

  /**
   * This function is used to triger the folder list
   */
  TriggerFolderList(arges) {
    if (!this.expandexNodelist.includes(arges.nodeData.id) && arges.nodeData.id != "<MatterFolder>") {
      this.expandexNodelist.push(arges.nodeData.id);
      this.LoadDataFolderList({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: arges.nodeData.id }, 'subFolderLevel');
    }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let begin = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
    if (this.action == 'edit' || this.action == 'duplicate') {
      this.LoadData();
      if (this.action == 'duplicate') {
        this.DocumentRegiData.DOCUMENTAUTHOR = this.userType.UserName;
      }
    } else {
      this.DocumentRegiData.DOCUMENTAUTHOR = this.userType.UserName;
      this.DocumentRegiData.GENERATEDATESEND = begin;
    }
   
    // time slot
    this.timeslot.push(this.startingTime);
    for (var i = 0; i < 47; i++) {     //47=(24*60/30)-1
      this.startingTime = this.addMinutes(this.startingTime, '30');   // 30=add 30 min
      this.timeslot.push(this.startingTime);
    }
   
    // load default fields in new form
    if (this.action == 'new') {
      this.getDocumentDefaultValues()
    }
    // ends here ~

  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub1?.unsubscribe();
  }


  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit() {
    if (this.is_inboxAttech) {
      $('.ejs-dropdown-folder').css({ "display": "block" });
    }
    let inputElement: HTMLElement = this.uploader.nativeElement as HTMLElement;
    if(this.action != 'edit'){
      inputElement.click();
    }
  }

  addMinutes(time, minsToAdd) { 
    function D(J) { return (J < 10 ? '0' : '') + J; };
    var piece = time.split(':');
    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
    return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
  }


  /**
   * This function is used to convert the 24 hours format data value.
   */
  convertTo24HourFormat(time12Hour) {
    const timeComponents = time12Hour.split(":");
  let hours = parseInt(timeComponents[0]);
  const minutes = timeComponents[1].slice(0, 2); // Remove 'pm' or 'am'

  if (time12Hour.includes("PM") && hours !== 12) {
    hours += 12;
  } else if (time12Hour.includes("AM") && hours === 12) {
    hours = 0;
  }
  const hoursFormatted = hours.toString().padStart(2, "0");
  return `${hoursFormatted}:${minutes}`;
  }
 
  /**
   * This function is used to load the data value.
   */
  LoadData() {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{ DOCUMENTGUID: this.DocRegData.DOCUMENTGUID }}, 'document').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.SendDataArray = res.DATA.RECORDS[0];
        if (this.SendDataArray.GENERATEDATE) {
          let DATE1 = this.SendDataArray.GENERATEDATE.split("/");
          this.DocumentRegiData.GENERATEDATE = this.SendDataArray.GENERATEDATE;
          this.DocumentRegiData.GENERATEDATETEXT = new Date(DATE1[1] + '/' + DATE1[0] + '/' + DATE1[2]);
        }
        this.generatDate = this.SendDataArray.GENERATEDATE;
        if (this.SendDataArray.GENERATETIME) {
          this.DocumentRegiData.GENERATETIME = this.convertTo24HourFormat(this.SendDataArray.GENERATETIME.trim());
        }
        // this.DocumentRegiData.GENERATETIME = this.SendDataArray.GENERATETIME;
        this.DocumentRegiData.DOCUMENTCLASS = this.SendDataArray.DOCUMENTCLASS;
        this.DocumentRegiData.DESCRIPTION = this.SendDataArray.DESCRIPTION;
        this.DocumentRegiData.DRAFTSTATUS = this.SendDataArray.DRAFTSTATUS;
        this.DocumentRegiData.DOCUMENTNUMBER = this.SendDataArray.DOCUMENTNUMBER;
        this.DocumentRegiData.DOCUMENTTYPE = this.SendDataArray.DOCUMENTTYPE;
        this.DocumentRegiData.DOCUMENTNAME = this.SendDataArray.DOCUMENTNAME;
        this.DocumentRegiData.KEYWORDS = this.SendDataArray.KEYWORDS;
        this.DocumentRegiData.GENERATEDATESEND = this.SendDataArray.GENERATEDATESEND;
        this.DocumentRegiData.DOCUMENTAUTHOR = this.SendDataArray.DOCUMENTAUTHOR;
        this.DocumentRegiData.DOCUMENTRECIPIENTS = this.SendDataArray.DOCUMENTRECIPIENTS;
      }
      this.isLoadingResults = false;
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }

  /**
   * This function is used to choosed the date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    // this.DocumentRegiData.GENERATEDATE = begin;
    this.generatDate = begin;

  }

  /**
   * This function is used to save the Document
   */
  DocumentSave() {
    this.isspiner = true;
    const materIDofTab = window.name.split("_");
    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
    let MatterData = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
    if(!MatterData){
      MatterData = JSON.parse(localStorage.getItem('set_active_matters'));
    }
    if (this.action == "edit") {
      this.FormAction = "update";
      this.DocGUID = this.SendDataArray.DOCUMENTGUID;
    } else if (this.action == 'new' || this.action == 'duplicate') {
      this.FormAction = "insert";
      this.DocGUID = "";
    }
    let Data = {
      CONTEXT: "Matter",
      CONTEXTGUID: this.SendDataArray.CONTEXTGUID,
      DESCRIPTION: this.DocumentRegiData.DESCRIPTION,
      DOCUMENTAUTHOR: this.DocumentRegiData.DOCUMENTAUTHOR,
      DOCUMENTCLASS: this.DocumentRegiData.DOCUMENTCLASS,
      DOCUMENTGUID: this.DocGUID,
      DOCUMENTNAME: this.DocumentRegiData.DOCUMENTNAME,
      DOCUMENTNUMBER: this.DocumentRegiData.DOCUMENTNUMBER,
      DOCUMENTRECIPIENTS: this.DocumentRegiData.DOCUMENTRECIPIENTS,
      DOCUMENTTYPE: this.DocumentRegiData.DOCUMENTTYPE,
      DRAFTSTATUS: this.DocumentRegiData.DRAFTSTATUS,
      GENERATEDATE: this.generatDate,
      GENERATETIME: this.DocumentRegiData.GENERATETIME,
      KEYWORDS: this.DocumentRegiData.KEYWORDS,
      MATTERGUID: MatterData.MATTERGUID,
      TEMPLATENAME: this.SendDataArray.TEMPLATENAME,
      FILEDATA: this.fileToUpload[0],
    }
    if (this.is_inboxAttech) {
      Data['FOLDERNAME'] = this.selectedFolder ? this.selectedFolder.id : "";
    } else {
      Data['FOLDERNAME'] = localStorage.getItem('sendSaveFolder') ? localStorage.getItem('sendSaveFolder') : "";
    }
    let finalData = { DATA: Data, FormAction: this.FormAction, VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'document').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to check the Validation data
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }

    // });
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.DocRegiData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.DocRegiData(details);
    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.DocRegiData(details);
        }
      }
    });
  }

  /**
   * This function is used to register the Document data
   */
  DocRegiData(data: any) {
    data.VALIDATEONLY = false;
    this.sub1 = this._mainAPiServiceService.getSetData(data, 'document').subscribe(response => {
      if ((response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) || response.CODE == 0) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        if (this.is_inboxAttech) {
          response.DATA.DOCUMENTNAME = data.DATA.DOCUMENTNAME;
          this.dialogRef.close(response.DATA);
        } else {
          this.dialogRef.close(true);
        }

      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, error => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to select the Document for upload perpose
   */
  selectDocUpload(fileInput) {
    let file64bit = [];
    let filenames = [];
    let files = fileInput.target.files;
    console.log(files);
    for (let i = 0; i < files.length; i++) {
      filenames.push(files[i].name);
      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        file64bit.push(reader.result);
      };
      reader.onerror = function (error) {
      };
    }
    this.DocumentRegiData.DOCUMENTNAME = (filenames.join(', '));
    this.fileToUpload = file64bit;
  }
  
  /**
   * This function is used to Close the Document
   */
  CloseDocument(): void {
    this.dialogRef.close(false);
  }

  /**
   * This function is used to load the Data folder list
   */
  LoadDataFolderList(postdata, level) {
    const materIDofTab = window.name.split("_");
    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
    // if (this.FolderData.length == 0) {
      // spiner commemnt becs of client need to open window immediately.
    this.isLoadingResults = true;
    
    let MatterData = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
    if(!MatterData){
      MatterData = JSON.parse(localStorage.getItem('set_active_matters'));
    }
    postdata.MATTERGUID = MatterData.MATTERGUID;
    let Data=postdata;
    this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.FolderData = res.DATA.FOLDERS;
        // this.fields = { dataSource: this.FolderData, value: 'FULLPATH', text: 'FOLDERNAME', child: 'FOLDERS' };

        if (level == 'rootLevel') {
          this.FolderData.forEach(e => {
            e.hasChild = true;
            e.expanded = false;
            // let tempData = e;

            this.FolderListArray.push(e);
            // tempData.FOLDERS.forEach(element => {
            //   element.hasChild = true;
            //   element.expanded = false;
            //   element.pid = tempData.FULLPATH;
            //   this.FolderListArray.push(element);
            // });
          });
          this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
          // this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
        } else {
          this.FolderData.forEach(element => {
            element.hasChild = true;
            element.expanded = false;
            element.pid = postdata.FOLDERNAME;
            this.FolderListArray.push(element);
          });

          this.expandexNodelist = this.expandexNodelist.filter((element, i) => i === this.expandexNodelist.indexOf(element));
          this.FolderListArray.forEach(item => {
            if (this.expandexNodelist.includes(item.FULLPATH) || item.FULLPATH == "<MatterFolder>") {
              item.expanded = true;
              item.hasChild = true;
            } else {
              item.hasChild = true;
              item.expanded = false;
            }
          });
          this.dropdownTree.refresh();
          (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);

          this.dropdownTree.showPopup();
          this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
        }

        this.isLoadingResults = false;
      } else {
        this.isLoadingResults = false;
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
    // } else {
    //   this.fields = { dataSource: this.FolderData, value: 'FULLPATH', text: 'FOLDERNAME', child: 'FOLDERS' };
    // }
  }

  /**
   * This function is used to change the folder structure
   */
  FloderChnage($event: SelectEventArgs) {
    this.selectedFolder = $event.itemData;
  }

 /**
  * This function is used to get default data list 
  */
  getDocumentDefaultValues() {
    // let finalData = { DATA: {}, Action: "Default", VALIDATEONLY: false }
     let finalData = { DATA: {}, Action: "Default" }

    this._mainAPiServiceService.getSetData(finalData, 'document').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
      this.SendDataArray = response.DATA.DEFAULTVALUES;
        if (this.SendDataArray.GENERATEDATE) {
          let DATE1 = this.SendDataArray.GENERATEDATE.split("/");
          this.DocumentRegiData.GENERATEDATE = this.SendDataArray.GENERATEDATE;
          this.DocumentRegiData.GENERATEDATETEXT = new Date(DATE1[1] + '/' + DATE1[0] + '/' + DATE1[2]);
        }
        this.generatDate = this.SendDataArray.GENERATEDATE;
        if (this.SendDataArray.GENERATETIME) {
            this.DocumentRegiData.GENERATETIME = this.convertTo24HourFormat(this.SendDataArray.GENERATETIME.trim());
            
            
          }
        // this.DocumentRegiData.GENERATETIME = this.SendDataArray.GENERATETIME;
        this.DocumentRegiData.DOCUMENTCLASS = this.SendDataArray.DOCUMENTCLASS;
        this.DocumentRegiData.DESCRIPTION = this.SendDataArray.DESCRIPTION;
        this.DocumentRegiData.DRAFTSTATUS = this.SendDataArray.DRAFTSTATUS;
        this.DocumentRegiData.DOCUMENTNUMBER = this.SendDataArray.DOCUMENTNUMBER;
        this.DocumentRegiData.DOCUMENTTYPE = this.SendDataArray.DOCUMENTTYPE;
        this.DocumentRegiData.DOCUMENTNAME = this.SendDataArray.DOCUMENTNAME;
        this.DocumentRegiData.KEYWORDS = this.SendDataArray.KEYWORDS;
        this.DocumentRegiData.GENERATEDATESEND = this.SendDataArray.GENERATEDATESEND;
        this.DocumentRegiData.DOCUMENTAUTHOR = this.SendDataArray.DOCUMENTAUTHOR;
        this.DocumentRegiData.DOCUMENTRECIPIENTS = this.SendDataArray.DOCUMENTRECIPIENTS;
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }
  // ends here ~ get document default values on load
}
