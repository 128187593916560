import { GlobalFunctionsService } from "@_services/global-functions.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import * as $ from "jquery";
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { WorkflowService } from "@_services/workflow.service";
import { BehaviorService } from "@_services/Behavior.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { error } from "console";
@Component({
    selector: "app-add-task-pack",
    templateUrl: "./add-task-pack.component.html",
    styleUrls: ["./add-task-pack.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class AddTaskPackComponent implements OnInit {
    confirmMessage = "Add Task Pack";
    TaskPackForm: FormGroup;
    TaskPackItemForm: FormGroup;
    isLoadingResults: boolean;
    Classdata: any;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    private _unsubscribeAll$: Subject<void> = new Subject();
    protected data = inject(MAT_DIALOG_DATA);
    protected globalFunService = inject(GlobalFunctionsService);
    btntext: string;
    Task_Pack_Item_dataSource: MatTableDataSource<unknown>;
    SelectedTaskPackItemRow: any;
    highlightedRows: any;
    selectedColore: string = "rgb(217, 217, 217)";
    TemplateDropDownData;
    EmailTemlateDropDownData: any;
    @ViewChild("table") table: MatTable<PeriodicElement>;
    ShowExpandedData = true;
    RoleAssignData: any;
    WhichFieldData: any;
    WhichTableData: any;
    isCalltaskPackItem: boolean=true;
    isLoad=false;
    isShowDueDateField: boolean=false;
    constructor(
        private fb: FormBuilder,
        private _mainAPiServiceService: MainAPiServiceService,
        private dialogRef: MatDialogRef<AddTaskPackComponent>,
        private toastr: ToastrService,
        public workflow: WorkflowService,
        private behaviorService: BehaviorService
    ) {
        this.TaskPackForm = this.fb.group({
            MATTERCLASS: [""],
            TASKPACKORDER: [""],
            DEFAULTPACK: [""],
            PACKDESCRIPTION: [""],
            TASKPACKGUID: [""],
        });

        /**
         * This observable is used to get the loader data.
         */
        this.TaskPackItemForm = this.workflow.getTaskPackItemForm();
        this.behaviorService.WorkFlowLoader$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((data) => {
            this.isLoadingResults = data;
            this.isLoad=true;
        });

        
        this.behaviorService.ColapsExpandEditRows$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((data) => {
            if(data != null && data == true){
                this.getAllTaskPackItem(this.data.EditId);
                this.expandedElement = null;
                this.maintainFlag = false;
            }
        });
        
        /**
         * This observable is used to get the default error and warning data.
         */
        this.behaviorService.WorkFlowErrorWarningData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((data) => {
            if (data != null && data != undefined) {
                this.errorWarningData = data;
            }
        });
    }

    ngOnInit(): void {
        this.structureOfProtectionCrud();

        this.TaskPackForm.valueChanges.subscribe((formControl) => {
            this.changeForControl(formControl);
        });


        this.TaskPackForm.get('MATTERCLASS')?.valueChanges.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((newValue) => {
            if(newValue != undefined && newValue !=null ){
                //this.getWhichTableData(newValue);
                this.getWhichFieldData(newValue);
            }
        });


        // this.TaskPackItemForm.get('WHICHTABLE')?.valueChanges.pipe(
        //     takeUntil(this._unsubscribeAll$)
        // ).subscribe((newValue) => {
        //     if(newValue != undefined && newValue !=null ){
        //         this.getWhichFieldData(newValue)
        //     }
        // });

        this.TaskPackItemForm.get('REMIND')?.valueChanges.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((Value) => {
            if((Value !=null && Value !=undefined))
                this.isShowDueDateField=(Value == 0) ? false : (Value == 1) ? true : Value;
            });
    }

    /**
     * Used to MAKE the crud structure
     */
    structureOfProtectionCrud(): void {
        if (this.data.action == "insert") {
            this.getDefaultData();
            this.btntext = "Save and Add Items";
            this.confirmMessage = "Add Task Pack";
        } else if (this.data.action == "update") {
            this.changeForControl(this.f);
            this.btntext = "Update";
            this.confirmMessage = "Update Task Pack";
            // if(this.isCalltaskPackItem == true){
                this.getTaskPackEditedData(this.data.EditId);
            // }
        }
        this.getMatterClassData();
        this.getRoleAssignData();
    }

    get f() {
        return this.TaskPackForm.controls;
    }

    get g() {
        return this.TaskPackItemForm.controls;
    }
    /**
     * This function is used to get the Matter class data.
     */
    getMatterClassData() {
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(
                { Action: "getdata", Filters: { LookupType: "Matter Class" } },
                "lookup"
            )
            .subscribe(
                (responses) => {
                    if (
                        responses.CODE === 200 &&
                        responses.STATUS === "success"
                    ) {
                        this.Classdata = responses.DATA.LOOKUPS;
                        if (responses.DATA.LOOKUPS[0]) {
                        }
                    }
                    this.isLoadingResults = false;
                },
                (error) => {
                    console.log(error);
                    this.isLoadingResults = false;
                }
            );
    }

    getRoleAssignData() {
        this.workflow.loadRoleAssignTo().subscribe(
            (responses) => {
                if (responses.CODE === 200 && responses.STATUS === "success") {
                    this.RoleAssignData = responses.DATA.LOOKUPS;
                }
                this.isLoadingResults = false;
            },
            (error) => {
                console.log(error);
                this.isLoadingResults = false;
            }
        ),
            (error) => {
                console.log(error);
            };
    }

    getWhichTableData(Newvalue){
        this.isLoadingResults=true;
        this.workflow.loadWhichTable(Newvalue).subscribe(
            (responses) => {
                if (responses.CODE === 200 && responses.STATUS === "success") {
                    this.WhichTableData = responses.DATA.LOOKUPS;
                    this.isLoadingResults=false;
                }
                this.isLoadingResults = false;
            },
            (error) => {
                console.log(error);
                this.isLoadingResults = false;
            }
        ),
            (error) => {
                console.log(error);
            };
    }

    getWhichFieldData(WHICHTABLEDATA) {
        if(WHICHTABLEDATA == '' || WHICHTABLEDATA == undefined || WHICHTABLEDATA == null){
            return
          }
          this.isLoadingResults=true;
        this.workflow.loadWhichField(this.f.MATTERCLASS.value,WHICHTABLEDATA).subscribe(
          (responses) => {
            if (responses.CODE === 200 && responses.STATUS === "success") {
              this.WhichFieldData = responses.DATA.LOOKUPS;
            }
            this.isLoadingResults = false;
          },
          (error) => {
            console.log('....................ERROR.............',error);
            this.isLoadingResults = false;
            //this.errorMessage = "Failed to load field data. Please try again later."; // Optional: Display error message to user
          }
        );
      }
    /**
     * This function is used to get the task pack data.
     */
    TaskPackData(): void {
        this.isLoadingResults = true;
        let payload;
        if (this.data.action == "update") {
            payload = {
                MATTERCLASS: this.f.MATTERCLASS.value,
                TASKPACKORDER: this.f.TASKPACKORDER.value,
                DEFAULTPACK: this.f.DEFAULTPACK.value,
                PACKDESCRIPTION: this.f.PACKDESCRIPTION.value,
                TASKPACKGUID: this.f.TASKPACKGUID.value,
            };
        } else {
            payload = {
                MATTERCLASS: this.f.MATTERCLASS.value,
                TASKPACKORDER: this.f.TASKPACKORDER.value,
                DEFAULTPACK: this.f.DEFAULTPACK.value,
                PACKDESCRIPTION: this.f.PACKDESCRIPTION.value,
            };
        }

        let finalpayload = {
            Action: this.data.action,
            VALIDATEONLY: true,
            DATA: {
                ...payload,
            },
        };
        this._mainAPiServiceService
            .getSetData(finalpayload, "task-pack")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            finalpayload,
                            "task-pack"
                        );
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            finalpayload,
                            "task-pack"
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            finalpayload,
                            "task-pack"
                        );
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * Thsi function is used to check the validation
     * @param bodyData - Request data value
     * @param details - Detail data value
     * @param APIURL - API URL
     */
    async checkValidation(
        bodyData: any,
        details: any,
        APIURL: any
    ): Promise<void> {
        this.isLoadingResults = false;
        await this.globalFunService
            .checkValidation(bodyData, details)
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((data) => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    // this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        //this.SafeCusodySave(details, APIURL)
                        this.saveTaskPacketsData(details, APIURL);
                    }
                }
            });
    }

    /**
     * This function is used to Save the TaskPacks Data
     * @param data -Data value
     * @param APIURL -API URL DATA
     */
    saveTaskPacketsData(data: any, APIURL: string): void {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService
            .getSetData(data, APIURL)
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        if (this.data.action == "update") {
                            this.toastr.success("update successfully");
                        } else {
                            this.toastr.success("save successfully");
                        }
                        this.isLoadingResults = false;
                         $('#TASKPACK').click();
                        // this.dialogRef.close(true);
                        if (this.data.action == "insert") {
                            this.data.action = "update";
                            this.data.EditId = response.DATA.TASKPACKGUID;
                            this.isCalltaskPackItem=false;
                            this.structureOfProtectionCrud();
                        } else {
                            $("#TASKPACK").click();
                            this.dialogRef.close(true);
                        }
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.toastr.warning(response.MESSAGE);
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                    this.isLoadingResults = false;
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to get the default data of the pack-item fields.
     */
    getDefaultData() {
        this.isLoadingResults = true;
        let data;
        data = { Action: "Default", DATA: {} };
        this._mainAPiServiceService
            .getSetData(data, "task-pack")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        const TaskPackData = res.DATA.DEFAULTVALUES;
                        this.TaskPackForm.patchValue({
                            MATTERCLASS: TaskPackData["MATTERCLASS"],
                            TASKPACKORDER: TaskPackData["TASKPACKORDER"],
                            DEFAULTPACK: TaskPackData["DEFAULTPACK"],
                            PACKDESCRIPTION: TaskPackData["PACKDESCRIPTION"],
                        });
                        this.isLoadingResults = false;
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toastr.warning(res.MESSAGE);
                        this.isLoadingResults = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toastr.error(res.MESSAGE);
                        this.isLoadingResults = false;
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }

                    this.CheckdefultValidation(res.DATA.VALIDATIONS);
                    this.isLoadingResults = false;
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * Used to check the default validation.
     * @param bodyData -All default data.
     */
    async CheckdefultValidation(bodyData: any): Promise<void> {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: {
            VALUEVALID: string;
            ERRORDESCRIPTION: any;
            FIELDNAME: any;
        }) {
            if (value.VALUEVALID == "No") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = await {
            Error: tempError,
            Warning: tempWarning,
        };
        this.errorWarningDataArray = await {
            Error: tempError,
            Warning: tempWarning,
        };
        // await this.changeForControl(this.f);
    }

    /**
     * To change the Formcontrol value
     * @param formControl -get updated form control value
     */
    changeForControl(formControl: any): void {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj };
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 };
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }

    /**
     * This function is used to set the selected Task-pack data
     * @param id -TASKPACKGUID
     */
    getTaskPackEditedData(id) {
        if(id =='' || id == undefined || id == null){
            return
        }
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(
                { Action: "GetData", FILTERS: { TASKPACKGUID: id } },
                "task-pack"
            )
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((res) => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.TaskPackForm.patchValue({
                        MATTERCLASS: res.DATA.RECORDS[0]["MATTERCLASS"],
                        TASKPACKORDER: res.DATA.RECORDS[0]["TASKPACKORDER"],
                        DEFAULTPACK: res.DATA.RECORDS[0]["DEFAULTPACK"],
                        PACKDESCRIPTION: res.DATA.RECORDS[0]["PACKDESCRIPTION"],
                        TASKPACKGUID: res.DATA.RECORDS[0]["TASKPACKGUID"],
                    });
                    // this.isLoadingResults = false;
                    this.getAllTaskPackItem(res.DATA.RECORDS[0]["TASKPACKGUID"]);
                } else if (res.CODE == 451 && res.STATUS == "warning") {
                    this.toastr.warning(res.MESSAGE);
                    this.isLoadingResults = false;
                } else if (res.CODE == 450 && res.STATUS == "error") {
                    this.toastr.error(res.MESSAGE);
                    this.isLoadingResults = false;
                } else if (res.MESSAGE == "Not logged in") {
                    this.dialogRef.close(false);
                }
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.behaviorService.ColapsExpandEditRows$.next(false);
      }

    getWhichTableSelectedData(data){

    }

    /**
     *  TASK PACK ITEM SECTION START FROM HERE
     */
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource = ELEMENT_DATA;
    columnsToDisplay = [
        "TASKORDER",
        "ROLEASSIGNEDTO",
        "DUEDATEDESCRIPTION",
        // "UNITS",
        // "UNITTYPE",
        // "ISBEFORE",
        // "WHICHTABLE",
        // "WHICHFIELD",
        "TASKDESCRIPTION",
        "REMINDERDESCRIPTION",
        // "REMIND",
        // "DAYSBEFOREDUEDATE",
        "PRIORITY",
        "TEMPLATEDESCRIPTION",
        // "TEMPLATETYPE",
        // "TEMPLATE",
        // "KITNAME",
        // "EMAILNAME",
    ];
    columnsToDisplayWithExpand = [...this.columnsToDisplay, "expand"];
    expandedElement: PeriodicElement | null;
    maintainFlag: boolean = false;

    getAllTaskPackItem(id) {
        if(id == '' || id == undefined || id == null){
return
        }
        this.isLoadingResults = true;
        let pastData = {
            Action: "GetData",
            Filters: {
                TASKPACKGUID: id,
            },
        };
        this._mainAPiServiceService
            .getSetData(pastData, "task-pack-item")
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.Task_Pack_Item_dataSource = new MatTableDataSource(
                            res.DATA.RECORDS
                        );
                        this.Task_Pack_Item_dataSource.paginator =
                            this.paginator;
                        if (res.DATA.RECORDS[0]) {
                            this.SelectedTaskPackItemRow = res.DATA.RECORDS[0];
                            this.highlightedRows =
                                res.DATA.RECORDS[0].TASKPACKITEMGUID;
                        }
                        setTimeout(() => {
                            this.isLoadingResults = false;
                        }, 500);
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    OpenTaskPackItemBlock() {
        this.maintainFlag = true;
        this.expandedElement = null;
       // this.getWhichTableData(this.f.MATTERCLASS.value)
        this.getWhichFieldData(this.f.MATTERCLASS.value);
        this.workflow.getTaskPackItemDefaultData(this.data.action);
    }

    editTaskPacksItems(row) {
        // console.log(row);
    }

    checkexpand(element) {
        // console.log(this.expandedElement);
        // console.log(element);
    }

    dataval(text) {
        this.workflow.getAllLookUpsValue(this.data.action, text.value);
    }

    datavalSingle(text) {
        this.workflow.getAllLookUpsValue(this.data.action, text);
    }

    getEditTaskPackItemData(element) {
        this.workflow.setEditTaskPackItemData(element,this.f.MATTERCLASS.value);
    }

    ExpandColapsArea(element) {
        this.maintainFlag = false;
        this.expandedElement =
            this.expandedElement === element ? null : element;
    }

    InsertUpdateTaskPackItem(Action) {
        this.workflow.InsertEditTaskPackItemData(Action, this.data.EditId);
    }


    DeleteTaskPackItem(Data){
        this.workflow.deleteTaskPack(Data?.TASKPACKITEMGUID,'task-pack-item');
    }
    /**
     * Sets the current dragged typename when a drag operation starts.
     *
     * @param typename - The typename of the item being dragged.
     */
    onDragStart(ele): void {
        //console.log(ele);
        // this.currentDraggedTypename = ele.TYPENAME;
        // this.highlightedRows = ele.MATTERCONTACTGUID;
    }

    /**
     * Handles the drop event when an item is dropped into a new position.
     *
     * @param event - The drag-and-drop event containing information about the drop.
     */
    onDrop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(
            this.Task_Pack_Item_dataSource.data,
            event.previousIndex,
            event.currentIndex
        );
        this.Task_Pack_Item_dataSource.data = [
            ...this.Task_Pack_Item_dataSource.data,
        ];
        this.expandedElement = null;
        let newPosition;

        console.log(this.Task_Pack_Item_dataSource.data);

        // this.Task_Pack_Item_dataSource.data.map((cors,index) => {
        //     if(cors.TASKPACKITEMGUID == this.highlightedRows) {
        //         newPosition = index + 1;
        //     }
        // })
        return;
        this._mainAPiServiceService
            .getSetData(
                {
                    Action: "Reorder",
                    DATA: {
                        MATTERCONTACTGUID: this.highlightedRows,
                        NEWPOSITION: "",
                    },
                },
                "matter-contact"
            )
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        //this.isspiner = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        // this.isspiner = false;
                        this.dialogRef.close(false);
                    } else {
                        //  this.isspiner = false;
                    }
                },
                (error: any) => {
                    //this.isspiner = false;
                }
            );
    }
}

export interface PeriodicElement {
    ROLEASSIGNEDTO: any;
    TASKORDER: any;
    UNITS: any;
    UNITTYPE: any;
    ISBEFORE: any;
   // WHICHTABLE: any;
    WHICHFIELD: any;
    TASKDESCRIPTION: any;
}

const ELEMENT_DATA = [
    {
        position: 1,
        name: "Hydrogen",
        weight: 1.0079,
        symbol: "H",
        description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
        atomic weight of 1.008, hydrogen is the lightest element on the periodic table.`,
    },
    {
        position: 2,
        name: "Helium",
        weight: 4.0026,
        symbol: "He",
        description: `Helium is a chemical element with symbol He and atomic number 2. It is a
        colorless, odorless, tasteless, non-toxic, inert, monatomic gas, the first in the noble gas
        group in the periodic table. Its boiling point is the lowest among all the elements.`,
    },
    {
        position: 3,
        name: "Lithium",
        weight: 6.941,
        symbol: "Li",
        description: `Lithium is a chemical element with symbol Li and atomic number 3. It is a soft,
        silvery-white alkali metal. Under standard conditions, it is the lightest metal and the
        lightest solid element.`,
    },
    {
        position: 4,
        name: "Beryllium",
        weight: 9.0122,
        symbol: "Be",
        description: `Beryllium is a chemical element with symbol Be and atomic number 4. It is a
        relatively rare element in the universe, usually occurring as a product of the spallation of
        larger atomic nuclei that have collided with cosmic rays.`,
    },
    {
        position: 5,
        name: "Boron",
        weight: 10.811,
        symbol: "B",
        description: `Boron is a chemical element with symbol B and atomic number 5. Produced entirely
        by cosmic ray spallation and supernovae and not by stellar nucleosynthesis, it is a
        low-abundance element in the Solar system and in the Earth's crust.`,
    },
    {
        position: 6,
        name: "Carbon",
        weight: 12.0107,
        symbol: "C",
        description: `Carbon is a chemical element with symbol C and atomic number 6. It is nonmetallic
        and tetravalent—making four electrons available to form covalent chemical bonds. It belongs
        to group 14 of the periodic table.`,
    },
    {
        position: 7,
        name: "Nitrogen",
        weight: 14.0067,
        symbol: "N",
        description: `Nitrogen is a chemical element with symbol N and atomic number 7. It was first
        discovered and isolated by Scottish physician Daniel Rutherford in 1772.`,
    },
];
