<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>Find/Replace
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3 fxFlex="100" class="pr-4">
                        This feature allows you to search for a specific word or phrase within unbilled time entries and replace all instances of it with another word or phrase, essentially making bulk edits to your text quickly and efficiently. 
                    </h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3 fxFlex="100" class="pr-4">
                        <strong>This process will prompt you for each unbilled Time Entry to make sure you want to change
                            it.</strong>
                    </h3>
                </div>
                
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" >
                        <mat-label>Find What</mat-label>
                        <input matInput [disabled]="readonly"  name="searchTerm" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" >
                        <mat-label>Replace With</mat-label>
                        <input matInput [disabled]="readonly" name="replaceTerm" [(ngModel)]="replaceTerm" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr" *ngIf="showStartCancel">
        <button mat-raised-button color="accent" class="mat-accent"  cdkFocusInitial  (click)="CheckTermToUnbilledTimeEntries()">
            <mat-spinner *ngIf="isspiner"></mat-spinner> Start
        </button>
        <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
    <mat-dialog-actions align="end" class="popup_ftr" *ngIf="!showStartCancel">
        <!-- Use Angular Flex Layout for flexible grid -->
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100%">
            <!-- *ngIf='this.router.url =="/invoice/bulk-invoices"' -->
          <div fxFlex="50" class="pr-4">
            <mat-form-field appearance="outline" fxFlex="100%">
              <mat-label>Before Change</mat-label>
              <textarea class="textaheight" matInput [disabled]="readonly" name="CurrentAdditionalText" [(ngModel)]="CurrentAdditionalText" [ngModelOptions]="{standalone: true}" required></textarea>
            </mat-form-field>
          </div>
          <!-- *ngIf='this.router.url =="/invoice/bulk-invoices"' -->
          <div fxFlex="50" class="pr-4">
            <mat-form-field appearance="outline" fxFlex="100%">
              <mat-label>After Change</mat-label>
              <textarea  [ngStyle]="{ 'height.px': 45 }" matInput name="UpdatedAdditionalext" [(ngModel)]="UpdatedAdditionalext" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>
          </div>
          <!-- Spacer to separate from buttons -->
          <div fxFlex="100%" class="mt-2" fxLayoutAlign="end">
            <!-- Buttons on a new line aligned to the right -->
            <button mat-raised-button color="accent" class="mat-accent" [disabled]="isspiner" (click)="isSkip = false; replaceTermToUnbilledTimeEntries()">
              <mat-spinner *ngIf="isspiner"></mat-spinner> Replace
            </button>
            <!-- <button mat-raised-button color="accent" class="mat-accent" [disabled]="isspiner" (click)="isSkip = true; skipFindReplace()">
                <mat-spinner *ngIf="isspiner"></mat-spinner> Skip
            </button> -->
            <button mat-raised-button color="primary" class="ml-2" [disabled]="isspiner" (click)="isSkip = true; skipFindReplace()">
                <mat-spinner *ngIf="isspiner"></mat-spinner>Don't Replace
            </button>
          </div>
        </div>
      </mat-dialog-actions>
</div>