import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import * as $ from "jquery";
import { User } from "../_models";
import { ToastrService } from "ngx-toastr";
import { BehaviorService } from "./Behavior.service";
import { ConfirmPopupComponent } from "../../../src/app/main/confirm-popup/confirm-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { TimersService } from "./timers.service";
import { LogoutErrorComponent } from "../../app/main/logout-error/logout-error.component";
// import * as appVersion from "src/assets/version.json";
import packageJson from "src/assets/version.json";
import { SortingBehaviourService } from "./sorting-behaviour.service";
import * as Fingerprint2 from "fingerprintjs2";
import { SocketService } from "./socket.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private _jsonURL = "assets/version.json";
    private currentUserSubject: BehaviorSubject<User>;
    public currentUserSubject$: BehaviorSubject<User>;
    loop: any;
    public currentUser: Observable<User>;
    currentUserLocal: any;
    confirmDialogRef: any;
    // accessing favicon link tag
    favIcon: HTMLLinkElement = window.document.querySelector("#appIcon");
    tempSessionToken: any;
    dialogRef: any;
    isPopoupOpened: boolean = false;
    CurrentURL: string = "";
    deviceUniqueId: string;
    deviceUniqueName: string;
    deviceUniqueType: string;
    LOCATIONDATA: any;
    constructor(
        private http: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        public MatDialog: MatDialog,
        private Timersservice: TimersService,
        public dialog: MatDialog,
        public SortingBehaviourService: SortingBehaviourService,
        private socketService: SocketService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUser"))
        );
        this.currentUserSubject$ = new BehaviorSubject<any>(
            JSON.parse(localStorage.getItem("currentUser"))
        );
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentUserLocal = JSON.parse(localStorage.getItem("currentUser"));
        this.generateDeviceId();
        this.performReverseGeocoding();
    }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     * This function is used to get the preformReverse Geocoding 
     */
    performReverseGeocoding(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

                    this.http.get(apiUrl).subscribe((response: any) => {
                        const address = response.address;
                        this.LOCATIONDATA = address;
                    });
                    // Process the latitude and longitude values as needed
                },
                (error) => {
                    // User denied permission or an error occurred
                    console.error(
                        "User denied permission or an error occurred:",
                        error
                    );
                    console.error("Error getting location:", error);
                    // Handle error case
                }
            );
        } else {
            // Geolocation is not supported by the browser
        }
    }


    /**
     * This fuction is used to generate the device id
     * @returns 
     */
    generateDeviceId(): Promise<string> {
        return new Promise((resolve, reject) => {
            Fingerprint2.get((components) => {
                const values = components.map((component) => component.value);
                const fingerprint = Fingerprint2.x64hash128(
                    values.join(""),
                    31
                );
                this.deviceUniqueId = `${fingerprint}`;
                this.deviceUniqueName = navigator.userAgent;
                this.deviceUniqueType = navigator.platform;

                resolve(this.deviceUniqueId);
            });
        });
    }

    /**
     * This function is used to login to the user
     */
    login(
        uesrname: string,
        password: string,
        ipAddress: any,
        BROWSER: any,
        OPERATINGSYSTEM: any,
        USERAGENT: any,
        request?,
        user2fatoken?
    ) {
        let detailLogin = {
            user: uesrname,
            password: password,
            formatting: "JSON",
            EmailAddress: "",
            SessionToken: "",
            // IPADDRESS: ipAddress,
            BROWSER: BROWSER,
            USERAGENT: USERAGENT,
            OPERATINGSYSTEM: OPERATINGSYSTEM,
        };
        let detail2FAUser = {
            user: uesrname,
            request: request,
            user2fatoken: user2fatoken,
            SessionToken: this.tempSessionToken,
        };
        let detailMS = {
            Request: "getlogindata",
            SessionToken: this.tempSessionToken,
        };
        let detail = request && user2fatoken ? detail2FAUser : detailLogin;

        // put version in payload to distinctiate between new version and old version
        detail["version"] = packageJson.version;
        detail["UI"] = packageJson.UI;
        // Add new parameters like ( DEVICEID,DEVICENAME and DEVICETYPE )

        (detail["DEVICEID"] = this.deviceUniqueId),
            (detail["DEVICENAME"] = this.deviceUniqueName),
            (detail["DEVICETYPE"] = this.deviceUniqueType);
        detail["LOCATION"] = this.LOCATIONDATA;

        const headers =
            request && user2fatoken
                ? { headers: { skip: "true" } }
                : { headers: {} };
        return this.logires(detail, headers);
    }

    /**
     * This function is used to set the login response
     * @param detail -details data value
     * @param headers -headers data value
     * @returns data
     */
    // logires(detail, headers) {
    //     return this.http
    //         .post<any>(environment.APIEndpoint + "login", detail, headers)
    //         .pipe(
    //             map((loginResponse: any) => {
    //                 if (
    //                     loginResponse &&
    //                     loginResponse.CODE == 200 &&
    //                     loginResponse.STATUS == "success"
    //                 ) {

    //                     // if (loginResponse && loginResponse?.DATA?.SHOWSETUPGUIDE == 1) {

    //                     //    setTimeout(() => {

    //                     //        $(".setupguide-btn").click();
    //                     //    }, 1000);
    //                     // }
    //                     if (loginResponse.DATA.AWAITING2FA == 1) {
    //                         this.tempSessionToken =
    //                             loginResponse.DATA.SESSIONTOKEN;

    //                         return { AWAITING2FA: true, loginSuccess: false };
    //                     } else {
    //                         let LoggedInStatus =
    //                             loginResponse.DATA.LOGGEDINSTATUS;
    //                         if (LoggedInStatus) {
    //                             localStorage.setItem('login_data',JSON.stringify(loginResponse.DATA))
    //                             localStorage.setItem(
    //                                 "Login_response",
    //                                 JSON.stringify(loginResponse.DATA)
    //                             );
    //                             localStorage.setItem(
    //                                 "session_token",
    //                                 loginResponse.DATA.SESSIONTOKEN
    //                             );
    //                             this.currentUserSubject.next(
    //                                 loginResponse.DATA
    //                             );
    //                             this.currentUserSubject$.next(
    //                                 loginResponse.DATA
    //                             );
    //                             this.currentUserLocal = loginResponse.DATA;
    //                             this.behaviorService.SetNotificationCall(true);
    //                             this.behaviorService.startInterval(true);
    //                             if (
    //                                 loginResponse &&
    //                                 loginResponse.DATA &&
    //                                 loginResponse.DATA.ALLOWEDFEATURES
    //                             ) {
    //                                 localStorage.setItem(
    //                                     "set_tier_permission",
    //                                     JSON.stringify(
    //                                         loginResponse.DATA.ALLOWEDFEATURES
    //                                     )
    //                                 );
    //                                 this.behaviorService.setaccessTierPermission(loginResponse.DATA.ALLOWEDFEATURES);
    //                             }
    //                             //localStorage.setItem('set_app_permissions', JSON.stringify(loginResponse.DATA.PERMISSIONS))
    //                             this.CurrentURL = null;
    //                             this.toastr.success("success");
    //                             // this.socketService.openSocketMatter(loginResponse.DATA.SESSIONTOKEN);
    //                             return {
    //                                 AWAITING2FA: false,
    //                                 loginSuccess: true,
    //                                 sessionToken: loginResponse.DATA.SESSIONTOKEN
    //                             };
    //                         }
    //                     }
    //                 }
    //             }),
    //             switchMap((result: any) => {
    //                 if (result && result.loginSuccess) {
    //                     return from(this.socketService.openSocketMatter(result.sessionToken)).pipe(
    //                         map(() => result)
    //                     );
    //                 }
    //                 return of(result);
    //             })
    //         );
    // }

    /**
     * This function is used to get the login response data. 
     */
    logires(detail, headers) {
        return this.http
        .post<any>(environment.APIEndpoint + "login", detail, headers)
        .pipe(
            map((loginResponse: any) => {
            if (
                loginResponse &&
                loginResponse.CODE == 200 &&
                loginResponse.STATUS == "success"
            ) {
                if (loginResponse.DATA.AWAITING2FA == 1) {
                this.tempSessionToken = loginResponse.DATA.SESSIONTOKEN;
                localStorage.setItem("session_token", loginResponse.DATA.SESSIONTOKEN);
                this.socketService.openSocketMatter(loginResponse.DATA.SESSIONTOKEN);
                return { AWAITING2FA: true, loginSuccess: false };
                } else {
                let LoggedInStatus = loginResponse.DATA.LOGGEDINSTATUS;
                if (LoggedInStatus) {
                    localStorage.setItem('login_data', JSON.stringify(loginResponse.DATA));
                    localStorage.setItem("Login_response", JSON.stringify(loginResponse.DATA));
                    localStorage.setItem("session_token", loginResponse.DATA.SESSIONTOKEN);
                    this.currentUserSubject.next(loginResponse.DATA);
                    this.currentUserSubject$.next(loginResponse.DATA);
                    this.currentUserLocal = loginResponse.DATA;
                    this.behaviorService.SetNotificationCall(true);
                    this.behaviorService.startInterval(true);
                    if (loginResponse.DATA.ALLOWEDFEATURES) {
                    localStorage.setItem("set_tier_permission", JSON.stringify(loginResponse.DATA.ALLOWEDFEATURES));
                    this.behaviorService.setaccessTierPermission(loginResponse.DATA.ALLOWEDFEATURES);
                    }
                    this.CurrentURL = null;
                    this.toastr.success("success");
                    this.socketService.openSocketMatter(loginResponse.DATA.SESSIONTOKEN);
                    return { AWAITING2FA: false, loginSuccess: true };
                }
                }
            }
            })
        );
    }
  
    
    

    /**
     * This function is used to change the password
     * @param postData -post data value
     * @returns -updated passord
     */
    changePassword(postData) {
        if (postData == null) {
            postData = {};
        }
        return this.http.post<any>(environment.APIEndpointDeliverable + "login", postData);
    }

    /**
     * This unction is used to get the route module data
     * @param initialwindowData -initial data value
     * @returns data
     */
    getRouteModuleData(initialwindowData) {
        let routeData: any = [""];
        if (initialwindowData) {
            switch (initialwindowData.trim()) {
                case "Matter":
                    routeData = ["matters"];
                    break;
                case "Trust":
                    routeData = ["trust-money/full-trust-money"];
                    break;
                case "Time Entries":
                    routeData = ["time-entries/full-time-entries"];
                    break;
                case "Contacts":
                    routeData = ["contact"];
                    break;
                case "Spend Money":
                    routeData = ["spend-money"];
                    break;
                case "Receive Money":
                    routeData = ["receive-money"];
                    break;
                case "Invoices":
                    routeData = ["invoice"];
                    break;
                case "Diary":
                    routeData = ["diary"];
                    break;
                case "Authorities":
                    routeData = ["authorities/full-authorities"];
                    break;
                case "Chart of Accounts":
                    let ChartHandlingData: any = {
                        ClickType: "WithoutTrust",
                        UseTrust: false,
                        PopUp: "",
                        Lable: "CHART OF ACCOUNTS",
                    };
                    localStorage.setItem(
                        "ChartURL",
                        JSON.stringify(ChartHandlingData)
                    );
                    this.behaviorService.TrustDuplicateModuleHandling(
                        ChartHandlingData
                    );
                    routeData = ["chart-account"];
                    break;
                case "Trust Chart of Accounts":
                    let ChartHandlingDataT: any = {
                        ClickType: "WithTrust",
                        UseTrust: true,
                        PopUp: "",
                        Lable: "TRUST CHART OF ACCOUNTS",
                    };
                    localStorage.setItem(
                        "ChartURL",
                        JSON.stringify(ChartHandlingDataT)
                    );
                    this.behaviorService.TrustDuplicateModuleHandling(
                        ChartHandlingDataT
                    );
                    routeData = ["trust-chart-account"];
                    break;
                case "Safe Custody":
                    routeData = ["full-Safe-Custody/Safe-Custody"];
                    break;
                case "DashBoard":
                    routeData = ["dashboard/firm-dashboard"];
                    break;
                case "Trust End of Month":
                    routeData = ["trust-end-month/to-do"];
                    break;
                default:
                    routeData = ["matters"];
            }
            localStorage.setItem("defult_routeData", JSON.stringify(routeData));
        }
        return routeData;
    }

    /**
     * This function is used to check the login data value
     */
    notLogin() {
        const currentUser = this.currentUserValue;
        if (currentUser) {
            // setTimeout(() => {
            this.router.navigate(
                this.getRouteModuleData(currentUser.initialwindow)
            );
            // }, 1000);
            return false;
        }
        // Not logged in so return true
        return true;
    }

    /**
     * This function is used to logout the user 
     */
    logout() {
        // remove user from local storage to log user out
        this.http
            .post<any>(environment.APIEndpoint + "Login", { request: "Logout" })
            .subscribe(
                (loginResponse) => {
                    if (
                        loginResponse.STATUS == "success" &&
                        loginResponse.CODE == 200
                    ) {
                        this.behaviorService.dialogClose(loginResponse);
                        this.removeDataFunction(loginResponse);
                    } else {
                        this.toastr.error(loginResponse.MESSAGE);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    addGoogleFont() {
        const fontLink = document.createElement('link');
        fontLink.id = 'google-font'; // Set an ID to identify it later
        fontLink.rel = 'stylesheet';
        fontLink.href = 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
        document.head.appendChild(fontLink);
    }

    /**
     * This function is used to Maintain the license
     * @returns data value
     */
    MaintainLicence() {
        this.getJSON().subscribe((data) => {
            this.behaviorService.setApplicationVersion(data.version);
        });
        return true;
        // let TimeVal: any = localStorage.getItem('currentTime');
        // if (new Date().getTime() > TimeVal) {
        //     this.CheckNewVersion(TimeVal);
        // remove user from local storage to log user out
        //     let currentUser = this.currentUserValue;
        //     let SessionToken = localStorage.getItem('session_token');
        //     if (currentUser && SessionToken) {
        //         this.http.post<any>(environment.APIEndpoint + 'Login', { request: 'maintainlicence', "BACKGROUND": true }).subscribe(loginResponse => {
        //             this.behaviorService.dialogClose(loginResponse);
        //             if (loginResponse.MESSAGE == 'Not logged in' || (loginResponse.STATUS == "" && loginResponse.MESSAGE == "")) {
        //                 let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        //                 let timerData = localStorage.getItem('timer_' + currentUser.UserGuid);
        //                 localStorage.removeItem('currentUser');
        //                 localStorage.removeItem('app_permissions');
        //                 localStorage.removeItem('session_token');
        //                 this.currentUserSubject.next(null);
        //                 this.currentUserSubject$.next(null);
        //                 let veriosn = localStorage.getItem('applicationVersion');
        //                 localStorage.clear();
        //                 localStorage.setItem('applicationVersion', veriosn);
        //                 localStorage.setItem('timer_' + currentUser.UserGuid, timerData);
        //                 this.router.navigate(['login']);
        //             }
        //         }, error => {
        //             this.toastr.error(error);
        //         });
        //     }
        // } else {
        //     return true;
        // }
    }

    /**
     * This unction is used to open the web socket connection 
     * @param SessionToken -session token data value
     */
    openWSConnection(SessionToken: any) {
        // if (localStorage.getItem('isstart') == 'no' && SessionToken) {
        //     let // webSocket = new WebSocket(environment.MainSocketUrl);
        //     try {
        //         let demoSoket = webSocket;
        //         demoSoket.onopen = (openEvent) => {
        //             localStorage.setItem('isstart', 'yes');
        //             demoSoket.send("watch channel " + SessionToken + " LicenceStatus");
        //         };
        //         demoSoket.onclose = (closeEvent) => {
        //             localStorage.setItem('isstart', 'no');
        //             // this.openWSConnection(localStorage.getItem('session_token'));
        //
        //         };
        //         demoSoket.onerror = (errorEvent) => {
        //
        //         };
        //         demoSoket.onmessage = (messageEvent) => {
        //             let data = JSON.parse(messageEvent.data);
        //             let details = JSON.parse(data.value);
        //             if (details) {
        //                 if (details.LOGGEDIN == 0) {
        //                     this.behaviorService.dialogClose({ MESSAGE: 'Not logged in' });
        //                     let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        //                     let timerData = localStorage.getItem('timer_' + currentUser.UserGuid);
        //                     localStorage.removeItem('currentUser');
        //                     localStorage.removeItem('app_permissions');
        //                     localStorage.removeItem('session_token');
        //                     this.currentUserSubject.next(null);
        //                     this.currentUserSubject$.next(null);
        //                     let veriosn = localStorage.getItem('applicationVersion');
        //                     localStorage.clear();
        //                     localStorage.setItem('timer_' + currentUser.UserGuid, timerData);
        //                     localStorage.setItem('applicationVersion', veriosn);
        //                     this.router.navigate(['login']);
        //                 }
        //                 if (details.LASTACTIVITY) {
        //                     clearInterval(this.loop);
        //                     this.checkSessionUpdate(details);
        //                 }
        //             }
        //         }
        //     } catch (exception) {
        //         console.error(exception);
        //     }
        // }
    }

    /**
     * This function is used to check the session token is present or not
     * @param details 
     */
    checkSessionUpdate(details) {
        this.loop = setInterval(() => {
            // let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset() * 600000;
            let dateFo = new Date();
            let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
            let dtate = new Date(currentUTC);
            let currentUTCS =
                Number(details.LASTACTIVITY) + Number(details.TIMEOUTMS);
            let dtate2 = new Date(currentUTCS);
            if (currentUTCS < currentUTC) {
                if (this.confirmDialogRef) {
                    clearInterval(this.loop);
                    this.confirmDialogRef.close(false);
                }
                this.confirmDialogRef = this.MatDialog.open(
                    ConfirmPopupComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: { is_count: true },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    "Hey, you don't seem to have used SILQ in a little while. If you are still using it click 'Continue'";
                this.confirmDialogRef.componentInstance.confirm1 = "Continue";
                this.confirmDialogRef.componentInstance.confirm2 = "Logout";
                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    clearInterval(this.loop);
                    if (result == "Logout") {
                        this.logout();
                        this.confirmDialogRef.close(false);
                    } else if (result == "Continue") {
                        this.Timersservice.ChangeQuantityType({
                            FromQuantityType: "U",
                            toQuantityType: "F",
                            Quantity: "5",
                        }).subscribe(
                            (response) => { },
                            (err) => {
                                this.toastr.error(err);
                            }
                        );
                        this.confirmDialogRef.close(false);
                    }
                    this.confirmDialogRef = null;
                });
            } else {
                if (this.confirmDialogRef) {
                    clearInterval(this.loop);
                    this.confirmDialogRef.close(false);
                }
            }
        }, 10000);
    }

    /**
     * This unction is used to check the New version
     * @param TimeVal -time val data
     * @returns data value
     */
    CheckNewVersion(TimeVal) {
        if (new Date().getTime() > TimeVal) {
            let currentTime: any = new Date();
            currentTime.setMinutes(currentTime.getMinutes() + 1);
            localStorage.setItem("currentTime", currentTime.getTime());
            this.getJSON().subscribe((data) => {
                this.behaviorService.setApplicationVersion(data.version);
            });
        } else {
            return true;
        }
    }

    /**
     * This function is used to get the JSON
     * @returns data value
     */
    public getJSON(): Observable<any> {
        let setHeaders = {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
        };
        let requestOptions = {
            headers: new HttpHeaders(setHeaders),
        };
        return this.http.get(this._jsonURL, requestOptions);
    }

    /**
     * This function is used to tab logout
     */
    tabLogout() {
        // window.name = undefined;
        this.currentUserSubject.next(null);
        this.currentUserSubject$.next(null);
        //this.router.navigate(['login']);

        this.openPopUp();
        if (!this.isPopoupOpened) {
        }
    }

    userDetail: any;
    matterFilter: any;
    spendmoneyFilter: any;


    /**
     * This function is used to get the Reqiured local storage. 
     */
    getRequireLocalStorage():void {
        this.userDetail = JSON.parse(localStorage.getItem("userDetails"));
        // this.matterFilter = JSON.parse(localStorage.getItem('Matterscreen_Data_Filter'));
        // this.spendmoneyFilter = JSON.parse(localStorage.getItem('Spend_money_Filter'));
    }

    /**
     * This function is used to set the require localstorage
      */
    setRequireLocalStorage() {
        localStorage.setItem("userDetails", JSON.stringify(this.userDetail));
        // localStorage.setItem('Matterscreen_Data_Filter',JSON.stringify(this.matterFilter));
        // localStorage.setItem('Spend_money_Filter',JSON.stringify(this.spendmoneyFilter));
    }

    /**
     * This function is used to clear local storage 
     */
    async clearLocal() {
        if (!this.CurrentURL) {
            this.CurrentURL = this.router.url;
        }
        localStorage.setItem("setBackup", "true");
        const windowId = window.name.split("_");
        let windowNameId;
        let tabData;
        windowNameId = windowId && windowId[1] ? windowId[1] : undefined;

        setTimeout(async () => {
            // window.name = undefined;
            let timerData;
            let timerDataId;
            if (this.currentUserLocal) {
                const userGuid =
                    this.currentUserLocal && this.currentUserLocal.UserGuid
                        ? this.currentUserLocal.UserGuid
                        : this.currentUserLocal.USERGUID;
                timerDataId = "timer_" + userGuid;
                // await this.stopTimerOnLogout(timerDataId);
                timerData = localStorage.getItem("timer_" + userGuid);
            }
            // currentUser? timerData = localStorage.getItem('timer_' + currentUser.UserGuid): '';
            const userDetails = localStorage.getItem("userDetails");
            if (windowNameId) {
                tabData = localStorage.getItem(windowNameId);
            }
            const errorMessage = localStorage.getItem("logoutmessage");
            if(this.socketService.isWebSocketConnected()) {
                this.socketService.closeSocketMatterTE();
            }
            localStorage.removeItem("currentUser");
            localStorage.removeItem("app_permissions");
            localStorage.removeItem("session_token");
            localStorage.removeItem("lexon_forms");
            this.currentUserSubject.next(null);
            this.currentUserSubject$.next(null);
            let veriosn = localStorage.getItem("applicationVersion");
            let SelectedCheckbox = localStorage.getItem("SelectedCheckbox");
            let sidebar_fold_unfold = localStorage.getItem(
                "sidebar_fold_unfold"
            );


            let mattr_sorting = localStorage.getItem("matter_screen_sorting");
            let contact_sorting = localStorage.getItem("contact_screen_sorting");
            let time_entries_sorting = localStorage.getItem("time_entry_screen_sorting");
            let invoices_sorting = localStorage.getItem("invoice_screen_sorting");
            let spend_money_sorting = localStorage.getItem("spend_money_screen_sorting");
            let receive_money_sorting = localStorage.getItem("receive_money_screen_sorting");
            let search_sorting = localStorage.getItem("search_screen_sorting");
            let safe_custody_sorting = localStorage.getItem("safe_custody_screen_sorting");
            let conflict_check_sorting = localStorage.getItem("conflick_check_screen_sorting");
            let FrimDashBoard_filter = localStorage.getItem("FrimDashBoard_filter");
            let ChartAccountData = localStorage.getItem("ChartAccountData");
            let USEV11UI = JSON.parse(localStorage.getItem("USEV11UI"));
            let wip_config = JSON.parse(localStorage.getItem("wip_config"));
            let DefaultBankData=JSON.parse(localStorage.getItem('BankingDialogDefaultData'))
            let TaskView=localStorage.getItem('TaskView');
            let currentView=localStorage.getItem('currentView');            
            localStorage.clear();
            this.clearAdvncedFilterData();

            /*  START Delete unused Local Storage */
            localStorage.setItem("matter_screen_sorting", mattr_sorting);
            localStorage.setItem("contact_screen_sorting",contact_sorting);
            localStorage.setItem("time_entry_screen_sorting",time_entries_sorting);
            localStorage.setItem("invoice_screen_sorting",invoices_sorting);
            localStorage.setItem("spend_money_screen_sorting",spend_money_sorting);
            localStorage.setItem("receive_money_screen_sorting",receive_money_sorting);
            localStorage.setItem("search_screen_sorting",search_sorting);
            localStorage.setItem("safe_custody_screen_sorting",safe_custody_sorting);
            localStorage.setItem("conflick_check_screen_sorting",conflict_check_sorting);
            localStorage.setItem("FrimDashBoard_filter",FrimDashBoard_filter);
            localStorage.setItem("BankingDialogDefaultData",JSON.stringify(DefaultBankData));
            localStorage.setItem("TaskView",TaskView);
            localStorage.setItem("currentView",currentView);
            //  localStorage.setItem('ChartAccountData', ChartAccountData)

            // localStorage.setItem('SelectedCheckbox', SelectedCheckbox)
            //  localStorage.setItem('sidebar_fold_unfold', sidebar_fold_unfold)
            localStorage.setItem("userDetails", userDetails);

            this.behaviorService.stopTimeInterval(true);
            localStorage.setItem("applicationVersion", veriosn);
            //   localStorage.setItem('logoutmessage',errorMessage);
            //   localStorage.setItem("wip_config",JSON.stringify(wip_config))
            localStorage.setItem("current_matter_class", "all");
            timerData ? localStorage.setItem(timerDataId, timerData) : "";

            tabData ? localStorage.setItem(windowNameId, tabData) : "";
            // localStorage.setItem('USEV11UI' , JSON.stringify(USEV11UI));

            /* END Delete unused Local Storage */
        }, 1000);
    }

    /**
     * This function is used clear the Advanced filter data value
     */
    clearAdvncedFilterData():void {
        //This is For clearing the Matter Window Filters DATA.
        let matterdata = JSON.parse(
            localStorage.getItem("Matterscreen_Data_Filter")
        );
        this.SortingBehaviourService.MatterFilterData$.next(matterdata);
        // This is For clearing the Contact Window Filters DATA.
        let contact_Filter = localStorage.getItem("contact_Filter");
        this.SortingBehaviourService.contactFilterData$.next(contact_Filter);
        // This is For clearing the SpendMoney Window Filters DATA.
        let Spend_money_Filterdata = JSON.parse(
            localStorage.getItem("Spend_money_Filter")
        );
        this.SortingBehaviourService.spendMoneyFilterData$.next(
            Spend_money_Filterdata
        );
        // This is For clearing the Recive_money Window Filters DATA.
        let Recive_money_Filter = JSON.parse(
            localStorage.getItem("Recive_money_Filter")
        );
        this.SortingBehaviourService.reciveMoneyFilterData$.next(
            Recive_money_Filter
        );
        // This is For clearing the Task Window Filters DATA.
        let Main_Task_Filter = JSON.parse(
            localStorage.getItem("Main_Task_Filter")
        );
        this.SortingBehaviourService.mainTaskFilterData$.next(Main_Task_Filter);
        // This is For clearing the SafeCustody Window Filters DATA.
        let Main_SafeCustody_Filter = JSON.parse(
            localStorage.getItem("Main_SafeCustody_Filter")
        );
        this.SortingBehaviourService.MainSafeCustodyFilterData$.next(
            Main_SafeCustody_Filter
        );
        // This is For clearing the Trust_Money Window Filters DATA.
        let Trust_Money_Filter = JSON.parse(
            localStorage.getItem("Trust_Money_Filter")
        );
        this.SortingBehaviourService.TrustMoneyFilterData$.next(
            Trust_Money_Filter
        );
    }

    /**
     * This function is used to remove the data functions
     * @param loginResponse -loginresponse data value
     */
    async removeDataFunction(loginResponse) {
        if (loginResponse.MESSAGE == "Not logged in") {
            this.clearLocal();
            setTimeout(() => {
                this.router.navigate(["login"], {
                    queryParams: { returnUrl: this.CurrentURL },
                });
                const fontLink = document.getElementById('google-font');
                if(!fontLink) {
                    this.addGoogleFont();
                }
                this.MatDialog.closeAll();
            }, 2000);
        } else if (
            loginResponse.CODE != 402 &&
            loginResponse.STATUS != "error"
        ) {
            this.clearLocal();
            setTimeout(() => {
                this.router.navigate(["login"], {
                    queryParams: { returnUrl: this.CurrentURL },
                });
                const fontLink = document.getElementById('google-font');
                if(!fontLink) {
                    this.addGoogleFont();
                }
                this.MatDialog.closeAll();
                $('body').removeClass('setupguide-active');
            }, 2000);
        }
        //setting the facicon icon to default
        this.favIcon.href = "assets/images/logos/sm-silq-small.png";
    }

    /**
     * This function is used to Forclogout
     */
    async ForcLogout() {
        await this.getRequireLocalStorage();
        this.clearLocal();
        // let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        // let timerData;
        // let timerDataId
        // if (currentUser) {
        //     timerDataId = 'timer_' + currentUser.UserGuid
        //     // await this.stopTimerOnLogout(timerDataId);
        //     timerData = localStorage.getItem('timer_' + currentUser.UserGuid);
        // }
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('app_permissions');
        // localStorage.removeItem('session_token');
        // this.currentUserSubject.next(null);
        // this.currentUserSubject$.next(null);
        // let veriosn = localStorage.getItem('applicationVersion');
        // localStorage.clear();
        // this.behaviorService.stopTimeInterval(true);
        // localStorage.setItem('applicationVersion', veriosn);
        // timerData? localStorage.setItem(timerDataId, timerData): '';
        // await  this.setRequireLocalStorage();
        // this.router.navigate(['login']);
        setTimeout(() => {
            this.router.navigate(["login"], {
                queryParams: { returnUrl: this.CurrentURL },
            });
            this.MatDialog.closeAll();
        }, 1000);
    }

    /**
     * This function is used to set the forgot password
     * @param email -email id
     * @returns data
     */
    forgetpassword(email: string) {
        return this.http
            .post<any>(environment.APIEndpointDeliverable + "login", {
                Request: "ForgottenPassword",
                EmailAddress: email,
            })
            .pipe(
                map((loginResponse) => {
                    if (
                        loginResponse.CODE == 200 &&
                        loginResponse.STATUS == "success"
                    ) {
                        this.toastr.success(
                            "We have sent an email with a password reset link to your email address"
                        );
                        this.router.navigate(["login"]);
                    }
                })
            );
    }

    // stopTimerOnLogout(timerDataId){
    //     let dateFo = new Date();
    //     let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
    //     let demoTimer: any[] = [];
    //     let prevMatterArray = JSON.parse(localStorage.getItem(timerDataId));
    //     if (prevMatterArray && prevMatterArray.length && prevMatterArray.length > 0) {
    //         prevMatterArray.forEach(items => {
    //             let startTimer: any = localStorage.getItem('start_' + items.uuid);
    //
    //             debugger;
    //             if (startTimer) {
    //             let tempData = { 'startTime': items.startTime, 'endTime': currentUTC, 'uuid': items.uuid, 'WORKITEMGUID': items.WORKITEMGUID, 'ADDITIONALTEXT': items.ADDITIONALTEXT, 'COMMENT': items.COMMENT, 'startTimeForDefult': items.startTimeForDefult, 'matter_id': items.matter_id, 'matterguid': items.matterguid, 'time': startTimer, 'isStart': false };
    //             demoTimer.push(tempData);
    //             localStorage.removeItem('start_' + items.uuid);
    //             } else {
    //             let tempData2 = { 'startTime': items.startTime, 'endTime': items.endTime, 'uuid': items.uuid, 'WORKITEMGUID': items.WORKITEMGUID, 'ADDITIONALTEXT': items.ADDITIONALTEXT, 'COMMENT': items.COMMENT, 'startTimeForDefult': items.startTimeForDefult, 'matter_id': items.matter_id, 'matterguid': items.matterguid, 'time': items.time, 'isStart': false };
    //             demoTimer.push(tempData2);
    //             }
    //         });
    //         localStorage.setItem(timerDataId, JSON.stringify(demoTimer));
    //     }
    // }

    /**
     * This function is used to open the error popup
     * @returns error 
     */
    openPopUp() {
        this.isPopoupOpened = true;
        if (!this.CurrentURL) {
            this.CurrentURL = this.router.url;
        }

        if (
            this.CurrentURL == "/login" ||
            this.CurrentURL == "/end-user-licence-agreement" ||
            this.CurrentURL == "/privacy-policy" ||
            window.active
        ) {
            if (this.dialogRef) {
                this.dialogRef.close();
            }
            return true;
        }
        //seassion expire
        if (this.dialogRef) {
            return true;
        }
        const errorMessage = JSON.parse(localStorage.getItem("logoutmessage"));

        this.dialogRef = this.dialog.open(LogoutErrorComponent, {
            disableClose: true,
            width: "100%",
            data: errorMessage ? errorMessage : "",
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            this.isPopoupOpened = false;
            if (result == 0) {
                const currentUser = JSON.parse(
                    localStorage.getItem("currentUser")
                );
                const token = localStorage.getItem("session_token");
                if (currentUser && token) {
                    this.dialogRef = null;
                    window.location.reload();
                } else {
                    this.dialogRef = null;
                    this.router.navigate(["login"], {
                        queryParams: { returnUrl: this.CurrentURL },
                    });
                    this.dialog.closeAll();
                }
            } else if (result == 1) {
                const currentUser = JSON.parse(
                    localStorage.getItem("currentUser")
                );
                const token = localStorage.getItem("session_token");
                if (currentUser && token) {
                    this.dialogRef = null;
                } else {
                    // window.name = undefined;
                    this.behaviorService.browserLogout$.next(
                        this.CurrentURL.split("/")[1]
                    );
                    this.router.navigate(["login"], {
                        queryParams: { returnUrl: this.CurrentURL },
                    });
                }
            }
        });
    }
}
