<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main select_metter_main_div">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>

        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main dropdown">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="contactForm" id="contactForm" [formGroup]="contactForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div class="display-flex align-center flex-wrap">
                    <mat-form-field class="example" fxFlex="25" class="pr-12 fieldleft-wrap" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CONTACTTYPE,'has-warning':errorWarningData.Warning?.CONTACTTYPE}">
                        <mat-label>Contact Type</mat-label>
                        <mat-select formControlName="CONTACTTYPE" #value name="selected" (selectionChange)="changeType($event.value)" [(ngModel)]="nameSelected"  [disabled]="(_data?.selectedList && this._data?.selectedList == 'Company')?true:false">
                            <mat-option [value]="val.Name" *ngFor="let val of common">{{val.Name}}</mat-option>
                            <mat-option value="Multi Person">Multi Person</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTTYPE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACTTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-slide-toggle class="pr-4"  formControlName="ACTIVE" class="pb-fieldspace checktoggle-box">
                        Active
                    </mat-slide-toggle>
                    <!-- <mat-checkbox fxFlex="16" class="pr-4" formControlName="ACTIVE">Contact is Active</mat-checkbox> -->
                    <mat-form-field  class="pr-4  fieldright-wrap"fxFlex="26" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CONTACTID,'has-warning':errorWarningData.Warning?.CONTACTID}">
                        <mat-label>Contact Id</mat-label>
                        <input formControlName="CONTACTID" matInput (change)="changContactId($event)"  matTooltip="{{allToolTip_Msg.CONTACTID?.HOVER}}" > 
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTID?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACTID?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example pr-4" fxFlex="35" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CONTACTCATEGORY,'has-warning':errorWarningData.Warning?.CONTACTCATEGORY}">
                        <mat-label>Contact Category</mat-label>
                        <!-- <input matInput type="text" placeholder="Contact Category" [(ngModel)]="categoryText" (input)="changeText($event)">
                        <mat-select  formControlName="CONTACTCATEGORY" (selectionChange)="onCategoryChange($event)">
                            <mat-option *ngFor="let Classoption of ContactCategoryData" value="{{Classoption.LOOKUPFULLVALUE}}">
                                {{Classoption.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select> -->
                        <input type="text"
                            matInput
                            formControlName="CONTACTCATEGORY"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let Classoption of ContactCategoryData" value="{{Classoption.LOOKUPFULLVALUE}}">
                            {{Classoption.LOOKUPFULLVALUE}}
                        </mat-option>
                        </mat-autocomplete>
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{(toolTipList)?toolTipList?.CONTACTCATEGORY?.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTCATEGORY?.COPYVALUE:'')">file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CONTACTNAME,'has-warning':errorWarningData.Warning?.CONTACTNAME}">
                        <mat-label>Name</mat-label>
                        <textarea  class="min_height_textarea" (change)="onKeyUp($event)" #ContactInput1 name="CONTACTNAME" formControlName="CONTACTNAME" textarea matInput  matTooltip="{{allToolTip_Msg.CONTACTNAME?.HOVER}}" ></textarea>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACTNAME?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                 <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="min_height">
                    <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedtabIndex">
                        <mat-tab *ngIf="nameSelected==='Person' ||nameSelected==='Party' ||nameSelected==='Payee/Payor'" label="Person">
                            <app-person [contactForm]="contactForm" [editDataCompny]="editDataCompny" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-person>
                        </mat-tab>
                        <mat-tab *ngIf="nameSelected==='Company' || nameSelected =='Multi Person'" label="People">
                            <app-people [contactForm]="contactForm" [errorWarningData]="errorWarningData" [action]="action" (contactDetail)="backContactDetail($event)"  ></app-people>
                        </mat-tab>
                        <mat-tab *ngIf="nameSelected==='Multi Person'" label="Multi Person">
                            <app-multi-person [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-multi-person>
                        </mat-tab>
                        <mat-tab label="Ph/Web">
                            <app-ph [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-ph>
                        </mat-tab>
                        <mat-tab label="{{isCompanyAddress? 'Company Addr.' :'Addr.'}}"> 
                            <app-address [contactForm]="contactForm"  [checkedaddress]="_data"[errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg">
                            </app-address>
                        </mat-tab>
                        <mat-tab label="ID">
                            <app-id [contactForm]="contactForm"  [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-id>
                        </mat-tab>
                        <mat-tab label="Other" *ngIf="nameSelected!='Multi Person'">
                            <app-other [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-other>
                        </mat-tab>
   
                     
                        <mat-tab *ngIf="nameSelected==='Multi Person' ||  nameSelected==='Person'" label="Notes">
                            <app-notes [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-notes>
                        </mat-tab>
                        <mat-tab label="Rates" *ngIf=" '' | productType" >
                            <app-rates   [contactForm]="contactForm" [action]="action" [errorWarningData]="errorWarningData" [isEditRate]="isEditRate" (rateDetail)="rateDetailBack($event)"></app-rates>
                        </mat-tab>
                        <mat-tab *ngIf="nameSelected==='Person'" label="Associations">
                            <app-associations [action]="action"></app-associations>
                        </mat-tab>
                        <mat-tab label="Matters" [disabled]="action=='new' ||action=='duplicate'">
                            <app-matters [contactForm]="contactForm" [errorWarningData]="errorWarningData" [action]="action">
                            </app-matters>
                        </mat-tab>




                        <mat-tab *ngIf="nameSelected==='Company'" label="Company">
                            <app-company [contactForm]="contactForm" [errorWarningData]="errorWarningData">
                            </app-company>
                        </mat-tab>
                    </mat-tab-group>
                </div> -->


                <mat-expansion-panel *ngIf="nameSelected !=='Company' && nameSelected !=='Multi Person'" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Person</mat-expansion-panel-header >
                        <app-person  [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [editDataCompny]="editDataCompny" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg" [action]="action"></app-person>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="nameSelected ==='Company' || nameSelected==='Multi Person'" label="People in this Company" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >{{(nameSelected=='Multi Person')?'People in this Multi Person':'People in this Company'}}</mat-expansion-panel-header >
                        <app-people [testBool]='testBool'  [toolTipList]="toolTipList"  [contactForm]="contactForm" [errorWarningData]="errorWarningData" [action]="action" (contactDetail)="backContactDetail($event)"  ></app-people>
                </mat-expansion-panel>
                <mat-expansion-panel   *ngIf="nameSelected==='Multi Person'" label="People in Multi Person" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >{{(nameSelected=='Multi Person')?'Multi Person':'People in Multi Person'}}</mat-expansion-panel-header >
                        <app-multi-person  [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-multi-person>
                </mat-expansion-panel>

                <mat-expansion-panel label="Phone, Email, Web" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Phone, Email, Web</mat-expansion-panel-header >
                        <app-ph [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-ph>
                </mat-expansion-panel>

                <mat-expansion-panel  mat-align-tabs="start" fxLayout="column" *ngIf="!isCompanyAddress">
                    <mat-expansion-panel-header class="Bold_panel_header" >Address</mat-expansion-panel-header >
                        <app-address [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm"  [checkedaddress]="_data"[errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg">
                        </app-address>
                </mat-expansion-panel>
                <!-- *ngIf="nameSelected==='Company'" -->
                <mat-expansion-panel *ngIf="isCompanyAddress && nameSelected !== 'Company' && f.COMPANYCONTACTGUID.value && COMPANYADDRESSES" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Company Address</mat-expansion-panel-header >
                        <!-- <app-company [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [errorWarningData]="errorWarningData">
                        </app-company> -->
                        <app-address [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm"  [checkedaddress]="_data"[errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg">
                        </app-address>
                </mat-expansion-panel>
                

                <mat-expansion-panel label="Identification" mat-align-tabs="start" fxLayout="column" class="identi-contact">
                    <mat-expansion-panel-header class="Bold_panel_header identi-contact" >Identification</mat-expansion-panel-header >
                        <app-id [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm"  [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-id>
                </mat-expansion-panel>

                <mat-expansion-panel label="Bank Details" mat-align-tabs="start" fxLayout="column" class="identi-contact">
                    <mat-expansion-panel-header class="Bold_panel_header identi-contact" >Bank Details</mat-expansion-panel-header >
                        <app-bankdetails [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm"  [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-bankdetails>
                </mat-expansion-panel>

                <!-- <mat-expansion-panel  *ngIf="nameSelected==='Multi Person'" label="Birth, Death, Marital Status" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Birth, Death, Marital Status</mat-expansion-panel-header >
                        <app-other [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-other>
                </mat-expansion-panel> -->

                <mat-expansion-panel label="Override the rates for this Contact" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Override the rates for this Contact</mat-expansion-panel-header >
                        <app-rates  [testBool]='testBool'  [toolTipList]="toolTipList"  [contactForm]="contactForm" [action]="action" [errorWarningData]="errorWarningData" [isEditRate]="isEditRate" (rateDetail)="rateDetailBack($event)"></app-rates>
                </mat-expansion-panel>

                <mat-expansion-panel  *ngIf="nameSelected !=='Multi Person'" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Birth, Death, Marital Status, etc</mat-expansion-panel-header >
                        <app-other [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-other> 
                    </mat-expansion-panel>

                <mat-expansion-panel *ngIf="nameSelected === 'Person'"  label=" Multi Persons associated with" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" > Multi Persons associated with</mat-expansion-panel-header >
                        <app-associations [action]="action"></app-associations>
                </mat-expansion-panel>

                <mat-expansion-panel  [disabled]="action=='new' ||action=='duplicate'"  mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >{{('' | productType)?'Matters as Client or Correspondent':'Matters as Solicitor or Correspondent'}}</mat-expansion-panel-header >
                        <app-contact-matters [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [errorWarningData]="errorWarningData" [action]="action">
                        </app-contact-matters>
                </mat-expansion-panel>
                <!-- *ngIf="nameSelected==='Multi Person' ||  nameSelected==='Person'" for nots -->
                <mat-expansion-panel   mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" >Notes</mat-expansion-panel-header >
                        <app-notes [testBool]='testBool'  [toolTipList]="toolTipList" [contactForm]="contactForm" [errorWarningData]="errorWarningData" [allToolTip]="allToolTip_Msg"></app-notes>
                </mat-expansion-panel>
            </form>
        </div>
    </div>


    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="ondialogSaveClick()" [disabled]="isspiner" *ngIf="action !=='edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="ondialogSaveClick()" [disabled]="isspiner" *ngIf="action==='edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
<!-- </form> -->