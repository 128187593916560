import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, of, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-property-address',
  templateUrl: './property-address.component.html',
  styleUrls: ['./property-address.component.scss']
})
export class PropertyAddressComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() MatterPropertyDetailsForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() testBool;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  
  StreetType: any = [];
  StateData: any = [];
  @ViewChild('addressInput', { static: true }) addressInput!: ElementRef;
  searchData: any[] = [];
  constructor(public _mainAPiServiceService: MainAPiServiceService, public tooltipService: TooltipService,
    private toastr: ToastrService) { }

      /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let streetTypesLookups = JSON.parse(localStorage.getItem('street_types_lookups'));
    if (streetTypesLookups && streetTypesLookups != null) {
      this.StreetType = streetTypesLookups;
    } else {
      this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'street types' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.StreetType = responses.DATA.LOOKUPS;
          localStorage.setItem('street_types_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        }
      });
    }
    let statesLookups = JSON.parse(localStorage.getItem('states_lookups'));
    if (statesLookups && statesLookups != null) {
      this.StateData = statesLookups;
    } else {
      this.sub1 = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'states' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          localStorage.setItem('states_lookups', JSON.stringify(responses.DATA.LOOKUPS));
          this.StateData = responses.DATA.LOOKUPS;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.addressInput.nativeElement, 'keyup')
      .pipe(
        map((event: Event) => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        debounceTime(570),
        switchMap((dataVal: string) => {
          if (dataVal.length < 6) {
            return of(null);
          }
          const addressData = {
            "Action": "GetAddressMatch",
            "Data": {
              "Address": dataVal
            }
          };
          return this.findData(addressData); // Replace with your service method
        })
      )
      .subscribe(result => {
        if (result && result.DATA) {
          this.searchData = result.DATA.SUGGESTIONS;
        } else {
          this.searchData = null; // Clear suggestions if no result or result is null
        }
    });
  }
  
  findData(addressData): Observable<any> {
      return this._mainAPiServiceService.getSetData(addressData, 'utility')
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  setAutoField(data, index) { 
    const addressData = {
      "Action": "GetAddressDetail",
      "Data": {
        "PlaceId": data?.PLACEID
      }
    }

    this.sub2 = this._mainAPiServiceService.getSetData(addressData, 'utility').subscribe((res) => {
      if(res.CODE == 200 && res.STATUS === 'success') {
        const address = res.DATA;
        let COMBINEDFIRST = address.COMBINED.split(",")[0];
        let BLANKDISC = address.COMBINED.split(",")[1];
        setTimeout(() => {
          if (index == 1) {
            // 'COUNTRY': data.COUNTRY,
            this.MatterPropertyDetailsForm.patchValue({
              'ADDRESS1': COMBINEDFIRST,
              'STATE': address.STATE,
              'POSTCODE': address.POSTALCODE,
              'SUBURB': address.DISTRICT || BLANKDISC,
              'UNITNUMBER': address.UNIT,
              'STREETNUMBER': address.STEETNO,
              'STREETNAME': address.STREETNAME,
              'STREETTYPE': address.STREETTYPE
            })
          } 
        }, 5);
      }
    })
  }

  /**
   * This function is used to get the text data.
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    //  navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    
    navigator.clipboard.writeText(event)
  }
}
