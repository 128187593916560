import { BehaviorService } from '@_services/Behavior.service';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-template-layout',
  templateUrl: './template-layout.component.html',
  styleUrls: ['./template-layout.component.scss']
})
export class TemplateLayoutComponent implements OnInit {
  private _unsubscribeAll$: Subject<void> = new Subject();
  isShowSelectButton: boolean=false;
  isLoadingResults: boolean = false;
  Templatedata: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private behaviorService:BehaviorService) {
   }

  ngOnInit(): void {
    this.behaviorService.packs$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
          if (result != null) {
            if(result.KITGUID !=undefined && result.KITGUID !=null && result.KITNAME !=undefined && result.KITNAME !=null){
              this.isShowSelectButton=true;
            }else{
              this.isShowSelectButton=false;
            }
          }
        });
  }

  ngOnDestroy(): void {
    // this.behaviorService.packsitems(null);
    // this._unsubscribeAll$.next();
    // this._unsubscribeAll$.complete();
  }
}
