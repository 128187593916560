<div class="sort_main_sidebar" *ngIf="isShowsFlag">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header">
        <button mat-icon-button (click)="isShowsFlag = !isShowsFlag;PassFlagData(isShowsFlag)" class="setmargin">
            <mat-icon>menu</mat-icon>
        </button>
        <h3 class="one"><strong class="x"> Document Thumbnail</strong></h3>
    </div>
    
    
    <!-- / SIDEBAR HEADER -->
    <!-- SIDEBAR CONTENT -->
    <div class="p-16 setheight">
        <div class="content">
            <div class="file-details text-center" [@animate]="{value:'*',params:{delay:'200ms'}}">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <ng-container *ngIf="thumbnailData?.THUMBNAILBASE64">
                    <img [src]="'data:image/png;base64,' + thumbnailData?.THUMBNAILBASE64" [style.width.px]="thumbnailData?.THUMBNAILWIDTH"
                    class="img-width doc-thumbnail" *ngIf="!isLoadingResults">    
                </ng-container>
            </div>

        </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>

<div class="sort_main_sidebar" *ngIf="isShowsFlag == false">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
        <button mat-icon-button (click)="isShowsFlag = !isShowsFlag;PassFlagData(isShowsFlag)" class="setmargin">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    
    <!-- / SIDEBAR HEADER -->
    <!-- SIDEBAR CONTENT -->
    <div class="p-16">
        <!-- <div class="content" fusePerfectScrollbar>

            <div class="file-details text-center" [@animate]="{value:'*',params:{delay:'200ms'}}">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <img src="{{thumbnailData?.THUMBNAILURL}}" class="img-width doc-thumbnail" *ngIf='!isLoadingResults'>
            </div>

        </div> -->
        <div class="vertical-text-container">
            <div class="vertical-text">Expand to show document preview.</div>
          </div>
          
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>