import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
// import { isNull } from 'util';
import { SelectDocumentsComponent } from 'src/app/main/pages/document-register/select-documents/select-documents.component';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap, takeUntil, tap } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
import * as moment from 'moment';
import { PacketsDialogComponent } from '../../../globally-safecustody/packets/packets-dialog/packets-dialog.component';
import { ContactDialogComponent } from '../../../contact/contact-dialog/contact-dialog.component';
import { ContactSelectDialogComponent } from '@pages/contact/contact-select-dialog/contact-select-dialog.component';
import { MatterDialogComponent } from '@pages/time-entries/matter-dialog/matter-dialog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TimersService } from '@_services/timers.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import * as $ from "jquery";
import { Router } from '@angular/router';

@Component({
    selector: 'app-safe-custody-dialog',
    templateUrl: './safe-custody-dialog.component.html',
    styleUrls: ['./safe-custody-dialog.component.scss'],
    animations: fuseAnimations
})
export class SafeCustodyDialogeComponent implements OnInit, OnDestroy {
    SafeCustody: FormGroup;
    isLoadingResults: boolean = false;
    eventVal: any;
    errorWarningData: any = { "Error": [], 'Warning': [] };
    errorWarningDataArray: any = { "Error": [], 'Warning': [] };
    isspiner: boolean = false;
    action: any;
    dialogTitle: string;
    // mattername: any;
    FormAction: any;
    safecustodydata: any = [];
    cuurentmatter: any;
    documnettype: any = [];
    packetcustody: any = [];
    displayedColumns: any = ['MOVEMENTDATE', 'MOVEMENTTYPE', 'CONTACTNAME', 'REASON'];
    tabLable: any = "Check In";
    checkInData: any = [];
    highlightedRows: any;
    selectCheckin: any;
    isDisableCheckBtn: boolean = false;
    MOVEMENTTYPEData: any;
    GetSetMixRetry: boolean = true;
    theme_type = localStorage.getItem('theme_type');
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    barristerTitle: any = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Matter Num' : 'Short Name';
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    cardImageBase64: string;
    uploadBy: string = '';
    SafeCustodyADD: any = [];
    fileToUpload: any = [];
    windowNameId: any;
    toolTipList: any;
    toolTipPostion = "above";
    docGUID: any[] = [];
    searchMatter: any;
    exportdatavalue: any;
    searchData: any;
    matterlist: Observable<string[]>;
    mattername: any = [];
    selectedData: any = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    hide: any = false;
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    searchContactData;
    Contactlist: any = []
    currentEvent: any;
    Contactname: any = [];
    selectedContctData: any = [];
    docname;

    @ViewChild('MatterInput2', { static: false }) MatterInputContact: ElementRef<HTMLInputElement>;
    matterdata: any;
    searchContact: any;
    exportdatavalueOFContact: any;
    result: any;
    oldIsTrackId: any;
    getAllMatters: any;
    flexSize: number;
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
    CallHostlistner: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    isDisabled: boolean=false;
    isOwnerDisabled: boolean = false;
    private searchTerms = new Subject<string>();
    private searchTermsOwner = new Subject<string>();
    subscription: Subscription;
    subscriptionOwner: Subscription;
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        public datepipe: DatePipe,
        public dialogRef: MatDialogRef<SafeCustodyDialogeComponent>,
        public _matDialog: MatDialog,
        private Timersservice: TimersService,
        public globalFunService: GlobalFunctionsService,
        public closeDialogRef: MatDialogRef<ContactSelectDialogComponent>,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any, public toolbarServiceService: ToolbarServiceService) {
        localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
        this.action = data.safeCustodyData.action;
        if (this.action === 'new client' || this.action === 'new matter' || this.action === 'newlegal') {
            this.tabLable = "Check In";
            this.dialogTitle = 'New Safe Custody';
        } else if (this.action === 'edit' || this.action === 'editlegal') {
            this.tabLable = "History";
            this.dialogTitle = 'Update Safe Custody';
        } else if (this.action === 'copy') {
            this.dialogTitle = 'Duplicate Safe Custody';
            this.tabLable = "Check In";
        }
        this.behaviorService.SafeCustody$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.safecustodydata = result;
            }
        });
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.cuurentmatter = result;
        //   }
        // });
        // this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //     this.cuurentmatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));

        //     //Global matter selection.
        //     if (this.data.matterIs) {
        //         this.cuurentmatter = this.data.matterIs;
        //         this.mattername = [this.cuurentmatter.SHORTNAME + '--' + this.cuurentmatter.MATTER]
        //     };

        //     if (this.cuurentmatter) {
        //     } else {
        //         if (result) {
        //             this.cuurentmatter = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.cuurentmatter))
        //         }
        //     }
        // });
        /**
                 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
                 * @remarks
                 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
                 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
                 */
                this.behaviorService.clickMaterFromTimeLine$
                    .pipe(
                        tap(result => {
                            const materIDofTab = window.name.split("_");
                            const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                            if (result) {
                                this.cuurentmatter = result;
                            } else {
                                this.cuurentmatter = JSON.parse(localStorage.getItem(windowNameId || 'set_active_matters')) || result;
                                localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.cuurentmatter));
                            }
                           if(this.action != 'new client' && this.action != 'edit'){
                               this.mattername = [this.cuurentmatter.SHORTNAME + '--' + this.cuurentmatter.MATTER];
                           } 
                        })
                    )
                    .pipe(takeUntil(this._unsubscribeAll$))
                    .subscribe();

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'SafeCustodyDialogeComponent' && result['click'] && this.GetSetMixRetry && (result['data']['safe-custody'] || result['data']['safe-custody-packet'] || result['data']['safe-custody'])) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'SafeCustodyDialogeComponent' && result['click'] && this.GetSetMixRetry == false && result['data']['safe-custody']) {
                this.SaveSafeCustody();
            }
        });
    }
    editCheckinData(row) {
        this.selectCheckin = row;
        // this.MOVEMENTTYPEData =  row.MOVEMENTTYPE;
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    async ngOnInit() {
        this.SafeCustody = this._formBuilder.group({
            SAFECUSTODYGUID: [''],
            SAFECUSTODYPACKETGUID: [''],
            MATTERGUID: [""],
            SHORTNAME: ['', { updateOn: 'blur' }],
            CONTACTGUID: [''],
            CONTACTNAME: [''],
            DOCUMENTTYPE: [''],
            SAFECUSTODYDESCRIPTION: [''],
            DOCUMENTNAME: [''],
            ADDITIONALTEXT: [''],
            REMINDERDATE: [this.datepipe.transform(new Date(), 'dd/MM/yyyy')],
            // REMINDERDATETEXT: [new Date()],
            REMINDERDATETEXT: [''],
            CHECKINDATE: [this.datepipe.transform(new Date(), 'dd/MM/yyyy')],
            CHECKINDATETEXT: [new Date()],
            CHECKINCONTACTNAME: [''],
            //movement form
            SAFECUSTODYMOVEMENTGUID: [''],
            MOVEMENTDATE: [''],
            MOVEMENTDATETEXT: [''],
            MOVEMENTTYPE: [''],
            REASON: [''],
            MOVEMENTCONTACTNAME: [''],
        });

        if (this.action === 'new client' || this.action == 'edit') {
            this.flexSize = 48
        } else {
            this.flexSize = 50
        }
        if (this.action === 'new client') {
            this.isDisabled = true;
            setTimeout(() => {
                this.SafeCustody.controls['SHORTNAME'].disable();
                $("#mattersnew").addClass("menu-disabled");

            },1000);
            //this.SafeCustody.get("SHORTNAME").disable();
            this.SafeCustody.controls['CONTACTNAME'].setValue(this.data.safeCustodyData.result.CONTACTNAME);
            this.SafeCustody.controls['CONTACTGUID'].setValue(this.data.safeCustodyData.result.CONTACTGUID);
            this.Contactname = [this.data.safeCustodyData.result.CONTACTNAME];
            // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
            this.getPacket(this.f.CONTACTGUID.value);
        } else if (this.action === 'new matter') {

            this.SafeCustody.controls['SHORTNAME'].setValue(this.data.safeCustodyData.result.SHORTNAME);
            this.SafeCustody.controls['MATTERGUID'].setValue(this.data.safeCustodyData.result.MATTERGUID);
            this.SafeCustody.controls['CONTACTNAME'].setValue(this.data.safeCustodyData.result.CONTACTNAME);
            this.SafeCustody.controls['CONTACTGUID'].setValue(this.data.safeCustodyData.result.CONTACTGUID);
            this.mattername = [this.data.safeCustodyData.result.SHORTNAME + '--' + this.data.safeCustodyData.result.MATTER]
            this.Contactname = [this.data.safeCustodyData.result.CONTACTNAME]
            // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
            this.getPacket(this.f.CONTACTGUID.value);
        } else if (this.action === 'newlegal') {
            this.SafeCustody.controls['SHORTNAME'].setValue(this.cuurentmatter.SHORTNAME);
            this.SafeCustody.controls['MATTERGUID'].setValue(this.cuurentmatter.MATTERGUID);
            this.SafeCustody.controls['CONTACTNAME'].setValue(this.cuurentmatter.CONTACTNAME);
            this.SafeCustody.controls['CONTACTGUID'].setValue(this.cuurentmatter.CONTACTGUID);
            if(this.router.url == "/matter-details/safe-custody") {
                this.isDisabled = true;
                this.isOwnerDisabled = true;
                this.Contactname = [this.cuurentmatter.CONTACTNAME];
            }
            this.data.safeCustodyData.result = this.cuurentmatter;
            if (this.cuurentmatter.SHORTNAME !== '') {
                this.mattername = [this.cuurentmatter.SHORTNAME + '--' + this.cuurentmatter.MATTER]
            }
            // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
            this.getPacket(this.f.CONTACTGUID.value);
        }
        this.isLoadingResults = true;
        //await this.selectMatterFormSearch('no/Search@Data');
        // let documentTypeLookups = JSON.parse(localStorage.getItem('document_type_lookups'));
        // if (documentTypeLookups && documentTypeLookups != null) {
        //   this.documnettype = documentTypeLookups;
        // } else {
        this.Timersservice.GetLookupsData({ Action: 'getdata', Filters: { LookupType: 'Document Type' } }).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.documnettype = res.DATA.LOOKUPS;
                localStorage.setItem('document_type_lookups', JSON.stringify(res.DATA.LOOKUPS));
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        });
        // }
        if (this.action == 'edit' || this.action === 'copy' || this.action === 'editlegal') {
            this.isLoadingResults = true;
            this.getSafeCustody();
        }
        this.defultValidation();
        this.sub = this.SafeCustody.valueChanges.subscribe((form: any) => {
            this.changeForControl(form)
        });
        this.getContactDataUsingBehaviour();

        this.subscription = this.searchTerms.pipe(
        debounceTime(300), // Wait for 300ms pause in events
        distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
        switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
        ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success" && this.eventVal !== 'no/Search@Data') {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            } else if (this.eventVal == 'no/Search@Data') {
                this.getAllMatters = response.DATA.RECORDS;
            } else {
                console.log('here');
            }
        });

        this.subscriptionOwner = this.searchTermsOwner.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectContactFormSearch(term)) // Switch to new observable on new term
            ).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.exportdatavalueOFContact = response.DATA;
                    this.searchContactData = response.DATA.RECORDS;
                    if (response.DATA.RECORDS.length == 0) {
                        this.Contactlist.push({ 'CONTACTNAME': this.currentEvent });
                    } else {
                        this.Contactlist = this.searchContactData;
                    }
    
                } else if (response.CODE == 406 && response.STATUS == "error") {
                    this.toastr.error(response.MESSAGE);
                }
            });
    }

    /**
     * This function is used to get the Contact Data Using the behaviour data value.
     */
    getContactDataUsingBehaviour():void {
        this.behaviorService.newContactData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            localStorage.setItem('istrackid', 'SpandMoneyDialog');
            this.Contactlist = [];
            if (result) {
                localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
                this.SafeCustody.controls['CONTACTGUID'].setValue(result.CONTACTGUID);
                this.SafeCustody.controls['CONTACTNAME'].setValue(result.CONTACTNAME);
                this.data.safeCustodyData.result = result;
                this.Contactname = [result.CONTACTNAME];
                // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
                this.getPacket(this.f.CONTACTGUID.value);
            }
        })
    }



    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
        let result = this.MatterInputContact.nativeElement.value;
        if (result && result != null && result != undefined && this.CallHostlistner == false) {
            this.Contactdatashow(this.MatterInputContact.nativeElement);
            this.MatterInputContact.nativeElement.value = '';
            this.Contactlist = []
        }
    }

    /**
     * This function is used to change for the control data
     */
    changeForControl(formControl):void {
        //Error
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }
        //Warning
        const dubObj2 = { ...this.errorWarningDataArray.Warning };
        let element2 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj2)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element2[key];
                dubObj2[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj2 }
            } else if (!formControl[KEY]) {
                element2[key] = dubObj2[key];
            }
        }
    }

/**
 * This function is used to set the default validation data 
 */
    defultValidation():void {
        this.isLoadingResults = true;
        let PostFinalData: any = { Action: 'default', Data: {} };
        this._mainAPiServiceService.getSetData(PostFinalData, 'safe-custody').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                let valiData = response.DATA.VALIDATIONS;
                this.toolTipList = response.DATA.FIELDTIPS
                let errorData: any = [];
                let warningData: any = [];
                let tempError: any = [];
                let tempWarning: any = [];
                valiData.forEach(function (value) {
                    if (value.VALUEVALID == 'No') {
                        errorData.push(value.ERRORDESCRIPTION);
                        tempError[value.FIELDNAME] = value;
                    }
                    else if (value.VALUEVALID == 'Warning') {
                        tempWarning[value.FIELDNAME] = value;
                        warningData.push(value.ERRORDESCRIPTION);
                    }
                });
                this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
                this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to get the Packet data value.
     */
    getPacket(data):void {
        this.isLoadingResults = true;
        let postData = { "Action": "GetData", "Filters": { CONTACTGUID: data } };
        this.sub1 = this._mainAPiServiceService.getSetData(postData, 'safe-custody-packet').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.packetcustody = res.DATA.RECORDS;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            if (this.action === 'new' || this.action === 'new client' || this.action === 'edit' || this.action === 'new matter' || this.action === 'newlegal') {
                let SAFECUSTODYPACKETGUID = ''
                this.sub2 = this.behaviorService.setSafeCstodyPacketGuid$.subscribe((res) => {
                    if (res) {
                        SAFECUSTODYPACKETGUID = res.SAFECUSTODYPACKETGUID;
                        this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue(SAFECUSTODYPACKETGUID);
                    }
                });
            }

            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        });
    }

    /**
     * This function is used to get the move ment data value.
     */
    getmoveMentData(type):void {
        this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { SAFECUSTODYGUID: this.f.SAFECUSTODYGUID.value } }, 'safe-custody-movement').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (this.action == 'edit' || this.action === 'editlegal' || this.action === 'copy') {
                    if (response.DATA.RECORDS[0]) {
                        this.selectCheckin = response.DATA.RECORDS[0];
                        this.highlightedRows = response.DATA.RECORDS[0].SAFECUSTODYMOVEMENTGUID;
                        // this.MOVEMENTTYPEData = response.DATA.SAFECUSTODIES[0].MOVEMENTTYPE;
                        this.checkInData = new MatTableDataSource(response.DATA.RECORDS);
                        if (type == 'copy') {
                            this.SafeCustody.controls['CHECKINCONTACTNAME'].setValue(this.selectCheckin.CONTACTNAME);
                            if (this.selectCheckin.MOVEMENTDATE) {
                                let tempDate = this.selectCheckin.MOVEMENTDATE.split("/");
                                this.SafeCustody.controls['CHECKINDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
                                this.SafeCustody.controls['CHECKINDATE'].setValue(this.selectCheckin.MOVEMENTDATE);
                            }
                            this.tabLable = "Check In";
                            this.isDisableCheckBtn = true;
                        }
                    } else {
                        this.checkInData = new MatTableDataSource([]);
                    }
                }
            }
            this.isspiner = false;
        });
    }

    /**
     * This function is used to set the Data row
     */
    setDataRow(type) {
        this.isDisableCheckBtn = true;
        this.SafeCustody.controls['SAFECUSTODYMOVEMENTGUID'].setValue(this.selectCheckin.SAFECUSTODYMOVEMENTGUID);
        // this.SafeCustody.controls['MOVEMENTDATE'].setValue(this.selectCheckin.MOVEMENTDATE);
        // if (this.selectCheckin.MOVEMENTDATE) {
        //   let tempDate = this.selectCheckin.MOVEMENTDATE.split("/");
        //   this.SafeCustody.controls['MOVEMENTDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
        // }
        let tempDate = moment(new Date()).format("DD/MM/YYYY").split("/");
        this.SafeCustody.controls['MOVEMENTDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
        this.SafeCustody.controls['MOVEMENTDATE'].setValue(moment(new Date()).format("DD/MM/YYYY"));
        let MOVEMENTTYPE = (type == "Check Out") ? 'CheckOut' : type;
        this.SafeCustody.controls['MOVEMENTTYPE'].setValue(MOVEMENTTYPE);
        // this.SafeCustody.controls['REASON'].setValue(this.selectCheckin.REASON);
        // this.SafeCustody.controls['MOVEMENTCONTACTNAME'].setValue(this.selectCheckin.CONTACTNAME);
        this.SafeCustody.controls['REASON'].setValue('');
        this.SafeCustody.controls['MOVEMENTCONTACTNAME'].setValue(this.currentuser.UserName);
    }

    /**
     * This function is used to choosed the Date tab
     */
    choosedDateTab(type: string, event: MatDatepickerInputEvent<Date>) {
        this.SafeCustody.controls['MOVEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    get f() {
        return this.SafeCustody.controls;
    }

    /**
     * This function is used to Select the contact data
     */
    SelectContact():void {
        this.searchContactData = [];
        this.Contactlist = this.searchContactData;
        const dialogRef = this._matDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: '' });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
                this.SafeCustody.controls['CONTACTGUID'].setValue(result.CONTACTGUID);
                this.SafeCustody.controls['CONTACTNAME'].setValue(result.CONTACTNAME);
                this.data.safeCustodyData.result = result;
                // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
                this.Contactname = [result.CONTACTNAME];
                this.getPacket(this.f.CONTACTGUID.value);
            } else if (result.CODE == 406 && result.STATUS == "error") {
                this.toastr.error(result.MESSAGE);
            }
        });
    }

    /**
     * This function is used to choosed Date
     */
    choosedDate(type: string, event: MatDatepickerInputEvent<Date>):void {
        this.SafeCustody.controls['REMINDERDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to get the Person data value
     */
    PersonDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.SafeCustody.controls['CHECKINDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to Save SafeCustody Movement
     */
    SaveSafeCustodyMoveMent():void {
        let PostData: any = {
            "SAFECUSTODYMOVEMENTGUID": this.f.SAFECUSTODYMOVEMENTGUID.value,
            "SAFECUSTODYGUID": this.f.SAFECUSTODYGUID.value,
            "MOVEMENTDATE": this.f.MOVEMENTDATE.value,
            "MOVEMENTTYPE": this.f.MOVEMENTTYPE.value,
            "CONTACTNAME": this.f.MOVEMENTCONTACTNAME.value,
            "REASON": this.f.REASON.value,
        }
        this.isspiner = true;
        let PostFinalData: any = { FormAction: 'insert', VALIDATEONLY: true, DATA: PostData };
        this._mainAPiServiceService.getSetData(PostFinalData, 'safe-custody-movement').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.checkValidation(response.DATA.VALIDATIONS, PostFinalData, 'safe-custody-movement');
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.checkValidation(response.DATA.VALIDATIONS, PostFinalData, 'safe-custody-movement');
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.checkValidation(response.DATA.VALIDATIONS, PostFinalData, 'safe-custody-movement');
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to Save the Safe custody data values
     */
    SaveSafeCustody():void {

        
        // if(!this.f.SAFECUSTODYPACKETGUID.value) {
        //      this.toastr.error('Packet no. not selected');
        // }
        // if(!this.f.SAFECUSTODYPACKETGUID.value) {
        //     return
        // }
        this.GetSetMixRetry = false;
        let PostData: any = {
            "SAFECUSTODYPACKETGUID": this.f.SAFECUSTODYPACKETGUID.value,
            "CONTACTGUID": (this.Contactname != '') ? this.f.CONTACTGUID.value : '',
            "DOCUMENTTYPE": this.f.DOCUMENTTYPE.value,
            "SAFECUSTODYDESCRIPTION": this.f.SAFECUSTODYDESCRIPTION.value,
            "DOCUMENTNAME": this.f.DOCUMENTNAME.value,
            "ADDITIONALTEXT": this.f.ADDITIONALTEXT.value,
            "REMINDERDATE": this.f.REMINDERDATE.value,
            "CHECKINDATE": this.f.CHECKINDATE.value,
            "CHECKINCONTACTNAME": this.f.CHECKINCONTACTNAME.value,
            // "REMINDERGROUP": {
            //   "REMINDER": '',
            //   "REMINDERDATE": this.datepipe.transform(new Date(), 'dd/MM/yyyy'), //as per client need we comment this out
            //   "REMINDERTIME": ''
            // }
        }

        // this.SafeCustody.controls['REMINDERDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
        if (this.uploadBy == 'device') {
            PostData['FILEDATA'] = this.fileToUpload[0];
        } else {
            if (this.docGUID.length > 0) {
                PostData['DOCUMENTGUID'] = this.docGUID;
            }
        }
        PostData.MATTERGUID = (this.f.MATTERGUID.value == null) ? "" : this.f.MATTERGUID.value;
        // PostData.MATTERGUID = isNull(this.f.MATTERGUID.value) ? "" : this.f.MATTERGUID.value;
        if (this.action === 'edit' || this.action === 'editlegal') {
            this.FormAction = 'update';
            PostData.SAFECUSTODYGUID = this.f.SAFECUSTODYGUID.value;
        } else {
            this.FormAction = 'insert';
        }
        this.isspiner = true;
        let PostFinalData: any = { Action: this.FormAction, VALIDATEONLY: true, Data: PostData };
        this.sub3 = this._mainAPiServiceService.getSetData(PostFinalData, 'safe-custody').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.checkValidation(response.DATA.VALIDATIONS, PostFinalData, 'safe-custody');
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.checkValidation(response.DATA.VALIDATIONS, PostFinalData, 'safe-custody');
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.checkValidation(response.DATA.VALIDATIONS, PostFinalData, 'safe-custody');
            } else if (response.CODE == 432 && response.STATUS == 'error') {
                this.isspiner = false;
                let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check the validation data 
     */
    async checkValidation(bodyData: any, details: any, APIURL: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   }
        //   else if (value.VALUEVALID == 'Warning') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };

        // if (Object.keys(errorData).length != 0) {
        //   this.isspiner = false;
        //   this.toastr.error(errorData);
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.SafeCusodySave(details, APIURL);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // }
        // if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0)
        //   this.SafeCusodySave(details, APIURL);
        this.isspiner = false;
        await this.globalFunService.checkValidation(bodyData, details)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    // this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.SafeCusodySave(details, APIURL)
                    }
                }
            });
    }

    /**
     * This function is used to SafeCustody save data value.
     */
    SafeCusodySave(data: any, APIURL: any):void {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, APIURL).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                if (APIURL == 'safe-custody-movement') {
                    this.getSafeCustody();
                    this.isDisableCheckBtn = false;
                    this.tabLable = 'History';
                    //this.refreshPage();
                    this.getmoveMentData(false);
                } else {
                    if (this.action !== 'edit') {
                        this.toastr.success(' save successfully');
                    } else {
                        this.toastr.success(' update successfully');
                    }
                    this.dialogRef.close(true);
                }
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.isspiner = false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.isspiner = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * this function is used to select Doc file
     */
    selectDoc(event):void {
        this.uploadBy = "device";
        this.FileConvert64bit(event);
    }

    /**
     * This function is used to convert the File into64 bit
     */
    FileConvert64bit(fileInput: any):void {
        let file64bit = [];
        let filenames = [];
        let files = fileInput.target.files;
        for (let i = 0; i < files.length; i++) {
            filenames.push(files[i].name);
            var reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = function () {
                file64bit.push(reader.result);
            };
            reader.onerror = function (error) {
            };
        }
        this.SafeCustodyADD.DOCUMENTNAME = (filenames.join(', '));
        this.fileToUpload = file64bit;
    }

    /**
     * This function is used to Document Register the popup data value.
     */
    documentRegPopup():void {
        this.uploadBy = "document-register";
        const dialogRef = this._matDialog.open(SelectDocumentsComponent, {
            disableClose: true,
            panelClass: "Select-Document-dialog",
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            let filename = [];
            this.docGUID = [];
            result.forEach(document => {
                // filename.push(document.DOCUMENTNAME);
                this.docGUID.push(document.DOCUMENTGUID);
                // this.docname = document.DOCUMENTNAME.split("\\");
                this.docname = document.DOCUMENTNAME
                // filename.push(this.docname[this.docname.length - 1])
                filename.push(this.docname)
            });
            //  alert(this.SafeCustodyADD.DOCUMENTNAME)
            this.SafeCustodyADD.DOCUMENTNAME = filename.join(', ');
            // this.SafeCustodyADD.DOCUMENTNAME = this.docname[this.docname.length - 1];

        });
    }

    /**
     * This function is used to get the safecustody data values
     */
    getSafeCustody():void {
        this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { SAFECUSTODYGUID: this.safecustodydata.SAFECUSTODYGUID } }, 'safe-custody').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                let SAFECUSTODIESDATA = res.DATA.RECORDS[0];
                this.MOVEMENTTYPEData = res.DATA.RECORDS[0].STATUS;
                this.toolTipList = res.DATA.FIELDTIPS
                this.SafeCustody.controls['SAFECUSTODYGUID'].setValue(SAFECUSTODIESDATA.SAFECUSTODYGUID);
                this.SafeCustody.controls['MATTERGUID'].setValue(SAFECUSTODIESDATA.MATTERGUID);
                if (SAFECUSTODIESDATA.MATTERGUID != '')
                    this.SafeCustody.controls['SHORTNAME'].setValue(SAFECUSTODIESDATA.SHORTNAME);
                if (SAFECUSTODIESDATA.SHORTNAME !== '' || SAFECUSTODIESDATA.MATTERGUID !== '') {
                    this.mattername = [SAFECUSTODIESDATA.SHORTNAME + '--' + SAFECUSTODIESDATA.MATTER]
                }
                else
                    this.SafeCustody.controls['SHORTNAME'].setValue('No Matter');
                if (SAFECUSTODIESDATA.REMINDERDATE) {
                    let tempDate = SAFECUSTODIESDATA.REMINDERDATE.split("/");
                    this.SafeCustody.controls['REMINDERDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
                    this.SafeCustody.controls['REMINDERDATE'].setValue(SAFECUSTODIESDATA.REMINDERDATE);
                }
                this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue(SAFECUSTODIESDATA.SAFECUSTODYPACKETGUID);
                if (this.action === 'copy') {
                    this.SafeCustody.controls['CONTACTNAME'].setValue(this.data.safeCustodyData.result.CONTACTNAME);
                    this.SafeCustody.controls['CONTACTGUID'].setValue(this.data.safeCustodyData.result.CONTACTGUID);
                } else {
                    this.SafeCustody.controls['CONTACTGUID'].setValue(SAFECUSTODIESDATA.CONTACTGUID);
                    this.SafeCustody.controls['CONTACTNAME'].setValue(SAFECUSTODIESDATA.CONTACTNAME);
                    this.Contactname = [SAFECUSTODIESDATA.CONTACTNAME]
                }
                this.SafeCustody.controls['ADDITIONALTEXT'].setValue(SAFECUSTODIESDATA.ADDITIONALTEXT);
                this.SafeCustody.controls['DOCUMENTTYPE'].setValue(SAFECUSTODIESDATA.DOCUMENTTYPE);
                this.SafeCustody.controls['DOCUMENTNAME'].setValue(SAFECUSTODIESDATA.DOCUMENTNAME);
                this.SafeCustody.controls['SAFECUSTODYDESCRIPTION'].setValue(SAFECUSTODIESDATA.SAFECUSTODYDESCRIPTION);
                this.getPacket(this.f.CONTACTGUID.value);
                this.getmoveMentData(this.action);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        });
    }

    /**
     * This function is used to refresh the page 
     */
    refreshPage():void {
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { SAFECUSTODYGUID: this.safecustodydata.SAFECUSTODYGUID } }, 'safe-custody').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                let SAFECUSTODIESDATA = res.DATA.RECORDS[0];
                this.toolTipList = res.DATA.FIELDTIPS
                this.SafeCustody.controls['SAFECUSTODYGUID'].setValue(SAFECUSTODIESDATA.SAFECUSTODYGUID);
                this.SafeCustody.controls['MATTERGUID'].setValue(SAFECUSTODIESDATA.MATTERGUID);
                if (SAFECUSTODIESDATA.MATTERGUID != '')
                    this.SafeCustody.controls['SHORTNAME'].setValue(SAFECUSTODIESDATA.SHORTNAME);
                if (SAFECUSTODIESDATA.SHORTNAME !== '' || SAFECUSTODIESDATA.MATTERGUID !== '') {
                    this.mattername = [SAFECUSTODIESDATA.SHORTNAME + '--' + SAFECUSTODIESDATA.MATTER]
                }
                else
                    this.SafeCustody.controls['SHORTNAME'].setValue('No Matter');
                if (SAFECUSTODIESDATA.REMINDERDATE) {
                    let tempDate = SAFECUSTODIESDATA.REMINDERDATE.split("/");
                    this.SafeCustody.controls['REMINDERDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
                    this.SafeCustody.controls['REMINDERDATE'].setValue(SAFECUSTODIESDATA.REMINDERDATE);
                }
                this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue(SAFECUSTODIESDATA.SAFECUSTODYPACKETGUID);
                if (this.action === 'copy') {
                    this.SafeCustody.controls['CONTACTNAME'].setValue(this.data.safeCustodyData.result.CONTACTNAME);
                    this.SafeCustody.controls['CONTACTGUID'].setValue(this.data.safeCustodyData.result.CONTACTGUID);
                } else {
                    this.SafeCustody.controls['CONTACTGUID'].setValue(SAFECUSTODIESDATA.CONTACTGUID);
                    this.SafeCustody.controls['CONTACTNAME'].setValue(SAFECUSTODIESDATA.CONTACTNAME);
                }
                this.SafeCustody.controls['ADDITIONALTEXT'].setValue(SAFECUSTODIESDATA.ADDITIONALTEXT);
                this.SafeCustody.controls['DOCUMENTTYPE'].setValue(SAFECUSTODIESDATA.DOCUMENTTYPE);
                this.SafeCustody.controls['DOCUMENTNAME'].setValue(SAFECUSTODIESDATA.DOCUMENTNAME);
                this.SafeCustody.controls['SAFECUSTODYDESCRIPTION'].setValue(SAFECUSTODIESDATA.SAFECUSTODYDESCRIPTION);
                this.getmoveMentData(this.action);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        })
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    onKeyUpOwner(event: any): void {
        const searchValue = event.target.value;
        this.searchTermsOwner.next(searchValue);
    }

    /**
     * This function is used to select the matter for From the search the data value.
     */
    public selectMatterFormSearch(event) {
        this.eventVal = '';
        // let searchobj: any = { FastSearch: 'True', SearchString: event };
        let searchobj: any = {
            "Action": "GetData",
            "Filters": {
                FastSearch: 'True', SearchString: event
            }
        }

        if (event == 'no/Search@Data') {
            this.eventVal = event;
            this.isLoadingResults = true;
            //searchobj = { 'Active': '', 'FeeEarner': '', 'SearchString': '' };
            searchobj = {
                "Action": "GetData",
                "Filters": { 'Active': '', 'FeeEarner': '', 'SearchString': '' }
            }
        }
        return this._mainAPiServiceService.getSetData(searchobj, 'matter')

    }

    /**
     * this function is used to show the data. 
     */
    datashow(event):void {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SHORTNAME + '--' + data.MATTER]
                this.SafeCustody.controls['SHORTNAME'].setValue(null);
                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }

                this.SafeCustody.controls['MATTERGUID'].setValue(data.MATTERGUID);
                let getActiveMatter = this.getAllMatters.filter((e) => e.MATTERGUID == data.MATTERGUID);
                if (getActiveMatter && getActiveMatter[0]) {
                    localStorage.setItem('set_active_matters', JSON.stringify(getActiveMatter[0]));
                }
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }

    /**
     * this function is used to remove the matter data
     */
    remove(fruit: any):void {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.SafeCustody.controls['SHORTNAME'].setValue('');
            this.SafeCustody.controls['MATTERGUID'].setValue('');
        }
        let sortname = fruit[0].split('--')[0]
        this.rm(sortname)
    };

    /**
     * Thsi function is used to remove the data value.
     */
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    /**
     * This function is used to add the matter into the input
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        this.SafeCustody.controls['SHORTNAME'].setValue(null);
    };

    /**
     * this function is used to select the value data.
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.SafeCustody.controls['SHORTNAME'].setValue(null);
        }
    }

    async selectedmatterguid(data: any) {
        this.SafeCustody.controls['MATTERGUID'].setValue(data.MATTERGUID);
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        };

        let getActiveMatter = this.getAllMatters.filter((e) => e.MATTERGUID == data.MATTERGUID);
        if (getActiveMatter && getActiveMatter[0]) {
            localStorage.setItem('set_active_matters', JSON.stringify(getActiveMatter[0]));
        }

    }

    /**
     * This function is used to select the Matter
     */
    SelectMatter():void {
        const dialogRef = this._matDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, });
        dialogRef.afterClosed().subscribe(result => {
            this.SetOldistrackid('close');
            if (result) {
                this.SafeCustody.controls['SHORTNAME'].setValue(result.SHORTNAME);
                this.SafeCustody.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.mattername = [result.SHORTNAME + '--' + result.MATTER];
                localStorage.setItem('set_active_matters', JSON.stringify(result));
            }
        });
    }

    /**
     * This functionis used to open the packet data value.
     */
    OpenPacket(actionType):void {
        this.SetOldistrackid('open');
        let PacketPopData = {}

        if (actionType === 'new client' || actionType === 'new matter' || actionType === 'newlegal') {
            actionType = 'new';
        };

        if (actionType == 'new') {
            PacketPopData = {
                action: actionType, result: this.data
            }
        } else if (actionType == 'edit' || actionType == 'duplicate') {
            PacketPopData = {
                action: actionType, result: this.data, PacketsID: this.f.SAFECUSTODYPACKETGUID.value
            }
        }

        const dialogRef = this._matDialog.open(PacketsDialogComponent, {
            disableClose: true,
            panelClass: 'Packets-dialog',
            data: PacketPopData
        });

        dialogRef.afterClosed().subscribe(result => {
            this.SetOldistrackid('close');
            if (result) {
                // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
                this.getPacket(this.f.CONTACTGUID.value);
                // $('#refereshpacketsdata').click();
            }
        });
    }

    /**
     * This function is used to Set the Oldistrict id
     * @param type -data value.
     */
    SetOldistrackid(type):void {
        if (type === 'open') {
            this.oldIsTrackId = localStorage.getItem('istrackid');
        } else {
            localStorage.setItem('istrackid', this.oldIsTrackId);
        }
    }

    /**
     * This function is used to get the Contact new Id. 
     */
    getContactNewId() {
        let contecttId = '';
        let postData = {
            "Action": "GetContactId",
            "VALIDATEONLY": true,
            "Data": {},
        };

        return new Promise((response, reject) => {
            this.sub5 = this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
                if (res.CODE == 200) {
                    contecttId = res.DATA.CONTACTID;
                    response(contecttId);
                } else {
                    response(null);
                }
            });
        })
    };

    /**
     * This function is used to select the NewContect
     */
    async selectNewContect() {
        const dialogRef = await this._matDialog.open(ContactDialogComponent, {
            disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true, contect_details: '', flagNewConactMatter: true }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                if (result.CONTACTGUID) {
                    let contectIdIs = await this.getContactNewId();
                    this.behaviorService.MatterpopupContactData$.next(contectIdIs);
                    this.SafeCustody.controls['CONTACTGUID'].setValue(result.CONTACTGUID);
                    this.SafeCustody.controls['CONTACTNAME'].setValue(result.CONTACTNAME);
                    this.Contactname = [result.CONTACTNAME];
                    this.data.safeCustodyData.result = result;
                    // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
                    this.getPacket(result.CONTACTGUID);
                    // this.closeDialogRef.close(result);
                } else {
                    this.behaviorService.MatterpopupContactData$.next(null)
                }
            }
        });
    }

/**
 * This function is used to select the contact form the search data value.
 * @param event -event data value.
 */
    selectContactFormSearch(event) {
        this.currentEvent = '';
        this.Contactlist = [];
        if (event.length == 0) {
            this.Contactlist = [];
        }
        // let searchobj = { FastSearch:'True', Search: event }
        let payload = {
            "ACTION": "GetData",
            "FILTERS": {
                "FASTSEARCH": 1,
                "SEARCH": event
            },
        }

        this.currentEvent = event;
        return this._mainAPiServiceService.getSetData(payload, 'contact')
    }

    /**
     * This function is used to Contact data show
     */
    Contactdatashow(event):void {
        this.Contactname = [];
        this.CallHostlistner = true;
        if (this.exportdatavalueOFContact && this.exportdatavalueOFContact.RECORDS && this.exportdatavalueOFContact.RECORDS.length != 0) {
            this.result = this.exportdatavalueOFContact.RECORDS[0]
            this.SafeCustody.controls['CONTACTNAME'].setValue(null);
            if (event.input) {
                event.input.value = '';
            } else {
                this.MatterInputContact.nativeElement.value = '';
            }
            if (this.result) {
                localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
                this.SafeCustody.controls['CONTACTGUID'].setValue(this.result.CONTACTGUID);
                this.SafeCustody.controls['CONTACTNAME'].setValue(this.result.CONTACTNAME);
                this.Contactname = [this.result.CONTACTNAME];
                this.data.safeCustodyData.result = this.result;
                // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
                this.getPacket(this.f.CONTACTGUID.value);
            }

        } else {
            this.toolbarServiceService.ContactsDialog('new', this.MatterInputContact.nativeElement.value);
            this.Contactname = [];
            this.Contactlist = [];
            this.MatterInputContact.nativeElement.value = '';
        }
        this.CallHostlistner = false;

    }

    /**
     * This function is used to remove the Contact data values.
     * @param fruit -data values
     */
    removeContact(fruit: any):void {

        const index = this.Contactname.indexOf(fruit);
        if (index >= 0) {
            this.Contactname.splice(index, 1);
            // this.contactForm.controls['COMPANYCONTACTGUID'].setValue('');
            //this.Deliverable = [];
        }
        let sortname = fruit.split('-')[0]
        this.MatterInputContact.nativeElement.value = '';
        this.rmContactdata(sortname)
    };

/**
 * This function is used to remove the contact data value.
 */
    rmContactdata(sortname):void {
        this.selectedContctData.forEach((element: any, index: any) => {
            if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedContctData.splice(index, 1);
            }
        })
        this.SafeCustody.controls['CONTACTNAME'].setValue('');
        this.SafeCustody.controls['CONTACTGUID'].setValue('');
    }

    /**
     * This function is used to add the Contact data value.
     */
    addContact(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.Contactlist.forEach((element: any) => {
            if (element.CONTACTNAME == event.value) {
                // Add our fruit
                const array3 = this.Contactname;
                if (value) {
                    if (this.Contactname.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.Contactname = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        this.SafeCustody.controls['CONTACTNAME'].setValue(null);
    };

    /**
     * This function is used to select the contact data value.
     * @param result -result data values
     */
    selectedContact(result: any):void {
        this.MatterInputContact.nativeElement.value = '';
        if (Object.keys(result).length == 1) {
            this.toolbarServiceService.ContactsDialog('new', result.CONTACTNAME);
            this.Contactname = [];
            this.Contactlist = [];
        } else {
            if (result) {
                localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
                this.SafeCustody.controls['CONTACTGUID'].setValue(result.CONTACTGUID);
                this.SafeCustody.controls['CONTACTNAME'].setValue(result.CONTACTNAME);
                this.Contactname = [result.CONTACTNAME];
                this.data.safeCustodyData.result = result;
                // this.SafeCustody.controls['SAFECUSTODYPACKETGUID'].setValue('');
                this.getPacket(this.f.CONTACTGUID.value);
            }
        }
    }

    ngOnDestroy(): void {
        this.behaviorService.newContactData$.next(null);
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.subscriptionOwner?.unsubscribe();
        this.subscription?.unsubscribe();
    }

}
