import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ContactCorresDetailsComponent } from '../../contact/contact-corres-details/contact-corres-details.component';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { MatterDialogComponentForTemplate } from '../matter-dialog/matter-dialog.component';
import { SelectEmailPopupComponent } from '../packs/select-email-popup/select-email-popup.component';
import { MatterFolderDailogComponent } from 'src/app/layout/components/toolbar/matter-folder-dailog/matter-folder-dailog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SocketService } from '@_services/socket.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { TableColumnsService } from '@_services/table-columns.service';
@Component({
    selector: 'app-generate-document-popup',
    templateUrl: './generate-document-popup.component.html',
    styleUrls: ['./generate-document-popup.component.scss']
})
export class GenerateDocumentPopupComponent implements OnInit, OnDestroy {
    btnText = '';
    progress: any = 5;
    fileExtention: any;
    SafeCustodyData: any;
    isspiner: boolean = false;
    isLoadingResults: boolean = false;
    isPDFLoadingResults: boolean = false;
    isPDFEmailLoadingResults: boolean = false;
    IsAuth: boolean = false;
    filefolder_Name: any;
    PdfName: any;
    base_url: any;
    documentDataIndex: any = 99999;
    documentsData: any = [];
    xFxaxVal: any = 60;
    yflaxCal: any = 40;
    filefolderUrlDes: any;
    PDffilerUrlDes: any;
    whichTypeTemplate: string;
    isGenerating: boolean = false;
    arguParam: any;
    // webSocket = new WebSocket(environment.MainSocketUrl);
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    // For retry Variable
    ApiRegRetryparam1: any;
    ApiRegRetryparam2: any;
    header2: boolean = false;
    header1: boolean = false;
    showPDFButton: boolean = true;
    windowNameId: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    isAllClose: boolean = false
    invoicedataDataContextGuid: any
    OpenDocumentNewPage: boolean = false;
    SHOWVIEWALL:boolean=false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    count: any=0;
    notShow: boolean=false;
    showmattercontact=false;
    Totallength: any=0;
    SINGLECOUNT: any=0;
    isShowRegenerateAll: boolean = false;
    trackingData: { [key: string]: any } = {}; // Store tracking info
    UNIQUEID=[];
    DocumentGenerationType='SINGLE';
    hideGloableprogressbarData: boolean=false;
    SingMultidataObject: any;
    SingleMultidataIndex: number;
    ReportData: any;
    ReportData1: any;
    GloableFlag: string="";
    isMulti: any;
    GloableData: any;
    selectMatterForRefreshReportData: any;
    uniqueId: string;
    selectMatterContactData: any[];
    private completedTrackingGuids = new Set<string>(); // Store completed TRACKINGGUIDs
    constructor(
        public dialog: MatDialog,
        public router: Router,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        public dialogRef: MatDialogRef<GenerateDocumentPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private socketService: SocketService,
        private sanitizer: DomSanitizer,
        public _matDialog: MatDialog,
        private globalFunService: GlobalFunctionsService,
        private TableColumnsService: TableColumnsService
        // private data:TemplateComponent
    ) {
if(_data.DocumentGenerationType != undefined && _data.DocumentGenerationType != null && _data.DocumentGenerationType != '' && _data.DocumentGenerationType == 'MULTY'){
    this.DocumentGenerationType=_data.DocumentGenerationType;
    console.log(this.DocumentGenerationType);
}
        this.showmattercontact=this._data.showmattercontact;
        // this.socketService.openWSConnection(this.currentUser.SessionToken, 'TemplateGenerateStatus',)
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        this.socketService.getWebSocketDocumentGenerationSubject().subscribe((event) => {
            if (event) {
                this.progress = event.PROGRESS;
                this.btnText = event.DESCRIPTION;

            }

            if(this.DocumentGenerationType == 'MULTY'){
                if (event?.TRACKINGGUID) {
                if (event.DESCRIPTION == "Generation Complete" || event.DESCRIPTION == 'User Input Required') {

                    this.completedTrackingGuids.add(event.TRACKINGGUID); // Mark as completed
                   // This is for remove the object when document is generate successfully.
        this.trackingData = Object.keys(this.trackingData)
          .filter(key => key !== event.TRACKINGGUID)
          .reduce((obj, key) => {
            obj[key] = this.trackingData[key];
            return obj;
          }, {} as { [key: string]: any });
    
          // START
    
          let response=event.APIRESPONSE;
        //   if(_data?.GloableFlag.trim() == 'multiinvoicedata'){
              if (response?.CODE == 200 && response?.STATUS == "success") {
                let isPdf:boolean=false;
                this.SINGLECOUNT++
                this.filefolderUrlDes = response?.DATA.DOCUMENTS[0].DESCRIPTION;
                let singledata=response?.DATA.DOCUMENTS[0];
                if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                let SplitData=singledata.ORIGFILENAME.split(".");
                if(SplitData.length >1){
                    if(SplitData[SplitData.length-1] == 'pdf'){
                        isPdf=true;
                    }else{
                        isPdf=false;
                    }
                }
                }
                response.DATA.DOCUMENTS[0].isPdf = isPdf;
                this.isAllClose = true;
                 response?.DATA.DOCUMENTS.forEach(element => {
                    this.documentsData.forEach(el => {
                        if(el.MATTERGUID == element.MATTERGUID){
                            el.DESCRIPTION = element.DESCRIPTION;
                            el.DESKTOPLINK = element.DESKTOPLINK;
                            el.DOCUMENTGUID = element.DOCUMENTGUID;
                            el.DOCUMENTTYPE = element.DOCUMENTTYPE;
                            el.DOWNLOAD = element.DOWNLOAD;
                            el.FILENAME = element.FILENAME;
                            el.MATTERGUID = element.MATTERGUID;
                            el.ORIGFILENAME = element.ORIGFILENAME;
                            el.ORIGFILENAMEONLY = element.ORIGFILENAMEONLY;
                            el.isShowSuccessData = true;
                            el.MATTERCONTACT=[];
                            el.isPdf = element.isPdf
                             console.log(el.MATTERGUID,response?.DATA.DOCUMENTS[0].MATTERGUID)
                            // element.MATTERCONTACT=[];
                            // element.SHORTNAME=el.SHORTNAME;
                            // element.MATTER=el.MATTER;
                            // element.INVOICECODE=el.INVOICECODE;
                            // element.CONTEXTGUID=el.ContextGuid;
                            // element.MATTERGUID=el.MATTERGUID;
                            // element.isShowSocketProgress=false;
                            // element.MESSAGE=''
                            // this.documentsData.push(element);
                        }
                    });
                   
                });

                setTimeout(() => {
                    this.documentsData = this.documentsData.filter(obj => 
                        !(obj.MATTERGUID == response?.DATA.DOCUMENTS?.[0]?.MATTERGUID && obj.MATTERCONTACT?.length > 0)
                    );
                    console.log('185',this.documentsData);
    
                        let DATA=this.documentsData.filter(data=> data.MATTERCONTACT.length > 0);
                        if(DATA.length > 0){
                            this.isShowRegenerateAll=true;
                        }else{
                            this.isShowRegenerateAll=false;
                        }     
                   
                },500);
                this.invoicedataDataContextGuid = '';
                // this.toastr.success('TemplateGenerate successfully');
                // console.log('TemplateGenerate successfully');
            }else {
                if (response?.CODE == '504') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false)
                    this.toastr.error("Server TimeOut!")
                    return;
                }
                if (response?.CODE == 432 || response?.CODE == '432') {
                    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                    this.dialogRef.close();
                    let payload = {
                        "Action": "OAuth2Start",
                        "VALIDATEONLY": false,
                        "Data": {
                            "USERGUID": this.currentUser.UserGuid,
                            "AuthType": "NetDrive"
                        }
                    }
                    this._mainAPiServiceService
                    .getSetData(payload, 'oauth-connections')
                    .subscribe((response) => {
                        if(response.CODE == 200 && response.STATUS == "success") {
                            window.open(
                                response.DATA[0].AuthorizationUrl,
                                "targetWindow",
                                `toolbar=no,
                                location=no,
                                status=no,
                                menubar=no,
                                scrollbars=yes,
                                resizable=yes,
                                width=800,
                                height=600`
                            );
                        }
                    });
                }

                if(response?.CODE == "422" || response?.CODE == 422){
                        // this.documentsData.push({MESSAGE:response.MESSAGE});
                        //   console.log(this.documentsData)

                this.documentsData.forEach(element => {
                    if(element.MATTERGUID == response?.DATA?.DOCUMENTS[0]?.MATTERGUID){   
                        element.MESSAGE=response.MESSAGE
                        element.isShowSocketProgress=false;
                        // this.documentsData.push(element);
                    }
                });
                console.log('247',this.documentsData);
                }
                this.isLoadingResults = false;
        
                if (response?.DATA && !response?.DATA?.VALIDATEONLY) {
        
                    if (response?.CODE == 431) {
        
                        this._data.multiSelect.forEach(element => {
                            if(element.MATTERGUID == response?.DATA.MATTERGUID){
                                this.invoicedataDataContextGuid =element.ContextGuid
                            }
                        });
                        if(this._data?.showmattercontact){
                            this.IsAuth = false;
                        }else{
                            this.IsAuth = true;
                        }
                           
                        let currentMatter: any;
                      
                        this.behaviorService.MatterData$.subscribe(result => {
                            const materIDofTab = window.name.split("_");
                            const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                            currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                            if (currentMatter) {
                            } else {
                                if (result) {
                                    currentMatter = result;
                                    localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                                }
                            }
                        });
        
                        let currentInvoiceMatter: any;
                        this.behaviorService.matterInvoice$.subscribe(result => {
        
                            if (result) { currentInvoiceMatter = result; }
                        });
                        
                        let MatterGuid = (this.ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter?.MATTERGUID : this.ReportData.Context == "Invoice" ? currentMatter?.MATTERGUID : this.ReportData?.ContextGuid;
                        if (response?.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response?.DATA.MATTERGUID != "") {
                            MatterGuid = response?.DATA.MATTERGUID;
                        }
        
                        // As per the clients new changes we use the condition {NOTE -> old code is in else block } 
        
                        if(this._data.showmattercontact){
                            this.isShowRegenerateAll=true;
        
                            this._mainAPiServiceService.getSetData({Action: "GetData", Filters: { MatterGuid: MatterGuid }}, 'matter-contact').subscribe(res => {
                                if (res.STATUS == "success" && res.CODE == 200) {
                                    this.isShowRegenerateAll=true;
                                    //this.filefolderUrlDes = response.DATA.REQUIREDFIELDS[0].DESCRIPTION;
                                    
                                    this.documentsData.forEach(element => {
                                            if(element.MATTERGUID == response?.DATA.MATTERGUID){
                                                element.MATTERCONTACT=res.DATA.RECORDS;
                                                element.isShowSuccessData = false
                                                // element.SHORTNAME=element.SHORTNAME;
                                                // element.MATTER=element.MATTER;
                                                // element.INVOICECODE=element.INVOICECODE;
                                                // element.CONTEXTGUID=element.ContextGuid;
                                                // element.MATTERGUID=element.MATTERGUID;
                                                // element.MESSAGE=''
                                                element.isShowSocketProgress=false;
                                               // this.documentsData.push(element);
                                            }
                                        });
                                       
                                        console.log('317',this.documentsData);
                                    this.isLoadingResults = false;
                                    this.notShow=true;
                                 
                                } else {
                                  this.toastr.error(res.MESSAGE);
                                  this.isLoadingResults = false;
                                }
                              });
        
                        }
                        // else{
                        //     const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                        //         disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                        //     });
                        //     dialogRefMC.afterClosed().subscribe(result => {
        
                        //         if (result) {
                        //             this.IsAuth = false
                        //             this.ReportData.ContextGuid = this.invoicedataDataContextGuid
                        //             this.ReportData.CHECKFORPROMPTS = false;
                        //             this.ReportData.ContactGuid = result.PERSONGUID;
                        //             this.ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                        //           //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        //             this.multiinvoicedata(this.ReportData, 'false');
                        //           //  reject()
                        //         } else {
                        //             this.isLoadingResults = false;
                        //         }
                        //     });
                        //     this.isLoadingResults = false;
                        // }
                    
                    }
                };
        
                if (response?.DATA.VALIDATEONLY) {
        
                    this.globalFunService.checkValidation(response?.DATA.VALIDATEONLY, this.ReportData1)
                        .subscribe(data => {
                            if (data && data.callback) {
                              
                            }
                        })
                }
        
            }
        //   }
        //   else if(_data?.GloableFlag.trim() == 'getRefreshData'){
        //     if (response?.CODE == 200 && response?.STATUS == "success") {
        //         if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM' || this._data.Type == 'LEXON FORM') {
        //             let isPdf:boolean=false;
        //             this.toastr.success('TemplateGenerate successfully');
        //             this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
        //             let singledata=response.DATA.DOCUMENTS[0];
        //                 if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
        //                 let SplitData=singledata.ORIGFILENAME.split(".");
        //                 if(SplitData.length >1){
        //                     if(SplitData[SplitData.length-1] == 'pdf'){
        //                         isPdf=true;
        //                     }else{
        //                         isPdf=false;
        //                     }
        //                 }
        //                 }
        //                 response.DATA.DOCUMENTS[0].isPdf=isPdf
        //             if (this.isMulti) {
        //                 this.documentsData.push(response.DATA.DOCUMENTS[0])
        //             } else {
        //                 this.documentsData = response.DATA.DOCUMENTS;
        //                 if (this.OpenDocumentNewPage == true) {
        //                     var url = `${this.base_url}${response.DATA.DOCUMENTS[0].FILENAME}`;
        //                     window.open(url);
        //                     this.dialogRef.close();
        //                 }
        //             }
        //         }else{
        //             if(this._data.showmattercontact){
        //                 let isPdf:boolean=false;
        //                 this.SINGLECOUNT++;
        //                 let singledata=response?.DATA.DOCUMENTS[0];
        //                 if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
        //                 let SplitData=singledata.ORIGFILENAME.split(".");
        //                 if(SplitData.length >1){
        //                     if(SplitData[SplitData.length-1] == 'pdf'){
        //                         isPdf=true;
        //                     }else{
        //                         isPdf=false;
        //                     }
        //                 }
        //                 }
        //                 console.log(this.GloableData);

        //                 this.documentsData.forEach(element => {
        //                     if(element.MATTERGUID == this.GloableData.MATTERGUID){
        //                         element.MATTERCONTACT=[];
        //                         element.DESCRIPTION=singledata.DESCRIPTION;
        //                         element.DESKTOPLINK=singledata.DESKTOPLINK;
        //                         element.DOCUMENTGUID=singledata.DOCUMENTGUID;
        //                         element.DOCUMENTTYPE=singledata.DOCUMENTTYPE;
        //                         element.DOWNLOAD=singledata.DOWNLOAD;
        //                         element.FILENAME=singledata.FILENAME;
        //                         element.MATTERGUID=singledata.MATTERGUID;
        //                         element.ORIGFILENAME=singledata.ORIGFILENAME;
        //                         element.ORIGFILENAMEONLY=singledata.ORIGFILENAMEONLY;
        //                         element.isShowSocketProgress=false; // This is for hide the progressbar of the perticular socket data;
        //                         element.isPdf=isPdf;
        //                     }
        //                 });
        //                 let DATA=this.documentsData.filter(data=> data.MATTERCONTACT.length > 0);
        //                 if(DATA.length > 0){
        //                     this.isShowRegenerateAll=true;
        //                 }else{
        //                     this.isShowRegenerateAll=false;
        //                 }

        //                 this.hideGloableprogressbarData=false;
        //             }
        //         }

        //         this.isLoadingResults = false;
        //         this.isGenerating = false;
        //         // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " TemplateGenerateStatus");
        //         // this.webSocket.close();
        //         $('#refreshWorkInprogress').click();
        //         // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
        //     } else if (response?.CODE == 420) {
        //         this.isLoadingResults = false;
        //         this.dialogRef.close();
        //     } else if (response?.CODE == 428) {
        //         this.isLoadingResults = false;
        //         this.IsAuth = true;
        //         this.arguParam = '';
        //         this.selectMatterForRefreshReportData.CHECKFORPROMPTS = true;
        //         let payload = {
        //             "Action": "OAuth2Start",
        //             "VALIDATEONLY": false,
        //             "Data": {
        //                 "USERGUID": this.currentUser.UserGuid,
        //                 "AuthType": "NetEmail"
        //             }
        //         }
        //         this._mainAPiServiceService
        //         .getSetData(payload, 'oauth-connections')
        //         .subscribe((response) => {
        //             if(response?.CODE == 200 && response?.STATUS == "success") {
        //                 window.open(
        //                     response?.DATA[0].AuthorizationUrl,
        //                     "targetWindow",
        //                     `toolbar=no,
        //                     location=no,
        //                     status=no,
        //                     menubar=no,
        //                     scrollbars=yes,
        //                     resizable=yes,
        //                     width=800,
        //                     height=600`
        //                 );
        //             }
        //         });
        //     } else if (response?.CODE == 432) {
        //         this.isLoadingResults = false;
        //         this.IsAuth = true;
        //         this.arguParam = '';
        //         this.selectMatterForRefreshReportData.CHECKFORPROMPTS = true;
        //         let payload = {
        //             "Action": "OAuth2Start",
        //             "VALIDATEONLY": false,
        //             "Data": {
        //                 "USERGUID": this.currentUser.UserGuid,
        //                 "AuthType": "NetDrive"
        //             }
        //         }
        //         this._mainAPiServiceService
        //         .getSetData(payload, 'oauth-connections')
        //         .subscribe((response) => {
        //             if(response.CODE == 200 && response.STATUS == "success") {
        //                 window.open(
        //                     response.DATA[0].AuthorizationUrl,
        //                     "targetWindow",
        //                     `toolbar=no,
        //                     location=no,
        //                     status=no,
        //                     menubar=no,
        //                     scrollbars=yes,
        //                     resizable=yes,
        //                     width=800,
        //                     height=600`
        //                 );
        //             }
        //         });
        //     } else if (response?.CODE == 431) {
        //         this.IsAuth = true;
        //         let currentMatter: any;
        //         this.behaviorService.MatterData$.subscribe(result => {
        //             const materIDofTab = window.name.split("_");
        //             this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //             currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        //             if (currentMatter) {
        //             } else {
        //                 if (result) {
        //                     currentMatter = result;
        //                     localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
        //                 }
        //             }
        //         });

        //         let currentInvoiceMatter: any;
        //         this.behaviorService.matterInvoice$.subscribe(result => {
        //             if (result) { currentInvoiceMatter = result; }
        //         });
        //         let MatterGuid = (this.selectMatterForRefreshReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : this.selectMatterForRefreshReportData.Context == "Invoice" ? currentMatter.MATTERGUID : this.selectMatterForRefreshReportData.ContextGuid;
        //         if (response?.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response?.DATA.MATTERGUID != "") {

        //             MatterGuid = response?.DATA.MATTERGUID;
        //         }

        //         const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
        //             disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
        //         });
        //         dialogRefMC.afterClosed().subscribe(result => {
        //             if (result) {
        //                 this.selectMatterForRefreshReportData.CHECKFORPROMPTS = false;
        //                 this.selectMatterForRefreshReportData.ContactGuid = result.PERSONGUID;
        //                 this.selectMatterForRefreshReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
        //                 this.socketService.disconnectWebSocketDocumentGeneration(this.uniqueId);
        //                 this.selectMatterForRefresh(this.selectMatterForRefreshReportData, 'false');
        //             } else {
        //                 this.isLoadingResults = false;
        //             }
        //         });
        //         this.isLoadingResults = false;
        //     } else if (response?.STATUS == 'error' || response?.CODE == '422') {
        //         this.isLoadingResults = false;
        //         this.dialogRef.close(false);
        //       //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
        //     } else if (response?.MESSAGE == 'Not logged in') {
        //         this.isLoadingResults = false;
        //         this.dialogRef.close(false);
        //         //this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
        //     }

        //   }else if(_data?.GloableFlag.trim() == 'reGenerateDocument'){
        //     if (response?.CODE == 200 && response?.STATUS == "success") {
        //         // try {
        //             if (this._data.showmattercontact) {
        //                 let isPdf:boolean=false;
        //                 this.SINGLECOUNT++;
        //                 let singledata = response?.DATA.DOCUMENTS[0];
        //                 if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
        //                     let SplitData=singledata.ORIGFILENAME.split(".");
        //                     if(SplitData.length >1){
        //                         if(SplitData[SplitData.length-1] == 'pdf'){
        //                             isPdf=true;
        //                         }else{
        //                             isPdf=false;
        //                         }
        //                     }
        //                     }
        //                 this.documentsData.forEach(element => {
        //                     // this.selectMatterContactData.forEach(data => {
        //                         if (element.MATTERGUID === singledata.MATTERGUID) {
        //                             element.MATTERCONTACT = [];
        //                             element.DESCRIPTION = singledata.DESCRIPTION;
        //                             element.DESKTOPLINK = singledata.DESKTOPLINK;
        //                             element.DOCUMENTGUID = singledata.DOCUMENTGUID;
        //                             element.DOCUMENTTYPE = singledata.DOCUMENTTYPE;
        //                             element.DOWNLOAD = singledata.DOWNLOAD;
        //                             element.FILENAME = singledata.FILENAME;
        //                             element.MATTERGUID = singledata.MATTERGUID;
        //                             element.ORIGFILENAME = singledata.ORIGFILENAME;
        //                             element.ORIGFILENAMEONLY = singledata.ORIGFILENAMEONLY;
        //                             element.isPdf=isPdf;
        //                             element.isShowSocketProgress=false;
                                    
        //                         }
        //                     // });
                            
        //                 });

        //                 let DATA = this.documentsData.filter(item => item.MATTERCONTACT.length > 0);
        //                 this.isShowRegenerateAll = DATA.length > 0;
        //             }

        //             this.isLoadingResults = false;
        //             this.isGenerating = false;

        //             $('#refreshWorkInprogress').click();
        //            // this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);

        //            // resolve(); // Resolve the promise if everything is successful
        //         // } catch (error) {
        //         //     console.error('Error processing the response data:', error);
        //         //    // reject(error); // Reject the promise if an error is caught
        //         // }
        //     } else {
        //         console.error('API call failed with response:', response);

        //        // reject(new Error('API call failed')); // Reject if the API call didn't succeed
        //     }
        //   }else{
        //     this.isLoadingResults=false;
        //   }
          // END
      } else {
        // This is for update tracking data
        if (this.completedTrackingGuids.has(event.TRACKINGGUID)) {
            return; // Ignore this event
        }
        this.trackingData[event.TRACKINGGUID] = event;
        // if (!this.trackingData[event.TRACKINGGUID] || 
        //     JSON.stringify(this.trackingData[event.TRACKINGGUID]) !== JSON.stringify(event)) {
        //     this.trackingData[event.TRACKINGGUID] = event;
        // }
        console.log('..........................>>>>',this.trackingData);
      }
    }
            }

        })
        localStorage.setItem('istrackid', 'GenerateDocumentPopupComponent');
        this.base_url = environment.DocumentsUrl;
        if (_data) {
            let flag = this._data.MainFlag

            if (this._data.isRegeisterDocPDFEmail || this._data.isRegeisterDocPDF) {
                this.header1 = true;
                this.showPDFButton = false;
            } else {
                this.showPDFButton = true;
                this.header2 = true;
            }
            this.whichTypeTemplate = _data.knownby;
            this.isGenerating = true;
            if (_data.doc) {
                this.PdfName = _data.doc['DOCUMENTNAME'];
                this.filefolder_Name = "";
                this.documentsData.push(_data.doc);
                console.log('655',this.documentsData);
                if (this._data.convertPdf && this.documentsData[0]) {
                    this.isLoadingResults = true;
                    this.convertToPDF(0, this._data.sendEmail);
                }
            } else if (this._data.isMultipleDocument && this._data.Context == "previewall") {
                this.SHOWVIEWALL=this._data.SHOWVIEWALL;
                this.invoiceCalled(this._data.data);
            } else {
                this.filefolder_Name = _data.nameShow ? _data.nameShow : _data.Template;
                this.PdfName = "";
                // this.openWSConnection(this.currentUser.SessionToken);

                if (this._data.isMultipleDocument) {
                    this.tempGenerateForBulk(_data, '');
                }
                else if (this._data.multipledatainvoice) {
                    this.SINGLECOUNT = 0;
                    console.log('_data',_data);
                    this.multiinvoicedata(_data, '');
                } else if (this._data.isMultipleMatter) {
                    // this.selectMatter()
                    // alert(" ?? >> MULTITPLE MATTER ")
                    this.multiMatterDocGenerate(_data, 0)
                } else {

                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    let MatterGuid;
                 //   let OpenInNewTab_MatterData = JSON.parse(localStorage.getItem('OPENINNEWTAB_MATTERDATA'))
                 let OpenInNewTab_MatterData = JSON.parse(localStorage.getItem(windowNameId || 'set_active_matters'));
                    MatterGuid =(OpenInNewTab_MatterData && OpenInNewTab_MatterData != undefined && OpenInNewTab_MatterData !=null
                         && OpenInNewTab_MatterData?.MATTERGUID != undefined && OpenInNewTab_MatterData?.MATTERGUID != ''
                          && (this.currentUser?.OPENINNEWTAB == 1 || this.currentUser?.OPENINNEWTAB == true)) ?  OpenInNewTab_MatterData?.MATTERGUID : _data.ContextGuid;
                        _data.ContextGuid = _data.Context == 'Matter' ? MatterGuid : _data.ContextGuid ? _data.ContextGuid : '';
                        this.selectMatter(_data, '');
                }
            }
            if (flag && flag != undefined && flag != null && flag == 'True') {
                this.OpenDocumentNewPage = true;
            } else {
                this.OpenDocumentNewPage = false
            }

        }
        this.behaviorService.dialogClose$.subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'GenerateDocumentPopupComponent' && result['click'] && result['data']['TemplateGenerate']) {
                // this.openWSConnection(this.currentUser.SessionToken);
                this.selectMatter(this.ApiRegRetryparam1, this.ApiRegRetryparam2);
            }
            if (localStorage.getItem('istrackid') == 'GenerateDocumentPopupComponent' && result['click'] && result['data']['NetDriveRenameDocument']) {
                this.RenameFile();
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        if(this.DocumentGenerationType == 'MULTY'){
            this._data.multiSelect.forEach(el => {
                    el.MATTERCONTACT=[];
                    el.isShowSocketProgress = true;
                    el.MESSAGE='';
                    el.isShowSuccessData = false
                    this.documentsData.push(el);
                    console.log('729',this.documentsData);
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to get the multi invoice data
     */
    async multiinvoicedata(ReportData: any, fval) {
        console.log('this.DocumentGenerationType',this.DocumentGenerationType);
         if(this.DocumentGenerationType == 'MULTY'){
            this.ApiRegRetryparam1 = ReportData;
            this.ApiRegRetryparam2 = fval;
            this.IsAuth = false;
            delete ReportData["knownby"];
            this.isLoadingResults = true;
            if (fval != '') {
                ReportData.CHECKFORPROMPTS = false;
            } else {
                ReportData.CHECKFORPROMPTS = true;
            }
    
            let documentData = [];
    
            let ReportData1;
            this.whichTypeTemplate = this._data.passdata.knownby
            await new Promise(async (response1, reject1) => {
                if(this._data.multiSelect.length > 0){
    
                    this.Totallength=this._data.multiSelect.length;
                }
                for (let i = 0; i < this._data.multiSelect.length; i++) {
                    this.isLoadingResults = true;
                    this.IsAuth = false;
                    await new Promise<void>(async (next, reject) => {
                        const uniqueId = this.socketService.generateUniqueId();
                        
                        this.socketService.openSocketDocumentGeneration(uniqueId);
                        if (ReportData.MATTERCONTACTGUID) {
    
                            ReportData1 = {
                                ContextGuid: this._data.multiSelect[i].ContextGuid,
                                CHECKFORPROMPTS: false,
                                Context: ReportData.Context,
                                Template: this._data.passdata.Template,
                                Type: this._data.passdata.Type,
                            }
    
                        } else {
                            ReportData1 = {
                                ContextGuid: this._data.multiSelect[i].ContextGuid,
                                Context: this._data.Context,
                                CHECKFORPROMPTS: ReportData.CHECKFORPROMPTS,
                                Template: this._data.passdata.Template,
                                Type: this._data.passdata.Type,
                            }
                        }
    
                        $('#refreshWorkInprogress').click();
                        ReportData1.TrackingGuid = uniqueId;
                        ReportData1.SCHEDULEREQUEST=true;
                        this._data.multiSelect[i].TrackingGuid = uniqueId;
                        this.SingMultidataObject=this._data.multiSelect[i];
                        this.SingleMultidataIndex=i;
                        this.ReportData=ReportData;
                        this.ReportData1=ReportData1
                        await this._mainAPiServiceService.SetDocuments(ReportData1, 'TemplateGenerate').subscribe(response => {
                            if (response.CODE == 200 && response.STATUS == "success") {
                                // let isPdf:boolean=false;
                                // this.SINGLECOUNT++
                                // this.toastr.success('TemplateGenerate successfully');
                                // this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                                // let singledata=response.DATA.DOCUMENTS[0];
                                // if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                                // let SplitData=singledata.ORIGFILENAME.split(".");
                                // if(SplitData.length >1){
                                //     if(SplitData[SplitData.length-1] == 'pdf'){
                                //         isPdf=true;
                                //     }else{
                                //         isPdf=false;
                                //     }
                                // }
                                // }
                                // response.DATA.DOCUMENTS[0].isPdf = isPdf;
                                // this.isAllClose = true;
                                // response.DATA.DOCUMENTS.forEach(element => {
                                //     element.MATTERCONTACT=[];
                                //     element.SHORTNAME=this._data.multiSelect[i].SHORTNAME;
                                //     element.MATTER=this._data.multiSelect[i].MATTER;
                                //     element.INVOICECODE=this._data.multiSelect[i].INVOICECODE;
                                //     element.CONTEXTGUID=this._data.multiSelect[i].ContextGuid;
                                //     element.MATTERGUID=this._data.multiSelect[i].MATTERGUID;
                                //     this.documentsData.push(element);
                                // });
                                next();
                               // this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                if (this._data.multiSelect.length - 1 == i) {
                                    response1(true);
                                    this.isAllClose = false;
                                }
                               // this.invoicedataDataContextGuid = ''
                            }
                            else {
                                if (response.CODE == '504') {
                                    next();
                                    // this.isLoadingResults = false;
                                    // this.dialogRef.close(false)
                                  //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                    response1(true);
                                    // this.toastr.error("Server TimeOut!")
                                    // return;
                                }
                                if (response.CODE == 432 || response.CODE == '432') {
                                    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                    // this.dialogRef.close();
                                  //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                    // let payload = {
                                    //     "Action": "OAuth2Start",
                                    //     "VALIDATEONLY": false,
                                    //     "Data": {
                                    //         "USERGUID": this.currentUser.UserGuid,
                                    //         "AuthType": "NetDrive"
                                    //     }
                                    // }
                                    // this._mainAPiServiceService
                                    // .getSetData(payload, 'oauth-connections')
                                    // .subscribe((response) => {
                                    //     if(response.CODE == 200 && response.STATUS == "success") {
                                    //         window.open(
                                    //             response.DATA[0].AuthorizationUrl,
                                    //             "targetWindow",
                                    //             `toolbar=no,
                                    //             location=no,
                                    //             status=no,
                                    //             menubar=no,
                                    //             scrollbars=yes,
                                    //             resizable=yes,
                                    //             width=800,
                                    //             height=600`
                                    //         );
                                    //     }
                                    // });
                                }
                                //this.isLoadingResults = false;
    
                                if (response.DATA && !response.DATA.VALIDATEONLY) {
    
                                    if (response.CODE == 431) {
    
                                        // this.invoicedataDataContextGuid = this._data.multiSelect[i].ContextGuid;
                                        // if(this._data?.showmattercontact){
                                        //     this.IsAuth = false;
                                        // }else{
                                        //     this.IsAuth = true;
                                        // }
                                           
                                        // let currentMatter: any;
                                      
                                        // this.behaviorService.MatterData$.subscribe(result => {
                                        //     const materIDofTab = window.name.split("_");
                                        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                                        //     currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
                                        //     if (currentMatter) {
                                        //     } else {
                                        //         if (result) {
                                        //             currentMatter = result;
                                        //             localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                                        //         }
                                        //     }
                                        // });
    
                                        // let currentInvoiceMatter: any;
                                        // this.behaviorService.matterInvoice$.subscribe(result => {
    
                                        //     if (result) { currentInvoiceMatter = result; }
                                        // });
                                        
                                        // let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter?.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter?.MATTERGUID : ReportData?.ContextGuid;
                                        // if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {
                                        //     MatterGuid = response.DATA.MATTERGUID;
                                        // }
    
                                        // As per the clients new changes we use the condition {NOTE -> old code is in else block } 
    
                                        if(this._data.showmattercontact){
                                            // this.isShowRegenerateAll=true;
    
                                            // this._mainAPiServiceService.getSetData({Action: "GetData", Filters: { MatterGuid: MatterGuid }}, 'matter-contact').subscribe(res => {
                                            //     if (res.STATUS == "success" && res.CODE == 200) {
                                            //         this.isShowRegenerateAll=true;
                                            //         //this.filefolderUrlDes = response.DATA.REQUIREDFIELDS[0].DESCRIPTION;
                                            //         this.documentsData.push({
                                            //            MATTERCONTACT:res.DATA.RECORDS,
                                            //            SHORTNAME:this._data.multiSelect[i].SHORTNAME,
                                            //            MATTER:this._data.multiSelect[i].MATTER,
                                            //            INVOICECODE:this._data.multiSelect[i].INVOICECODE,
                                            //            CONTEXTGUID:this._data.multiSelect[i].ContextGuid,
                                            //            MATTERGUID:this._data.multiSelect[i].MATTERGUID
                                            //         })
                                            //         this.isLoadingResults = false;
                                            //         this.notShow=true;
                                                  //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                                    next();
                                            //     } else {
                                            //       this.toastr.error(res.MESSAGE);
                                            //       this.isLoadingResults = false;
                                            //     }
                                            //   });
    
                                        }else{
                                            // const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                                            //     disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                                            // });
                                            // dialogRefMC.afterClosed().subscribe(result => {
        
                                            //     if (result) {
                                            //         this.IsAuth = false
                                            //         ReportData.ContextGuid = this.invoicedataDataContextGuid
                                            //         ReportData.CHECKFORPROMPTS = false;
                                            //         ReportData.ContactGuid = result.PERSONGUID;
                                            //         ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                                                  //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                                   // this.multiinvoicedata(ReportData, 'false');
                                                    reject()
                                                // } else {
                                                //     this.isLoadingResults = false;
                                                // }
                                            // });
                                            // this.isLoadingResults = false;
                                        }
                                    
                                    }
                                    // next(this.documentsData);
                                    // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                                    // this.toastr.error(response.MESSAGE);
    
                                };
    
                                if (response.DATA.VALIDATEONLY) {
                                    console.log('972',this.documentsData);
                                    // this.globalFunService.checkValidation(response.DATA.VALIDATEONLY, ReportData1)
                                    //     .subscribe(data => {
                                    //         if (data && data.callback) {
                                                next(this.documentsData);
                                             //   this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                        //         // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                                        //     }
                                        // })
                                }
    
                                if (this._data.multiSelect.length - 1 == i) {
                                   // this.isLoadingResults = false;
                                    response1(true);
                                }
                            }
                        });
                        // this.isGenerating = false;
                        // this.isLoadingResults = false;
                    });
                }
                this.isLoadingResults = false;
                this.isGenerating = false;
                this.behaviorService.selectedMatters$.next([]);
                this.behaviorService.selectedMattersClear$.next(true);
            });
         }
        else{
            console.log('this._data',this._data);
            this.ApiRegRetryparam1 = ReportData;
            this.ApiRegRetryparam2 = fval;
            this.IsAuth = false;
            delete ReportData["knownby"];
            this.isLoadingResults = true;
            if (fval != '') {
                ReportData.CHECKFORPROMPTS = false;
            } else {
                ReportData.CHECKFORPROMPTS = true;
            }
    
            let documentData = [];
    
            let ReportData1;
            this.whichTypeTemplate = this._data.passdata.knownby
            await new Promise(async (response1, reject1) => {
                if(this._data.multiSelect.length > 0){
    
                    this.Totallength=this._data.multiSelect.length;
                }
                for (let i = 0; i < this._data.multiSelect.length; i++) {
                    this.isLoadingResults = true;
                    this.IsAuth = false;
                     await new Promise<void>(async (next, reject) => {
                        const uniqueId = this.socketService.generateUniqueId();
                        this.socketService.openSocketDocumentGeneration(uniqueId);
                        if (ReportData.MATTERCONTACTGUID) {
    
                            ReportData1 = {
                                ContextGuid: this._data.multiSelect[i].ContextGuid,
                                CHECKFORPROMPTS: false,
                                Context: ReportData.Context,
                                Template: this._data.passdata.Template,
                                Type: this._data.passdata.Type,
                            }
    
                        } else {
                            ReportData1 = {
                                ContextGuid: this._data.multiSelect[i].ContextGuid,
                                Context: this._data.Context,
                                CHECKFORPROMPTS: ReportData.CHECKFORPROMPTS,
                                Template: this._data.passdata.Template,
                                Type: this._data.passdata.Type,
                            }
                        }
    
                        $('#refreshWorkInprogress').click();
                        ReportData1.TrackingGuid = uniqueId;
                        await this._mainAPiServiceService.SetDocuments(ReportData1, 'TemplateGenerate').subscribe(response => {
                            if (response.CODE == 200 && response.STATUS == "success") {
                                let isPdf:boolean=false;
                                this.SINGLECOUNT++
                                this.toastr.success('TemplateGenerate successfully');
                                this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                                let singledata=response.DATA.DOCUMENTS[0];
                                if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                                let SplitData=singledata.ORIGFILENAME.split(".");
                                if(SplitData.length >1){
                                    if(SplitData[SplitData.length-1] == 'pdf'){
                                        isPdf=true;
                                    }else{
                                        isPdf=false;
                                    }
                                }
                                }
                                response.DATA.DOCUMENTS[0].isPdf = isPdf;
                                this.isAllClose = true;
                                response.DATA.DOCUMENTS.forEach(element => {
                                    element.MATTERCONTACT=[];
                                    element.SHORTNAME=this._data.multiSelect[i].SHORTNAME;
                                    element.MATTER=this._data.multiSelect[i].MATTER;
                                    element.INVOICECODE=this._data.multiSelect[i].INVOICECODE;
                                    element.CONTEXTGUID=this._data.multiSelect[i].ContextGuid;
                                    element.MATTERGUID=this._data.multiSelect[i].MATTERGUID;
                                    this.documentsData.push(element);
                                    console.log('1078',this.documentsData);
                                });
                                next(this.documentsData);
                                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                if (this._data.multiSelect.length - 1 == i) {
                                    response1(true);
                                    this.isAllClose = false;
                                }
                                this.invoicedataDataContextGuid = ''
                                this.isLoadingResults = false;

                            }
                            else {
                                if (response.CODE == '504') {
                                    next(this.documentsData);
                                    this.isLoadingResults = false;
                                    this.dialogRef.close(false)
                                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                    response1(true);
                                    this.toastr.error("Server TimeOut!")
                                    return;
                                }
                                if (response.CODE == 432 || response.CODE == '432') {
                                    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                    this.dialogRef.close();
                                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                    let payload = {
                                        "Action": "OAuth2Start",
                                        "VALIDATEONLY": false,
                                        "Data": {
                                            "USERGUID": this.currentUser.UserGuid,
                                            "AuthType": "NetDrive"
                                        }
                                    }
                                    this._mainAPiServiceService
                                    .getSetData(payload, 'oauth-connections')
                                    .subscribe((response) => {
                                        if(response.CODE == 200 && response.STATUS == "success") {
                                            window.open(
                                                response.DATA[0].AuthorizationUrl,
                                                "targetWindow",
                                                `toolbar=no,
                                                location=no,
                                                status=no,
                                                menubar=no,
                                                scrollbars=yes,
                                                resizable=yes,
                                                width=800,
                                                height=600`
                                            );
                                        }
                                    });
                                }
                                this.isLoadingResults = false;
    
                                if (response.DATA && !response.DATA.VALIDATEONLY) {
    
                                    if (response.CODE == 431 || response.CODE == '431') {
    
                                        this.invoicedataDataContextGuid = this._data.multiSelect[i].ContextGuid;
                                        if(this._data?.showmattercontact){
                                            this.IsAuth = false;
                                        }else{
                                            this.IsAuth = true;
                                        }
                                        let currentMatter: any;
                                        this.behaviorService.MatterData$.subscribe(result => {
                                            const materIDofTab = window.name.split("_");
                                            const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                                            currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                                            if (currentMatter) {
                                            } else {
                                                if (result) {
                                                    currentMatter = result;
                                                    localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                                                }
                                            }
                                        });
    
                                        let currentInvoiceMatter: any;
                                        this.behaviorService.matterInvoice$.subscribe(result => {
    
                                            if (result) { currentInvoiceMatter = result; }
                                        });
                                        
                                        let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter?.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter?.MATTERGUID : ReportData?.ContextGuid;
                                        if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {
                                            MatterGuid = response.DATA.MATTERGUID;
                                        }
    
                                        // As per the clients new changes we use the condition {NOTE -> old code is in else block } 
    
                                        if(this._data.showmattercontact){
                                            this.isShowRegenerateAll=true;
    
                                            this._mainAPiServiceService.getSetData({Action: "GetData", Filters: { MatterGuid: MatterGuid }}, 'matter-contact').subscribe(res => {
                                                if (res.STATUS == "success" && res.CODE == 200) {
                                                    this.isShowRegenerateAll=true;
                                                    this.documentsData.push({
                                                       MATTERCONTACT:res.DATA.RECORDS,
                                                       SHORTNAME:this._data.multiSelect[i].SHORTNAME,
                                                       MATTER:this._data.multiSelect[i].MATTER,
                                                       INVOICECODE:this._data.multiSelect[i].INVOICECODE,
                                                       CONTEXTGUID:this._data.multiSelect[i].ContextGuid,
                                                       MATTERGUID:this._data.multiSelect[i].MATTERGUID
                                                    })
                                                    console.log('1184',this.documentsData);
                                                    this.isLoadingResults = false;
                                                    this.notShow=true;
                                                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                                    next(this.documentsData);
                                                } else {
                                                  this.toastr.error(res.MESSAGE);
                                                  this.isLoadingResults = false;
                                                }
                                              });
    
                                        }else{
                                            const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                                                disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                                            });
                                            dialogRefMC.afterClosed().subscribe(result => {
        
                                                if (result) {
                                                    this.IsAuth = false
                                                    ReportData.ContextGuid = this.invoicedataDataContextGuid
                                                    ReportData.CHECKFORPROMPTS = false;
                                                    ReportData.ContactGuid = result.PERSONGUID;
                                                    ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                                                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                                    console.log('ReportData',ReportData);
                                                    this.multiinvoicedata(ReportData, 'false');
                                                    reject()
                                                } else {
                                                    this.isLoadingResults = false;
                                                }
                                            });
                                            this.isLoadingResults = false;
                                        }
                                    
                                    }
                                };
    
                                if (response.DATA.VALIDATEONLY) {
    
                                    this.globalFunService.checkValidation(response.DATA.VALIDATEONLY, ReportData1)
                                        .subscribe(data => {
                                            if (data && data.callback) {
                                                next(this.documentsData);
                                                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                                // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                                            }
                                        })
                                }
    
                                if (this._data.multiSelect.length - 1 == i) {
                                    this.isLoadingResults = false;
                                    // this.dialogRef.close(false);
                                    response1(true);
                                }
                            }
                        });
                        // this.isGenerating = false;
                        // this.isLoadingResults = false;
                     });
                }
               // this.isLoadingResults = false;
                this.isGenerating = false;
                this.behaviorService.selectedMatters$.next([]);
                this.behaviorService.selectedMattersClear$.next(true);
            });
            //   Promise.all([ReportData1]).then(async (values) => {
            //  let passdata: any = { 'Context': "invoiceall", 'ContextGuid': '', "Type": "Template", isMultipleDocumentinvoice: true };
            //     if (values.length > 0) {
            //       values[0].forEach((el) => {
            //         if (el.CODE == 200 && el.STATUS == "success") {
            //           documentData.push(el.DATA)
            //         }
            //       });
            //     }
    
            //     this.toastr.success('TemplateGenerate successfully');
    
    
            //     passdata.previewallData = documentData;
            //     this.behaviorService.selectedMatters$.next([]);
            //     this.behaviorService.selectedMattersClear$.next(true);
            //   });
        }
    }

    // async multiinvoicedata(ReportData: any, fval) {
    //     this.ApiRegRetryparam1 = ReportData;
    //     this.ApiRegRetryparam2 = fval;
    //     this.IsAuth = false;
    //     delete ReportData["knownby"];
    //     this.isLoadingResults = true;
    //     if (fval != '') {
    //         ReportData.CHECKFORPROMPTS = false;
    //     } else {
    //         ReportData.CHECKFORPROMPTS = true;
    //     }

    //     let documentData = [];

    //     let ReportData1;
    //     this.whichTypeTemplate = this._data.passdata.knownby
    //     await new Promise(async (response1, reject1) => {
    //         if(this._data.multiSelect.length > 0){

    //             this.Totallength=this._data.multiSelect.length;
    //         }
    //         for (let i = 0; i < this._data.multiSelect.length; i++) {
    //             this.isLoadingResults = true;
    //             this.IsAuth = false;
    //             // await new Promise<void>(async (next, reject) => {
    //                 const uniqueId = this.socketService.generateUniqueId();
    //                 this.socketService.openSocketDocumentGeneration(uniqueId);
    //                 if (ReportData.MATTERCONTACTGUID) {

    //                     ReportData1 = {
    //                         ContextGuid: this._data.multiSelect[i].ContextGuid,
    //                         CHECKFORPROMPTS: false,
    //                         Context: ReportData.Context,
    //                         Template: this._data.passdata.Template,
    //                         Type: this._data.passdata.Type,
    //                     }

    //                 } else {
    //                     ReportData1 = {
    //                         ContextGuid: this._data.multiSelect[i].ContextGuid,
    //                         Context: this._data.Context,
    //                         CHECKFORPROMPTS: ReportData.CHECKFORPROMPTS,
    //                         Template: this._data.passdata.Template,
    //                         Type: this._data.passdata.Type,
    //                     }
    //                 }

    //                 $('#refreshWorkInprogress').click();
    //                 ReportData1.TrackingGuid = uniqueId;
    //                 this.UNIQUEID.push({'uniqueId': uniqueId});
    //                 console.log('UNIQUEID',this.UNIQUEID);
    //               // ReportData1.SCHEDULEREQUEST=true
    //                 await this._mainAPiServiceService.SetDocuments(ReportData1, 'TemplateGenerate').subscribe(response => {
    //                     if (response.CODE == 200 && response.STATUS == "success") {
    //                         let isPdf:boolean=false;
    //                         this.SINGLECOUNT++
    //                         this.toastr.success('TemplateGenerate successfully');
    //                         this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
    //                         let singledata=response.DATA.DOCUMENTS[0];
    //                         if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
    //                         let SplitData=singledata.ORIGFILENAME.split(".");
    //                         if(SplitData.length >1){
    //                             if(SplitData[SplitData.length-1] == 'pdf'){
    //                                 isPdf=true;
    //                             }else{
    //                                 isPdf=false;
    //                             }
    //                         }
    //                         }
    //                         response.DATA.DOCUMENTS[0].isPdf = isPdf;
    //                         this.isAllClose = true;
    //                         response.DATA.DOCUMENTS.forEach(element => {
    //                             element.MATTERCONTACT=[];
    //                             element.SHORTNAME=this._data.multiSelect[i].SHORTNAME;
    //                             element.MATTER=this._data.multiSelect[i].MATTER;
    //                             element.INVOICECODE=this._data.multiSelect[i].INVOICECODE;
    //                             element.CONTEXTGUID=this._data.multiSelect[i].ContextGuid;
    //                             element.MATTERGUID=this._data.multiSelect[i].MATTERGUID;
    //                             this.documentsData.push(element);
    //                         });
    //                        // next(this.documentsData);
    //                         this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    //                         if (this._data.multiSelect.length - 1 == i) {
    //                             response1(true);
    //                             this.isAllClose = false;
    //                         }
    //                         this.invoicedataDataContextGuid = ''
    //                     }
    //                     else {
    //                         if (response.CODE == '504') {
    //                           //  next(this.documentsData);
    //                             this.isLoadingResults = false;
    //                             this.dialogRef.close(false)
    //                             this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    //                             response1(true);
    //                             this.toastr.error("Server TimeOut!")
    //                             return;
    //                         }
    //                         if (response.CODE == 432 || response.CODE == '432') {
    //                             let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //                             this.dialogRef.close();
    //                             this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    //                             let payload = {
    //                                 "Action": "OAuth2Start",
    //                                 "VALIDATEONLY": false,
    //                                 "Data": {
    //                                     "USERGUID": this.currentUser.UserGuid,
    //                                     "AuthType": "NetDrive"
    //                                 }
    //                             }
    //                             this._mainAPiServiceService
    //                             .getSetData(payload, 'oauth-connections')
    //                             .subscribe((response) => {
    //                                 if(response.CODE == 200 && response.STATUS == "success") {
    //                                     window.open(
    //                                         response.DATA[0].AuthorizationUrl,
    //                                         "targetWindow",
    //                                         `toolbar=no,
    //                                         location=no,
    //                                         status=no,
    //                                         menubar=no,
    //                                         scrollbars=yes,
    //                                         resizable=yes,
    //                                         width=800,
    //                                         height=600`
    //                                     );
    //                                 }
    //                             });
    //                         }
    //                         this.isLoadingResults = false;

    //                         if (response.DATA && !response.DATA.VALIDATEONLY) {

    //                             if (response.CODE == 431) {

    //                                 this.invoicedataDataContextGuid = this._data.multiSelect[i].ContextGuid;
    //                                 if(this._data?.showmattercontact){
    //                                     this.IsAuth = false;
    //                                 }else{
    //                                     this.IsAuth = true;
    //                                 }
    //                                 let currentMatter: any;
    //                                 this.behaviorService.MatterData$.subscribe(result => {
    //                                     const materIDofTab = window.name.split("_");
    //                                     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
    //                                     currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
    //                                     if (currentMatter) {
    //                                     } else {
    //                                         if (result) {
    //                                             currentMatter = result;
    //                                             localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
    //                                         }
    //                                     }
    //                                 });

    //                                 let currentInvoiceMatter: any;
    //                                 this.behaviorService.matterInvoice$.subscribe(result => {

    //                                     if (result) { currentInvoiceMatter = result; }
    //                                 });
                                    
    //                                 let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter?.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter?.MATTERGUID : ReportData?.ContextGuid;
    //                                 if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {
    //                                     MatterGuid = response.DATA.MATTERGUID;
    //                                 }

    //                                 // As per the clients new changes we use the condition {NOTE -> old code is in else block } 

    //                                 if(this._data.showmattercontact){
    //                                     this.isShowRegenerateAll=true;

    //                                     this._mainAPiServiceService.getSetData({Action: "GetData", Filters: { MatterGuid: MatterGuid }}, 'matter-contact').subscribe(res => {
    //                                         if (res.STATUS == "success" && res.CODE == 200) {
    //                                             this.isShowRegenerateAll=true;
    //                                             this.documentsData.push({
    //                                                MATTERCONTACT:res.DATA.RECORDS,
    //                                                SHORTNAME:this._data.multiSelect[i].SHORTNAME,
    //                                                MATTER:this._data.multiSelect[i].MATTER,
    //                                                INVOICECODE:this._data.multiSelect[i].INVOICECODE,
    //                                                CONTEXTGUID:this._data.multiSelect[i].ContextGuid,
    //                                                MATTERGUID:this._data.multiSelect[i].MATTERGUID
    //                                             })
    //                                             this.isLoadingResults = false;
    //                                             this.notShow=true;
    //                                             this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    //                                          //   next(this.documentsData);
    //                                         } else {
    //                                           this.toastr.error(res.MESSAGE);
    //                                           this.isLoadingResults = false;
    //                                         }
    //                                       });

    //                                 }else{
    //                                     const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
    //                                         disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
    //                                     });
    //                                     dialogRefMC.afterClosed().subscribe(result => {
    
    //                                         if (result) {
    //                                             this.IsAuth = false
    //                                             ReportData.ContextGuid = this.invoicedataDataContextGuid
    //                                             ReportData.CHECKFORPROMPTS = false;
    //                                             ReportData.ContactGuid = result.PERSONGUID;
    //                                             ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
    //                                             this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    //                                             this.multiinvoicedata(ReportData, 'false');
    //                                           //  reject()
    //                                         } else {
    //                                             this.isLoadingResults = false;
    //                                         }
    //                                     });
    //                                     this.isLoadingResults = false;
    //                                 }
                                
    //                             }
    //                         };

    //                         if (response.DATA.VALIDATEONLY) {

    //                             this.globalFunService.checkValidation(response.DATA.VALIDATEONLY, ReportData1)
    //                                 .subscribe(data => {
    //                                     if (data && data.callback) {
    //                                       //  next(this.documentsData);
    //                                         this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    //                                         // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
    //                                     }
    //                                 })
    //                         }

    //                         if (this._data.multiSelect.length - 1 == i) {
    //                             this.isLoadingResults = false;
    //                             // this.dialogRef.close(false);
    //                             response1(true);
    //                         }
    //                     }
    //                 });
    //                 // this.isGenerating = false;
    //                 // this.isLoadingResults = false;
    //             // });
    //         }
    //         this.isLoadingResults = false;
    //         this.isGenerating = false;
    //         this.behaviorService.selectedMatters$.next([]);
    //         this.behaviorService.selectedMattersClear$.next(true);
    //     });
    //     //   Promise.all([ReportData1]).then(async (values) => {
    //     //  let passdata: any = { 'Context': "invoiceall", 'ContextGuid': '', "Type": "Template", isMultipleDocumentinvoice: true };
    //     //     if (values.length > 0) {
    //     //       values[0].forEach((el) => {
    //     //         if (el.CODE == 200 && el.STATUS == "success") {
    //     //           documentData.push(el.DATA)
    //     //         }
    //     //       });
    //     //     }

    //     //     this.toastr.success('TemplateGenerate successfully');


    //     //     passdata.previewallData = documentData;
    //     //     this.behaviorService.selectedMatters$.next([]);
    //     //     this.behaviorService.selectedMattersClear$.next(true);
    //     //   });
    // }

    /**
     * This function is used to check the validation
     */
    checkValidation(bodyData: any, details: any, ApiName: any) {

        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No' || value.VALUEVALID == 'Error') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });

        if (Object.keys(errorData).length != 0) {
            localStorage.setItem('confEWshow', 'error');
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: {
                    errorData: errorData
                }
            });
            this.confirmDialogRef.componentInstance.confirmMessage = '';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                localStorage.removeItem('confEWshow');
                if (result) {
                }

                this.confirmDialogRef = null;
            });

        } else if (Object.keys(warningData).length != 0) {
            localStorage.setItem('confEWshow', 'warning');
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = (ApiName === 'SetInvoice') ? '' : warningData;
            this.confirmDialogRef.afterClosed().subscribe(result => {
                localStorage.removeItem('confEWshow');
                if (result) {
                    this.DeleteGData(details, ApiName);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.DeleteGData(details, ApiName);
        }
    }

    /**
     * This function is used todelete the data 
     */
    DeleteGData(data: any, ApiName) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, ApiName).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.success('Deleted successfully');
                $('#refreshInvoiceTab').click();
                $('#refreshMatterInvoice').click();
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.toastr.error(error);
        });
    }

/**
 * This function is used to select the matter
 * 
 */
    async selectMatter(ReportData: any, fval, isMulti?,data?) {
        //  alert(2)
        // await this.socketService.openSocket();
        this.ApiRegRetryparam1 = ReportData;
        this.ApiRegRetryparam2 = fval;
        this.IsAuth = false;

        delete ReportData["knownby"];
        delete ReportData["MainFlag"];

        this.isLoadingResults = true;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketDocumentGeneration(uniqueId);
        // This is for show the progressbar of the perticular socket data;
        if(data != undefined){
            data.isShowSocketProgress=true;
            // data.ProgressIndex=0;
        }
        delete ReportData["isMultipleDocument"];
        if (fval != '' || (this._data && this._data.CHECKFORPROMPTS == false)) {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        ReportData.TrackingGuid = uniqueId;
        this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(async response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM' || this._data.Type == 'LEXON FORM') {
                    let isPdf:boolean=false;
                    this.toastr.success('TemplateGenerate successfully');
                    this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                    let singledata=response.DATA.DOCUMENTS[0];
                        if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                        let SplitData=singledata.ORIGFILENAME.split(".");
                        if(SplitData.length >1){
                            if(SplitData[SplitData.length-1] == 'pdf'){
                                isPdf=true;
                            }else{
                                isPdf=false;
                            }
                        }
                        }
                        response.DATA.DOCUMENTS[0].isPdf=isPdf
                    if (isMulti) {
                        this.documentsData.push(response.DATA.DOCUMENTS[0])
                        console.log('1660',this.documentsData);
                    } else {
                        this.documentsData = response.DATA.DOCUMENTS;
                        console.log('1663',this.documentsData);
                        if (this.OpenDocumentNewPage == true) {
                            var url = `${this.base_url}${response.DATA.DOCUMENTS[0].FILENAME}`;
                            window.open(url);
                            this.dialogRef.close();
                        }
                    }
                }else{
                    if(this._data.showmattercontact){
                        let isPdf:boolean=false;
                        this.SINGLECOUNT++;
                        let singledata=response.DATA.DOCUMENTS[0];
                        if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                        let SplitData=singledata.ORIGFILENAME.split(".");
                        if(SplitData.length >1){
                            if(SplitData[SplitData.length-1] == 'pdf'){
                                isPdf=true;
                            }else{
                                isPdf=false;
                            }
                        }
                        }
                        this.documentsData.forEach(element => {
                            if(element.MATTERGUID == data.MATTERGUID){
                                element.MATTERCONTACT=[];
                                element.DESCRIPTION=singledata.DESCRIPTION;
                                element.DESKTOPLINK=singledata.DESKTOPLINK;
                                element.DOCUMENTGUID=singledata.DOCUMENTGUID;
                                element.DOCUMENTTYPE=singledata.DOCUMENTTYPE;
                                element.DOWNLOAD=singledata.DOWNLOAD;
                                element.FILENAME=singledata.FILENAME;
                                element.MATTERGUID=singledata.MATTERGUID;
                                element.ORIGFILENAME=singledata.ORIGFILENAME;
                                element.ORIGFILENAMEONLY=singledata.ORIGFILENAMEONLY;
                                element.isShowSocketProgress=false; // This is for hide the progressbar of the perticular socket data;
                                element.isPdf=isPdf;
                            }
                        });
                        console.log('1701',this.documentsData);
                        let DATA=this.documentsData.filter(data=> data.MATTERCONTACT.length > 0);
                        if(DATA.length > 0){
                            this.isShowRegenerateAll=true;
                        }else{
                            this.isShowRegenerateAll=false;
                        }

                        this.hideGloableprogressbarData=false;
                    }
                }

                this.isLoadingResults = false;
                this.isGenerating = false;
                // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " TemplateGenerateStatus");
                // this.webSocket.close();
                $('#refreshWorkInprogress').click();
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
            } else if (response.CODE == 420) {
                this.isLoadingResults = false;
                this.dialogRef.close();
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.CODE == 428) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                this.arguParam = '';
                ReportData.CHECKFORPROMPTS = true;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetEmail"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 432) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                this.arguParam = '';
                ReportData.CHECKFORPROMPTS = true;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 431) {
                this.IsAuth = true;
                let currentMatter: any;
                // this.behaviorService.MatterData$.subscribe(result => {
                //   if (result) { currentMatter = result; }
                // });

                this.behaviorService.MatterData$.subscribe(result => {
                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                    if (currentMatter) {
                    } else {
                        if (result) {
                            currentMatter = result;
                            localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                        }
                    }
                });

                let currentInvoiceMatter: any;
                this.behaviorService.matterInvoice$.subscribe(result => {
                    if (result) { currentInvoiceMatter = result; }
                });
                let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.MATTERGUID : ReportData.ContextGuid;
                if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {

                    MatterGuid = response.DATA.MATTERGUID;
                }

                const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                    disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                });
                dialogRefMC.afterClosed().subscribe(result => {
                    if (result) {
                        ReportData.CHECKFORPROMPTS = false;
                        ReportData.ContactGuid = result.PERSONGUID;
                        ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                        this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        this.selectMatter(ReportData, 'false');
                    } else {
                        this.isLoadingResults = false;
                    }
                });
                this.isLoadingResults = false;
            } else if (response.STATUS == 'error' || response.CODE == '422') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.MESSAGE == 'Not logged in') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            }
            // await this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
            // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
        });
    }
    // openWSConnection(SessionToken: any) {
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " TemplateGenerateStatus");
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //     };
    //     demoSoket.onerror = (errorEvent) => {
    //     };
    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }

    /**
     * This function is used to convert the pdf
     */
    convertToPDF(indexD: any, ismail: any) {
        this.isLoadingResults = true;
        let documentData = this.documentsData[indexD];
        this.filefolder_Name = "";
        if (this._data.doc) {
            this.PdfName = this._data.doc['DOCUMENTNAME'];
        } else {
            this.PdfName = documentData.ORIGFILENAMEONLY;
        }
        if (ismail) {
            this.isPDFEmailLoadingResults = true;
        } else {
            this.isPDFLoadingResults = true;
        }
        let postData: any = {
            DOCUMENTLIST: [{ DOCUMENTGUID: documentData.DOCUMENTGUID, DOCUMENTPATH: documentData.DOWNLOAD }],
        };
        if (this.router.url == "/create-document/matter-template") {
            let currentMatter: any;
            // this.behaviorService.MatterData$.subscribe(result => {
            //   if (result) { currentMatter = result; }
            // });

            this.behaviorService.MatterData$.subscribe(result => {
                const materIDofTab = window.name.split("_");
                const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                if (currentMatter) {
                } else {
                    if (result) {
                        currentMatter = result;
                        localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                    }
                }
            });

            postData.MATTERGUID = currentMatter.MATTERGUID;
        }
        this._mainAPiServiceService.getSetData({ VALIDATEONLY: false, Data: postData }, 'NetDriveConvertToPDF').subscribe(response => {

            if (response.CODE == 200 && response.STATUS == "success") {
                this.isGenerating = false;
                this.isLoadingResults = false;
                if (this._data.convertPdf) {
                    this.documentsData = [];
                }
                this.toastr.success("PDF generate successfully");
                let pdfData: any = response.DATA.DOCUMENTS[0];
                this.PDffilerUrlDes = true;
                this.PdfName = response.DATA.DOCUMENTS[0].DOCUMENTNAME;
                pdfData.isPdf = true;
                let tempPak: any = [];
                if (this._data.convertPdf) {
                    tempPak.push(pdfData);
                }

                if(this._data?.showmattercontact){

                    this.documentsData.forEach((element, index) => {
                        if (indexD == index) {
                            pdfData.SHORTNAME=element.SHORTNAME;
                            pdfData.MATTERGUID=element.MATTERGUID;
                            pdfData.MATTERCONTACT=element.MATTERCONTACT;
                            pdfData.MATTER=element.MATTER;
                            pdfData.INVOICECODE=element.INVOICECODE;

                            tempPak.push(pdfData);
                        } else {
                            tempPak.push(element);
                        }
                    });
                    console.log('1961',this.documentsData);
                }else{
                    this.documentsData.forEach((element, index) => {
                        if (indexD == index) {
                            tempPak.push(pdfData);
                        } else {
                            tempPak.push(element);
                        }
                    });
                }
                this.documentsData = tempPak;
                console.log('1972',this.documentsData);
                if (ismail) {
                    if (this._data.isRegeisterDocPDFEmail) {
                        this.ToEmailthedocument(indexD);
                    } else {
                        this.ToEmailthedocument(indexD + 1);
                    }
                }
            } else if (response.CODE == 432 || response.CODE == '432') {
                let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 447 || response.CODE == '447') {
                this.toastr.error(response.MESSAGE);
                this.isLoadingResults = false
                this.isGenerating = false;
                this.dialogRef.close();
            }
            if (ismail) {
                this.isPDFEmailLoadingResults = false;
            } else {
                this.isPDFLoadingResults = false;
            }
        }, error => {
            if (ismail) {
                this.isPDFEmailLoadingResults = false;
            } else {
                this.isPDFLoadingResults = false;
            }
            this.toastr.error(error);
            this.isLoadingResults = false
            this.isGenerating = false;
            this.dialogRef.close();
        });
    }

    /**
     * This function is used to sanitize the URL
     */
    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    /**
     * This function is used to get the Email Document
     */
    ToEmailthedocument(index: any) {
        let documentData = this.documentsData[index];
        let DocumentData: any = { DOCUMENTGUID: documentData.DOCUMENTGUID };
        let DocumentName: any = { name: documentData.ORIGFILENAMEONLY, index: 9999, };
        const dialogRef = this.dialog.open(SelectEmailPopupComponent, { width: '100%', disableClose: true });
        this.behaviorService.SafeCustody$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.SafeCustodyData = result;
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let EmailtemplateData: any = '';
                this.behaviorService.EmailGenerateData$.subscribe(result => {
                    if (result) {
                        EmailtemplateData = result;
                    }
                });

                if (this.router.url == "/time-billing/matter-invoices" || this.router.url == "/invoice/sub-invoices" || this.router.url == "/create-document/invoice-template" || this.router.url == "/create-document/packs-invoice-template" || this.router.url == "/time-billing/work-in-progress/invoice" || this.router.url == "/time-billing/work-in-progress") {
                    let invoiceGUid
                    if (JSON.parse(localStorage.getItem('collectSelectedInvoice')).length > 0) {
                        let data = JSON.parse(localStorage.getItem('collectSelectedInvoice'))
                        invoiceGUid = data[index].INVOICEGUID
                    } else {
                        invoiceGUid = localStorage.getItem('edit_invoice_id');
                    }
                    let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'invoice' }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/time-billing/matter-invoices" || this.router.url == "/invoice/sub-invoices" || this.router.url == "/create-document/matter-template" || this.router.url == "/create-document/packs-matter-template") {
                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    let matterData = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                    let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'matter' }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/receive-money" || this.router.url == "/time-billing/receipts-credits" || this.router.url == "/create-document/receive-money-template" || this.router.url == "/create-document/packs-receive-money-template" || this.router.url == "/receive-money" || this.router.url == "/time-billing/receipts-credits") {
                    let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
                    let passdata = { 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/contact" || this.router.url == "/create-document/contact-template" || this.router.url == "/create-document/packs-contact-template") {
                    let ContactGuID = localStorage.getItem('contactGuid');
                    let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/full-Safe-Custody/Safe-Custody" || this.router.url == "/create-document/email-safe-custody-template" || this.router.url == "/create-document/packs-safe-custody-template") {
                    let passdata = { 'Context': "Safe Custody", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == '/document-register') {
                    let docDeta: any;
                    this.behaviorService.DocumentRegisterData$.subscribe(result => {
                        if (result) { docDeta = result; }
                    });
                    let passdata = { 'Context': docDeta.CONTEXT, 'ContextGuid': docDeta.CONTEXTGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/create-document/invoice-multiple-document" || this.router.url == "/create-document/packs-invoice-multiple-document") {
                    let invoiceGUid = JSON.parse(localStorage.getItem('collectSelectedInvoice'))[index].INVOICEGUID;
                    let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'invoice' }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/matters") {
                    let invoiceGUid
                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    if (JSON.parse(localStorage.getItem('collectedSelectedMatter')).length > 0) {
                        let data = JSON.parse(localStorage.getItem('collectedSelectedMatter'))
                        invoiceGUid = data[index].MATTERGUID
                    } else {
                        let data = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                        invoiceGUid = data.MATTERGUID
                    }
                    let passdata = { 'Context': "Matter", 'ContextGuid': invoiceGUid, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'matter' }
                    this.ForEmailDialogOpen(passdata);
                } else {
                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    let matterData = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                    let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'matter' }
                    this.ForEmailDialogOpen(passdata);
                }
            }
        });
    }

    /**
     * This function is used to open the Dialog 
     */
    ForEmailDialogOpen(passdata) {
        const dialogRef = this.dialog.open(MatterDialogComponentForTemplate, { width: '100%', disableClose: true, data: passdata });
        dialogRef.afterClosed().subscribe(result => { });
    }

    /**
     * This function is used to Open the PDF and Email
     */
    ToPDFAndEmail(index: any) {
        let documentData = this.documentsData[index];
        let exten = documentData.ORIGFILENAME.substr(documentData.ORIGFILENAME.lastIndexOf('.') + 1);
        if (exten == 'pdf') {
            this.ToEmailthedocument(index);
        } else {
            this._data.isRegeisterDocPDFEmail = true;
            this.convertToPDF(index, true);
        }
    }

    /**
     * This function is used to Rename File
     */
    RenameFile() {
        let documentData = this.documentsData[this.documentDataIndex];
        this.isLoadingResults = true;
        let newName: any = documentData.NEWFILENAME.replace(/\.[^/.]+$/, "");
        this.fileExtention = documentData.ORIGFILENAME.substr(documentData.ORIGFILENAME.lastIndexOf('.') + 1);
        this._mainAPiServiceService.getSetData({ DOCUMENTGUID: documentData.DOCUMENTGUID, NEWDOCUMENTNAME: newName + '.' + this.fileExtention }, 'NetDriveRenameDocument').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toastr.success("Renamed successfully");
                this.xFxaxVal = 55;
                this.yflaxCal = 45;
                this.documentsData[this.documentDataIndex] = response.DATA.DOCUMENTS[0];
                this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DOCUMENTNAME;
                this.documentDataIndex = 99999;
            } else if (response.CODE == 432 || response.CODE == '432') {
                let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            }
            this.isLoadingResults = false;
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
        });
    }

    /**
     * This function is used to Rename the File data
     */
    RenameFileData(index) {
        let documentData = this.documentsData[index];
        let dialogRef: any = this.dialog.open(MatterFolderDailogComponent, {
            width: '100%', data: { docData: documentData, type: 'rename', isPopup: true }
        });
        dialogRef.afterClosed().subscribe(result => {
            let isPdf: boolean = false;
            if (result) {
                if(this._data?.showmattercontact){
                    result.RECORDS[0].SHORTNAME=this.documentsData[index]?.SHORTNAME;
                    result.RECORDS[0].MATTERGUID=this.documentsData[index]?.MATTERGUID;
                    result.RECORDS[0].MATTERCONTACT=this.documentsData[index]?.MATTERCONTACT;
                    result.RECORDS[0].MATTER=this.documentsData[index]?.MATTER;
                    result.RECORDS[0].INVOICECODE=this.documentsData[index]?.INVOICECODE;
                    this.documentsData[index] = result.DOCUMENTS[0];
                    this.documentsData[index].isPdf = isPdf;
                }else{
                    if(result.RECORDS[0]) {
                        this.documentsData[index] = result.RECORDS[0];
                        this.documentsData[index].isPdf = isPdf
                    }
                }
                if(result.RECORDS[0]){
                    this.filefolderUrlDes = result.RECORDS[0].DOCUMENTNAME;
                }
                this.documentDataIndex = 99999;
            }
        });
    }

    /**
     * This function is used to tempGenerate data 
     */
    async tempGenerateForBulk(ReportData: any, fval) {
        this.ApiRegRetryparam1 = ReportData;
        this.ApiRegRetryparam2 = fval;
        this.IsAuth = false;
        delete ReportData["knownby"];
        delete ReportData["isMultipleDocument"];
        this.isLoadingResults = true;
        if (fval != '') {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));
        if (collectSelectedInvoice) {
            for (var i = 0; i < collectSelectedInvoice.length; i++) {
                await new Promise<void>(async (next, reject) => {
                    // await this.socketService.openSocket();
                    // // this.openWSConnection(this.currentUser.SessionToken);
                    const uniqueId = this.socketService.generateUniqueId();
                    this.socketService.openSocketDocumentGeneration(uniqueId);
                    ReportData.ContextGuid = collectSelectedInvoice[i].INVOICEGUID;
                    ReportData.TrackingGuid = uniqueId;

                    // for invoice page only
                    // ---------------------------------------
                    try {
                        const currentMatterGuid = collectSelectedInvoice[i]["MATTERGUID"];
                        const currentMatterName = collectSelectedInvoice[i]["MATTER"];
                        await new Promise<void>(async (next, reject) => {
                            if ((this.router.url == "/invoice/sub-invoices") && fval == "") {
                                const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                                    disableClose: true, width: '100%', data: { ContextGuid: currentMatterGuid, 'callType': 'select', isTemplate: 'Template', MatterName: currentMatterName, InvoiceCode: collectSelectedInvoice[i].INVOICECODE, WindowName: "MultiSelectInvoice" },
                                    // MatterNum: currentMatterData.SHORTNAME, WindowName: "MultiSelectMatter"
                                });
                                dialogRefMC.afterClosed().subscribe(result => {
                                    if (result) {
                                        this.isAllClose=true;
                                        ReportData.CHECKFORPROMPTS = false;
                                        ReportData.ContactGuid = result.PERSONGUID;
                                        ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                                        next();
                                    }
                                });
                            }
                        })
                    } catch (err) {
                        console.error(err);
                    }
                    // ---------------------------------------
                    // ends here ~ for invoice page only

                    this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(response => {


                        if (response.CODE == 200 && response.STATUS == "success") {
                            if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM' || this._data.Type == 'LEXON FORM') {


                                this.toastr.success('TemplateGenerate successfully');
                                let isPdf:boolean=false;
                                let singledata=response.DATA.DOCUMENTS[0];
                        if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                        let SplitData=singledata.ORIGFILENAME.split(".");
                        if(SplitData.length >1){
                            if(SplitData[SplitData.length-1] == 'pdf'){
                                isPdf=true;
                            }else{
                                isPdf=false;
                            }
                        }
                        }
                        response.DATA.DOCUMENTS[0].isPdf = isPdf
                                response.DATA.DOCUMENTS.forEach(element => {
                                    this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                                    this.documentsData.push(element);
                                    console.log('2321',this.documentsData);
                                });
                            }
                            // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " TemplateGenerateStatus");

                            next();
                            $('#refreshWorkInprogress').click();
                            // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        } else if (response.CODE == 420) {
                            this.isLoadingResults = false;
                            this.dialogRef.close();
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        } else if (response.CODE == 428) {
                            this.isLoadingResults = false;
                            this.IsAuth = true;
                            this.arguParam = '';
                            ReportData.CHECKFORPROMPTS = true;
            //                 window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
            //                     `toolbar=no,
            //    location=no,
            //    status=no,
            //    menubar=no,
            //    scrollbars=yes,
            //    resizable=yes,
            //    width=800,
            //    height=600`);
                            let payload = {
                                "Action": "OAuth2Start",
                                "VALIDATEONLY": false,
                                "Data": {
                                    "USERGUID": this.currentUser.UserGuid,
                                    "AuthType": "NetEmail"
                                }
                            }
                            this._mainAPiServiceService
                            .getSetData(payload, 'oauth-connections')
                            .subscribe((response) => {
                                if(response.CODE == 200 && response.STATUS == "success") {
                                    window.open(
                                        response.DATA[0].AuthorizationUrl,
                                        "targetWindow",
                                        `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=600`
                                    );
                                }
                            });
                        } else if (response.CODE == 432) {
                            this.isLoadingResults = false;
                            this.IsAuth = true;
                            this.arguParam = '';
                            ReportData.CHECKFORPROMPTS = true;
            //                 window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
            //                     `toolbar=no,
            //    location=no,
            //    status=no,
            //    menubar=no,
            //    scrollbars=yes,
            //    resizable=yes,
            //    width=800,
            //    height=600`);
                            let payload = {
                                "Action": "OAuth2Start",
                                "VALIDATEONLY": false,
                                "Data": {
                                    "USERGUID": this.currentUser.UserGuid,
                                    "AuthType": "NetDrive"
                                }
                            }
                            this._mainAPiServiceService
                            .getSetData(payload, 'oauth-connections')
                            .subscribe((response) => {
                                if(response.CODE == 200 && response.STATUS == "success") {
                                    window.open(
                                        response.DATA[0].AuthorizationUrl,
                                        "targetWindow",
                                        `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=600`
                                    );
                                }
                            });
                        } else if (response.CODE == 431) {
                            if (ReportData.MATTERCONTACTGUID == '' || ReportData.MATTERCONTACTGUID == undefined) {
                                this.IsAuth = true;
                                let currentMatter: any;
                                // this.behaviorService.MatterData$.subscribe(result => {
                                //   if (result) { currentMatter = result; }
                                // });
                                this.behaviorService.MatterData$.subscribe(result => {
                                    const materIDofTab = window.name.split("_");
                                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                                    currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                                    if (currentMatter) {
                                    } else {
                                        if (result) {
                                            currentMatter = result;
                                            localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                                        }
                                    }
                                });
                                let currentInvoiceMatter: any;
                                this.behaviorService.matterInvoice$.subscribe(result => {
                                    if (result) { currentInvoiceMatter = result; }
                                });
                                let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.MATTERGUID : ReportData.ContextGuid;
                                if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {
                                    MatterGuid = response.DATA.MATTERGUID;
                                }

                                const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                                    disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                                });
                                dialogRefMC.afterClosed().subscribe(result => {
                                    if (result) {
                                        ReportData.CHECKFORPROMPTS = false;
                                        ReportData.ContactGuid = result.PERSONGUID;
                                        ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                                        this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                        this.tempGenerateForBulk(ReportData, 'false');
                                        reject();
                                    } else {
                                        this.isLoadingResults = false;
                                    }
                                });
                                this.isLoadingResults = false;

                            } else {
                                next();
                                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                            }
                        } else if (response.STATUS == 'error' || response.CODE == '422') {
                            this.isLoadingResults = false;
                            this.dialogRef.close(false);
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        } else if (response.MESSAGE == 'Not logged in') {
                            this.isLoadingResults = false;
                            this.dialogRef.close(false);
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        }
                    }, error => {
                        this.isLoadingResults = false;
                        this.toastr.error(error);
                        this.dialogRef.close();
                    });
                });
            }
            // this.webSocket.close();
            this.isLoadingResults = false;
            this.isGenerating = false;
        }
    }

/**
 * This function is used to invoice call
 */
    async invoiceCalled(selectedMatterForinvoice: any) {
        this.isLoadingResults = true;
        let tempPost: any;
        this.count = 0;

        let tempPromise = [];
        let promiseList;

        let documentData = [];
        await new Promise(async (response1, reject1) => {
            for (var i = 0; i < selectedMatterForinvoice.length; i++) {
                promiseList = await new Promise(async (resolve, reject) => {
                    if(this._data?.isBulkPreview && this._data?.isBulkPreview == true){
                        this.isLoadingResults = true;
                        tempPost = {
                        INVOICECODE: '',
                        MATTERGUID: selectedMatterForinvoice[i].MATTERGUID,
                        INVOICEDATE: this._data.INVOICEDATE,
                        DUEDATE: this._data.DUEDATE,
                        PRINTEDDATE: '',
                        INVOICETOTAL:selectedMatterForinvoice[i].UNBILLEDVALUEEXGST,
                        INVOICETOTALINCGST: selectedMatterForinvoice[i].UNBILLEDVALUEINCGST,
                        GST: (selectedMatterForinvoice[i].UNBILLEDVALUEINCGST -selectedMatterForinvoice[i].UNBILLEDVALUEEXGST),
                        FOREIGNCURRENCYID: '',
                        COMMENT: '',
                        ADDITIONALTEXT: '',
                        BULKINVOICING:true,
                        INVOICEUPTO:this._data.INVOICEUPTO,
                        INVOICEFORMAT:this._data?.Template,
                        }
                    }else{
                        tempPost = {
                            MATTERGUID: selectedMatterForinvoice[i].MATTERGUID,
                        }
                    }
                    const uniqueId = this.socketService.generateUniqueId();
                    this.socketService.openSocketDocumentGeneration(uniqueId);
                    // await this.socketService.openSocket();

                    let PostInvoiceEntryData: any;
                    PostInvoiceEntryData = { FormAction: "previewall", VALIDATEONLY: false, TrackingGuid: uniqueId, Data: tempPost };
                    await this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(response => {
                        if (response.CODE == 200 && response.STATUS == "success") {
                            this.count = this.count + 1;
                            tempPromise.push(response);
                            response.DATA.DOCUMENTS.forEach(element => {
                                this.filefolderUrlDes = element.DESCRIPTION;
                                this.isAllClose = true;
                                this.documentsData.push(element);
                            });
                            console.log('2538',this.documentsData);
                            resolve(tempPromise);
                            $('#refreshWorkInprogress').click();
                            // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                            if (i == selectedMatterForinvoice.length - 1) {
                                response1(true);
                                this.isAllClose = false;

                            }
                        } else {
                            if (response.CODE == 504) {
                                resolve(tempPromise);
                                this.isLoadingResults = false;
                                this.dialogRef.close(false);
                                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                response1(true);
                                this.toastr.error("Server Timeout!");

                            };
                            if (response.CODE == 432 || response.CODE == '432') {
                                let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
                                this.dialogRef.close();
                                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                //                 window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                //                     `toolbar=no,
                //  location=no,
                //  status=no,
                //  menubar=no,
                //  scrollbars=yes,
                //  resizable=yes,
                //  width=800,
                //  height=600`);
                                let payload = {
                                    "Action": "OAuth2Start",
                                    "VALIDATEONLY": false,
                                    "Data": {
                                        "USERGUID": this.currentUser.UserGuid,
                                        "AuthType": "NetDrive"
                                    }
                                }
                                this._mainAPiServiceService
                                .getSetData(payload, 'oauth-connections')
                                .subscribe((response) => {
                                    if(response.CODE == 200 && response.STATUS == "success") {
                                        console.log('response',response.DATA[0].AuthorizationUrl);
                                        window.open(
                                            response.DATA[0].AuthorizationUrl,
                                            "targetWindow",
                                            `toolbar=no,
                                            location=no,
                                            status=no,
                                            menubar=no,
                                            scrollbars=yes,
                                            resizable=yes,
                                            width=800,
                                            height=600`
                                        );
                                    }
                                });
                            }
                            if(response.CODE == 409 || response.CODE == '409'){
                                    this.documentsData.push({MESSAGE:response.MESSAGE,SHORTNAME:selectedMatterForinvoice[i].SHORTNAME});
                                    console.log('2601',this.documentsData);
                            }
                            this.isLoadingResults = false;

                            tempPromise.push(response);
                            this.globalFunService.checkValidation(response.DATA.VALIDATIONS, PostInvoiceEntryData)
                                .subscribe(data => {
                                    if (data && data.callback) {
                                        resolve(tempPromise);
                                        // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                                        this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                    }
                                });

                            if (i == selectedMatterForinvoice.length - 1) {
                                this.isLoadingResults = false;
                                this.dialogRef.close(false);
                                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                                response1(true);
                            }
                        }
                    });
                });
            }

            this.isLoadingResults = false;
            this.isGenerating = false;
        })

        Promise.all([promiseList]).then(async (values) => {
            let passdata: any = { 'Context': "previewall", 'ContextGuid': '', "knownby": "Template", "Type": "Template", isMultipleDocument: true };
            if (values.length > 0) {
                values[0].forEach((el) => {
                    if (el.CODE == 200 && el.STATUS == "success") {
                        documentData.push(el.DATA)
                    }
                });
            }

            this.toastr.success('TemplateGenerate successfully');



            passdata.previewallData = documentData;

            this.behaviorService.selectedMatters$.next([]);
            this.behaviorService.selectedMattersClear$.next(true);
        });
    }

    // generate multiple document on based on selected matters
    // ---------------------------------------------------------------------------------

    // dynamic function to select matter

    /**
     * This function is used to select the Multimatter
     */
    async selectMulMatter(ReportData: any, fval, isMulti?, prevResolve?, prevReject?) {
        return new Promise((resolve, reject) => {

            // --------------------------

            this.ApiRegRetryparam1 = ReportData;
            this.ApiRegRetryparam2 = fval;
            this.IsAuth = false;
            this.isAllClose = true;
            const uniqueId = this.socketService.generateUniqueId();
            this.socketService.openSocketDocumentGeneration(uniqueId);
            delete ReportData["knownby"];
            this.isLoadingResults = true;

            delete ReportData["isMultipleDocument"];
            if (fval != '') {
                ReportData.CHECKFORPROMPTS = false;
            } else {
                ReportData.CHECKFORPROMPTS = true;
            }
            ReportData.TrackingGuid = uniqueId;
            this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(async response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM' || this._data.Type == 'LEXON FORM') {
                        this.toastr.success('TemplateGenerate successfully');
                        let isPdf:boolean=false;
                        this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                        let singledata=response.DATA.DOCUMENTS[0];
                        if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                        let SplitData=singledata.ORIGFILENAME.split(".");
                        if(SplitData.length >1){
                            if(SplitData[SplitData.length-1] == 'pdf'){
                                isPdf=true;
                            }else{
                                isPdf=false;
                            }
                        }
                        }
                        response.DATA.DOCUMENTS[0].isPdf = isPdf

                        if (isMulti) {
                            this.documentsData.push(response.DATA.DOCUMENTS[0])
                            console.log('2701',this.documentsData);
                        } else {
                            this.documentsData = response.DATA.DOCUMENTS;
                            console.log('2704',this.documentsData);
                        }
                    }

                    this.isLoadingResults = false;
                    this.isGenerating = false;
                    // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " TemplateGenerateStatus");
                    // this.webSocket.close();
                    $('#refreshWorkInprogress').click();
                    // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                    resolve("COMPLETED");
                    if (prevResolve) {
                        prevResolve("COMPLETED")
                    }

                } else if (response.CODE == 420) {
                    this.isLoadingResults = false;
                    this.dialogRef.close();
                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                    reject("ERROR")
                    if (prevReject) {
                        prevReject("ERROR")
                    }
                } else if (response.CODE == 428) {
                    this.isLoadingResults = false;
                    this.IsAuth = true;
                    this.arguParam = '';
                    ReportData.CHECKFORPROMPTS = true;
                    reject("ERROR")
                    if (prevReject) {
                        prevReject("ERROR")
                    }
        //             window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //                 `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                        let payload = {
                            "Action": "OAuth2Start",
                            "VALIDATEONLY": false,
                            "Data": {
                                "USERGUID": this.currentUser.UserGuid,
                                "AuthType": "NetEmail"
                            }
                        }
                        this._mainAPiServiceService
                        .getSetData(payload, 'oauth-connections')
                        .subscribe((response) => {
                            if(response.CODE == 200 && response.STATUS == "success") {
                                window.open(
                                    response.DATA[0].AuthorizationUrl,
                                    "targetWindow",
                                    `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=600`
                                );
                            }
                        });
                } else if (response.CODE == 432) {
                    this.isLoadingResults = false;
                    this.IsAuth = true;
                    this.arguParam = '';
                    ReportData.CHECKFORPROMPTS = true;
        //             window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //                 `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                        let payload = {
                            "Action": "OAuth2Start",
                            "VALIDATEONLY": false,
                            "Data": {
                                "USERGUID": this.currentUser.UserGuid,
                                "AuthType": "NetDrive"
                            }
                        }
                        this._mainAPiServiceService
                        .getSetData(payload, 'oauth-connections')
                        .subscribe((response) => {
                            if(response.CODE == 200 && response.STATUS == "success") {
                                window.open(
                                    response.DATA[0].AuthorizationUrl,
                                    "targetWindow",
                                    `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=600`
                                );
                            }
                        });
                    reject("ERROR")
                    if (prevReject) {
                        prevReject("ERROR")
                    }
                } else if (response.CODE == 431) {
                    this.IsAuth = true;
                    let currentMatter: any;
                    // this.behaviorService.MatterData$.subscribe(result => {
                    //   if (result) { currentMatter = result; }
                    // });

                    this.behaviorService.MatterData$.subscribe(result => {
                        const materIDofTab = window.name.split("_");
                        const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                        currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                        if (currentMatter) {
                        } else {
                            if (result) {
                                currentMatter = result;
                                localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                            }
                        }
                    });

                    let currentInvoiceMatter: any;
                    this.behaviorService.matterInvoice$.subscribe(result => {
                        if (result) { currentInvoiceMatter = result; }
                    });
                    let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.
                        MATTERGUID : ReportData.ContextGuid;

                    try {
                        if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {
                            MatterGuid = response.DATA.MATTERGUID;
                        }
                    } catch (err) {
                        // console.error(err)
                    }


                    const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                        disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                    });
                    dialogRefMC.afterClosed().subscribe(result => {
                        if (result) {
                            ReportData.CHECKFORPROMPTS = false;
                            ReportData.ContactGuid = result.PERSONGUID;
                            ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                            this.selectMulMatter(ReportData, 'false', true, resolve, reject);
                            // resolve("COMPLETED");

                        } else {
                            this.isLoadingResults = false;
                            // resolve("COMPLETED");

                        }
                        // resolve("COMPLETED");
                    });
                    this.isLoadingResults = false;
                } else if (response.STATUS == 'error' || response.CODE == '422') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false);
                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                    reject("ERROR")
                    if (prevReject) {
                        prevReject("ERROR")
                    }
                } else if (response.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    reject("ERROR")
                    if (prevReject) {
                        prevReject("ERROR")
                    }
                    this.dialogRef.close(false);
                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                }

                // resolve("COMPLETED");
                // await this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
            }, error => {
                this.isLoadingResults = false;
                this.toastr.error(error);
                reject("ERROR");
                if (prevReject) {
                    prevReject("ERROR")
                }
                // this.dialogRef.close();
                // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
            });

            // ---------------------------

        })
    }
    // ends here ~ dynamic function to select matter


    /**
     * This function is used to select the matter contact
     */
    selectMatterContact(ReportData, MatterGuid, currentMatterData) {

        return new Promise((resolve, reject) => {

            const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template', MatterNum: currentMatterData.SHORTNAME, WindowName: "MultiSelectMatter" },
            });
            dialogRefMC.afterClosed().subscribe(result => {
                if (result) {
                    ReportData.CHECKFORPROMPTS = false;
                    ReportData.ContactGuid = result.PERSONGUID;
                    ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;

                    resolve(ReportData)

                } else {
                    this.isLoadingResults = false;
                    resolve(ReportData)
                }
            });

        })
    }
    // ends here ~ select matter contact

    /**
     * This function is used to get the multimatter
     */
    async multiMatterDocGenerate(_data, index) {
        let selectedMatterList = JSON.parse(localStorage.getItem('collectedSelectedMatter'));

        if (selectedMatterList.length == 0 || !selectedMatterList) {
            selectedMatterList = [JSON.parse(localStorage.getItem("set_active_matters"))]
        }
        // if(index > 0) {
        //   await this.selectMatterContact(_data,selectedMatterList[index].MATTERGUID, selectedMatterList[index])
        //   _data["ContextGuid"] = selectedMatterList[index].MATTERGUID
        // }
        // await this.selectMatterContact(_data,selectedMatterList[index].MATTERGUID, selectedMatterList[index])
        _data["ContextGuid"] = selectedMatterList[index].MATTERGUID;
        await this.selectMulMatter(_data, '', true);
        if (index != (selectedMatterList.length - 1)) {
            this.multiMatterDocGenerate(_data, index + 1)
        }

        // else {
        //   localStorage.setItem("collectedSelectedMatter","[]");
        // }

        //  let selectedMatterList = JSON.parse(localStorage.getItem('collectedSelectedMatter'));
        //  for(let data of selectedMatterList) {
        //   _data["ContextGuid"] = data.MATTERGUID
        //   await this.selectMatter(_data, '', true);
        //  }
    }
    // ends here ~ recurring call on all selecteed matter

    // ---------------------------------------------------------------------------------
    // ends here ~ generate multiple document on based on selected matters

    ViewallInvoices(){
        if(this.documentsData.length >= 1){
            if(this.documentsData.length < 5){
                this.documentsData.forEach(element => {
                    if(element.FILENAME){
                        // const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(`${this.base_url}${element.FILENAME}`)}&embedded=true`;
                        // window.open(googleViewerUrl, '_blank');
                        window.open(`${this.base_url}${element.FILENAME}`, '_blank');
                    }
                 });
            }else{
                this.confirmDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    `Do you really want to view all ${this.count} documents?`;
                this.confirmDialogRef.afterClosed().subscribe(result=>{
                    if(result){
                        this.documentsData.forEach(element => {
                            if(element.FILENAME){
                                // const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(`${this.base_url}${element.FILENAME}`)}&embedded=true`;
                                // window.open(googleViewerUrl, '_blank');
                                window.open(`${this.base_url}${element.FILENAME}`, '_blank');
                            }
                         });
                    }
                })
            }
         
        }
        
    }

    /**
     * This function is used to Download the all Invoice
     */
    DownloadallInvoices(){
        if(this.documentsData.length >= 1){
           if(this.documentsData.length < 5){
            this.documentsData.forEach(element => {
                if(element.FILENAME){
                    // window.open(`${this.base_url}${element.FILENAME}`, '_blank');
                    this.TableColumnsService.downloadFileDATA(`${this.base_url}${element.FILENAME}`);
                }
             });
            }else{
                this.confirmDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    `Do you really want to download all ${this.count} documents?`;
                this.confirmDialogRef.afterClosed().subscribe(result=>{
                    if(result){
                        this.documentsData.forEach(element => {
                            if(element.FILENAME){
                                // window.open(`${this.base_url}${element.FILENAME}`, '_blank');
                                this.TableColumnsService.downloadFileDATA(`${this.base_url}${element.FILENAME}`);
                            }
                         });
                    }
                })
            }
        }
    }

    /**
     * This function is used to get the Singleview
     */
    getSingleView(FILENAME){
        // const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(`${this.base_url}${FILENAME}`)}&embedded=true`;
        // window.open(googleViewerUrl, '_blank');
        window.open(`${this.base_url}${FILENAME}`, '_blank');
    }

    /**
     * This function is used to get the single Download
     */
    getSingleDowanload(FILENAME){
        const fileName = `${this.base_url}${FILENAME}`;
        this.TableColumnsService.downloadFileDATA(fileName);
        // const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(`${this.base_url}${FILENAME}`)}&embedded=true`;
        // window.open(googleViewerUrl, '_blank');
        // window.open(`${this.base_url}${FILENAME}`, '_blank');
    }

    /**
     * This function is used to change the matter contact
     */
    onChangeMatterContact(data){
        this._data.ContactGuid=data.PERSONGUID
        this._data.MATTERCONTACTGUID = data.MATTERCONTACTGUID
    }

    /**
     * This function is used to get Refresh Data
     */
    getRefreshData(item){
        if(item.mattercontact == undefined){
            this.toastr.error('Please select a matter contact')
               return
           }
           console.log(item);
           console.log(this._data)
        // this._data.ContextGuid = item.CONTEXTGUID;
           this._data.ContextGuid = item.ContextGuid;
        this._data.CHECKFORPROMPTS = false;
        this.trackingData = {}; // Store tracking info
       this.selectMatterForRefresh(this._data,'false',true,item);
       this.notShow=false;
       this.isAllClose = true;
       // this is for hide the gloable progrss bar
       this.hideGloableprogressbarData=true;
    }

    /**
     * This function is used to veiw all the documents at a time
     * @param data -Documents data
     */
    viewAllDocument(data){
let unselectedMatterContactsRecord=[];
let selectedMatterContactRecords=[];

data.forEach(element => {
    if(!element.FILENAME){
        unselectedMatterContactsRecord.push(element);
    }else{
        selectedMatterContactRecords.push(element);
    }
});

if(data.length == unselectedMatterContactsRecord.length){
    this.toastr.error("Please select the matter contact first");
    return
}

        if(selectedMatterContactRecords.length >0){
            if(selectedMatterContactRecords.length < 5){
                selectedMatterContactRecords.forEach(element => {
                    if(element.FILENAME){
                        window.open(`${element.FILENAME}`, '_blank');
                    }
                 });
            }else{
                this.confirmDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    `Do you really want to view all ${selectedMatterContactRecords.length} documents?`;
                this.confirmDialogRef.afterClosed().subscribe(result=>{
                    if(result){
                        selectedMatterContactRecords.forEach(element => {
                            if(element.FILENAME){
                                window.open(`${element.FILENAME}`, '_blank');
                            }
                         });
                    }
                })
            }
         
        }
    }

    /**
     * This functionis used to download the all document at a time
     * @param data -Documents data
     */
    downloadAllDocument(data){
        let unselectedMatterContactsRecord=[];
        let selectedMatterContactRecords=[];
        
        data.forEach(element => {
            if(!element.DOWNLOAD){
                unselectedMatterContactsRecord.push(element);
            }else{
                selectedMatterContactRecords.push(element);
            }
        });
        
        if(data.length == unselectedMatterContactsRecord.length){
            this.toastr.error("Please select the matter contact first");
            return
        }

        if(selectedMatterContactRecords.length >0){
            if(selectedMatterContactRecords.length < 2){
             selectedMatterContactRecords.forEach(element => {
                 if(element.DOWNLOAD){
                     this.TableColumnsService.downloadFileDATA(`${this.base_url}${element.DOWNLOAD}`);
                 }
              });
             }else{
                 this.confirmDialogRef = this._matDialog.open(
                     FuseConfirmDialogComponent,
                     {
                         disableClose: true,
                         width: "100%",
                     }
                 );
                 this.confirmDialogRef.componentInstance.confirmMessage =
                     `Do you really want to download all ${selectedMatterContactRecords.length} documents?`;
                 this.confirmDialogRef.afterClosed().subscribe(result=>{
                     if(result){
                         selectedMatterContactRecords.forEach(element => {
                             if(element.FILENAME){
                                 this.TableColumnsService.downloadFileDATA(`${this.base_url}${element.DOWNLOAD}`);
                             }
                          });
                     }
                 })
             }
         }
    }
    
    /**
     * This function is used to Re-generate the Document
     * @param data -Documents data
     */
    async reGenerateDocument(data) {
        this.trackingData = {}; // Store tracking info
        let unselectedMatterContactsRecord = [];
        let selectedMatterContactRecords = [];
        data.forEach(element => {
            if (element?.MATTERCONTACT?.length > 0) {
                unselectedMatterContactsRecord.push(element);
            } else {
                selectedMatterContactRecords.push(element);
            }
        });
        let selectedMatterContact = unselectedMatterContactsRecord.filter(data => data?.mattercontact);
        // API CALLING START HERE
        if (selectedMatterContact.length > 0) {
            try {
                this.selectMatterContactData=selectedMatterContact;
                // Iterate over each selectedMatterContact sequentially
                for (let i = 0; i < selectedMatterContact.length; i++) {
                    // this._data.ContextGuid = selectedMatterContact[i].CONTEXTGUID;
                     this._data.ContextGuid = selectedMatterContact[i].ContextGuid;
                    this._data.ContactGuid = selectedMatterContact[i].mattercontact.PERSONGUID;
                    this._data.MATTERCONTACTGUID = selectedMatterContact[i].mattercontact.MATTERCONTACTGUID;
                    this._data.CHECKFORPROMPTS = false;
                    this.notShow = false;
                    selectedMatterContact[i].isShowSocketProgress=true;
                    // selectedMatterContact[i].ProgressIndex= i;
                    // Await the completion of each API call before proceeding to the next one
                     this.hideGloableprogressbarData = true;
                        await this.RegenerateAll(this._data, 'false', selectedMatterContact[i]);
                }
                // this.toastr.success("Documents regenerated successfully.");
            } catch (error) {
                this.toastr.error("Error while regenerating documents: " + error.message);
            }
        } else {
            this.toastr.error("Please select a matter contact first.");
        }
    }
    
    /**
     * This function is used to RegenerateAll data
     */
    RegenerateAll(ReportData: any, fval, data): Promise<void> {
        this.IsAuth = false;
        delete ReportData["knownby"];
        delete ReportData["MainFlag"];
        this.isLoadingResults = true;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketDocumentGeneration(uniqueId);
        delete ReportData["isMultipleDocument"];
        
        if (fval !== '' || (this._data && this._data.CHECKFORPROMPTS === false)) {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        ReportData.TrackingGuid = uniqueId;
        data.TrackingGuid = uniqueId;
        ReportData.SCHEDULEREQUEST=true;
        ReportData.Template= this._data.passdata.Template
        const payload = { ...ReportData }; // Shallow copy
        delete payload.multiSelect;
        delete payload.passdata;
        delete payload.multipledatainvoice;
        delete payload.DocumentGenerationType;
       // delete ReportData.showmattercontact;
        payload.Type= "Template"
        return new Promise((resolve, reject) => {
            this._mainAPiServiceService.SetDocuments(payload, 'TemplateGenerate').subscribe({
                 next: response => {
                     if (response?.CODE == 200 && response?.STATUS == "success") {
                //         try {
                //             if (this._data.showmattercontact) {
                //                 let isPdf:boolean=false;
                //                 this.SINGLECOUNT++;
                //                 let singledata = response.DATA.DOCUMENTS[0];
                //                 if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                //                     let SplitData=singledata.ORIGFILENAME.split(".");
                //                     if(SplitData.length >1){
                //                         if(SplitData[SplitData.length-1] == 'pdf'){
                //                             isPdf=true;
                //                         }else{
                //                             isPdf=false;
                //                         }
                //                     }
                //                     }
                //                 this.documentsData.forEach(element => {
                //                     if (element.MATTERGUID === data?.MATTERGUID) {
                //                         element.MATTERCONTACT = [];
                //                         element.DESCRIPTION = singledata.DESCRIPTION;
                //                         element.DESKTOPLINK = singledata.DESKTOPLINK;
                //                         element.DOCUMENTGUID = singledata.DOCUMENTGUID;
                //                         element.DOCUMENTTYPE = singledata.DOCUMENTTYPE;
                //                         element.DOWNLOAD = singledata.DOWNLOAD;
                //                         element.FILENAME = singledata.FILENAME;
                //                         element.MATTERGUID = singledata.MATTERGUID;
                //                         element.ORIGFILENAME = singledata.ORIGFILENAME;
                //                         element.ORIGFILENAMEONLY = singledata.ORIGFILENAMEONLY;
                //                         element.isPdf=isPdf;
                //                         if(element?.isShowSocketProgress !=undefined || element?.isShowSocketProgress == true){
                //                             element.isShowSocketProgress=false;
                //                         }
                                        
                //                     }
                //                 });
    
                //                 let DATA = this.documentsData.filter(item => item.MATTERCONTACT.length > 0);
                //                 this.isShowRegenerateAll = DATA.length > 0;
                //             }
    
                //             this.isLoadingResults = false;
                //             this.isGenerating = false;
    
                //             $('#refreshWorkInprogress').click();
                //             this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
    
                             resolve(); // Resolve the promise if everything is successful
                //         } catch (error) {
                //             console.error('Error processing the response data:', error);
                //             reject(error); // Reject the promise if an error is caught
                //         }
                //     } else {
                //         console.error('API call failed with response:', response);

               //         reject(new Error('API call failed')); // Reject if the API call didn't succeed
                    }else{
                        reject()
                    }
                },
                error: error => {
               //     console.error('API request failed with error:', error);
               //     this.isLoadingResults = false;
               //     //this.toastr.error('An error occurred while generating the document.');
               //     // this.dialogRef.close();
                    reject(error); // Reject if there's an error in the API request
                }
           });
        });
    }
    
    /**
 * This function is used to select the matter
 * 
 */
    async selectMatterForRefresh(ReportData: any, fval, isMulti?,data?) {
        //  alert(2)
        // await this.socketService.openSocket();
        this.ApiRegRetryparam1 = ReportData;
        this.ApiRegRetryparam2 = fval;
        this.IsAuth = false;

        delete ReportData["knownby"];
        delete ReportData["MainFlag"];

        this.isLoadingResults = true;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketDocumentGeneration(uniqueId);
        // This is for show the progressbar of the perticular socket data;
        if(data != undefined){
            data.isShowSocketProgress=true;
            // data.ProgressIndex=0;
            this.GloableData=data;
        }
        delete ReportData["isMultipleDocument"];
        if (fval != '' || (this._data && this._data.CHECKFORPROMPTS == false)) {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        ReportData.TrackingGuid = uniqueId;
        if(data != undefined){
            data.TrackingGuid = uniqueId;
        }
        ReportData.SCHEDULEREQUEST=true;
        ReportData.Template= this._data.passdata.Template,
        this.isMulti=isMulti;
        const payload = { ...ReportData }; // Shallow copy
        delete payload.multiSelect;
        delete payload.passdata;
        delete payload.multipledatainvoice;
        delete payload.DocumentGenerationType;
        delete payload.showmattercontact;
        payload.Type= "Template"
      //  delete ReportData.showmattercontact;
        this.selectMatterForRefreshReportData=ReportData;
        this._mainAPiServiceService.SetDocuments(payload, 'TemplateGenerate').subscribe(async response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM' || this._data.Type == 'LEXON FORM') {
                    // let isPdf:boolean=false;
                    // this.toastr.success('TemplateGenerate successfully');
                    // this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                    // let singledata=response.DATA.DOCUMENTS[0];
                    //     if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                    //     let SplitData=singledata.ORIGFILENAME.split(".");
                    //     if(SplitData.length >1){
                    //         if(SplitData[SplitData.length-1] == 'pdf'){
                    //             isPdf=true;
                    //         }else{
                    //             isPdf=false;
                    //         }
                    //     }
                    //     }
                    //     response.DATA.DOCUMENTS[0].isPdf=isPdf
                    // if (isMulti) {
                    //     this.documentsData.push(response.DATA.DOCUMENTS[0])
                    // } else {
                    //     this.documentsData = response.DATA.DOCUMENTS;
                    //     if (this.OpenDocumentNewPage == true) {
                    //         var url = `${this.base_url}${response.DATA.DOCUMENTS[0].FILENAME}`;
                    //         window.open(url);
                    //         this.dialogRef.close();
                    //     }
                    // }
                }else{
                    if(this._data.showmattercontact){
                        // let isPdf:boolean=false;
                        // this.SINGLECOUNT++;
                        // let singledata=response.DATA.DOCUMENTS[0];
                        // if(singledata && singledata.ORIGFILENAME != undefined && singledata.ORIGFILENAME != ''){
                        // let SplitData=singledata.ORIGFILENAME.split(".");
                        // if(SplitData.length >1){
                        //     if(SplitData[SplitData.length-1] == 'pdf'){
                        //         isPdf=true;
                        //     }else{
                        //         isPdf=false;
                        //     }
                        // }
                        // }
                        // this.documentsData.forEach(element => {
                        //     if(element.MATTERGUID == data.MATTERGUID){
                        //         element.MATTERCONTACT=[];
                        //         element.DESCRIPTION=singledata.DESCRIPTION;
                        //         element.DESKTOPLINK=singledata.DESKTOPLINK;
                        //         element.DOCUMENTGUID=singledata.DOCUMENTGUID;
                        //         element.DOCUMENTTYPE=singledata.DOCUMENTTYPE;
                        //         element.DOWNLOAD=singledata.DOWNLOAD;
                        //         element.FILENAME=singledata.FILENAME;
                        //         element.MATTERGUID=singledata.MATTERGUID;
                        //         element.ORIGFILENAME=singledata.ORIGFILENAME;
                        //         element.ORIGFILENAMEONLY=singledata.ORIGFILENAMEONLY;
                        //         element.isShowSocketProgress=false; // This is for hide the progressbar of the perticular socket data;
                        //         element.isPdf=isPdf;
                        //     }
                        // });
                        // let DATA=this.documentsData.filter(data=> data.MATTERCONTACT.length > 0);
                        // if(DATA.length > 0){
                        //     this.isShowRegenerateAll=true;
                        // }else{
                        //     this.isShowRegenerateAll=false;
                        // }

                        // this.hideGloableprogressbarData=false;
                    }
                }

                // this.isLoadingResults = false;
                // this.isGenerating = false;
                // // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " TemplateGenerateStatus");
                // // this.webSocket.close();
                // $('#refreshWorkInprogress').click();
               // this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
            } else if (response.CODE == 420) {
                // this.isLoadingResults = false;
                // this.dialogRef.close();
              //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.CODE == 428) {
                // this.isLoadingResults = false;
                // this.IsAuth = true;
                // this.arguParam = '';
                // ReportData.CHECKFORPROMPTS = true;
                // let payload = {
                //     "Action": "OAuth2Start",
                //     "VALIDATEONLY": false,
                //     "Data": {
                //         "USERGUID": this.currentUser.UserGuid,
                //         "AuthType": "NetEmail"
                //     }
                // }
                // this._mainAPiServiceService
                // .getSetData(payload, 'oauth-connections')
                // .subscribe((response) => {
                //     if(response.CODE == 200 && response.STATUS == "success") {
                //         window.open(
                //             response.DATA[0].AuthorizationUrl,
                //             "targetWindow",
                //             `toolbar=no,
                //             location=no,
                //             status=no,
                //             menubar=no,
                //             scrollbars=yes,
                //             resizable=yes,
                //             width=800,
                //             height=600`
                //         );
                //     }
                // });
            } else if (response.CODE == 432) {
                // this.isLoadingResults = false;
                // this.IsAuth = true;
                // this.arguParam = '';
                // ReportData.CHECKFORPROMPTS = true;
                // let payload = {
                //     "Action": "OAuth2Start",
                //     "VALIDATEONLY": false,
                //     "Data": {
                //         "USERGUID": this.currentUser.UserGuid,
                //         "AuthType": "NetDrive"
                //     }
                // }
                // this._mainAPiServiceService
                // .getSetData(payload, 'oauth-connections')
                // .subscribe((response) => {
                //     if(response.CODE == 200 && response.STATUS == "success") {
                //         window.open(
                //             response.DATA[0].AuthorizationUrl,
                //             "targetWindow",
                //             `toolbar=no,
                //             location=no,
                //             status=no,
                //             menubar=no,
                //             scrollbars=yes,
                //             resizable=yes,
                //             width=800,
                //             height=600`
                //         );
                //     }
                // });
            } else if (response.CODE == 431) {
                // this.IsAuth = true;
                // let currentMatter: any;
                

                // this.behaviorService.MatterData$.subscribe(result => {
                //     const materIDofTab = window.name.split("_");
                //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                //     currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
                //     if (currentMatter) {
                //     } else {
                //         if (result) {
                //             currentMatter = result;
                //             localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                //         }
                //     }
                // });

                // let currentInvoiceMatter: any;
                // this.behaviorService.matterInvoice$.subscribe(result => {
                //     if (result) { currentInvoiceMatter = result; }
                // });
                // let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.MATTERGUID : ReportData.ContextGuid;
                // if (response.DATA['REQUIREDFIELDS'][0]['FIELDNAME'] == "MatterContact" && response.DATA.MATTERGUID != "") {

                //     MatterGuid = response.DATA.MATTERGUID;
                // }

                // const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                //     disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                // });
                // dialogRefMC.afterClosed().subscribe(result => {
                //     if (result) {
                //         ReportData.CHECKFORPROMPTS = false;
                //         ReportData.ContactGuid = result.PERSONGUID;
                //         ReportData.MATTERCONTACTGUID = result.MATTERCONTACTGUID;
                           this.uniqueId=uniqueId
                    //     this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                    //     this.selectMatterForRefresh(ReportData, 'false');
                    // } else {
                    //     this.isLoadingResults = false;
                    // }
                // });
                //this.isLoadingResults = false;
            } else if (response.STATUS == 'error' || response.CODE == '422') {
                // this.isLoadingResults = false;
                // this.dialogRef.close(false);
              //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.MESSAGE == 'Not logged in') {
                // this.isLoadingResults = false;
                // this.dialogRef.close(false);
              //  this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            }
            // await this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
            // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
        });
    }

}