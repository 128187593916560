import { Component, OnInit, Input, ViewChild,AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/style/style-transforms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit, OnDestroy {
serch:string =''
  common: any;
  country: string;
  isChecked: boolean = false;
  sub1: Subscription;
  sub: Subscription;
  sub2: Subscription;
  searchData: any[] = [] ;
  searchAddressForm2: any[] = [];
  sub3: Subscription;
  sub4: Subscription;
  @Input() allToolTip:any;
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() checkedaddress:any;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool: any;
  @ViewChild('addressInput', { read: MatAutocompleteTrigger,static:true})
  dropremove: MatAutocompleteTrigger;
  @ViewChild('addressInput1', { read: MatAutocompleteTrigger,static:true})
  dropremove1: MatAutocompleteTrigger;
  constructor(private _mainAPiServiceService: MainAPiServiceService,
    public tooltipService:TooltipService) { }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.contactForm.controls['ADDRESS1'].setValue('');
    this.contactForm.controls['ADDRESS2'].setValue('');
    this.contactForm.controls['ADDRESS3'].setValue('');
    this.contactForm.controls['SUBURB'].setValue('');
    this.contactForm.controls['STATE'].setValue('');
    this.contactForm.controls['POSTCODE'].setValue('');
    this.contactForm.controls['COUNTRY'].setValue('');
    // this.contactForm.controls['POSTALCOUNTRY'].setValue('');
    this.contactForm.controls['DX'].setValue('');
    this.contactForm.controls['DXSUBURB'].setValue('');
    setTimeout(() => {
      let content =document.querySelector('.dropdown')
      const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
      this.sub1 = scroll$.subscribe((res) => {
      if (this.dropremove.panelOpen) {
        this.dropremove.closePanel();
      }
      if (this.dropremove1.panelOpen) {
        this.dropremove1.closePanel();
      }
    })
    }, 50);
    this.contactForm.controls['USEPARENTADDRESS'].valueChanges.subscribe(val => {
      if (val == false) { 

       let previewaddress= localStorage.getItem('getContact_edit')
    //  this.getContact({ CONTACTGUID: previewaddress });
       this.EnableForm();
      }
      else {
      this.DisableForm();
      let contactguidforbody = this.contactForm.get('COMPANYCONTACTGUID').value;
      if (val && contactguidforbody) {
        let payload = {
          "ACTION": "GetData",
          "FILTERS": {
            "CONTACTGUID": contactguidforbody
          },
        };
        this.getContact(payload);
        // this.getContact({ CONTACTGUID: contactguidforbody });
      }
    }
      // } else {
      //   this.EnableForm();
      //   this.triggerSomeEvent(this.contactForm)
      // }
      // this.contactForm.disable();
    })
    // this.common = [

    //   { Id: 1, Name: "United States of America'" },
    //   { Id: 2, Name: "ComUnited Kingdompany" },
    //   { Id: 3, Name: "Russia" },
    //   { Id: 4, Name: "China" },
    //   { Id: 5, Name: "Japan" },
    //   { Id: 6, Name: "Turkey" },

    // ];
  }

  /**
     * It runs only once after the component's view has been rendered.
     */
  @ViewChild('addressInput', { static: true }) addressInput!: ElementRef;
  @ViewChild('addressInput1', { static: true }) postalAddressInput!: ElementRef;

  ngAfterViewInit(): void {
    this.triggerSomeEvent(this.contactForm);

    fromEvent(this.addressInput.nativeElement, 'keyup')
    .pipe(
      map((event: Event) => (event.target as HTMLInputElement).value),
      distinctUntilChanged(),
      debounceTime(570),
      switchMap((dataVal: string) => {
        if (dataVal.length < 6) {
          return of(null);
        }
        const addressData = {
          "Action": "GetAddressMatch",
          "Data": {
            "Address": dataVal
          }
        };
        return this.findData(addressData); // Replace with your service method
      })
    )
    .subscribe(result => {
      if (result && result.DATA) {
        this.searchData = result.DATA.SUGGESTIONS;
      } else {
        this.searchData = null; // Clear suggestions if no result or result is null
      }
    });

    fromEvent(this.postalAddressInput.nativeElement, 'keyup')
    .pipe(
      map((event: Event) => (event.target as HTMLInputElement).value),
      distinctUntilChanged(),
      debounceTime(570),
      switchMap((dataVal: string) => {
        if (dataVal.length < 6) {
          return of(null);
        }
        const addressData2 = {
          "Action": "GetAddressMatch",
          "Data": {
            "Address": dataVal
          }
        };
        return this.findData(addressData2); // Replace with your service method
      })
    )
    .subscribe(result => {
      if (result && result.DATA) {
        this.searchAddressForm2 = result.DATA.SUGGESTIONS;
      } else {
        this.searchAddressForm2 = null; // Clear suggestions if no result or result is null
      }
    });
  }

  /**
   * This function is used to get the contact data
   * @param contactguidforbody 
   */
  getContact(contactguidforbody){
    if(this.checkedaddress && this.checkedaddress.contect_details && this.checkedaddress.flagNewConactMatter == true){
      contactguidforbody.CONTACTGUID = this.checkedaddress.contect_details[0].CONTACTGUID 
    }

    this._mainAPiServiceService.getSetData(contactguidforbody, 'contact').subscribe(res => {
      if (res.MESSAGE == 'Not logged in') {
        // this.dialogRef.close(false);
      } else {

        if (res.DATA.RECORDS[0] ) {
          let getContactData = res.DATA.RECORDS[0];
            this.contactForm.controls['ADDRESS1'].setValue(getContactData.ADDRESS1);
            this.contactForm.controls['ADDRESS2'].setValue(getContactData.ADDRESS2);
            this.contactForm.controls['ADDRESS3'].setValue(getContactData.ADDRESS3);
            this.contactForm.controls['SUBURB'].setValue(getContactData.SUBURB);
            this.contactForm.controls['STATE'].setValue(getContactData.STATE);
            this.contactForm.controls['POSTCODE'].setValue(getContactData.POSTCODE);
            // this.contactForm.controls['POSTALCOUNTRY'].setValue(getContactData.COUNTRY);
            this.contactForm.controls['COUNTRY'].setValue(getContactData.COUNTRY);
            // this.contactForm.controls['DX'].setValue(getContactData.DX);
            // this.contactForm.controls['DXSUBURB'].setValue(getContactData.DXSUBURB);
            // 
            this.contactForm.controls['POSTALADDRESS1'].setValue(getContactData.POSTALADDRESS1);
            this.contactForm.controls['POSTALADDRESS2'].setValue(getContactData.POSTALADDRESS2);
            this.contactForm.controls['POSTALADDRESS3'].setValue(getContactData.POSTALADDRESS3);
            this.contactForm.controls['POSTALSUBURB'].setValue(getContactData.POSTALSUBURB);
            this.contactForm.controls['POSTALSTATE'].setValue(getContactData.POSTALSTATE);
            this.contactForm.controls['POSTALPOSTCODE'].setValue(getContactData.POSTALPOSTCODE);
            this.contactForm.controls['POSTALCOUNTRY'].setValue(getContactData.POSTALCOUNTRY);
            this.contactForm.controls['DX'].setValue(getContactData.DX);
            this.contactForm.controls['DXSUBURB'].setValue(getContactData.DXSUBURB);

          // first client diff TODO to click check box and address is disable and (postal adress  == "")  than postal adress = company address
          // if(res.DATA.CONTACTS[0].POSTALADDRESS1 == '' && res.DATA.CONTACTS[0].POSTALADDRESS2 == '' && res.DATA.CONTACTS[0].POSTALADDRESS3 == ''){
          //   this.contactForm.controls['POSTALADDRESS1'].setValue(getContactData.ADDRESS1);
          //   this.contactForm.controls['POSTALADDRESS2'].setValue(getContactData.ADDRESS2);
          //   this.contactForm.controls['POSTALADDRESS3'].setValue(getContactData.ADDRESS3);
          //   this.contactForm.controls['POSTALSUBURB'].setValue(getContactData.SUBURB);
          //   this.contactForm.controls['POSTALSTATE'].setValue(getContactData.STATE);
          //   this.contactForm.controls['POSTALPOSTCODE'].setValue(getContactData.POSTCODE);
          //   this.contactForm.controls['POSTALCOUNTRY'].setValue(getContactData.COUNTRY);
          //   this.contactForm.controls['DX'].setValue(getContactData.DX);
          //   this.contactForm.controls['DXSUBURB'].setValue(getContactData.DXSUBURB);
          // }else{
          // this.contactForm.controls['POSTALADDRESS1'].setValue(getContactData.POSTALADDRESS1);
          // this.contactForm.controls['POSTALADDRESS2'].setValue(getContactData.POSTALADDRESS2);
          // this.contactForm.controls['POSTALADDRESS3'].setValue(getContactData.POSTALADDRESS3);
          // this.contactForm.controls['POSTALSUBURB'].setValue(getContactData.POSTALSUBURB);
          // this.contactForm.controls['POSTALSTATE'].setValue(getContactData.POSTALSTATE);
          // this.contactForm.controls['POSTALPOSTCODE'].setValue(getContactData.POSTALPOSTCODE);
          // this.contactForm.controls['POSTALCOUNTRY'].setValue(getContactData.POSTALCOUNTRY);
          // this.contactForm.controls['DX'].setValue(getContactData.DX);
          // this.contactForm.controls['DXSUBURB'].setValue(getContactData.DXSUBURB);
          // }
        }
      }
    })
  }

  /**
   * This function is used to Enable form data 
   */
  EnableForm(){    
    this.contactForm.get('ADDRESS1').enable();
    this.contactForm.get('ADDRESS1').enable();
    this.contactForm.get('ADDRESS2').enable();
    this.contactForm.get('ADDRESS3').enable();
    this.contactForm.get('SUBURB').enable();
    this.contactForm.get('STATE').enable();
    this.contactForm.get('POSTCODE').enable();
    this.contactForm.get('COUNTRY').enable();
    this.contactForm.get('SAMEASSTREET').enable();
    this.contactForm.get('POSTALADDRESS1').enable();
    this.contactForm.get('POSTALADDRESS1').enable();
    this.contactForm.get('POSTALADDRESS2').enable();
    this.contactForm.get('POSTALADDRESS3').enable();
    this.contactForm.get('POSTALSUBURB').enable();
    this.contactForm.get('POSTALSTATE').enable();
    this.contactForm.get('POSTALPOSTCODE').enable();
    this.contactForm.get('POSTALCOUNTRY').enable();
    this.contactForm.get('DX').enable();
    this.contactForm.get('DXSUBURB').enable();
  }

  /**
   * This function is used to disable form data 
   */
  DisableForm(){
    this.contactForm.get('ADDRESS1').disable();
    this.contactForm.get('ADDRESS1').disable();
    this.contactForm.get('ADDRESS2').disable();
    this.contactForm.get('ADDRESS3').disable();
    this.contactForm.get('SUBURB').disable();
    this.contactForm.get('STATE').disable();
    this.contactForm.get('POSTCODE').disable();
    this.contactForm.get('COUNTRY').disable();
    this.contactForm.get('SAMEASSTREET').disable();
    this.contactForm.get('POSTALADDRESS1').disable();
    this.contactForm.get('POSTALADDRESS1').disable();
    this.contactForm.get('POSTALADDRESS2').disable();
    this.contactForm.get('POSTALADDRESS3').disable();
    this.contactForm.get('POSTALSUBURB').disable();
    this.contactForm.get('POSTALSTATE').disable();
    this.contactForm.get('POSTALPOSTCODE').disable();
    this.contactForm.get('POSTALCOUNTRY').disable();
    this.contactForm.get('DX').disable();
    this.contactForm.get('DXSUBURB').disable();
  }

  /**
   * This function is used to triggerSome event
   * @param f -this function is used to triger some event data
   */
  triggerSomeEvent(f):void {
    if (f.value.SAMEASSTREET == true) {
      let add1 = this.contactForm.get('ADDRESS1').value;
      let add2 = this.contactForm.get('ADDRESS2').value;
      let add3 = this.contactForm.get('ADDRESS3').value;
      let suburb = this.contactForm.get('SUBURB').value;
      let state = this.contactForm.get('STATE').value;
      let postcode = this.contactForm.get('POSTCODE').value;
      let country = this.contactForm.get('COUNTRY').value;
      //FOR SET VALUE
      this.contactForm.controls['POSTALADDRESS1'].setValue(add1);
      this.contactForm.controls['POSTALADDRESS2'].setValue(add2);
      this.contactForm.controls['POSTALADDRESS3'].setValue(add3);
      this.contactForm.controls['POSTALSUBURB'].setValue(suburb);
      this.contactForm.controls['POSTALSTATE'].setValue(state);
      this.contactForm.controls['POSTALPOSTCODE'].setValue(postcode);
      this.contactForm.controls['POSTALCOUNTRY'].setValue(country);
      this.contactForm.get('POSTALADDRESS1').disable();
      this.contactForm.get('POSTALADDRESS2').disable();
      this.contactForm.get('POSTALADDRESS3').disable();
      this.contactForm.get('POSTALSUBURB').disable();
      this.contactForm.get('POSTALSTATE').disable();
      this.contactForm.get('POSTALPOSTCODE').disable();
      this.contactForm.get('POSTALCOUNTRY').disable();
    } else {
      this.contactForm.controls['POSTALADDRESS1'].setValue("");
      this.contactForm.controls['POSTALADDRESS2'].setValue("");
      this.contactForm.controls['POSTALADDRESS3'].setValue("");
      this.contactForm.controls['POSTALSUBURB'].setValue("");
      this.contactForm.controls['POSTALSTATE'].setValue("");
      this.contactForm.controls['POSTALPOSTCODE'].setValue("");
      this.contactForm.controls['POSTALCOUNTRY'].setValue("");
      this.contactForm.get('POSTALADDRESS1').enable();
      this.contactForm.get('POSTALADDRESS2').enable();
      this.contactForm.get('POSTALADDRESS3').enable();
      this.contactForm.get('POSTALSUBURB').enable();
      this.contactForm.get('POSTALSTATE').enable();
      this.contactForm.get('POSTALPOSTCODE').enable();
      this.contactForm.get('POSTALCOUNTRY').enable();
    }
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub3?.unsubscribe();
      this.sub4?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This unction is used to autofillup the Information
   * @param searchOn 
   */
  autoFillUp(searchOn) {

    // let addressData = { "Address": this.contactForm.get('ADDRESS1').value };
   
   
    // let addressData2 = { "Address": this.contactForm.get('POSTALADDRESS1').value };
    if (this.contactForm.get('ADDRESS1').value.length >= 6  && searchOn == 1) {
     
      // this.findData(addressData, 1);
    }
   else if(this.contactForm.get('ADDRESS1').value.length < 6  && searchOn == 1)
    {
      this.searchData = null
    }

    if (this.contactForm.get('POSTALADDRESS1').value.length >= 6 && searchOn == 2) {
      // this.findData(addressData2, 2);
    }
     else if(this.contactForm.get('POSTALADDRESS1').value.length < 6  && searchOn == 2){
      this.searchAddressForm2 = null
    }
  }

/**
 * This function is used to set the autofield  
 * @param data -data value
 * @param index -index data value
 */
  setAutoField(data, index) {
    const addressData = {
      "Action": "GetAddressDetail",
      "Data": {
        "PlaceId": data?.PLACEID
      }
    }

    this.sub2 = this._mainAPiServiceService.getSetData(addressData, 'utility').subscribe((res) => {
      if(res.CODE == 200 && res.STATUS === 'success') {
        const address = res.DATA;
        let COMBINEDFIRST= address.COMBINED.split(",")[0];
        let BLANKDISC =address.COMBINED.split(",")[1]
        setTimeout(() => {
          if (index == 1) {
            this.contactForm.patchValue({
              'ADDRESS1': COMBINEDFIRST,
              'STATE': address.STATE,
              'POSTCODE': address.POSTALCODE,
              'COUNTRY': address.COUNTRY,
              'SUBURB':address.DISTRICT || BLANKDISC,
            })
          } else if (index == 2) {
            this.contactForm.patchValue({
              'POSTALADDRESS1': COMBINEDFIRST,
              'POSTALSTATE': address.STATE,
              'POSTALPOSTCODE': address.POSTALCODE,
              'POSTALCOUNTRY': address.COUNTRY,
              'POSTALSUBURB':address.DISTRICT || BLANKDISC,
            })
          }
        }, 5);
      }
    })  
  }

  /**
   * This function is used to clear the search data value
   */
  clearSearch(){
    this.serch=''
  }
  /**
   * This function is used to findData 
   * @param addressData -Address Data value
   * @param indx -indx data value
   */
  findData(addressData): Observable<any> {
    return this._mainAPiServiceService.getSetData(addressData, 'utility')
  }

}
