<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>{{(data.action == 'Email') ? 'Email Template' : (data.action == 'Document Pack') ? 'Pack Template' : ''}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog"><mat-icon>close</mat-icon></button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="select_matter_main_div">
            <div class="align" *ngIf="data.action == 'Email'">
                <app-email-templete [inputShowLocalFilter]="false"></app-email-templete>
            </div>
            <div class="align" *ngIf="data.action == 'Document Pack'">
                <app-packs [ACTION]="'Document Pack'"></app-packs>
            </div>
            <!-- <div class="align" *ngIf="data.action == 'Precedent'">
                <app-precedents></app-precedents>
            </div> -->
            
            <!-- <div class="example-containerdata" *ngIf="Templatedata">
                
        </div> -->
    </div>
    
    <mat-dialog-actions align="end" class="popup_ftr contact_select_footer">
        <button mat-raised-button [mat-dialog-close]="true" color="accent" *ngIf="data.action == 'Email'">Select</button>
        <button mat-raised-button [mat-dialog-close]="true" color="accent" *ngIf="data.action == 'Document Pack'" [disabled]="!isShowSelectButton">Select</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>