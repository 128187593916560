import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";
import { ContactDialogComponent } from '../../../contact/contact-dialog/contact-dialog.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ContactSelectDialogComponent } from '@pages/contact/contact-select-dialog/contact-select-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-estate-beneficiary',
  templateUrl: './estate-beneficiary.component.html',
  styleUrls: ['./estate-beneficiary.component.scss']
})
export class EstateBeneficiaryComponent implements OnInit, OnDestroy {
  @ViewChild('beneficiaryForm', { static: true }) ngForm: NgForm;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  testBool: boolean=false;
  
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  dialogTitle: any;
  isLoadingResults: boolean = false;
  action: any;
  isspiner: boolean = false;
  confirmDialogRef: any;
  currentMatter: any;
  ContactDetails: any;
  EstateBeneficiaryForm:any ={};
  estatbeneficiaryData:any;
  formChangesSubscription:Subscription;
  RelationshipData:any;
  windowNameId:any;
  toolTipList: any;
  Contactname: any = [];
  matterdata: any;
  searchContact: any;
  exportdatavalueOFContact: any;
  result: any;
  searchContactData;
  Contactlist: any=[];
  selectedContctData: any = [];
  appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
  private _unsubscribeAll$: Subject<void> = new Subject();
  private searchTerms = new Subject<string>();
  subscription: Subscription;
  
  @ViewChild('MatterInput2', { static: false }) MatterInputContact: ElementRef<HTMLInputElement>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService, public _matDialog: MatDialog,
    private toastr: ToastrService, public dialogRef: MatDialogRef<EstateBeneficiaryComponent>,
    public datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    public globalFunService:GlobalFunctionsService,
    public tooltipService:TooltipService,) {

    this.action = data.action;
 
    
    this.behaviorService.estateAssets$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
          this.estatbeneficiaryData = result;
      }
    });

    this.behaviorService.estateBeneficiary$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result =>{
      this.estatbeneficiaryData = result;
    })

    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.currentMatter = result;
    //   } else {
    //     this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
    //   }
    // });
    
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
           const windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
      this.currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
      if (this.currentMatter) {
        // localStorage.setItem('set_active_matters', JSON.stringify(this.currentMatter));
      } else {
        if (result) {
          this.currentMatter = result;
          localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        } else {
            this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
            localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        }
      }
    });
    

    if (this.action === 'new') {
      this.ContactDetails = data.result;
      this.EstateBeneficiaryForm.CONTACTGUID = (this.ContactDetails.CONTACTGUID);
      this.EstateBeneficiaryForm.CONTACTNAME = (this.ContactDetails.CONTACTNAME + ' - ' + this.ContactDetails.SUBURB);
      this.dialogTitle = 'New Beneficiary';
      this.validationColor();
      this.LoadBeneficiarydata();  
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Beneficiary';
      this.LoadData();
    }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {   
    this.getLookupType();
    this.formChangesSubscription = this.ngForm.form.valueChanges.subscribe(x => {
      this.changeForControl(x)
    })
    this.setTooltipData()
    this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.selectContactFormSearch(term)) // Switch to new observable on new term
    ).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.exportdatavalueOFContact = response.DATA
        this.searchContactData = response.DATA.RECORDS
        this.Contactlist = this.searchContactData;
      }
    });
   }

   ngOnDestroy(): void {
       this.formChangesSubscription?.unsubscribe();
       this._unsubscribeAll$.next();
       this._unsubscribeAll$.complete();
       this.subscription?.unsubscribe();
   }

   /**
    * This function is used to set Tool tip data value
    */
   async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['EstateBeneficiary'] ){
      this.toolTipList  = TooltipData['EstateBeneficiary'];
    }else{
      this.tooltipService.setToolTipData = ('EstateBeneficiary');
     }
  };

  /**
   * This function is used to toggle for the updates
   */
  ToggleForUpadte():void {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to copyTooltip text
   * @param event -data event value
   */
  copyTooltipText(event: any) {
    navigator.clipboard.writeText(event)
  };

  /**
   * This function is used to get the lookup type
   */
  getLookupType(){
    this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'Relationship' }}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        this.RelationshipData = responses.DATA.LOOKUPS;
      } else if (responses.MESSAGE == 'Not logged in') {
        // this.dialogRef.close(false);
      }
      this.isLoadingResults = false;
    });
  }

  /**
   * This functionn is used to
   */
  changeForControl(formControl){
    const dubObj ={...this.errorWarningDataArray.Error};
    let element = this.errorWarningData.Error;
    for (const [key, value] of Object.entries(dubObj)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element[key];
       dubObj[key] = value;
       this.errorWarningDataArray.Error = {...dubObj}
     } else if (!formControl[KEY]) {
        element[key] = dubObj[key];
     }
    }

    const dubObj_1 ={...this.errorWarningDataArray.Warning};
    let element_1 = this.errorWarningData.Warning;
    for (const [key, value] of Object.entries(dubObj_1)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element_1[key];
       dubObj_1[key] = value;
       this.errorWarningDataArray.Warning = {...dubObj_1}
     } else if (!formControl[KEY]) {
        element_1[key] = dubObj_1[key];
     }
    }
  }

  /**
   * This function is used to set the validation color data
   */
  validationColor() {
    let tempError: any = [];
    let tempWarning: any = [];
    // tempError["CONTACTGUID"] = {};
    // tempError["TENANCY"] = {};
    this.errorWarningData = { Error: tempError, Warning: tempWarning };
    this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
  }

  /**
   * This function is used to Load the Data value
   */
  LoadData():void {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({ Action: "GetData", FILTERS: { MATTERGUID: this.currentMatter.MATTERGUID, ESTATEBENEFICIARYGUID: this.estatbeneficiaryData.ESTATEBENEFICIARYGUID } }, 'estate-beneficiary').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
            this.EstateBeneficiaryForm = response.DATA.RECORDS[0];
            this.EstateBeneficiaryForm['NOMINALENTITLEMENT'] = (response.DATA.RECORDS[0]['NOMINALENTITLEMENT'] == 'No' )? false : true;
            this.EstateBeneficiaryForm['HASBEENDISTRIBUTED'] = (response.DATA.RECORDS[0]['HASBEENDISTRIBUTED'] == 'No' )? false : true;
            this.toolTipList = response.DATA.FIELDTIPS;
            this.Contactname=[this.EstateBeneficiaryForm.CONTACTNAME];
            // let temTODATE = this.EstateBeneficiaryForm.DATETRANSFERRED.split("/");
            // this.EstateBeneficiaryForm.DATETRANSFERRED =  new Date(temTODATE[1] + "/" + temTODATE[0] + "/" + temTODATE[2])
        }
        this.isLoadingResults = false;
    }, error => {
        this.isLoadingResults = false;
        this.toastr.error(error);
    });
  }

  /**
   * This function is used to Load the Benificiarydata value.
   */
LoadBeneficiarydata():void{
  this._mainAPiServiceService.getSetData({ Action: "Default", DATA: { MATTERGUID: '', ESTATEBENEFICIARYGUID: '' } }, 'estate-beneficiary').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
    if (response.CODE == 200 && response.STATUS == "success") {  
       this.EstateBeneficiaryForm=response.DATA.DEFAULTVALUES;  
       this.EstateBeneficiaryForm['NOMINALENTITLEMENT'] = (response.DATA.DEFAULTVALUES['NOMINALENTITLEMENT'] == 'No' )? false : true;
       this.EstateBeneficiaryForm['HASBEENDISTRIBUTED'] = (response.DATA.DEFAULTVALUES['HASBEENDISTRIBUTED'] == 'No' )? false : true;
    }
    this.isLoadingResults = false;
}, error => {
    this.isLoadingResults = false;
    this.toastr.error(error);
});
}

/**
 * This function is used to Onsubmit the form data 
 * @param form-form data value
 */
  OnSubmit(form?:any){
    this.isspiner = true;
    const FormAction = this.action === 'new' ? 'insert' : 'update';
    delete this.EstateBeneficiaryForm.CONTACTNAME;
    let Data: any = {...this.EstateBeneficiaryForm,  MATTERGUID: this.currentMatter.MATTERGUID}
    let details = { FormAction: FormAction, VALIDATEONLY: true, Data: Data, };
    this._mainAPiServiceService.getSetData(details, "estate-beneficiary").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 451 && response.STATUS == "warning") {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 450 && response.STATUS == "error") {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
        }
    }, (error) => {
        this.isspiner = false;
        this.toastr.error(error);
    });
  }

  /**
   * This function is used to check the Validation 
   */
 async checkValidation(bodyData: any, details: any) {
      // let errorData: any = [];
      // let warningData: any = [];
      // let tempError: any = [];
      // let tempWarning: any = [];
      // bodyData.forEach(function (value) {
      //     if (value.VALUEVALID == "No") {
      //         errorData.push(value.ERRORDESCRIPTION);
      //         tempError[value.FIELDNAME] = value;
      //     } else if (value.VALUEVALID == "Warning") {
      //         tempWarning[value.FIELDNAME] = value;
      //         warningData.push(value.ERRORDESCRIPTION);
      //     }
      // });
      // this.errorWarningData = { Error: tempError, Warning: tempWarning };
      // this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
      // if (Object.keys(errorData).length != 0) {
      //     this.toastr.error(errorData);
      //     this.isspiner = false;
      // } else if (Object.keys(warningData).length != 0) {
      //     this.isspiner = false;
      //     this.confirmDialogRef = this._matDialog.open(
      //         FuseConfirmDialogComponent,
      //         {
      //             disableClose: true,
      //             width: "100%",
      //             data: warningData,
      //         }
      //     );
      //     this.confirmDialogRef.componentInstance.confirmMessage =
      //         "Are you sure you want to Save?";
      //     this.confirmDialogRef.afterClosed().subscribe((result) => {
      //         if (result) {
      //             this.isspiner = true;
      //             this.saveData(details);
      //         } else {
      //             this.isspiner = false;
      //         }
      //         this.confirmDialogRef = null;
      //     });
      // } else if (
      //     Object.keys(warningData).length == 0 &&
      //     Object.keys(errorData).length == 0
      // ) {
      //     this.saveData(details);
      // }

      this.isspiner = false;
      await this.globalFunService.checkValidation(bodyData, details)
      .subscribe(data => {
        if (data) {
          this.errorWarningData = data.errorWarningData;
          //this.errorWarningDataArray = data.errorWarningData;
          if (data.callback) {
            this.saveData(details);
          }
        }
      });
  } 

  /**
   * This function is used to save the data 
   * @param data -data value
   */
  saveData(data: any):void {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, "estate-beneficiary").subscribe((response) => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            if (this.action !== "edit") {
                this.toastr.success(" save successfully");
            } else {
                this.toastr.success(" update successfully");
            }
            $('#getEstateBeneficiary').click();
            this.dialogRef.close(true);
        } else if (response.CODE == 451 && response.STATUS == "warning") {
            this.isspiner = false;
            this.toastr.warning(response.MESSAGE);
        } else if (response.CODE == 450 && response.STATUS == "error") {
            this.isspiner = false;
            this.toastr.error(response.MESSAGE);
        } else if (response.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
        }
    }, (error) => {
        this.isspiner = false;
        this.toastr.error(error);
    });
  }    

  /**
   * This function is used to contact the matter data value 
   */
  ContactMatter():void {
    this.searchContactData = [];
    this.Contactlist = this.searchContactData;

    const dialogRef = this._matDialog.open(ContactSelectDialogComponent, {
      width: '100%', disableClose: true, data: { type: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'MatterPopupComponent');
      if (result) {
        this.EstateBeneficiaryForm.CONTACTGUID = (result.CONTACTGUID);
        this.EstateBeneficiaryForm.CONTACTNAME = (result.CONTACTNAME + ' - ' + result.SUBURB);
        this.Contactname=[result.CONTACTNAME];
      }
    });
  }

/**
 * This function is used to select the New contact data.
 */
  async selectNewContect(){
    const dialogRef = await this._matDialog.open(ContactDialogComponent, {
      disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true , contect_details : '' , flagNewConactMatter : true}
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.CONTACTGUID) {
          let contectIdIs = await this.getContactNewId();
          this.behaviorService.MatterpopupContactData$.next(contectIdIs);
          this.EstateBeneficiaryForm['CONTACTGUID'] = result.CONTACTGUID;
          this.EstateBeneficiaryForm['CONTACTNAME'] = result.CONTACTNAME;
          this.Contactname = [result.CONTACTNAME];
         // this.getPacket({ CONTACTGUID: result.CONTACTGUID});
          // this.closeDialogRef.close(result);
        }else{
          this.behaviorService.MatterpopupContactData$.next(null)
        }
      }
    });
  }

  /**
   * This function is used to getContact New Id.
   */
  getContactNewId(){
    let contecttId = '';
    let postData = {
      "Action": "GetContactId",
      "VALIDATEONLY": true,
      "Data": {},
    };

    return new Promise((response , reject)=>{
      this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
        if(res.CODE == 200){
          contecttId = res.DATA.CONTACTID;
          response(contecttId);
        }else{
          response(null);
        }
      });
    })
  };

  onKeyUp(event: any): void {
    const searchValue = event.target.value;
    this.searchTerms.next(searchValue);
  }

/**
 * This functgion is used to select Contact form search data value.
 */
  selectContactFormSearch(event) {
    // let searchobj = { FastSearch:'True', Search: event }
    let payload = {
      "ACTION": "GetData",
      "FILTERS": {
        "FASTSEARCH": 1,
        "SEARCH": event
      },
    }
    return this._mainAPiServiceService.getSetData(payload, 'contact')
  }

  /**
   * This function is used to Show the Contact data value
   */
  Contactdatashow(event):void {
    if(this.exportdatavalueOFContact && this.exportdatavalueOFContact.RECORDS){
      this.result=this.exportdatavalueOFContact.RECORDS[0]
      this.EstateBeneficiaryForm['CONTACTNAME'] = null;
      if (event.input) {
        event.input.value = '';
      } else {
        this.MatterInputContact.nativeElement.value = '';
      }
    } 

    if (this.result) {
      localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
      this.EstateBeneficiaryForm['CONTACTGUID'] = this.result.CONTACTGUID;
      this.EstateBeneficiaryForm['CONTACTNAME'] = this.result.CONTACTNAME;
      this.Contactname=[this.result.CONTACTNAME];
      this.data.safeCustodyData.result = this.result;
     // this.getPacket({ CONTACTGUID: this.f.CONTACTGUID.value });
    }
   
    
   
  }

  /**
   * This function is used to remove the contact data value
   */
  removeContact(fruit: any) {
    const index = this.Contactname.indexOf(fruit);
    if (index >= 0) {
      this.Contactname.splice(index, 1);
     // this.contactForm['COMPANYCONTACTGUID'].setValue('');
      //this.Deliverable = [];
    }
    let sortname = fruit.split('-')[0]
    this.MatterInputContact.nativeElement.value = '';
    this.rmContactdata(sortname)
  };

/**
 * This function is used to rm Contact data. 
 */
  rmContactdata(sortname) {
    this.selectedContctData.forEach((element: any, index: any) => {
      if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
        this.selectedContctData.splice(index, 1);
      }
    })
  }

  /**
   * This function is used to add the Contact data value
   */
  addContact(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    this.Contactlist.forEach((element: any) => {
      if (element.CONTACTNAME == event.value) {
        // Add our fruit
        const array3 = this.Contactname;
        if (value) {
          if (this.Contactname.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.Contactname = [value]
          }
        }
      }
    });

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
    this.EstateBeneficiaryForm['CONTACTNAME'] = null;
  };

  /**
   * This function is used to select the Contact data value
   */
  selectedContact(result:any):void{
    if (result) {
      localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
      this.EstateBeneficiaryForm['CONTACTGUID'] = result.CONTACTGUID;
      this.EstateBeneficiaryForm['CONTACTNAME'] = result.CONTACTNAME;
      this.Contactname=[result.CONTACTNAME];
     // this.data.safeCustodyData.result = result;
     // this.getPacket({ CONTACTGUID: this.f.CONTACTGUID.value });
    }
  }
 
}
