import { Injectable } from "@angular/core";
import {
    ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import * as _ from "lodash";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
// import { FuseConfigService } from "src/@fuse/services/config.service";
// import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
// import { navigation } from "src/app/navigation/navigation";
import { Router } from "@angular/router";
// import { ContactDialogComponent } from './../../../main/pages/contact/contact-dialog/contact-dialog.component';
// import { LicenceAgreementComponent } from "../../../main/licence-agreement/licence-agreement.component";

import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
// import { ContactCorresDetailsComponent } from 'src/app/main/pages/contact/contact-corres-details/contact-corres-details.component';
// import { ReportsComponent } from "src/app/main/reports/reports.component";
import { ToastrService } from "ngx-toastr";
// import { TimeEntriesComponent } from 'src/app/main/pages/time-entries/time-entries.component';
// import { TimeEntryDialogComponent } from 'src/app/main/pages/time-entries/time-entry-dialog/time-entry-dialog.component';
// import { MatterPopupComponent } from 'src/app/main/pages/matters/matter-popup/matter-popup.component';
// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
// import { ReceiptDilogComponent } from 'src/app/main/pages/invoice/receipt-dilog/receipt-dilog.component';
// import { InvoiceDetailComponent } from "src/app/main/pages/invoice/invoice-detail/invoice-detail.component";
// import { SpendMoneyAddComponent } from 'src/app/main/pages/spend-money/spend-money-add-dialog/spend-money-add.component';
// import { GeneralReceiptDilogComponent } from "src/app/main/pages/receive-money/general-receipt-dilog/general-receipt-dilog.component";
// import { InstantInvoiceDailogComponent } from 'src/app/main/pages/invoice/instant-invoice-dailog/instant-invoice-dailog.component';
// import { InvoiceAddDailogComponent } from 'src/app/main/pages/invoice/invoice-add-dailog/invoice-add-dailog.component';
// import { MatterDialogComponentForTemplate } from "src/app/main/pages/template/matter-dialog/matter-dialog.component";

// import { UserDialogComponent } from "./../../../main/pages/users/user-dialog/user-dialog.component";
// import { ActivityDialogComponent } from './../../../main/pages/activities/activity-dialog/activity-dialog.component';
// import { ChangePasswordComponent } from "src/app/main/change-password/change-password.component";

// import { DocumentDailogComponent } from "./../../../main/pages/document-register/document-dailog/document-dailog.component";
// import { EmailDailogComponent } from "./../../../main/pages/template/email-templete/email-dailog/email-dailog.component";
// import { PacksDailogComponent } from "./../../../main/pages/template/packs/packs-dailog/packs-dailog.component";
// import { ChartAcDailogComponent } from "./../../../main/pages/chart-account/chart-ac-dailog/chart-ac-dailog.component";
// import { FileNoteDialogComponent } from 'src/app/main/pages/matters/file-note-dialog/file-note-dialog.component';
// import { BankingDialogComponent } from "src/app/main/pages/banking/banking-dialog.component";
// import { GeneralDailogComponent } from "./../../../main/pages/general-journal/general-dailog/general-dailog.component";
// import { AuthorityDialogComponent } from 'src/app/main/pages/main-authorities/authority-dialog/authority-dialog.component';
// import { ReportFilterComponent } from "./../../../main/pages/general-journal/report-filter/report-filter.component";
// import { ChronItemDailogComponent } from "./../../../main/pages/legal-details/chronology/chron-item-dailog/chron-item-dailog.component";
// import { DairyDailogComponent } from "./../../../main/pages/diary/dairy-dailog/dairy-dailog.component";
// import { ResumeTimerComponent } from "src/app/main/pages/time-entries/resume-timer/resume-timer.component";
// import { CopyTemplateComponent } from "src/app/main/pages/template/template-list/copy-template/copy-template.component";
// import { SetLetterHeadComponent } from "src/app/main/pages/template/template-list/set-letterhead/set-letterhead.component";
// import { EditTemplateComponent } from "src/app/main/pages/template/template-list/edit-template/edit-template.component";
// import { WriteOffTimeEntryComponent } from "src/app/main/pages/time-entries/write-off-time-entry/write-off-time-entry.component";
// import { SafeCustodyDialogeComponent } from "src/app/main/pages/legal-details/safecustody/safe-custody-dialog/safe-custody-dialog.component";
// import { TrustMoneyDialogeComponent } from "src/app/main/pages/Trust Accounts/trust-money/trust-money-dialoge/trust-money-dialoge.component";
// import { ContactSelectDialogComponent } from "src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component";
// import { TaskDialogeComponent } from "src/app/main/pages/Task/task-dialoge/task-dialoge.component";
// import { WriteOffInvoiceComponent } from "src/app/main/pages/invoice/newWriteOffInvoice/newWriteOffInvoice.component";
// import { TopicDialogComponent } from 'src/app/main/pages/main-authorities/topic/topic-dialog/topic-dialog.component';
// import { AuthorityDialogComponent } from "src/app/main/pages/globally-Authority/main-authorities/authority-dialog/authority-dialog.component";
// import { TopicDialogComponent } from "src/app/main/pages/globally-Authority/main-authorities/topic/topic-dialog/topic-dialog.component";
// import { EstimateDilogComponent } from "src/app/main/pages/time-billing/estimate/estimate-dilog/estimate-dilog.component";
// import { GenerateInvoiceComponent } from 'src/app/main/pages/invoice/generate-invoice/generate-invoice.component';
// import { PacketsDialogComponent } from "src/app/main/pages/globally-safecustody/packets/packets-dialog/packets-dialog.component";
// import { TrustChartOfAccountDailogComponent } from "src/app/main/pages/trust-chart-of-account/trust-chart-of-account-dailog/trust-chart-of-account-dailog.component";
// import { round } from "lodash";
// import { ReCalcTimeEntriesDialogeComponent } from "src/app/main/pages/time-billing/re-calc-timeEntrie-dialoge/re-calc-timeEntrie-dialoge.component";
// import { MatterFolderDailogComponent } from "./matter-folder-dailog/matter-folder-dailog.component";
// import { AdjustmentDialogComponent } from "src/app/main/pages/account-reconciliation/adjustment-dialog/adjustment-dialog.component";
// import { ActivitySundryDialogComponent } from "src/app/main/pages/activities/activity-sundry-dialog/activity-sundry-dialog.component";
// import { MoveEntryComponent } from "src/app/main/pages/time-entries/move-entry/move-entry.component";
// import { SendEmailComponent } from "src/app/main/pages/template/email-templete/send-email/send-email.component";
// import { CalculateInterestPopupComponent } from "src/app/main/pages/invoice/calculate-interest-popup/calculate-interest-popup.component";
// import { CheckForCreditsComponent } from "src/app/main/pages/invoice/check-for-credits/check-for-credits.component";
// import { AllowancePopupComponent } from "src/app/main/pages/legal-details/settlement/allowance-popup/allowance-popup.component";
// import { ChequePopupComponent } from "src/app/main/pages/legal-details/settlement/cheque-popup/cheque-popup.component";
// import { GenerateDocumentPopupComponent } from 'src/app/main/pages/template/generate-document-popup/generate-document-popup.component';
// import { GeneratePacksPopupComponent } from "src/app/main/pages/template/packs/generate-packs-popup/generate-packs-popup.component";
// import { ConfirmPopupComponent } from 'src/app/main/confirm-popup/confirm-popup.component';
// import { SelectBankingDialogComponent } from "src/app/main/pages/banking/select-banking-dialog/select-banking-dialog.component";
// import { EndOfMonthReportsComponent } from 'src/app/main/end-of-month-reports/end-of-month-reports.component';
// import { SyncToXeroPopupComponent } from "src/app/main/sync-to-xero-popup/sync-to-xero-popup.component";
// import { UploadTemplatesPopupComponent } from "src/app/main/upload-templates-popup/upload-templates-popup.component";
// import { UserSelectPopupComponent } from "src/app/main/pages/matters/user-select-popup/user-select-popup.component";
// import { FolderCreateComponent } from "src/app/main/pages/template/template-list/folder-create/folder-create.component";
// import { CloseYearAccountsDialogComponent } from "src/app/main/pages/close-year-accounts-dialog/close-year-accounts-dialog.component";
// import { SignaturesPopupComponent } from "src/app/main/pages/inbox/signatures-popup/signatures-popup.component";
// import { RenameEmailComponent } from "src/app/main/pages/inbox/rename-email/rename-email.component";
// import { SelectEmailPopupComponent } from "src/app/main/pages/template/packs/select-email-popup/select-email-popup.component";
// import { EstateAssetsComponent } from "src/app/main/pages/legal-details/estate/estate-assets/estate-assets.component";
// import { EstateLiabilityComponent } from "src/app/main/pages/legal-details/estate/estate-liability/estate-liability.component";
// import { EstateBeneficiaryComponent } from "src/app/main/pages/legal-details/estate/estate-beneficiary/estate-beneficiary.component";
// import { EstateDetailsComponent } from "src/app/main/pages/legal-details/estate/estate-details/estate-details.component";
// import { DomSanitizer } from "@angular/platform-browser";
// import { TierPermissionPipe } from "src/app/_pipes/tier-permission.pipe";
// import { SplitTimeEntryComponent } from "src/app/main/pages/split-time-entry/split-time-entry.component";
// import { Location } from "@angular/common";
// import { MatterDocumanrPopupComponent } from 'src/app/main/pages/template/matter-documanr-popup/matter-documanr-popup.component';
// import { DeliverableDailogComponent } from "src/app/main/pages/time-entries/deliverable-dailog/deliverable-dailog.component";
// import { SearchandreplaceworkitemComponent } from "src/app/main/pages/time-billing/searchandreplaceworkitem/searchandreplaceworkitem.component";
// import { LoggedonUserComponent } from "src/app/main/pages/account-managment/loggedon-user/loggedon-user.component";
// import { ProtectedTrustDialogComponent } from "src/app/main/pages/Trust Accounts/trust-money/protected-trust-dialog/protected-trust-dialog.component";
// import { Chart, registerables } from "chart.js";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ContactDialogComponent } from "@pages/contact/contact-dialog/contact-dialog.component";
import { TimeEntriesComponent } from "@pages/time-entries/time-entries.component";
import { ConfirmPopupComponent } from "src/app/main/confirm-popup/confirm-popup.component";
import { EndOfMonthReportsComponent } from "src/app/main/end-of-month-reports/end-of-month-reports.component";
import { MatterPopupComponent } from "@pages/matters/matter-popup/matter-popup.component";
import { MatterDocumanrPopupComponent } from "@pages/template/matter-documanr-popup/matter-documanr-popup.component";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { TimeEntryDialogComponent } from "@pages/time-entries/time-entry-dialog/time-entry-dialog.component";
import { ActivityDialogComponent } from "@pages/activities/activity-dialog/activity-dialog.component";
import { FileNoteDialogComponent } from "@pages/matters/file-note-dialog/file-note-dialog.component";
import { ContactCorresDetailsComponent } from "@pages/contact/contact-corres-details/contact-corres-details.component";
import { InstantInvoiceDailogComponent } from "@pages/invoice/instant-invoice-dailog/instant-invoice-dailog.component";
import { InvoiceAddDailogComponent } from "@pages/invoice/invoice-add-dailog/invoice-add-dailog.component";
import { GenerateInvoiceComponent } from "@pages/invoice/generate-invoice/generate-invoice.component";
import { GenerateDocumentPopupComponent } from "@pages/template/generate-document-popup/generate-document-popup.component";
import { ReceiptDilogComponent } from "@pages/invoice/receipt-dilog/receipt-dilog.component";
import { SpendMoneyAddComponent } from "@pages/spend-money/spend-money-add-dialog/spend-money-add.component";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { TimersService } from "@_services/timers.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SocketService } from "@_services/socket.service";
@Injectable({
    providedIn: "root",
})
export class ToolbarServiceService {
    ActiveConflictActionType: any = null;
    // dataFrimDashboard :any ={}
    matterselected: any;
    MainAuthorityData: any;
    activeMenu: any;
    target: any;
    TopicMaindata: any;
    LegalAuthorityData: any;
    LegalAuthotool: string;
    LegalAuthoritySubAuthata: any;
    LegalSubAuthotool: any;
    mainlegalAuthWebUrl: any;
    recouncileItemdata: any;
    ShowGenerateInvoice: string;
    DairyData: any;
    JournalData: any;
    receiptData: any;
    receipTitle: any = "View";
    DisabledReceiptTool: string;
    PacketsData: any;
    CurrentDate: any;
    PathOfRouter: any;
    SafeCustodyData: any;
    ShowMatLable: any;
    ShowTranstionsLable: any;
    ChartHandlingData: any = [];
    TotalUnbilledWIP: any = 0;
    TotalOutstanding: any = 0;
    WorkInProgressData: any;
    CommonToolbarHSData: any;
    ClickTypeForTrustChartHD: any;
    AccountClassForTrustChartHD: any;
    isMatterData: string;
    selectedChequeStatment: any;
    selectedAllowances: any;
    isContactData: string;
    isTimeEntryData: string;
    isInvoiceData: string;
    isSpendMoneyData: string;
    isReceiptData: string;
    isTaskData: string;
    isChartaccountData: string;
    isSafeCustody: string;
    isSafePackData: string;
    isGeneralJounralData: string;
    isUserData: string;
    isEstimateData: string;
    isdeliverable: string;
    isMatterInvoiceData: string;
    isWIPData: string;
    isChronoData: string;
    isFileNoteData: string;
    isDocRegData: string;
    isDiaryData: string;
    isActivityData: string;
    isTrustMoneyList: boolean = false;
    isTrustMoneyListData: any;
    DocumentRegisterSelectedFolder: any = [];

    isGenrateReport: boolean = false;
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    SnapShotAppPermissions: boolean = false;
    @ViewChild(TimeEntriesComponent, { static: false })
    TimeEntrieschild: TimeEntriesComponent;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    selectedLanguage: any;
    selectedIndex: number;
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    isTabShow: number;
    ENDOFMONTHDATE: any = {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    confirmDialogRef2: MatDialogRef<ConfirmPopupComponent>;
    confirmDialogRef3: MatDialogRef<EndOfMonthReportsComponent>;
    //timer setting
    timerId: any;
    TotalTimer: number = 0;
    prevMatterArray: any[] = [];
    timerInterval: any;
    currentTimer: any = 0;
    currentTimerHMS: any;
    ReportListObj: any[] = [];
    getContactData: any;
    detailwidth: any;
    activeSubMenu: any = "";
    isInvoice: any;
    isforCredits: string;
    greenTheme: any = false;
    CreatDocumentChild: any;
    TemplateGenerateData: any = [];
    // Private
    private _unsubscribeAll: Subject<any>;
    activedocument: any;
    clickedBtn: string;
    hideShowDoc: string;
    isDocumentGenerateHide: string;
    templateRoter: string;
    spendMoneyMenu: string;
    emailrouting: string;
    precedentsRouting: string; // add by web27
    TemplateUrlHandel: string;
    packrouting: string;
    KitName: any;
    KitGUid: any;
    packsToobar: string;
    EmailtemplateData: any = [];
    SendMoney_dataGUID: any;
    DocRegData: any = [];
    AccountGUID: any;
    chartAccountDetail: any;
    isMainAccount: boolean = false;
    isAccountEditable: boolean = false;
    TaskData: any;
    FileNotesData: any;
    conflictData: any;
    ChronologyLegalData: any;
    disconflictToolbar: string;
    DisEnTimeEntryToolbar: string;
    timeEntryData: any;
    DisMainAuthorityToolbar: string;
    estimateData: any;
    deliverableData: any;
    journalText: any = "View";
    journalLinktype: any;
    storeFolder: any;
    recouncileTab: any;
    isStoreTrustVal: any;
    valOfIsTrust: any;
    storePastRecouncileData: any;
    tabName: any;
    courtFormsRouting: any;
    courtFormData: any;
    TrustEndofMonthReport: any;
    isTrustEndofMonthReport: boolean = false;
    selectedMatterRow: any;
    toolbarsetting: any;
    isDocTempletFolder: boolean = true;
    isDocTempletType: boolean = false;
    isOpenDesktopWord: boolean = false;
    isUnusedAdjustment: boolean = false;
    isClickTypeTrust: any = null;
    notificationList: any = "";
    notificationBadge: number = 0;
    isInboxDisable: boolean = false;
    PrintBankingSlipVal: any;
    invoiceallarr: any = [];
    // this variable use for retry function for report
    ApiRepoParm1: any;
    ApiRepoParm2: any;
    // End this variable use for retry function for Reprot
    DATEDESCdata: any = [];
    DATEDESCValuedata: any = [];
    DATEDESCClrdata: any = [];
    // Retry api For Delete
    DeletePostData: any;
    ApiMethod: any;
    refreshId: any = [];
    oldIsTrackId: any;
    hidematterTab: boolean = true;
    // Retry api For Delete
    selectedEmailList: any[];
    selectedMatterTab: any;
    //use for tier permission
    accessTierPermission: any = JSON.parse(
        localStorage.getItem("set_tier_permission")
    );
    openWebsite: any;
    destoryCurrentState: any;
    editAssest: boolean = false;
    editliability: boolean = false;
    editbeneficiary: boolean = false;
    isProperty: boolean = true;
    deliverableDatalegel: any;
    // isProperty:boolean = false
    splitAccessTier: any;
    dissabledeleteButton: boolean = false;
    DerivableDropShow: boolean = false;
    selUserMenuButton: string = "";
    // @ViewChild('widgetsContent', { static: true }) widgetsContent: ElementRef<any>;
    selectedMatterForinvoice: any = [];
    isUsersUserActivity: boolean = false;
    matterDetailsFlag: boolean = false;
    showMatterDetailsMenu: boolean = false;
    diaryMatterInfo: any;
    Object: any;
    listMenu = [
        {
            id: 1,
            name: "Matter",
            icon: "icon_matter_new_d.ico",
        },
        {
            id: 2,
            name: "Contact",
            icon: "icon_contact_new_d.ico",
        },
        {
            id: 3,
            name: "File note",
            icon: "icon_filesnotes_new_d.ico",
        },
        {
            id: 4,
            name: "Time entry",
            icon: "icon_time_cost_new_d.ico",
        },
        {
            id: 5,
            name: "Activity",
            icon: "icon_time_cost_new_d.ico",
        },
        {
            id: 6,
            name: "Sundry",
            icon: "icon_expenses_new_d.ico",
        },
        {
            id: 7,
            name: "Task",
            icon: "icon_tasks_new_d.ico",
        },
        {
            id: 8,
            name: "Spend money",
            icon: "icon_expenses_new_d.ico",
        },
        {
            id: 9,
            name: "Matter receipt",
            icon: "icon_receipt_new_d.ico",
        },
        {
            id: 10,
            name: "General income",
            icon: "icon_receipt_new_d.ico",
        },
        {
            id: 11,
            name: "Safe custody",
            icon: "icon_safecustody_new_d.ico",
        },
        {
            id: 12,
            name: "Instant invoice",
            icon: "icon_invoice_new_d.ico",
        },
        {
            id: 13,
            name: "Itemised invoice",
            icon: "icon_invoice_new_d.ico",
        },
        {
            id: 14,
            name: "Appointment",
            icon: "icon_diary_new_d.ico",
        },
    ];
    permission: any;
    constructor(
        // private elementRef: ElementRef,
        // private _fuseConfigService: FuseConfigService,
        // private _fuseSidebarService: FuseSidebarService,
        // private authenticationService: AuthenticationService,
        private router: Router,
        public dialog: MatDialog,
        public _matDialog: MatDialog,
        private toastr: ToastrService,
        private TimersServiceI: TimersService,
        private _mainAPiServiceService: MainAPiServiceService,
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        private socketService: SocketService,
     //   private tierpermission: TierPermissionPipe,
        // private cd: ChangeDetectorRef,
        private globalFunctionService: GlobalFunctionsService // private sanitizer: DomSanitizer,
    ) // private tierpermission: TierPermissionPipe,
    // private route: ActivatedRoute,
    // private location: Location,
    // private activateroute:ActivatedRoute
    { }

    /*---------------------------------------------------------------------- Coman funcation  --------------------------------------------------------------------------  */
    SetOldistrackid(type) {
        if (type === "open") {
            this.oldIsTrackId = localStorage.getItem("istrackid");
        } else {
            localStorage.setItem("istrackid", this.oldIsTrackId);
        }
    }

    /**
     * This function is used to Set variable for retry data.
     * @param PostData -post the data value
     * @param Methode -method data value
     * @param RefreshArray -Refresh array
     */
    SetVariableForRetry(PostData: any, Methode: any, RefreshArray: any) {
        this.oldIsTrackId = localStorage.getItem("istrackid");
        this.DeletePostData = PostData;
        this.ApiMethod = Methode;
        this.refreshId = RefreshArray;
        localStorage.setItem("istrackid", "DeleteDataClick");
    }
    // __________________________tab mangement __________________
    targetNewTab(URL: any, modul: string) {
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser && currentUser.OPENINNEWTAB) {
            if (
                modul == "DOCUMENTREGISTER" ||
                modul == "TIMEANDBILLING" ||
                modul == "LEGALDETAILS" ||
                modul == "DOCUMENTTEMPLATES" ||
                modul == "FIRMDIRECTORY" ||
                modul == "MATTERDETAILS"
            ) {
                const windowNameId = window.name.split("_");
                const matterIdOfTab =
                    windowNameId && windowNameId[1]
                        ? windowNameId[1]
                        : undefined;
                const _matter = JSON.parse(localStorage.getItem(matterIdOfTab));
                const active_matter = JSON.parse(
                    localStorage.getItem(
                        matterIdOfTab ? matterIdOfTab : "set_active_matters"
                    )
                );
                if (!active_matter) {
                    this.toastr.error("Please Select Matter");
                    return;
                }

                let matterGUID = active_matter.MATTERGUID;
                let random: any = matterGUID;
                // random =  window.open('/#' + URL, modul + '_' + matterGUID);
                // random.focus();
                if (environment.ISPRO) {
                    this.commonFocusWindow(
                        "/#" + URL,
                        modul + "_" + matterGUID
                    );
                    matterGUID = "";
                } else {
                    // window.open('/silqWebApp/#' + url, '_blank');
                    // this.commonFocusWindow(
                    //     "/" + environment.URLPATH + "/#" + URL,
                    //     modul + "_" + matterGUID
                    // );
                    this.commonFocusWindow(
                        "/#" + URL,
                        modul + "_" + matterGUID
                    );
                    matterGUID = "";
                }
            } else {
                const url = this.router.serializeUrl(
                    this.router.createUrlTree([URL])
                );
                let target;
                if (/[,\-|\/]/.test(URL)) {
                    target = URL.replace(/-|\//g, "");
                } else {
                    target = URL;
                }
                if (environment.ISPRO) {
                    // window.open
                    this.commonFocusWindow("/#" + url, target);
                } else {
                    // window.open
                    // this.commonFocusWindow(
                    //     "/" + environment.URLPATH + "/#" + url,
                    //     target
                    // );
                    this.commonFocusWindow(
                        "/#" + url,
                        target
                    );
                }
            }
        } else {
            setTimeout(() => {
                if (modul == "MATTERDETAILS") {
                    this.isTabShow = 4;
                }
                this.router.navigate([URL]);
            }, 100);
        }
    }

    /**
     * This function is used to focus the common widow 
     * @param url -url data value
     * @param modul -modul name
     */
    commonFocusWindow(url, modul) {
        const active_matter = JSON.parse(
            localStorage.getItem("set_active_matters")
        );
        let random: any = active_matter;
        random = window.open(url, modul);
        random.focus();
    }
    // __________________________END tab mangement __________________
    setTab(event: any) {
        if (this.isTabShow != 30) {
            this.selectedIndex = 0;
            //  this.selectedIndex = undefined;
            // setTimeout(() =>
            //     this.selectedIndex = 0;
            // }, 500);
        } else {
            this.selectedIndex = 2;
        }
    }

    /**
     * This function is used to dbl click on the url
     * @param docType -doc type value
     * @returns -data value
     */
    dblClickUrl(docType) {
        let sendurl: any = "";
        if (
            this.router.url == "/matters" ||
            this.router.url == "/legal-details/settlement" ||
            this.router.url == "/legal-details/chronology" ||
            this.router.url == "/legal-details/authority"
        ) {
            return (sendurl = "/create-document/matter-template");
        } else if (
            this.router.url == "/time-billing/matter-invoices" ||
            this.router.url == "/invoice/sub-invoices"
        ) {
            return (sendurl = "/create-document/invoice-template");
        } else if (
            this.router.url == "/receive-money" ||
            this.router.url == "/time-billing/receipts-credits"
        ) {
            return (sendurl = "/create-document/receive-money-template");
        } else if (this.router.url == "/contact") {
            return (sendurl = "/create-document/contact-template");
        } else if (this.router.url == "/full-Safe-Custody/Safe-Custody") {
            return (sendurl = "/full-Safe-Custody/Safe-Custody");
        }
    }

    /* --------------------------------------------------------------------- START Matter start--------------------------------------------------------------------------  */
    // Add - edit- dulplicate matter Pop-up
    matterpopup(actionType, data?: any) {
        this.accessTierPermission = JSON.parse(localStorage.getItem("set_tier_permission"));
        // store matter mode in localstorage
        if (actionType == "edit") {
            localStorage.setItem("matterMode", "edit");
        } else if (actionType == "duplicate") {
            localStorage.setItem("matterMode", "duplicate");
        } else {
            localStorage.setItem("matterMode", "new");
        }
        // ends here ~ store matter mode in localstorage
        const foundPermission = this.accessTierPermission && this.accessTierPermission.find((permission: any) => permission?.FEATURENAME === 'Conflict Check');
        this.SetOldistrackid("open");
        if (
           (JSON.parse(localStorage.getItem("currentUser"))
                .SHOWCONFLICTCHECKFORNEWMATTER &&
            actionType != "edit" &&
                actionType != "conflicNewMatter") &&  (
                foundPermission.ALLOWED == 1 && foundPermission.FEATURENAME == 'Conflict Check')
        ) {
            this.ActiveConflictActionType = actionType;
            this.router.navigate(["conflict-check"]);
            return;
        }

        let MaterPopupData = {};


        if (
            (actionType == "new" ||
            (actionType == "conflicNewMatter" &&
                this.ActiveConflictActionType != "duplicate"))
            )
            {
            actionType = "new";
            MaterPopupData = { action: actionType };

        } else if (
            actionType == "edit" ||
            actionType == "duplicate" ||
            (actionType == "conflicNewMatter" &&
                this.ActiveConflictActionType == "duplicate")
        ) {
            if (!JSON.parse(localStorage.getItem("set_active_matters"))) {
                this.toastr.error("Please Select Matter");
                return false;
            }
            let mattersData:any
            this.behaviorService.clickMaterFromTimeLine$.subscribe(result => {
                if (result) {
                     mattersData = result
                } else {
                    mattersData = JSON.parse(localStorage.getItem("set_active_matters"));
                }
            })
            // let mattersData = JSON.parse(
            //     localStorage.getItem("set_active_matters")
            // );
            if (data) {
                mattersData = data;
            }
            MaterPopupData = {
                action:
                    actionType == "conflicNewMatter" &&
                        this.ActiveConflictActionType == "duplicate"
                        ? this.ActiveConflictActionType
                        : actionType,
                matterGuid: mattersData.MATTERGUID,
            };
        }
        const dialogRef = this.dialog.open(MatterPopupComponent, {
            width: "100%",
            disableClose: true,
            data: MaterPopupData,
            closeOnNavigation: false,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.behaviorService.UpdateMartterData$.subscribe(
                (data) => {
                    if (data == true) {
                        if (result) {
                            let Payload = {
                                Action: "GetData",
                                Filters: {
                                    MATTERGUID: result,
                                    GETALLFIELDS: true,
                                },
                            };
                            this._mainAPiServiceService
                                .getSetData(Payload, "matter")
                                .subscribe((response) => {
                                    if (
                                        response.CODE === 200 &&
                                        response.STATUS === "success"
                                    ) {
                                        localStorage.setItem(
                                            "set_active_matters",
                                            JSON.stringify(
                                                response.DATA.RECORDS[0]
                                            )
                                        );
                                        setTimeout(() => {
                                            this.behaviorService.UpdateMartterFlag$.next(
                                                false
                                            );
                                        }, 200);
                                    }
                                });
                        }
                    }
                }
            );

            this.SetOldistrackid("close");
            this.ActiveConflictActionType = "";
        });
    }

    /**
     * This function is used to delete New Matter popupdata value
     */
    DeleteNewmatterpopup(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let MatterData: any = JSON.parse(
                    localStorage.getItem("set_active_matters")
                );
                // let postData = { FormAction: "delete", data: { MATTERGUID: MatterData.MATTERGUID } };

                let postData = {
                    Action: "delete",
                    data: {
                        MATTERGUID: MatterData.MATTERGUID,
                    },
                };

                let RefreshIdArray: any = ["#refreshMatterTab"];
                this.SetVariableForRetry(postData, "matter", RefreshIdArray);
                localStorage.setItem("istrackid", "DeleteDataClick");
                this.behaviorService.setChildTableData({
                    MATTERGUID: MatterData.MATTERGUID,
                    classType: MatterData.MATTERCLASS,
                    action: "deleteData",
                });
                this._mainAPiServiceService
                    .getSetData(postData, "matter")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshMatterTab").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter"
                            );
                        } else if (res.CODE == 462 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            // this.dialogRef.close(false);
                        } else {
                            let Massge: any = res.DATA.VALIDATIONS[0]
                                ? res.DATA.VALIDATIONS[0]["ERRORDESCRIPTION"]
                                : res.MESSAGE;
                            this.toastr.error(Massge);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to check the validation data value.
     */
    checkValidation(bodyData: any, details: any, ApiName: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (
                value.VALUEVALID == "No" ||
                value.VALUEVALID == "NO" ||
                value.VALUEVALID == "Error"
            ) {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });

        if (Object.keys(errorData).length != 0) {
            localStorage.setItem("confEWshow", "error");
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: {
                        errorData: errorData,
                    },
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage = "";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                localStorage.removeItem("confEWshow");
                if (result) {
                }

                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length != 0) {
            localStorage.setItem("confEWshow", "warning");
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    // data: {warningValidation:warningData},
                    data: warningData,
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                ApiName === "SetInvoice"
                    ? ""
                    : "Are you sure you want to delete?";
            // this.confirmDialogRef.componentInstance.confirmMessage = (ApiName === 'SetInvoice') ? '' : warningData;
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                localStorage.removeItem("confEWshow");
                if (result) {
                    this.DeleteGData(details, ApiName);
                }
                this.confirmDialogRef = null;
            });
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            this.DeleteGData(details, ApiName);
        }
    }

    /**
     * This function is used to delete data value
     * @param data -data value
     * @param ApiName -api name 
     */
    DeleteGData(data: any, ApiName) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, ApiName).subscribe(
            (response) => {
                if (
                    response.CODE == 200 &&
                    (response.STATUS == "OK" || response.STATUS == "success")
                ) {
                    this.toastr.success("Deleted successfully");
                    this.behaviorService.checktogglestatus$.next(false);
                    $("#refreshInvoiceTab").click();
                    $("#refreshMatterInvoice").click();
                    $("#getmatterinvoice").click();
                    if(ApiName == 'expenditure-batch'){
                        $("#BatchPayment").click();
                    }
                } else if (
                    response.CODE == 451 &&
                    response.STATUS == "warning"
                ) {
                    this.toastr.warning(response.MESSAGE);
                } else if (response.CODE == 450 && response.STATUS == "error") {
                    this.toastr.error(response.MESSAGE);
                } else if (response.MESSAGE == "Not logged in") {
                    // this.dialogRef.close(false);
                }
            },
            (error) => {
                this.toastr.error(error);
            }
        );
    }
    
/**
 * This function is used to generte the document data flag value
 * @param event -event data value
 * @param flag -flag data value
 */
    generateDocumentsFromMatters(event: any, flag?: any) {
        let sendurl = this.dblClickUrl("document");
        let popupData: any = {
            action: "Matter-Generate-Doc",
            WORKITEMGUID: this.WorkInProgressData,
            url: sendurl,
            flag: flag,
        };
        const dialogRef = this.dialog.open(MatterDocumanrPopupComponent, {
            width: "100%",
            disableClose: true,
            data: popupData,
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }
    /* --------------------------------------------------------------------- END Matter start--------------------------------------------------------------------------  */
    /* ---------------------------------------------------------------------START Extras Tab  Matter --------------------------------------------------------------------------  */
    addNewTimeEntryNew(isReadOnly, matterIs?: any) {
        let matterData = JSON.parse(localStorage.getItem("set_active_matters"));
        if (matterData.ACTIVE) {
            this.addNewTimeEntry(
                "Add-New",
                matterData.MATTERGUID,
                isReadOnly,
                "",
                matterIs
            );
        } else {
            this.toastr.error(
                "You cannot time entry for Inactive matter. Please select active matter and try again."
            );
            return false;
        }
    }

    /**
     * This function is used to add new Time Entry 
     * @param Data -data value
     * @param matterData -matter data value
     * @param isReadOnly -read only flag data
     * @param previewTimer -timer value
     * @param matterIs -id
     */
    addNewTimeEntry(
        Data: any,
        matterData: any,
        isReadOnly: any,
        previewTimer: any,
        matterIs?: any
    ) {
        this.SetOldistrackid("open");
        this.socketService.openSocketMatter();
        let UserPermisstion = localStorage.getItem("MatterWindow_Open_Status");
        if (Data == "Add-New") {
            if (UserPermisstion == "true" || UserPermisstion == '1') {
                if(this.router.url == "/matters") {
                    const dialogRef = this.dialog.open(
                        TimeEntryDialogComponent,
                        {
                            width: "100%",
                            disableClose: true,
                            data: {
                                edit: "Add",
                                matterData: matterData,
                                isReadOnly: isReadOnly,
                                matterIs: matterIs ? matterIs : null,
                                TABLEGUID: ""
                            },
                        }
                    );
                    dialogRef.afterClosed().subscribe((result) => {
                        this.SetOldistrackid("close");
                        if (result) {
                            $("#refreshMatterTab").click();
                            $("#refreshTimeEntryTab").click();
                            $("#refreshWorkInprogress").click();
                            localStorage.setItem('Spellcheck','false');
                        }
                    });
                } else {
                    const dialogRef = this.MatDialog.open(MatterDialogComponent, {
                        width: "100%",
                        disableClose: true,
                        data: null,
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            localStorage.setItem(
                                "set_active_matters",
                                JSON.stringify(result)
                            );
                            const dialogRef = this.dialog.open(
                                TimeEntryDialogComponent,
                                {
                                    width: "100%",
                                    disableClose: true,
                                    data: {
                                        edit: "Add",
                                        matterData: matterData,
                                        isReadOnly: isReadOnly,
                                        ActiveMatterData: result,
                                        TABLEGUID: ""
                                    },
                                }
                            );
                            dialogRef.afterClosed().subscribe((result) => {
                                this.SetOldistrackid("close");
                                if (result) {
                                    if(this.router.url == "/matters") {
                                        $("#refreshMatterTab").click();
                                    }
                                    $("#refreshTimeEntryTab").click();
                                    $("#refreshWorkInprogress").click();
                                }
                            });
                        } else {
                            this.SetOldistrackid("close");
                        }
                    });
                }
            } else {
                // prefrence flage if false.
                const dialogRef = this.dialog.open(TimeEntryDialogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: {
                        edit: "Add",
                        matterData: matterData,
                        isReadOnly: isReadOnly,
                        matterIs: matterIs ? matterIs : null,
                        TABLEGUID: ""
                    },
                });
                dialogRef.afterClosed().subscribe((result) => {
                    this.SetOldistrackid("close");
                    if (result) {
                        if(this.router.url == "/matters") {
                            $("#refreshMatterTab").click();
                        }
                        $("#refreshTimeEntryTab").click();
                        $("#refreshWorkInprogress").click();
                    }
                });
            }
        } else {
            if (
                Data != "NewTimeEntryMatter" &&
                this.WorkInProgressData &&
                (this.WorkInProgressData.ITEMTYPEDESC == "Activity" ||
                    this.WorkInProgressData.ITEMTYPEDESC == "Sundry")
            ) {
                this.SetOldistrackid("open");
                let popupData: any = {
                    action: "edit",
                    WORKITEMGUID: this.WorkInProgressData.WORKITEMGUID,
                };
                const dialogRef = this.dialog.open(ActivityDialogComponent, {
                    disableClose: true,
                    panelClass: "Activity-dialog",
                    data: {
                        popupData,
                        popupname: this.WorkInProgressData.ITEMTYPEDESC,
                    },
                });
                dialogRef.afterClosed().subscribe((result) => {
                    this.SetOldistrackid("close");
                    if (result) {
                        $("#refreshTimeEntryTab").click();
                        $("#refreshWorkInprogress").click();
                        $("#refreshtabledetails").click();
                        $("#refreshreciptsDAta").click();
                    }
                });
            } else {
                const dialogRef = this.dialog.open(TimeEntryDialogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: {
                        edit: Data,
                        matterData: matterData,
                        isReadOnly: isReadOnly,
                        previewTimer: previewTimer,
                        matterIs: matterIs ? matterIs : null,
                        TABLEGUID: ""
                    },
                });
                dialogRef.afterClosed().subscribe((result) => {
                    this.SetOldistrackid("close");
                    if (result) {
                        if(this.router.url == "/matters") {
                            $("#refreshMatterTab").click();
                        }
                        $("#refreshTimeEntryTab").click();
                        $("#refreshWorkInprogress").click();
                        $("#refreshtabledetails").click();
                        $("#refreshreciptsDAta").click();
                    }
                });
            }
        }
    }

    /**
     * This function is used to add the timer For matter
     */
    addTimerForMatter() {
        this.TimersServiceI.addTimeEnrtS("");
    }
   
    /**
     * This function is used to open the file note popup data
     * @param actionType -action type value data
     * @param matterIs -matterIs data value
     */
    FileNotePopup(actionType, matterIs?: any) {
        this.SetOldistrackid("open");
        let FileNotePopdata: any = {};
        if (actionType == "new") {
            FileNotePopdata = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            FileNotePopdata = { action: actionType };
        }

        if (matterIs) {
            FileNotePopdata.globalMatter = matterIs;
        }

        const dialogRef = this.dialog.open(FileNoteDialogComponent, {
            disableClose: true,
            panelClass: "Document-dialog",
            data: FileNotePopdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.SetOldistrackid("close");
            if (result) {
                $("#refreshFileNote").click();
            }
        });
    }

    /**
     * This function is used to open the contact dialog 
     * @param actionType -action type value
     * @param ContactName -contact name value
     * @returns -data value
     */
    ContactsDialog(actionType, ContactName?, flag?, SOLI?) {
        this.SetOldistrackid("open");
        let contactPopupData = {};
        if (actionType == "new") {
            if (
                ContactName &&
                ContactName != null &&
                ContactName != undefined
            ) {
                contactPopupData = {
                    action: actionType,
                    ContactName: ContactName,
                    isReturn: true,
                };
            } else {
                contactPopupData = { action: actionType };
            }
        } else if (
            actionType == "edit" ||
            actionType == "duplicate" ||
            actionType == "matter_contect"
        ) {
            if (actionType == "matter_contect") {
                let getMatterContactGuId = JSON.parse(
                    localStorage.getItem("set_active_matters")
                );
                localStorage.setItem(
                    "contactGuid",
                    getMatterContactGuId.CONTACTGUID
                );
                actionType = "edit";
                if (getMatterContactGuId.CONTACTGUID == "") {
                    this.toastr.error("CONTACTGUID not available");
                    return false;
                }
            } else if (
                !localStorage.getItem("contactGuid") &&
                actionType != "matter_contect"
            ) {
                this.toastr.error("Please Select Contact");
                return false;
            }
        }
        contactPopupData = {
            action: actionType,
            ContactName: ContactName,
            isReturn: true,
        };
        const dialogRef = this.dialog.open(ContactDialogComponent, {
            disableClose: true,
            width: "100%",
            data: contactPopupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.SetOldistrackid("close");
            $('#refreshContactTa').click();
            if (result) {
                if (
                    ContactName &&
                    ContactName != null &&
                    ContactName != undefined
                ) {

                    if(flag != undefined && flag != null && flag == 'Correspondent'){
                        result.flag=flag;
                    }

                    if(SOLI != undefined && SOLI != null && SOLI == 'SOLICITOR'){
                        result.SOLI=SOLI;
                    }

                    this.behaviorService.newContactData$.next(result);
                    //  $('#refreshContactTab').click();
                } else {
                    //$('#refreshContactTab').click();
                }
            }
        });
    }

    /**
     * This function is used to open the contact corres Details data value. 
     */
    openCorresDialog() {
        this.SetOldistrackid("open");
        let getMatterGuId = JSON.parse(
            localStorage.getItem("set_active_matters")
        );
        const dialogRef = this.dialog.open(ContactCorresDetailsComponent, {
            disableClose: true,
            width: "100%",
            data: { ContextGuid: getMatterGuId.MATTERGUID, callType: "edit" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.SetOldistrackid("close");
        });
    }

    /**
     * This function is used to create the invoice data value
     * @param isMatterOtions -data value 
     */
    createInstantInvoice(isMatterOtions: any) {
        this.SetOldistrackid("open");
        if (isMatterOtions && isMatterOtions == true) {
            const dialogRef = this._matDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.SetOldistrackid("close");
                if (result) {
                    localStorage.setItem(
                        "set_active_matters",
                        JSON.stringify(result)
                    );
                    this.CallInsInvoiceDilog();
                }
            });
        } else {
            this.CallInsInvoiceDilog();
        }
    }

    /**
     * This function is used to Call Invoice dialog data
     */
    CallInsInvoiceDilog() {
        this.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(InstantInvoiceDailogComponent, {
            width: "100%",
            disableClose: true,
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.SetOldistrackid("close");

            if (result) {
                $("#refreshWorkInprogress").click();
                $("#getmatterinvoice").click();
            }
        });
    }

    /**
     * This function is used to create the invoice 
     * @param type -type of thr invoice
     * @returns -data value
     */
    createInvoice(type: any) {
        this.SetOldistrackid("open");
        this.behaviorService.selectedMatters$.subscribe((res) => {
            if (res.length > 0) {
                this.selectedMatterForinvoice = res;
            } else {
                this.selectedMatterForinvoice = [];
            }
        });
        // if selected then return. window timeand billing in any situation matter tiked not tiked any type
        if (
            (type == "" || type == "preview" || type == "Invoice All WIP") &&
            (this.router.url == "/time-billing/work-in-progress" ||
                this.router.url == "/invoice/sub-invoices" || this.router.url == "/invoice/sub-invoices"||
                this.router.url == "/matter-details/work-in-progress")
        ) {
            const dialogRef = this._matDialog.open(InvoiceAddDailogComponent, {
                width: "100%",
                disableClose: true,
                data: type,
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.SetOldistrackid("close");
                if (result) {
                    $("#refreshWorkInprogress").click();
                    $("#refreshTimeEntryTab").click();
                }
            });
            // this.wipallinvoiceCalled(this.selectedMatterForinvoice);
            return;
        }

        if (
            this.selectedMatterForinvoice.length > 0 &&
            type != "Invoice All WIP"
        ) {
            if (type == "") {
                const dialogRef = this._matDialog.open(
                    InvoiceAddDailogComponent,
                    { width: "100%", disableClose: true, data: type }
                );

                dialogRef.afterClosed().subscribe((result) => {
                    this.SetOldistrackid("close");
                    if (result) {
                        $("#refreshWorkInprogress").click();
                        $("#refreshTimeEntryTab").click();
                    }
                });
                // this.wipallinvoiceCalled(this.selectedMatterForinvoice);
                return;
            }
            if (
                type == "Invoice All WIP" &&
                this.selectedMatterForinvoice.length > 0
            ) {
                this.wipallinvoiceCalled(this.selectedMatterForinvoice);
            }
            if (type == "preview") {
                let passdata: any = {
                    Context: "previewall",
                    ContextGuid: "",
                    knownby: "Template",
                    Type: "Template",
                    data: this.selectedMatterForinvoice,
                    Template: this.TemplateGenerateData.TEMPLATENAME,
                    isMultipleDocument: true,
                };
                this.ForDocDialogOpen(passdata);
                return;
            }
        }

        if (
            type == "Invoice All WIP" &&
            this.selectedMatterForinvoice.length > 0
        ) {
            this.wipallinvoiceCalled(this.selectedMatterForinvoice);
        }
        if (
            (type == "Invoice All WIP" &&
                this.selectedMatterForinvoice.length <= 0) ||
            type == "preview"
        ) {
            const dialogRef = this._matDialog.open(InvoiceAddDailogComponent, {
                width: "100%",
                disableClose: true,
                data: type,
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.SetOldistrackid("close");
                if (result) {
                    $("#refreshWorkInprogress").click();
                    $("#refreshTimeEntryTab").click();
                }
            });
        }
    }

    /**
     * This function is used to wipallInvoice called
     * @param selectedMatterForinvoice 
     */
    async wipallinvoiceCalled(selectedMatterForinvoice: any) {
        let tempPost: any;
        let res: any;
        let promisedata: any;
        this.invoiceallarr = [];

        await new Promise(async (res1, rej) => {
            for (let i = 0; i < selectedMatterForinvoice.length; i++) {
                promisedata = await new Promise(async (resolve, reject) => {
                    tempPost = {
                        MATTERGUID: selectedMatterForinvoice[i].MATTERGUID,
                    };

                    let PostInvoiceEntryData: any;
                    PostInvoiceEntryData = {
                        FormAction: "invoiceall",
                        VALIDATEONLY: false,
                        Data: tempPost,
                    };
                    await this._mainAPiServiceService
                        .getSetData(PostInvoiceEntryData, "SetInvoice")
                        .subscribe((response) => {
                            if (
                                response.CODE == 200 &&
                                response.STATUS == "success"
                            ) {
                                res = response;
                                this.behaviorService.matterInvoiceData({ INVOICEGUID: res.DATA.INVOICEGUID });
                                this.invoiceallarr.push(res);
                                resolve(this.invoiceallarr);

                                if (selectedMatterForinvoice.length - 1 == i) {
                                    res1(true);
                                }
                            } else if (
                                response.CODE == 451 &&
                                response.STATUS == "warning"
                            ) {
                                this.globalFunctionService
                                    .checkValidation(
                                        response.DATA.VALIDATIONS,
                                        PostInvoiceEntryData
                                    )
                                    .subscribe((data) => {
                                        if (data && data.callback) {
                                            resolve(true);
                                        }
                                    });
                                if (selectedMatterForinvoice.length - 1 == i) {
                                    // this.dialogRef.close(false);
                                    res1(true);
                                }
                            } else {
                                this.globalFunctionService
                                    .checkValidation(
                                        response.DATA.VALIDATIONS,
                                        PostInvoiceEntryData
                                    )
                                    .subscribe((data) => {
                                        if (data && data.callback) {
                                            resolve(true);
                                        }
                                    });

                                if (selectedMatterForinvoice.length - 1 == i) {
                                    // this.dialogRef.close(false);
                                    res1(true);
                                }
                            }
                        });
                });
            }
        });

        await Promise.all([promisedata]).then(async (value) => {
            const dialogRef = this._matDialog.open(GenerateInvoiceComponent, {
                width: "100%",
                disableClose: true,
                data: {
                    invoiceallarrData: this.invoiceallarr,
                    multipledatainvoice: true,
                    context: "invoiceall",
                },
            });
            dialogRef.afterClosed().subscribe((result) => { });
            $("#refreshMatterTab").click();
            await this.behaviorService.selectedMatters$.next([]);
        });
    }

    /**
     * This function is used to open the dock dialog data value
     * @param passdata -value data
     */
    ForDocDialogOpen(passdata) {
        const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
            width: "100%",
            disableClose: true,
            data: passdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to create the receipt. 
     * @param val 0value data
     */
    createReceipt(val) {
        this.SetOldistrackid("open");

        if (val == "select_matter") {
            const dialogRef = this._matDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    localStorage.setItem(
                        "set_active_matters",
                        JSON.stringify(result)
                    );
                    const dialogRef = this._matDialog.open(
                        ReceiptDilogComponent,
                        {
                            width: "100%",
                            disableClose: true,
                            data: {
                                action: "add",
                                type: " ",
                                matterData: result,
                            },
                        }
                    );
                    dialogRef.afterClosed().subscribe((result) => {
                        this.SetOldistrackid("close");
                        if (result) {
                            $("#refreshReceiceMoany").click();
                            $("#refreshreciptsDAta").click();
                        }
                    });
                } else {
                    this.SetOldistrackid("close");
                }
            });
        } else if (val == "allredy_select_matter") {
            let matterData = JSON.parse(
                localStorage.getItem("set_active_matters")
            );
            const dialogRef = this._matDialog.open(ReceiptDilogComponent, {
                width: "100%",
                disableClose: true,
                data: { action: "add", type: " ", matterData: matterData },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.SetOldistrackid("clsoe");
                if (result) {
                    $("#refreshReceiceMoany").click();
                    $("#refreshreciptsDAta").click();
                }
            });
        } else if (val == "selected_invoice_matter") {
            let matterGUID: any = "";
            this.behaviorService.matterInvoice$.subscribe(
                (matterInvoiceData) => {
                    if (matterInvoiceData)
                        matterGUID = matterInvoiceData.MATTERGUID;
                }
            );

            let Payload = {
                Action: "GetData",
                Filters: {
                    MATTERGUID: matterGUID,
                    GETALLFIELDS: true,
                },
            };

            this._mainAPiServiceService.getSetData(Payload, "matter").subscribe(
                (response) => {
                    this.SetOldistrackid("close");
                    if (
                        response.CODE === 200 &&
                        response.STATUS === "success"
                    ) {
                        let matterData = response.DATA.RECORDS[0];
                        const dialogRef = this._matDialog.open(
                            ReceiptDilogComponent,
                            {
                                width: "100%",
                                disableClose: true,
                                data: {
                                    action: "add",
                                    type: " ",
                                    matterData: matterData,
                                },
                            }
                        );
                        dialogRef.afterClosed().subscribe((result) => {
                            this.SetOldistrackid("close");
                            if (result) {
                                $("#refreshReceiceMoany").click();
                                $("#refreshreciptsDAta").click();
                            }
                        });
                    } else if (response.MESSAGE == "Not logged in") {
                    }
                },
                (error) => {
                    this.SetOldistrackid("close");
                    this.toastr.error(error);
                }
            );
        }
    }

    /**
     * This function is used to open the spen money popup data value.
     * @param actionType -action type data value
     * @param val -value 
     * @param flag -flag data
     * @param FLAG -flag data value
     * @param matterIs -data value
     */
    spendmoneypopup(
        actionType: any,
        val: any = "",
        flag?: any,
        FLAG?: any,
        matterIs?: any
    ) {
        this.SetOldistrackid("open");
        const dialogRef = this.dialog.open(SpendMoneyAddComponent, {
            disableClose: true,
            panelClass: "SpendMoney-dialog",
            data: {
                action: actionType,
                FromWhere: val,
                flag: flag,
                FLAG: FLAG,
                matterIs: matterIs ? matterIs : null,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.SetOldistrackid("close");
            if (result) {
                $("#refreshSpendMoneyTab").click();
                $("#refreshRecouncilItem").click();
                $("#refreshWorkInprogress").click();
                $("#refreshGeneral").click();
            }
        });
    }
    /* ---------------------------------------------------------------------END Extras Tab  Matter --------------------------------------------------------------------------  */
}
