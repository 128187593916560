import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
// import { TaskDialogeComponent } from '../../main/pages/Task/task-dialoge/task-dialoge.component';
import { MatDialog } from "@angular/material/dialog";
// import { InvoiceDetailComponent } from '../../main/pages/invoice/invoice-detail/invoice-detail.component';
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
// import { MatterPopupComponent } from '../../main/pages/matters/matter-popup/matter-popup.component';
// import { ReceiptDilogComponent } from '../../main/pages/invoice/receipt-dilog/receipt-dilog.component';
// import { FileNoteDialogComponent } from '../../main/pages/matters/file-note-dialog/file-note-dialog.component';
// import { TimeEntryDialogComponent } from '../../main/pages/time-entries/time-entry-dialog/time-entry-dialog.component';
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { TaskDialogeComponent } from "@pages/Task/task-dialoge/task-dialoge.component";
import { InvoiceDetailComponent } from "@pages/invoice/invoice-detail/invoice-detail.component";
import { MatterPopupComponent } from "@pages/matters/matter-popup/matter-popup.component";
import { ReceiptDilogComponent } from "@pages/invoice/receipt-dilog/receipt-dilog.component";
import { FileNoteDialogComponent } from "@pages/matters/file-note-dialog/file-note-dialog.component";
import { TimeEntryDialogComponent } from "@pages/time-entries/time-entry-dialog/time-entry-dialog.component";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { EMPTY, Observable, Subject, of, switchMap, takeUntil, throwError } from "rxjs";
import { TimersService } from "@_services/timers.service";
import { ActivityDialogComponent } from "@pages/activities/activity-dialog/activity-dialog.component";
@Component({
    selector: "app-timeline",
    templateUrl: "./timeline.component.html",
    styleUrls: ["./timeline.component.scss"],
    // providers: [{
    //   provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    // }]
})
export class TimelineComponent implements OnInit, OnDestroy {
    dashboardModeIs: any = false;
    setMatterDetailsFav: any[] = [];
    isLoadingResults: boolean = false;

    firstFormGroup: FormGroup = this._formBuilder.group({ firstCtrl: [""] });
    secondFormGroup: FormGroup = this._formBuilder.group({ secondCtrl: [""] });
    @Input() ComponentName: any;
    @Input() currentMatter: any;
    @Input() currentUser: any;

    timelistArray: any = [];
    days: any = [
        "Today",
        "Yesterday",
        "2 Days Ago",
        "3 Days Ago",
        "4 Days Ago",
        "5 Days Ago",
        "6 Days Ago",
        "7 Days Ago",
        "Last Week",
        "Older",
    ];
    timelineTrackdata: any = [];
    timelineMenuList = [];
    selectedMatterTab: any;
    ApiRepoParm1: any;
    isGenrateReport: boolean;
    windowNameId: any;
    isChronoData: string;
    ChronologyLegalData: any;
    isDocRegData: string;
    DocRegData: any;
    indDashboardIs: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        public router: Router,
        private _formBuilder: FormBuilder,
        private _behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        public datePile: DatePipe,
        public dialog: MatDialog,
        public toolbarServiceService: ToolbarServiceService,
        private toastr: ToastrService,
        private Timersservice: TimersService
    ) {
        this._behaviorService.editDashboardMode$

            .subscribe((result) => {
                this.dashboardModeIs = result;
            });

        this._behaviorService.editIndividualDashboardMode$

            .subscribe((result) => {
                this.indDashboardIs = result;
            });

        this._behaviorService.UpdateMatterDetailFev$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                this.setMatterDetailsFav = result.DATA;
            }
            if (!result && this.setMatterDetailsFav.length == 0) {
                this._behaviorService.UpdateIndividualDashboardFev$.pipe(
                    takeUntil(this._unsubscribeAll$)
                ).subscribe((result2) => {
                    if (result2) {
                        this.setMatterDetailsFav = result2.DATA;
                    }
                });
            }
        });

        this._behaviorService.LegalChronologyData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result != null) {
                this.isChronoData = "Notempty";
                this.ChronologyLegalData = result;
            } else {
                this.isChronoData = "empty";
            }
        });

        this._behaviorService.DocumentRegisterData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result != null) {
                // this.isOpenDesktopWord = false;
                this.isDocRegData = "Notempty";
                this.isDocRegData = result;

                if (
                    this.DocRegData?.type == "docx" ||
                    this.DocRegData?.type == "doc" ||
                    this.DocRegData?.type == "dotx" ||
                    this.DocRegData?.type == "dot" ||
                    this.DocRegData?.type == "docm" ||
                    this.DocRegData?.type == "dotm"
                ) {
                    // this.isOpenDesktopWord = true;
                }
            } else {
                this.isDocRegData = "empty";
            }
        });
    }

    /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        const matterId = window.name.split("_");
        this.windowNameId = matterId && matterId[1] ? matterId[1] : undefined;

        this.setTimeline();
        this.serachData();
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to search the Data 
     */
    serachData():void {
        if (this.setMatterDetailsFav) {
            this.setMatterDetailsFav.forEach((e) => {
                if (e.ID.indexOf("timeline-") != -1) {
                    this.timelineMenuList.push(e);
                }
            });
        }
    }

    /**
     * This function is used to Edit the Favourite list
     */
    onEdit(active: any):void {
        for (let key of this.setMatterDetailsFav) {
            if (key.ID == active) {
                key.STAR = key.STAR == "star" ? "" : "star";
            }
        }

        this._behaviorService.UpdateMatterDetailFev$.next({
            DATA: this.setMatterDetailsFav,
            FIRSTTIME: false,
        });
    }

    /**
     * This function is used to Set the Favourite Icon Status.
     */
    setFavriotIconStatus(key: string): any {
        if (this.setMatterDetailsFav) {
            const setFevdata = this.setMatterDetailsFav.filter(
                (e) => e.ID == key
            );
            return setFevdata;
        }
    }

    /**
     * This function is used to set the timeline 
     */
    async setTimeline() {
        // example MatterGUID - "YEA21MLNHJ94SHAM"

        let payload = {
            Action: "GetData",
            Filters: {
                MatterGuid: this.currentMatter?.MATTERGUID,
            },
            SessionToken: "LBVliqCCbl0NMpXiBsatwqrd5wrMON",
            apikey: "SNGMTUEEB2AJBFC9",
            formatting: "JSON",
        };

        if (this.currentUser) {
            delete payload["Filters"].MatterGuid;
            payload["Filters"]["USERGUID"] = this.currentUser.UserGuid;
        }

        await this._mainAPiServiceService
            .getSetData(payload, "timeline")

            .subscribe((response) => {
                if (response.CODE == 200 && response.DATA) {
                    // let collactiondata : any = {
                    //   today : [],
                    //   yesterday : [],
                    //   _2dayago : [],
                    //   _3dayago : [],
                    //   _4dayago : [],
                    //   _5dayago : [],
                    //   _6dayago : [],
                    //   _7dayago : [],
                    //   lastweek : [],
                    //   older :[]
                    // };

                    this.timelistArray = response.DATA.RECORDS;

                    for (let key in this.timelistArray) {
                        let obj = {
                            key: key,
                            value: this.timelistArray[key],
                        };
                        this.timelineTrackdata.push(obj);
                    }
                    // this.timelistArray.forEach((data , index) => {
                    //   switch (data.GROUPING) {
                    //     case 'Today': {
                    //       collactiondata.today.push(data);
                    //       break;
                    //     }
                    //     case 'Yesterday': {
                    //       collactiondata.yesterday.push(data)
                    //       break;
                    //     }
                    //     case '2 Days Ago': {
                    //       collactiondata._2dayago.push(data)
                    //       break;
                    //     }
                    //     case '3 Days Ago': {
                    //       collactiondata._3dayago.push(data)
                    //       break;
                    //     }
                    //     case '4 Days Ago': {
                    //       collactiondata._4dayago.push(data)
                    //       break;
                    //     }
                    //     case '5 Days Ago': {
                    //       collactiondata._5dayago.push(data)
                    //       break;
                    //     }
                    //     case '6 Days Ago': {
                    //       collactiondata._6dayago.push(data)
                    //       break;
                    //     }
                    //     case '7 Days Ago': {
                    //       collactiondata._7dayago.push(data)
                    //       break;
                    //     }
                    //     case 'Last Week': {
                    //       collactiondata.lastweek.push(data)
                    //       break;
                    //     }
                    //     case 'Older': {
                    //       collactiondata.older.push(data)
                    //       break;
                    //     }
                    //   };
                    // });

                    // for(let key in collactiondata){
                    //   let obj = {
                    //     key :(key == 'today')?'Today':(key == 'yesterday')?'Yesterday':(key == '_2dayago')?'2 Days Ago':(key == '_3dayago')?'3 Days Ago':(key == '_4dayago')?'4 Days Ago':(key == '_5dayago')?'5 Days Ago':(key == '_6dayago')?'6 Days Ago':(key == '_7dayago')?'7 Days Ago':(key == 'lastweek')?'Last Week':'Older',
                    //     value : collactiondata[key]
                    //   };
                    //   this.timelineTrackdata.push(obj);

                    // }
                }
            });
    }

    /**
     * This function is used to change the format data value.
     */
    formatechanges(date) {
        return moment(date, "DD/MM/YYYY").format("MM/DD/YYYY");
    }

    /**
     * This function is used to Edit the Details.
     */
    EditDetail(event: any):void {
        let TaskPopdata: any = {};
        if (event.TABLENAME == "Tasks") {
            TaskPopdata = { action: "edit", TABLEGUID: event.TABLEGUID };

            const dialogRef = this.dialog.open(TaskDialogeComponent, {
                disableClose: true,
                panelClass: "Task-dialog",
                data: TaskPopdata,
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
            });
        } else if (event.TABLENAME == "Invoice") {
            const dialogRef = this.dialog.open(InvoiceDetailComponent, {
                width: "100%",
                disableClose: true,
                data: { type: "edit", INVOICEGUID: event.TABLEGUID },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
            });
        } else if (event.TABLENAME == "Matter") {
            TaskPopdata = { action: "edit", matterGuid: event.TABLEGUID };

            const dialogRef = this.dialog.open(MatterPopupComponent, {
                width: "100%",
                disableClose: true,
                data: TaskPopdata,
                closeOnNavigation: false,
            });
            dialogRef.afterClosed().subscribe((result) => {
                // this.SetOldistrackid('close');
            });
        } else if (event.TABLENAME == "Income") {
            this.toolbarServiceService.SetOldistrackid("open");
            const dialogRef = this.dialog.open(ReceiptDilogComponent, {
                width: "100%",
                disableClose: true,
                data: { action: "editForTB", INCOMEGUID: event.TABLEGUID },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    // $('#refreshReceiceMoany').click();
                    // $('#refresheReceiptsTab').click();
                    // $('#refreshreciptsDAta').click();
                }
            });
        } else if (event.TABLENAME == "Filenote") {
            TaskPopdata = { action: "edit" };
            TaskPopdata.TABLEGUID = event.TABLEGUID;
            const dialogRef = this.dialog.open(FileNoteDialogComponent, {
                disableClose: true,
                panelClass: "Document-dialog",
                data: TaskPopdata,
            });
            dialogRef.afterClosed().subscribe((result) => {
                // this.SetOldistrackid('close');
                // if (result) {
                //     $('#refreshFileNote').click();
                // }
            });
        } else if (event.TABLENAME == "WorkItem") {
            // const dialogRef = this.dialog.open(TimeEntryDialogComponent, {
            //     width: "100%",
            //     disableClose: true,
            //     data: {
            //         edit: "Edit",
            //         matterData: event.MATTERGUID,
            //         isReadOnly: true,
            //         ActiveMatterData: null,
            //         TABLEGUID: event.TABLEGUID
            //     },
            // });
            // dialogRef.afterClosed().subscribe((result) => {
            //     this.toolbarServiceService.SetOldistrackid("close");
            //     if (result) {
            //         $("#refreshTimeEntryTab").click();
            //         $("#refreshWorkInprogress").click();
            //     }
            // });
            this.setActivityData(event.TABLEGUID);
        } else if (event.TABLENAME == "Documents") {
            // let sendurl = '/matter-details/matter-detail-dashboard'
            // let popupData: any = { action: 'Matter-Generate-Doc', WORKITEMGUID: '', url: sendurl, flag: 'GenerateDocument' };
            // const dialogRef = this.dialog.open(MatterDocumanrPopupComponent, { width: '100%', disableClose: true, data: popupData });
            // dialogRef.afterClosed().subscribe(result => {
            // });

            this.OpenMatter("DOCUMENT", event);
        }
    }

    setActivityData(WORKITEMGUID) {
            this.isLoadingResults = true;
            this.Timersservice.getTimeEnrtyData({ 'WorkItemGuid': WORKITEMGUID }).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.isLoadingResults = false;
                    let timeEntryData = response.DATA.WORKITEMS[0];
                    if(timeEntryData){
                        this.EditTimeEntryFromWIP(timeEntryData);
                    }
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
    }

    EditTimeEntryFromWIP(WorkInProgressData) {
        if (WorkInProgressData.ITEMTYPEDESC == "Disbursement") {
            this._behaviorService.SpendMoneyData({
                EXPENDITUREGUID: WorkInProgressData.EXPENDITUREGUID,
            });
            this.toolbarServiceService.spendmoneypopup("edit");
        } else if ( 
            WorkInProgressData.ITEMTYPEDESC == "Activity" ||
            WorkInProgressData.ITEMTYPEDESC == "Sundry"
        ) {
            this.toolbarServiceService.SetOldistrackid("open");
            let popupData: any = {
                action: "edit",
                WORKITEMGUID: WorkInProgressData.WORKITEMGUID,
            };
            const dialogRef = this.dialog.open(ActivityDialogComponent, {
                disableClose: true,
                panelClass: "Activity-dialog",
                data: {
                    popupData,
                    popupname: WorkInProgressData.ITEMTYPEDESC,
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    $("#refreshTimeEntryTab").click();
                    $("#refreshWorkInprogress").click();
                }
            });
        } else if(WorkInProgressData.ITEMTYPEDESC == "WIP") {
            const dialogRef = this.dialog.open(TimeEntryDialogComponent, {
                width: "100%",
                disableClose: true,
                data: {
                    edit: "Edit",
                    matterData: WorkInProgressData.MATTERGUID,
                    isReadOnly: true,
                    ActiveMatterData: null,
                    TABLEGUID: WorkInProgressData.WORKITEMGUID
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    $("#refreshTimeEntryTab").click();
                    $("#refreshWorkInprogress").click();
                }
            });
        }
    }

    /**
     * This function is used to Open the Matter data value
     */
    OpenMatter(Type: any, page: any):void {
        const materIDofTab = window.name.split("_");
        const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        this.selectedMatterTab = JSON.parse(
            localStorage.getItem(
                windowNameId ? windowNameId : "set_active_matters"
            )
        );
        this.isGenrateReport = true;
        let sendData: any = {};
        // let APIURL = "NetDriveGetDocument";
        let APIURL = 'cloud-storage';
        let documentName = page.EVENTDESCRIPTION.split(`\ /`);
        documentName = documentName[0].split(`:`);
        sendData = {
            FileType: Type,
            MATTERGUID: page.MATTERGUID,
            DocumentGuid: page.TABLEGUID,
        };

        if (Type != "LOADDOCUMENTS") {
            this._mainAPiServiceService
                .getSetData({ Action: 'GetDocument', Data: sendData }, APIURL)

                .subscribe(
                    (DriveGetDocument) => {
                        if (
                            DriveGetDocument.CODE == 200 &&
                            DriveGetDocument.STATUS == "success"
                        ) {
                            this.toastr.success(
                                "Document Generate successfully"
                            );
                            this.isGenrateReport = false;
                            window.open(
                                DriveGetDocument.DATA.DOCUMENTS[0]
                                    .DRIVEFILEWEBURL,
                                "_blank"
                            );
                        } else if (DriveGetDocument.CODE == 432) {
                            let currentUser: any = JSON.parse(
                                localStorage.getItem("currentUser")
                            );
                            this.isGenrateReport = false;
            //                 window.open(
            //                     environment.APIEndpoint +
            //                     "OAuth2Start?AuthType=NetDrive&SessionToken=" +
            //                     currentUser.SessionToken,
            //                     "targetWindow",
            //                     `toolbar=no,
            //  location=no,
            //  status=no,
            //  menubar=no,
            //  scrollbars=yes,
            //  resizable=yes,
            //  width=800,
            //  height=600`
            //                 );
                            let payload = {
                                "Action": "OAuth2Start",
                                "VALIDATEONLY": false,
                                "Data": {
                                    "USERGUID": currentUser.UserGuid,
                                    "AuthType": "NetDrive"
                                }
                            }
                            this._mainAPiServiceService
                            .getSetData(payload, 'oauth-connections')
                            .subscribe((response) => {
                                if(response.CODE == 200 && response.STATUS == "success") {
                                    window.open(
                                        response.DATA[0].AuthorizationUrl,
                                        "targetWindow",
                                        `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=600`
                                    );
                                }
                            });
                        } else {
                            this.isGenrateReport = false;
                        }
                    },
                    (error) => {
                        this.isGenrateReport = false;
                        this.toastr.error(error);
                    }
                );
        }
    }
    // getMatterData(data:any) {

    //     let Payload = {
    //         "Action": "GetData",
    //         "Filters": { MATTERGUID: data.MATTERGUID, 'GETALLFIELDS': true }
    //     }
    //     this._mainAPiServiceService.getSetData(Payload, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
    //         if (response.CODE === 200 && response.STATUS === 'success') {

    //             sessionStorage.setItem(
    //                 "SetclickMaterFromTimeLine",
    //                 JSON.stringify(response.DATA.RECORDS)
    //             );
    //             this._behaviorService.SetclickMaterFromTimeLine(response.DATA.RECORDS);

    //         } else if (response.MESSAGE == 'Not logged in') {

    //         }

    //     }, error => {
    //         this.toastr.error(error);

    //     })
    // }

    /**
     * This function is used to Redirect on the Hyperlink.
     */
    RedirectHyperLink(data: any) {
        data.RedirectHyperLink = true;
        this.getMatterData(data)
            .pipe(
                switchMap((matterData) => {
                    return this.handleRedirect(data, matterData);
                }),
                takeUntil(this._unsubscribeAll$)
            )
            .subscribe(
                () => {
                    // Handle success
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to getMatter Data
     */
    getMatterData(data: any): Observable<any> {
        const payload = {
            Action: 'GetData',
            Filters: { MATTERGUID: data.MATTERGUID, GETALLFIELDS: true }
        };

        return this._mainAPiServiceService.getSetData(payload, 'matter').pipe(
            takeUntil(this._unsubscribeAll$),
            switchMap((response) => {
                if (response.CODE === 200 && response.STATUS === 'success') {
                    const records = response.DATA.RECORDS[0];
                    sessionStorage.setItem('SetclickMaterFromTimeLine', JSON.stringify(records));
                    this._behaviorService.SetclickMaterFromTimeLine(records);
                    return of(records);
                } else if (response.MESSAGE === 'Not logged in') {
                    // Handle not logged in scenario
                    return EMPTY;
                } else {
                    // Handle other error scenarios
                    return throwError(response.MESSAGE || 'Unknown error');
                }
            })
        );
    }

    /**
     * This function is used to handle the redirect data value.
     */
    handleRedirect(data: any, matterData: any): Observable<void> {
        if (this.currentUser.OPENINNEWTAB) {
            // const urlPath = environment.ISPRO ? '/#' : `/${environment.URLPATH}/#`;
            const urlPath = '/#';
            this.commonFocusWindow(`${urlPath}${data.URL}`, `MATTERDETAILS_${data.MATTERGUID}`);
        } else {
            this.router.navigate([]).then((result) => {
                window.open(`#${data.URL}`, '_self');
            });
        }

        // Additional logic for handling the redirect can be added here

        return of(); // Return an observable to match the signature
    }
    // RedirectHyperLink(data: any) {
    //     data.RedirectHyperLink = true;
    //     // sessionStorage.setItem(
    //     //     "SetclickMaterFromTimeLine",
    //     //     JSON.stringify(data)
    //     // );
    //     //this._behaviorService.SetclickMaterFromTimeLine(data);
    //     this.getMatterData(data)
    //     if (this.currentUser.OPENINNEWTAB) {
    //         if (environment.ISPRO) {
    //             this.commonFocusWindow(
    //                 "/#" + data.URL,
    //                 "MATTERDETAILS" + "_" + data.MATTERGUID
    //             );
    //         } else {
    //             this.commonFocusWindow(
    //                 "/" + environment.URLPATH + "/#" + data.URL,
    //                 "MATTERDETAILS" + "_" + data.MATTERGUID
    //             );
    //         }
    //     } else {
    //         // this.commonFocusWindow('/' + environment.URLPATH + '/#' + data.URL, 'MATTERDETAILS' + '_' + data.MATTERGUID);
    //         this.router.navigate([]).then((result) => {
    //             window.open("#" + data.URL, "_self");
    //         });
    //     }
    // }
    // open url in new tab
    commonFocusWindow(url, target) {
        let random = target;
        random = window.open(url, target);
        random.focus();
    }
    // ends here ~ open url in new tab
}
