import {
    Component,
    OnInit,
    Output,
    ViewEncapsulation,
    EventEmitter,
    ViewChild,
    HostListener,
    ElementRef,
    Input,
    Renderer2,
    inject,
    NgZone,
    ChangeDetectorRef,
} from "@angular/core";

import { fuseAnimations } from "src/@fuse/animations";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { SortingDialogComponent } from "../../../sorting-dialog/sorting-dialog.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import { MatSort, Sort } from "@angular/material/sort";
import { Subject, tap } from "rxjs";
import { Router } from "@angular/router";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ResizeEvent } from "angular-resizable-element";
import * as moment from "moment";
import { SelectionModel } from "@angular/cdk/collections";
import { MatMenuTrigger } from "@angular/material/menu";
import { MATTER_DASHBOARD_RIGHT_CLICK_DATA } from "src/app/_constant/Right-click-list.const";
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { HyperlinkNavigationService } from "@_services/hyperlink-navigation.service";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import { SocketService } from "@_services/socket.service";
import { BroadcastService } from "@_services/broadcast.service";

export const FAVORITES_FIRST = true;
export const FAVORITES_ALSO_SORTED = true;

@Component({
    selector: "app-matters-list",
    templateUrl: "./matters-list.component.html",
    styleUrls: ["./matters-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    //  directives:[ToolbarComponent]
})
export class MattersListComponent implements OnInit {
    public datePipe = inject(DatePipe);
    @ViewChild("matterTable", { static: false }) matterTable: ElementRef;
    @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
    selectedMatter: any;
    oldMatterTableWidth = 0;
    oldMatterTableHeight = 0;
    currentResizeIndex: number;
    startX: number;
    startWidth: number;
    isResizingRight: boolean;
    COLOR: any;
    resizableMousemove: () => void;
    resizableMouseup: () => void;

    ngAfterViewChecked() {
        // const newWidth = this.matterTable.nativeElement.offsetWidth;
        // const newHeight = this.matterTable.nativeElement.offsetHeight;
        // if(document.getElementsByClassName('cookie_policy_mian')){
        //   const myHeight = newHeight - document.getElementsByClassName('cookie_policy_mian')[0].clientHeight;
        //   if (this.oldMatterTableWidth !== newWidth || this.oldMatterTableHeight !== myHeight)
        //   setTimeout(()=>{
        //     document.getElementById("matterTableId").style.height= "10px;"
        //   },500)
        //   this.oldMatterTableHeight = myHeight;
        // }
        // this.oldMatterTableWidth = newWidth;
        // this.matterTable.nativeElement.offsetHeight = this.matterTable.nativeElement.offsetHeight - 20;
    }

    CurrentRightClickMatter: any;
    @ViewChild("matterListTable", { static: false })
    matterListTable: ElementRef;
    // subscription: Subscription;
    [x: string]: any;
    MatterDataRecords=[];
    highlightedRows: any;
    isDisplay: boolean = false;
    theme_type = localStorage.getItem("theme_type");
    selectedColore: string = "rgb(217, 217, 217)";
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    dynamiccolor =
        this.currentUser.PRODUCTTYPE == "Solicitor"
            ? "rgb(67, 160, 71)"
            : "#4E2083";
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    displayedColumns = [];
    hyperLinkInfo: any;
    ColumnsObj = [];
    dateColFilter = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    // @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatSort, { static: false }) sort: Sort;
    public resizing: boolean = false;
    currentMatters: any;
    mattersData: any = [];
    class: any;
    MATTERRECORDS: any[] = [];
    lastFilter: any = {};
    tempColobj: any;
    isGetallData: any = false;
    isCallDownloadFunction: boolean = false;
    isLoadingResults: any = false;
    pageSize: any;
    isShowDetails: boolean = true;
    pushRowData: any = JSON.parse(localStorage.getItem("stored_matter_data"));
    sortingMatterState: any = {};
    sortactive: any = "";
    sortDirection: any = "";
    @Output() matterDetail: EventEmitter<any> = new EventEmitter<any>();
    windowNameId: any;
    getAllRecords: any = [];
    CheckedUncheckedData = [];
    selection = new SelectionModel<any>(true, []);
    SelectedWIPCheckBox: any[];
    RightClickData: any;
    @ViewChild("trigger") trigger: MatMenuTrigger;
    columns = [];
    selectedCellWidth: number = 0;
    TblHeaderCellList: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    isWebSocketConnected: boolean = false;
    gloableAdvancedFilters;

    menuTopLeftPosition = { x: 0, y: 0 };
    @Input() optionalDateFilterPayload: any;
    @Input() advancedFiltersDynamic: EventEmitter<any> =
        new EventEmitter<any>();
    Object: any;
    RightClicklistMenu: any = MATTER_DASHBOARD_RIGHT_CLICK_DATA;
    webSocketData: any;
    constructor(
        private dialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private TableColumnsService: TableColumnsService,
        private behaviorService: BehaviorService,
        private SortingbehaviorService: SortingBehaviourService,
        private router: Router,
        private _hyperlink: HyperlinkNavigationService,
        public toolbarServiceService: ToolbarServiceService,
        private renderer: Renderer2,
        private socketService: SocketService,
        private broadcastService: BroadcastService,
        private ngZone: NgZone,
        private changeDetector: ChangeDetectorRef
    ) {
        this.Object = Object;
        // this.socketService.openWSConnectionMatter(this.currentUser.SessionToken, 'Matter-DataChange')
        //     .subscribe(details => {
        //         if (details) {
        //             // this.progress = details.PROGRESS;
        //             // this.btnText = details.DESCRIPTION;
        //         }
        //     })
            // this.behaviorService.matterDataChangeValues$
            // .subscribe((result) => {
            //     if (result) {
            //         console.log('resultresultresultresultresult',result);
            //         this.getWebSocketUpdate(result);
            //     }
            // });


            this.socketService.getWebSocketSubject().subscribe((event) => {
                this.ngZone.run(() => {
                    if (event) {
                        this.webSocketData = event;
                        this.getWebSocketUpdate(event);
                    }
                });
            })

            this.broadcastService.getEventSubject().subscribe((event) => {
                this.ngZone.run(() => {
                    if (event && !this.webSocketData) {
                        this.getWebSocketUpdate(event);
                    }
                });
            })

            // this.socketService.openWSConnectionMatter()
            // .subscribe(details => {
            //     if (details) {
            //         console.log('resultresultresultresult',details);
            //         // this.getWebSocketUpdate(details);
            //     }
            // })
        this.SortingbehaviorService.matterScreenSorting$
            .subscribe((result) => {
                if (result) {
                    this.sortingMatterState = result;
                    localStorage.setItem(
                        "matter_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingMatterState = JSON.parse(
                        localStorage.getItem("matter_screen_sorting")
                    );
                }
                if (this.sortingMatterState) {
                    this.sortactive = this.sortingMatterState.active;
                    this.sortDirection = this.sortingMatterState.direction;
                }
            });

        this.mattersData = [];
        if (JSON.parse(localStorage.getItem("matter_filter"))) {
            this.lastFilter = JSON.parse(localStorage.getItem("matter_filter"));
        }
        // if (JSON.parse(localStorage.getItem('matterFilter_config'))) {
        //   this.lastFilter = JSON.parse(localStorage.getItem('matterFilter_config'));
        // }

        this.getTableFilter();
        this.behaviorService.defultFeeErnerData$
            .subscribe((result) => {
                if (result) {
                    //this.getMatterList(JSON.parse(localStorage.getItem('matter_filter')));
                    //this.getMatterList(JSON.parse(localStorage.getItem('matterFilter_config')));
                }
            });

        this.behaviorService.selectedMattersClear$
            .subscribe((res) => {
                if (res) {
                    this.selection.clear();
                    this.lastFilter = JSON.parse(
                        localStorage.getItem("matter_filter")
                    );
                    //this.lastFilter = JSON.parse(localStorage.getItem('matterFilter_config'));
                    this.getMatterList(this.lastFilter);
                }
            });

        this.behaviorService.RightClick$.subscribe((res) => {
            if (res) {
                this.RightClickData = res;
            }
        });
        this._hyperlink.setColorSettingWithHyperlink();
        this.behaviorService.loaderDATA$.subscribe(data=>{
            this.isLoadingResults=data;
        })



         this.behaviorService.clickMaterFromTimeLine$
                    .pipe(
                        tap(result => {
                            const materIDofTab = window.name.split("_");
                            const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                            if (result) {
                                this.selectedMatterTab = result;
                            } else {
                                this.selectedMatterTab = JSON.parse(localStorage.getItem(windowNameId || 'set_active_matters')) || result;
                                localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.selectedMatterTab));
                            }
                        })
                    )
                    .subscribe();
    }

    /**
     * function for print the report of particular matter
     */
    // printPage() {
    //     window.print();
    // }

    getWebSocketUpdate(details){
        // const jsonDetails = JSON.parse(details);
        const matterGuidKey = 'MATTERGUID';
        this.MATTERRECORDS = this.MATTERRECORDS.map(item => {
            if (item[matterGuidKey] === details.MatterGuid) {
                for (let key in details) {
                    if (key.toUpperCase() === 'MATTERGUID' && item['MATTERGUID'] === details[key]) {
                        continue;
                    }
                    if (item.hasOwnProperty(key.toUpperCase())) {
                        item[key.toUpperCase()] = details[key];
                    }
                }
            }
            return item;
        });
        this.mattersData = new MatTableDataSource(this.MATTERRECORDS);
        this.mattersData.paginator = this.paginator;
        this.sortingMatterState = JSON.parse(localStorage.getItem("matter_screen_sorting"));
        if (this.sortingMatterState) {
            this.sortactive = this.sortingMatterState.active;
            this.sortDirection = this.sortingMatterState.direction;
        }
        this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
        this.setDefaultWidth(this.displayedColumns, 500);
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.permissionCheckforHeight$
            .subscribe((data) => {
                this.class = data;
            });

        // empty selection of matters on load
        // window.localStorage.setItem("collectedSelectedMatter", "[]");
        localStorage.setItem("collectedSelectedMatter", "[]");
        // ends here ~ empty selection of matters on load
        if (this.currentUser && this.currentUser.SORTBYSURNAME) {
            this.sortactive = "CONTACTFAMILYNAME";
            this.sortDirection = "asc";
        } else {
            this.sortactive = "SHORTNAME";
            this.sortDirection = "desc";
        }
        if (this.sortingMatterState) {
            this.sortactive = this.sortingMatterState.active;
            this.sortDirection = this.sortingMatterState.direction;
        }
        $("content").addClass("inner-scroll");
        this.behaviorService.ColorSystemSettingHyperLink$.subscribe((data) => {
            this.COLOR = data;
        });
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.TableColumnsService.setTableResize(
            this.matTableRef.nativeElement.clientWidth
        );
    }

    /**
     * function is called when someone right clicks on any matter from table
     * @param event mouse event of particular matter click
     * @param item parameter for matter item
     */
    onRightClick(event: MouseEvent, item: any) {
        if (event) {
            event.preventDefault(); // prevent the default context menu from showing
            this.CurrentRightClickMatter = item;
            this.menuTopLeftPosition.x = event.clientX;
            this.menuTopLeftPosition.y = event.clientY;
            this.trigger.menuData = { item: item };
            if (
                this.CurrentRightClickMatter?.MATTERGUID ==
                this.RightClickData?.MATTERGUID
            ) {
                this.trigger.openMenu();
            }
        }
    }

    /**
     * function is called to download pdf with mode
     * @param type type of size like portrait or landscape
     */
    public downloadAsPDF(type: any) {
        const doc = new jsPDF(type);
        // doc.autoTable({ html: '#matterListTable' });
        doc.save("matterReport.pdf");
    }

    /**
     * function is called to sorting the data with date wise
     */
    // sortingDate() {
    //     this.mattersData.sortingDataAccessor = (item, property) => {
    //         let FildeValue = this.dateColFilter;
    //         if (FildeValue.includes(property)) {
    //             if (
    //                 item[property] &&
    //                 (property == "CREATEDDATEANDTIME" ||
    //                     property == "MODIFIEDDATEANDTIME")
    //             ) {
    //                 let momentDate = moment(
    //                     moment(item[property], "DD/MM/YYYY hh:mm A")
    //                 ).format("YYYY-MM-DD HH:mm:ss");
    //                 let timestamp = Date.parse(momentDate);
    //                 return timestamp / 1000;
    //             } else if (
    //                 item[property] &&
    //                 (property != "CREATEDDATEANDTIME" ||
    //                     property != "MODIFIEDDATEANDTIME")
    //             ) {
    //                 let tempDate = item[property].split("/");
    //                 let Sd = new Date(
    //                     tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
    //                 );
    //                 let newDate = new Date(Sd);
    //                 return newDate;
    //             }
    //             return item[property];
    //         } else {
    //             if (property == "CONTACTNAME") {
    //                 return item["CONTACTNAMESORT"];
    //             }
    //             return item[property];
    //         }
    //     };
    //     // proper shorting for date
    //     this.mattersData.sort = this.sort;
    // }

    /**
     * function is call when someone click on sort icon in table
     * @param val current event of table column
     */
    // sortData(val) {
    //     this.SortingbehaviorService.matterScreenSorting(val);
    //     this.setDefaultWidth(this.displayedColumns, 0);
    // }

    //table resize change end
    refreshMatterTab(isAllData: any) {
        this.lastFilter = JSON.parse(localStorage.getItem("matter_filter"));
        // this.lastFilter = JSON.parse(localStorage.getItem('matterFilter_config'));
        // if (isAllData) {
        this.isGetallData = isAllData;
        this.lastFilter.GETALLDATA = this.isGetallData;
        // localStorage.setItem('matter_filter', JSON.stringify(this.lastFilter));
        // }
        this.getMatterList(this.lastFilter);
    }

    /**
     * function is call when someone change pagination from table
     * @param event current event of page change
     */
    onPaginateChange(event) {
        this.setDefaultWidth(this.displayedColumns, 0);
        this.pageSize.matter_page = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * function is call to get the columns of particular table
     */
    getTableFilter() {
        let mattersListColumns: any = JSON.parse(
            localStorage.getItem("matters_list_columns")
        );
        if (mattersListColumns && mattersListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(
                mattersListColumns.ColumnsObj
            );
            this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(
                mattersListColumns.ColumnsObj
            );
            this.behaviorService.Hyperlink$.next(this.hyperLinkInfo);
            this.displayedColumns = mattersListColumns.displayedColumns;
            this.ColumnsObj = mattersListColumns.ColumnsObj;
            let justification=data.tempColobj;
            localStorage.setItem('matterjustification',JSON.stringify(data?.colobj));
            if(this.currentUser.PRODUCTTYPE == 'Barrister'){
                if(this.displayedColumns[this.displayedColumns.length-1] == "CONTACTNAME"){
                    for(let data in justification){
                       if(data == "CONTACTNAME"){
                        justification[data].JUSTIFICATION = "L";
                        this.tempColobj = justification;
                       }
                    }
                }else{
                    this.tempColobj = justification
                }
            }   else{
                this.tempColobj = data.tempColobj;
            }

            //For Checkbox multi select time entries
            if (!this.displayedColumns.includes("select")) {
                if (this.displayedColumns.length !== 0) {
                    this.displayedColumns.splice(0, 0, "select");
                }
            }

            //
            if (!this.displayedColumns.includes("favourite")) {
                if (this.displayedColumns.length !== 0) {
                    this.displayedColumns.splice(1, 0, "favourite");
                }
            }

            this.dateColFilter = data.dateCol;
        } else {
            this.TableColumnsService.getTableFilter("matters", "")
                .subscribe(
                    (response) => {
                        if (
                            response.CODE == 200 &&
                            response.STATUS == "success"
                        ) {
                            let data =
                                this.TableColumnsService.filtertableColum(
                                    response.DATA.RECORDS
                                );
                            this.hyperLinkInfo =
                                this.TableColumnsService.hyperLinkInfo(
                                    response.DATA.RECORDS
                                );
                            this.behaviorService.Hyperlink$.next(
                                this.hyperLinkInfo
                            );
                            this.displayedColumns = data.showcol;
                            this.ColumnsObj = data.colobj;
                            //For Checkbox multi select time entries
                            this.displayedColumns.splice(0, 0, "select");
                            this.displayedColumns.splice(1, 0, "favourite");

                            //this.tempColobj = data.tempColobj;
                            let justification=data.tempColobj;
                            localStorage.setItem('matterjustification',JSON.stringify(data?.colobj));
                            if(this.currentUser.PRODUCTTYPE == 'Barrister'){
                                if(this.displayedColumns[this.displayedColumns.length-1] == "CONTACTNAME"){
                                    for(let data in justification){
                                       if(data == "CONTACTNAME"){
                                        localStorage.setItem('DefaultJustification',justification[data].JUSTIFICATION);
                                        justification[data].JUSTIFICATION ="L";
                                        this.tempColobj = justification;
                                       }
                                    }
                                }else{
                                    this.tempColobj = justification
                                }
                                 }else{
                                    this.tempColobj = data.tempColobj;
                                 }
                            this.dateColFilter = data.dateCol;
                            localStorage.setItem(
                                "matters_list_columns",
                                JSON.stringify({
                                    ColumnsObj: data.colobj,
                                    displayedColumns: data.showcol,
                                })
                            );
                        }
                    },
                    (error) => {
                        this.toastr.error(error);
                    }
                );
        }
    }


    /**
     * function is call to set the default width of table columns
     * @param displayedColumns array of table columns
     * @param timeout timeout set for updating column width
     */
    setDefaultWidth(displayedColumns, timeout) {
        setTimeout(() => {
            displayedColumns.forEach((element) => {
                if ((element != "select") && (element != "favourite")) {
                    let temWidth = this.tempColobj[element]["WIDTH"];
                    const cssValue = temWidth + "px";
                    const columnElts = document.getElementsByClassName(
                        "mat-column-" + element
                    );
                    for (let i = 0; i < columnElts.length; i++) {
                        const currentEl = columnElts[i] as HTMLDivElement;
                        currentEl.style.visibility = "inherit";
                        currentEl.style.width = cssValue;
                    }
                }

            });
        }, timeout);
    }

    /**
     * function is call when resize column is about to end or complete
     * @param event current resize event of resizing column
     * @param columnName column name of table 
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        this.TableColumnsService.SaveWidthData(this.ColumnsObj, "matters", "");
    }

    /**
     * function is call when resize column is still in progress
     * @param event current resize event of resizing column
     * @param columnName column name of table
     */
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(
                "mat-column-" + columnName
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(
                (col) => col.COLUMNID === columnName
            );
            this.ColumnsObj[indexData]["WIDTH"] = event.rectangle.width;
            localStorage.setItem(
                "matters_list_columns",
                JSON.stringify({
                    ColumnsObj: this.ColumnsObj,
                    displayedColumns: this.displayedColumns,
                })
            );
        }
    }

    /**
     * This function is used to open the dialog value
     */
    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            data: this.ColumnsObj,
            type: "matters",
            list: "",
        };
        //open pop-up
        const dialogRef = this.dialog.open(
            SortingDialogComponent,
            dialogConfig
        );
        //Save button click
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.displayedColumns = result.columObj;
                if (this.displayedColumns[0] != "select") {
                    //this.displayedColumns.unshift("select");
                    this.displayedColumns.splice(0, 0, "select");

                }
                if (this.displayedColumns[0] != "favourite") {
                   // this.displayedColumns.unshift("favourite");
                   this.displayedColumns.splice(1, 0, "favourite");
                }
                this.ColumnsObj = result.columnameObj;
                //this.tempColobj = result.tempColobj;
                            let justification=result.tempColobj;
                            localStorage.setItem('matterjustification',JSON.stringify(result?.columnameObj));
                            if(this.currentUser.PRODUCTTYPE == 'Barrister'){
                                if(this.displayedColumns[this.displayedColumns.length-1] == "CONTACTNAME"){
                                    for(let data in justification){
                                       if(data == "CONTACTNAME"){
                                        justification[data].JUSTIFICATION ="L";
                                        this.tempColobj = justification;
                                       }
                                    }
                                }else{
                                  let DefaultJustificatio=localStorage.getItem('DefaultJustification');
                                  if(DefaultJustificatio && DefaultJustificatio !=null && DefaultJustificatio != undefined){
                                    justification["CONTACTNAME"].JUSTIFICATION=DefaultJustificatio
                                    this.tempColobj = justification
                                  }else{
                                      this.tempColobj = justification;
                                  }
 
                                }
                                 }else{
                                    this.tempColobj = result.tempColobj;
                                 }
                this.dateColFilter = result.dateCol;
                localStorage.setItem(
                    "matters_list_columns",
                    JSON.stringify({
                        displayedColumns: result.columObj,
                        ColumnsObj: result.columnameObj,
                    })
                );
                if (!result.columObj) {
                    this.mattersData = new MatTableDataSource([]);
                    this.mattersData.paginator = this.paginator;
                    this.mattersData.sort = this.sort;
                    this.isDisplay = true;
                } else {
                    this.getMatterList(this.lastFilter);
                }
            }
        });
    }

    /**
     * function is call when user click on any row of table to edit the particular matter
     * @param matters selected matter of table
     */
    editmatter(matters) {
        if (matters) {
            localStorage.setItem("set_active_matters", JSON.stringify(matters));
            localStorage.setItem("contactGuid", matters.CONTACTGUID);
            this.pushRowData =
                this.pushRowData && this.pushRowData != null
                    ? this.pushRowData
                    : [];
            const found = this.pushRowData.find(
                (element) => element.MATTERGUID == matters.MATTERGUID
            );
            if (found == undefined) {
                this.pushRowData.push({ MATTERGUID: matters.MATTERGUID });
                localStorage.setItem(
                    "stored_matter_data",
                    JSON.stringify(this.pushRowData)
                );
                // this.behaviorService.reduceMatterContactCall(matters);
            }
            this.behaviorService.reduceMatterContactCall(matters);
            this.behaviorService.MatterData(matters);
            sessionStorage.setItem(
                "SetclickMaterFromTimeLine",
                JSON.stringify(matters)
            );
            this.behaviorService.SetclickMaterFromTimeLine(matters);
        } else {
            this.behaviorService.MatterData(null);
        }
    }

    /**
     * function is call when user double clicks on any row of matters table
     */
    doubleClick(data) {
        // this.router.navigate(['time-billing/work-in-progress']);
        // this.router.navigate(['matter-details/work-in-progress']);
        if (this.currentUser.POPUPSELECTMATTERFIRST) {
            if (this.currentUser?.OPENINNEWTAB) {
                localStorage.setItem("OPENINNEWTAB_MATTERDATA",JSON.stringify(data));
                if (environment.ISPRO) {
                    let random;
                    random = window.open('/#/matter-details/work-in-progress', 'MATTERDETAILS' + "_" + this.selectedMatterTab.MATTERGUID);
                    random.focus();
                } else {
                    let random;
                    random = window.open('/#/matter-details/work-in-progress', 'MATTERDETAILS' + "_" + this.selectedMatterTab.MATTERGUID);
                    random.focus();
                }
            } else {
                const url = this.router.serializeUrl(
                    this.router.createUrlTree(["/matter-details/work-in-progress"])
                );
                if (environment.ISPRO) {
                    this.commonFocusWindow("/#" + url);
                } else {
                    // this.commonFocusWindow("/" + environment.URLPATH + "/#" + url);
                    this.commonFocusWindow("/#" + url);
                }
            }
        } else {
            if (this.currentUser?.OPENINNEWTAB) {
                localStorage.setItem("OPENINNEWTAB_MATTERDATA",JSON.stringify(data));
                if (environment.ISPRO) {
                    // const url = "/#/matter-details/work-in-progress";
                    // window.open(url, "_blank");
                    let random;
                    random = window.open('/#/matter-details/work-in-progress', 'MATTERDETAILS' + "_" + this.selectedMatterTab.MATTERGUID);
                    random.focus();

                } else {
                    let random;
                    random = window.open('/#/matter-details/work-in-progress', 'MATTERDETAILS' + "_" + this.selectedMatterTab.MATTERGUID);
                    random.focus();

                    // const url =
                    //     "/" +
                    //     environment.URLPATH +
                    //     "/#/matter-details/work-in-progress";


                    // const url = "/#/matter-details/work-in-progress";
                    // window.open(url, "_blank");
                }
            } else {
                this.router.navigate(["matter-details/work-in-progress"]);
            }
        }
    }

    /**
     * function is call to open a web url in new window
     * @param url url to open in new window
     */
    commonFocusWindow(url) {
        const active_matter = JSON.parse(
            localStorage.getItem("set_active_matters")
        );
        let random: any = active_matter;
        random = window.open(url, "_blank");
        // this.router.navigate([url]);
        random.focus();
    }

    /**
     * function is call to display menu of matters details
     */
    showDetails() {
        if (this.isShowDetails) {
            this.matterDetail.emit(true);
            this.isShowDetails = false;
        } else {
            this.isShowDetails = true;
            this.matterDetail.emit(false);
        }
    }

    /**
     * function is call to get matters list from API
     * @param data parameter of filters to send in API
     */
    getMatterList(data) {
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }

        data.GETALLDATA = this.isGetallData;
        // this.behaviorService.MatterData(null);
        this.SortingbehaviorService.SetMatterFilterData(data);
        this.isLoadingResults = true;

        //delete data.AdvancedFilters;
        // if(!data.AdvancedFilters){
        //   if(this.advancedFiltersDynamic){
        //     data.AdvancedFilters = this.advancedFiltersDynamic;
        //   }
        // };

        // if(data.AdvancedFilters && !data.AdvancedFilters[0]){
        //   delete data.AdvancedFilters
        // };

        delete data.matterClass;
        delete data.unbilledWork;
        delete data.active;
        delete data.FeeEarnerDesc;
        delete data.matterClassDesc;
        delete data.FILTERS;

        //Advance Filter Payload Logic.
        const advanceFilterPayload =
            this.TableColumnsService.loadAdvanceFilterPayload("MatterWindow");
        data.AdvancedFilters =
            advanceFilterPayload && advanceFilterPayload.length !== 0
                ? advanceFilterPayload
                : [];

        if (data.AdvancedFilters.length == 0) {
            delete data.AdvancedFilters;
            localStorage.removeItem("MatterWindow_Advance_Filter");
        }
        const AdvancedFilters = data.AdvancedFilters
            ? data.AdvancedFilters
            : [];
        let Payload: any = {
            Action: "GetData",
            Filters: data,
            AdvancedFilters: AdvancedFilters,
        };
        this.gloableAdvancedFilters=Payload;
        if (AdvancedFilters.length == 0) {
            delete Payload.AdvancedFilters;
        }
        delete Payload.Filters.AdvancedFilters;
        this._mainAPiServiceService
            .getSetData(Payload, "matter")
            .subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.MATTERRECORDS=[];
                        if (response.DATA.RECORDS && response.DATA.RECORDS[0]) {
                            this.isShowDetails = false;
                            this.isDisplay = false;
                            let matterBehaviorData: any = {};
                            this.behaviorService.MatterData$.subscribe(
                                (res) => {
                                    matterBehaviorData = res;
                                }
                            );
                            // this.behaviorService.MatterData$.subscribe(result => {
                            //        const materIDofTab = window.name.split("_").pop();
                            // this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
                            //    this.matterBehaviorData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
                            //    if (this.matterBehaviorData) {
                            //    } else {
                            //      if (result) {
                            //        this.matterBehaviorData = result;
                            //        localStorage.setItem(this.windowNameId,JSON.stringify(this.matterBehaviorData))
                            //      }
                            //    }
                            //  });

                            if (matterBehaviorData) {
                                let updatedMatterData =
                                    response.DATA.RECORDS.filter(
                                        (data) =>
                                            data.MATTERGUID ==
                                            matterBehaviorData.MATTERGUID
                                    );
                                if (updatedMatterData.length != 0) {
                                    this.behaviorService.MatterData(
                                        updatedMatterData[0]
                                    );
                                    this.highlightedRows = updatedMatterData[0]
                                        .MATTERGUID
                                        ? updatedMatterData[0].MATTERGUID
                                        : "";
                                    this.editmatter(updatedMatterData[0]);
                                } else {
                                    this.behaviorService.MatterData(
                                        matterBehaviorData
                                    );
                                    this.highlightedRows =
                                        matterBehaviorData.MATTERGUID
                                            ? matterBehaviorData.MATTERGUID
                                            : "";
                                    this.editmatter(matterBehaviorData);
                                }
                            } else {
                                this.behaviorService.MatterData(
                                    response.DATA.RECORDS[0]
                                );
                                this.highlightedRows =
                                    response.DATA.RECORDS[0].MATTERGUID;
                                this.editmatter(response.DATA.RECORDS[0]);
                            }
                        } else {
                            this.behaviorService.MatterData(null);
                            this.editmatter(null);
                            this.isDisplay = true;
                        }

                        this.MATTERRECORDS=response.DATA.RECORDS
                        this.mattersData = new MatTableDataSource(response.DATA.RECORDS);
                        // this.downloadFile(response.DATA.MATTERS,'jsontocsv');
                        this.mattersData.paginator = this.paginator;
                        this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
                        // this.sortingDate();
                        this.isGetallData = response.DATA.TOOMANYRECORDS;
                        this.isLoadingResults = false;
                        this.setDefaultWidth(this.displayedColumns, 500);
                        if (this.isCallDownloadFunction == true) {
                            setTimeout(() => {
                                this.downloadFile();
                                this.isCallDownloadFunction = false;
                            }, 1000);
                        }
                    } else if (
                        (response.CODE == 406 || response.CODE == 400) &&
                        (response.MESSAGE == "Permission denied" ||
                            response.MESSAGE == "General Error")
                    ) {
                        this.mattersData = new MatTableDataSource([]);
                        this.mattersData.paginator = this.paginator;
                        // this.sortingDate();
                        this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
                        this.isLoadingResults = false;
                        this.isDisplay = true;
                        this.setDefaultWidth(this.displayedColumns, 500);
                    } else if (response.errorMessage) {
                        this.toastr.error(response.errorMessage);
                        this.mattersData = new MatTableDataSource([]);
                        this.mattersData.paginator = this.paginator;
                        // this.sortingDate();
                        this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
                        this.isLoadingResults = false;
                        this.isDisplay = true;
                        this.setDefaultWidth(this.displayedColumns, 500);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                    this.isLoadingResults = false;
                    this.isDisplay = true;
                }
            );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * This function is used to on sort changes the data
     * @param sort 
     */
    onSortChanged(sort: Sort) {
        this.sort = sort;
        const matters = this.mattersData.data.slice();
        const column = sort.active;
        const isAsc = sort.direction === 'asc';
        this.SortingbehaviorService.matterScreenSorting(sort);
        this.setDefaultWidth(this.displayedColumns, 0);
        this.sortIt(column, isAsc, matters);
    }

    /**
     * This function is used to restart after Data Changed
     */
    resortAfterDataChanged() {
        this.onSortChanged(this.sort);
    }
    
    /**
     * This unction is used to Sort the it data value
     * @param property -property data value
     * @param isAsc -isAsc data value
     * @param matters -matters data value
     */
    sortIt(property: string, isAsc: boolean, matters): void {
        matters.sort((a, b) => {
          if (FAVORITES_FIRST) {
            if (a.FAVOURITE && b.FAVOURITE) {
              return this.sortFavorites(property, isAsc, a, b);
            }
            // if only one favorite, the favorite is on top, no matter asc or desc
            // if (a.FAVOURITE === 1) {
            //   return -1;
            // } else if (b.FAVOURITE === 1) {
            //   return 1;
            // }
            if (a.FAVOURITE) {
                return -1; // a is favorite, so it comes before b
            } else if (b.FAVOURITE) {
                return 1; // b is favorite, so it comes before a
            }
            // if (a.FAVOURITE && !b.FAVOURITE) {
            //     return -1; // a is favorite, so it comes before b
            // } else if (!a.FAVOURITE && b.FAVOURITE) {
            //     return 1; // b is favorite, so it comes before a
            // }
            // else {
            //     return this.sortFavorites(property, isAsc, a, b);
            // }
          }
    
          // if no favorite, standard sorting
          return this.stdSort(property, isAsc, a, b);
        });
    
        this.mattersData = new MatTableDataSource(matters);
        this.mattersData.paginator = this.paginator;
        // this.mattersData.sort = this.sort;
    }

    /**
     * This function is used to sort the fav data
     * @param property -property data value
     * @param isAsc -b oolean type
     * @param a -first value
     * @param b -second value
     * @returns -sorted data
     */
    private sortFavorites(
        property: string,
        isAsc: boolean,
        a,
        b
      ): number | string {
        if (FAVORITES_ALSO_SORTED) {
          // if both favorites, they must be ordered among themselves
          return this.stdSort(property, isAsc, a, b);
        }
        // if both favorites, no sorting among themselves
        return 0;
      }

    stdSort(property: string, isAsc: boolean, a, b) {
        switch (property) {
            // case 'MODIFIEDDATE':
            //     return this.compare(a.MODIFIEDDATE, b.MODIFIEDDATE, isAsc);
            // case 'CREATEDDATE':
            //     return this.compare(a.CREATEDDATE, b.CREATEDDATE, isAsc);
            // case 'DATE':
            //     return this.compare(a.DATE, b.DATE, isAsc);
            case property:
                    return this.compare(a, b, property, isAsc)
            default:
                return 0;
        }
    }

    /**
     * This function is used to compare the table data value
     */
    compare(
        a: any,
        b: any,
        property,
        isAsc: boolean
      ): number | string {
        let FildeValue = this.dateColFilter;
        const splitDate = (date) => {
            const dateValues = date.split("/");
            const day = dateValues[0];
            const month = dateValues[1];
            const year = dateValues[2];
            // let Sd = new Date(
            //     tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
            // );
            // let newDate = new Date(Sd);
            return month + "/" + (day.length < 2 ? `0${day}`: day) + "/" + year;
            // const originalDate = moment(date, 'DD/MM/YYYY');
            // const formattedDate = originalDate.format('DD/MM/YYYY');
        }

        const splitDT = (date) => {
            if(date) {
                const momentDate = moment(moment(date, "DD/MM/YYYY hh:mm A")).format("YYYY-MM-DD HH:mm:ss");
                let timestamp = Date.parse(momentDate);
                return timestamp / 1000;
            }
        }

        // const convertDate = (date) => {
        //     return this.datePipe.transform(new Date(date), 'dd/MM/yyyy');
        // }
        // return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        // let newDate = new Date(Sd);
        // let newDate = ;
        if (FildeValue.includes(property)) {
            if ((a[property] && b[property]) && (property == "CREATEDDATEANDTIME" || property == "MODIFIEDDATEANDTIME")) {
                const AD = splitDT(a[property]);
                const BD = splitDT(b[property]);
                // let momentDate = moment(
                //     moment(item[property], "DD/MM/YYYY hh:mm A")
                // ).format("YYYY-MM-DD HH:mm:ss");
                // let timestamp = Date.parse(momentDate);
                // return timestamp / 1000;
                return (new Date(AD).valueOf() < new Date(BD).valueOf() ? -1 : 1) * (isAsc ? 1 : -1);
            } else if ((a[property] && b[property]) && (property != "CREATEDDATEANDTIME" || property != "MODIFIEDDATEANDTIME")) {
                const A = splitDate(a[property]);
                const B = splitDate(b[property]);
                // let tempDate = item[property].split("/");
                // let Sd = new Date(
                //     tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                // );
                // let newDate = new Date(Sd);
                // return newDate;
                return (new Date(A).valueOf() < new Date(B).valueOf() ? -1 : 1) * (isAsc ? 1 : -1);
            }
            return (a[property] < b[property] ? -1 : 1) * (isAsc ? 1 : -1);
        } else {
            if (property == "CONTACTNAME") {
                // return "CONTACTNAMESORT";
                return (a["CONTACTNAMESORT"] < b["CONTACTNAMESORT"] ? -1 : 1) * (isAsc ? 1 : -1);
            }
            return (a[property] < b[property] ? -1 : 1) * (isAsc ? 1 : -1);
        }
        // return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        // return (new Date(A).valueOf() < new Date(B).valueOf() ? -1 : 1) * (isAsc ? 1 : -1);
      }



    /**
     * function is call to download current matters data with csv format
     */
    downloadFile() {
        // const sortedData = this.mattersData.sortData(
        //     this.mattersData.filteredData,
        //     this.mattersData.sort
        // );  
        this.TableColumnsService.exportDataIntoCsv(
            this.displayedColumns,
            this.mattersData.filteredData,
            "Matter_data_",
            this.tempColobj
        );
    }

    /**
     * function is call to download all matters data with csv format
     */
    async DownloadAllData() {
        // const requestdata={
        //   Active: "active",
        //  FeeEarner: "all",
        //  GETALLDATA: true,
        //  MatterClass: "",
        //  SearchString: "",
        //  UninvoicedWork: "All"
        // };

        //this.isGetallData=true
        //this.isCallDownloadFunction=true

        // let appyFilterPayload = JSON.parse(JSON.stringify(requestdata));
        // let daynamicFilters = [];
        // this.optionalDateFilterPayload.forEach((e)=>{
        //   daynamicFilters.push(e.value);
        // });

        // if(daynamicFilters.length !== 0){
        //   appyFilterPayload.AdvancedFilters = daynamicFilters;
        // }

        // this.getMatterList(appyFilterPayload);

        // this.getMatterList(requestdata)

        const requestdata = {
            Active: "active",
            FeeEarner: "all",
            GETALLDATA: true,
            MatterClass: "all",
            SearchString: "",
            UninvoicedWork: "All",
        };
        this.isLoadingResults = true;

        this._mainAPiServiceService
            .getSetData(
                { Action: "GetData", Filters: { requestdata } },
                "matter"
            )
            .subscribe(
                async (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.getAllRecords = await response.DATA.RECORDS;
                        // const sortedData = this.mattersData.sortData(
                        //     this.getAllRecords,
                        //     this.mattersData.sort
                        // );
                        await this.TableColumnsService.exportDataIntoCsv(
                            this.displayedColumns,
                            this.getAllRecords,
                            "Matter_data_",
                            this.tempColobj
                        );
                        this.isLoadingResults = false;
                    } else if (
                        (response.CODE == 406 || response.CODE == 400) &&
                        (response.MESSAGE == "Permission denied" ||
                            response.MESSAGE == "General Error")
                    ) {
                        this.isLoadingResults = false;
                    }
                },
                (error) => {
                    this.toastr.error(error);
                    this.isLoadingResults = false;
                }
            );
    }

    //::::: Multi Select timer ::::
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.mattersData.data.forEach((row) =>
                this.selection.select(row)
            );
        this.collectSelectedVal();
    }

    /**
     * This unction is used to Select the All
     * @returns data value
     */
    isAllSelected() {
        let numSelected: any = 0;
        let numRows: any = 0;
        if (this.selection.selected) {
            numSelected = this.selection.selected.length;
        }
        if (this.mattersData.data) {
            numRows = this.mattersData.data.length;
        }
        return numSelected === numRows;
    }


    /**
     * This function is used to checkbox lebel
     * @param row -row data value
     * @returns -data value
     */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
            }`;
    }

    /**
     * This function is used to collect the Selectedvalue
     */
    collectSelectedVal() {
        this.SelectedWIPCheckBox = [];
        if (this.selection.selected.length > 0) {
            this.selection.selected.forEach((element) => {
                //  element.TAGGED = 1;
                this.SelectedWIPCheckBox.push(element);
            });
        }
        //  else {
        //   this.SelectedWIPCheckBox = [];
        // }
        localStorage.setItem(
            "collectedSelectedMatter",
            JSON.stringify(this.SelectedWIPCheckBox)
        );
        //  window.localStorage.setItem("collectedSelectedMatter",JSON.stringify(this.SelectedWIPCheckBox))

        this.behaviorService.selectedMatters$.next(this.SelectedWIPCheckBox);
        // localStorage.setItem('SelectedMatterCheckbox', JSON.stringify(this.SelectedWIPCheckBox));
    }

    /**
     * This function is used to select the hyperlink
     * @param row -row data value
     * @param item -item data value
     * @param hyperLinkInfo -hyperlink information data
     */
    selectHyperLink(row, item, hyperLinkInfo: any) {
        this._hyperlink.selectHyperLink(row, item, hyperLinkInfo);
    }

    /**
     * This function is used to Right click data Add
     * @param Rightclickdata -get the right click data value
     */
    RightClickAddData(Rightclickdata: any) {
        switch (Rightclickdata.TABID) {
            //  Edit mayter
            case 1:
                {
                    this.toolbarServiceService.matterpopup("edit");
                }
                break;
            //  Matter Details
            case 2:
                {
                    //    this.toolbarServiceService.setTab('')
                    //    this.toolbarServiceService.targetNewTab('/matter-details','MATTERDETAILS');
                }
                break;
            //  Genrate Document
            case 3:
                {
                    this.toolbarServiceService.generateDocumentsFromMatters("");
                }
                break;
            //  New Time ENtry
            case 4:
                {
                    this.toolbarServiceService.addNewTimeEntryNew("");
                }
                break;
            //  New Timer
            case 5:
                {
                    this.toolbarServiceService.addTimerForMatter();
                }
                break;
            //  New File NOte
            case 6:
                {
                    this.toolbarServiceService.FileNotePopup("new");
                }
                break;
            // Instant Invoice
            case 7:
                {
                    this.toolbarServiceService.createInstantInvoice(false);
                }
                break;
            // Prview Invoice
            case 8:
                {
                    this.toolbarServiceService.createInvoice("preview");
                }
                break;
            //Invoice ALL  WIP
            case 9:
                {
                    this.toolbarServiceService.createInvoice("Invoice All WIP");
                }
                break;
            //New Recipt
            case 10:
                {
                    this.toolbarServiceService.createReceipt(
                        "allredy_select_matter"
                    );
                }
                break;
            //New Matter Expense
            case 11:
                {
                    this.toolbarServiceService.spendmoneypopup(
                        "new",
                        "FromWIP",
                        "true"
                    );
                }
                break;
            // "defualt"
            case 12:
                {
                }
                break;
        }
    }

    //   // Resize table column. also make some change in html side put below function with 'mwlResizable'.
    //   onResizeColumnSetting(event , index){
    //     //event : selected column.
    //     //index : selected column index.
    //     //renderer : dont make this comman it's give issue import in your component.
    //     //displayedColumns : displayedColumns name's (Table Header names).
    //     //matTableRef : most importent please import in component wise don't make it comman.
    //     // last field is localstorage name which use to store columnObj

    //     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'matters_list_columns');
    //   };

    //For resize this fuction is most impotent don't make it comman it's give issue in future.
    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.TableColumnsService.setTableResize(
            this.matTableRef.nativeElement.clientWidth
        );
    }

    /**
     * This Function is used to get the Favourite Matters
     * @param row -matter data
     */
    getFavouritelist(row){
        this.isLoadingResults = true;   
        this._mainAPiServiceService.getSetData({Action: "setfavourite",DATA:{ MATTERGUID:row.MATTERGUID,FAVOURITE:(row.FAVOURITE == 0 )? true : false} }, "matter").subscribe(data=>{
        if(data.CODE==200){
            this.isLoadingResults = false;
           // $('#refreshMatterTab').click();
           this.MATTERRECORDS.forEach(element => {
                if(row.MATTERGUID == element.MATTERGUID){
                element.FAVOURITE = (element.FAVOURITE ==1) ? 0 : 1
                }
            });

            this.mattersData = new MatTableDataSource(this.MATTERRECORDS);
            this.mattersData.paginator = this.paginator;
            this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
        }
        })
    }

    /**
     * This Function is used to Sort the items By Favourite
     */
    sortItemsByFavourite() {
        this.MatterDataRecords.sort((a, b) => {
          if (a.FAVOURITE === b.FAVOURITE) {
            return 0;
          }
          return a.FAVOURITE ? -1 : 1;
        });
        return this.MatterDataRecords
        // this.mattersData = new MatTableDataSource(
        //     this.MatterDataRecords
        // );
      }

/**
 * This function is used to getNewExportData;
 */
      getNewExportData(){
        let matterjustification=JSON.parse(localStorage.getItem('matterjustification'));
        let columnname=[];
        if( this.sortactive !== undefined && this.sortDirection !==undefined &&  this.sortactive !== '' && this.sortDirection !==''){
            columnname=matterjustification.filter(data=>data.COLUMNID == this.sortactive);
        }else{
            columnname.push({COLUMNNAME:''});
            this.sortDirection='';
        }
        this.behaviorService.loaderDATA$.next(true);
        let advanceFilters=(this.gloableAdvancedFilters?.AdvancedFilters !=undefined) ? this.gloableAdvancedFilters?.AdvancedFilters :''
        this.TableColumnsService.getNewExportData("matters","","matter",this.gloableAdvancedFilters?.Filters,advanceFilters,columnname[0].COLUMNNAME,this.sortDirection)
    }
}