
<div id="DocReg" class="page-layout simple left-sidebar inner-sidebar inner-scroll" [ngClass]="COLOR">
    <div fxLayoutAlign="center" class="content">
        <div class="center p-24 more_tbl_clmn list_tbl_main">
            <div class="matter-serach-div sticky_search_div responsive_auto">
                <div id="refreshDOCREGTab" (click)="refreshDOCREGTab();"> </div>
                <!-- <form [formGroup]="documentform" class="align-center display-flex filter-menuwrap pt-0 pb-0">
                    <div class="align-center display-flex flex-wrap filter-menublock">
                        <div class="position-relative">
                            <mat-icon class="badge-ic">
                                 </mat-icon>
                            <button mat-button  class="buttonwith-text dotted-button calender-btn"  >
                                Matter <span class="filter-applytext display-flex align-center">
                                    <input matInput placeholder="Matter" formControlName="matter" readonly>
                                    <mat-icon matSuffix (click)='DcoumentFloder()'>
                                        <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                                    </mat-icon>
                            </span>
                            </button>
                        </div>

                        <div class="position-relative">
                            <mat-icon class="badge-ic">
                                 </mat-icon>
                            <button mat-button  class="buttonwith-text dotted-button calender-btn"  >
                                {{('' | productType)?'Client' :' Solicitor'}} <span class="filter-applytext display-flex align-center">
                                    <input matInput placeholder="Client" formControlName="Client">
                                    <mat-icon matSuffix (click)='SelectClient()'>
                                        <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                                    </mat-icon>
                            </span>
                            </button>
                        </div>
                        <div class="position-relative" fxFlex="25">

                           
                            <mat-icon class="badge-ic">
                            </mat-icon>

                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                Search<span class="filter-applytext display-flex align-center">

                                    <input style="margin-top: 3px;" matInput placeholder="search" formControlName="search" (keydown)="onSearch($event)">
                                    <mat-icon matSuffix>search</mat-icon>

                                </span>
                            </button>
                        </div>
                        <div>
                            Folder: <strong>  {{showFolder}}</strong>
                        </div>
                        <span class="appCues-blankSpace">
                            <a href="javascript:void(0);">Link</a>
                        </span>
                    </div>

                    <button mat-raised-button color="primary" type="button" id="refreshDOCREGTab" (click)="refreshDOCREGTab();">Refresh</button>
                </form> -->
                <!-- <form [formGroup]="documentform" class="align-center display-flex filter-menuwrap form-div-side" id="filter-form-menu-wrap">
                    <div class="align-center display-flex flex-wrap filter-menublock">
                            <div class="add-filterwrap">
                            <button mat-button [matMenuTriggerFor]="dummyButton" [disabled]="true" style="display: none;">
                                Filters</button>
                            <mat-menu #dummyButton="matMenu">
                            </mat-menu>
                            <button mat-button [matMenuTriggerFor]="menuFilteroption" class="filter-button"
                             
                            
                            (click)="backToFilter('optional-Discount');showFiltersList=true;"><img [src]="currentUser?.PRODUCTTYPE == 'Solicitor' ? 'assets/icons/filter-icon.png':'assets/icons/filter-icon-barrister.png'"
                                alt="Filter" class="filter-icon" />
                           <span > Filters </span> </button>


                           <mat-icon class="icon-btn close-btn" (click)="resetFilter()" *ngIf="optionalFilters?.length > 0">close</mat-icon>
                           <mat-icon class="icon-btn"  id="refreshDOCREGTab" (click)="refreshDOCREGTab()">refresh</mat-icon >
                            <mat-menu #menuFilteroption="matMenu" (click)="preventDefault($event)">
                                <div class="filterscroll">
                                <div  *ngFor="let optionalFilterData of optionalFilterList">
                                    <div  *ngIf="optionalFilterData.FILTERTYPE != 'HIDDEN'">
                                    <button class="addbutton" [ngClass]="COLOR" *ngIf="!optionalFilterData.FILTERAPPLY  && showFiltersList"
                                        (click)="preventDefault($event);activeCurrentFilter('optional-Discount',optionalFilterData);addNewFilter(activeOptionalFilter);showFiltersList=false;">
                                        <mat-icon>add_circle</mat-icon>{{optionalFilterData.DESCRIPTION}}
                                    </button>
                                    <div class="menu-submenu" id="optional-Discount">
                                        <h5 class="filter-title" *ngIf="activeOptionalFilter">
                                            <mat-icon class="back-icon"
                                                (click)="backToFilter('optional-Discount');preventDefault($event);showFiltersList=true;">arrow_back_ios
                                            </mat-icon>Filter by {{activeOptionalFilter.DESCRIPTION}}
                                        </h5>
                                        <div *ngIf="activeOptionalFilter">
                                            <ng-container formArrayName="FILTERSLIST">
                                                <div *ngFor="let filter of _filterList; let j =index;" class="position-relative">
                                                    <ng-container [formGroupName]="j">
                                                        <mat-form-field appearance="outline" class="width-full"
                                                            (click)="preventDefault($event)">
                                                            <mat-select (selectionChange)="optionalDateFilter($event , filter , 'default' , -1)"
                                                                name="OPERATION" [(ngModel)]="addFilter.OPERATION"
                                                                formControlName="OPERATION"  #operationFilter>
                                                                <mat-option *ngFor="let d of filter.DATEFILTERLIST "
                                                                    [value]="d.value">{{d.description}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <mat-form-field class="width-full" appearance="outline"
                                                                (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                <input  matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required appNumberMask>
                                                            </mat-form-field>
                                                            <mat-form-field class="width-full" appearance="outline"
                                                                (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                <input  matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required currencyMask>
                                                            </mat-form-field>
                                                           
                                                        </div>
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <mat-form-field class="width-full" appearance="outline"
                                                                (click)="preventDefault($event)">
                                                                <input matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                            </mat-form-field>
                                                        </div>
            
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required appNumberMask>
                                                                </mat-form-field>
                                                                <span class="middle-caption">and</span>
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE2"
                                                                        formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required appNumberMask>
                                                                </mat-form-field>
                                                            </div>

                                                            <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required currencyMask>
                                                                </mat-form-field>
                                                                <span class="middle-caption">and</span>
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE2"
                                                                        formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required currencyMask>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <div class="display-flex align-center">
                                                                <mat-form-field appearance="outline" class="set-date"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput [matDatepicker]="picker6" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                    <mat-datepicker-toggle matSuffix [for]="picker6">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker6></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <div class="display-flex align-center field-box"
                                                                (click)="preventDefault($event)">
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                </mat-form-field>
                                                                <mat-form-field appearance="outline" class="set-date">
                                                                    <mat-select [value]="'days'" formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required>
                                                                        <mat-option value="days">Days</mat-option>
                                                                        <mat-option value="month">Month </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
            
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                            <mat-form-field appearance="outline" class="set-date"
                                                                (click)="preventDefault($event)">
                                                                <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                                    formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                            </mat-form-field>
                                                            <span class="middle-caption">and</span>
                                                            <mat-form-field appearance="outline" class="set-date"
                                                                (click)="preventDefault($event)">
                                                                <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                                    formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required>
                                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker2></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>
            
                                                        <button mat-raised-button color="primary" class="width-full accent"
                                                            (click)="addfilter(filter , operationFilter, $event)">Apply</button>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </mat-menu>

                            </div>
                            <div class="position-relative">
                                <mat-icon class="badge-ic">
                                     </mat-icon>
                                <button mat-button  class="buttonwith-text dotted-button calender-btn"  >
                                    Matter <span class="filter-applytext display-flex align-center">
                                        <input matInput placeholder="Matter" formControlName="matter" readonly>
                                        <mat-icon matSuffix (click)='DcoumentFloder()'>
                                            <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                                        </mat-icon>
                                </span>
                                </button>
                            </div>
                            <div class="position-relative">
                                <mat-icon class="badge-ic">
                                     </mat-icon>
                                <button mat-button  class="buttonwith-text dotted-button calender-btn"  >
                                    {{('' | productType)?'Client' :' Solicitor'}} <span class="filter-applytext display-flex align-center">
                                        <input matInput placeholder="Client" formControlName="Client">
                                        <mat-icon matSuffix (click)='SelectClient()'>
                                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                                        </mat-icon>
                                </span>
                                </button>
                            </div>
                            <div class="position-relative" fxFlex="20">

                           
                                <mat-icon class="badge-ic"(click)="onSearch('Empty')" *ngIf="Searchvalue">
                                    {{Searchvalue ?'cancel_circle' : ''}}
                                </mat-icon>
    
                                <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                    Search<span class="filter-applytext display-flex align-center">
    
                                        <input style="margin-top: 3px;" matInput placeholder="search" formControlName="search" (keydown)="onSearch($event)">
                                        <mat-icon matSuffix>search</mat-icon>
    
                                    </span>
                                </button>
                            </div>
                            <div class="position-relative" >
                                <mat-slide-toggle name="includeSubFolder" [(ngModel)]='includeSubFolder' [ngModelOptions]="{standalone: true}"
                                (change)="changeSubFolderInclude()"> Include Sub Folders
                                </mat-slide-toggle>

                            </div>
                            <div class="position-relative">
                                Folder: <strong>  {{showFolder}}</strong>
                            </div>
                            <ng-container formArrayName="FILTERS">
                                <div *ngFor="let filter of optionalFilters; let i =index;" class="position-relative">
                                    <ng-container [formGroupName]="i">
                                        <mat-icon class="badge-ic" (click)="resetOptionalFilter(filter , filter.FILTERTYPE , i)">cancel_circle
                                        </mat-icon>
                                        <button mat-button [matMenuTriggerFor]="menuCreatedDate_i" class="buttonwith-text" (click)="setFilterUi(filter,i)">
                                            {{filter.DESCRIPTION}}<span
                                                class="filter-applytext display-flex align-center">{{(optionalDateFilterPayload[i] &&
                                                optionalDateFilterPayload[i]['value'] !==
                                                null)?optionalDateFilterPayload[i]['value'].VALUE : ''}} {{(optionalDateFilterPayload[i]
                                                && optionalDateFilterPayload[i]['value'] !== null &&
                                                optionalDateFilterPayload[i]['value'].VALUE2 !== '' && optionalDateFilterPayload[i]['value'].VALUE2 !== null)?' - ' +
                                                optionalDateFilterPayload[i]['value'].VALUE2 : ''}}<mat-icon class="rightdownarrow">
                                                    keyboard_arrow_down</mat-icon></span>
                                        </button>
                                        <mat-menu #menuCreatedDate_i="matMenu">
                                            <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5>
                                            <mat-form-field appearance="outline" class="width-full" (click)="preventDefault($event)">
                                                <mat-select (selectionChange)="optionalDateFilter($event , filter , 'active' , i)" name="OPERATION"
                                                    formControlName="OPERATION" #Datefilter>
                                                    <mat-option *ngFor="let d of filter.DATEFILTERLIST " [value]="d.value">{{d.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <mat-form-field class="width-full" appearance="outline"
                                                    (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                    <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                        required appNumberMask>
                                                </mat-form-field>
                                                <mat-form-field class="width-full" appearance="outline"
                                                    (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                    <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                        required currencyMask>
                                                </mat-form-field>
                                            </div>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <mat-form-field class="width-full" appearance="outline"
                                                    (click)="preventDefault($event)">
                                                    <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                        required>
                                                </mat-form-field>
                                            </div>
                
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required appNumberMask>
                                                    </mat-form-field>
                                                    <span class="middle-caption">and</span>
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE2" formControlName="VALUE2"
                                                            required appNumberMask>
                                                    </mat-form-field>
                                                </div>

                                                <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required currencyMask>
                                                    </mat-form-field >
                                                    <span class="middle-caption">and</span>
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE2" formControlName="VALUE2"
                                                            required currencyMask>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <div class="display-flex align-center">
                                                    <mat-form-field appearance="outline" class="set-date"
                                                        (click)="preventDefault($event)">
                                                        <input matInput [matDatepicker]="picker6" name="VALUE" formControlName="VALUE"
                                                            required>
                                                        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker6></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <div class="display-flex align-center field-box" (click)="preventDefault($event)">
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="set-date">
                                                        <mat-select [value]="'days'" formControlName="VALUE2" required>
                                                            <mat-option value="days">Days</mat-option>
                                                            <mat-option value="month">Month </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                                    <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                        formControlName="VALUE" required>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                <span class="middle-caption">and</span>
                                                <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                                    <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                        formControlName="VALUE2" required>
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <button mat-raised-button color="primary" class="width-full accent"
                                            (click)="applayOptionalfilter(optionalFilters[i] , i ,Datefilter, $event,'isDynamic')">Apply</button>
                                        </mat-menu>
                                    </ng-container>
                                </div>
                            </ng-container>
                            
                    </div>
                </form> -->
                                            <!-- GSM -->
                                            <!-- <div class="align-center display-flex flex-wrap time-selection" *ngIf="(filter.FILTERTYPE == 'DATE'&& !filter.selectedFilter || filter.selectedFilter == 'is in the last')">
                                            <span class="timeselection-text">TIme zone:</span>
                                            <mat-radio-group aria-label="Select an option" (click)="preventDefault($event)">
                                                <mat-radio-button value="1">GMT + 10</mat-radio-button>
                                                <mat-radio-button value="2">UTC</mat-radio-button>
                                            </mat-radio-group>
                                        </div> -->
            </div>

            <!-- appFileDragNDrop (filesChangeEmiter)="onFilesChange($event)" -->
            <div class="content" >
                <!-- <div class="btn contact_right_action one dot_controller">
                    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>
                    <mat-menu #contextMenu="matMenu">
                        <ng-template matMenuContent let-item="item">
                            <button mat-menu-item (click)="DocumentDialog()"> Field Configuration</button>
                            <button mat-menu-item   id="refreshDOCREGTab" (click)="refreshDOCREGTab()"> Reload Data</button>
                        </ng-template>
                    </mat-menu>
                    <button mat-icon-button (click)="IsRegisterDocumentList=!IsRegisterDocumentList" aria-label="More">
                        <mat-icon class="secondary-text">menu</mat-icon>
                    </button>
                </div> -->
                <div class="content">
                    <!-- <div class="example-loading-shade" *ngIf="isLoadingResults">
                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                    </div> -->
                    <div class=" resizable-table" >
                        <!-- <div class="grid-overlay" *ngIf="isLoadingResults" style="margin-top: 220px !important;margin-left: 120px !important;">
                            <div class="grid-overlay-msg"> Loading please wait </div>
                        </div> -->
                        <div class="sample-container" id="containerdata">
                            <!-- <ejs-filemanager id='overview' [ajaxSettings]='ajaxSettings'  [toolbarSettings]="toolbarSettings" [contextMenuSettings]="contextMenuSettings" [view]='view'>
                            </ejs-filemanager> -->
                            <!-- (beforeDelete)="onBeforeDelete($event)" -->
                           <!-- <div class="content" appFileDragNDrop (filesChangeEmitter)="onFilesChange($event)"> -->
                            <!-- [navigationPaneSettings]="navigationPaneSettings" -->
                               <ejs-filemanager
                               #fileManager
                               id="file-manager"
                               (fileSelect)="onFileSelect($event)"
                               (fileOpen)="onFileOpen($event,resultData)"
                               [fileSystemData]="resultData"
                               [allowDragAndDrop]='allowDragAndDrop'
                               (created)="created($event)"
                               (uploadListCreate)="uploadListCreate($event)"
                               (beforePopupOpen)="beforePopupOpen($event)"
                               [showItemCheckBoxes]="false"
                               [allowMultiSelection]="false"
                               [toolbarSettings]="toolbarSettings"
                               [contextMenuSettings]="contextMenuSettings"
                               [detailsViewSettings]="detailsViewSettings"
                               (fileDragStart)='onFileDragStart($event)'
                               (fileDragStop)='onFileDragStop($event)' 
                               (fileDragging)='onFileDragging($event)' 
                               (fileDropped)='onFileDropped($event)'
                               (popupOpen)="popupOpen($event)"
                               (success)="success($event,'true')"
                               [view]="view"
                               (toolbarClick)="onToolbarClick($event)"
                               (fileLoad)="onFileLoad($event)"
                               >
                               
                               </ejs-filemanager>
                           <!-- </div> -->


                           <!-- [allowDragAndDrop]='allowDragAndDrop'
                           (fileDragStart)='onFileDragStart($event)'
                           (fileDragStop)='onFileDragStop($event)' 
                           (fileDragging)='onFileDragging($event)'
                           (fileDropped)='onFileDropped($event)' -->

                        </div>
                        <!-- <mat-table #table [dataSource]="DocumentAllData" (matSortChange)="sortData($event)" matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear matSort [@animateStagger]="{value:'50'}">

                            <ng-container *ngFor="let item of displayedColumns; let indx = index" matColumnDef="{{item}}">
                                <mat-header-cell 
                                *matHeaderCellDef="let i = index"
                                mat-sort-header
                                class="header_title"
                                mwlResizable
                                enableGhostResize="true"
                                (resizing)="onResizing($event, item)"
                                (resizeEnd)="onResizeEnd($event, item)"
                                [resizeEdges]="{
                                    bottom: false,
                                    right: true,
                                    top: false,
                                    left: true
                                 }">
                                <div
                                    class="desc_cell resize-handle-right "
                                    mwlResizeHandle
                                    [resizeEdges]="{ right: true }"></div>
                                {{ tempColobj[item].DESCRIPTION }}
                            </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}" [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                    <span class="show_text" *ngIf="row[item]!==undefined && item !='GENERATETIME'">
                                        <mat-icon class="main-icon_inner"
                                            *ngIf="tempColobj[item].DESCRIPTION == 'Document Name' &&  (row.type == 'docx' || row.type == 'doc' || row.type == 'dotx' || row.type == 'dot' || row.type == 'docm' || row.type == 'dotm ')">
                                            <img src="assets/icons/web_app/EXP_RTF.ico" matTooltip=""
                                                matTooltipPosition="above">
                                        </mat-icon>
                                        <mat-icon class="main-icon_inner"
                                            *ngIf="tempColobj[item].DESCRIPTION == 'Document Name' &&  (row.type == 'pdf')">
                                            <img src="assets/icons/web_app/EXP_PDF.ico" matTooltip=""
                                                matTooltipPosition="above">
                                        </mat-icon>
                                        <mat-icon class="main-icon_inner"
                                            *ngIf="tempColobj[item].DESCRIPTION == 'Document Name' &&  (row.type == 'xslx' || row.type == 'csv')">
                                            <img src="assets/icons/web_app/EXP_XLS.ico" matTooltip=""
                                                matTooltipPosition="above">
                                        </mat-icon>
                                        <mat-icon class="main-icon_inner"
                                            *ngIf="tempColobj[item].DESCRIPTION == 'Document Name' &&  (row.type == 'eml')">
                                            <img src="assets/icons/web_app/eml.png" matTooltip=""
                                                matTooltipPosition="above">
                                        </mat-icon>
                                        {{row[item] | toFixedAmount:tempColobj[item].CURRENCY |
                                        wordwrap:tempColobj[item].WIDEDATA}}
                                    </span>
                                    <span class="show_text" *ngIf="row[item]!==undefined && item == 'GENERATETIME'">
                                        {{row[item] | convet24To12:false}}
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;let element;" class="row" (click)="RowClick(row);highlightedRows=row.DOCUMENTGUID" [style.background]="highlightedRows == row.DOCUMENTGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.DOCUMENTGUID ? 'row-text-colore' : ''"
                            (dblclick)="OpenMatter('DOCUMENT','safecustody')"  matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                            <ng-container matColumnDef="groupHeader">
                                <th class="special-group-header" colspan="999" *matCellDef="let group">
                                    <mat-icon>
                                        <img *ngIf="group.foldertype=='folder' " src="assets/icons/web_app/folder.ico ">
                                        <img *ngIf="group.foldertype=='Go_Back' " src="assets/icons/web_app/folderup.ico ">
                                    </mat-icon>
                                    <strong>{{group.FOLDERNAME}}</strong>
                                </th>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: [ 'groupHeader']; when:isGroup" (dblclick)='doubleClick(row)' [style.background]="highlightedRows==row.FOLDERNAME ? selectedColore : '' " [ngClass]="highlightedRows==row.FOLDERNAME
                                        ? 'row-text-colore' : '' " highlightedRows=row.FOLDERNAME (click)="highlightedRows=row.FOLDERNAME ">
                            </mat-row>
                        </mat-table> -->
                    </div>
                    <!-- <mat-paginator [pageSizeOptions]="[10, 20,50,100] " [pageSize]="pageSize?.document_register " (page)="onPaginateChange($event) " showFirstLastButtons></mat-paginator> -->
                </div>

                <!-- 'has-orange-colore':row.INVOICEGUID == -1 ,'has-light-blue-colore':(row.INVOICEGUID && row.INVOICEGUID != -1) -->

                <div class="upload_doscument_main " *ngIf="fileList.length>0 && isUplodedFileListShow">
                    <div class="upload_doc_header">
                        <div class="left_title_div">
                            <span>{{fileList.length}} upload complete</span>
                        </div>
                        <div class="right_btn_div">
                            <button type="button" (click)="isClose = !isClose" mat-button class="upload_action_btn">
                                <mat-icon *ngIf="isClose">expand_more</mat-icon>
                                <mat-icon *ngIf="!isClose">expand_less</mat-icon>
                            </button>
                            <button type="button" (click)="closePopup();" mat-button class="upload_action_btn">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="upload_doscument_main_body" *ngIf="isClose">
                        <div class="upload_doc_body" *ngFor="let file of fileList">
                            <div class="left_doc_name">
                                <!-- <img src="assets/icons/web_app/img_icon.png"> -->
                                <span fileList>{{file.name}}</span>
                            </div>
                            <div class="right_icon_div">
                                <button type="button" mat-button class="upload_action_btn">
                                    <mat-icon>check_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <fuse-sidebar *ngIf='IsRegisterDocumentList' [ngStyle]="{'height': detailHeight,'margin-right':'10px'}" id="list_sidebar_right_child_detail" class="sidebar final-sidebar  list_sidebar_right_child document-sidebar" [ngClass]="SidePannelFlag ? 'defaultsmall' : 'default'" name="final-sidebar" position="right"
            lockedOpen="gt-md">
            <app-side-panel class="list_sidebar_padding"></app-side-panel>
        </fuse-sidebar>
    </div>
</div>