import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    AfterViewInit,
    ElementRef,
    OnDestroy,
} from "@angular/core";
import { fuseAnimations } from "src/@fuse/animations";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
import { DatePipe } from "@angular/common";
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
import * as $ from "jquery";
// import { BankingDialogComponent } from 'src/app/main/pages/banking/banking-dialog.component';
import { TrustMoneyReciptComponent } from "../trust-money-recipt/trust-money-recipt.component";
import { CurrencyPipe } from "@angular/common";
// import { GenerateDocumentPopupComponent } from 'src/app/main/pages/template/generate-document-popup/generate-document-popup.component';
import { MatSort } from "@angular/material/sort";
import { ConfirmPopupComponent } from "src/app/main/confirm-popup/confirm-popup.component";
import { environment } from "src/environments/environment";
import { SelectionModel } from "@angular/cdk/collections";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, forkJoin, iif, mergeMap, of, switchMap, tap } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { CSVPopupComponent } from "src/app/main/csv-popup/csv-popup.component";
import readXlsxFile from "read-excel-file";
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { GenerateDocumentPopupComponent } from "@pages/template/generate-document-popup/generate-document-popup.component";
import { ContactSelectDialogComponent } from "@pages/contact/contact-select-dialog/contact-select-dialog.component";
import { BankingDialogComponent } from "@pages/banking/banking-dialog.component";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { Router } from "@angular/router";
@Component({
    selector: "app-trust-money.dialoge",
    templateUrl: "./trust-money-dialoge.component.html",
    styleUrls: ["./trust-money-dialoge.component.scss"],
    animations: fuseAnimations,
})
export class TrustMoneyDialogeComponent implements OnInit, AfterViewInit, OnDestroy {
    highlightedRows: any;
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    displayedColumns: string[] = [
        "checked",
        "INVOICECODE",
        "INVOICEDATE",
        "AMOUNTOUTSTANDINGINCGST",
        "TRUSTBALANCE",
        "AMOUNTAPPLIED",
        "CLIENTNAME",
        "SHORTNAME",
        "PRIMARYFEEEARNERNAME",
    ];
    getDataForTable: any = [];
    currentuser = JSON.parse(localStorage.getItem("currentUser"));
    barristerTitle: any =
        this.currentuser.PRODUCTTYPE == "Solicitor"
            ? "Matter Num"
            : "Short Name";
    TRUSTTRANSACTIONITEMS: any = [];
    MatterAmmountArray: any = [];
    dataSource: any = [];
    errorWarningData: any = { Error: [], Warning: [] };
    errorWarningDataArray: any = { Error: [], Warning: [] };
    isLoadingResults: boolean = false;
    TrustMoneyData = { PaymentType: "Cheque", CheckBox: false };
    addData: any = [];
    isspiner: boolean = false;
    PymentType: string = "Cheque";
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    confirmDialogRef1: any;
    matterType: boolean = false;
    action: any;
    isMatterselected: boolean = false;
    isDisableTab: boolean = false;
    isTrustTrans: boolean = false;
    TRUSTTRANSACTIONSDATA: any;
    EFTDEFAULTVALUES: any;
    title: string;
    reportTitle: string;
    MaxValue: number;
    ForDetailTab: string;
    TrustMoneyForm: FormGroup;
    INCOMEGUID: any;
    INDEX: any;
    SendDate: string;
    sendToAPI: string;
    PDFURL: any;
    base_url: string;
    forPDF: any;
    sendTransectionSubType: string;
    TranClassName: any;
    titletext: any;
    Paidamount = 0;
    Invoicedata: any = [];
    priceTemp: any;
    data: any;
    TrustMoneyList: any = null;
    multiMatterGuid: any = null;
    matterData: any;
    filterVals = { SHOWGENERALJOURNAL: false };
    public saveUsername: boolean;
    isdisable: boolean;
    IsReverse: boolean = false;
    isReverse: string;
    GetSetMixRetry: boolean = true;
    ImgDisAb: string;
    matterNumber: any = "";
    flaxValForRe: number = 50;
    windowNameId: any;

    DataGetAccount: any = [];
    searchMatter: any;
    exportdatavalue: any;
    searchData: any;
    matterlist: Observable<string[]>;
    mattername: any = [];
    selectedData: any = [];
    balance: any;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    selectedMatterGUID: any;
    @ViewChild("MatterInput1", { static: false })
    MatterInput: ElementRef<HTMLInputElement>;

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    selection = new SelectionModel<any>(true, []);
    ChartHandlingData: {
        ClickType: string;
        UseTrust: string;
        PopUp: string;
        Lable: string;
    };
    isDisabled: boolean = true;
    RequestGuid: string;
    getReverseTranData: any = [];
    balancedata: any;
    payorList: any = [];
    contactList: any;
    contactData: any = [];
    selContactName: string = "";
    @ViewChild("contactElem", { static: false })
    contactElemdata: ElementRef<HTMLInputElement>;
    csvContent: string;
    filters: any;
    finalarr: any = [];
    csvData: any;
    count: number = 0;
    lines = []; //for headings
    linesR = [];
    appPermissions = JSON.parse(localStorage.getItem("app_permissions"));
    CallHostlistner: boolean = false;
    DestryedContactdata: Subscription;
    AmountValue: any;
    selectFromIcon: boolean = false;
    sub: Subscription;
    sub1: Subscription;
    AmountColor: any;
    private searchTerms = new Subject<string>();
    subscription: Subscription;
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        public MatDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public dialogRef: MatDialogRef<TrustMoneyReciptComponent>,
        private dialog: MatDialog,
        public _matDialog: MatDialog,
        private datepipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        private globalFunService: GlobalFunctionsService,
        private toolbarServiceService: ToolbarServiceService,
        private router: Router
    ) {
        localStorage.setItem("istrackid", "TrustMoneyDialogeComponent");
        this.action = _data.action;
        this.isMatterselected = _data.isMatter ? _data.isMatter : false;
        this.selectedMatterGUID = _data.MATTERGUID;
        // this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        this.behaviorService.TrustMoneyList$.subscribe((result) => {
            if (result) {
               this.TrustMoneyList = result;
               // this.matterData = this.TrustMoneyList
            }
        });
          /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */

       // if (!this.TrustMoneyList) {
            this.behaviorService.clickMaterFromTimeLine$
                .pipe(
                    tap(result => {
                        const materIDofTab = window.name.split("_");
                        const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                        if (result && this.router.url != '/trust-money/full-trust-money') {
                            this.matterData = result;
                            this.mattername = [this.matterData]


                        } else if (this.router.url != '/trust-money/full-trust-money'){
                            this.matterData = JSON.parse(localStorage.getItem(windowNameId || 'set_active_matters')) || result;
                            localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.matterData));

                        }

                    })
                )
                .subscribe();
        //}

        if (this.isMatterselected) {
            this.behaviorService.MatterData$.subscribe((result) => {
                if (result) {
                    this.matterData = result;
                    if (this._data && this._data.MATTERGUID) {
                        // this.matterData.MATTERGUID = this._data.MATTERGUID;
                    }
                }
            });
            // this.behaviorService.MatterData$.subscribe(result => {
            //        const materIDofTab = window.name.split("_");
            // this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
            //   this.matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
            //   if (this.matterData) {
            //   } else {
            //     if (result) {
            //       this.matterData = result;
            //       localStorage.setItem(this.windowNameId,JSON.stringify(this.matterData))
            //     }
            //   }
            // });

        }
        this.TrustMoneyForm = this._formBuilder.group({
            TRUSTTRANSACTIONGUID: [""],
            FROMMATTER: [""],
            TrustAccount: [""],
            FROMMATTERGUID: [""],
            TRANSACTIONDATE: [""],
            AMOUNT: [0, { updateOn: 'blur' }],
            PAYMENTTYPE: [""],
            PaymentType: [""],
            BANKCHEQUE: [""],
            BENEFICIARY: [""],
            AUTHORISEDBY: [""],
            PREPAREDBY: [""],
            TOMATTERGUID: [""],
            //item
            SHORTNAME: [""],
            MATTERGUID: [""],
            PURPOSE: [""],
            //cheque details
            CHEQUENO: [""],
            ACCOUNTNAME: [""],
            ACCOUNTNUMBER: [""],
            BSB: [""],
            EFTREFERENCE: [""],
            // Bpay
            BPAYBILLERCODE: [""],
            BPAYREFERENCE: [""],

            // International EFT

            FOREIGNEFTSWIFTCODE: [""],
            FOREIGNEFTIBAN: [""],
            FOREIGNEFTBANKNAME: [""],
            FOREIGNEFTCOUNTRY: [""],
            FOREIGNEFTCURRENCY: [""],
            FOREIGNEFTAMOUNT: [""],
            FOREIGNEFTCONVERSIONDETAILS: [""],

            // cash
            COINS: [0],
            FIVEDOLLARS: [0],
            TENDOLLARS: [0],
            TWENTYDOLLARS: [0],
            FIFTYDOLLARS: [0],
            HUNDREDDOLLARS: [0],
            TOTALDOLLARS: [0],
            // address
            PAYOR: [""],
            ADDRESS1: [""],
            POSTCODE: [""],
            STATE_: [""],
            SUBURB: [""],
            MatterAMOUNT: [""],
            // extra
            BankChequeChkBox: [""],
            CheckBox: [""],
            INVOICEDVALUEEXGST: [""],
            Unallocated: [""],
            Total: [""],
            Ledger: [""],
            BANKACCOUNTGUID: [""],
            BANKACCOUNTGUIDTEXT: [""],
            //OVER Amount
            OVERAMOUNT: [""],
            ROWCHECK: [""],
            multiTableSHORTNAME: [""],
        });
        this.TranClassName = "";
        this.sendTransectionSubType = "Normal";
        // this.TrustMoneyForm.controls['PREPAREDBY'].setValue('Claudine Parkinson (pwd=test)');
        // this.TrustMoneyForm.controls['PAYMENTTYPE'].setValue('Cheque');
        this.TrustMoneyForm.controls["CheckBox"].setValue(false);
        this.TrustMoneyForm.controls["ROWCHECK"].setValue(false);
        this.isdisable = false;
        this.isReverse = "No";
        this.TrustMoneyForm.controls["TRANSACTIONDATE"].setValue(new Date());
        this.SendDate = this.datepipe.transform(new Date(), "dd/MM/yyyy");
        this.flaxValForRe = 50;
        if (this.action == "Reverse") {
            this.IsReverse = true;
            this.isdisable = true;
            this.TrustMoneyForm.controls["AMOUNT"].disable();
            this.defaultCallAPI(true);
            this.GetEditData();
        } else if (this.action == "receipt") {
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Trust Receipt";
            this.reportTitle = "Trust Receipt";
            this.TranClassName = "Trust Money";
            this.sendToAPI = "Receipt";
            this.titletext = "Received From";
            this.defaultCallAPI(false);
        } else if (this.action == "withdrawal") {
            this.flaxValForRe = 33;
            this.TranClassName = "Trust Money";
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Trust withdrawal";
            this.reportTitle = "Trust withdrawal";
            this.sendToAPI = "Withdrawal";
            this.titletext = "Paid To";
            this.defaultCallAPI(false);
        } else if (this.action == "Transfer") {
            this.TranClassName = "Trust Money";
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Trust Transfer";
            this.reportTitle = "Trust Transfer";
            this.sendToAPI = "Transfer";
            this.TrustMoneyForm.controls["PAYMENTTYPE"].setValue("Transfer");
            this.TrustMoneyData.PaymentType = "Transfer";
            this.PymentType = "Transfer";
            this.defaultCallAPI(false);
        } else if (this.action == "office") {
            this.isLoadingResults = true;
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Trust to Office withdrawal";
            this.reportTitle = "Trust to Office withdrawal";
            this.matterType = true;
            this.TranClassName = "Trust Money";
            this.sendTransectionSubType = "Trust to office";
            this.sendToAPI = "Withdrawal";
            this.TrustMoneyForm.controls["CheckBox"].setValue(true);
            this.TrustMoneyForm.controls["PURPOSE"].setValue("Trust to office");
            this.TrustMoneyData.CheckBox = true;
            this.isTrustTrans = false;
            this.titletext = "Paid To";
            this.defaultCallAPI(false);
        } else if (this.action == "money receipt") {
            // $("#Contcat_id").removeClass("menu-disabled");
            this.TranClassName = "Controlled Money";
            this.sendToAPI = "Receipt";
            this.sendTransectionSubType = "Normal";
            this.titletext = "Received From";
            this.title = "Add Controlled Money Receipt";
            this.reportTitle = "Controlled Money Receipt";
            this.defaultCallAPI(false);
        } else if (this.action == "money withdrawal") {
            // $("#Contcat_id").removeClass("menu-disabled");
            this.TranClassName = "Controlled Money";
            this.sendTransectionSubType = "Normal";
            this.sendToAPI = "Withdrawal";
            this.titletext = "Paid To";
            this.title = "Add Controlled Money withdrawal";
            this.reportTitle = "Controlled Money withdrawal";
            this.defaultCallAPI(false);
        } else if (this.action == "Cancelled Cheque") {
            this.ImgDisAb = "menu-disabled";
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Cancelled Cheque";
            this.reportTitle = "Cancelled Cheque";
            this.sendToAPI = "Receipt";
            this.TranClassName = "Trust Money";
            this.sendTransectionSubType = "Cancelled Cheque";
            // $("#Contcat_id").addClass("menu-disabled");
            this.TrustMoneyForm.controls["Ledger"].setValue(
                "Nill Values Ledger : $0.00"
            );
            this.TrustMoneyForm.controls["PURPOSE"].setValue(
                "Cancelled Cheque"
            );
            this.TrustMoneyForm.controls["PAYMENTTYPE"].setValue("Cheque");
            this.TrustMoneyForm.controls["AMOUNT"].setValue(0.0);
            this.TrustMoneyForm.controls["PAYMENTTYPE"].disable();
            this.TrustMoneyForm.controls["PAYOR"].disable();
            this.TrustMoneyForm.controls["AMOUNT"].disable();
            this.TrustMoneyForm.controls["TRANSACTIONDATE"].disable();
            this.titletext = "Paid To";
            this.defaultCallAPI(false);
        } else if (this.action == "Unknown Deposit") {
            this.titletext = "Received From";
            this.isLoadingResults = true;
            this.TranClassName = "Trust Money";
            // $("#Contcat_id").removeClass("menu-disabled");
            this.PymentType = "EFT";
            this.sendToAPI = "Receipt";
            this.title = "Add Unknown Deposit Receipt";
            this.reportTitle = "Unknown Deposit Receipt";
            this.sendTransectionSubType = "Unknown Deposit";
            // this.TrustMoneyForm.controls['PAYMENTTYPE'].setValue('EFT');
            this.TrustMoneyForm.controls["PURPOSE"].setValue("Unknown Deposit");
            this.defaultCallAPI(false);
        } else if (this.action == "Transfer Unknown Deposit") {
            // $("#Contcat_id").removeClass("menu-disabled");
            this.TranClassName = "Transfer";
            this.title = "Add Unknown Deposit Transfer";
            this.reportTitle = "Unknown Deposit Transfer";
            this.sendToAPI = "Transfer";
            this.sendTransectionSubType = "Unknown Deposit";
            this.TrustMoneyForm.controls["PAYMENTTYPE"].disable();
            this.TrustMoneyForm.controls["PAYMENTTYPE"].setValue("Transfer");
            this.TrustMoneyData.PaymentType = "Transfer";
            this.PymentType = "Transfer";
            this.defaultCallAPI(false);
        } else if (this.action == "Statutory Deposit") {
            this.isLoadingResults = true;
            this.TranClassName = "Trust Money";
            this.sendToAPI = "Withdrawal";
            this.TrustMoneyForm.controls["PURPOSE"].setValue(
                "Statutory Deposit"
            );
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Statutory Deposit";
            this.reportTitle = "Statutory Deposit";
            this.ForDetailTab = "Statutory Deposit";
            this.sendTransectionSubType = "Statutory Deposit";
            this.titletext = "Paid To";
            this.defaultCallAPI(false);
            // this.action = "withdrawal";
        } else if (this.action == "Statutory Receipt") {
            this.titletext = "Received From";
            this.isLoadingResults = true;
            this.TranClassName = "Trust Money";
            this.sendToAPI = "Receipt";
            this.TrustMoneyForm.controls["PURPOSE"].setValue(
                "Statutory Deposit"
            );
            this.sendTransectionSubType = "Statutory Deposit";
            this.defaultCallAPI(false);
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Statutory Receipt";
            this.reportTitle = "Statutory Deposit";
            this.ForDetailTab = "Statutory Receipt";
            // this.action = "receipt";
        } else if (this.action == "Release Trust") {
            // $("#Contcat_id").removeClass("menu-disabled");
            this.title = "Add Release Trust";
            this.reportTitle = "Release Trust";
        } else {
            this.sendToAPI = "Select Banking";
        }
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        this.TrustMoneyForm.controls["PREPAREDBY"].setValue(
            currentUser.UserName
        );
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (
                localStorage.getItem("istrackid") ==
                    "TrustMoneyDialogeComponent" &&
                this.GetSetMixRetry &&
                result["click"] &&
                result["data"]["SetTrustTransaction"]
            ) {
                this.defaultCallAPI(false);
            }
            if (
                localStorage.getItem("istrackid") ==
                    "TrustMoneyDialogeComponent" &&
                result["click"] &&
                result["data"]["trust-transaction"]
            ) {
                this.GetEditData();
            }
            if (
                localStorage.getItem("istrackid") ==
                    "TrustMoneyDialogeComponent" &&
                this.GetSetMixRetry == false &&
                result["click"] &&
                result["data"]["SetTrustTransaction"]
            ) {
                this.SaveTrustMoney();
            }
        });
        this.sub = this.TrustMoneyForm.valueChanges.subscribe((form: any) => {
            const newFormControl = { ...form };
            if (newFormControl.STATE_) {
                newFormControl["STATE"] = newFormControl.STATE_;
            }
            this.changeForControl(newFormControl);
        });
    }

    /**
     * Used to chenge theform control value
     * @param formControl -get the form control value
     */
    changeForControl(formControl):void {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = key.toLocaleUpperCase();

            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj };
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }
        //Warning
        const dubObj2 = { ...this.errorWarningDataArray.Warning };
        let element2 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj2)) {
            const KEY = key.toLocaleUpperCase();

            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element2[key];
                dubObj2[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj2 };
            } else if (!formControl[KEY]) {
                element2[key] = dubObj2[key];
            }
        }
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.TrustMoneyForm.controls["BANKACCOUNTGUID"].setValue("");
        setTimeout(() => {
            this.TrustMoneyForm.controls["OVERAMOUNT"].setValue("0.00");
        }, 500);
        this.getContactDataUsingBehaviour();
        if (this.router.url != '/trust-money/full-trust-money') {
            this.getAccount(this.matterData.MATTERGUID, this.matterData.SHORTNAME);
        }
        /**
         * Used to Reduced the more API calls.
         */

        this.sub1 = this.TrustMoneyForm.controls.PAYOR.valueChanges
        .pipe(distinctUntilChanged(),
         debounceTime(570),
         switchMap((dataVal:string)=>
         forkJoin([of(dataVal),this.fastSearchContact(dataVal)])),
         mergeMap(([res,response])=>
            iif(()=> (response.STATUS == "success" && response.DATA.RECORDS.length === 0), of([{ CONTACTNAME: res }]),of(response.DATA.RECORDS))
         )).subscribe(responseData=>{
            this.payorList=responseData;
         })

    //      this.TrustMoneyForm.controls.PAYOR.valueChanges
    //      .pipe(distinctUntilChanged(), debounceTime(500))
    //      .subscribe((payorData: string) => {
    //          this.fastSearchContact(payorData);
    //  })
        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
        ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA;
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
        });
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (document) {

                const clas = document
                    .getElementsByClassName("trust-money-tabgroup")[0]
                    .getElementsByClassName("mat-tab-label");
                for (let index = 0; index < clas.length; index++) {
                    const element: any = clas[index];
                    element.id = `mat-tab-id-${index}`;
                }
            }
        }, 3000);
    }
    MoneyReceipt() {}

    /**
     * Used to get the edit trust-money data.
     */
    GetEditData() {
        this.isDisableTab = true;
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(
                {
                    Action: "GetData",
                    Filters: {
                        TRUSTTRANSACTIONGUID:
                            this.TrustMoneyList.TRUSTTRANSACTIONGUID,
                    },
                },
                "trust-transaction"
            )
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.isTrustTrans = true;

                        this.isReverse = "Yes";
                        let getTranData = res.DATA.RECORDS[0];
                        this.PymentType = getTranData.PAYMENTTYPE;
                        this.TrustMoneyForm.patchValue({
                            PAYMENTTYPE: getTranData.PAYMENTTYPE,
                        });
                        this.getReverseTranData = res.DATA.RECORDS[0]
                            ? res.DATA.RECORDS[0].TRUSTTRANSACTIONITEMS[0]
                            : [];

                        if (
                            this.action == "Reverse" &&
                            this.getReverseTranData &&
                            this.getReverseTranData.length !== 0
                        ) {
                            this.TrustMoneyForm.controls["MATTERGUID"].setValue(
                                this.getReverseTranData.MATTERGUID
                            );
                            this.getReverseTranData.OPENINGBALANCE =
                                this.getReverseTranData.LEDGERBALANCE;
                            this.mattername = [this.getReverseTranData];
                        }

                        this.matterType =
                            getTranData.MULTIITEM == 0 ? false : true;
                        this.TrustMoneyForm.controls[
                            "TRUSTTRANSACTIONGUID"
                        ].setValue(getTranData.TRUSTTRANSACTIONGUID);
                        if (
                            getTranData.TRANSACTIONCLASS == "Controlled Money"
                        ) {
                            if (getTranData.CASHBOOK == "Withdrawal") {
                                this.action = "money withdrawal";
                                this.TranClassName = "Controlled Money";
                                this.sendTransectionSubType = "Normal";
                                this.sendToAPI = "Withdrawal";
                                this.title =
                                    "Reverse Controlled Money Withdrawal";
                                    this.showDatatEdit(getTranData);
                                    // this.TrustMoneyForm.controls['PURPOSE'].setValue('Reverse Controlled Money Withdrawal');
                            } else if (getTranData.CASHBOOK == "Receipt") {
                                this.action = "money receipt";
                                this.sendToAPI = "Receipt";
                                this.TranClassName = "Controlled Money";
                                this.sendTransectionSubType = "Normal";
                                this.title = "Reverse Controlled Money Receipt";
                                this.showDatatEdit(getTranData);
                                // this.TrustMoneyForm.controls['PURPOSE'].setValue('Reverse Controlled Money Receipt');
                            }
                        } else if (
                            getTranData.TRANSACTIONCLASS == "Trust Money"
                        ) {
                            if (getTranData.CASHBOOK == "Receipt") {
                                this.action = "receipt";
                                this.title = "Reverse Trust Receipt";
                                this.TranClassName = "Trust Money";
                                this.sendToAPI = "Receipt";
                                this.titletext = "Received From";
                                // this.TrustMoneyForm.controls['PURPOSE'].setValue('Receipt');
                            } else if (getTranData.CASHBOOK == "Withdrawal") {
                                this.action = "withdrawal";
                                this.TranClassName = "Trust Money";
                                this.title = "Reverse Trust withdrawal";
                                this.sendToAPI = "Withdrawal";
                                this.titletext = "Paid To";
                                // this.TrustMoneyForm.controls['PURPOSE'].setValue('Withdrawal');
                            } else if (getTranData.CASHBOOK == "Transfer") {
                                this.action = "Transfer";
                                this.TranClassName = "Trust Money";
                                this.title = "Reverse Trust Transfer";
                                this.sendToAPI = "Transfer";
                                this.TrustMoneyForm.controls[
                                    "PAYMENTTYPE"
                                ].setValue("Transfer");
                                this.TrustMoneyData.PaymentType = "Transfer";
                                this.PymentType = "Transfer";
                                // this.TrustMoneyForm.controls['PURPOSE'].setValue('Transfer');
                            }
                            this.showDatatEdit(getTranData);
                        }
                        this.isLoadingResults = false;
                    } else if (
                        res.CODE == 406 &&
                        res.MESSAGE == "Permission denied"
                    ) {
                        this.isLoadingResults = false;
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * used to set the default data into the trust money form
     * @param data -data which we need to bind.
     */
    showDatatEdit(data):void {
        this.TrustMoneyForm.controls["CheckBox"].setValue(this.matterType);
        if (data.TRANSACTIONDATE) {
            let TRANSACTIONDATE1 = data.TRANSACTIONDATE.split("/");
            this.TrustMoneyForm.controls["TRANSACTIONDATE"].setValue(
                new Date(
                    TRANSACTIONDATE1[1] +
                        "/" +
                        TRANSACTIONDATE1[0] +
                        "/" +
                        TRANSACTIONDATE1[2]
                )
            );
            this.SendDate = data.TRANSACTIONDATE;
        }
        this.TrustMoneyForm.patchValue({
            AMOUNT: data.AMOUNT,
            PAYMENTTYPE: data.PAYMENTTYPE,
            BENEFICIARY: data.BENEFICIARY,
            ACCOUNTNAME: data.ACCOUNTNAME,
            CHEQUENO: data.CHEQUENO,
            ACCOUNTNUMBER: data.ACCOUNTNUMBER,
            BSB: data.BSB,
            EFTREFERENCE: data.EFTREFERENCE,
            COINS: data.COINS,
            FIVEDOLLARS: data.FIVEDOLLARS,
            TENDOLLARS: data.TENDOLLARS,
            TWENTYDOLLARS: data.TWENTYDOLLARS,
            FIFTYDOLLARS: data.FIFTYDOLLARS,
            HUNDREDDOLLARS: data.HUNDREDDOLLARS,
            ADDRESS1: data.ADDRESS1,
            SUBURB: data.SUBURB,
            STATE_: data.SUBURB,
            POSTCODE: data.POSTCODE,
            PAYOR: data.PAYOR,
            AUTHORISEDBY: data.AUTHORISEDBY,
            PREPAREDBY: data.PREPAREDBY,
        });
        data.TRUSTTRANSACTIONITEMS.forEach((element) => {
            if (element.ITEMTYPE == "From") {
                this.TrustMoneyForm.patchValue({
                    TrustAccount:
                        element.ACCOUNTNAME +
                        " : " +
                        this.ShowCurrencyFormate(element.LEDGERBALANCE),
                });
            } else if (element.ITEMTYPE == "To") {
                if (this.matterType) {
                    this.getDataForTable.push({
                        Matter:
                            element.MATTERDESCRIPTION +
                            " :" +
                            this.ShowCurrencyFormate(element.LEDGERBALANCE),
                        Ammount: element.AMOUNT,
                        Reason: element.PURPOSE,
                    });
                } else {
                    this.TrustMoneyForm.patchValue({
                        SHORTNAME:
                            element.MATTERDESCRIPTION +
                            " : " +
                            this.ShowCurrencyFormate(element.LEDGERBALANCE),
                    });
                }
            }
        });
    }

    /**
     * Used to call the default API.
     * @param isPaymentType -get the payment type
     */
    defaultCallAPI(isPaymentType: any):void {
        this.isLoadingResults = true;
        let data: any = {
            TRANSACTIONCLASS: this.TranClassName,
            TRANSACTIONTYPE: "Normal Item",
            TRANSACTIONSUBTYPE: this.sendTransectionSubType,
            CASHBOOK: this.sendToAPI,
        };
        if (isPaymentType) {
            data.PAYMENTTYPE = this.f.PAYMENTTYPE.value;
        }

        this._mainAPiServiceService
            .getSetData(
                { Data: data, FormAction: "default" },
                "SetTrustTransaction"
            )
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        // this.Paidamount = 0;
                        // this.TrustMoneyForm.controls["AMOUNT"].setValue(this.Paidamount);
                        this.TrustMoneyForm.controls["OVERAMOUNT"].setValue(0);
                        this.selection.clear();
                        this.Invoicedata = [];
                        this.setdefultVal(response, isPaymentType);
                        this.RequestGuid = response.DATA.REQUESTGUID;
                    } else if (
                        response.CODE == "432" &&
                        response.STATUS == "error"
                    ) {
                        // this.Paidamount = 0;
                        // this.TrustMoneyForm.controls["AMOUNT"].setValue(this.Paidamount);
                        this.TrustMoneyForm.controls["OVERAMOUNT"].setValue(0);
                        this.selection.clear();
                        this.Invoicedata = [];
                        this.setdefultVal(response, isPaymentType);
                        let currentUser: any = JSON.parse(
                            localStorage.getItem("currentUser")
                        );
                        // window.open(
                        //     environment.APIEndpoint +
                        //     "OAuth2Start?AuthType=NetDrive&SessionToken=" +
                        //     currentUser.SessionToken,
                        //     "targetWindow",
                        //     `toolbar=no,
                        //     location=no,
                        //     status=no,
                        //     menubar=no,
                        //     scrollbars=yes,
                        //     resizable=yes,
                        //     width=800,
                        //     height=600`
                        // );
                        let payload = {
                            "Action": "OAuth2Start",
                            "VALIDATEONLY": false,
                            "Data": {
                                "USERGUID": this.currentuser.UserGuid,
                                "AuthType": "NetDrive"
                            }
                        }
                        this._mainAPiServiceService
                        .getSetData(payload, 'oauth-connections')
                        .subscribe((response) => {
                            if(response.CODE == 200 && response.STATUS == "success") {
                                window.open(
                                    response.DATA[0].AuthorizationUrl,
                                    "targetWindow",
                                    `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=600`
                                );
                            }
                        });
                    } else {
                        this.toastr.error(response.MESSAGE);
                        this.isLoadingResults = false;
                    }
                },
                (error) => {
                    this.toastr.error(error);
                    this.isLoadingResults = false;
                }
            );
    }

    /**
     * Used to set the default value
     * @param response -response data
     * @param isPaymentType -get the updated payment type.
     */
    setdefultVal(response, isPaymentType):void {
        const defaultValue = (this.TRUSTTRANSACTIONSDATA =
            response.DATA.DEFAULTVALUES);
        this.EFTDEFAULTVALUES = response.DATA.EFTDEFAULTVALUES;
        if (!isPaymentType) {
            this.PymentType = defaultValue.PAYMENTTYPE;
        }
        let result = defaultValue.PAYORGROUP;
        if (this.action == "office" || this.action == "Unknown Deposit") {
            this.TrustMoneyForm.patchValue({
                Ledger: defaultValue.MATTER,
                PREPAREDBY: defaultValue.PREPAREDBY,
                MATTERGUID: defaultValue.MATTERLEDGERGUID,
                BANKACCOUNTGUID: defaultValue.BANKACCOUNTGUID,
                TrustAccount:
                    defaultValue.BANKACCOUNT +
                    "  " +
                    this.ShowCurrencyFormate(defaultValue.BANKACCOUNTBALANCE),
                CHEQUENO: defaultValue.BANKDETAILS.CHEQUENO,
                EFTREFERENCE: defaultValue.BANKDETAILS.EFTREFERENCE,
                PAYOR: result.PAYOR,
                ADDRESS1: result.ADDRESS1 + " " + result.ADDRESS2,
                SUBURB: result.SUBURB,
                STATE_: result.STATE,
                POSTCODE: result.POSTCODE,
            });
            this.contactData = [result.PAYOR];
        } else {
            this.TrustMoneyForm.patchValue({
                Ledger: defaultValue.MATTER,
                PREPAREDBY: defaultValue.PREPAREDBY,
                BANKACCOUNTGUID: defaultValue.BANKACCOUNTGUID,
                TrustAccount:
                    defaultValue.BANKACCOUNT +
                    "  " +
                    this.ShowCurrencyFormate(defaultValue.BANKACCOUNTBALANCE),
                CHEQUENO: defaultValue.BANKDETAILS.CHEQUENO,
                EFTREFERENCE: defaultValue.BANKDETAILS.EFTREFERENCE,
            });
        }
        if (this.isMatterselected) {
            this.multiMatterGuid = this.matterData.MATTERGUID;
            this.refreshMatterTrustblance(this.selectedMatterGUID);
        }
        if (!isPaymentType) {
            this.TrustMoneyForm.patchValue({
                PAYMENTTYPE: defaultValue.PAYMENTTYPE,
            });
        }
        if (this.PymentType == "EFT") {
            this.TrustMoneyForm.patchValue({
                ACCOUNTNAME: this.EFTDEFAULTVALUES.ACCOUNTNAME,
                BSB: this.EFTDEFAULTVALUES.BSB,
                ACCOUNTNUMBER: this.EFTDEFAULTVALUES.ACCOUNTNUMBER,
            });
        } else {
            this.TrustMoneyForm.patchValue({
                ACCOUNTNAME: defaultValue.BANKDETAILS.ACCOUNTNAME,
                BSB: defaultValue.BANKDETAILS.BSB,
                ACCOUNTNUMBER: defaultValue.BANKDETAILS.ACCOUNTNAME,
            });
        }
        if (defaultValue.INVOICES) {
            this.dataSource = new MatTableDataSource(defaultValue.INVOICES);
            this.dataSource.sort = this.sort;
            this.sortingDate();
        } else {
            this.dataSource = new MatTableDataSource([]);
            this.dataSource.sort = this.sort;
            this.sortingDate();
        }
        this.isLoadingResults = false;
        this.CheckdefultValidation(response.DATA.VALIDATIONS);
    }

    /**
     * USed to check the default validation
     * @param bodyData -default validation data.
     */
    CheckdefultValidation(bodyData: any):void {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: {
            VALUEVALID: string;
            ERRORDESCRIPTION: any;
            FIELDNAME: any;
        }) {
            if (value.VALUEVALID == "No") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { Error: tempError, Warning: tempWarning };
        this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
    }
    get f() {
        return this.TrustMoneyForm.controls;
    }

    /**
     * USed to change the payment type
     * @param val -updated payment type
     */
    PaymentTypeChange(val):void {
        let tempError: any = this.errorWarningData["Error"]
            ? this.errorWarningData["Error"]
            : [];
        let tempWarning: any = this.errorWarningData["Warning"]
            ? this.errorWarningData["Warning"]
            : [];
        if (val == "Cheque") {
            this.PymentType = "Cheque";
            tempWarning["CHEQUENO"] = {};
            tempError["BSB"] = {};
            tempError["ACCOUNTNUMBER"] = {};
            tempError["ACCOUNTNAME"] = {};
        } else if (val == "EFT") {
            this.PymentType = "EFT";
            tempWarning["CHEQUENO"] = {};
            tempError["BSB"] = {};
            tempError["ACCOUNTNUMBER"] = {};
            tempError["ACCOUNTNAME"] = {};
            if (this.EFTDEFAULTVALUES) {
                this.TrustMoneyForm.patchValue({
                    ACCOUNTNAME: this.EFTDEFAULTVALUES.ACCOUNTNAME,
                    BSB: this.EFTDEFAULTVALUES.BSB,
                    ACCOUNTNUMBER: this.EFTDEFAULTVALUES.ACCOUNTNUMBER,
                });
            }
        } else if (val == "Cash") {
            this.PymentType = "Cash";
        } else if (val == "Bank") {
            this.PymentType = "Bank";
        } else if (val == "BPay") {
            this.PymentType = val;
        } else if (val == "International EFT") {
            this.PymentType = val;
            this.TrustMoneyForm.patchValue({
                CHEQUENO: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.CHEQUENO,
                EFTREFERENCE: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.EFTREFERENCE,
                ACCOUNTNAME: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.ACCOUNTNAME,
                BSB: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.BSB,
                ACCOUNTNUMBER: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.ACCOUNTNUMBER,
                FOREIGNEFTSWIFTCODE: this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTSWIFTCODE,
                FOREIGNEFTIBAN: this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTIBAN,
                FOREIGNEFTBANKNAME: this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTBANKNAME,
                FOREIGNEFTCOUNTRY: this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTCOUNTRY,
                FOREIGNEFTCURRENCY: this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTCURRENCY
            });
        }
        if (
            val == "Cash" ||
            val == "Bank" ||
            val == "Cheque" ||
            val == "BPay"
        ) {
            this.TrustMoneyForm.patchValue({
                CHEQUENO: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.CHEQUENO,
                EFTREFERENCE: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.EFTREFERENCE,
                ACCOUNTNAME: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.ACCOUNTNAME,
                BSB: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.BSB,
                ACCOUNTNUMBER: this.TRUSTTRANSACTIONSDATA.BANKDETAILS.ACCOUNTNUMBER
            });
        }
        this.errorWarningData = { Error: tempError, Warning: tempWarning };
        this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
        this.defaultCallAPI(true);
    }

    /**
     * get the check box value data.
     * @param val -get the selected check box data.
     */
    CheckBoxClick(val):void {
        this.matterType = val;
    }

    /**
     * Used to get the selected matter data.
     * @param key -value which we search.
     */
    async SelectMatter(key):Promise<void> {
        this.searchData = [];
        this.matterlist = this.searchData;
        if (!this.IsReverse) {
            const dialogRef = this.dialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: { isTrustTrans: true },
            });
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    if (key == "from matter") {
                        this.isLoadingResults = true;
                        if (this.mattername.length !== 0) {
                            // this.balancedata = this.mattername[0].OPENINGBALANCE
                            //     ? this.mattername[0].OPENINGBALANCE
                            //     : "0";
                            this.balancedata = this.mattername[0].ACCOUNTBALANCE
                                ? this.mattername[0].ACCOUNTBALANCE
                                : "0";
                        }
                        this._mainAPiServiceService
                            .getSetData(
                                {
                                    MatterGuid: result.MATTERGUID,
                                    UseTrust: true,
                                    ACCOUNTCLASS: "Matter Ledger",
                                },
                                "GetAccount"
                            )
                            .subscribe(
                                (res) => {
                                    if (
                                        res.CODE == 200 &&
                                        res.STATUS == "success"
                                    ) {
                                        this.isLoadingResults = false;
                                        this.AmountValue =
                                            res.DATA.ACCOUNTS[0].ACCOUNTBALANCE;
                                        this.TrustMoneyForm.controls[
                                            "FROMMATTER"
                                        ].setValue(
                                            result.CONTACTNAME +
                                                ": " +
                                                result.MATTER +
                                                " Trust Ledger" +
                                                ": " +
                                                "$" +
                                                this.AmountValue
                                        );
                                    }
                                },
                                (err) => {
                                    this.toastr.error(err);
                                    this.isLoadingResults = false;
                                }
                            );

                        //Due to new changes commit this line.
                        // this.TrustMoneyForm.controls['FROMMATTER'].setValue(result.CONTACTNAME + ': ' + result.MATTER + ' Trust Ledger' + ': ' + this.ShowCurrencyFormate(this.balancedata));
                        // this.TrustMoneyForm.controls['FROMMATTER'].setValue(result.MATTER);
                        this.TrustMoneyForm.controls["FROMMATTERGUID"].setValue(
                            result.MATTERGUID
                        );
                    } else if (key == "multi matter") {
                        this.multiMatterGuid = result.MATTERGUID;
                        this.matterNumber = result.SHORTNAME;
                        await this.getAccount(
                            result.MATTERGUID,
                            result.SHORTNAME
                        );
                        // let balance = this.mattername[0].OPENINGBALANCE ? this.mattername[0].OPENINGBALANCE : 0;
                        this.TrustMoneyForm.controls["SHORTNAME"].setValue(
                            result.SHORTNAME
                        );
                        // if (this.mattername.OPENINGBALANCE) {
                        //     this.balance = this.mattername.OPENINGBALANCE
                        //         ? this.mattername.OPENINGBALANCE
                        //         : 0;
                        // } else {
                        //     if (this.mattername[0].OPENINGBALANCE) {
                        //         this.balance = this.mattername[0].OPENINGBALANCE
                        //             ? this.mattername[0].OPENINGBALANCE
                        //             : 0;
                        //     }
                        // }
                        if (this.mattername.ACCOUNTBALANCE) {
                            this.balance = this.mattername.ACCOUNTBALANCE
                                ? this.mattername.ACCOUNTBALANCE
                                : 0;
                        } else {
                            if (this.mattername[0].ACCOUNTBALANCE) {
                                this.balance = this.mattername[0].ACCOUNTBALANCE
                                    ? this.mattername[0].ACCOUNTBALANCE
                                    : 0;
                            }
                        }
                        this.TrustMoneyForm.controls[
                            "multiTableSHORTNAME"
                        ].setValue(
                            result.CONTACTNAME +
                                ": " +
                                result.MATTER +
                                " Trust Ledger" +
                                ": " +
                                this.ShowCurrencyFormate(this.balance)
                        );
                        this.TrustMoneyForm.controls["MATTERGUID"].setValue(
                            result.MATTERGUID
                        );
                        this.TrustMoneyForm.controls[
                            "INVOICEDVALUEEXGST"
                        ].setValue(result.INVOICEDVALUEEXGST);
                        //  this.mattername = [result.SELECTEDDESCRIPTION]
                    } else {
                        await this.getAccount(
                            result.MATTERGUID,
                            result.SHORTNAME
                        );
                        this.matterData = result;
                        this.multiMatterGuid = result.MATTERGUID;
                        // if (this.mattername.OPENINGBALANCE) {
                        //     this.balance = this.mattername.OPENINGBALANCE
                        //         ? this.mattername.OPENINGBALANCE
                        //         : 0;
                        // } else {
                        //     if (this.mattername[0].OPENINGBALANCE) {
                        //         this.balance = this.mattername[0].OPENINGBALANCE
                        //             ? this.mattername[0].OPENINGBALANCE
                        //             : 0;
                        //     }
                        // }
                        if (this.mattername.ACCOUNTBALANCE) {
                            this.balance = this.mattername.ACCOUNTBALANCE
                                ? this.mattername.ACCOUNTBALANCE
                                : 0;
                        } else {
                            if (this.mattername[0].ACCOUNTBALANCE) {
                                this.balance = this.mattername[0].ACCOUNTBALANCE
                                    ? this.mattername[0].ACCOUNTBALANCE
                                    : 0;
                            }
                        }
                        this.TrustMoneyForm.controls[
                            "multiTableSHORTNAME"
                        ].setValue(
                            result.CONTACTNAME +
                                ": " +
                                result.MATTER +
                                " Trust Ledger" +
                                ": " +
                                this.ShowCurrencyFormate(this.balance)
                        );
                        this.TrustMoneyForm.controls["SHORTNAME"].setValue(
                            result.CONTACTNAME +
                                ": " +
                                result.MATTER +
                                " Trust Ledger" +
                                ": " +
                                this.ShowCurrencyFormate(this.balance)
                        );
                        this.TrustMoneyForm.controls["MATTERGUID"].setValue(
                            result.MATTERGUID
                        );
                        this.TrustMoneyForm.controls[
                            "INVOICEDVALUEEXGST"
                        ].setValue(result.INVOICEDVALUEEXGST);
                        // this.mattername = [result.SHORTNAME + '--' + result.MATTER]
                    }
                }
            });
        }
    }

    /**
     * Used to open the Matter list popup
     */
    public selectMatter_1():void {
        this.searchData = [];
        this.matterlist = this.searchData;

        const dialogRef = this.MatDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                localStorage.setItem("istrackid", "SpandMoneyDialog");
                this.TrustMoneyForm.controls["SHORTNAME"].setValue(
                    result.SHORTNAME
                );
                this.TrustMoneyForm.controls["MATTERGUID"].setValue(
                    result.MATTERGUID
                );
                // this.mattername = [result.SHORTNAME + '--' + result.MATTER]
            }
        });
    }

    /**
     * Used to get the check box updated value.
     * @param value -Check box updated value.
     * @param row -selected check box row data.
     */
    changeValueOfCheckbox(value, row):void {
        row["checkbox"] = value.checked;
        this.CommonTrust(row);
    }

    /**
     * Used to pass the search data to the API.
     * @param searchValue -Searching string value
     */
    onSearchChange(searchValue: any):void{
        this.Paidamount =
            Number(this.Paidamount) - Number(this.data.AMOUNTAPPLIED);
        this.data.AMOUNTAPPLIED = searchValue;
        this.Paidamount =
            Number(this.Paidamount) + Number(this.data.AMOUNTAPPLIED);
        this.TrustMoneyForm.controls["AMOUNT"].setValue(this.Paidamount);
        this.invouiceData();
    }

    /**
     * Used to click on the row
     * @param row -get the selected row value
     */
    Rowclick(row):void {
        this.isDisabled = row && row.checkbox ? false : true;
        if (row.checkbox === true) {
            this.data = row;
            this.TrustMoneyForm.controls["OVERAMOUNT"].setValue(
                row.AMOUNTAPPLIED
            );
        } else {
            this.TrustMoneyForm.controls["OVERAMOUNT"].setValue("0.00");
        }
    }

    /**
     * get the comman trust data
     * @param row -comman data
     */
    CommonTrust(row):void {
        this.isDisabled = row && row.checkbox ? false : true;
        if (row.checkbox === true) {
            this.data = row;
            if (
                Number(row.AMOUNTOUTSTANDINGINCGST) < Number(row.TRUSTBALANCE)
            ) {
                row.AMOUNTAPPLIED = row.AMOUNTOUTSTANDINGINCGST;
                this.Paidamount += Number(row.AMOUNTOUTSTANDINGINCGST);
            } else {
                row.AMOUNTAPPLIED = row.TRUSTBALANCE;
                this.Paidamount += Number(row.TRUSTBALANCE);
            }
            this.TrustMoneyForm.controls["AMOUNT"].setValue(this.Paidamount);
            this.priceTemp = row.AMOUNTAPPLIED;
            this.TrustMoneyForm.controls["OVERAMOUNT"].setValue(this.priceTemp);
            // this.Invoicedata.push({
            //   INVOICEGUID: row.INVOICEGUID,
            //   AMOUNTAPPLIED: row.AMOUNTAPPLIED
            // });
        } else {
            let te = row.AMOUNTAPPLIED;
            row.AMOUNTAPPLIED = 0;
            this.Paidamount = Number(this.Paidamount) - Number(te);
            this.TrustMoneyForm.controls["AMOUNT"].setValue(this.Paidamount);
            this.TrustMoneyForm.controls["OVERAMOUNT"].setValue("0.00");
            // let index=this.Invoicedata.findIndex(arrayRow => arrayRow.INVOICEGUID ===row.INVOICEGUID);
            // this.Invoicedata.splice(index,1);
        }
        this.invouiceData();
    }

    /**
     * This function is for getting the invoice data.
     */
    invouiceData():void{
        this.Invoicedata = [];
        this.selection.selected.forEach((element) => {
            this.Invoicedata.push({
                INVOICEGUID: element.INVOICEGUID,
                AMOUNTAPPLIED: element.AMOUNTAPPLIED,
            });
        });
    }

    /**
     * Used to get trust money date
     * @param type - type of the event
     * @param event -get selected Date.
     */
    choosedDateTranD(type: string, event: MatDatepickerInputEvent<Date>):void {
        let begin = this.datepipe.transform(event.value, "dd/MM/yyyy");
        this.SendDate = begin;
    }

    /**
     * Used to get the check balance save data
     */
    checkBalaceSave():void {
        if (this.action == "withdrawal" && this.matterData) {
            // let balance = this.matterData.TRUSTBALANCE ? this.matterData.TRUSTBALANCE : 0;
            // if (Number(balance) < Number(this.f.AMOUNT.value)) {
            //   this.confirmDialogRef1 = this.dialog.open(ConfirmPopupComponent, { disableClose: true, width: '100%', });
            //   this.confirmDialogRef1.componentInstance.confirmMessage = "This Amount will make the " + this.matterData.CONTACTNAME + " : " + this.matterData.MATTER + ' Trust Ledger OverDrawn! Current balance : ' + this.ShowCurrencyFormate(balance);
            //   this.confirmDialogRef1.componentInstance.confirmTitle = "Continue?";
            //   this.confirmDialogRef1.afterClosed().subscribe(result => {
            //     if (result) {
            this.SaveTrustMoney();
            //     }
            //   });
            // } else {
            //   this.SaveTrustMoney();
            // }
        } else if (
            this.action == "Unknown Deposit" ||
            this.action == "Transfer Unknown Deposit" ||
            this.action == "Cancelled Cheque" ||
            this.action == "Statutory Receipt" ||
            this.action == "Statutory Deposit"
        ) {
            this.confirmDialogRef1 = this.dialog.open(ConfirmPopupComponent, {
                disableClose: true,
                width: "100%",
            });
            this.confirmDialogRef1.componentInstance.confirmMessage =
                "Are you sure you want to save this transaction. it CANNOT be undone! if you make an error you will need to reverse the entry.";
            this.confirmDialogRef1.componentInstance.confirmTitle =
                "Save Transaction?";
            this.confirmDialogRef1.afterClosed().subscribe((result) => {
                if (result) {
                    this.SaveTrustMoney();
                }
            });
        } else {
            this.SaveTrustMoney();
        }
    }

    /**
     * Used to save the trust money data.
     */
    SaveTrustMoney(): void {
        this.GetSetMixRetry = false;
        let data;
        if (this.isReverse == "Yes") {
            data = {
                TRUSTTRANSACTIONGUID: this.f.TRUSTTRANSACTIONGUID.value,
                TRANSACTIONCLASS: "Trust Money",
                TRANSACTIONTYPE: "Reversal",
                TRANSACTIONSUBTYPE: "Normal",
                CASHBOOK: this.sendToAPI,
                AMOUNT: this.f.AMOUNT.value,
                AUTHORISEDBY: this.f.AUTHORISEDBY.value,
                PURPOSE: this.f.PURPOSE.value,
            };
        } else {
            data = {
                MULTIITEM: this.matterType,
                TRANSACTIONDATE: this.SendDate,
                AMOUNT: this.f.AMOUNT.value,
                PAYMENTTYPE: this.f.PAYMENTTYPE.value,
                PURPOSE: this.f.PURPOSE.value,
                FROMMATTERGUID: this.f.FROMMATTERGUID.value,
                BANKCHEQUE: this.f.BANKCHEQUE.value,
                BENEFICIARY: this.f.BENEFICIARY.value,
                // new added
                CASHBOOK: this.sendToAPI,
                // TRANSACTIONCLASS: "Trust Money",
                TRANSACTIONCLASS: this.TranClassName,
                TRANSACTIONTYPE: "Normal Item",
                TRANSACTIONSUBTYPE: this.sendTransectionSubType,
                PAYORGROUP: {
                    PAYOR: this.f.PAYOR.value,
                    ADDRESS1: this.f.ADDRESS1.value,
                    // ADDRESS2: '',
                    SUBURB: this.f.SUBURB.value,
                    STATE: this.f.STATE_.value,
                    POSTCODE: this.f.POSTCODE.value,
                },
                AUTHORISEDBY: this.f.AUTHORISEDBY.value,
                PREPAREDBY: this.f.PREPAREDBY.value,

                CASHGROUP: {
                    COINS: this.f.COINS.value,
                    FIFTYDOLLARS: this.f.FIFTYDOLLARS.value,
                    HUNDREDDOLLARS: this.f.HUNDREDDOLLARS.value,
                    FIVEDOLLARS: this.f.FIVEDOLLARS.value,
                    TENDOLLARS: this.f.TENDOLLARS.value,
                    TWENTYDOLLARS: this.f.TWENTYDOLLARS.value,
                },
                BANKDETAILS: {
                    CHEQUENO: this.f.CHEQUENO.value,
                    ACCOUNTNAME: this.f.ACCOUNTNAME.value,
                    ACCOUNTNUMBER: this.f.ACCOUNTNUMBER.value,
                    BSB: this.f.BSB.value,
                    EFTREFERENCE: this.f.EFTREFERENCE.value,
                },
                BPAYDETAILS: {
                    BPAYBILLERCODE: this.f.BPAYBILLERCODE.value,
                    BPAYREFERENCE: this.f.BPAYREFERENCE.value,
                },
                FOREIGNEFT: {
                    FOREIGNEFTSWIFTCODE: this.f.FOREIGNEFTSWIFTCODE.value,
                    FOREIGNEFTIBAN: this.f.FOREIGNEFTIBAN.value,
                    FOREIGNEFTBANKNAME: this.f.FOREIGNEFTBANKNAME.value,
                    FOREIGNEFTCOUNTRY: this.f.FOREIGNEFTCOUNTRY.value,
                    FOREIGNEFTCURRENCY: this.f.FOREIGNEFTCURRENCY.value,
                    FOREIGNEFTAMOUNT: this.f.FOREIGNEFTAMOUNT.value,
                    FOREIGNEFTCONVERSIONDETAILS:
                        this.f.FOREIGNEFTCONVERSIONDETAILS.value,
                },
                INVOICES: this.Invoicedata,
                BANKACCOUNTGUID: this.f.BANKACCOUNTGUID.value,
            };
            if (this.matterType) {
                delete data.PURPOSE;
                data.TRUSTTRANSACTIONITEMS = this.TRUSTTRANSACTIONITEMS;
            } else {
                data.TOMATTERGUID = this.f.TOMATTERGUID.value;
            }
        }
        if (
            this.action == "Statutory Receipt" ||
            this.action == "Cancelled Cheque" ||
            this.action == "Unknown Deposit" ||
            this.action == "Statutory Deposit"
        ) {
            delete data.TOMATTERGUID;
            delete data.FROMMATTERGUID;
            delete data.BANKACCOUNTGUID;
        }
        this.isspiner = true;


        // this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        let finalData = {
            DATA: data,
            FormAction: "insert",
            VALIDATEONLY: true,
            RequestGuid: this.RequestGuid,
        };
        //return;
        this._mainAPiServiceService
            .getSetData(finalData, "SetTrustTransaction")
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            finalData
                        );
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            finalData
                        );
                        this.isspiner = false;
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            finalData
                        );
                        //this.toastr.error(response.MESSAGE);
                        this.isspiner = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                },
                (err) => {
                    this.isspiner = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * Used to check the validation data
     * @param bodyData -Validation data
     * @param details -valiodation details
     */
    async checkValidation(bodyData: any, details: any):Promise<void> {

        this.globalFunService
            .checkValidation(bodyData, details)
            .subscribe((data) => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.trustMoneyData(details);
                    } else {
                        this.isspiner = false;
                    }
                }
            });
    }

    /**
     * Used to get the trust money data.
     * @param data -trust money data.
     */
    trustMoneyData(data: any):void {
        this.isspiner = true;
        data.VALIDATEONLY = false;
        // this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        data.RequestGuid = this.RequestGuid;
        this._mainAPiServiceService
            .getSetData(data, "SetTrustTransaction")
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        if (this.isReverse != "Yes") {
                            this.INCOMEGUID = response.DATA.INCOMEGUID;
                            if (response.DATA.PDFFILENAME != "") {
                                let confirmDilog = this.dialog.open(
                                    TrustMoneyReciptComponent,
                                    {
                                        width: "100%",
                                        data: {
                                            action: this.sendToAPI,
                                            forPDF: "Yes",
                                            PDFURL: response.DATA.PDFFILENAME,
                                            reportTitle: this.reportTitle,
                                            dialogName: "AddTrustWithdrawal",
                                        },
                                    }
                                );
                                confirmDilog
                                    .afterClosed()
                                    .subscribe((result) => {
                                        if (result) {
                                            if (this.action == "office") {
                                                let confirmDialogRefRe =
                                                    this._matDialog.open(
                                                        FuseConfirmDialogComponent,
                                                        {
                                                            disableClose: true,
                                                            width: "100%",
                                                        }
                                                    );
                                                confirmDialogRefRe.componentInstance.confirmMessage =
                                                    "Do You want to generate a copy of the receipt?";
                                                confirmDialogRefRe
                                                    .afterClosed()
                                                    .subscribe((result) => {
                                                        if (result) {
                                                            let passdata = {
                                                                Context:
                                                                    "Receipt",
                                                                ContextGuid:
                                                                    this
                                                                        .INCOMEGUID,
                                                                knownby:
                                                                    "Receipt",
                                                                Type: "Template",
                                                                Template:
                                                                    "<DEFRECTEMP>",
                                                            };
                                                            const dialogRef =
                                                                this._matDialog.open(
                                                                    GenerateDocumentPopupComponent,
                                                                    {
                                                                        width: "100%",
                                                                        disableClose:
                                                                            true,
                                                                        data: passdata,
                                                                    }
                                                                );
                                                        }
                                                        confirmDialogRefRe =
                                                            null;
                                                    });
                                            } else if (
                                                this.action == "withdrawal"
                                            ) {
                                                let confirmDialogRefRe =
                                                    this._matDialog.open(
                                                        FuseConfirmDialogComponent,
                                                        {
                                                            disableClose: true,
                                                            width: "100%",
                                                        }
                                                    );
                                                confirmDialogRefRe.componentInstance.confirmMessage =
                                                    "Do You want to prepare another trust withdrawal for this matter?";
                                                confirmDialogRefRe
                                                    .afterClosed()
                                                    .subscribe((result) => {
                                                        if (result) {
                                                            const dialogRef =
                                                                this._matDialog.open(
                                                                    TrustMoneyDialogeComponent,
                                                                    {
                                                                        width: "100%",
                                                                        disableClose:
                                                                            true,
                                                                        data: {
                                                                            action: "withdrawal",
                                                                            forPDF: "No",
                                                                            isMatter:
                                                                                true,
                                                                            MATTERGUID:
                                                                                this
                                                                                    .f
                                                                                    .MATTERGUID
                                                                                    .value,
                                                                        },
                                                                    }
                                                                );
                                                            dialogRef
                                                                .afterClosed()
                                                                .subscribe(
                                                                    (
                                                                        result
                                                                    ) => {
                                                                        if (
                                                                            result
                                                                        ) {
                                                                            $(
                                                                                "#trustMoneyRefersh"
                                                                            ).click();
                                                                        }
                                                                    }
                                                                );
                                                        }
                                                        confirmDialogRefRe =
                                                            null;
                                                    });
                                            }
                                        }
                                        confirmDilog = null;
                                    });
                            }
                        }
                        this.toastr.success("save successfully");
                        this.isspiner = false;
                        this.dialogRef.close(true);
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.isspiner = false;
                        this.toastr.warning(response.MESSAGE);
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                        this.isspiner = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    } else {
                        this.isspiner = false;
                    }
                },
                (error) => {
                    this.isspiner = false;
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This is used to edit the Inner table data.
     * @param val -table data
     * @param Index -updated index value.
     */
    editInnerTable(val, Index):void {
        this.INDEX = Index;
        this.highlightedRows = Index;
    }

    /**
     * Used to Add the matter data
     */
    addMatterItem():boolean {
        this.MatterAmmountArray = [];
        if (this.f.AMOUNT.value == "") {
            this.toastr.error("Please enter Amount");
            return false;
        } else if (this.f.PURPOSE.value == "") {
            this.toastr.error("Please enter Reason");
            return false;
        } else {
            if (
                this.f.SHORTNAME.value != "" &&
                this.f.MatterAMOUNT.value != "" &&
                this.f.PURPOSE.value != ""
            ) {
                // calculation total and unallocated
                // + ' :' + this.f.INVOICEDVALUEEXGST.value
                this.getDataForTable.push({
                    MatterNumber: this.matterNumber,
                    Matter: this.f.multiTableSHORTNAME.value,
                    Ammount: this.f.MatterAMOUNT.value,
                    Reason: this.f.PURPOSE.value,
                });

                this.TRUSTTRANSACTIONITEMS.push({
                    MATTERGUID: this.multiMatterGuid,
                    AMOUNT: this.f.MatterAMOUNT.value,
                    PURPOSE: this.f.PURPOSE.value,
                });

                this.getDataForTable.forEach((element) => {
                    this.MatterAmmountArray.push(Number(element.Ammount));
                });

                let SumOfMatterAmmount = Number(
                    this.MatterAmmountArray.reduce(function (a = 0, b = 0) {
                        return a + b;
                    }, 0)
                );
                this.TrustMoneyForm.controls["Unallocated"].setValue(
                    Number(this.f.AMOUNT.value) - Number(SumOfMatterAmmount)
                );
                this.TrustMoneyForm.controls["Total"].setValue(
                    Number(SumOfMatterAmmount)
                );
                // empty field for another push
                this.CommonEmptyField();
            } else {
                this.toastr.error("Please fill up Matter | Amount fields");
            }
            this.mattername = [];
        }
    }

    /**
     * Used to make the empty fields
     */
    CommonEmptyField():void {
        this.TrustMoneyForm.controls["MatterAMOUNT"].setValue("");
        this.TrustMoneyForm.controls["PURPOSE"].setValue("");
        this.TrustMoneyForm.controls["SHORTNAME"].setValue("");
        this.TrustMoneyForm.controls["INVOICEDVALUEEXGST"].setValue("");
        // this.multiMatterGuid = false;
    }

    /**
     * Used to get the main amount
     */
    MainAmmountPress():void {
        this.TrustMoneyForm.controls["Unallocated"].setValue(
            this.f.AMOUNT.value
        );
    }

    /**
     * Used to delete the Matter Item
     */
    deleteMatterItem():void {
        this.MatterAmmountArray.splice(this.INDEX, 1);
        let SumOfMatterAmmount = Number(
            this.MatterAmmountArray.reduce(function (a = 0, b = 0) {
                return a + b;
            }, 0)
        );
        this.TrustMoneyForm.controls["Unallocated"].setValue(
            Number(this.f.AMOUNT.value) - Number(SumOfMatterAmmount)
        );
        this.TrustMoneyForm.controls["Total"].setValue(
            Number(SumOfMatterAmmount)
        );
        this.getDataForTable.splice(this.INDEX, 1);
        this.TRUSTTRANSACTIONITEMS.splice(this.INDEX, 1);
    }

    /**
     * Used to select the contact data.
     */
    SelectContact():void {
        this.selectFromIcon = true;
        if (!this.IsReverse) {
            const dialogRef = this.dialog.open(ContactSelectDialogComponent, {
                width: "100%",
                disableClose: true,
                data: "",
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.selectFromIcon = false;
                if (result) {
                    if (this.action == "withdrawal" || this.action == "money receipt" || this.action == "Statutory Deposit") {
                        this.TrustMoneyForm.controls["ACCOUNTNAME"].setValue(result.BANKACCOUNTNAME);
                        this.TrustMoneyForm.controls["BSB"].setValue(result.BANKBSB);
                        this.TrustMoneyForm.controls["ACCOUNTNUMBER"].setValue(result.BANKACCOUNTNUMBER);
                    }
                    if (this.action == "withdrawal") {
                        this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTSWIFTCODE = result.FOREIGNEFTSWIFTCODE;
                        this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTIBAN = result.FOREIGNEFTIBAN;
                        this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTBANKNAME = result.FOREIGNEFTBANKNAME;
                        this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTCOUNTRY = result.FOREIGNEFTCOUNTRY;
                        this.TRUSTTRANSACTIONSDATA.FOREIGNEFT.FOREIGNEFTCURRENCY = result.FOREIGNEFTCURRENCY;
                        if(this.PymentType == 'International EFT') {
                            this.TrustMoneyForm.controls["FOREIGNEFTSWIFTCODE"].setValue(result.FOREIGNEFTSWIFTCODE);
                            this.TrustMoneyForm.controls["FOREIGNEFTIBAN"].setValue(result.FOREIGNEFTIBAN);
                            this.TrustMoneyForm.controls["FOREIGNEFTBANKNAME"].setValue(result.FOREIGNEFTBANKNAME);
                            this.TrustMoneyForm.controls["FOREIGNEFTCOUNTRY"].setValue(result.FOREIGNEFTCOUNTRY);
                            this.TrustMoneyForm.controls["FOREIGNEFTCURRENCY"].setValue(result.FOREIGNEFTCURRENCY);
                        }
                    }
                    // if (this.action == "receipt") {
                    this.TrustMoneyForm.controls["ADDRESS1"].setValue(
                        result.ADDRESS1 +
                            " " +
                            result.ADDRESS2 +
                            "" +
                            result.ADDRESS3
                    );
                    this.TrustMoneyForm.controls["SUBURB"].setValue(
                        result.SUBURB
                    );
                    this.TrustMoneyForm.controls["STATE_"].setValue(
                        result.STATE
                    );
                    this.TrustMoneyForm.controls["POSTCODE"].setValue(
                        result.POSTCODE
                    );
                    // }
                    this.TrustMoneyForm.controls["PAYOR"].setValue(
                        result.CONTACTNAME
                    );
                    this.contactData = [result.CONTACTNAME];
                }
            });
        }
    }

    /**
     * This is Used to open the Bank Dialog
     * @param type -
     * @param UseTrust
     */
    BankingDialogOpen(type: any, UseTrust) {
        // for by default bank account select not trust account
        this.ChartHandlingData = {
            ClickType: "WithTrust",
            UseTrust: "Yes",
            PopUp: "",
            Lable: "TRUST CHART OF ACCOUNTS",
        };
        localStorage.setItem(
            "ChartURL",
            JSON.stringify(this.ChartHandlingData)
        );
        this.behaviorService.TrustDuplicateModuleHandling(
            this.ChartHandlingData
        );
        const dialogRef = this._matDialog.open(BankingDialogComponent, {
            disableClose: true,
            width: "100%",
            data: { AccountType: type, UseTrust: UseTrust },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let bankAccountName =
                    this.action == "Transfer Unknown Deposit"
                        ? result.ACCOUNTNAME
                        : result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER;
                this.TrustMoneyForm.patchValue({
                    BANKACCOUNTGUIDTEXT: bankAccountName,
                    BANKACCOUNTGUID: result.ACCOUNTGUID,
                });
                if (result.ACCOUNTCLASSNAME == "Controlled Money Account") {
                    this.TrustMoneyForm.patchValue({
                        TOMATTERGUID: result.MATTERGUID,
                    });
                }
                if (type == "Unknown Deposit") {
                    this.MaxValue = result.OPENINGBALANCE;
                    this.TrustMoneyForm.patchValue({
                        AMOUNT: parseFloat(result.OPENINGBALANCE).toFixed(2),
                    });
                }
            }
        });
    }

    onKey(event: any) {
        let Total: any = 0;
        Total += this.f.COINS.value;
        Total += this.f.FIVEDOLLARS.value * 5;
        Total += this.f.TENDOLLARS.value * 10;
        Total += this.f.TWENTYDOLLARS.value * 20;
        Total += this.f.FIFTYDOLLARS.value * 50;
        Total += this.f.HUNDREDDOLLARS.value * 100;
        this.TrustMoneyForm.controls["TOTALDOLLARS"].setValue(Total);
    }

    /**
     * Used to create currency format
     * @param val -currency value
     * @returns-its return the formated amount
     */
    ShowCurrencyFormate(val):string {
        let isNumCalClosingBal = val ? val.toString().replace(/[$,]/g, "") : 0;
        if (!isNaN(isNumCalClosingBal)) {
            let formattedAmount = this.currencyPipe.transform(
                isNumCalClosingBal,
                "$"
            );
            return formattedAmount;
        } else {
        }
    }

    /**
     * This function is mainly used for sorting the data
     */
    sortingDate():void {
        this.dataSource.sortingDataAccessor = (item, property) => {
            let FildeValue = ["INVOICEDATE"];
            if (FildeValue.includes(property)) {
                if (item[property]) {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(
                        tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                    );
                    let newDate = new Date(Sd);
                    return newDate;
                }
                return item[property];
            } else {
                return item[property];
            }
        };
        // proper shorting for date
        this.dataSource.sort = this.sort;
    }

    /**
     * Used to refresh the Matter trust balance list
     * @param matterGuid -MatterGuid
     */
    async refreshMatterTrustblance(matterGuid):Promise<void> {
        let Payload = {
            Action: "GetData",
            Filters: { MATTERGUID: matterGuid },
        };

        this._mainAPiServiceService.getSetData(Payload, "matter").subscribe(
            async (response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    if (response.DATA.RECORDS[0]) {
                        this.matterData = response.DATA.RECORDS[0];
                        let balance = 0;
                        if (this.mattername[0]) {
                            balance = this.mattername[0].OPENINGBALANCE
                                ? this.mattername[0].OPENINGBALANCE
                                : 0;
                        }
                        this.TrustMoneyForm.patchValue({
                            SHORTNAME:
                                this.matterData.CONTACTNAME +
                                ": " +
                                this.matterData.MATTER +
                                " Trust Ledger" +
                                ": " +
                                this.ShowCurrencyFormate(balance),
                            MATTERGUID: this.matterData.MATTERGUID,
                            INVOICEDVALUEEXGST:
                                this.matterData.INVOICEDVALUEEXGST,
                        });

                        if (this._data && this._data.MATTERGUID) {
                            await this.getAccount(
                                this._data.MATTERGUID,
                                this.matterData.SHORTNAME
                            );
                            // this.mattername = [this.matterData];
                        }
                    }
                }
            },
            (error) => {
                this.toastr.error(error);
            }
        );
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * Used to select the matter
     * @param event -For geeting the matter data.
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            Action: "GetData",
            Filters: { FastSearch: "True", SearchString: event },
        };

        return this._mainAPiServiceService.getSetData(searchobj, "matter")
    }

    /**
     * USed to get the data
     * @param event -
     */
    async datashow(event):Promise<void> {
        this.exportdatavalue.RECORDS.forEach(async (element, i) => {
            if (i == 0) {
                let data = element;
                await this.getAccount(data.MATTERGUID, data.SHORTNAME);
                //this.mattername = [data.ACCOUNTNAME + '--' + data.MATTER]
                this.TrustMoneyForm.controls["SHORTNAME"].setValue(null);
                this.matterNumber = data.SHORTNAME;
                this.multiMatterGuid = data.MATTERGUID;
                // let balance = this.mattername.OPENINGBALANCE ? this.mattername.OPENINGBALANCE : 0;
                this.TrustMoneyForm.controls["SHORTNAME"].setValue(
                    data.CONTACTNAME
                );
                // if (this.mattername.OPENINGBALANCE) {
                //     this.balance = this.mattername.OPENINGBALANCE
                //         ? this.mattername.OPENINGBALANCE
                //         : 0;
                // } else {
                //     if (this.mattername[0].OPENINGBALANCE) {
                //         this.balance = this.mattername[0].OPENINGBALANCE
                //             ? this.mattername[0].OPENINGBALANCE
                //             : 0;
                //     }
                // }
                if (this.mattername.ACCOUNTBALANCE) {
                    this.balance = this.mattername.ACCOUNTBALANCE
                        ? this.mattername.ACCOUNTBALANCE
                        : 0;
                } else {
                    if (this.mattername[0].ACCOUNTBALANCE) {
                        this.balance = this.mattername[0].ACCOUNTBALANCE
                            ? this.mattername[0].ACCOUNTBALANCE
                            : 0;
                    }
                }
                this.TrustMoneyForm.controls["multiTableSHORTNAME"].setValue(
                    data.CONTACTNAME +
                        ": " +
                        data.MATTER +
                        " Trust Ledger" +
                        ": " +
                        this.ShowCurrencyFormate(this.balance)
                );
                if (event.input) {
                    event.input.value = "";
                } else {
                    this.MatterInput.nativeElement.value = "";
                }

                this.TrustMoneyForm.controls["MATTERGUID"].setValue(
                    data.MATTERGUID
                );
                if (this.matterData) {
                    this.matterData.MATTERGUID = data.MATTERGUID;
                }
                if (
                    this.selectedData.map((ele, index) =>
                        ele.MATTERGUID == data.MATTERGUID ? index : -1
                    )[0] == -1 ||
                    this.selectedData.length == 0
                ) {
                    this.selectedData = [data];
                }
            }
        });
    }

    remove(fruit: any) {
        const index = this.mattername.findIndex(
            (e) => e.ACCOUNTGUID == fruit.ACCOUNTGUID
        );
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.TrustMoneyForm.controls["SHORTNAME"].setValue("");
            this.TrustMoneyForm.controls["MATTERGUID"].setValue("");
        }
        let sortname = fruit;
        this.rm(sortname);
    }

    rm(sortname) {
        this.behaviorService.newContactData$.next(null);
        this.contactElemdata.nativeElement.value = "";
        this.selectedData.forEach((element: any, index: any) => {
            if (
                element.MATTERGUID.toString() ==
                sortname.MATTERGUID.replace(/^\s+|\s+$/gm, "")
            ) {
                this.selectedData.splice(index, 1);
            }
        });
    }
    add(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (
                        this.mattername.indexOf(
                            value.replace(/^\s+|\s+$/gm, "")
                        ) == -1
                    ) {
                        this.mattername = [value];
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }
        //  event.chipInput!.clear();

        this.TrustMoneyForm.controls["SHORTNAME"].setValue(null);
    }

    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (
            this.mattername.indexOf(
                event.option.viewValue.replace(/^\s+|\s+$/gm, "")
            ) == -1
        ) {
            this.mattername = [event.option.viewValue];
            this.MatterInput.nativeElement.value = "";
            this.TrustMoneyForm.controls["SHORTNAME"].setValue(null);
        }
    }

    async selectedmatterguid(data: any) {
        this.TrustMoneyForm.controls["MATTERGUID"].setValue(data.MATTERGUID);
        await this.getAccount(data.MATTERGUID, data.SHORTNAME);
        this.matterNumber = data.SHORTNAME;
        this.multiMatterGuid = data.MATTERGUID;
        // if (this.mattername.OPENINGBALANCE) {
        //     this.balance = this.mattername.OPENINGBALANCE
        //         ? this.mattername.OPENINGBALANCE
        //         : 0;
        // } else {
        //     if (this.mattername[0].OPENINGBALANCE) {
        //         this.balance = this.mattername[0].OPENINGBALANCE
        //             ? this.mattername[0].OPENINGBALANCE
        //             : 0;
        //     }
        // }
        if (this.mattername.ACCOUNTBALANCE) {
            this.balance = this.mattername.ACCOUNTBALANCE
                ? this.mattername.ACCOUNTBALANCE
                : 0;
        } else {
            if (this.mattername[0].ACCOUNTBALANCE) {
                this.balance = this.mattername[0].ACCOUNTBALANCE
                    ? this.mattername[0].ACCOUNTBALANCE
                    : 0;
            }
        }
        this.TrustMoneyForm.controls["multiTableSHORTNAME"].setValue(
            data.CONTACTNAME +
                ": " +
                data.MATTER +
                " Trust Ledger" +
                ": " +
                this.ShowCurrencyFormate(this.balance)
        );
        this.TrustMoneyForm.controls["SHORTNAME"].setValue(data.SHORTNAME);
        if (this.matterData) {
            this.matterData.MATTERGUID = data.MATTERGUID;
        }
        if (
            this.selectedData.map((ele, index) =>
                ele.MATTERGUID == data.MATTERGUID ? index : -1
            )[0] == -1 ||
            this.selectedData.length == 0
        ) {
            this.selectedData = [data];
        }
        if (this.mattername != "") {
            await this.getAccount(data.MATTERGUID, data.SHORTNAME);
        }
    }

    async getAccount(matterGUID, shortname) {
        return new Promise(async (next, reject) => {
            await this._mainAPiServiceService
                .getSetData(
                    {
                        MatterGuid: matterGUID,
                        UseTrust: true,
                        ACCOUNTCLASS: "Matter Ledger",
                    },
                    "GetAccount"
                )
                .subscribe(
                    (res) => {
                        if (res.CODE == 200 && res.STATUS == "success") {
                            this.mattername = [res.DATA.ACCOUNTS[0]];
                            this.mattername[0].SHORTNAME = shortname;
                            this.TrustMoneyForm.controls["TOMATTERGUID"].setValue(this.mattername[0].MATTERGUID);
                            this.AmountColor=(res.DATA.ACCOUNTS[0].ROWCOLOUR && res.DATA.ACCOUNTS[0].ROWCOLOUR !="") ? res.DATA.ACCOUNTS[0].ROWCOLOUR : '';
                            // this.errorWarningData = { "Error": [{TOMATTERGUID : ''}], 'Warning': [{TOMATTERGUID : ''}]};
                            next(true);
                        } else {
                            next(true);
                        }
                        this.isLoadingResults = false;
                    },
                    (err) => {
                        this.toastr.error(err);
                        this.isLoadingResults = false;
                        next(true);
                    }
                );
        });
    }

    GetCurrentAccountID(matterGUID, shortname) {
        this._mainAPiServiceService
            .getSetData(
                {
                    MatterGuid: matterGUID,
                    UseTrust: true,
                    ACCOUNTCLASS: "Matter Ledger",
                },
                "GetAccount"
            )
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        return (this.AmountValue =
                            res.DATA.ACCOUNTS[0].ACCOUNTBALANCE);
                    } else {
                    }
                    this.isLoadingResults = false;
                },
                (err) => {
                    this.toastr.error(err);
                    this.isLoadingResults = false;
                }
            );
    }

    /**
     * Used For Getting event regarding data.
     * @param searchContact --To gat the updated data of PAYOR
     **/
    fastSearchContact(searchContact: string):Observable<any> {
        this.payorList = [];
        let payload = {
            ACTION: "GetData",
            FILTERS: {
                FASTSEARCH: 1,
                SEARCH: searchContact,
            },
        };
    return  this._mainAPiServiceService.getSetData(payload, "contact")
    }
    // ends here ~ fast search for payor field

    /**
     * function is called when user select payor from autocomplete list
     * @param result parameter of selected payor from list
     */
    selectContactData(result) {
        let avaiability= this.payorList.filter(data=>data.CONTACTNAME === result.CONTACTNAME);
        if(Object.keys(avaiability[0]).length==1){
            this.toolbarServiceService.ContactsDialog('new',result.CONTACTNAME);
            // this.contactData = [];
            this.TrustMoneyForm.controls['PAYOR'].setValue(null);
            this.payorList=[];
        }else{
            // this.TrustMoneyForm.controls['PAYOR'].setValue(result);
            if (result) {
                this.contactData = [result.CONTACTNAME];
                let contactPayload = {};
                contactPayload["CONTACTGUID"] = result.CONTACTGUID;
                let paylaod = {
                    ACTION: "GetData",
                    FILTERS: { CONTACTGUID: result.CONTACTGUID },
                };
                this._mainAPiServiceService
                    .getSetData(paylaod, "contact")
                    .subscribe((response) => {
                        if (
                            response.CODE == 200 &&
                            response.STATUS == "success"
                        ) {
                            const singleContactDetail =
                                response.DATA.RECORDS[0];

                                if (this.action == "withdrawal" || this.action == "money receipt" || this.action == "Statutory Deposit") {
                                    this.TrustMoneyForm.controls["ACCOUNTNAME"].setValue(singleContactDetail?.BANKACCOUNTNAME);
                                    this.TrustMoneyForm.controls["BSB"].setValue(singleContactDetail?.BANKBSB);
                                    this.TrustMoneyForm.controls["ACCOUNTNUMBER"].setValue(singleContactDetail?.BANKACCOUNTNUMBER);
                                }
                            this.TrustMoneyForm.controls["ADDRESS1"].setValue(
                                singleContactDetail.ADDRESS1 +
                                    " " +
                                    singleContactDetail.ADDRESS2 +
                                    "" +
                                    singleContactDetail.ADDRESS3
                            );
                            this.TrustMoneyForm.controls["SUBURB"].setValue(
                                singleContactDetail.SUBURB
                            );
                            this.TrustMoneyForm.controls["STATE_"].setValue(
                                singleContactDetail.STATE
                            );
                            this.TrustMoneyForm.controls["POSTCODE"].setValue(
                                singleContactDetail.POSTCODE
                            );
                            this.TrustMoneyForm.controls["PAYOR"].setValue(
                                singleContactDetail.CONTACTNAME
                            );
                        }
                    });
            }
        }



        // this.contactElemdata.nativeElement.value = "";
        // if (Object.keys(result).length == 1) {
            //this.toolbarServiceService.ContactsDialog('new',result.CONTACTNAME);
            //this.contactData = [];
            // this.payorList=[];
        //     this.contactData = [result.CONTACTNAME];
        //     this.TrustMoneyForm.controls["PAYOR"].setValue(result.CONTACTNAME);
        // } else {
        //     if (result) {
        //         this.contactData = [result.CONTACTNAME];
        //         let contactPayload = {};
        //         contactPayload["CONTACTGUID"] = result.CONTACTGUID;
        //         let paylaod = {
        //             ACTION: "GetData",
        //             FILTERS: { CONTACTGUID: result.CONTACTGUID },
        //         };
        //         this._mainAPiServiceService
        //             .getSetData(paylaod, "contact")
        //             .subscribe((response) => {
        //                 if (
        //                     response.CODE == 200 &&
        //                     response.STATUS == "success"
        //                 ) {
        //                     const singleContactDetail =
        //                         response.DATA.RECORDS[0];

        //                     this.TrustMoneyForm.controls["ADDRESS1"].setValue(
        //                         singleContactDetail.ADDRESS1 +
        //                             " " +
        //                             singleContactDetail.ADDRESS2 +
        //                             "" +
        //                             singleContactDetail.ADDRESS3
        //                     );
        //                     this.TrustMoneyForm.controls["SUBURB"].setValue(
        //                         singleContactDetail.SUBURB
        //                     );
        //                     this.TrustMoneyForm.controls["STATE_"].setValue(
        //                         singleContactDetail.STATE
        //                     );
        //                     this.TrustMoneyForm.controls["POSTCODE"].setValue(
        //                         singleContactDetail.POSTCODE
        //                     );
        //                     this.TrustMoneyForm.controls["PAYOR"].setValue(
        //                         singleContactDetail.CONTACTNAME
        //                     );
        //                 }
        //             });
        //     }
        // }
    }

    /**
     * This function is used to getContactDataUsing Behaviour.
     */
    getContactDataUsingBehaviour():void {
        this.DestryedContactdata =
            this.behaviorService.newContactData$.subscribe((result) => {
                this.payorList = [];
                if (result) {
                    // if (this.action == "receipt") {
                    this.TrustMoneyForm.controls["ADDRESS1"].setValue(
                        result.ADDRESS1 +
                            " " +
                            result.ADDRESS2 +
                            "" +
                            result.ADDRESS3
                    );
                    this.TrustMoneyForm.controls["SUBURB"].setValue(
                        result.SUBURB
                    );
                    this.TrustMoneyForm.controls["STATE_"].setValue(
                        result.STATE
                    );
                    this.TrustMoneyForm.controls["POSTCODE"].setValue(
                        result.POSTCODE
                    );
                    // }
                    this.TrustMoneyForm.controls["PAYOR"].setValue(
                        result.CONTACTNAME
                    );
                    this.contactData = [result.CONTACTNAME];
                }
            });
    }

    // @HostListener("document:click", ["$event"])
    // documentClick(event: MouseEvent) {
    //     let result = this.contactElemdata.nativeElement.value;
    //     if (
    //         result &&
    //         result != null &&
    //         result != undefined &&
    //         this.CallHostlistner == false
    //     ) {
    //         this.selectContactTab(this.contactElemdata.nativeElement);
    //         this.contactElemdata.nativeElement.value = "";
    //     }
    // }
    ngOnDestroy(): void {
        this.DestryedContactdata?.unsubscribe();
        this.behaviorService.newContactData$.next(null);
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    /**
     * function is called when user click key from keyboard
     * @param evt current event value from particular element
     */
    selectContactTab(evt) {
        // this.contactData = [];
        // this.CallHostlistner = true;
        // if (Object.keys(this.payorList[0]).length > 1) {
        //     this.selectContactData(this.payorList[0]);
        //     this.contactData = [this.payorList[0].CONTACTNAME];
        // } else {
            // this.toolbarServiceService.ContactsDialog('new',this.contactElemdata.nativeElement.value);
            // this.contactData = [];
            // this.payorList=[];
            //this.contactElemdata.nativeElement.value='';
        //     this.contactData = [this.contactElemdata.nativeElement.value];
        //     this.TrustMoneyForm.controls["PAYOR"].setValue(
        //         this.contactElemdata.nativeElement.value
        //     );
        // }
        // this.CallHostlistner = false;
        if(Object.keys(this.payorList[0]).length==1){
           // this.contactData = [];
           this.toolbarServiceService.ContactsDialog('new',this.payorList[0]["CONTACTNAME"]);
           this.TrustMoneyForm.controls['PAYOR'].setValue(null);
            this.payorList=[];
          }else{
            const contactName = this.payorList[0]["CONTACTNAME"]
            this.TrustMoneyForm.controls['PAYOR'].setValue(contactName);
          }
    }

    // ends here ~ select contact from fast search result

    // remove contact
    // removeContact(contact) {
    //     this.contactData = [];
    //     this.TrustMoneyForm.controls["ADDRESS1"].setValue("");
    //     this.TrustMoneyForm.controls["SUBURB"].setValue("");
    //     this.TrustMoneyForm.controls["STATE_"].setValue("");
    //     this.TrustMoneyForm.controls["POSTCODE"].setValue("");
    //     this.TrustMoneyForm.controls["PAYOR"].setValue("");
    //     this.contactElemdata.nativeElement.value = "";
    //     this.payorList = [];
    // }
    // ends here ~ select contact from fast search result
    LoadFromCSV() {
        let AmountValue = this.TrustMoneyForm.controls["Unallocated"].value;

        const amountVal = this.TrustMoneyForm.controls["AMOUNT"].value;
        const reasonVal = this.TrustMoneyForm.controls["PURPOSE"].value;

        // if(amountVal != "" && reasonVal != "") {
        //   document.getElementById("csvFileUpload").click();
        // } else {
        // }
        let dialogRef = this.dialog.open(CSVPopupComponent, {
            width: "100%",
            data: {
                MATTERGUID: this.multiMatterGuid,
                SHORTNAME: this.matterNumber,
                AmountValue: AmountValue,
                amountVal: amountVal,
                reasonVal: reasonVal,
                dialogName: "CSVPopupComponent",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (reasonVal != "" && amountVal != "" && amountVal > 0) {
                    document.getElementById("csvFileUpload").click();
                }
            }
        });
    }

    /**
     * This function is used to chenge the listner
     */
    async changeListener(files: FileList) {
        if (files && files.length > 0) {
            let file: File = files.item(0);
            //File reader method
            let reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                let csv: any = reader.result;
                let allTextLines = [];
                allTextLines = csv.split(/\r|\n|\r/);

                //Table Headings
                let headers = allTextLines[0].split(",");
                let data = headers;
                let tarr = [];
                for (let j = 0; j < headers.length; j++) {
                    tarr.push(data[j]);
                }
                //Pusd headings to array variable
                this.lines.push(tarr);
                // Table Rows
                let tarrR = [];
                let arrl = allTextLines.length;
                let rows = [];
                for (let i = 0; i < arrl; i++) {
                    rows.push(allTextLines[i].split(","));
                }
                for (let j = 0; j < arrl; j++) {
                    if (rows[j][0] !== "" || undefined) {
                        tarrR.push(rows[j]);
                    }
                }
                this.csvData = tarrR;
                this.finalarr = [
                    tarrR[0][0]
                        .replace(/^"|"$/g, "")
                        .replace(/\\+/g, "")
                        .replace(/\t/g, " "),
                    parseInt(tarrR[0][1]),
                ];
                this.filters = {
                    FastSearch: "True",
                    SearchString: this.finalarr[0].toString(),
                };
                this.getMatterList(this.filters, this.finalarr);
            };
        }
    }

    /**
     * This functionis used to read the Matter Csv
     */
    async readMatterCsvFile(files) {
        this.csvData = "";
        this.count = 0;
        // each row being an array of cells.
        if (files[0]?.type == "text/csv") {
            await this.changeListener(files);
        } else {
            readXlsxFile(files[0]).then(async (data) => {
                this.csvData = data;
                let filters = {
                    FastSearch: "True",
                    SearchString: data[0][0].toString(),
                };
                await this.getMatterList(filters, data[0]);
            });
        }
    }

    /**
     * This function is used to get the MatterList data value.
     */
    async getMatterList(filterVal: any, cell: any) {
        let Payload: any = {
            Action: "GetData",
        };

        Payload.Filters = filterVal;
        this.isLoadingResults = true;
        return new Promise(async (responseIs: any, reject: any) => {
            await this._mainAPiServiceService
                .getSetData(Payload, "matter")
                .subscribe(async (response) => {
                    if (response.CODE == 200 && response.DATA) {
                        let TrustReciptmatterData =
                            response.DATA.RECORDS.filter(
                                (e) => e.SHORTNAME == cell[0]
                            );
                        if (TrustReciptmatterData.length > 0) {
                            await this.getAccount(
                                TrustReciptmatterData[0].MATTERGUID,
                                TrustReciptmatterData[0].SHORTNAME
                            ).then(() => {
                                let balance = 0;
                                // if (this.mattername?.OPENINGBALANCE) {
                                //     balance = this.mattername.OPENINGBALANCE
                                //         ? this.mattername.OPENINGBALANCE
                                //         : 0;
                                // } else {
                                //     if (this.mattername[0]?.OPENINGBALANCE) {
                                //         balance = this.mattername[0]
                                //             .OPENINGBALANCE
                                //             ? this.mattername[0].OPENINGBALANCE
                                //             : 0;
                                //     }
                                // }

                                if (this.mattername?.ACCOUNTBALANCE) {
                                    balance = this.mattername.ACCOUNTBALANCE
                                        ? this.mattername.ACCOUNTBALANCE
                                        : 0;
                                } else {
                                    if (this.mattername[0]?.ACCOUNTBALANCE) {
                                        balance = this.mattername[0]
                                            .ACCOUNTBALANCE
                                            ? this.mattername[0].ACCOUNTBALANCE
                                            : 0;
                                    }
                                }

                                if (TrustReciptmatterData.length > 0) {
                                    this.getDataForTable.push({
                                        MatterNumber:
                                            TrustReciptmatterData[0].SHORTNAME,
                                        Matter:
                                            TrustReciptmatterData[0]
                                                .CONTACTNAME +
                                            ": " +
                                            TrustReciptmatterData[0].MATTER +
                                            " Trust Ledger" +
                                            ": " +
                                            this.ShowCurrencyFormate(balance),
                                        Ammount: cell[1],
                                        Reason: this.f.PURPOSE.value,
                                    });
                                    this.MatterAmmountArray.push(
                                        Number(cell[1])
                                    );
                                    this.TRUSTTRANSACTIONITEMS.push({
                                        MATTERGUID:
                                            TrustReciptmatterData[0].MATTERGUID,
                                        AMOUNT: cell[1],
                                        PURPOSE: this.f.PURPOSE.value,
                                    });

                                    let SumOfMatterAmmount = Number(
                                        this.MatterAmmountArray.reduce(
                                            function (a = 0, b = 0) {
                                                return a + b;
                                            },
                                            0
                                        )
                                    );
                                    this.TrustMoneyForm.controls[
                                        "Unallocated"
                                    ].setValue(
                                        Number(this.f.AMOUNT.value) -
                                            Number(SumOfMatterAmmount)
                                    );
                                    this.TrustMoneyForm.controls[
                                        "Total"
                                    ].setValue(Number(SumOfMatterAmmount));
                                    this.mattername = [];

                                    // empty field for another push
                                    // this.CommonEmptyField();

                                    this.TrustMoneyForm.controls[
                                        "MatterAMOUNT"
                                    ].setValue("");
                                    this.TrustMoneyForm.controls[
                                        "SHORTNAME"
                                    ].setValue("");
                                    this.TrustMoneyForm.controls[
                                        "INVOICEDVALUEEXGST"
                                    ].setValue("");

                                    this.count++;
                                    if (this.csvData.length > this.count) {
                                        let filters = {
                                            FastSearch: "True",
                                            SearchString:
                                                this.csvData[
                                                    this.count
                                                ][0].toString(),
                                        };
                                        this.getMatterList(
                                            filters,
                                            this.csvData[this.count]
                                        );
                                    } else {
                                        this.toastr.success(
                                            "Your Data is Uploaded"
                                        );
                                        //after loop finish it's clear.
                                        this.TrustMoneyForm.controls[
                                            "PURPOSE"
                                        ].setValue("");
                                    }
                                }

                                this.isLoadingResults = false;
                                responseIs(true);
                            });
                        } else {
                            //If Matter Detail is not found.
                            let matterData = {
                                csvData: true,
                                message: `could not locate matter '${cell[0]}' , it has not been loaded stop loading?`,
                            };

                            this.confirmDialogRef = this.MatDialog.open(
                                FuseConfirmDialogComponent,
                                {
                                    disableClose: true,
                                    width: "100%",
                                    data: matterData,
                                }
                            );

                            this.confirmDialogRef.componentInstance.confirmMessage = `could not locate matter '${cell[0]}' , it has not been loaded stop loading?`;
                            this.confirmDialogRef
                                .afterClosed()
                                .subscribe((result) => {
                                    if (!result) {
                                        this.count++;
                                        if (this.csvData.length > this.count) {
                                            let filters = {
                                                FastSearch: "True",
                                                SearchString:
                                                    this.csvData[
                                                        this.count
                                                    ][0].toString(),
                                            };
                                            this.getMatterList(
                                                filters,
                                                this.csvData[this.count]
                                            );
                                        } else {
                                            this.toastr.success(
                                                "Your Data is Uploaded"
                                            );
                                            //after loop finish it's clear.
                                            this.TrustMoneyForm.controls[
                                                "PURPOSE"
                                            ].setValue("");
                                        }
                                    } else {
                                        this.toastr.success(
                                            "Your Data is Uploaded"
                                        );
                                        this.TrustMoneyForm.controls[
                                            "PURPOSE"
                                        ].setValue("");
                                    }
                                    this.confirmDialogRef = null;
                                });

                            this.isLoadingResults = false;
                        }
                    } else {
                        responseIs(true);
                        // this.isLoadingResults = false;
                    }
                });
        });
    }

    // read matter csv file
    // readMatterCsvFile(files) {
    //   let csvFile = files.item(0);

    //   var reader = new FileReader();

    //   reader.addEventListener('load', function (e:any) {
    //     // output.textContent = e.target.result;

    //     var arr = e.target.result.split('\n');

    //   var jsonObj = [];
    //   var headers = arr[0].split(',');
    //   for(var i = 1; i < arr.length; i++) {
    //     var data = arr[i].split(',');
    //     // var obj = {};
    //     // for(var j = 0; j < data.length; j++) {
    //     //   if(data[j] && headers[j]) {
    //     //     obj[headers[j].trim()] = data[j].trim();
    //     //   }
    //     // }
    //     // jsonObj.push(obj);
    //   }
    //   });

    //   reader.readAsBinaryString(csvFile);

    // };
    // ends here ~ read matters csv file
}
