<div class="example-loading-shade" *ngIf="loader">
    <mat-spinner [style.zoom]="0.5" *ngIf="loader"></mat-spinner>
</div>
<div id="refreshtabledetails" (click)="getWorkItemsData()"></div>
<div class="example-containerdata" [ngClass]="className">
    <div class="grid-overlay" *ngIf="invoiceData?.data?.length == 0">
        <div class="grid-overlay-msg mgn"> There is no data to display. </div>
    </div>
    <table mat-table #table *ngIf="invoiceData" id="invoice_data" [dataSource]="invoiceData"
        [@animateStagger]="{value:'50'}" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <!-- <table mat-table #table *ngIf="invoiceData" id="invoice_data" [dataSource]="invoiceData"
        [@animateStagger]="{value:'50'}" (matSortChange)="sortData($event);sortingCLM($event)" matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear matSort> -->
        <!-- <ng-container matColumnDef="select" *ngIf="isShownCheckBox || toggleValue==true"> -->
        <ng-container matColumnDef="select" *ngIf="isShownCheckBox || toggleValue==true">
            <mat-header-cell  *matHeaderCellDef style="width: 55px;text-align: center;align-items: center;">
                <mat-checkbox (click)="retuenData();" (change)="$event ? masterToggle() : null;retuenData();"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="width: 55px;text-align: center;align-items: center;">
                <mat-checkbox (click)="$event.stopPropagation();retuenData();"
                    (change)="$event ? selection.toggle(row) : null;retuenData();" [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)" [disabled]="row.ISGROUPBY != 0">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="link" *ngIf="!isShownCheckBox">
            <mat-header-cell  *matHeaderCellDef style="width: 60px;text-align: center;align-items: center;">
                EDIT
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="width: 60px;text-align: center;align-items: center;">
                <!-- <button mat-raised-button  class="redious" (click)="getrowdata(row)">
                    <mat-icon class="SwitchIcon">edit</mat-icon>
                </button> -->
                <div class="ellipsis-menu">
                    <button mat-icon-button [matMenuTriggerFor]="menu" style="margin-top: -5px;">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <ng-container>
                        <ng-container >
                          <button
                          [ngClass]="(appPermissions[2]?.Edit==0) ? 'disabled-click large-button' : 'large-button'"
                          *ngIf="row?.ITEMTYPEDESC !='Search' && row?.ITEMTYPEDESC !='Cost Recovery'" mat-menu-item (click)="getrowdata(row)">
                            <mat-icon class="main-icon">
                             <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                            </mat-icon>Edit
                         </button>
                          <button
                          [ngClass]="(appPermissions[2]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                          *ngIf="row?.ITEMTYPEDESC !='Search' && row?.ITEMTYPEDESC !='Cost Recovery' && row?.ITEMTYPEDESC !='Disbursement' " mat-menu-item (click)="addDuplicateTimeEntry(row)">
                            <mat-icon class="main-icon">
                                <img
                                    src="assets/icons/web_app/icon_time_cost_new_d.ico">
                            </mat-icon>
                            Duplicate Entry
                         </button>
                          <button 
                          [ngClass]="(appPermissions[2]?.Edit==0 ||  row.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                          mat-menu-item (click)='OpenWriteOffDialog("WIP",row)'>
                            <mat-icon>
                                <img
                                    src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                            </mat-icon>
                            Write Off
                          </button>
                          <button 
                          [ngClass]="(appPermissions[7]?.Access==0 || row.INVOICEGUID != '' || row.INVOICEGUID == -1) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                          *ngIf="row?.ITEMTYPEDESC !='Search' && row?.ITEMTYPEDESC !='Cost Recovery' && row?.ITEMTYPEDESC !='Disbursement' " mat-menu-item (click)="moveEntry(row)">
                            <mat-icon>
                                <img
                                    src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                            </mat-icon>
                            Move Entry
                         </button>
                          <button
                          [ngClass]="(appPermissions[2]?.Access==0 || appPermissions[2]?.Create==0  || appPermissions[2]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                          (click)="splittimeentry('Split',row)" *ngIf="row?.ITEMTYPEDESC =='Time Entry' || row?.ITEMTYPEDESC =='WIP'" mat-menu-item>
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                            </mat-icon>
                            Split Time Entry
                          </button>
                          <button
                          [ngClass]="(appPermissions[2]?.Delete==0 || row.INVOICEGUID != '') ? 'disabled-click large-button' : 'large-button'"
                          (click)="deleteTimeEntry(row)"
                          *ngIf="row?.ITEMTYPEDESC !='Search' && row?.ITEMTYPEDESC !='Cost Recovery' &&  row?.ITEMTYPEDESC != 'Disbursement'" mat-menu-item >
                            <mat-icon class="main-icon">
                                <img
                                    src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                            </mat-icon>
                            Delete
                         </button>
                        </ng-container>
                      </ng-container>
                    </mat-menu>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ITEMDATE">
            <mat-header-cell style="width: 135px !important;" mwlResizable  (resizeEnd)="onResizeEnd($event, 'ITEMDATE')"
            mwlResizeHandle [resizeEdges]="{ right: true }" *matHeaderCellDef class="text_with_class_date">
                <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
                Date </mat-header-cell>
                    <mat-cell style="width: 135px !important;" *matCellDef="let row" class="text_with_class_date">
                        <div class="text_with_disp">
                            <!-- !row.DELIVERABLEID &&  -->
                            <mat-icon *ngIf="toggleValue == false && row.ISGROUPBY != 2" class="menu-icon dragCursor" cdkDrag (cdkDragStarted)="onDragStart(row)">menu</mat-icon>
                            <span>{{row.ITEMDATE }} <br>{{row.ITEMTIME}}</span>
                        </div>
                    </mat-cell>
                    <!-- <mat-cell style="width: 135px;" *matCellDef="let row" class="text_with_class_date text_with_disp">
                        <mat-icon  class="menu-icon dragCursor" cdkDrag (cdkDragStarted)="onDragStart(row)">menu</mat-icon> <span>{{row.ITEMDATE }} <br>
                            {{row.ITEMTIME}}</span>
                    </mat-cell>   -->
        </ng-container>
        <ng-container matColumnDef="FEEEARNER" *ngIf = "this.currentUser.PRODUCTTYPE== 'Solicitor'" >
            <mat-header-cell style="width: 65px !important;" mwlResizable (resizeEnd)="onResizeEnd($event, 'FEEEARNER')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" *matHeaderCellDef
                class="text_with_class_date">
                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                FE</mat-header-cell>
            <mat-cell style="width: 65px !important;" *matCellDef="let row" class="text_with_class_date">
                {{row.FEEEARNER }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ADDITIONALTEXT" *ngIf="router?.url =='/invoice/bulk-invoices'">
            <mat-header-cell style="width: 100px !important;" mwlResizable (resizeEnd)="onResizeEnd($event, 'ADDITIONALTEXT')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" *matHeaderCellDef
                class=" textarea_wrapper">
                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                Text</mat-header-cell>
            <mat-cell style="width: 100px !important;" *matCellDef="let row" class=" d-flex align-items-center textarea_wrapper">
                <textarea #textAreas class="your-textarea-class" [(ngModel)]="row.ADDITIONALTEXT" (ngModelChange)="getdatavalue($event,row)" spellcheck="true" style="height: 40px;" *ngIf="row?.ITEMTYPEDESC !== 'Disbursement'"> </textarea>   
                
                <div class=" d-flex align-items-center flex-column" *ngIf="row?.ITEMTYPEDESC !=='Disbursement' && row?.isShownsavecancel">
                    <mat-icon style="color:red;font-size: 20px;padding-top: 2px" (click)="Removenewchanges(row)">cancel</mat-icon> 
                    <mat-icon style="color:green;font-size: 20px;padding-top: 2px" (click)="SaveNewChangesData(row)">check_circle</mat-icon>
                </div>
                <span *ngIf="row?.ITEMTYPEDESC == 'Disbursement'" style="height: 100%;"> {{row.ADDITIONALTEXT }}</span> 
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ADDITIONALTEXT" *ngIf="router?.url !=='/invoice/bulk-invoices'">
            <mat-header-cell style="width: 130px !important;" mwlResizable (resizeEnd)="onResizeEnd($event, 'ADDITIONALTEXT')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" *matHeaderCellDef
                class="text_with_class">
                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                Text</mat-header-cell>
            <mat-cell style="width: 130px !important;" *matCellDef="let row" class="text_with_class d-flex align-items-center"> 
                <span style="height: 100%;"> {{row.ADDITIONALTEXT }}</span> 
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="RATE">
            <mat-header-cell style="width: 90px !important;" mwlResizable  (resizeEnd)="onResizeEnd($event, 'RATE')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" class="text_with_Exgst"
                *matHeaderCellDef>
                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                                        Rate</mat-header-cell>
            <mat-cell style="width: 90px !important;" *matCellDef="let row">
                {{row.RATEDESC}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="QTY" *ngIf="router?.url =='/invoice/bulk-invoices'">
            <mat-header-cell style="width: 100px;" class="text_with_Exgst" *matHeaderCellDef>
                 <div class="resize-handle-right"></div> Quantity</mat-header-cell>
                 <mat-cell style="width: 90px;" *matCellDef="let row">
                {{row.FORMATTEDQUANTITY}}
            </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="QTY" *ngIf="router?.url =='/invoice/bulk-invoices'">
            <mat-header-cell style="width: 120px !important;" class="text_with_Exgst" *matHeaderCellDef>
                <div class="resize-handle-right"></div>
                 Quantity</mat-header-cell>
                 <mat-cell style="width: 120px !important; position: relative;" *matCellDef="let row; let i = index">
                    <div *ngIf="!isEditing(i, 'quantity')" class="cell-content">
                        <span>{{ row.FORMATTEDQUANTITY }}</span>
                        <button mat-icon-button class="edit-btn" (click)="startEdit(i, 'quantity')">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>  
                    <div *ngIf="isEditing(i, 'quantity')" class="editable-cell">
                        <input type="text" style="height: 40px;" [(ngModel)]="row.QUANTITY" class="edit-input" />
                        <div class="d-flex align-items-center flex-column">
                            <mat-icon style="color:red;font-size: 20px;padding-top: 2px" (click)="cancelEdit()">cancel</mat-icon> 
                            <mat-icon style="color:green;font-size: 20px;padding-top: 2px" (click)="saveEdit(i, row)">check_circle</mat-icon>
                        </div>
                    </div>
                </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="PRICE">
            <mat-header-cell style="width: 90px;" mwlResizable  (resizeEnd)="onResizeEnd($event, 'PRICE')" mwlResizeHandle [resizeEdges]="{ right: true }" class="text_with_Exgst" *matHeaderCellDef>
                <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
                Price (Ex)</mat-header-cell>
            <mat-cell style="width: 90px;" *matCellDef="let row">
                {{row.PRICE | toFixedAmount:true}}
            </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="PRICE">
            <mat-header-cell style="width: 120px !important;" mwlResizable  (resizeEnd)="onResizeEnd($event, 'PRICE')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" class="text_with_Exgst"
                *matHeaderCellDef>
                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                Price (Ex)</mat-header-cell>
            <mat-cell style="width: 90px;" *matCellDef="let row">
                {{row?.PRICE | toFixedAmount:true}}
            </mat-cell>
        </ng-container>
                <mat-cell style="width: 120px !important; position: relative;" *matCellDef="let row; let i = index">
                    <div *ngIf="!(isEditing(i, 'price'))" class="cell-content">
                        <span>{{ row.PRICE | toFixedAmount:true }}</span>
                        <button mat-icon-button class="edit-btn" (click)="startEdit(i, 'price')">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="isEditing(i, 'price')" class="editable-cell">
                        <input type="text" (keypress)="numberOnly($event)" style="height: 40px;" [(ngModel)]="row.PRICE" class="edit-input" />
                        <div class="d-flex align-items-center flex-column">
                            <mat-icon style="color:red;font-size: 20px;padding-top: 2px" (click)="cancelEdit()">cancel</mat-icon> 
                            <mat-icon style="color:green;font-size: 20px;padding-top: 2px" (click)="saveEdit(i, row)">check_circle</mat-icon>
                        </div>
                    </div>
                </mat-cell>
        </ng-container> -->


        <ng-container matColumnDef="GST">
            <mat-header-cell style="width: 90px !important;" mwlResizable  (resizeEnd)="onResizeEnd($event, 'GST')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" *matHeaderCellDef>

                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                GST
            </mat-header-cell>
            <mat-cell style="width: 90px !important;" *matCellDef="let row">
                {{row.GST | toFixedAmount:true}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="PRICEINCGST">
            <mat-header-cell style="width: 100px !important;" mwlResizable (resizeEnd)="onResizeEnd($event, 'PRICEINCGST')"
            mwlResizeHandle
            [resizeEdges]="{ right: true }" *matHeaderCellDef>
                <div class="resize-handle-right" mwlResizeHandle
                                        [resizeEdges]="{ right: true }"></div>
                Price</mat-header-cell>
            <mat-cell style="width: 100px !important;" *matCellDef="let row">
                {{row.PRICEINCGST | toFixedAmount:true}}
            </mat-cell>
        </ng-container>
        <mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumnsTime; sticky: true">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTime;" (click)="highlightedRows=row.WORKITEMGUID; $event ? selection.toggle(row) : null;retuenData();" matRipple [@animate]="{value:'*',params:{y:'100%'}}" [style.background]="highlightedRows == row.WORKITEMGUID ? selectedColore : ''"
            [ngClass]="highlightedRows == row.WORKITEMGUID ? 'row-text-colore' : ''">
        </mat-row>
        <!-- <mat-row *matRowDef="let row; columns: displayedColumnsTime;" (click)="$event ? selection.toggle(row) : null;retuenData();" matRipple
            [@animate]="{value:'*',params:{y:'100%'}}">
        </mat-row> -->
        <tr mat-header-row matColumnDef="groupHeader" class="headingtr-block">
            <th mat-header-cell class="special-group-header" colspan="100%" *matCellDef="let group">
                <!-- <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                <mat-icon *ngIf="!group.expanded">expand_more</mat-icon> -->
                <ng-container *ngIf="!group.DELIVERABLEID">
                    <strong style="margin-left: 25px; color: rgba(0, 0, 0, 0.87);">Item Type: {{group.ITEMTYPEDESC}}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Price(Ex): {{group?.PRICE | currency}}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Price:
                        {{group?.PRICEINCGST | currency}}
                        <!-- &nbsp;&nbsp;&nbsp;&nbsp;
                        Price(Ex): {{tempTotalData[group.ITEMTYPEDESC]?.PRICE |currency}}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Price:
                        {{tempTotalData[group.ITEMTYPEDESC]?.PRICEINCGST |currency}} -->
                    </strong>
                </ng-container>
                <ng-container *ngIf="group.DELIVERABLEID">
                    <strong style="margin-left: 75px; color: rgba(0, 0, 0, 0.87);" >Deliverable: {{group.ITEMTYPEDESC}}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Price(Ex): {{group?.PRICE | currency}}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Price:
                        {{group?.PRICEINCGST | currency}}
                    </strong>
                </ng-container>
            </th>
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when:isGroup">
        </tr>
    </table>
</div>