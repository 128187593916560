import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    ChangeDetectorRef,
    HostListener,
    Renderer2,
    AfterViewInit,
} from "@angular/core";
import { fuseAnimations } from "src/@fuse/animations";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
// import { MatterDialogComponent } from '../time-entries/matter-dialog/matter-dialog.component';
// import { ContactSelectDialogComponent } from '../contact/contact-select-dialog/contact-select-dialog.component';
import * as $ from "jquery";
import { ToastrService } from "ngx-toastr";
import { SortingDialogComponent } from "src/app/main/sorting-dialog/sorting-dialog.component";
import { ContactSelectDialogComponent } from "../../contact/contact-select-dialog/contact-select-dialog.component";
import { MatterDialogComponent } from "../../time-entries/matter-dialog/matter-dialog.component";
import { ResizeEvent } from "angular-resizable-element";
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, takeUntil } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
// import { MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSelect } from "@angular/material/select";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FilterSearchOptions } from "src/app/_constant/dynamic-search-option.const";
import { DatePipe } from "@angular/common";
import { HyperlinkNavigationService } from "@_services/hyperlink-navigation.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";

@Component({
    selector: "app-main-safe-custody",
    templateUrl: "./main-safe-custody.component.html",
    styleUrls: ["./main-safe-custody.component.scss"],
    animations: fuseAnimations,
})
export class MainSafeCustodyComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
    private searchMatterSubject = new Subject<any>();
    private searchClientSubject = new Subject<any>();
    currentuser = JSON.parse(localStorage.getItem("currentUser"));
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    highlightedRows: any;
    MainSafeCustody: FormGroup;
    ColumnsObj = [];
    pageSize: any;
    isLoadingResults: boolean = false;
    tempColobj: any;
    displayedColumns: any;
    addData: any = [];
    MainSafeCustodyData: any = [];
    ImgDisAb: any;
    dateColFilter = [];
    isDisplay: boolean = false;
    sortingDefaultState: any = {};
    sortactive: any;
    sortDirection: any;
    IsSafeCustodyList: boolean = false;
    pushRowData: any = JSON.parse(
        localStorage.getItem("stored_safe_custody_data")
    );
    filterData: {
        STATUS: any;
        MATTERGUID: any;
        Matter: any;
        Contactguid: any;
        Contact: any;
        Search: string;
        ITEMDATESTART: any;
        ITEMDATEEND: any;
    };
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    isSearchStringFilter: boolean;
    windowNameId: any;

    searchMatter: Subscription;
    exportdatavalue: any;
    searchData: any;
    matterlist: Observable<string[]>;
    mattername: any = [];
    selectedData: any = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    hide: any = false;
    isGetallData: any = false;
    isCallDownloadFunction: boolean = false;
    @ViewChild("MatterInput1", { static: false })
    MatterInput: ElementRef<HTMLInputElement>;
    activeFilters: any;

    @ViewChild("mainSafesearchField", { static: false })
    searchField: ElementRef<HTMLInputElement>;
    currentUserData = JSON.parse(window.localStorage.getItem("currentUser"));

    optionalDateFilterPayload = [];
    // Optional Filters
    activeFilter = FilterSearchOptions.activeFilter;
    unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter;
    activeOptionalFilter: any;
    dateFilter = FilterSearchOptions.dateFilter;
    currencyFilter = FilterSearchOptions.currencyFilter;
    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;
    dayRangeOptions = FilterSearchOptions.dayRangeOptions;

    FILTERS: FormArray;
    FILTERSLIST: FormArray;
    _filterList = [];
    optionalFilterList = [];
    addFilter: any;
    showFiltersList: boolean = true;
    selectedFilterIs: any;
    optionalFilters = [];
    daterangedata: MatDatepickerInputEvent<Date>;
    forHideShowDateRangePicker: Boolean = false;
    hasDateRange: boolean = false;
    saveAdvanceFilter: any = [];
    totalApplicableFilterCount: number = 0;
    hyperLinkInfo: any;
    Object: any;
    COLOR: any;
    HyperlinkColor: any;
    resizableMousemove: () => void;
    resizableMouseup: () => void;
    class: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;
    sub2: Subscription;
    gloableAdvancedFilters: any;
    GloableadvanceFilterPayload: any;

    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private dialog: MatDialog,
        private TableColumnsService: TableColumnsService,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        private _formBuilder: FormBuilder,
        private SortingbehaviorService: SortingBehaviourService,
        private cd: ChangeDetectorRef,
        private _hyperlink: HyperlinkNavigationService,
        private renderer: Renderer2,
        public datepipe: DatePipe
    ) {
        this.Object = Object;
        const materIDofTab = window.name.split("_");
        this.windowNameId =
            materIDofTab && materIDofTab[1] ? materIDofTab[1] : undefined;
        localStorage.setItem("istrackid", "MainSafeCustodyComponent");
        this.SortingbehaviorService.safeCustodyScreenSorting$
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((result) => {
                if (result) {
                    this.sortingDefaultState = result;
                    localStorage.setItem(
                        "safe_custody_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingDefaultState = JSON.parse(
                        localStorage.getItem("safe_custody_screen_sorting")
                    );
                }
            });
        this.SortingbehaviorService.MainSafeCustodyFilterData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                this.filterData = result;
                delete this.filterData["ENDDATE"];
                delete this.filterData["STARTDATE"];
                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(this.filterData)
                );
            }
        });

        this.behaviorService.APIretryCall$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (
                localStorage.getItem("istrackid") ==
                "MainSafeCustodyComponent" &&
                result["click"] &&
                result["data"]["safe-custody"]
            ) {
                if (!localStorage.getItem("mainsafecustody_list_columns")) {
                    this.getTableFilter();
                }
                this.LoadData(
                    JSON.parse(localStorage.getItem("mainsafecustody_filter"))
                );
            }
        });

        this._hyperlink.setColorSetting();
        this._hyperlink.setColorSettingWithHyperlink();
        this.behaviorService.ColorSystemSetting$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((data) => {
            this.COLOR = data;
        });

        this.behaviorService.ColorSystemSettingHyperLink$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((data) => {
            this.HyperlinkColor = data;
        });

        this.behaviorService.loaderDATA$.subscribe(data=>{
            this.isLoadingResults=data;
        })
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.permissionCheckforHeight$
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((data) => {
                this.class = data;
            });

        this.activeFilters = {};
        this.addFilter = {
            OPERATION: "",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: "",
        };

        if (this.currentuser && this.currentuser.SORTBYSURNAME) {
            this.sortactive = "CONTACTNAME";
            this.sortDirection = "asc";
        } else {
            this.sortactive = "SHORTNAME";
            this.sortDirection = "desc";
        }
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "";
            this.sortDirection = "";
        }
        this.ImgDisAb = "disabled-click-cursor";
        $(".example-containerdata").css(
            "height",
            $(window).height() -
            ($("#tool_baar_main").height() +
                $(".sticky_search_div").height() +
                90) +
            "px"
        );
        $(window).resize(function () {
            $(".example-containerdata").css(
                "height",
                $(window).height() -
                ($("#tool_baar_main").height() +
                    $(".sticky_search_div").height() +
                    90) +
                "px"
            );
        });
        // SEARCH fast matter
        this.searchMatterSubject
            .pipe(
                debounceTime(300), // Adjust the debounce time (milliseconds) as needed
                distinctUntilChanged()
            )
            .subscribe(searchValue => {
                this.getMatterList(searchValue);
            });
        // SEARCH END fast matter

        // SEARCH fast Client
        this.searchClientSubject
            .pipe(
                debounceTime(300), // Adjust the debounce time (milliseconds) as needed
                distinctUntilChanged()
            )
            .subscribe(searchValue => {
                this.getClientList(searchValue);
            });
        // SEARCH END fast Client
        this.getTableFilter();
        this.MainSafeCustody = this._formBuilder.group({
            STATUS: [""],
            MATTERCHECK: [true],
            MATTER: [""],
            CLIENTCHECK: [true],
            CLIENT: [""],
            SEARCH: [""],
            FILTERS: new FormArray([]),
            FILTERSLIST: new FormArray([]),
            MAINFILTER: [""],
            DayRange: ["Last 90 days"],
            ITEMDATESTART: [""],
            ITEMDATEEND: [""],
            dateRang: [""],
        });
        this.filterData = {
            STATUS: "",
            MATTERGUID: "",
            Matter: "",
            Contactguid: "",
            Contact: "",
            Search: "",
            ITEMDATESTART: "",
            ITEMDATEEND: "",
        };
        if (!localStorage.getItem("mainsafecustody_filter")) {
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
        } else {
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            (this.filterData.Contactguid = ""),
                (this.filterData.MATTERGUID = ""),
                (this.filterData.Matter = ""),
                (this.filterData.Contactguid = ""),
                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(this.filterData)
                );
        }
        this.isSearchStringFilter = this.filterData.Search ? false : true;
        this.MainSafeCustody.controls["STATUS"].setValue(
            this.filterData.STATUS
        );
        
        this.MainSafeCustody.controls["SEARCH"].setValue(
            this.filterData.Search
        );
       
        if (
            this.filterData.MATTERGUID == "" ||
            this.filterData.Contactguid == ""
        ) {
            if (this.filterData.MATTERGUID == "") {
                
                this.checkMatter();
            }
            if (this.filterData.Contactguid == "") {
                this.checkClient();
            }
        }

        this.getFilter();
        this.setDefaultAdvanceFilter();
        this.forHideShowDateRangePicker = false;
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        this.TableColumnsService.setTableResize(
            this.matTableRef.nativeElement.clientWidth
        );
    }

    get f() {
        return this.MainSafeCustody.controls;
    }

    /**
     * This function is used to getbthe table filter data value
     */
    getTableFilter() {
        let mainSafeCustodyListColumns: any = JSON.parse(
            localStorage.getItem("mainsafecustody_list_columns")
        );
        if (mainSafeCustodyListColumns && mainSafeCustodyListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(
                mainSafeCustodyListColumns.ColumnsObj
            );
            this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(
                mainSafeCustodyListColumns.ColumnsObj
            );
            this.displayedColumns = mainSafeCustodyListColumns.displayedColumns;
            this.ColumnsObj = mainSafeCustodyListColumns.ColumnsObj;
            this.tempColobj = data.tempColobj;
            this.dateColFilter = data.dateCol;
            localStorage.setItem('mainsafe-custody-justification',JSON.stringify(data?.colobj));
        } else {
            this.TableColumnsService.getTableFilter(
                "Safe Custody",
                "Safe Custody"
            )
                .pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(
                    (response) => {
                        if (
                            response.CODE == 200 &&
                            response.STATUS == "success"
                        ) {
                            let data =
                                this.TableColumnsService.filtertableColum(
                                    response.DATA.RECORDS
                                );
                            this.hyperLinkInfo =
                                this.TableColumnsService.hyperLinkInfo(
                                    response.DATA.RECORDS
                                );
                            this.displayedColumns = data.showcol;
                            this.ColumnsObj = data.colobj;
                            this.tempColobj = data.tempColobj;
                            this.dateColFilter = data.dateCol;
                            localStorage.setItem('mainsafe-custody-justification',JSON.stringify(data?.colobj));
                            localStorage.setItem(
                                "mainsafecustody_list_columns",
                                JSON.stringify({
                                    ColumnsObj: data.colobj,
                                    displayedColumns: data.showcol,
                                })
                            );
                        }
                    },
                    (error) => {
                        this.toastr.error(error);
                    }
                );
        }
    }

    
    /**
     * This function is used to onResizig the data
     */
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(
                "mat-column-" + columnName
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(
                (col) => col.COLUMNID === columnName
            );
            this.ColumnsObj[indexData]["WIDTH"] = event.rectangle.width;
            localStorage.setItem(
                "mainsafecustody_list_columns",
                JSON.stringify({
                    ColumnsObj: this.ColumnsObj,
                    displayedColumns: this.displayedColumns,
                })
            );
        }
    }

    /**
     * This function is used to onResize the End
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        this.TableColumnsService.SaveWidthData(
            this.ColumnsObj,
            "Safe Custody",
            "Safe Custody"
        );
    }
    //   // Resize table column. also make some change in html side put below function with 'mwlResizable'.
    //   onResizeColumnSetting(event , index){
    //     //event : selected column.
    //     //index : selected column index.
    //     //renderer : dont make this comman it's give issue import in your component.
    //     //displayedColumns : displayedColumns name's (Table Header names).
    //     //matTableRef : most importent please import in component wise don't make it comman.
    //     // last field is localstorage name which use to store columnObj

    //     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'mainsafecustody_list_columns');
    //   };

    //For resize this fuction is most impotent don't make it comman it's give issue in future.
    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.TableColumnsService.setTableResize(
            this.matTableRef.nativeElement.clientWidth
        );
    }

    /**
     * This function is used to set the default width data value.
     */
    setDefaultWidth(displayedColumns, timeout) {
        setTimeout(() => {
            displayedColumns.forEach((element) => {
                let temWidth = this.tempColobj[element]["WIDTH"];
                const cssValue = temWidth + "px";
                const columnElts = document.getElementsByClassName(
                    "mat-column-" + element
                );
                for (let i = 0; i < columnElts.length; i++) {
                    const currentEl = columnElts[i] as HTMLDivElement;
                    currentEl.style.visibility = "inherit";
                    currentEl.style.width = cssValue;
                }
            });
        }, timeout);
    }

    /**
     * This function is used to on pagination change
     */
    onPaginateChange(event) {
        this.setDefaultWidth(this.displayedColumns, 0);
        this.pageSize.main_safe_custoday = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to load the data value
     */
    LoadData(data) {
        this.SortingbehaviorService.SetMainSafeCustodyFilterData(data);
        // for optional filter
        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.MainSafeCustody.value.FILTERS)
        );
        const ArrayResetOptionalNew = ArrayResetOptional.map((data1, index) => {
            if (this.optionalFilters[index]) {
                data1.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data1;
        });

        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }
                return e;
            }
        });

        // resetFilter.FILTERS = finalPayload;
        data.AdvancedFilters = finalPayload;

        //Advance Filter Payload Logic.
        const advanceFilterPayload =
            this.TableColumnsService.loadAdvanceFilterPayload("SafeCustody");
        data.AdvancedFilters =
            advanceFilterPayload && advanceFilterPayload.length !== 0
                ? advanceFilterPayload
                : [];

        if (data.AdvancedFilters.length == 0) {
            delete data.AdvancedFilters;
            localStorage.removeItem("SafeCustody_Advance_Filter");
        }
        // ends here ~ for optional filter

        const finalBasicPayload = JSON.parse(JSON.stringify(data));
        // delete finalBasicPayload.Contact;
        // delete finalBasicPayload.Contactguid;
        // delete finalBasicPayload.Matter;
        // delete finalBasicPayload.MATTERGUID;

        this.isLoadingResults = true;
        const AdvancedFilters = finalBasicPayload.AdvancedFilters
            ? finalBasicPayload.AdvancedFilters
            : [];
        let Payload: any = {
            Action: "GetData",
            Filters: finalBasicPayload,
            AdvancedFilters: AdvancedFilters,
        };

        this.gloableAdvancedFilters=finalBasicPayload,
        this.GloableadvanceFilterPayload=AdvancedFilters
        if (AdvancedFilters.length == 0) {
            delete Payload.AdvancedFilters;
        }
        delete Payload.Filters.AdvancedFilters;
        this._mainAPiServiceService
            .getSetData(Payload, "safe-custody")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.MainSafeCustodyData = new MatTableDataSource(
                            res.DATA.RECORDS
                        );
                        this.MainSafeCustodyData.paginator = this.paginator;
                        this.behaviorService.SafeCustody(null);
                        if (res.DATA.RECORDS[0]) {
                            this.isDisplay = false;
                            this.editsafecustody(res.DATA.RECORDS[0]);
                            this.highlightedRows =
                                res.DATA.RECORDS[0].SAFECUSTODYGUID;
                        } else {
                            this.isDisplay = true;
                        }
                        this.isLoadingResults = false;
                        this.sortingDate();
                        this.setDefaultWidth(this.displayedColumns, 500);
                        if (this.isCallDownloadFunction == true) {
                            setTimeout(() => {
                                this.downloadFile();
                                this.isCallDownloadFunction = false;
                            }, 1000);
                        }
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * This function is used to select the status
     */
    selectStatus(val) {
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        this.filterData.STATUS = val;
        localStorage.setItem(
            "mainsafecustody_filter",
            JSON.stringify(this.filterData)
        );
        this.LoadData(this.filterData);
    }

    /**
     * This function is used to MatterCheck
     */
    MatterChecxed() {
        if (this.f.MATTERCHECK.value == true) {
            this.ImgDisAb = "disabled-click-cursor";
            //this.MainSafeCustody.controls["MATTER"].disable();
            this.MainSafeCustody.controls["MATTER"].setValue("");
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            this.filterData.MATTERGUID = "";
            this.filterData.Matter = "";
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.LoadData(this.filterData);
        } else {
            this.ImgDisAb = "";
            this.MainSafeCustody.controls["MATTER"].enable();
            const dialogRef = this.dialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                localStorage.setItem("istrackid", "MainSafeCustodyComponent");
                if (result != false) {
                    if (result) {
                        const materIDofTab = window.name.split("_");
                        const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                        localStorage.setItem(
                            windowNameId
                                ? windowNameId
                                : "set_active_matters",
                            JSON.stringify(result)
                        );
                        this.MainSafeCustody.controls["MATTER"].setValue(
                            result.MATTER
                        );
                        this.filterData = JSON.parse(
                            localStorage.getItem("mainsafecustody_filter")
                        );
                        this.filterData.MATTERGUID = result.MATTERGUID;
                        this.filterData.Matter = result.MATTER;
                        localStorage.setItem(
                            "mainsafecustody_filter",
                            JSON.stringify(this.filterData)
                        );
                        this.LoadData(this.filterData);
                    } else if (this.f.MATTER.value == "") {
                        this.MainSafeCustody.controls["MATTERCHECK"].setValue(
                            true
                        );
                    }
                } else {
                    this.ImgDisAb = "disabled-click-cursor";
                    this.MainSafeCustody.controls["MATTERCHECK"].setValue(true);
                    //this.MainSafeCustody.controls["MATTER"].disable();
                }
            });
        }
    }

    /**
     * This function is used to Select the Matter
     */
    SelectMatter() {
        this.searchData = [];
        this.matterlist = this.searchData;

        const dialogRef = this.dialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem("istrackid", "MainSafeCustodyComponent");
            if (result) {
                this.MainSafeCustody.controls["MATTER"].setValue(result.MATTER);
                this.filterData = JSON.parse(
                    localStorage.getItem("mainsafecustody_filter")
                );
                this.filterData.MATTERGUID = result.MATTERGUID;
                this.filterData.Matter = result.MATTER;
                this.mattername = [result.SHORTNAME + "--" + result.MATTER];
                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(this.filterData)
                );
                this.LoadData(this.filterData);
            }
        });
    }

    /**
     * This function is used to contact checked
     */
    ContactChecxed() {
        if (this.f.CLIENTCHECK.value == true) {
            this.ImgDisAb = "disabled-click-cursor";
            this.MainSafeCustody.controls["CLIENT"].setValue("");
            //this.MainSafeCustody.controls["CLIENT"].disable();
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            this.filterData.Contactguid = "";
            this.filterData.Contact = "";
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.LoadData(this.filterData);
        } else {
            this.ImgDisAb = "";
            this.MainSafeCustody.controls["CLIENT"].enable();
            const dialogRef = this.dialog.open(ContactSelectDialogComponent, {
                width: "100%",
                disableClose: true,
                data: "",
            });
            dialogRef.afterClosed().subscribe((result) => {
                localStorage.setItem("istrackid", "MainSafeCustodyComponent");
                if (result != false) {
                    if (result) {
                        localStorage.setItem(
                            "contact_active",
                            JSON.stringify(result)
                        );
                        this.MainSafeCustody.controls["CLIENT"].setValue(
                            result.CONTACTNAME
                        );
                        this.filterData = JSON.parse(
                            localStorage.getItem("mainsafecustody_filter")
                        );
                        this.filterData.Contactguid = result.CONTACTGUID;
                        this.filterData.Contact = result.CONTACTNAME;

                        localStorage.setItem(
                            "mainsafecustody_filter",
                            JSON.stringify(this.filterData)
                        );
                        this.LoadData(this.filterData);
                    } else if (this.f.CLIENT.value == "") {
                        this.MainSafeCustody.controls["CLIENTCHECK"].setValue(
                            true
                        );
                    }
                } else {
                    this.ImgDisAb = "disabled-click-cursor";
                    this.MainSafeCustody.controls["CLIENTCHECK"].setValue(true);
                    //this.MainSafeCustody.controls["CLIENT"].disable();
                }
            });
        }
    }

    /**
     * This function is used to select the contact data value
     */
    SelectContact() {
        const dialogRef = this.dialog.open(ContactSelectDialogComponent, {
            width: "100%",
            disableClose: true,
            data: "",
        });
        dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem("istrackid", "MainSafeCustodyComponent");
            if (result) {
                this.MainSafeCustody.controls["CLIENT"].setValue(
                    result.CONTACTNAME
                );
                this.filterData = JSON.parse(
                    localStorage.getItem("mainsafecustody_filter")
                );
                this.filterData.Contactguid = result.CONTACTGUID;
                this.filterData.Contact = result.CONTACTNAME;

                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(this.filterData)
                );
                this.LoadData(this.filterData);
            }
        });
    }

    /**
     * This function is used to onsearch the data
     */
    onSearch(searchFilter: any) {
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        if (
            searchFilter["key"] === "Enter" ||
            searchFilter == "Enter" ||
            searchFilter == "RemoveSearchString"
        ) {
            if (searchFilter == "RemoveSearchString") {
                this.MainSafeCustody.controls["SEARCH"].setValue("");
            }
            this.filterData.Search = this.f.SEARCH.value;
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.isSearchStringFilter = this.filterData.Search ? false : true;
            this.LoadData(this.filterData);
        }
    }

    /**
     * This function is used to click the matter search
     */
    clickMatterserch_Fast(fastSer: any) {
        // Update the searchSubject when the input changes
        const searchValue = fastSer?.target.value;
        this.searchMatterSubject.next(searchValue);
    }

    /**
     * This function is used to get the matter list
     */
    getMatterList(searchValue: any) {
        let dataAPI = {
            FastSearch: true,
            SearchString: searchValue
        };
        let Postdata: any = { "Action": "GetData", Filters: dataAPI };
        this.sub1 = this._mainAPiServiceService.getSetData(Postdata, 'matter').subscribe(
            (response: any) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    if (response.DATA.RECORDS && response.DATA.RECORDS[0]) {
                        this.exportdatavalue = response.DATA.RECORDS;
                    } else {
                        this.exportdatavalue = [];
                    }
                } else {
                    this.toastr.error(response.MESSAGE);
                }
            },
            (err) => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            }
        );
    }

    /**
     * This function is used to MatterSekect data
     */
    MatterSelect(data: any) {

        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        let selected = this.exportdatavalue.filter((ele: any) => ele.MATTERGUID == data.MATTERGUID)
        if (selected) {
            this.MainSafeCustody.controls['MATTER'].setValue(selected[0].MATTER);
            this.filterData.MATTERGUID = data.MATTERGUID;
            this.filterData.Matter = data.MATTER;
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.LoadData(this.filterData);
        }
    }

    /**
     * This function is used to click the client search
     */
    clickClientserch_Fast(fastSer: any) {
        // Update the searchSubject when the input changes
        const searchValue = fastSer?.target.value;
        this.searchClientSubject.next(searchValue);
    }

    /**
     * This function is used to get the client list
     */
    getClientList(searchValue: any) {
        let dataAPI = {
            FastSearch: true,
            SearchString: searchValue
        };
        let Postdata: any = { "Action": "GetData", Filters: dataAPI };
        this.sub2 = this._mainAPiServiceService.getSetData(Postdata, 'contact').subscribe(
            (response: any) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    if (response.DATA.RECORDS && response.DATA.RECORDS[0]) {
                        this.exportdatavalue = response.DATA.RECORDS;
                    } else {
                        this.exportdatavalue = [];
                    }
                } else {
                    this.toastr.error(response.MESSAGE);
                }
            },
            (err) => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            }
        );
    }

    /**
     * This function is used to client select
     */
    ClientSelect(data: any) {
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        let selected = this.exportdatavalue.filter((ele: any) => ele.CONTACTGUID == data.CONTACTGUID)
        if (selected) {
            this.MainSafeCustody.controls['CLIENT'].setValue(selected[0].CONTACTNAME);
            this.filterData.Contactguid = data.CONTACTGUID;
            this.filterData.Contact = data.CONTACTNAME;
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.LoadData(this.filterData);
        }
    }

    /**
     * This function is used to edit the safe custody
     */
    editsafecustody(row) {
        this.behaviorService.SafeCustody(row);
        this.pushRowData =
            this.pushRowData && this.pushRowData != null
                ? this.pushRowData
                : [];
        const found = this.pushRowData.find(
            (element) => element.SAFECUSTODYGUID == row.SAFECUSTODYGUID
        );
        if (found == undefined) {
            this.pushRowData.push({ SAFECUSTODYGUID: row.SAFECUSTODYGUID });
            localStorage.setItem(
                "stored_safe_custody_data",
                JSON.stringify(this.pushRowData)
            );
            this.behaviorService.reduceSafeCustodyCall(row);
        }
    }

    /**
     * This function is used to sorting the date
     */
    sortingDate() {
        this.MainSafeCustodyData.sortingDataAccessor = (item, property) => {
            let FildeValue = this.dateColFilter;
            if (FildeValue.includes(property)) {
                if (item[property]) {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(
                        tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                    );
                    let newDate = new Date(Sd);
                    return newDate;
                }
                return item[property];
            } else {
                return item[property];
            }
        };
        // proper shorting for date
        this.MainSafeCustodyData.sort = this.sort;
    }

    /**
     * This function is used to sort the data
     */
    sortData(val) {
        this.SortingbehaviorService.safeCustodyScreenSorting(val);
        this.setDefaultWidth(this.displayedColumns, 0);
    }

    /**
     * This function is used to open the mat dialg config
     */
    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            data: this.ColumnsObj,
            type: "safe custody",
            list: "safe custody",
        };
        //open pop-up
        const dialogRef = this.dialog.open(
            SortingDialogComponent,
            dialogConfig
        );
        //Save button click
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.displayedColumns = result.columObj;
                this.tempColobj = result.tempColobj;
                this.ColumnsObj = result.columnameObj;
                this.dateColFilter = result.dateCol;
                localStorage.setItem('mainsafe-custody-justification',JSON.stringify(result?.columnameObj));
                localStorage.setItem(
                    "mainsafecustody_list_columns",
                    JSON.stringify({
                        displayedColumns: result.columObj,
                        ColumnsObj: result.columnameObj,
                    })
                );
                if (!result.columObj) {
                    this.MainSafeCustodyData = new MatTableDataSource([]);
                    this.isDisplay = true;
                } else {
                    this.LoadData(this.filterData);
                }
            }
        });
    }

    /**
     * This function is used to refresh the main Safe custody window
     */
    refreshmainsafecusday() {
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        this.LoadData(this.filterData);
    }
    // IStart 13-10-2020 Issue: All Contact checkbox is unchecked default(Safe Custody Issue)
   /**
    * This function is used to check the matter
    */
    checkMatter(): void {
        this.ImgDisAb = "disabled-click-cursor";
        // this.MainSafeCustody.controls["MATTER"].disable();
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        this.filterData.MATTERGUID = "";
        this.filterData.Matter = "";
        localStorage.setItem(
            "mainsafecustody_filter",
            JSON.stringify(this.filterData)
        );
    }

    /**
     * This function is used to check the client matter
     */
    checkClient(): void {
        this.ImgDisAb = "disabled-click-cursor";
        //this.MainSafeCustody.controls["CLIENT"].disable();
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        this.filterData.Contactguid = "";
        this.filterData.Contact = "";

        localStorage.setItem(
            "mainsafecustody_filter",
            JSON.stringify(this.filterData)
        );
    }
    // IStart 13-10-2020 Issue: All Contact checkbox is unchecked default(Safe Custody Issue)
    ngOnDestroy(): void {
        if (localStorage.getItem("mainsafecustody_filter")) {
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            this.filterData.Search = "";
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
        }
        this.searchMatter?.unsubscribe();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
    }

    /**
     * This fuinction i used to print the PAge
     */
    printPage() {
        window.print();
    }

    /**
     * This function is used to download the file
     */
    downloadFile(data?) {
        const sortedData = this.MainSafeCustodyData.sortData(
            this.MainSafeCustodyData.filteredData,
            this.MainSafeCustodyData.sort
        );
        this.TableColumnsService.exportDataIntoCsv(
            this.displayedColumns,
            sortedData,
            "SafeCustody_data_",
            this.tempColobj
        );
        // this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, data, "SafeCustody_data_", this.tempColobj);
    }

    selectRow(row) {
    }

    /**
     * This function is used to select the Matter Form search
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event };

        let searchobj = {
            Action: "GetData",
            Filters: {
                FastSearch: "True",
                SearchString: event,
            },
        };
        this.searchMatter = this._mainAPiServiceService
            .getSetData(searchobj, "matter")
            .subscribe((response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.exportdatavalue = response.DATA;
                    this.searchData = response.DATA.RECORDS;
                    this.matterlist = this.searchData;
                }
            });
    }

    // datashow(event) {
    //     this.exportdatavalue.RECORDS.forEach((element, i) => {
    //         if (i == 0) {
    //             let data = element;
    //             this.mattername = [data.SHORTNAME + "--" + data.MATTER];
    //             this.MainSafeCustody.controls["MATTER"].setValue(null);

    //             if (event.input) {
    //                 event.input.value = "";
    //             } else {
    //                 this.MatterInput.nativeElement.value = "";
    //             }

    //             //this.MainSafeCustody.controls['MatterGUID'].setValue(data.MATTERGUID);
    //             this.filterData = JSON.parse(
    //                 localStorage.getItem("mainsafecustody_filter")
    //             );
    //             this.filterData.MATTERGUID = data.MATTERGUID;
    //             this.filterData.Matter = data.MATTER;
    //             localStorage.setItem(
    //                 "mainsafecustody_filter",
    //                 JSON.stringify(this.filterData)
    //             );
    //             this.LoadData(this.filterData);
    //             //this.matterChange('MatterGuid', data.MATTERGUID);

    //             if (
    //                 this.selectedData.map((ele, index) =>
    //                     ele.MATTERGUID == data.MATTERGUID ? index : -1
    //                 )[0] == -1 ||
    //                 this.selectedData.length == 0
    //             ) {
    //                 this.selectedData = [data];
    //             }
    //         }
    //     });
    // }

    /**
     * This function is used to remove the matter list
     */
    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.MainSafeCustody.controls["MATTER"].setValue("");
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            this.filterData.MATTERGUID = "";
            this.filterData.Matter = "";
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.LoadData(this.filterData);
            // this.MainSafeCustody.controls['MatterGUID'].setValue('');
        }
        let sortname = fruit.split("--")[0];
        this.rm(sortname);
    }

    /**
     * this function is used to rm the matter list
     */
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (
                element.SHORTNAME.toString() ==
                sortname.replace(/^\s+|\s+$/gm, "")
            ) {
                this.selectedData.splice(index, 1);
            }
        });
    }


/**
 * This function is used to add the matter list
 */
    add(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (
                        this.mattername.indexOf(
                            value.replace(/^\s+|\s+$/gm, "")
                        ) == -1
                    ) {
                        this.mattername = [value];
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }
        //  event.chipInput!.clear();

        this.MainSafeCustody.controls["MATTER"].setValue(null);
    }

    /**
     * This function is used to select the value
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        // const array3 = this.mattername;
        // if (array3.length <1) {
        if (
            this.mattername.indexOf(
                event.option.viewValue.replace(/^\s+|\s+$/gm, "")
            ) == -1
        ) {
            // this.mattername.push(event.option.viewValue);
            this.mattername = [event.option.viewValue];
            this.MatterInput.nativeElement.value = "";
            this.MainSafeCustody.controls["MATTER"].setValue(null);
        }
        // }
    }


    /**
     * This function is used to select the matterGuid
     */
    selectedmatterguid(data: any) {
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        this.filterData.MATTERGUID = data.MATTERGUID;
        this.filterData.Matter = data.MATTER;
        localStorage.setItem(
            "mainsafecustody_filter",
            JSON.stringify(this.filterData)
        );
        this.LoadData(this.filterData);
        // this.MainSafeCustody.controls['MatterGUID'].setValue(data.MATTERGUID);
        if (
            this.selectedData.map((ele, index) =>
                ele.MATTERGUID == data.MATTERGUID ? index : -1
            )[0] == -1 ||
            this.selectedData.length == 0
        ) {
            this.selectedData = [data];
        }
    }

    /**
     * This function is used to download the all data
     */
    DownloadAllData() {
        const requestdata = {
            Contact: "",
            Contactguid: "",
            MATTERGUID: "",
            Matter: "",
            STATUS: "All",
            Search: "",
        };
        this.isGetallData = true;
        this.isCallDownloadFunction = true;
        this.LoadData(requestdata);
    }

    /**
     * This function is used to set the focus
     */
    setFocus(fieldRefVar) {
        this.searchField.nativeElement.focus();
    }

    /**
     * This function is used to Filter the config data value
     */
    Filterconfig(event: MatDatepickerInputEvent<Date>, filterBy) {
        if (filterBy == "status") {
            this.activeFilters.status = event.value;
        }

        if (filterBy == "dayRange") {
            this.activeFilters.dayRange = event.value;
        }

        if (filterBy == "DATERANGE") {
            this.daterangedata = event;
            let begin = this.datepipe.transform(
                event.value["begin"],
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(event.value["end"], "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
        }

        if (filterBy == "SearchString") {
            this.activeFilters.SearchString = this.f.SEARCH.value;
            this.filterData.Search = this.f.SEARCH.value;
        }
    }

    /**
     * This function is used to get the filter
     */
    getFilter() {
        let opFilterList = JSON.parse(
            localStorage.getItem("mainsafecustody_list_columns")
        );
        const filterData = this.TableColumnsService.getDynamicFilter(
            opFilterList,
            this.optionalFilterList,
            "mainsafecustody_list_columns"
        );
        this.refreshFilterList();
        this.defaultFilter();
    }

    /**
     * This function is used refresh the filter
     */
    refreshFilterList() {
        //Advance Filter Refresh
        this.totalApplicableFilterCount = 0;

        const advDynamicFilter = JSON.parse(
            localStorage.getItem("SafeCustody_Advance_Filter")
        );
        this.optionalFilterList.map((x) => {
            if (x.FILTERTYPE != "HIDDEN") {
                this.totalApplicableFilterCount += 1;
            }

            if (
                advDynamicFilter &&
                advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
                -1
            ) {
                x.FILTERAPPLY = true;
            }
        });
    }

    /**
     * This function is used to set the default prevent 
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * This function is used to apply the filter
     */
    appyFilters() {
        this.LoadData(this.filterData);
    }

    /**
     * This function is used to set the default filter
     */
    defaultFilter() {
        if (this.filterData) {
            this.activeFilters.status =
                this.filterData.STATUS && this.filterData.STATUS !== " "
                    ? this.filterData.STATUS
                    : "";
            this.activeFilters.SearchString = this.filterData.Search;
            this.activeFilters.dayRange = "Last 90 days";
            this.SelectDayRangeFilter("Last 90 days");
        }
    }

    /**
     * This function is used to set the defalt active filter
     */
    activeCurrentFilter(id, data?: any) {
        $("#" + id).addClass("active");
        setTimeout(() => {
            $(".mat-menu-content").addClass("active");
            $(".mat-menu-content").addClass("mat-menu-content_height");
        }, 200);
        this.activeOptionalFilter = data;
        this.MainSafeCustody.controls["MAINFILTER"].reset();
    }

    /**
     * This function is used to back the filter
     */
    backToFilter(id) {
        $("#" + id).removeClass("active");
        setTimeout(() => {
            $(".mat-menu-content").removeClass("active");
            $(".mat-menu-content").removeClass("mat-menu-content_height");
        }, 50);
    }

    /**
     * This function is used to clear the filter
     */
    clearFilter(name, filterBy) {
        delete this.activeFilters[name];
        let resetFilter =
            typeof localStorage.getItem("mainsafecustody_filter") == "string"
                ? JSON.parse(localStorage.getItem("mainsafecustody_filter"))
                : localStorage.getItem("mainsafecustody_filter");
        if (filterBy == "status") {
            this.MainSafeCustody.controls["STATUS"].setValue(" ");
            resetFilter.STATUS = " ";
            this.activeFilters.status = " ";
        }

        if (filterBy == "dayRange") {
            this.MainSafeCustody.controls["DayRange"].setValue("Last 90 days");
            this.forHideShowDateRangePicker = false;
            this.activeFilters.dayRange = "Last 90 days";
            this.SelectDayRangeFilter("Last 90 days");
        }

        if (filterBy == "Date Range") {
            resetFilter.ITEMDATESTART = "";
            resetFilter.ITEMDATEEND = "";
            this.MainSafeCustody.controls["dateRang"].setValue("");
        }

        if (filterBy == "SearchString") {
            this.MainSafeCustody.controls["SEARCH"].setValue("");
            resetFilter.Search = "";
            this.activeFilters.SearchString = "";
        }
        if (filterBy == "CLIENT") {
            this.MainSafeCustody.controls["CLIENT"].setValue("");
            //this.MainSafeCustody.controls["Contactguid"].setValue("");
            resetFilter.Contact = "";
            resetFilter.Contactguid = "";
            // this.activeFilters.CLIENT = "";
        }

        if (filterBy == "MATTER") {
            this.MainSafeCustody.controls["MATTER"].setValue("");
            //this.MainSafeCustody.controls["Contactguid"].setValue("");
            resetFilter.MATTERGUID = "";
            resetFilter.Matter = "";
            //this.activeFilters.MATTER = "";
        }
        delete resetFilter.DateRange;
        delete resetFilter.FILTERINDEX;
        delete resetFilter.FILTERAPPLAY;
        this.LoadData(resetFilter);
    }

    /**
     * This function is used to clear the All filter
     */
    clearAllFilter() {
        // reset FILTERAPPLY value
        this.optionalFilterList.forEach((filter, index) => {
            filter.FILTERAPPLY = false;
        });
        // ends here ~ reset FILTERAPPLY value

        let resetFilter = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        this.optionalDateFilterPayload = [];
        this.optionalFilters = [];

        if (this.FILTERS) {
            this.FILTERS.reset();
            this.saveAdvanceFilter = [];
        }

        localStorage.removeItem("SafeCustody_Advance_Filter");
        setTimeout(() => {
            // resetFilter.ITEMDATESTART = "";
            // resetFilter.ITEMDATEEND = "";

            let currentDate = new Date();
            let updatecurrentDate = new Date();
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            resetFilter.ITEMDATESTART = begin;
            resetFilter.ITEMDATEEND = end;
            resetFilter.STATUS = "";
            resetFilter.Search = "";
            this.MainSafeCustody.controls["DayRange"].setValue("Last 90 days");
            this.forHideShowDateRangePicker = false;
            this.activeFilters.dayRange = "Last 90 days";

            delete resetFilter.FILTERS;
            delete resetFilter.AdvancedFilters;

            // this.DateType = 'Incurred Date';
            this.MainSafeCustody.controls["STATUS"].setValue("");
            this.MainSafeCustody.controls["SEARCH"].setValue("");
            // this.MainSafeCustody.controls['DateRange'].reset();
            // this.MainSafeCustody.controls['searchFilter'].reset();

            delete resetFilter.DateType;
            this.LoadData(resetFilter);
        }, 200);
    }

    @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;

    /**
     * This function is used to add the New filter
     */
    addNewFilter(event) {
        setTimeout(() => {
            this.operationFilter.options.first.select();
        }, 200);

        this.addFilter.VALUE = "";
        this.addFilter.VALUE2 = "";

        if (this.FILTERSLIST) {
            this.FILTERSLIST.controls.splice(0, 1);
            //this.FILTERSLIST.reset();
        }

        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        this._filterList = [event];
        this.addFilter.FILTERTYPE = event.FILTERTYPE;
        this.addFilter.VALUE = "";
        this.addFilter.VALUE2 = "";

        // OPTIONAL FILTER
        this.FILTERSLIST = this.MainSafeCustody.get("FILTERSLIST") as FormArray;
        this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

        this.addFilter.OPERATION =
            event.FILTERTYPE == "DATE"
                ? "is in the last"
                : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
                    ? "is equal to"
                    : "contains";
        this.cd.detectChanges();
    }

   /**
    * This function is used to create the OPFilter
    */
    createOPFilter(type): FormGroup {
        return this._formBuilder.group({
            OPERATION:
                type == "DATE"
                    ? "is in the last"
                    : type == "CURRENCY" || type == "NUMBER"
                        ? "is equal to"
                        : "contains",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: type,
        });
    }

    /**
     * This function is used to add the optional filter data value
     */
    optionalDateFilter(event, filterIs, val, index) {
        filterIs.selectedFilter = event.value;

        if (index != -1) {
            this.MainSafeCustody.controls.FILTERS["controls"][index].controls[
                "VALUE"
            ].reset();
            this.MainSafeCustody.controls.FILTERS["controls"][index].controls[
                "VALUE2"
            ].reset();
        }

        this.addFilter["VALUE"] = "";
        this.addFilter["VALUE2"] = "";
    }

    /**
     * This function is used to add the filter data value
     */
    async addfilter(IsfilterData, oprationalFilter, evt) {
        // alert(" >> value changed >> ")

        if (
            !this.TableColumnsService.checkValidation(
                this.addFilter.VALUE,
                this.addFilter.VALUE2,
                this.addFilter.OPERATION,
                IsfilterData.FILTERTYPE
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
        await this.addDynamicFilter(IsfilterData);

        let sIndex = this.optionalFilters.length - 1;
        this.cd.detectChanges();
        setTimeout(() => {
            this.MainSafeCustody.controls.FILTERS["controls"][sIndex].controls[
                "OPERATION"
            ].setValue(this.addFilter.OPERATION);
            this.MainSafeCustody.controls.FILTERS["controls"][sIndex].controls[
                "VALUE"
            ].setValue(this.addFilter.VALUE);
            this.MainSafeCustody.controls.FILTERS["controls"][sIndex].controls[
                "VALUE2"
            ].setValue(this.addFilter.VALUE2);
            this.MainSafeCustody.controls.FILTERS["controls"][sIndex].controls[
                "FILTERTYPE"
            ].setValue(IsfilterData.FILTERTYPE);
        }, 500);
        this.MainSafeCustody.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
        this.MainSafeCustody.value.FILTERS[sIndex].VALUE2 =
            this.addFilter.VALUE2;
        this.MainSafeCustody.value.FILTERS[sIndex].OPERATION =
            this.addFilter.OPERATION;
        this.MainSafeCustody.value.FILTERS[sIndex].FILTERTYPE =
            IsfilterData.FILTERTYPE;

        // Advance Filter.
        IsfilterData.AdvancedFilter =
            this.MainSafeCustody.value.FILTERS[sIndex];
        let advFilters = JSON.parse(
            localStorage.getItem("SafeCustody_Advance_Filter")
        );
        if (advFilters) {
            this.saveAdvanceFilter = advFilters;
        }

        this.saveAdvanceFilter.push(IsfilterData);
        localStorage.setItem(
            "SafeCustody_Advance_Filter",
            JSON.stringify(this.saveAdvanceFilter)
        );

        if (this.FILTERS.length == 1) {
            this.MainSafeCustody.patchValue({
                FILTERS: [
                    {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    },
                ],
            });
        } else {
            this.FILTERS.value.forEach((filterData, index) => {
                if (sIndex == index) {
                    filterData = {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    };
                }
            });

            this.MainSafeCustody.patchValue({
                FILTERS: this.FILTERS.value,
            });
        }

        //After getting data appy filter.
        this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

        $(".example-containerdata").css(
            "height",
            $(window).height() -
            ($("#filter-form-menu-wrap").height() + 260) +
            "px"
        );
    }

    /**
     * This function is used to add the dynamic filter
     */
    addDynamicFilter(event, type?) {
        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        event.FILTERAPPLY = true; //for filter hide/show from optional filter list.

        if (!type) {
            this.optionalFilters.push(event);
        }
        // OPTIONAL FILTER
        this.FILTERS = this.MainSafeCustody.get("FILTERS") as FormArray;
        this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));
    }

    /**
     * This function is used to apply the optional filter
     */
    applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any) {
        // new code for validation
        let saveAdvancefilterData = JSON.parse(
            localStorage.getItem("SafeCustody_Advance_Filter")
        );
        const field1Val =
            this.MainSafeCustody.controls.FILTERS["controls"][isIndex].controls[
                "VALUE"
            ].value;
        const field2Val =
            this.MainSafeCustody.controls.FILTERS["controls"][isIndex].controls[
                "VALUE2"
            ].value;
        const operationVal =
            this.MainSafeCustody.controls.FILTERS["controls"][isIndex].controls[
                "OPERATION"
            ].value;
        const filterTypeVal =
            this.MainSafeCustody.controls.FILTERS["controls"][isIndex].controls[
                "FILTERTYPE"
            ].value;

        if (
            !this.TableColumnsService.checkValidation(
                field1Val,
                field2Val,
                operationVal,
                filterTypeVal
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }

        // ends here ~ new code for validation

        //addData.selectedFilter = OpretionIs.triggerValue;
        let addData = JSON.parse(JSON.stringify(FilterData));
        addData.selectedFilter = OpretionIs.triggerValue;
        const applyFilterData = this.TableColumnsService.Optionalfilter(
            addData,
            isIndex,
            this.optionalDateFilterPayload,
            this.MainSafeCustody
        );
        this.optionalDateFilterPayload = applyFilterData;

        // Advance Dynamic filter when it's change any data.
        this.TableColumnsService.setAdvanceFilter(
            saveAdvancefilterData,
            this.saveAdvanceFilter,
            isIndex,
            FilterData,
            field1Val,
            field2Val,
            operationVal,
            filterTypeVal,
            applyFilterData,
            "SafeCustody"
        );
        this.doFilter(this.optionalDateFilterPayload);
    }

    /**
     * This function is used to reset the optional filter 
     */
    resetOptionalFilter(event, type, isindex) {
        let index = this.optionalFilters.findIndex(
            (e) => e.COLUMNID == event.COLUMNID
        );
        setTimeout(() => {
            this.optionalFilterList.map((x) => {
                if (
                    this.optionalFilters.findIndex(
                        (e) => x.COLUMNID == event.COLUMNID
                    ) !== -1
                ) {
                    x.FILTERAPPLY = false;
                }
            });
        }, 100);
        event.FILTERAPPLY = false; //for filter hide/show from optional filter list.

        this.optionalFilters.splice(index, 1);
        this.optionalDateFilterPayload.splice(isindex, 1);
        this.FILTERS.controls.splice(isindex, 1);
        this.MainSafeCustody.value.FILTERS.splice(isindex, 1);
        this.saveAdvanceFilter.splice(isindex, 1);

        //Advance Filter.
        this.TableColumnsService.refrshAdvanceFilter(event, "SafeCustody");

        //Delete Filters
        //const resetFilter = JSON.parse(localStorage.getItem('matter_filter'));
        const resetFilter = JSON.parse(JSON.stringify(this.filterData));
        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.MainSafeCustody.value.FILTERS)
        );
        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });

        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;

                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }
                //e.COLUMNNAME = event.COLUMNNAME;
                return e;
            }
        });

        // resetFilter.FILTERS = finalPayload;
        resetFilter.AdvancedFilters = finalPayload;
        if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
            // delete resetFilter.FILTERS;
            delete resetFilter.AdvancedFilters;
        }

        this.LoadData(resetFilter);
    }

    /**
     * This function is used to do the filter
     */
    doFilter(mainFilter: any) {
        let payload = JSON.parse(JSON.stringify(this.filterData));
        // payload.FILTERS = this.TableColumnsService.optionalDOFilters(mainFilter , this.activeFilters);
        payload.AdvancedFilters = this.TableColumnsService.optionalDOFilters(
            mainFilter,
            this.activeFilters
        );
        this.LoadData(payload);
    }

    /**
     * This function is used to select the day range filter
     */
    SelectDayRangeFilter(val) {
        localStorage.setItem("Date_Flag", JSON.stringify(val));
        this.filterData = JSON.parse(
            localStorage.getItem("mainsafecustody_filter")
        );
        let currentDate = new Date();
        let updatecurrentDate = new Date();
        let begin = this.datepipe.transform(currentDate, "dd/MM/yyyy");
        if (val == "Last 7 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 7);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Today") {
            // updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: currentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Yesterday") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 1);
            // begin = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
            currentDate.setDate(currentDate.getDate() - 1);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: updatecurrentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(updatecurrentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Last 30 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Last 90 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Date Range") {
            this.forHideShowDateRangePicker = true;
            this.activeFilters.daterange = "";
            this.filterData.ITEMDATESTART = "";
            this.filterData.ITEMDATEEND = "";
            return;
        } else if (val == "All time") {
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            if (this.filterData && val) {
                this.forHideShowDateRangePicker = false;
                this.filterData.ITEMDATESTART = "";
                this.filterData.ITEMDATEEND = "";
            }
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.LoadData(this.filterData);
            // this.addOptionalFilter(this.filterData)
        } else if (val == "Last 6 months") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 180);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Last 12 months") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 365);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        } else if (val == "Last 2 years") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 730);
            this.forHideShowDateRangePicker = false;
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });

            let begin = this.datepipe.transform(
                updatecurrentDate,
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(currentDate, "dd/MM/yyyy");
            this.activeFilters.daterange = begin + "-" + end;
            this.activeFilters.inputdaterange = begin + "-" + end;
        }

        if (val !== "All time") {
            let begindata = updatecurrentDate;
            let end = currentDate;
            this.forHideShowDateRangePicker = false;
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );
            if (this.filterData && val) {
                this.filterData.ITEMDATESTART = this.datepipe.transform(
                    begindata,
                    "dd/MM/yyyy"
                );
                this.filterData.ITEMDATEEND = this.datepipe.transform(
                    end,
                    "dd/MM/yyyy"
                );
                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(this.filterData)
                );
            }
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );

            this.LoadData(this.filterData);
            // this.addOptionalFilter(this.filterData)
        }
    }

    /**
     * This function is used to apply the Date Range fiter
     */
    applayDateRangeDate() {
        let event = this.daterangedata;
        try {
            this.hasDateRange = true;
            let begin = this.datepipe.transform(
                event.value["begin"],
                "dd/MM/yyyy"
            );
            let end = this.datepipe.transform(event.value["end"], "dd/MM/yyyy");
            this.filterData = JSON.parse(
                localStorage.getItem("mainsafecustody_filter")
            );

            if (this.filterData && event.value) {
                this.filterData.ITEMDATESTART = begin;
                this.filterData.ITEMDATEEND = end;
                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(this.filterData)
                );
            } else {
                let filterVal = { ITEMDATESTART: begin, ITEMDATEEND: end };
                localStorage.setItem(
                    "mainsafecustody_filter",
                    JSON.stringify(filterVal)
                );
            }
        } catch (error) {
            this.hasDateRange = false;
            this.filterData.ITEMDATESTART = "";
            this.filterData.ITEMDATEEND = "";
            localStorage.setItem(
                "mainsafecustody_filter",
                JSON.stringify(this.filterData)
            );
            this.MainSafeCustody.controls["dateRang"].setValue({
                begin: this.filterData.ITEMDATESTART,
                end: this.filterData.ITEMDATEEND,
            });
        }
        // this.loadData(this.filterData);
        this.LoadData(this.filterData);
    }

    /**
     * This function is used to set the default advance filter
     */
    async setDefaultAdvanceFilter() {
        // Advance Filter If Already have.
        let advanceFiltes = JSON.parse(
            localStorage.getItem("SafeCustody_Advance_Filter")
        );

        if (advanceFiltes) {
            this.optionalDateFilterPayload = [];
            this.optionalFilters = advanceFiltes;
            await advanceFiltes.forEach(async (filterData, index) => {
                await this.addDynamicFilter(
                    filterData.AdvancedFilter,
                    "default"
                );

                this.FILTERS.value[index].OPERATION = await filterData
                    .AdvancedFilter.OPERATION;
                this.FILTERS.value[index].FILTERTYPE = await filterData
                    .AdvancedFilter.FILTERTYPE;
                this.FILTERS.value[index].VALUE = await filterData
                    .AdvancedFilter.VALUE;
                this.FILTERS.value[index].VALUE2 = await filterData
                    .AdvancedFilter.VALUE2;
                await this.optionalDateFilterPayload.push(
                    filterData.FilterHeadings
                );

                await this.MainSafeCustody.patchValue({
                    FILTERS: this.FILTERS.value,
                });
            });
        }
    }

    /**
     * This function is used to select the Hyper link
     */
    selectHyperLink(row, item, hyperLinkInfo: any) {
        this._hyperlink.selectHyperLink(row, item, hyperLinkInfo);
    }

          /**
 * This function is used to getNewExportData;
 */
          getNewExportData(){

            let active;
    let direction
    let contactjustification=JSON.parse(localStorage.getItem('mainsafe-custody-justification'));
    if(this.sortingDefaultState == null){
      active=this.sortactive;
      direction=this.sortDirection
    }else{
      active=this.sortingDefaultState?.active;
      direction=this.sortingDefaultState?.direction
    }
    let columnname=[];
    if(active !== undefined && direction !== undefined && active !=='' &&  direction !== ''){
      columnname=contactjustification.filter(data=>data.COLUMNID == active);
    }else{
      columnname.push({COLUMNNAME:''})
      direction = ''
    }
            this.behaviorService.loaderDATA$.next(true);
            this.TableColumnsService.getNewExportData("Safe Custody","Safe Custody","safe-custody",this.gloableAdvancedFilters,this.GloableadvanceFilterPayload,columnname[0].COLUMNNAME,direction) 
          }
}
