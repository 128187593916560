import { BehaviorService } from "@_services/Behavior.service";
import { firstValueFrom, map, Subject, takeUntil, tap } from "rxjs";
import { Component, OnInit, inject } from "@angular/core";
import { ContactDialogComponent } from "@pages/contact/contact-dialog/contact-dialog.component";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import * as $ from 'jquery';
import { GenerateDocumentPopupComponent } from "@pages/template/generate-document-popup/generate-document-popup.component";
import { MatterDialogComponentForTemplate } from "@pages/template/matter-dialog/matter-dialog.component";
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { ReCalcTimeEntriesDialogeComponent } from "@pages/time-billing/re-calc-timeEntrie-dialoge/re-calc-timeEntrie-dialoge.component";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { SearchandreplaceworkitemComponent } from "@pages/time-billing/searchandreplaceworkitem/searchandreplaceworkitem.component";
import { MoveEntryComponent } from "@pages/time-entries/move-entry/move-entry.component";
import { WriteOffTimeEntryComponent } from "@pages/time-entries/write-off-time-entry/write-off-time-entry.component";
import { TimersService } from "@_services/timers.service";
import { LogoutErrorComponent } from "src/app/main/logout-error/logout-error.component";
import { BulkProgressComponent } from "@pages/template/bulk-progress/bulk-progress.component";

@Component({
    selector: "app-bulk-invoicing-workitem",
    templateUrl: "./bulk-invoicing-workitem.component.html",
    styleUrls: ["./bulk-invoicing-workitem.component.scss"],
})
export class BulkInvoicingWorkitemComponent implements OnInit {
  appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    private _fuseSidebarService = inject(FuseSidebarService);
    private behaviourService = inject(BehaviorService);
    public dialog = inject(MatDialog);
    public currentuserdata = JSON.parse(localStorage.getItem("currentUser"));
    public toaster=inject(ToastrService)
    public _mainAPiServiceService=inject(MainAPiServiceService);
    public globalFunService=inject(GlobalFunctionsService);
    public _matDialog=inject(MatDialog);
    public toolbarServiceService=inject(ToolbarServiceService);
    public TimersServiceI=inject(TimersService);
    public MatDialog=inject(MatDialog);
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public bulkInvoiceWorkItems$ =this.behaviourService.bulkInvoiceWorkItemslist$.asObservable().pipe(map((data) => ({ invoiceBulkWorkItems: data })),tap((data)=>{console.log('DATA.....................>>>>>>',data)}));
    public bulkInvoicegetEXandINC_GST$ =this.behaviourService.getEXandINC_GST$.asObservable().pipe(map((data) => ({ invoiceBulkgetEXandINC_GST: data })),tap((data)=>{console.log('DATA.....................>>>>>>',data)}));

    public SingleMatterData$=this.behaviourService.SingleInvoiceData$.asObservable().pipe(map((data)=> ({ SingleInvoiceData:data })))
currentMatter: any;
isLoadingResults: boolean;
InvoiceGUID: any[]=[];
ContextGuidID: any=[];
errorWarningData: any = {};
CheckBoxBulkRecord: any[]=[];
filterCheckBoxBulkRecord: any;
passdata: {  };
isWIPData: string;
Checktaggingstatus: boolean;
isTaggingEnabled=false;
private _unsubscribeAll$: Subject<void> = new Subject();
 WorkInProgressData: any;
 DisEnTimeEntryToolbar: string;
RequestGuid: string;
isspiner: boolean;
isshowButtons=false;
oldIsTrackId: any;
dialogRef: MatDialogRef<LogoutErrorComponent>;
showdeleteentries: boolean=false;
dialogRefdata: MatDialogRef<BulkProgressComponent>;

   /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {

        this.behaviourService.refreshTaggedList.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            if(data && data.length >0){
                let filteritemtypedesc=data.filter(data => data.ITEMTYPEDESC == "Search");
                if(filteritemtypedesc?.length > 0){
                  this.showdeleteentries=false;
                }else{
                    this.showdeleteentries=true
                }
                this.isshowButtons=true
            }else{
                this.isshowButtons=false
            }
        })

        this.behaviourService.checktogglestatus$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            if(data !=undefined){
                this.isTaggingEnabled=data;
                this.Checktaggingstatus=data;
            }
        })

        this.behaviourService.workInProgress$
            .subscribe((result) => {
                this.WorkInProgressData = result;
                if (result) {
                    this.isWIPData = "Notempty";
                    this.DisEnTimeEntryToolbar =
                        result.INVOICEGUID == "-1" || result.INVOICEGUID == -1
                            ? "undo"
                            : "write_off";
                } else {
                    this.isWIPData = "empty";
                    this.DisEnTimeEntryToolbar = "write_off";
                }
            });

      this.behaviourService.workInProgress$
      .subscribe((result) => {
          if (result) {
              this.isWIPData = "Notempty";
          } else {
              this.isWIPData = "empty";
          }
      });

      this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
    }

    /**
     * This function is used to close the sidebar.
     * @param key -key data
     */
    close(key) {
        this._fuseSidebarService.getSidebar(key).close();
    }

    /**
     * This function is used to Open the Matter.
     */
    openMatter() {
        setTimeout(() => {
            this.behaviourService.UpdateMartterData$.next(true);
        }, 1000);
        this.behaviourService.openDifferentTab("editMatter");
    }

    /**
     * This function is used to Open the Contact Dialog.
     */
    openContactName(id) {
        let contactPopupData = { action: "edit" ,CONTACTGUID:((id && id !=null && id !=undefined && id !='') ? id : '')};
        const dialogRef = this.dialog.open(ContactDialogComponent, {
            disableClose: true,
            width: "100%",
            data: contactPopupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.data?.Data ) {
                this.currentMatter.CONTACTNAME = result.data.Data.CONTACTNAME;
                localStorage.setItem(
                    "set_active_matters",
                    JSON.stringify(this.currentMatter)
                );
            }
        });
    }

/**
 * Save the multiple invoice one by one format
 */
async SaveInvoice(data?:any,value?,SingleData?) {
    this.isLoadingResults=true
    const SingleValue=SingleData?.SingleInvoiceData;
    const matterItem = value?.invoiceBulkWorkItems;
    let PostData;
           PostData = {
            INVOICECODE: '',
            MATTERGUID: matterItem.MATTERGUID,
            INVOICEDATE: SingleValue.INVOICEDATE,
            DUEDATE: SingleValue.DUEDATE,
            PRINTEDDATE: '',
            INVOICETOTAL: matterItem.UNBILLEDVALUEEXGST,
            INVOICETOTALINCGST: matterItem.UNBILLEDVALUEINCGST,
            GST: (matterItem?.UNBILLEDVALUEINCGST - matterItem?.UNBILLEDVALUEEXGST),
            FOREIGNCURRENCYID: '',
            COMMENT: '',
            ADDITIONALTEXT: '',
            BULKINVOICING:true,
            INVOICEUPTO:SingleValue.FILLUPTO
        };
        
  
      let PostInvoiceEntryData: any = { FormAction:'invoiceall', VALIDATEONLY: true, Data: PostData };
  
      this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(async res => {
  
          PostInvoiceEntryData = { FormAction:'invoiceall', VALIDATEONLY: true, Data: PostData };
          if (res.CODE == 200 && res.STATUS == 'success') {
           await   this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData,data,SingleValue);
          } else if (res.CODE == 451 && res.STATUS == 'warning') {
            await  this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData,data,SingleValue);
              this.isLoadingResults = false;
             
          } else if ((res.CODE=="409" || res.CODE=="450" || res.CODE == 450) && res.STATUS == 'error') {
            await  this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData,data,SingleValue);
              this.isLoadingResults = false;
          } else if (res.MESSAGE == 'Not logged in') {
            this.isLoadingResults = false;
          }
        
          
      }, err => {
       
          this.isLoadingResults = false;
          this.toaster.error(err);
      });
    this.isLoadingResults=false
    
  }
  
  /**
   * This function is used to check the validation.
   * @param bodyData -validation data
   * @param PostInvoiceEntryData -invoice data
   * @returns 
   */
   async checkValidation(bodyData: any, PostInvoiceEntryData: any,Type,SingleValue): Promise<void> {
      await this.globalFunService.checkValidation(bodyData, PostInvoiceEntryData)
      .subscribe(async data => {
          if (data) {
              this.errorWarningData = data.errorWarningData;
              if (data.callback) {
                this.isLoadingResults = false;
                 await this.saveInvoice(PostInvoiceEntryData,Type,SingleValue);
                 
              } else {
                  this.isLoadingResults = false;
                  
              }
          }
      });
   
  }
  
  /**
   * This function is used to save the invoice with validation false
   * @param PostInvoiceEntryData -invoice data
   * @returns 
   */
  async saveInvoice(PostInvoiceEntryData: any,Type,SingleValue):Promise<void> {
    PostInvoiceEntryData.VALIDATEONLY = false;
    this.isLoadingResults = true;
    await  this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(async res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          this.InvoiceGUID.push({ContextGuid:res.DATA.INVOICEGUID})
          this.ContextGuidID.push({INVOICEGUID:res.DATA.INVOICEGUID,INVOICECODE:res.DATA.INVOICECODE})
          this.behaviourService.matterInvoiceData({ INVOICEGUID: res.DATA.INVOICEGUID });
            let CreditinvoiceData = PostInvoiceEntryData.Data;
            CreditinvoiceData.INVOICEGUID = res.DATA.INVOICEGUID;
            localStorage.setItem('Create_INVOICEGUID_WorkInProgress', CreditinvoiceData.INVOICEGUID)
            CreditinvoiceData.AMOUNTOUTSTANDINGINCGST = PostInvoiceEntryData.Data.INVOICETOTALINCGST;
         this.exicuteLoop(Type,SingleValue);
         this.toaster.success(res.STATUS);
        } else {
            if (res.CODE=="409" || (res.CODE == 402 && res.STATUS == 'error' && res.MESSAGE == 'Not logged in')) {
            this.exicuteLoop(Type,SingleValue);
            }
        };
        this.isLoadingResults = false;
    },async err => {
      this.isLoadingResults = false;
        this.toaster.error(err);
        this.exicuteLoop(Type,SingleValue); 
    });   
}


/**
 * This function is deside which function we need to call
 */
exicuteLoop(Type,SingleValue){
   
      if(Type=='Create Bulk Invoice'){
        this.close('BulkInvoicingWorkItemPanel')
        $("#BulkInvoices").click();
        $('#refreshInvoiceTab').click()
        $('#Legal_Details').click();
        this.CheckBoxBulkRecord=[];
        this.behaviourService.CheckBulkInvoiceData$.next(this.CheckBoxBulkRecord);
        this.InvoiceGUID=[];
        this.ContextGuidID=[]
        localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
      }else if(Type=='Create+Generate Bulk Invoice'){
        this.passdata = {
          Context: 'Invoice',
          knownby:'Template',
          Type:'Template',
          Template: SingleValue?.INVOICETEMPLATEE,
      } 
      if(this.InvoiceGUID?.length > 0){
        this.invoicedatawip();
      }
      }else if(Type=='Create+Email Bulk Invoice'){
          if(this.ContextGuidID?.length > 0){
            this.ForEmailDialogOpen(SingleValue);
          }
      }
  }
  
  /**
 * This funtion is used to generate the email.
 */
ForEmailDialogOpen(SingleValue) {
    localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
  let  passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Email", "Type": "Email", "Folder": '', "Template": SingleValue?.Template, isMultipleDocument: true }
  
    const dialogRef = this._matDialog.open(MatterDialogComponentForTemplate, {
        width: '100%',
        disableClose: true,
        data: passdata
    });
    dialogRef.afterClosed().subscribe(result => { 
        this.close('BulkInvoicingWorkItemPanel')
          $('#refreshInvoiceTab').click()
          $('#Legal_Details').click();
          $("#BulkInvoices").click();
          this.CheckBoxBulkRecord=[];
          this.behaviourService.CheckBulkInvoiceData$.next(this.CheckBoxBulkRecord);
          this.InvoiceGUID=[];
          this.ContextGuidID=[]
          localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
    });
  }




/**
 * This function is used to generate the invoice
 */
invoicedatawip() {
    let dialogRef: any;
    dialogRef = this.dialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: { Context: 'Invoice', multiSelect:this.InvoiceGUID, multipledatainvoice: true, passdata: this.passdata } });  
     dialogRef.afterClosed().subscribe((result: any) => {
        this.close('BulkInvoicingWorkItemPanel')
           $('#refreshInvoiceTab').click()
           $('#Legal_Details').click();
           $("#BulkInvoices").click();
            this.CheckBoxBulkRecord=[];
            this.behaviourService.CheckBulkInvoiceData$.next(this.CheckBoxBulkRecord);
            this.InvoiceGUID=[];
            this.ContextGuidID=[]
            localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
           // this.getMatterDataAPI();
           // this.selection.clear()
    });
    
    }



/**
 * This function is used to Calling the preview
 * @param value -value data
 * @param SingleData -single data value
 */
callingPreview(value?,SingleData?){
    const SingleValue=SingleData?.SingleInvoiceData;
    const matterItem = value?.invoiceBulkWorkItems;
      let passdata: any = {
          Context: "previewall",
          ContextGuid: "",
          knownby: "Template",
          Type: "Template",
          data: [matterItem],
          //Template:SingleValue?.Template,
          isMultipleDocument: true,
          isBulkPreview:true,
          INVOICEDATE: SingleValue?.INVOICEDATE,
          DUEDATE: SingleValue?.DUEDATE,
          BULKINVOICING:true,
          INVOICEUPTO:SingleValue?.INVOICEUPTO,
          Template:SingleValue?.INVOICETEMPLATEE
      };
      this.ForDocDialogOpen(passdata);  
  }
  
       /**
       * This function is used to open the dock dialog data value
       * @param passdata -value data
       */
   ForDocDialogOpen(passdata) {
    const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
        width: "100%",
        disableClose: true,
        data: passdata,
    });
    dialogRef.afterClosed().subscribe((result) => {
         // $('#refreshInvoiceTab').click()
        //  $('#Legal_Details').click();
    });
  }

 /**
     * This function is used to RecalcTimeEntry data 
     */
 ReCalcTimeEntriClick() {
  this.toolbarServiceService.SetOldistrackid("open");
  const dialogRef = this._matDialog.open(
      ReCalcTimeEntriesDialogeComponent,
      {
          width: "100%",
          disableClose: true,
          data: { action: "" },
      }
  );
  dialogRef.afterClosed().subscribe((result) => {
      this.toolbarServiceService.SetOldistrackid("close");
      if (result == true) {
          this.confirmDialogRef = this._matDialog.open(
              FuseConfirmDialogComponent,
              {
                  disableClose: true,
                  width: "100%",
                  data: {
                      errorData: ["There are no Entries to change"],
                  },
              }
          );
          this.confirmDialogRef.componentInstance.confirmMessage =
              "Recalculate Time Entries";
      } else {
      }
  });
}

/**
 * This function is used to Search and replace Workitem On click
 */
searchAndReplaceWorkItemOnClick() {
    this.toolbarServiceService.SetOldistrackid("open");
    const dialogRef = this._matDialog.open(
        SearchandreplaceworkitemComponent,
        {
            width: "100%",
            disableClose: true,
            data: { action: "" },
        }
    );
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
        }
    });
}

/**
     * This function is used to update the data.
     */
ToggleForUpadte() {
    this.Checktaggingstatus = !this.Checktaggingstatus;
    this.behaviourService.checktogglestatus$.next(this.Checktaggingstatus);
    console.log(this.Checktaggingstatus);
    if(this.Checktaggingstatus == false){
        this.behaviourService.refreshTaggedList.next(null);
    }
}


/**
    * This function is ued to Move the WIP
    * @returns moveWIP data
    */

moveWIP() {
    this.toolbarServiceService.SetOldistrackid("open");
    let moveMatterSelectorData;
    this.behaviourService.refreshTaggedList.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
        if(data && data.length >0){
            moveMatterSelectorData=data;
        }
    })


    if (moveMatterSelectorData && moveMatterSelectorData.length == 0) {
        this.toaster.error(
            "Please tick at least one item that you want to move another matter."
        );
        return;
    }
    const dialogRef = this._matDialog.open(MoveEntryComponent, {
        width: "100%",
        disableClose: true,
        data: "",
    });
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
            // $('#refreshWorkInprogress').click();
            // $('#refreshTimeEntryTab').click();
        }
    });
}

ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
}

 /**
     * This function is used to get the Write off time entry data value
     * @param type -type data value
     */
 WriteOffTimeEntry(type) {
    let WorkItemData =
        type == "WIP" ? this.WorkInProgressData : '';
    let ItemTypDesc = WorkItemData.ITEMTYPEDESC;
    if (type == "WIP") {
        let selectedWipCheckBox
        this.behaviourService.refreshTaggedList.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            if(data && data.length >0){
                selectedWipCheckBox=data;
            }
        })
        if (!selectedWipCheckBox || selectedWipCheckBox.length < 2) {
            if (selectedWipCheckBox[0]) {
                WorkItemData = selectedWipCheckBox[0];
                ItemTypDesc = selectedWipCheckBox[0].ITEMTYPEDESC;
            }
            if (
                ItemTypDesc == "WIP" ||
                ItemTypDesc == "Activity" ||
                ItemTypDesc == "Sundry"
            ) {
                this.OpenWriteOffDialog(type);
            } else {
                this.openBulkProgressbar()
                this.DirectWriteOff(WorkItemData);
            }
        } else {
            this.openBulkProgressbar()
            this.MultiDirectWriteOff(selectedWipCheckBox);
        }
    }
   
}


 /**
     * This function is used to open the Write off Dialog data
     * @param type -type of the data
     */
 OpenWriteOffDialog(type) {
    this.toolbarServiceService.SetOldistrackid("open");
    const dialogRef = this._matDialog.open(WriteOffTimeEntryComponent, {
        width: "100%",
        disableClose: true,
        data: { type: type },
    });
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
            localStorage.setItem('Spellcheck','false');
            setTimeout(() => {
                $("#refreshTimeEntryTab").click();
                $("#refresheWorkEtimateTab").click();
                $("#refreshreciptsDAta").click();
                $("#refreshtabledetails").click();               
                this.behaviourService.checktogglestatus$.next(false);
            },1000);
           
        }
    });
}

 /**
     * This function is used to Direct Write off
     * @param Data -data value
     */
 DirectWriteOff(Data) {
    let PostData: any = {
        ADDITIONALTEXT: Data.ADDITIONALTEXT,
        COMMENT: Data.COMMENT,
        FEEEARNER: Data.FEEEARNER,
        ITEMTYPE: Data.ITEMTYPE,
        ITEMDATE: Data.ITEMDATE,
        ITEMTIME: Data.ITEMTIME,
        MATTERGUID: Data.MATTERGUID,
        PRICE: Data.PRICE,
        PRICEINCGST: Data.PRICEINCGST,
        QUANTITY: Data.QUANTITY,
        WRITEOFFQUANTITY: Data.QUANTITY,
        REMAININGQUANTITY: "",
        WorkItemGuid: Data.WORKITEMGUID,
    };
    if (Data.QUANTITYTYPE == "hh:mm") {
        PostData.WRITEOFFQUANTITY = this.secondsToHms(Data.QUANTITY);
        PostData.REMAININGQUANTITY = this.secondsToHms("");
    }
    if (Data.ITEMTYP == "2" || Data.ITEMTYPE == "3") {
        PostData.FEETYPE = Data.QUANTITYTYPE;
        PostData.QUANTITYTYPE = "";
    } else {
        PostData.QUANTITYTYPE = Data.QUANTITYTYPE;
    }
    let PostTimeEntryData: any = {
        FormAction: "write off",
        VALIDATEONLY: true,
        RequestGuid: this.RequestGuid,
        Data: PostData,
    };
    this.TimersServiceI.SetWorkItems(PostTimeEntryData)
        .subscribe(
            (res) => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.checkValidationWrite(
                        res.DATA.VALIDATIONS,
                        PostTimeEntryData
                    );
                } else if (res.CODE == 451 && res.STATUS == "warning") {
                    this.checkValidationWrite(
                        res.DATA.VALIDATIONS,
                        PostTimeEntryData
                    );
                } else if (res.CODE == 450 && res.STATUS == "error") {
                    this.checkValidationWrite(
                        res.DATA.VALIDATIONS,
                        PostTimeEntryData
                    );
                }
            },
            (err) => {
                // this.isspiner = false;
                this.toaster.error(err);
            }
        );
}

/**
     * this function is used to convert the second to HMS
     * @param d -data of d
     * @returns data value
     */
secondsToHms(d: any) {
    d = Number(d);
    var hours =
        Math.floor(d / 3600) < 10
            ? ("00" + Math.floor(d / 3600)).slice(-2)
            : Math.floor(d / 3600);
    var minutes = ("00" + Math.floor((d % 3600) / 60)).slice(-2);
    var seconds = ("00" + ((d % 3600) % 60)).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
}

/**
     * This function is used to open the body data value
     * @param bodyData -bodydata value
     * @param PostTimeEntryData -post time entry data value
     */
checkValidationWrite(bodyData: any, PostTimeEntryData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    // errorData
    bodyData.forEach(function (value) {
        if (value.VALUEVALID == "No" || value.VALUEVALID == "NO") {
            errorData.push(value.ERRORDESCRIPTION);
            tempError[value.FIELDNAME] = value;
        } else if (
            value.VALUEVALID == "WARNING" ||
            value.VALUEVALID == "Warning"
        ) {
            tempWarning[value.FIELDNAME] = value;
            warningData.push(value.ERRORDESCRIPTION);
        }
    });
    if (Object.keys(errorData).length != 0) {
        this.toaster.error(errorData);
        this.isspiner = false;
        this.dialogRefdata.close(true);
    } else if (Object.keys(warningData).length != 0) {
        this.isspiner = false;
        this.confirmDialogRef = this.MatDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
                data: warningData,
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to Save?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.isspiner = true;
                this.saveTimeEntryWrite(PostTimeEntryData);
            }else{
                this.dialogRefdata.close(true);
                this.behaviourService.checktogglestatus$.next(false);
                localStorage.setItem('Spellcheck','false');
            }
            this.confirmDialogRef = null;
        });
    } else if (
        Object.keys(warningData).length == 0 &&
        Object.keys(errorData).length == 0
    ) {
        this.saveTimeEntryWrite(PostTimeEntryData);
    }
}

/**
     * This function is used to saveTimeEntryWrite off data value
     * @param PostTimeEntryData -data value data
     */
    saveTimeEntryWrite(PostTimeEntryData: any) {
        //this.RequestGuid =  this._mainAPiServiceService.generateUniqSerial();
        PostTimeEntryData.VALIDATEONLY = false;
        PostTimeEntryData.RequestGuid = this.RequestGuid;
        this.TimersServiceI.SetWorkItems(PostTimeEntryData)
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.toaster.success("Update success");
                        localStorage.setItem('Spellcheck','false');
                        setTimeout(() => {
                            $("#refreshTimeEntryTab").click();
                            $("#refresheWorkEtimateTab").click();
                            $("#refreshreciptsDAta").click();  
                            $("#refreshtabledetails").click();               
                        },1000);
                        this.behaviourService.checktogglestatus$.next(false);
                        this.dialogRefdata.close(true);
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toaster.warning(res.MESSAGE);
                        this.isspiner = false;
                        this.dialogRefdata.close(true);
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toaster.warning(res.MESSAGE);
                        this.isspiner = false;
                        this.dialogRefdata.close(true);
                    }
                },
                (err) => {
                    this.isspiner = false;
                    this.toaster.error(err);
                }
            );
    }

    /**
     * This function is used to get the Multi DirecWrite off data
     * @param Data -data value
     */
    MultiDirectWriteOff(Data) {
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        let multiWriteoff = [];
        Data.forEach((element) => {
            let WriteoffElement: any = {
                ADDITIONALTEXT: element.ADDITIONALTEXT,
                COMMENT: element.COMMENT,
                FEEEARNER: element.FEEEARNER,
                ITEMTYPE: element.ITEMTYPE,
                ITEMDATE: element.ITEMDATE,
                ITEMTIME: element.ITEMTIME,
                MATTERGUID: element.MATTERGUID,
                PRICE: element.PRICE,
                PRICEINCGST: element.PRICEINCGST,
                QUANTITY: element.QUANTITY,
                WRITEOFFQUANTITY: element.QUANTITY,
                REMAININGQUANTITY: "",
                WorkItemGuid: element.WORKITEMGUID,
            };
            if (element.QUANTITYTYPE == "hh:mm") {
                WriteoffElement.WRITEOFFQUANTITY = this.secondsToHms(
                    element.QUANTITY
                );
                WriteoffElement.REMAININGQUANTITY = this.secondsToHms("");
            }
            if (element.ITEMTYP == "2" || element.ITEMTYPE == "3") {
                WriteoffElement.FEETYPE = element.QUANTITYTYPE;
                WriteoffElement.QUANTITYTYPE = "";
            } else {
                WriteoffElement.QUANTITYTYPE = element.QUANTITYTYPE;
            }
            multiWriteoff.push(WriteoffElement);
        });

        let PostTimeEntryData: any = {
            FormAction: "write off",
            VALIDATEONLY: true,
            RequestGuid: this.RequestGuid,
            MULTIRECORD: true,
            Data: multiWriteoff,
        };
        this.TimersServiceI.SetWorkItems(PostTimeEntryData)
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    }
                },
                (err) => {
                    this.isspiner = false;
                    this.toaster.error(err);
                }
            );
    }

    /**
     * This function is used to UndoWrite off time entry
     * @param type -type of the time entry
     */
    UndoWriteOffTimeEntry(type) {
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        let workerId =
            type == "WIP"
                ? this.WorkInProgressData.WORKITEMGUID : ''
        let postData = {
            FormAction: "undo write off",
            RequestGuid: this.RequestGuid,
            DATA: { WORKITEMGUID: workerId },
        };
        this.TimersServiceI.SetWorkItems(postData)
            .subscribe((res) => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    localStorage.setItem('Spellcheck','false');
                    setTimeout(() => {
                        $("#refreshTimeEntryTab").click();
                    $("#refresheWorkEtimateTab").click();
                    $("#refreshreciptsDAta").click();
                    },1000);
                    this.behaviourService.checktogglestatus$.next(false);
                    this.toaster.success("Undo successfully");
                }
            });
    }


    async deleteTimeEntry(): Promise<void> {
            let FilterItemTypeDesc: any[] = [];
            
            // Wait for the subscription to resolve
            try {
                const datavalue = await firstValueFrom(this.behaviourService.refreshTaggedList);
                if (datavalue && datavalue.length !== 0) {
                    FilterItemTypeDesc = datavalue.filter(data => data.ItemTypDesc !== 'Search');
                }
            } catch (error) {
                console.error('Error fetching data from observable:', error);
            }
    
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: "100%",
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 
                `Are you sure you want to delete ${FilterItemTypeDesc.length} entries?`;
    
            // Wait for the dialog to close and get the result
            const result = await this.confirmDialogRef.afterClosed().toPromise();
            if (result) {
                this.openBulkProgressbar('Delete Entries')
                for (let i = 0; i < FilterItemTypeDesc.length; i++) {
                    const item = FilterItemTypeDesc[i];
                    const WORKITEMGUID = item?.WORKITEMGUID || localStorage.getItem("edit_WORKITEMGUID");
                    const postData = item.ITEMTYPEDESC !== "Disbursement"
                        ? { FormAction: "delete", data: { WORKITEMGUID } }
                        : { FormAction: "delete", data: { EXPENDITUREGUID: item.EXPENDITUREGUID } };
                    const apiName = item.ITEMTYPEDESC !== "Disbursement" ? "workitem" : "SetExpenditure";
    
                    // Set retry variables
                    const RefreshIdArray = ["#refreshTimeEntryTab", "#refreshWorkInprogress"];
                    this.toolbarServiceService.SetVariableForRetry(postData, apiName, RefreshIdArray);
    
                    // Perform API call and handle responses
                    const res = await this._mainAPiServiceService.getSetData(postData, apiName).toPromise();
                    if (res.STATUS == "success" && res.CODE == 200) {
                        this.behaviourService.updateBulkdeleteAPICount$.next({data:{totalcount:FilterItemTypeDesc.length,updatedCount:i+1}});
                        localStorage.setItem("istrackid", this.oldIsTrackId);
                        this.toaster.success("Successfully Deleted");
                        if(i == FilterItemTypeDesc.length-1){
                            localStorage.setItem('Spellcheck','false');
                            this.behaviourService.checktogglestatus$.next(false);
                            this.dialogRefdata.close(true);
                            setTimeout(() => {
                                $("#refreshTimeEntryTab").click();
                                $("#refresheWorkEtimateTab").click();
                                $("#refreshreciptsDAta").click();
                                $("#refreshtabledetails").click();
                            },100);
                        }
                        
                    } else if (res.CODE == 451 || res.CODE == 450) {
                        await this.checkValidationdata(res.DATA.VALIDATIONS, postData, apiName);
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }else{
                        localStorage.setItem('Spellcheck','false');
                        this.behaviourService.checktogglestatus$.next(false);
                        this.dialogRefdata.close(true);
                        setTimeout(() => {
                            $("#refreshTimeEntryTab").click();
                            $("#refresheWorkEtimateTab").click();
                            $("#refreshreciptsDAta").click();
                            $("#refreshtabledetails").click();
                        },100);
                    }
                }
            }
    
            this.confirmDialogRef = null;
    }

     /**
     * This function is used to check the validation data
     * @param bodyData -bodydata value
     * @param details -details value
     * @param ApiName -Api Name
     */
     async checkValidationdata(bodyData: any, details: any, ApiName: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
    
        // Categorize error and warning data
        bodyData.forEach((value: any) => {
            if (
                value.VALUEVALID == "No" ||
                value.VALUEVALID == "NO" ||
                value.VALUEVALID == "Error"
            ) {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
    
        if (Object.keys(errorData).length !== 0) {
            localStorage.setItem("confEWshow", "error");
    
            // Open the error dialog asynchronously
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: "100%",
                data: { errorData: errorData },
            });
    
            this.confirmDialogRef.componentInstance.confirmMessage = "";
    
            // Wait for the dialog to close
            const result = await this.confirmDialogRef.afterClosed().toPromise();
            localStorage.removeItem("confEWshow");
    
            if (result) {
                // Additional logic for handling error dialog confirmation, if any
            }
    
            this.confirmDialogRef = null;
        } else if (Object.keys(warningData).length !== 0) {
            localStorage.setItem("confEWshow", "warning");
    
            // Open the warning dialog asynchronously
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: "100%",
                data: warningData,
            });
    
            this.confirmDialogRef.componentInstance.confirmMessage =
                ApiName == "SetInvoice"
                    ? ""
                    : "Are you sure you want to delete?";
    
            // Wait for the dialog to close
            const result = await this.confirmDialogRef.afterClosed().toPromise();
            localStorage.removeItem("confEWshow");
    
            if (result) {
                // Execute delete operation after warning dialog confirmation
                await this.toolbarServiceService.DeleteGData(details, ApiName);
            }
    
            this.confirmDialogRef = null;
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            // If no errors or warnings, execute delete operation
            await this.toolbarServiceService.DeleteGData(details, ApiName);
        }
    }

    openBulkProgressbar(data?){
        this.dialogRefdata = this.MatDialog.open(BulkProgressComponent, {
            width: "100%",
            disableClose: true,
            data: "",
        });
        this.dialogRefdata.componentInstance.DialogTitle =(data && data !=undefined) ? data : "Write Off Entries";
        this.dialogRefdata.afterClosed().subscribe((result) => {
            if (result) {
              
            }
        });
      }
}