import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { BehaviorService } from "./Behavior.service";
import { MainAPiServiceService } from "./main-api-service.service";
import { ToastrService } from "ngx-toastr";
import { ToolbarServiceService } from "../layout/components/toolbar/toolbar-service.service";
import * as $ from "jquery";
import { AddTaskPackComponent } from "@pages/system-settings/workflow/add-task-pack/add-task-pack.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { catchError, forkJoin, Observable, of, Subject, takeUntil } from "rxjs";
import { GenerateTemplatesDialoagComponent } from "@pages/system-settings/templates/gennerate-template-dialoag/generate-template.component";
import { TemplateLayoutComponent } from "@pages/system-settings/workflow/template-layout/template-layout.component";
import { MatterDocumanrPopupComponent } from "@pages/template/matter-documanr-popup/matter-documanr-popup.component";
import { GlobalFunctionsService } from "./global-functions.service";

@Injectable({
    providedIn: "root",
})
export class WorkflowService {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    private dialogRefdata: MatDialogRef<AddTaskPackComponent>;
    TaskPackItemForm: FormGroup;
    private _unsubscribeAll$: Subject<void> = new Subject();
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    constructor(
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        public _matDialog: MatDialog,
        public toolbarServiceService: ToolbarServiceService,
        private fb: FormBuilder,private globalFunService:GlobalFunctionsService
    ) {
        this.TaskPackItemForm = this.fb.group({
            ROLEASSIGNEDTO: [""],
            TASKORDER: [""],
            UNITS: [""],
            UNITTYPE: [""],
            ISBEFORE: [""],
           // WHICHTABLE: [""],
            WHICHFIELD: [""],
            TASKDESCRIPTION: [""],
            REMIND: [""],
            DAYSBEFOREDUEDATE: [""],
            PRIORITY: [""],
            TEMPLATETYPE: [""],
            TEMPLATE: [""],
            KITGUID: [""],
            KITNAME: [""],
            EMAILGUID: [""],
            EMAILNAME: [""],
            TASKPACKGUID:[''],
            TASKPACKITEMGUID:[""]
        });

        this.TaskPackItemForm.valueChanges.subscribe((formControl) => {
            this.changeForControlTaskPackItems(formControl);
        });
    }

    /**
     *  START THE CODE SECTION OF DELETE THE TASK PACK
     */

    getTaskPackItemForm() {
        return this.TaskPackItemForm;
    }

    /**
     * This function is used to delete protected trust window
     * @param action -action data
     */
    deleteTaskPack(id,API) {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData;
                if(API == 'task-pack'){
                    postData = {
                        ACTION: "delete",
                        DATA: {
                            TASKPACKGUID: id,
                        },
                    };
                }else{
                    postData = {
                        ACTION: "delete",
                        DATA: {
                            TASKPACKITEMGUID: id,
                        },
                    };
                }
                
                this._mainAPiServiceService
                    .getSetData(postData, API)
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            if(API == 'task-pack'){
                                $("#TASKPACK").click();
                            }else{
                                this.behaviorService.ColapsExpandEditRows$.next(true);
                            }
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                API
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                API
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to check the validation data
     * @param bodyData -bodydata value
     * @param details -details value
     * @param ApiName -Api Name
     */
    async checkValidation(bodyData: any, details: any, ApiName: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];

        // Categorize error and warning data
        bodyData.forEach((value: any) => {
            if (
                value.VALUEVALID == "No" ||
                value.VALUEVALID == "NO" ||
                value.VALUEVALID == "Error"
            ) {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });

        if (Object.keys(errorData).length !== 0) {
            localStorage.setItem("confEWshow", "error");

            // Open the error dialog asynchronously
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: { errorData: errorData },
                }
            );

            this.confirmDialogRef.componentInstance.confirmMessage = "";

            // Wait for the dialog to close
            const result = await this.confirmDialogRef
                .afterClosed()
                .toPromise();
            localStorage.removeItem("confEWshow");

            if (result) {
                // Additional logic for handling error dialog confirmation, if any
            }

            this.confirmDialogRef = null;
        } else if (Object.keys(warningData).length !== 0) {
            localStorage.setItem("confEWshow", "warning");

            // Open the warning dialog asynchronously
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: warningData,
                }
            );

            this.confirmDialogRef.componentInstance.confirmMessage =
                ApiName == "SetInvoice"
                    ? ""
                    : "Are you sure you want to delete?";

            // Wait for the dialog to close
            const result = await this.confirmDialogRef
                .afterClosed()
                .toPromise();
            localStorage.removeItem("confEWshow");

            if (result) {
                // Execute delete operation after warning dialog confirmation
                await this.toolbarServiceService.DeleteGData(details, ApiName);
            }

            this.confirmDialogRef = null;
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            // If no errors or warnings, execute delete operation
            await this.toolbarServiceService.DeleteGData(details, ApiName);
        }
    }

    /**
     *  END THE CODE SECTION OF DELETE THE TASK PACK
     */

    /**
     * This function is used to Add task pack.
     */
    TaskPack(headerName, id) {
        const dialogRef = this._matDialog.open(AddTaskPackComponent, {
            width: "100%",
            disableClose: true,
            data: {
                action: headerName == "Add Task Pack" ? "insert" : "update",
                EditId: headerName == "Add Task Pack" ? "" : id,
            },
        });
        dialogRef.componentInstance.confirmMessage = headerName;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to get the default data of the pack-item fields.
     */
    getTaskPackItemDefaultData(Action) {
        this.behaviorService.WorkFlowLoader$.next(true);
        let data;
        data = { Action: "Default", DATA: {} };
        this._mainAPiServiceService
            .getSetData(data, "task-pack-item")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        const TaskPackItemData = res.DATA.DEFAULTVALUES;
                        this.TaskPackItemForm.patchValue({
                            ROLEASSIGNEDTO: TaskPackItemData["ROLEASSIGNEDTO"],
                            TASKORDER: TaskPackItemData["TASKORDER"],
                            UNITS: TaskPackItemData["UNITS"],
                            UNITTYPE: TaskPackItemData["UNITTYPE"],
                            ISBEFORE: TaskPackItemData["ISBEFORE"],
                           // WHICHTABLE: TaskPackItemData["WHICHTABLE"],
                            WHICHFIELD: TaskPackItemData["WHICHFIELD"],
                            TASKDESCRIPTION:
                                TaskPackItemData["TASKDESCRIPTION"],
                            REMIND: TaskPackItemData["REMIND"],
                            DAYSBEFOREDUEDATE:
                                TaskPackItemData["DAYSBEFOREDUEDATE"],
                            PRIORITY: TaskPackItemData["PRIORITY"],
                            TEMPLATETYPE: TaskPackItemData["TEMPLATETYPE"],
                            TEMPLATE: TaskPackItemData["TEMPLATE"],
                            KITGUID: TaskPackItemData["KITGUID"],
                            KITNAME: TaskPackItemData["KITNAME"],
                            EMAILGUID: TaskPackItemData["EMAILGUID"],
                            EMAILNAME: TaskPackItemData["EMAILNAME"],
                            TASKPACKGUID:TaskPackItemData["TASKPACKGUID"],
                            TASKPACKITEMGUID:TaskPackItemData["TASKPACKITEMGUID"]

                        });
                        this.behaviorService.WorkFlowLoader$.next(false);
                        this.getAllLookUpsValue(Action);
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toastr.warning(res.MESSAGE);
                        this.behaviorService.WorkFlowLoader$.next(false);
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toastr.error(res.MESSAGE);
                        this.behaviorService.WorkFlowLoader$.next(false);
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRefdata.close(false);
                    }

                    this.CheckTaskPackItemdefultValidation(
                        res.DATA.VALIDATIONS
                    );
                    this.behaviorService.WorkFlowLoader$.next(false);
                },
                (err) => {
                    this.behaviorService.WorkFlowLoader$.next(false);
                    this.toastr.error(err);
                }
            );
    }

    async CheckTaskPackItemdefultValidation(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: {
            VALUEVALID: string;
            ERRORDESCRIPTION: any;
            FIELDNAME: any;
        }) {
            if (value.VALUEVALID == "No") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });

        this.errorWarningData = await {
            Error: tempError,
            Warning: tempWarning,
        };
        this.errorWarningDataArray = await {
            Error: tempError,
            Warning: tempWarning,
        };

        this.behaviorService.WorkFlowErrorWarningData$.next(
            this.errorWarningData
        );
    }

    /**
     * To change the Formcontrol value
     * @param formControl -get updated form control value
     */
    changeForControlTaskPackItems(formControl: any): void {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj };
                this.behaviorService.WorkFlowErrorWarningData$.next(
                    this.errorWarningData
                );
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 };
                this.behaviorService.WorkFlowErrorWarningData$.next(
                    this.errorWarningData
                );
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    get g() {
        return this.TaskPackItemForm.controls;
    }

    async getAllLookUpsValue(Action, text?) {
        if (this.g.TEMPLATETYPE.value == "Template") {
            // if (Action == "insert") {
                this.SelectDocument();
            // }
        } else if (this.g.TEMPLATETYPE.value == "Precedent") {
            this.OpenPrecedentTemplates(text);
        } else if (this.g.TEMPLATETYPE.value == "Lexon") {
            this.OpenLexonTemplates(text);
        } else if (this.g.TEMPLATETYPE.value == "Court Form") {
            this.OpenCourtFormTemplates(text);
        } else if (this.g.TEMPLATETYPE.value == "Email") {
            this.SelectEmailTemplate(text);
        } else if (this.g.TEMPLATETYPE.value == "Document Pack") {
            this.SelectPackTemoplates(text);
        } else if (this.g.TEMPLATETYPE.value == "No Template") {
            this.TaskPackItemForm.controls["EMAILNAME"].setValue("");
            this.TaskPackItemForm.controls["EMAILGUID"].setValue("");
            this.TaskPackItemForm.controls["KITNAME"].setValue("");
            this.TaskPackItemForm.controls["KITGUID"].setValue("");
            this.TaskPackItemForm.controls["TEMPLATE"].setValue("");
            //this.TaskPackItemForm.get('TEMPLATE')?.disable(); // To disable
        }
        //this._mainAPiServiceService.getSetData(postData, "TemplateList")
    }

    /**
     * This function is used to select the templates
     */
    SelectDocument() {
        const dialogRef = this._matDialog.open(
            GenerateTemplatesDialoagComponent,
            {
                disableClose: true,
                panelClass: "contact-dialog",
                data: { action: "new" },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.TaskPackItemForm.controls["TEMPLATE"].setValue(
                    result.TEMPLATENAME
                );
                this.TaskPackItemForm.controls["EMAILNAME"].setValue("");
                this.TaskPackItemForm.controls["EMAILGUID"].setValue("");
                this.TaskPackItemForm.controls["KITNAME"].setValue("");
                this.TaskPackItemForm.controls["KITGUID"].setValue("");
            }
        });
    }

    /**
     * This function is used to select the Email Templates
     */
    SelectEmailTemplate(text) {
        const dialogRef = this._matDialog.open(TemplateLayoutComponent, {
            disableClose: true,
            panelClass: "contact-dialog",
            data: { action: text },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.EmailGenerateData$.pipe(
                    takeUntil(this._unsubscribeAll$)
                ).subscribe((data) => {
                    if (data != null && data != undefined) {
                        this.TaskPackItemForm.controls["EMAILNAME"].setValue('');
                        this.TaskPackItemForm.controls["EMAILGUID"].setValue(
                            data.EMAILGUID
                        );
                        this.TaskPackItemForm.controls["KITNAME"].setValue("");
                        this.TaskPackItemForm.controls["KITGUID"].setValue("");
                        this.TaskPackItemForm.controls["TEMPLATE"].setValue(data.NAME);
                    }
                });
            }
        });
    }

    /**
     * This function is used to SelectPack Templates
     * @param text -text data value
     */
    SelectPackTemoplates(text) {
        const dialogRef = this._matDialog.open(TemplateLayoutComponent, {
            disableClose: true,
            panelClass: "contact-dialog",
            data: { action: text },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.packs$
                    .pipe(takeUntil(this._unsubscribeAll$))
                    .subscribe((data) => {
                        if (data != null && data != undefined) {
                            this.TaskPackItemForm.controls[
                                "EMAILNAME"
                            ].setValue("");
                            this.TaskPackItemForm.controls[
                                "EMAILGUID"
                            ].setValue("");
                            this.TaskPackItemForm.controls["KITNAME"].setValue(
                                ''
                            );
                            this.TaskPackItemForm.controls["KITGUID"].setValue(
                                data.KITGUID
                            );
                            this.TaskPackItemForm.controls["TEMPLATE"].setValue(
                                data.KITNAME
                            );
                        }
                    });
            }
        });
    }

    /**
     * This function is used to Open Precedent Templates
     * @param text -Text data value
     */
    OpenPrecedentTemplates(text) {
        const dialogRef = this._matDialog.open(MatterDocumanrPopupComponent, {
            disableClose: true,
            panelClass: "contact-dialog",
            data: { action: text, selectedTabIndex: 3 },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.courtFormData$
                    .pipe(takeUntil(this._unsubscribeAll$))
                    .subscribe((data) => {
                        if (data != null && data != undefined) {
                            this.TaskPackItemForm.controls[
                                "EMAILNAME"
                            ].setValue("");
                            this.TaskPackItemForm.controls[
                                "EMAILGUID"
                            ].setValue("");
                            this.TaskPackItemForm.controls["KITNAME"].setValue(
                                ""
                            );
                            this.TaskPackItemForm.controls["KITGUID"].setValue(
                                ""
                            );
                            this.TaskPackItemForm.controls["TEMPLATE"].setValue(
                                data?.DESCRIPTION
                            );
                        }
                    });
            }
        });
    }

    /**
     * This function is used to Open the Lexon Templates
     * @param text -Text data value
     */
    OpenLexonTemplates(text) {
        const dialogRef = this._matDialog.open(MatterDocumanrPopupComponent, {
            disableClose: true,
            panelClass: "contact-dialog",
            data: { action: text, selectedTabIndex: 5 },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.courtFormData$
                    .pipe(takeUntil(this._unsubscribeAll$))
                    .subscribe((data) => {
                        if (data != null && data != undefined) {
                            this.TaskPackItemForm.controls[
                                "EMAILNAME"
                            ].setValue("");
                            this.TaskPackItemForm.controls[
                                "EMAILGUID"
                            ].setValue("");
                            this.TaskPackItemForm.controls["KITNAME"].setValue(
                                ""
                            );
                            this.TaskPackItemForm.controls["KITGUID"].setValue(
                                ""
                            );
                            this.TaskPackItemForm.controls["TEMPLATE"].setValue(
                                data?.DESCRIPTION
                            );
                        }
                    });
            }
        });
    }

    /**
     * This function is used to Open Court Form Template
     * @param text - Text data value
     */
    OpenCourtFormTemplates(text) {
        const dialogRef = this._matDialog.open(MatterDocumanrPopupComponent, {
            disableClose: true,
            panelClass: "contact-dialog",
            data: { action: text, selectedTabIndex: 4 },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.courtFormData$
                    .pipe(takeUntil(this._unsubscribeAll$))
                    .subscribe((data) => {
                        if (data != null && data != undefined) {
                            this.TaskPackItemForm.controls[
                                "EMAILNAME"
                            ].setValue("");
                            this.TaskPackItemForm.controls[
                                "EMAILGUID"
                            ].setValue("");
                            this.TaskPackItemForm.controls["KITNAME"].setValue(
                                ""
                            );
                            this.TaskPackItemForm.controls["KITGUID"].setValue(
                                ""
                            );
                            this.TaskPackItemForm.controls["TEMPLATE"].setValue(
                                data?.DESCRIPTION
                            );
                        }
                    });
            }
        });
    }

    // loadAllLookupsData():Observable<any> {
    //    return forkJoin({
    //         roleAssignTo: this.loadRoleAssignTo(),
    //         whichTable: this.loadWhichTable(),
    //         whichField: this.loadWhichField(),
    //     })
    // }

    loadRoleAssignTo():Observable<any> {
        const data = {
            Action: "getdata",
            Filters: {
                LookupType: "TaskRoleAssignedTo",
            },
        };
        return this._mainAPiServiceService.getSetData(data, "lookup").pipe(
            takeUntil(this._unsubscribeAll$),
            catchError((err) => {
                console.error("Error in loadRoleAssignTo:", err);
                return of([]); // Return an empty array or default value
            })
        );
    }

    loadWhichTable(Matterclass):Observable<any> {
        const data = {
            Action: "getdata",
            Filters: {
                 "LOOKUPTYPE": "TaskWhichTable",
                 "MATTERCLASS": Matterclass
            },
        };
        return this._mainAPiServiceService.getSetData(data, "lookup").pipe(
            takeUntil(this._unsubscribeAll$),
            catchError((err) => {
                console.error("Error in loadWhichTable:", err);
                return of([]); // Return an empty array or default value
            })
        );
    }

    loadWhichField(Matterclass,WHICHTABLE): Observable<any> {
        const data = {
            Action: "getdata",
            Filters: {
                "LOOKUPTYPE": "TaskWhichField",
                "MATTERCLASS": Matterclass,
                // "WHICHTABLE": WHICHTABLE,
            },
        };
    
        return this._mainAPiServiceService.getSetData(data, "lookup").pipe(
            takeUntil(this._unsubscribeAll$),
            catchError((err) => {
                console.error("Error in loadWhichField:", err);
                return of([]); // Return an empty array or default value in case of error
            })
        );
    }
    

    setEditTaskPackItemData(res,Matterclass) {
        const TaskPackItemData = res;
        this.TaskPackItemForm.patchValue({
            ROLEASSIGNEDTO: TaskPackItemData["ROLEASSIGNEDTO"],
            TASKORDER: TaskPackItemData["TASKORDER"],
            UNITS: TaskPackItemData["UNITS"],
            UNITTYPE: TaskPackItemData["UNITTYPE"],
            ISBEFORE: TaskPackItemData["ISBEFORE"],
           // WHICHTABLE: TaskPackItemData["WHICHTABLE"],
            WHICHFIELD: TaskPackItemData["WHICHFIELD"],
            TASKDESCRIPTION: TaskPackItemData["TASKDESCRIPTION"],
            REMIND: TaskPackItemData["REMIND"],
            DAYSBEFOREDUEDATE: TaskPackItemData["DAYSBEFOREDUEDATE"],
            PRIORITY: TaskPackItemData["PRIORITY"],
            TEMPLATETYPE: TaskPackItemData["TEMPLATETYPE"],
            TEMPLATE: TaskPackItemData["TEMPLATE"],
            KITGUID: TaskPackItemData["KITGUID"],
            KITNAME: TaskPackItemData["KITNAME"],
            EMAILGUID: TaskPackItemData["EMAILGUID"],
            EMAILNAME: TaskPackItemData["EMAILNAME"],
            TASKPACKITEMGUID:TaskPackItemData["TASKPACKITEMGUID"],
            TASKPACKGUID:TaskPackItemData["TASKPACKGUID"]
        });
    }



    /**
     * This function is used to get the task pack data.
     */
        InsertEditTaskPackItemData(Action,id):void {
            this.behaviorService.WorkFlowLoader$.next(true);
            let payload;
            if(Action == 'update'){
              payload ={
                ROLEASSIGNEDTO:this.g.ROLEASSIGNEDTO.value,
                TASKORDER:this.g.TASKORDER.value,
                UNITS:this.g.UNITS.value,
                UNITTYPE:this.g.UNITTYPE.value,
                ISBEFORE:this.g.ISBEFORE.value,
                //WHICHTABLE:this.g.WHICHTABLE.value,
                WHICHFIELD:this.g.WHICHFIELD.value,
                TASKDESCRIPTION:this.g.TASKDESCRIPTION.value,
                REMIND:this.g.REMIND.value,
                DAYSBEFOREDUEDATE:this.g.DAYSBEFOREDUEDATE.value,
                PRIORITY:this.g.PRIORITY.value,
                TEMPLATETYPE:this.g.TEMPLATETYPE.value,
                TEMPLATE:this.g.TEMPLATE.value,
                KITGUID:this.g.KITGUID.value,
                // KITNAME:this.g.KITNAME.value,
                EMAILGUID:this.g.EMAILGUID.value,
                // EMAILNAME:this.g.EMAILNAME.value,
                TASKPACKGUID:this.g.TASKPACKGUID.value,
                TASKPACKITEMGUID:this.g.TASKPACKITEMGUID.value,
              }
            }else{
                payload ={
                    ROLEASSIGNEDTO:this.g.ROLEASSIGNEDTO.value,
                    TASKORDER:this.g.TASKORDER.value,
                    UNITS:this.g.UNITS.value,
                    UNITTYPE:this.g.UNITTYPE.value,
                    ISBEFORE:this.g.ISBEFORE.value,
                    //WHICHTABLE:this.g.WHICHTABLE.value,
                    WHICHFIELD:this.g.WHICHFIELD.value,
                    TASKDESCRIPTION:this.g.TASKDESCRIPTION.value,
                    REMIND:this.g.REMIND.value,
                    DAYSBEFOREDUEDATE:this.g.DAYSBEFOREDUEDATE.value,
                    PRIORITY:this.g.PRIORITY.value,
                    TEMPLATETYPE:this.g.TEMPLATETYPE.value,
                    TEMPLATE:this.g.TEMPLATE.value,
                    KITGUID:this.g.KITGUID.value,
                    // KITNAME:this.g.KITNAME.value,
                    EMAILGUID:this.g.EMAILGUID.value,
                    // EMAILNAME:this.g.EMAILNAME.value,
                    TASKPACKGUID:id,
                  }
            }
           
            let finalpayload = {
                Action: Action,
                VALIDATEONLY: true,
                DATA: {
                    ...payload,
                },
            };
            this._mainAPiServiceService
                .getSetData(finalpayload, "task-pack-item")
                .pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(
                    (res) => {
                        if (res.CODE == 200 && res.STATUS == "success") {
                            this.checkTaskPackItemValidation(
                                res.DATA.VALIDATIONS,
                                finalpayload,
                                "task-pack-item",Action
                            );
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkTaskPackItemValidation(
                                res.DATA.VALIDATIONS,
                                finalpayload,
                                "task-pack-item",Action
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkTaskPackItemValidation(
                                res.DATA.VALIDATIONS,
                                finalpayload,
                                "task-pack-item",Action
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    },
                    (err) => {
                        this.behaviorService.WorkFlowLoader$.next(false);
                        this.toastr.error(err);
                    }
                );
        }
    
    
     /**
      * Thsi function is used to check the validation
      * @param bodyData - Request data value
      * @param details - Detail data value
      * @param APIURL - API URL
      */
     async checkTaskPackItemValidation(bodyData: any, details: any, APIURL: any,Action:any):Promise<void> {
      this.behaviorService.WorkFlowLoader$.next(false);
      await this.globalFunService
          .checkValidation(bodyData, details)
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((data) => {
              if (data) {
                  this.errorWarningData = data.errorWarningData;
                  this.behaviorService.WorkFlowErrorWarningData$.next(
                    this.errorWarningData
                );
                  if (data.callback) {
                      this.saveTaskPacketsItemData(details, APIURL,Action);
                  }
              }
          });
    }
    
    /**
     * This function is used to Save the TaskPacks Data 
     * @param data -Data value
     * @param APIURL -API URL DATA
     */
    saveTaskPacketsItemData(data: any, APIURL:string,Action):void {
      data.VALIDATEONLY = false;
      this._mainAPiServiceService
          .getSetData(data, APIURL)
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (
                      response.CODE == 200 &&
                      (response.STATUS == "OK" ||
                          response.STATUS == "success")
                  ) {
                    if(Action == 'update'){
                      this.toastr.success("update successfully");
                    }else{
                      this.toastr.success("save successfully");
                    }
                      this.behaviorService.WorkFlowLoader$.next(false);
                      this.behaviorService.ColapsExpandEditRows$.next(true);
                       // $('#TASKPACK').click();
                       // this.dialogRef.close(true);
                    
                      
                  } else if (
                      response.CODE == 451 &&
                      response.STATUS == "warning"
                  ) {
                      this.toastr.warning(response.MESSAGE);
                  } else if (
                      response.CODE == 450 &&
                      response.STATUS == "error"
                  ) {
                      this.toastr.error(response.MESSAGE);
                  } else if (response.MESSAGE == "Not logged in") {
                      this.dialogRef.close(false);
                  }
                  this.behaviorService.WorkFlowLoader$.next(false);
              },
              (error) => {
                  this.toastr.error(error);
              }
          );
    }
}
