import { BehaviorService } from '@_services/Behavior.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dollarFixedAmount'
})
export class FixedAmountPipe implements PipeTransform {
    CurrenctSymbol: any = ''
    constructor(private behaviorService: BehaviorService,) {

        /**
         * Use this behaviour for the Currency Symbol
         */
        this.behaviorService.CURRENCYSYMBOL$.subscribe(res => {
            if (res && res !== '') {
                this.CurrenctSymbol = res
            }
        })
    }

    /**
     * This function is used to transform the data.
     */
    transform(value: any, args: any = false, parameter: any): any {


        let tempVal = value;

        if ((typeof value == "string" || typeof value == "undefined") && !value) {

            tempVal = 0;
        }
        if (args) {
            if (tempVal != 0) {
                let tem: any = parseFloat(tempVal).toFixed(2);


                return this.CurrenctSymbol + this.addCommas(tem);
            } else {
                if (tempVal == 0) {
                    let tem: any = parseFloat(tempVal).toFixed(2);
                    return this.CurrenctSymbol + this.addCommas(tem);
                } else {
                    return;
                }
            }
        } else {
            if (value.includes(":")) {
                const splitArray = value.split(':');
                const finalArray = splitArray.map((ele) => {
                    const string = ele.trim();
                    if (this.containsNumbers(string)) {
                        ele = this.CurrenctSymbol + `${string}`
                    }
                    return ele;
                })
                const finalValue = finalArray.join(';');
                return finalValue
            } else if (parameter == "ALLOCATION") {
                return this.CurrenctSymbol +`${value}`;
            } else {
                return value;
            }
        }
    }

    /**
     * This function is used to add the comma.
     */
    addCommas(nStr) {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }

    /**
     * This function is used to contains the numbers.
     */
    containsNumbers(str) {
        return /^\d/.test(str);
    }


    /**
     * This function is used to get the only Numbers.
     */
    onlyNumbers(val) {
        return /^\d+$/.test(val);
    }

}
