import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-check-for-credits',
  templateUrl: './check-for-credits.component.html',
  styleUrls: ['./check-for-credits.component.scss'],
  animations: fuseAnimations
})
export class CheckForCreditsComponent implements OnInit, OnDestroy {
  displayedColumns = ['Discription', 'Receipt', 'Date', 'Aallocation', 'Allocated'];
  isLoadingResults: boolean = true;
  matterData: any;
  invoiceData: any;
  CreditsDataSource: any = [];
  CheckforCreditsForm: FormGroup;
  selectedColore: string = 'rgb(217, 217, 217)';
  highlightedRows: any;
  unuseCredit = 0;
  outstanding: any;
  currentReciptData: any;
  UsedCredits: any;
  INDEX: number;
  AllocationData: any[];
  showEmptyDataMsg: boolean=false;
  sub: Subscription;
  sub1: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CheckForCreditsComponent>,
    public _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    private _formBuilder: FormBuilder,
  ) {
    localStorage.setItem('istrackid', 'CheckForCreditsComponent');
    this.matterData = this._data.MatterData;
    this.invoiceData = this._data.InvoiceData;
    this.outstanding = this.invoiceData.AMOUNTOUTSTANDINGINCGST;
    this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'CheckForCreditsComponent' && result['click'] && result['data']['income']) {
        this.ngOnInit();
      }
    });
  }
  ngOnInit() {
    this.CheckforCreditsForm = this._formBuilder.group({
      allocatedSelected: ['']
    });
    this.sub1 = this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{ 'MATTERGUID': this.matterData.MATTERGUID, CREDITSONLY: true,"RECEIPTSANDCREDITS": true }}, 'income').subscribe(res => {
      if ((res.CODE == 200 || res.CODE == '200') && res.STATUS == "success") {
        this.isLoadingResults = false;
        if(res.DATA.RECORDS.length==0){
         this.showEmptyDataMsg=true
        }
          this.CreditsDataSource = res.DATA.RECORDS;
          res.DATA.RECORDS.forEach(element => {
            this.unuseCredit = this.unuseCredit + Number(element.ALLOCATION);
          });
          this.CreditsAllowcation();
          this.invoiceData.TotalCredits = this.unuseCredit;
          this.isLoadingResults = false;
       
       
      } else if (res.MESSAGE == 'Not logged in') {
        this.isLoadingResults = false;
        this.dialogRef.close(false);
      } else if((res.CODE == 406 || res.CODE == '406') && res.STATUS == "error") {
        this.isLoadingResults = false;
      }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

  /**
   * This functionis used to get the CreditAllowcation
   */
  CreditsAllowcation():void {
    if (this.unuseCredit > this.outstanding) {
      this.UsedCredits = this.outstanding;
      this.CheckforCreditsForm.controls['allocatedSelected'].setValue(this.outstanding);
    } else {
      this.UsedCredits = this.unuseCredit;
      this.CheckforCreditsForm.controls['allocatedSelected'].setValue(this.unuseCredit);
    }
    this.checkCal(this.CreditsDataSource, 'autoAllocation', this.outstanding)
    this.isLoadingResults = false;
  }
  get f() {
    return this.CheckforCreditsForm.controls;
  }

  /**
   * This function is used to checkCalculation data value.
   * @param data -data value
   * @param checkval -checkvalue
   * @param outstanding -outstanding data value
   */
  checkCal(data, checkval, outstanding):void {
    if (typeof data != "undefined") {
      let enteredval = 0;
      let i = 0;
      data.forEach(element => {
        element.AMOUNTALLOCATED = 0;
      });
      if (checkval == 'autoAllocation') {
        enteredval = Number(outstanding);
        for (i = 0; data.length >= i; i++) {
          if (Number(enteredval) > 0 && data[i]) {
            if (Number(data[i].ALLOCATION) <= Number(enteredval)) {
              data[i].AMOUNTALLOCATED = (Number(data[i].ALLOCATION)).toFixed(2);
              enteredval = enteredval - data[i].ALLOCATION;
            } else {
              data[i].AMOUNTALLOCATED = (Number(enteredval)).toFixed(2);
              enteredval = enteredval - data[i].ALLOCATION;
            }
          } else {
            if (data[i])
              data[i].AMOUNTALLOCATED = 0;
          }
        }
      }
      if(!data ){ 
        this.highlightedRows = data[0].RECEIPTALLOCATIONGUID;
        this.currentReciptData = data[0];
        this.clickRow(data[0]);
        this.isLoadingResults = false;
      }
      else{
        this.isLoadingResults = false;
      }
    }
  }

  /**
   * This function is used to click on row value
   * @param row 
   */
  clickRow(row: any) {
    this.currentReciptData = row;
    if (this.CreditsDataSource) {
      const indexData = this.CreditsDataSource.findIndex(CreditInvoice => CreditInvoice.RECEIPTALLOCATIONGUID === this.currentReciptData.RECEIPTALLOCATIONGUID);
      this.INDEX = indexData;
    }
    this.CheckforCreditsForm.controls['allocatedSelected'].setValue(row.AMOUNTALLOCATED);
  }

  /**
   * This function is used to Single row allowcation data value
   */
  SingalrowAllocation() {
    if (Number(this.f.allocatedSelected.value) <= Number(this.CreditsDataSource[this.INDEX].ALLOCATION)) {
      this.CreditsDataSource[this.INDEX].AMOUNTALLOCATED = Number(this.f.allocatedSelected.value);
      this.highlightedRows = this.CreditsDataSource[this.INDEX].RECEIPTALLOCATIONGUID;
    } else {
      this.toastr.error('You are trying to allocate an amount that is more than the amount outstanding.');
      this.CheckforCreditsForm.controls['allocatedSelected'].setValue(0);
      this.CreditsDataSource[this.INDEX].AMOUNTALLOCATED = 0;
    } this.calUsedCredits();
  }

  /**
   * This funcxtion is used to calculate the Used credit data
   */
  calUsedCredits():void {
    this.UsedCredits = 0;
    this.CreditsDataSource.forEach(element => this.UsedCredits = this.UsedCredits + Number(element.AMOUNTALLOCATED));
  }

  /**
   * This function is used to Set the Use credits. 
   */
  SetUseCredits():void {
    this.AllocationData = [];
    this.CreditsDataSource.forEach(element => {
      this.AllocationData.push({ RECEIPTALLOCATIONGUID: element.RECEIPTALLOCATIONGUID, AMOUNTALLOCATED: Number(element.AMOUNTALLOCATED) })
    });
    const PostData: any = {
      InvoiceGUID: this.invoiceData.INVOICEGUID,
      Credits: this.AllocationData
    };
    let PostCreditData: any = { FormAction: 'update', VALIDATEONLY: false, Data: PostData };
    this._mainAPiServiceService.getSetData(PostCreditData, 'SetMatterCredit').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        $('#refreshMatterInvoice').click();
        this.dialogRef.close(true);
      } else {
        this.toastr.error(res.MESSAGE);
        if (res.CODE == 402 && res.STATUS == 'error' && res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    }, err => {
      this.toastr.error(err);
    });
  }
}