<!-- <button mat-raised-button type="button" color="primary" id="refreshBank"
    (click)="refreshBank();">Refresh</button> -->
    <div
    class="popup_main_div corres_popup_main select_metter_main_div account_select_main_popup"
    cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>{{confirmMessage}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="example-containerdatavalue task-notes">
        <ng-container [formGroup]="TaskPackForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
              <!-- Matter Class -->
              <mat-form-field appearance="outline" fxFlex="10" class="pr-4" 
              [ngClass]="{'has-error':errorWarningData?.Error?.MATTERCLASS,'has-warning':errorWarningData?.Warning?.MATTERCLASS}">
                <mat-label>Matter Class</mat-label>
                <mat-select #value placeholder="Matter Class" formControlName="MATTERCLASS">
                  <mat-option *ngFor="let item of Classdata" [value]="item.LOOKUPFULLVALUE">
                    {{item.LOOKUPFULLVALUE}}</mat-option>
                </mat-select>
              </mat-form-field>
          
              <!-- Task Pack Order -->
              <mat-form-field appearance="outline" fxFlex="10" class="pr-4">
                <mat-label>Task Pack Order</mat-label>
                <input class="sideText" type="text" matInput formControlName="TASKPACKORDER" />
              </mat-form-field>
          
              <!-- Default Pack Checkbox -->
              <div fxFlex="8" style="margin-bottom: 15px;margin-left: 5px !important">
                <mat-checkbox name="CheckBox" class="pr-4" formControlName="DEFAULTPACK">Default Pack</mat-checkbox>  
              </div>

              <div fxFlex="67" >
                <mat-form-field appearance="outline" fxFlex="100" 
                [ngClass]="{'has-error':errorWarningData?.Error?.PACKDESCRIPTION,'has-warning':errorWarningData?.Warning?.PACKDESCRIPTION}">
                                    <mat-label>Pack Description</mat-label>
                                    <textarea matInput formControlName="PACKDESCRIPTION"></textarea>
                                </mat-form-field>  
              </div>

              <div fxFlex="5" style="margin-bottom: 15px;margin-left: 5px !important">
               
              </div>
            </div>
          
            <!-- Notes Section -->
            <!-- <div fxLayout="row wrap"  fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" 
[ngClass]="{'has-error':errorWarningData?.Error?.PACKDESCRIPTION,'has-warning':errorWarningData?.Warning?.PACKDESCRIPTION}">
                    <mat-label>Pack Description</mat-label>
                    <textarea matInput formControlName="PACKDESCRIPTION"></textarea>
                </mat-form-field>
            </div> -->
        </ng-container>
        </div>  

        <!-- START TASK_PACK_ITEM SECTION -->
        <div class="Addbutton">
         <p class="notedata" *ngIf="btntext == 'Save and Add Items'"> Note: You must save the Task Pack before adding items to it.</p>  &nbsp;&nbsp;
        <button (click)="OpenTaskPackItemBlock()" [disabled]="( btntext == 'Save and Add Items' )"
        mat-flat-button="" color="accent" class="spend-money-button btn-text mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14">
        <mat-icon class="fs-21">add
        </mat-icon> 
        Add
       </button>
        </div>
        
        <div class="packitem-table">
          <table mat-table
       [dataSource]="Task_Pack_Item_dataSource" multiTemplateDataRows
       class="mat-elevation-z8" #table cdkDropList (cdkDropListDropped)="onDrop($event)">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef> {{
       (column == 'ROLEASSIGNEDTO') ? 'Assigned To' :
       (column == 'TASKORDER') ? 'Order':
       (column == 'DUEDATEDESCRIPTION') ? 'Due Date':
       (column == 'TASKDESCRIPTION') ? 'Description' :
       (column == 'REMINDERDESCRIPTION') ? 'Reminder' :
       (column == 'PRIORITY') ? 'Priority' :
       (column == 'TEMPLATEDESCRIPTION') ? 'Generate What?' :
        '' }}
    </th>
    <!-- <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="column == 'TASKORDER'"class="menu-icon dragCursor" >menu</mat-icon> {{element[column]}}
     </td> -->

     <td mat-cell *matCellDef="let element">
       {{element[column]}}
     </td>

  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">
      <!-- <button 
       mat-flat-button="" color="accent" class="spend-money-button btn-text mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14">
      <mat-icon class="fs-21">add
      </mat-icon> 
      Add
    </button> -->
   </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="ExpandColapsArea(element)">
        <mat-icon *ngIf="expandedElement !== element" (click)="getEditTaskPackItemData(element)">edit</mat-icon>
        <mat-icon *ngIf="expandedElement === element" (click)="checkexpand(element)">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>



  <ng-container matColumnDef="header-row-first-group">
    <th *matHeaderCellDef [style.text-align]="center" style="border: none !important;"
        [attr.colspan]="100">
        <div class="tablepack-box">
          <ng-container [formGroup]="TaskPackItemForm">
          <div class="tablebox-wrapper grid-box mb-12 order-row">
            <mat-form-field appearance="outline" class="order-formfield">
              <mat-label>Order</mat-label>
              <input class="sideText" type="text" matInput formControlName="TASKORDER" />
            </mat-form-field>
            <!-- <mat-form-field appearance="outline">
              <mat-label>Priority</mat-label>
              <input class="sideText" type="text" matInput formControlName="PRIORITY" />
            </mat-form-field> -->
            <mat-form-field appearance="outline"  class="small-fieldbox">
              <mat-label>Priority</mat-label>
              <mat-select #value placeholder="Priority" formControlName="PRIORITY">
                <mat-option value="Low">Low</mat-option>
                <mat-option value="Medium">Medium</mat-option>
                <mat-option value="High">High</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ROLEASSIGNEDTO,'has-warning':errorWarningData.Warning?.ROLEASSIGNEDTO}">
              <mat-label>Assigned To</mat-label>
              <mat-select #value placeholder="Assigned To" formControlName="ROLEASSIGNEDTO">
                <mat-option *ngFor="let item of RoleAssignData" [value]="item.LOOKUPFULLVALUE">
                  {{item.LOOKUPFULLVALUE}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Template Type</mat-label>
              <mat-select #value placeholder="Template Type" formControlName="TEMPLATETYPE" (selectionChange)="dataval($event)">
                <mat-option value="No Template">No Template</mat-option>
                <mat-option value="Template">Template</mat-option>
                <mat-option value="Precedent">Precedent</mat-option>
                <mat-option value="Lexon">Lexon</mat-option>
                <mat-option value="Court Form">Court Form</mat-option>
                <mat-option value="Email">Email</mat-option>
                <mat-option value="Document Pack">Document Pack</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Template'">
              <mat-label>Template</mat-label>
              <input [attr.disabled]="(g.TEMPLATETYPE.value == 'No Template') ? true : null"  class="sideText" type="text" matInput formControlName="TEMPLATE" />

              <mat-icon  matSuffix (click)="datavalSingle('Template')" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1) && g.TEMPLATETYPE.value != 'No Template'">
                <img src="assets/icons/web_app/icon_letters_d.ico">
            </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Email'">
              <mat-label>Email Template</mat-label>
              <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />

              <mat-icon  matSuffix (click)="datavalSingle('Email')" *ngIf="g.TEMPLATETYPE.value == 'Email'">
                <img src="assets/icons/web_app/icon_email_d.ico">
            </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Document Pack'">
              <mat-label>Pack Template</mat-label>
              <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />

              <mat-icon  matSuffix (click)="datavalSingle('Document Pack')">
                <img src="assets/icons/web_app/icon_doc_pack_d.ico">
            </mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Precedent'">
              <mat-label>Precedent Template</mat-label>
              <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />

              <mat-icon  matSuffix (click)="datavalSingle('Precedent')">
                <img src="assets/icons/web_app/icon_doc_pack_d.ico">
            </mat-icon>
            </mat-form-field>
            
            <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Lexon'">
              <mat-label>Lexon Template</mat-label>
              <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />

              <mat-icon  matSuffix (click)="datavalSingle('Lexon')">
                <img src="assets/icons/web_app/Lexon.jpeg">
            </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Court Form'">
              <mat-label>Court Form Template</mat-label>
              <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />

              <mat-icon  matSuffix (click)="datavalSingle('Court Form')">
                <img src="assets/icons/web_app/icon_courtforms_d.ico">
            </mat-icon>
            </mat-form-field>
            
          </div>
          <div class="wrapper-3box grid-box mb-8 reminder-wrapper">
            <div class="duedate-box taskpack-item">
              <span class="formlabel-bx">Due Date</span>
              <div>
                <div class="wrapper-4box grid-box">
                  <mat-form-field appearance="outline" class="small-fieldbox">
                    <mat-label>Units</mat-label>
                    <input class="sideText" type="text" matInput formControlName="UNITS" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.UNITTYPE,'has-warning':errorWarningData.Warning?.UNITTYPE}">
                    <mat-label>Unit Type</mat-label>
                    <mat-select #value placeholder="Unit Type" formControlName="UNITTYPE">
                      <mat-option value="Days">Days</mat-option>
                      <mat-option value="Weeks">Weeks</mat-option>
                      <mat-option value="Months">Months</mat-option>
                      <mat-option value="Years">Years</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline"  class="small-fieldbox">
                    <mat-label>Before/After</mat-label>
                    <mat-select #value placeholder="Before/After" formControlName="ISBEFORE">
                      <mat-option [value]="1">Before</mat-option>
                      <mat-option [value]="0">After</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field style="width: 165px;" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.WHICHFIELD,'has-warning':errorWarningData.Warning?.WHICHFIELD}">
                    <mat-label>Which Field</mat-label>
                    <mat-select #value placeholder="Which Field" formControlName="WHICHFIELD">
                      <mat-option *ngFor="let item of WhichFieldData" [value]="item.LOOKUPFULLVALUE">
                        {{item.LOOKUPFULLVALUE}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div class="wrapper-2box grid-box">
                  <mat-form-field appearance="outline">
                    <mat-label>Which Table</mat-label>
                    <mat-select #value placeholder="Which Table" formControlName="WHICHTABLE" (selectionChange)="getWhichTableSelectedData($event)">
                      <mat-option *ngFor="let item of WhichTableData" [value]="item.LOOKUPFULLVALUE">
                        {{item.LOOKUPFULLVALUE}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.WHICHFIELD,'has-warning':errorWarningData.Warning?.WHICHFIELD}">
                    <mat-label>Which Field</mat-label>
                    <mat-select #value placeholder="Which Field" formControlName="WHICHFIELD">
                      <mat-option *ngFor="let item of WhichFieldData" [value]="item.LOOKUPFULLVALUE">
                        {{item.LOOKUPFULLVALUE}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
              </div>
            </div>
            <div class="taskpack-item">
              <span class="formlabel-bx">Reminder</span>
              <div class="wrapper-2box grid-box">
                <mat-checkbox name="CheckBox" class="pr-4 cb" formControlName="REMIND">Remind?</mat-checkbox>
                <ng-container *ngIf="isShowDueDateField">
                  <mat-form-field appearance="outline">
                    <mat-label>Days Before Due Date</mat-label>
                    <input class="sideText" type="text" matInput formControlName="DAYSBEFOREDUEDATE" />
                  </mat-form-field>
                </ng-container>
                
              </div>
            </div>
          </div>
          <mat-form-field appearance="outline" class="full-width mb-8" [ngClass]="{'has-error':errorWarningData.Error?.TASKDESCRIPTION,'has-warning':errorWarningData.Warning?.TASKDESCRIPTION}">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="TASKDESCRIPTION"></textarea>
          </mat-form-field>
        </ng-container>
                <div class="d-flex gap-10 justify-content-end ">
                  <button mat-raised-button class="cancel disabled-colore" color="accent"
                  class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base" mat-button (click)="InsertUpdateTaskPackItem('insert')">
                  <!-- <mat-spinner *ngIf="isspiner"></mat-spinner> -->
                  Save</button>
                  <button  class="ng-tns-c142-6 mdc-button mat-mdc-button mat-warn mat-mdc-button-base spend-money-deletes"
                  (click)="maintainFlag = false" mat-button >Cancel</button>
                </div>
        </div>
    </th>
</ng-container>



  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <!-- <div class="example-element-diagram">
          <div class="example-element-position"> {{element.ROLEASSIGNEDTO}} </div>
          <div class="example-element-symbol"> {{element.TASKORDER}} </div>
          <div class="example-element-name"> {{element.UNITS}} </div>
          <div class="example-element-weight"> {{element.UNITTYPE}} </div>
        </div> -->
        <div class="tablepack-box">
          <ng-container [formGroup]="TaskPackItemForm">
            <div class="tablebox-wrapper grid-box mb-12 order-row">
              <mat-form-field appearance="outline" class="order-formfield">
                <mat-label>Order</mat-label>
                <input class="sideText" type="text" matInput formControlName="TASKORDER" />
              </mat-form-field>
              <!-- <mat-form-field appearance="outline">
                <mat-label>Priority</mat-label>
                <input class="sideText" type="text" matInput formControlName="PRIORITY" />
              </mat-form-field> -->
              <mat-form-field appearance="outline"  class="small-fieldbox">
                <mat-label>Priority</mat-label>
                <mat-select #value placeholder="Priority" formControlName="PRIORITY">
                  <mat-option value="Low">Low</mat-option>
                  <mat-option value="Medium">Medium</mat-option>
                  <mat-option value="High">High</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ROLEASSIGNEDTO,'has-warning':errorWarningData.Warning?.ROLEASSIGNEDTO}">
                <mat-label>Assigned To</mat-label>
                <mat-select #value placeholder="Assigned To" formControlName="ROLEASSIGNEDTO">
                  <mat-option *ngFor="let item of RoleAssignData" [value]="item.LOOKUPFULLVALUE">
                    {{item.LOOKUPFULLVALUE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field appearance="outline">
                <mat-label>Template Type</mat-label>
                <mat-select #value placeholder="Template Type" formControlName="TEMPLATETYPE" (selectionChange)="dataval($event)">
                  <mat-option value="No Template">No Template</mat-option>
                  <mat-option value="Template">Template</mat-option>
                  <mat-option value="Precedent">Precedent</mat-option>
                  <mat-option value="Lexon">Lexon</mat-option>
                  <mat-option value="Court Form">Court Form</mat-option>
                  <mat-option value="Email">Email</mat-option>
                  <mat-option value="Document Pack">Document Pack</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Template'">
                <mat-label>Template</mat-label>
                <input [attr.disabled]="(g.TEMPLATETYPE.value == 'No Template') ? true : null"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
  
                <mat-icon  matSuffix (click)="datavalSingle('Template')" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1) && g.TEMPLATETYPE.value != 'No Template'">
                  <img src="assets/icons/web_app/icon_letters_d.ico">
              </mat-icon>
              </mat-form-field>
  
              <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Email'">
                <mat-label>Email Template</mat-label>
                <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
  
                <mat-icon  matSuffix (click)="datavalSingle('Email')" *ngIf="g.TEMPLATETYPE.value == 'Email'">
                  <img src="assets/icons/web_app/icon_email_d.ico">
              </mat-icon>
              </mat-form-field>
  
              <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Document Pack'">
                <mat-label>Pack Template</mat-label>
                <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
  
                <mat-icon  matSuffix (click)="datavalSingle('Document Pack')">
                  <img src="assets/icons/web_app/icon_doc_pack_d.ico">
              </mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Precedent'">
                <mat-label>Precedent Template</mat-label>
                <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
  
                <mat-icon  matSuffix (click)="datavalSingle('Precedent')">
                  <img src="assets/icons/web_app/icon_doc_pack_d.ico">
              </mat-icon>
              </mat-form-field>
              
              <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Lexon'">
                <mat-label>Lexon Template</mat-label>
                <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
  
                <mat-icon  matSuffix (click)="datavalSingle('Lexon')">
                  <img src="assets/icons/web_app/Lexon.jpeg">
              </mat-icon>
              </mat-form-field>
  
              <mat-form-field appearance="outline" *ngIf="g.TEMPLATETYPE.value == 'Court Form'">
                <mat-label>Court Form Template</mat-label>
                <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
  
                <mat-icon  matSuffix (click)="datavalSingle('Court Form')">
                  <img src="assets/icons/web_app/icon_courtforms_d.ico">
              </mat-icon>
              </mat-form-field>
              
            </div>
            <div class="wrapper-3box grid-box mb-8 reminder-wrapper">
              <div class="duedate-box taskpack-item">
                <span class="formlabel-bx">Due Date</span>
                <div>
                  <div class="wrapper-4box grid-box">
                    <mat-form-field appearance="outline" class="small-fieldbox">
                      <mat-label>Units</mat-label>
                      <input class="sideText" type="text" matInput formControlName="UNITS" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.UNITTYPE,'has-warning':errorWarningData.Warning?.UNITTYPE}">
                      <mat-label>Unit Type</mat-label>
                      <mat-select #value placeholder="Unit Type" formControlName="UNITTYPE">
                        <mat-option value="Days">Days</mat-option>
                        <mat-option value="Weeks">Weeks</mat-option>
                        <mat-option value="Months">Months</mat-option>
                        <mat-option value="Years">Years</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline"  class="small-fieldbox">
                      <mat-label>Before/After</mat-label>
                      <mat-select #value placeholder="Before/After" formControlName="ISBEFORE">
                        <mat-option [value]="1">Before</mat-option>
                        <mat-option [value]="0">After</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 165px;" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.WHICHFIELD,'has-warning':errorWarningData.Warning?.WHICHFIELD}">
                      <mat-label>Which Field</mat-label>
                      <mat-select #value placeholder="Which Field" formControlName="WHICHFIELD">
                        <mat-option *ngFor="let item of WhichFieldData" [value]="item.LOOKUPFULLVALUE">
                          {{item.LOOKUPFULLVALUE}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- <div class="wrapper-2box grid-box">
                    <mat-form-field appearance="outline">
                      <mat-label>Which Table</mat-label>
                      <mat-select #value placeholder="Which Table" formControlName="WHICHTABLE" (selectionChange)="getWhichTableSelectedData($event)">
                        <mat-option *ngFor="let item of WhichTableData" [value]="item.LOOKUPFULLVALUE">
                          {{item.LOOKUPFULLVALUE}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.WHICHFIELD,'has-warning':errorWarningData.Warning?.WHICHFIELD}">
                      <mat-label>Which Field</mat-label>
                      <mat-select #value placeholder="Which Field" formControlName="WHICHFIELD">
                        <mat-option *ngFor="let item of WhichFieldData" [value]="item.LOOKUPFULLVALUE">
                          {{item.LOOKUPFULLVALUE}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                </div>
              </div>
              <div class="taskpack-item">
                <span class="formlabel-bx">Reminder</span>
                <div class="wrapper-2box grid-box">
                  <mat-checkbox name="CheckBox" class="pr-4 cb" formControlName="REMIND">Remind?</mat-checkbox>
                  <mat-form-field appearance="outline" *ngIf="isShowDueDateField">
                    <mat-label>Days Before Due Date</mat-label>
                    <input class="sideText" type="text" matInput formControlName="DAYSBEFOREDUEDATE" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-form-field appearance="outline" class="full-width mb-8" [ngClass]="{'has-error':errorWarningData.Error?.TASKDESCRIPTION,'has-warning':errorWarningData.Warning?.TASKDESCRIPTION}">
                      <mat-label>Description</mat-label>
                      <textarea matInput formControlName="TASKDESCRIPTION"></textarea>
            </mat-form-field>
          </ng-container>
                <div class="d-flex gap-10 justify-content-end ">
                  <button mat-raised-button class="cancel disabled-colore" color="accent"
                  class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base" mat-button (click)="InsertUpdateTaskPackItem('update')">
                  <!-- <mat-spinner *ngIf="isspiner"></mat-spinner> -->
                  Update</button>
                  <button mat-raised-button class="cancel disabled-colore" color="accent"
                  class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base" mat-button (click)="DeleteTaskPackItem(element)">
                  Delete</button>
                  <button  class="ng-tns-c142-6 mdc-button mat-mdc-button mat-warn mat-mdc-button-base spend-money-deletes"
                  (click)="ExpandColapsArea(element)" mat-button >Cancel</button>
                </div>
        </div>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand" class="fixed-row"></tr>
  <tr mat-header-row *matHeaderRowDef="['header-row-first-group']"
                    [ngClass]="(maintainFlag)?'' : 'hideRow'"> 
                </tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" cdkDrag [cdkDragData]="element" (cdkDragStarted)="onDragStart(element)"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element" (click)="editTaskPacksItems(element);highlightedRows=element.TASKPACKITEMGUID" [style.background]="highlightedRows == element.TASKPACKITEMGUID ? selectedColore : ''"
      >
  </tr>
  <ng-container >
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [hidden]="expandedElement !== row" class="example-detail-row" ></tr>
  </ng-container>
          </table>
        </div>
    </div>
    <mat-paginator [pageSizeOptions]="[5,10,20,50,100]"
                   [pageSize]="10"
                   showFirstLastButtons 
                   aria-label="Select page of periodic elements" style="margin-right: 10px;">
    </mat-paginator>
    <!-- [mat-dialog-close]="true" -->
    <mat-dialog-actions align="left" class="popup_ftr contact_select_footer">
            <button mat-raised-button
            color="accent" (click)="TaskPackData()">
            <mat-spinner *ngIf="isLoadingResults"
                    style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                margin: auto;top: 0;bottom: 0;" >
            </mat-spinner>
            {{btntext}}
          </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>
