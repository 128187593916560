import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { GenerateInvoiceComponent } from '../generate-invoice/generate-invoice.component';
import * as $ from 'jquery';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CheckForCreditsComponent } from '../check-for-credits/check-for-credits.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TimersService } from '@_services/timers.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-instant-invoice-dailog',
  templateUrl: './instant-invoice-dailog.component.html',
  styleUrls: ['./instant-invoice-dailog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InstantInvoiceDailogComponent implements OnInit, OnDestroy {
  addInvoiceForm: FormGroup;
  errorWarningData: any = {};
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  optionList: any = [
    { 'ACTIVITYID': 'X', 'DESCRIPTION': 'hh:mm' },
    { 'ACTIVITYID': 'H', 'DESCRIPTION': 'Hours' },
    { 'ACTIVITYID': 'M', 'DESCRIPTION': 'Minutes' },
    { 'ACTIVITYID': 'D', 'DESCRIPTION': 'Days' },
    { 'ACTIVITYID': 'U', 'DESCRIPTION': 'Units' },
    { 'ACTIVITYID': 'F', 'DESCRIPTION': 'Fixed' }
  ];
 error :any = "";
  LookupsList: any;
  filteredOptions: Observable<string[]>;
  TotalExGst: any;
  TotalIncGst: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  matterDetail: any = {};
  GstVal: any;
  lastQtyType: any = "";
  GetSetMixRetry: boolean = true;
  calculateData: any = { MatterGuid: '', QuantityType: '', Quantity: '', FeeEarner: '', FeeType: '' };
  userList: any;
  windowNameId:any;
  fixedandGSTfree:boolean =true;
  tootipData:any = {};
  testBool: boolean=false;
  toolTipList: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
  constructor(
    public globalFunService:GlobalFunctionsService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InstantInvoiceDailogComponent>,
    public datepipe: DatePipe,
    public _matDialog: MatDialog,
    private toastr: ToastrService,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    private Timersservice: TimersService,
    public tooltipService:TooltipService,
    private router: Router
  ) {
    localStorage.setItem('istrackid', 'InstantInvoiceDailogComponent');
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    // this.behaviorService.MatterData$.subscribe(result => {
    //   this.matterDetail = result;
    // });
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
        const materIDofTab = window.name.split("_");
        const windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
        this.matterDetail = JSON.parse(localStorage.getItem(windowNameId? windowNameId : 'set_active_matters'));
       if (this.matterDetail) {
       } else {
         if (result) {
           this.matterDetail = result;
           localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.matterDetail))
         }
       }
    });


    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'InstantInvoiceDailogComponent' && this.GetSetMixRetry && result['click'] && (result['data']['lookup'] || result['data']['SetInvoice'])) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'InstantInvoiceDailogComponent' && this.GetSetMixRetry == false && result['click'] && result['data']['SetInvoice']) {
        this.SaveInstaceInvoice();
      }
    });
    this.isLoadingResults = true;
    let userList = JSON.parse(localStorage.getItem('users_list'));
    if (userList && userList != null) {
      this.userList = this.Timersservice.removeUSerWithoutId(userList);
      this.isLoadingResults = false;
    } else {
      this.Timersservice.GetUsers({}).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          this.userList = this.Timersservice.removeUSerWithoutId(res.DATA.USERS);
          localStorage.setItem('users_list', JSON.stringify(res.DATA.USERS));
        } else if (res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        } else {
          this.userList = [];
        }
        this.isLoadingResults = false;
      }, err => {
        this.toastr.error(err);
      });
    }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.lastQtyType = currentUser.DefaultQuantityType;
    this.lastQtyType = "F";
    this.addInvoiceForm = this._formBuilder.group({
      INVOICEDATETEXT: [''],
      INVOICEDATE: [''],
      DUEDATETEXT: [''],
      DUEDATE: [''],
      MATTERGUID: [this.matterDetail.MATTERGUID],
      QUANTITYTYPE: ['F'],
      // QUANTITYTYPE: [currentUser.DefaultQuantityType],
      client: [this.matterDetail.CONTACTNAME],
      matter: [this.matterDetail.MATTER],
      Invoiceno: [''],
      QUANTITY: ['0'],
      Totalexgst: ['', { updateOn: 'blur' }],
      Totalincgst: ['', { updateOn: 'blur' }],
      GST: ['', { updateOn: 'blur' }],
      INVOICECOMMENT: [''],
      ADDITIONALTEXT: [''],
      FEEEARNER: [(currentUser.WORKINGONBEHALFOFGUID) ? currentUser.WORKINGONBEHALFOFGUID : currentUser.UserId],
    });
    this.calculateData.MatterGuid = this.matterDetail.MATTERGUID;
    this.calculateData.FeeEarner = (currentUser.WORKINGONBEHALFOFGUID) ? currentUser.WORKINGONBEHALFOFGUID : currentUser.UserId;
    this.calculateData.QuantityType = 'F';
    // this.calculateData.QuantityType = currentUser.DefaultQuantityType;
    this.isLoadingResults = true;
    let PostInvoiceEntryData: any = { FormAction: 'default', VALIDATEONLY: false, Data: {} };
    this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        let temInvoice = response.DATA.DEFAULTVALUES.INVOICECODE;
        this.tootipData =  response.DATA.FIELDTIPS;
        this.addInvoiceForm.controls['Invoiceno'].setValue(temInvoice.toString().padStart(8, "0"));
        let temInvoiceData = response.DATA.DEFAULTVALUES;
        if (temInvoiceData.INVOICEDATE) {
          let tinvoiceDate = temInvoiceData.INVOICEDATE.split("/");
          this.addInvoiceForm.controls['INVOICEDATETEXT'].setValue(new Date(tinvoiceDate[1] + '/' + tinvoiceDate[0] + '/' + tinvoiceDate[2]));
          this.addInvoiceForm.controls['INVOICEDATE'].setValue(temInvoiceData.INVOICEDATE);
        }
        if (temInvoiceData.DUEDATE) {
          let tdueDate = temInvoiceData.DUEDATE.split("/");
          this.addInvoiceForm.controls['DUEDATETEXT'].setValue(new Date(tdueDate[1] + '/' + tdueDate[0] + '/' + tdueDate[2]));
          this.addInvoiceForm.controls['DUEDATE'].setValue(temInvoiceData.DUEDATE);
        }
        this.isLoadingResults = false;
      }
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
    this.calcTotalEXGST();
    this.calcTotalINGST();
    this.isLoadingResults = true;
    let timeEntryLookups = JSON.parse(localStorage.getItem('time_entry_lookups'));
    if (timeEntryLookups && timeEntryLookups != null) {
      this.LookupsList = timeEntryLookups;
      this.filteredOptions = this.f.ADDITIONALTEXT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.isLoadingResults = false;
    } else {
      this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'time entry' }}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.LookupsList = responses.DATA.LOOKUPS;
          this.filteredOptions = this.f.ADDITIONALTEXT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
          localStorage.setItem('time_entry_lookups', JSON.stringify(responses.DATA.LOOKUPS));
          this.isLoadingResults = false;
        }
      });
    }
  this.setTooltipData()}

  /**
   * This function is used to set the tooltip data value
   */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['Invoice'] ){
      this.toolTipList  = TooltipData['Invoice'];
    }else{
      this.tooltipService.setToolTipData = ('Invoice');
     }
  };

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to Toggle for the updates
   */
  ToggleForUpadte():void {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This functionis used to _filter the data value
   * @param value -value data value
   * @returns data value
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.LookupsList.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)));
  }

  /**
   * This function is used to Lookups changes
   * @param value -cvalue data
   */
  LookupsChange(value: any) {
    this.addInvoiceForm.controls['ADDITIONALTEXT'].setValue(value);
  }

  /**
   * This function is used to change the QTY type value
   * @param key -key data value
   * @param event -event data value
   */
  quntityTypeChange(key: any, event: any):void {
    if (key == "QuantityType") {
      this.calculateData.QuantityType = event;
    }
    this.calculateData.Quantity = this.f.QUANTITY.value;
    if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
      this.isLoadingResults = true;
      let sendData = {
        FromQuantityType: this.lastQtyType,
        toQuantityType: event,
        Quantity: this.f.QUANTITY.value
      }
      this.Timersservice.ChangeQuantityType(sendData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          this.addInvoiceForm.controls['QUANTITY'].setValue(response.DATA.QUANTITY);
          this.lastQtyType = response.DATA.TOQUANTITYTYPE
          this.isLoadingResults = false;
          this.matterChange('QuantityType', event);
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }, err => {
        this.isLoadingResults = false;
        this.toastr.error(err);
      });
    }
  }

  /**
   * This function is used to change the matter
   * @param key -key data value
   * @param event -event data value
   */
  matterChange(key: any, event: any):void {
    if (this.f.FEEEARNER.value && key != "FeeEarner") {
      this.calculateData.FeeEarner = this.f.FEEEARNER.value;
    }
    if (key == "QuantityType") {
      this.calculateData.QuantityType = event;
      this.lastQtyType = this.calculateData.QuantityType;
    } else if (key == "FeeEarner") {
      this.calculateData.FeeEarner = event;
    }
    this.calculateData.Quantity = this.f.QUANTITY.value;
    if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
      this.isLoadingResults = true;
      this.Timersservice.calculateWorkItems(this.calculateData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let CalcWorkItemCharge = response.DATA;
          this.addInvoiceForm.patchValue({
            Totalexgst: Number(CalcWorkItemCharge.PRICE).toFixed(2),
            GST: Number(CalcWorkItemCharge.GST).toFixed(2),
            Totalincgst: Number(CalcWorkItemCharge.PRICEINCGST).toFixed(2),
          });
          this.isLoadingResults = false;
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }, err => {
        this.isLoadingResults = false;
        this.toastr.error(err);
      });
    }
  }

  /**
   * This function is used to set the invoice start date value
   * @param type -type value
   * @param event -event data value
   */
  InvoiceStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.addInvoiceForm.controls['INVOICEDATE'].setValue(begin);
  }

  /**
   * This function is used to get the Invoice due date value
   * @param type -type of the Due date
   * @param event -event data value
   */
  InvoiceDueDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.addInvoiceForm.controls['DUEDATE'].setValue(begin);
  }

  /**
   * this function is used to calculate the Total Ex-GST
   */
  calcTotalEXGST():void {
    const mattersType = JSON.parse(localStorage.getItem(this.windowNameId? this.windowNameId : 'set_active_matters'));
    if (this.TotalExGst == undefined) {
      this.GstVal = 0.00;
      this.TotalIncGst = 0.00;
    } else {
      // this.GstVal = Number(this.TotalExGst) * 10 / 100;
      // this.GstVal = (mattersType.GSTTYPEDESC == 'GST Free') ? 0.00 : Number(this.TotalExGst) * 10 / 100;
      this.GstVal =(mattersType.GSTTYPEDESC == 'GST Free' || mattersType.GSTTYPEDESC == 'Export')? 0.00 :(Number(this.TotalExGst) * 10 / 100);
      this.TotalIncGst = (Number(this.GstVal) + Number(this.TotalExGst)).toFixed(2);
    }
  }
  get f() {
    return this.addInvoiceForm.controls;
  }

  /**
   * This function is used to calcTotal INCGST.
   */
  calcTotalINGST():void {
    const mattersType = JSON.parse(localStorage.getItem(this.windowNameId? this.windowNameId : 'set_active_matters'));
    if (this.TotalIncGst == undefined) {
      this.TotalExGst = 0.00;
      this.GstVal = 0.00;
    } else {
      // let ExGSt = Number(this.TotalIncGst) / 1.1;
      let ExGSt =  (mattersType.GSTTYPEDESC == 'GST Free' || mattersType.GSTTYPEDESC == 'Export') ? (Number(this.TotalIncGst)) :(Number(this.TotalIncGst) / 1.1);
      this.TotalExGst = (ExGSt).toFixed(2);
      this.GstVal = (mattersType.GSTTYPEDESC == 'GST Free' || mattersType.GSTTYPEDESC == 'Export') ? 0.00 : (Number(this.TotalIncGst) - Number(ExGSt)).toFixed(2);
    }
  }

  /**
   * This function is used to Save Instance of Invoice data 
   * @returns -data value
   */
  SaveInstaceInvoice() {
    this.GetSetMixRetry = false;
    let warningData: any = [];
    // SaveInstaceInvoice
    if (this.TotalExGst == 0 || this.TotalExGst == undefined) {
      // this.toastr.error("Invoice of $0 is not allowed");
      this.error += "Invoice of $0 is not allowed.\n";
      warningData.push("Invoice of $0 is not allowed.\n")
      if (this.error != "") {
        //this.toastr.error(error);
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: "100%", data: {errorData :warningData}, });
        this.confirmDialogRef.componentInstance.confirmMessage = "Check the Validation Section for Errors/Warnings.";
      }
          return;
    }

    let SendData = {
      INVOICEGUID: '',
      INVOICECODE: this.f.Invoiceno.value,
      MATTERGUID: this.matterDetail.MATTERGUID,
      INVOICEDATE: this.f.INVOICEDATE.value,
      DUEDATE: this.f.DUEDATE.value,
      INVOICETOTAL: this.TotalExGst,
      INVOICETOTALINCGST:this.TotalIncGst,
      GST: this.GstVal,
      COMMENT: this.f.INVOICECOMMENT.value,
      PRINTEDDATE: '',
      FOREIGNCURRENCYID: '',
      WORKITEMS: {
        FEEEARNER: this.f.FEEEARNER.value,
        QUANTITY: this.f.QUANTITY.value,
        QUANTITYTYPE: this.f.QUANTITYTYPE.value,
        ADDITIONALTEXT: this.f.ADDITIONALTEXT.value,
      }
    }
    this.isspiner = true;
    let finalData = { DATA: SendData, FormAction: 'insert', VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'SetInvoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });

  }

  /**
   * This function is used to check the validation
   * @param bodyData -body data value
   * @param details -details data value
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No' || value.VALUEVALID == 'NO') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning' || value.VALUEVALID == 'WARNING') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // }); ``
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.isspiner = false;
    //   this.toastr.error(errorData);
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.InstantInvoiceData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.InstantInvoiceData(details);
    // }
    // this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        // this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.InstantInvoiceData(details);
        }else{
          this.isspiner = false;
        }
      }
    });
  }

  /**
   * This function is used to Create the Instant Invoice Data 
   * @param data -data value
   */
  InstantInvoiceData(data: any):void {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'SetInvoice').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success(' save successfully');
        this.dialogRef.close(true);
          $('#refreshWorkInprogress').click();
          $('#Legal_Details').click();
        this.behaviorService.matterInvoiceData({ INVOICEGUID: response.DATA.INVOICEGUID });
        if(this.currentUser?.OPENINNEWTAB && (this.router.url == "/matter-details/work-in-progress" || this.router.url == "/matters" || this.router.url == "/matter-details/matter-invoices")) {
          localStorage.setItem("'INVOICEGUID_"+data?.DATA?.MATTERGUID,response.DATA.INVOICEGUID);
        }
        let CreditinvoiceData = data.DATA;
        CreditinvoiceData.INVOICEGUID = response.DATA.INVOICEGUID;
        CreditinvoiceData.AMOUNTOUTSTANDINGINCGST = data.DATA.INVOICETOTALINCGST;
        this.dialogRef.close(true);
        this.checkForCredits(CreditinvoiceData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      };
      this.isspiner = false;
    }, error => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to check for the credits
   * @param invoiceData -invoice data value.
   */
  checkForCredits(invoiceData):void {
    this.isspiner = true;
    this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{ 'MATTERGUID': this.matterDetail.MATTERGUID, CREDITSONLY: true,RECEIPTSANDCREDITS: true }}, 'income').subscribe(res => {
      if ((res.CODE == 200 || res.CODE == '200') && res.STATUS == "success") {
        this.isspiner = false;
        if (res.DATA.RECORDS[0]) {
          const dialogRef = this._matDialog.open(CheckForCreditsComponent, { width: '100%', disableClose: true, data: { 'type': 'view', MatterData: this.matterDetail, InvoiceData: invoiceData } });
          dialogRef.afterClosed().subscribe(result => {
                this.GenerateInvoicepopup();
              if (result) {
            }
          });
        } else {
            this.isspiner = false;
                this.GenerateInvoicepopup()
        }
      }
    }, err => {
        this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to Generate the Invoice popup data value
   */
  GenerateInvoicepopup():void {
    this.isspiner = true;
    const dialogRef = this._matDialog.open(GenerateInvoiceComponent, {
      width: '100%',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {

            this.isspiner = false;

});
  }

}
