import { BehaviorService } from '@_services/Behavior.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent {
    public confirmMessage: string = "Are you sure you want to Save?";
    public confirmTitle: string = "Confirm";
    public confirmData: any;
    public isShowInputField=false;
    AccountDescription:any='';
    usecompanyaddress1 :boolean = false
    ShowHide: string;
    ConBtn: string;
    btnName: string;
    btnName1 :string
    isTrust: boolean = false;
    isSearchData: boolean = false;
    isContactUs : boolean = false;
    SignupUrl: string = "";
    contactUsMessage:any;
    isErrorWarn:Boolean = false;
    isBankFeed: boolean = false;
    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private behaviorService: BehaviorService,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {

        if (_data == null) {
            this.ConBtn = 'Show';
            this.btnName = 'Cancel'
            this.confirmData = _data;
            this.isSearchData = false;
            this.isContactUs = false
        } else if (_data.errorData) {
            this.ConBtn = 'Hide';
            this.btnName = 'Ok'
            this.confirmData = _data.errorData;
            this.isSearchData = false;
            this.isContactUs = false;
        } else if (_data.trstData) {
            this.ConBtn = 'Hide';
            this.btnName = 'Ok';
            this.isTrust = true;
            this.isSearchData = false;
            this.isContactUs = false
        } else if (_data.isSearch) {
            this.isSearchData = true;
            this.SignupUrl = _data.SignupUrl;
            this.isContactUs = false
        }else if(_data.isContactUs){
            this.isContactUs = _data.isContactUs;
            this.confirmData = null;
            this.contactUsMessage = _data.message
        }else if (_data.usecompanyaddress) {
            this.isContactUs =false,
            this.isTrust =false
            this.isSearchData =false,
            this.usecompanyaddress1 = true;
            this.ConBtn = 'usecompany';
            this.btnName1 = 'No';
            this.confirmData =null
            // this.btnName1 = 'No';
        } else if (_data.errorValidation) {
            this.ConBtn = 'Hide';
            this.btnName = 'Ok'
            this.confirmData = _data.errorValidation;
            this.isErrorWarn = true;
            this.isSearchData = false;
            this.isContactUs = false;
            this.confirmTitle = 'Errors/Warnings'
        } else if (_data.warningValidation) {
            this.ConBtn = 'Show';
            this.btnName = 'Cancel'
            this.confirmData = _data.warningValidation;
            this.isErrorWarn = true;
            this.isSearchData = false;
            this.isContactUs = false;
            this.confirmTitle = 'Errors/Warnings'
        } else if (_data.csvData){
            this.ConBtn = 'Yes';
            this.btnName = 'No'
            this.confirmData =null
            this.isErrorWarn = false;
            this.isSearchData = false;
            this.isContactUs = false;
            this.confirmTitle = 'Errors/Warnings'
        }
        else if (_data.toggleAutocompleteDropdown == 'toggleAutocompleteDropdown') {
            this.btnName = 'Replace'
        }
        else if(_data.isBankFeed){
            this.isSearchData = false;
            this.isContactUs = false;
            this.btnName = 'Cancel';
            this.isBankFeed = _data.isBankFeed;
            // this.confirmData = null;
            this.contactUsMessage = _data.message
        }
        else {
            this.ConBtn = 'Show';
            this.btnName = 'Cancel';
            this.confirmData = _data;
        }
        // let item = localStorage.getItem('confEWshow');
        // if (item =='error') {
        //     this.ShowHide="Hide"

        // }else{
        //     this.ShowHide=""
        // }


    }

    /**
 * This function is avoid the multiple time load the DOM elemnts.
 * @param index->Use to pass the unique identity to function.
 *  */

    trackByFn(index:number):number {
        return index; // or item.id
      }

}
