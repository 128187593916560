import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-discount-increase',
    templateUrl: './discount-increase.component.html',
    styleUrls: ['./discount-increase.component.scss']
})
export class DiscountIncreaseComponent implements OnInit, OnDestroy {
    isLoadingResults: boolean = false;
    @Input() addInvoiceForm: FormGroup;
    @Input() allItemsTotalObj: any;
    @Output() discountAmount: EventEmitter<any> = new EventEmitter<any>();
    isAmount: boolean = true;
    GSTAmmountCange: string;
    FeesCheckVal: any;
    SundriesCheckVal: any;
    MatterExPenseCheckVal: any;
    WorkInProgressData: any;
    ammountStore: any;
    previousAmmount: any;
    isIncrease: any;
    isCalIngst: any;
    iscalbyAmount: any;
    selectedOption: any = 'All Fees/Activities';
    feeEarnarList: any = [];
    feeEarnardeliverableList: any = [];
    FEEEARNERSLIST: any = [];
    FEEEARNERS: FormArray;
    FEEEARNERBTN: boolean = false;
    defaultSelect: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    TaggedItems: any = [];

    matterDetail: any = {};
    windowNameId: any;
    deliverableData: any = '';
    freeEarnarData: any = '';
    isDisabled: boolean = true;
    feeEarnarListTagged: any = [];
    feeEarnarListWorkItemList = [];
    showApplyToWhatDropdown: boolean = false;
    currentMatter: any;
    workItemsData: any;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private behaviorService: BehaviorService,
        private currencyPipe: CurrencyPipe,
        private _mainAPiServiceService: MainAPiServiceService,
        private fb: FormBuilder,
        private toastr: ToastrService,
    ) {
        this.behaviorService.workInProgress$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            this.WorkInProgressData = result
        });

        // set active matter
        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            const materIDofTab = window.name.split("_");
            const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            this.currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
            if (this.currentMatter) {
            } else {
                if (result) {
                    this.currentMatter = result;
                    localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.currentMatter))
                }
            }
        });
        // ends here ~ set active matter

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.addInvoiceForm.controls['Percentage_type'].setValue('Percentage');
        this.addInvoiceForm.controls['Percentage'].setValue(0);
        this.iscalbyAmount = false;
        this.addInvoiceForm.controls['Discount_type'].setValue('Discount');
        this.isIncrease = false;
        this.addInvoiceForm.controls['GST_type'].setValue('ExGstAmount');
        this.isCalIngst = false;
        this.addInvoiceForm.controls['amount'].setValue(0);
        this.addInvoiceForm.controls['Fees'].setValue(true);
        this.GSTAmmountCange = 'Ex-GST';
        this.FeesCheckVal = 'Fees';
        this.SundriesCheckVal = '';
        this.MatterExPenseCheckVal = '';

        // call function to get all work items
        this.getWorkItems()
        // ends here ~ call function to get all work items
        //Dynamic
        this.behaviorService.refreshTaggedList.pipe(takeUntil(this._unsubscribeAll$)).subscribe((e) => {
            if (e) {
                this.TaggedItems = e;
            }
        });

        this.behaviorService.refreshinvoiceWorkItem.pipe(takeUntil(this._unsubscribeAll$)).subscribe((userData) => {
            if (userData) {
                this.feeEarnarListWorkItemList = userData;
            }
        })

        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            const materIDofTab = window.name.split("_");
            const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            this.matterDetail = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
            if (this.matterDetail) {
            } else {
                if (result) {
                    this.matterDetail = result;
                    localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.matterDetail))
                }
            }
        });

        this.onAmoPerChnage('Enter');

        // set fields availability according to features allowed
        this.setFieldEnableDisableAccToPermission();
        // ends here ~ set fields availability according to features allowed

    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    get f() {
        return this.addInvoiceForm.controls;
    };

    /**
     * This function is used to get the users list
     */
    getUser():void {
        this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: {} }, 'user').pipe(takeUntil(this._unsubscribeAll$)).subscribe(async response => {
            if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
                this.feeEarnarList = response.DATA.USERS;
                this.feeEarnarListTagged = [];
                if (response.DATA.USERS) {
                    await response.DATA.USERS.forEach(feeEarnarData => {
                        if (this.feeEarnarListWorkItemList.some((t) => t.FEEEARNER !== "" && (t.FEEEARNER == feeEarnarData.USERID))) {
                            this.feeEarnarListTagged.push(feeEarnarData);
                        }
                    });
                    this.feeEarnarList = this.feeEarnarListTagged;
                    this.freeEarnarData = (this.feeEarnarList.length !== 0) ? this.feeEarnarList[0].USERID : '';
                }
            } else {
                this.feeEarnarList = [];
            }
        });
    };

    /**
     * This function is used to get the deliverables
     */
    getDeliverable():void {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: {"INCLUDEBLANKROW": true, MATTERGUID: this.matterDetail.MATTERGUID }, }, 'matter-deliverable').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
            if (responses.CODE == "200" && responses.STATUS == 'success') {
                this.feeEarnardeliverableList = responses.DATA.RECORDS;
                this.deliverableData = (this.feeEarnardeliverableList.length !== 0) ? this.feeEarnardeliverableList[0].DELIVERABLEID : '';
            }
        });
    }

    /**
     * This function is used to select the check box
     * @param val -value data 
     */
    checkBoxSelect(val):void {
        if (val.source.name == 'Fees') {
            if (val.checked == true) {
                this.FeesCheckVal = val.source.name
            } else {
                this.FeesCheckVal = ''
            }
        } else if (val.source.name == 'Sundries') {
            if (val.checked == true) {
                this.SundriesCheckVal = val.source.name;
            } else {
                this.SundriesCheckVal = '';
            }
        } else if (val.source.name == 'Matter Expenses') {
            if (val.checked == true) {
                this.MatterExPenseCheckVal = val.source.name;
            } else {
                this.MatterExPenseCheckVal = '';
            }
        }
        this.onAmoPerChnage('Enter');
    }

    /**
     * This function is used to perctage type change
     * @param val -type of the value
     */
    PercentageTypeChange(val) {
        this.isAmount = val == "Percentage" ? true : false;
        if (val == 'Percentage') {
            this.iscalbyAmount = false;
            this.addInvoiceForm.controls['Percentage'].setValue(this.ammountStore);
        }
        else {
            this.addInvoiceForm.controls['amount'].setValue(this.f.Percentage.value);
            this.iscalbyAmount = true;
        }

        this.onAmoPerChnage('Enter');
    }

    /**
     * Thisa function is used to GSTTYe change
     * @param val -value
     */
    GSTTypeChange(val):void {
        if (val == 'ExGstAmount') {
            this.isCalIngst = false;
            this.GSTAmmountCange = 'Ex-GST';
        } else {
            this.isCalIngst = true;
            this.GSTAmmountCange = 'Inc-GST';
        }
        // this.CommonCalFuction();
        this.onAmoPerChnage('Enter');

    }

    /**
     * This function is used to change the Discount Type
     */
    DiscountTypeChange():void {
        if (this.f.Discount_type.value == 'Increase') {
            this.isIncrease = true;
        } else {
            this.isIncrease = false;
        }
        this.onAmoPerChnage('Enter');
    }

    /**
     * This function is used to change the amount data value
     */
    onAmoPerChnage(searchFilter = ""):void {
        // convert ammount to real formate
        if (this.f.Percentage_type.value == "Amount") {
            this.ammountStore = (this.f.amount.value).toString().replace(/[$,-]/g, '');
            if (!isNaN(this.ammountStore)) {
                this.previousAmmount = this.ammountStore;
                // let formattedAmount = this.currencyPipe.transform(this.ammountStore, '$');
                this.addInvoiceForm.controls['amount'].setValue(Number(parseFloat(this.ammountStore).toFixed(2)));
            }
            this.calDiscountAPIfun()
        } else {
            this.ammountStore = this.f.Percentage.value;
            this.calDiscountAPIfun()
        }
        // if (Number(this.WorkInProgressData.PRICE) < Number(this.ammountStore)) {
        //   this.addInvoiceForm.controls['ADDITIONALTEXT'].setValue('You have discounted by more then the selected items');
        // } else {
        //   // this.CommonCalFuction();
        //   // this.discountAmount.emit({ 'amount': Number(this.ammountStore), 'Percentage': this.f.Percentage.value, 'Percentage_type': this.f.Percentage_type.value, 'GST_type': this.f.GST_type.value, 'Discount_type': this.f.Discount_type.value });

        // }

    }

    /**
     * calDiscountAPIfun data value
     * @param data -data
     */
    async calDiscountAPIfun(data?: any) {
        let SendData: any = {
            'DISCOUNTFEES': this.f.Fees.value,
            'FEESEXTOTAL': Number(this.f.ActivityEXTOTAL.value),
            'FEESINCTOTAL': Number(this.f.ActivityINTOTAL.value),
            'DISCOUNTSUNDRIES': this.f.Sundries.value,
            'SUDNRIESEXTOTAL': Number(this.f.SundryEXTOTAL.value),
            'SUNDRIESINCTOTAL': Number(this.f.SundryINTOTAL.value),
            'DISCOUNTMATTEREXPENSES': this.f.MatterExpenses.value,
            'MATTEREXPENSEEXTOTAL': Number(this.f.WIPEXTOTAL.value),
            'MATTEREXPENSEINCTOTAL': Number(this.f.WIPINTOTAL.value),
            'INCREASE': this.isIncrease,
            'CALCINCGST': this.isCalIngst,
            'CALCULATEBYAMOUNT': this.iscalbyAmount,
            'DISCOUNTAMOUNT': Number(this.ammountStore),
        };

        let DataSet = this.addInvoiceForm.controls['FEEEARNERS']?.value;
        if (DataSet && DataSet.length > 0) {
            await DataSet.forEach(user => {


                let userSelected = Object.assign((this.selectedOption !== 'Selected Deliverables') ? this.feeEarnarList : this.feeEarnardeliverableList);
                let selectedUserIs = userSelected.filter((data) => {
                    return (this.selectedOption !== 'Selected Deliverables') ? user.FEEEARNERID !== '' && (data.USERID == user.FEEEARNERID) : user.FEEEARNERID !== '' && (data.DELIVERABLEID == user.FEEEARNERID);
                });

                if (selectedUserIs.length == 0) {
                    return;
                };

                if (this.selectedOption == 'Selected Deliverables') {
                    // user.MATTERDELIVERABLEID = selectedUserIs[0].DELIVERABLEID
                    // user.MATTERDELIVERABLEGUID = selectedUserIs[0].MATTERDELIVERABLEGUID;
                    delete user.FEEEARNERID;
                    delete user.FEEEARNERGUID;
                } else {
                    user.FEEEARNERID = user.FEEEARNERID;
                    user.FEEEARNERGUID = (selectedUserIs) ? (this.selectedOption !== 'Selected Deliverables') ? selectedUserIs[0].USERGUID : selectedUserIs[0].MATTERDELIVERABLEGUID : '';
                }


                user.INCREASE = (user.INCREASE == "") ? false : JSON.parse(user.INCREASE);
                user.CALCINCGST = (user.CALCINCGST == "") ? false : JSON.parse(user.CALCINCGST);
                user.DISCOUNTBYAMOUNT = (user.DISCOUNTBYAMOUNT == "") ? false : JSON.parse(user.DISCOUNTBYAMOUNT);
                user.DISCOUNTAMOUNT = user.DISCOUNTAMOUNT;





                if (this.selectedOption == "Selected Deliverables") {
                    // for deliverables
                    const currentWorkItem = this.workItemsData.filter((data) => {
                        return data.MATTERDELIVERABLEGUID == selectedUserIs[0].MATTERDELIVERABLEGUID;
                    });

                    if (currentWorkItem[0]) {
                        user.FEESEXTOTAL = (this.allItemsTotalObj[currentWorkItem[0].FEEEARNER]) ? this.allItemsTotalObj[currentWorkItem[0].FEEEARNER]["PRICE"] : 0;
                        user.FEESINCTOTAL = (this.allItemsTotalObj[currentWorkItem[0].FEEEARNER]) ? this.allItemsTotalObj[currentWorkItem[0].FEEEARNER]["PRICEINCGST"] : 0;

                        user.MATTERDELIVERABLEID = currentWorkItem[0].DELIVERABLEID;
                        user.MATTERDELIVERABLEGUID = currentWorkItem[0].MATTERDELIVERABLEGUID
                    }
                    // ends here ~ for deliverables
                } else {
                    user.FEESEXTOTAL = (this.allItemsTotalObj[user.FEEEARNERID]) ? this.allItemsTotalObj[user.FEEEARNERID]["PRICE"] : 0;
                    user.FEESINCTOTAL = (this.allItemsTotalObj[user.FEEEARNERID]) ? this.allItemsTotalObj[user.FEEEARNERID]["PRICEINCGST"] : 0;
                }

                delete user.DISCOUNTANS;
            });


            // set variables
            if (this.selectedOption === 'Selected Deliverables') {
                SendData.MATTERDELIVERABLES = DataSet;
            } else {
                SendData.FEEEARNERS = DataSet;
            }
        };
        // ends here ~  set variables

        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData({ "Action": "CalcInvoiceDiscount",Data:SendData }, 'utility').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                let data = response.DATA;
                this.addInvoiceForm.controls['ADDITIONALTEXT'].setValue(data.DISCOUNTDESC);
                this.discountAmount.emit({ 'DISCOUNTEXGST': Number(data.DISCOUNTEXGST), 'DISCOUNTGST': Number(data.DISCOUNTGST), 'DISCOUNTINCGST': Number(data.DISCOUNTINCGST) });
                this.isLoadingResults = false;
                let SuccessData = [];
                if (this.selectedOption == 'Selected Deliverables') {
                    SuccessData = response.DATA.MATTERDELIVERABLES;
                } else {
                    SuccessData = response.DATA.FEEEARNERS;
                }

                if (data && SuccessData.length !== 0) {

                    // set data into form
                    DataSet.forEach(async (ItemUser, index) => {
                        const myForm = (<FormArray>this.addInvoiceForm.get("FEEEARNERS")).at(index);
                        if (ItemUser.CALCINCGST) {
                            myForm.patchValue({
                                DISCOUNTANS: (SuccessData.length !== 0) ? Number(SuccessData[index].DISCOUNTEXGST) : Number(0)
                            });

                        } else {
                            myForm.patchValue({
                                DISCOUNTANS: (SuccessData.length !== 0) ? Number(SuccessData[index].DISCOUNTINCGST) : Number(0)
                            });
                        }
                    });
                    // ends here ~ set data into form

                    // old logic
                    // DataSet.forEach(async (ItemUser, index) => {
                    //   let finalDataIs = await SuccessData.filter((e) => {
                    //     return e.FEEEARNERID == ItemUser.FEEEARNERID
                    //   });

                    //   const myForm = (<FormArray>this.addInvoiceForm.get("FEEEARNERS")).at(index);
                    //   if (ItemUser.CALCINCGST) {
                    //     myForm.patchValue({
                    //       DISCOUNTANS: (finalDataIs.length !== 0) ? Number(finalDataIs[index].DISCOUNTEXGST) : Number(0)
                    //     });

                    //   } else {
                    //     myForm.patchValue({
                    //       DISCOUNTANS: (finalDataIs.length !== 0) ? Number(finalDataIs[index].DISCOUNTINCGST) : Number(0)
                    //     });
                    //   }
                    // });
                    // ends here ~ old logic
                }
            }
        }, error => {
            // this.toastr.error(error);
        });
    }

    /**
     * This function is used to change the currency data value
     * @param event -event data value
     */
    async currencyChange(event) {
        if (this.selectedOption == 'All Fees/Activities') {
            this.FEEEARNERBTN = false;
            this.resetFeeEarners();
        } else {
            this.FEEEARNERBTN = true;
            if (this.selectedOption == 'Selected Deliverables') {
                this.getDeliverable();
            } else {
                this.getUser();
            };


            await this.resetFeeEarners();
            await this.addNewLine();
        }
    };

   
/**
 * This function is used to set the default field on change
 * @param selectedFor -select value data
 */
    setDefaultFieldOnChange(selectedFor):void {
        if (selectedFor == "Selected Fee Earners") {
            const itemIndex = this.addInvoiceForm.controls['FEEEARNERS']["controls"].length - 1;
            this.addInvoiceForm.controls['FEEEARNERS']["controls"][itemIndex]["controls"].INCREASE.setValue("false");
            this.addInvoiceForm.controls['FEEEARNERS']["controls"][itemIndex]["controls"].CALCINCGST.setValue("true");
            this.addInvoiceForm.controls['FEEEARNERS']["controls"][itemIndex]["controls"].DISCOUNTBYAMOUNT.setValue("false");
        } else if (selectedFor == "Selected Deliverables") {
            const itemIndex = this.addInvoiceForm.controls['FEEEARNERS']["controls"].length - 1;
            this.addInvoiceForm.controls['FEEEARNERS']["controls"][itemIndex]["controls"].INCREASE.setValue("false");
            this.addInvoiceForm.controls['FEEEARNERS']["controls"][itemIndex]["controls"].CALCINCGST.setValue("true");
            this.addInvoiceForm.controls['FEEEARNERS']["controls"][itemIndex]["controls"].DISCOUNTBYAMOUNT.setValue("false");
        }
    }

    
/**
 * This function is used to reset the Fee Earners data value
 */
    resetFeeEarners():void {
        if (this.FEEEARNERS) {
            (<FormArray>this.addInvoiceForm.get("FEEEARNERS")).clear();
            this.FEEEARNERS.clear();
            this.FEEEARNERSLIST = [];
            // (this.addInvoiceForm.controls['FEEEARNERS'] as FormArray).clear();
        }
    }

  /**
   * This function is used to create the op filter data value
   * @returns -data value
   */
    createOPFilter(): FormGroup {
        return this.fb.group({
            FEEEARNERID: (this.feeEarnarList?.length > 0) ? this.feeEarnarList[0].USERID : '',
            FEEEARNERGUID: (this.feeEarnarList?.length > 0) ? this.feeEarnarList[0].USERGUID : '',
            INCREASE: [''],
            CALCINCGST: [''],
            DISCOUNTBYAMOUNT: [''],
            DISCOUNTAMOUNT: [''],
            FEESEXTOTAL: [''],
            FEESINCTOTAL: [''],
            DISCOUNTANS: ['']
        });
    }

    /**
     * This function is used to add the newline data value
     */
    addNewLine():void {
        // OPTIONAL FILTER
        this.FEEEARNERS = this.addInvoiceForm.get('FEEEARNERS') as FormArray;
        this.FEEEARNERS.push(this.createOPFilter());
        this.FEEEARNERSLIST.push(this.FEEEARNERS.value[this.FEEEARNERS.value.length - 1]);
        this.setDefaultFieldOnChange(this.selectedOption);
    };

    /**
     * This function is used to remove the fee earner
     * @param isIndex -isIndex data value
     */
    async removeFeeEarner(isIndex) {
        this.FEEEARNERSLIST.splice(isIndex, 1);
        this.FEEEARNERS.controls.splice(isIndex, 1);
        this.addInvoiceForm.value.FEEEARNERS.splice(isIndex, 1);

        // re-calculate price on remove item
        let objData = await this.calcPriceGST();
        this.calDiscountAPIfun(objData);
        // ends here ~ re-calculate price on remove item

    };

    /**
     * This function is used to select the FeeEarner
     * @param event -event data value
     * @param index -index data value
     */
    selectFeeEarnar(event, index):void {
        this.addInvoiceForm.value.FEEEARNERS[index].FEEEARNERID = event.value
        let userSelected = Object.assign(this.feeEarnarList);
        let selectedUserIs = userSelected.filter((user) => {
            return user.USERID == event.value;
        });
        this.addInvoiceForm.value.FEEEARNERS[index].FEEEARNERGUID = (selectedUserIs) ? selectedUserIs[0].USERGUID : '';
    };

    /**
     * Thisa function is used to select the Deliverable data value
     * @param event -event data value
     * @param index -index data value
     */
    selectDeliverable(event, index) {
        this.addInvoiceForm.value.FEEEARNERS[index].FEEEARNERID = event.value
        let userSelected = Object.assign(this.feeEarnardeliverableList);
        let selectedUserIs = userSelected.filter((user) => {
            return user.DELIVERABLEID == event.value;
        });

        this.addInvoiceForm.value.FEEEARNERS[index].FEEEARNERGUID = (selectedUserIs) ? selectedUserIs[0].MATTERDELIVERABLEGUID : '';
    };

    /**
     * This function is used to change the fee earner data value.
     */
    async onAmoPerChnageFeeEarnar(event, index) {
        // convert ammount to real formate
        let objData = await this.calcPriceGST();


        for (let data in this.addInvoiceForm.value.FEEEARNERS[index]) {
            if (this.addInvoiceForm.value.FEEEARNERS[index][data] == null) {
                this.addInvoiceForm.value.FEEEARNERS[index][data] = '';
            }
        };

        if (this.addInvoiceForm.value.FEEEARNERS[index].DISCOUNTAMOUNT.value == "" && this.addInvoiceForm.value.FEEEARNERS[index].DISCOUNTAMOUNT.value == false) {
            this.ammountStore = (this.addInvoiceForm.value.FEEEARNERS[index].DISCOUNTAMOUNT.value).toString().replace(/[$,-]/g, '');
            if (!isNaN(this.ammountStore)) {
                this.previousAmmount = this.ammountStore;
                let formattedAmount = this.currencyPipe.transform(this.ammountStore, '$');
                this.addInvoiceForm.value.FEEEARNERS[index].DISCOUNTAMOUNT = formattedAmount;
                // this.addInvoiceForm.controls['amount'].setValue(formattedAmount);
            }
            this.calDiscountAPIfun(objData)
        } else {
            this.ammountStore = this.f.Percentage.value;
            this.calDiscountAPIfun(objData)
        }
    };

    /**
     * This function is used to calculate the calcPriceGST 
     * @returns data value
     */
    calcPriceGST() {
        let amountEx = 0;
        let amountInc = 0;

        this.TaggedItems.forEach(userData => {
            amountEx += userData.PRICE;
            amountInc += userData.PRICEINCGST;
        });

        return { "AmountEx": amountEx, "AmountInc": amountInc }
    }

    /**
     * This function is used to change the Discount type
     * @param index -index data value
     */
    DiscountTypeChangeFreeEarner(index) {
        this.onAmoPerChnageFeeEarnar('Enter', index);
    };

    /**
     * This function is used to Percentage type change
     */
    PercentageTypeChangeFreeEarner(val, index):void {
        this.onAmoPerChnageFeeEarnar('Enter', index);
    };

    /**
     * This function is used to change the Fee Earner data value
     */
    GSTTypeChangeFreeEarner(val, index):void {
        this.onAmoPerChnageFeeEarnar('Enter', index);
    };

    /**
     * Thuis function is used to Tag on the fee Earner data value
     */
    feeEarnerTagged():void {
        this.feeEarnarListTagged = [];
        this.feeEarnarList.forEach(feeEarnarData => {
            if (this.TaggedItems.some((t) => t.FEEEARNER == feeEarnarData.USERID)) {
                this.feeEarnarListTagged.push(feeEarnarData);
            }
        });
    }

    /**
     * This function is used to set the FieldEnabledDisabledAccTo permission.
     */
    setFieldEnableDisableAccToPermission():void {
        let permissionDesc = JSON.parse(window.localStorage.getItem("set_tier_permission"));
        for (let i = 0; i < permissionDesc.length; i++) {
            if (permissionDesc[i]["FEATURENAME"] == "Advanced Discounting") {
                if (permissionDesc[i]["ALLOWED"] == 1) {
                    this.showApplyToWhatDropdown = true;
                }
                break;
            }
        }
    }
    // ends here ~ hide or show "Apply What To" field according to account permission

   /**
    * This function is used to get the Work items
    */
    getWorkItems():void {
        this.behaviorService.refreshinvoiceWorkItem.pipe(takeUntil(this._unsubscribeAll$)).subscribe((res) => {
            if (res) {
                this.workItemsData = res;
            };
        });

        // this._mainAPiServiceService.getSetData({ MATTERGUID: this.currentMatter.MATTERGUID, Invoiced: 'No', INVOICING: true }, 'GetWorkItems').subscribe(response => {
        //   if (response.CODE == 200 && response.STATUS == "success") {
        //     this.workItemsData = response.DATA.WORKITEMS;
        //   }
        // })
    }
    // ends here ~ get work items

}
