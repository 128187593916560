import { Injectable } from '@angular/core';
import { FuseConfigService } from 'src/@fuse/services/config.service';
import { BehaviorService } from './Behavior.service';
import { SortingBehaviourService } from './sorting-behaviour.service';
import { SocketService } from './socket.service';


@Injectable({ providedIn: 'root' })
export class AppPermissionsService {

    constructor(private _fuseConfigService: FuseConfigService, public behaviorService: BehaviorService, private SortingbehaviorService: SortingBehaviourService, private socketService: SocketService) { }
    PermissionsCons = {
        'MATTER DETAILS': '1', 'DAY BOOK / TIME ENTRIES': '2', 'CONTACTS': '3', 'ESTIMATES': '4', 'DOCUMENT/EMAIL GENERATION': '5', 'DOCUMENT REGISTER': '6', 'INVOICING': '7',
        'RECEIVE MONEY': '8', 'SPEND MONEY': '9', 'CHRONOLOGY': '10', 'TOPICS': '11', 'AUTHORITIES': '12', 'FILE NOTES': '13', 'SAFE CUSTODY': '14', 'SAFE CUSTODY PACKET': '15',
        'SEARCHING': '16', 'DIARY': '17', 'TASKS': '18', 'CHART OF ACCOUNTS': '19', 'GENERAL JOURNAL': '20', 'OTHER ACCOUNTING': '21', 'TRUST MONEY': '22', 'TRUST CHART OF ACCOUNTS': '23',
        'TRUST GENERAL JOURNAL': '24', 'TRUST REPORTS': '25', 'ACCOUNTING REPORTS': '26', 'MANAGEMENT REPORTS': '27', 'SYSTEM': '28', 'USERS': '29', 'ACTIVITIES/SUNDRIES': '30', 'PROTECTED TRUST': '31', 'DELIVERABLES': '32', 'DASHBOARDS': '33'
    };

    Permissions = [];

    /**
     * This function is used to set deault settings data
     * @param loginResponse -login response data
     */
    applictionSetting(loginResponse: any) {

        if (loginResponse) {
            let LoginData = {
                DELIVERABLES: loginResponse.DELIVERABLES,
                SessionToken: loginResponse.SESSIONTOKEN,
                TimeStampUTC: loginResponse.TIMESTAMPUTC,
                TimeStamp: loginResponse.TIMESTAMP,
                LoggedInStatus: loginResponse.LOGGEDINSTATUS,
                LicenceNumber: loginResponse.LICENCENUMBER,
                AllowMobileAccess: loginResponse.ALLOWMOBILEACCESS,
                UserGuid: loginResponse.USERGUID,
                UserId: loginResponse.USERID,
                UserName: loginResponse.USERNAME,
                PRODUCTTYPE: loginResponse.PRODUCTTYPE,
                DefaultQuantityType: loginResponse.DEFAULTQUANTITYTYPE,
                InitialWindow: loginResponse.INITIALWINDOW,
                ShowOutstanding: loginResponse.SHOWOUTSTANDING,
                ShowWIPGraph: loginResponse.SHOWWIPGRAPH,
                initialwindow: loginResponse.INITIALWINDOW,
                REGISTRATIONSTATUS: loginResponse.REGISTRATIONSTATUS,
                REGISTRATIONDATE: loginResponse.REGISTRATIONDATE,
                UNITSPERHOUR: loginResponse.UNITSPERHOUR,
                SORTBYSURNAME: loginResponse.SORTBYSURNAME,
                SORTMATTERALPHANUMERICALLY: loginResponse.SORTMATTERALPHANUMERICALLY,
                SORTPACKETSALPHANUMERICALLY: loginResponse.SORTPACKETSALPHANUMERICALLY,
                SHOWDISBURSEMENTS: loginResponse.SHOWDISBURSEMENTS,
                SHOWINACTIVEFEEEARNERS:loginResponse.SHOWINACTIVEFEEEARNERS,
                LOADALLUSERSMATTERS: loginResponse.LOADALLUSERSMATTERS,
                LOADPREVIOUSXDAYS: loginResponse.LOADPREVIOUSXDAYS,
                SHOWREMINDERS: loginResponse.SHOWREMINDERS,
                CONVERTTOPDF: loginResponse.CONVERTTOPDF,
                CONTACTDEFAULTTYPE: loginResponse.CONTACTDEFAULTTYPE,
                CONTACTSTARTSWITH: loginResponse.CONTACTSTARTSWITH,
                CONTACTACTIVE: loginResponse.CONTACTACTIVE,
                THEMECOLOUR: loginResponse.THEMECOLOUR,
                NAVBARHIDE: loginResponse.NAVBARHIDE,
                NAVBARFOLDED: loginResponse.NAVBARFOLDED,
                SIDEPANELHIDE: loginResponse.SIDEPANELHIDE,
                DISBURSEMENTACCOUNTGUID: loginResponse.DISBURSEMENTACCOUNTGUID,
                TAXPAYABLEACCOUNTGUID: loginResponse.TAXPAYABLEACCOUNTGUID,
                REGISTRATIONMESSAGE: loginResponse.REGISTRATIONMESSAGE,
                SAVEEMAILSTOREGISTER: loginResponse.SAVEEMAILSTOREGISTER,
                OPENINNEWTAB: loginResponse.OPENINNEWTAB,
                OPENINNEWTABWINDOWS: loginResponse.OPENINNEWTABWINDOWS,
                WORKINGONBEHALFOFGUID: loginResponse.WORKINGONBEHALFOFGUID,
                DEFAULTOWNERGUID: loginResponse.WORKINGONBEHALFOFGUID,
                DEFAULTPRIMARYFEEEARNERGUID: loginResponse.WORKINGONBEHALFOFGUID,
                DEFAULTASSISTANTGUID: loginResponse.WORKINGONBEHALFOFGUID,
                EXPORTACCOUNTSSYSTEM: loginResponse.EXPORTACCOUNTSSYSTEM,
                EXPORTACCOUNTSVERSION: loginResponse.EXPORTACCOUNTSVERSION,
                EXPORTACCOUNTSEMAIL: loginResponse.EXPORTACCOUNTSEMAIL,
                ALLOWESTIMATERANGE: loginResponse.ALLOWESTIMATERANGE,
                SHOWCONFLICTCHECKFORNEWMATTER: loginResponse.SHOWCONFLICTCHECKFORNEWMATTER,
                SHOWSETUPGUIDE: loginResponse.SHOWSETUPGUIDE,
                SETUPGUIDEEXPANDED: loginResponse.SETUPGUIDEEXPANDED,
                CHARGEDISBGSTONGSTFREEMATTERS: loginResponse.CHARGEDISBGSTONGSTFREEMATTERS,
                THUMBNAILSIZE: loginResponse.THUMBNAILSIZE,
                DontAutoAllocateReceipt: loginResponse.DONTAUTOALLOCATERECEIPT,
                DOCUMENTSYSTEM: loginResponse.DOCUMENTSYSTEM,
                DEFAULTMATTERCLASS: loginResponse.DEFAULTMATTERCLASS,
                APPURL: loginResponse.APPURL,
                EmailSystem: (loginResponse.EMAILSYSTEM) ? loginResponse.EMAILSYSTEM : '',
                APPCUESDATA: (loginResponse.APPCUESDATA) ? loginResponse.APPCUESDATA : '',
                USEV11UI: loginResponse.USEV11UI,
                ONBOARDING: loginResponse.ONBOARDING,
                PREFILLTIMEOFDAY: (loginResponse.PREFILLTIMEOFDAY) ? loginResponse.PREFILLTIMEOFDAY : '',
                POPUPSELECTMATTERFIRST: loginResponse.POPUPSELECTMATTERFIRST,
                DONTGENERATERECEIPT:loginResponse.DONTGENERATERECEIPT,
                SHOWINACTIVEMATTERS:loginResponse.SHOWINACTIVEMATTERS
            };
            localStorage.setItem('currentUser', JSON.stringify(LoginData));
            let contactFilterArray: any = {
                'active': loginResponse.CONTACTACTIVE == "all" ? '' : loginResponse.CONTACTACTIVE,
                'FirstLetter': loginResponse.CONTACTSTARTSWITH == "all" ? '' : loginResponse.CONTACTSTARTSWITH.toUpperCase(),
                'SEARCH': '',
                'ContactType': loginResponse.CONTACTDEFAULTTYPE == "all" ? '' : loginResponse.CONTACTDEFAULTTYPE
            };
            localStorage.setItem('contact_Filter', JSON.stringify(contactFilterArray));
            localStorage.setItem('contact_Filter_After_Reset_Filter', JSON.stringify(contactFilterArray));
            this.SortingbehaviorService.setContactFilterData(contactFilterArray);
            localStorage.setItem('contact_Filter_popup', JSON.stringify(contactFilterArray));

            localStorage.setItem('session_token', loginResponse.SESSIONTOKEN);
            let theme_name = loginResponse.PRODUCTTYPE == "Barrister" ? "theme-default" : "theme-yellow-light";
            localStorage.setItem('theme_type', theme_name);
            this.appPermissions(loginResponse.PERMISSIONS);
            localStorage.removeItem('Login_response');
            this._fuseConfigService.config = {
                colorTheme: loginResponse.THEMECOLOUR,
                layout: { sidepanel: { hidden: loginResponse.SIDEPANELHIDE } }
            };
            this.behaviorService.setToolBaarSetting({ NAVBARFOLDED: loginResponse.NAVBARFOLDED, NAVBARHIDE: loginResponse.NAVBARHIDE, OPENINNEWTABWINDOWS: loginResponse.OPENINNEWTABWINDOWS });
            // if (LoginData.SessionToken && LoginData.SHOWSETUPGUIDE == 1) {
            //     setTimeout(() => {
            //         $('body').addClass('setupguide-active');
            //          $('.time_entry_sidebar').addClass('active')
            //     }, 1000);
            // }
        }
    }

    /**
     * This function is used to set the App permission data
     * @param setting -setting data value
     */
    appPermissions(setting: any) {
        if (setting) {
            for (let i in setting) {
                this.setObj(setting[i], i);
            }
            localStorage.setItem('app_permissions', JSON.stringify(this.Permissions));
            this.behaviorService.setAppPermissionsSetting(this.Permissions);
        }
    }

    /**
     * This function is used to set the obj data value
     * @param Obj -Obj data
     * @param type -type of the object value 
     */
    setObj(Obj: any, type: any) {
        let subPermissions = {};
        Obj.forEach(function (value) {
            subPermissions[value.NAME] = value.VALUE;
        });
        if (Object.keys(subPermissions).length != 0) {
            this.Permissions[this.PermissionsCons[type]] = subPermissions;
        }
    }
}
