<div cdkDrag class="popup_main_div delete_main_popup confirm_popup" style="width:400px">
    <h1 cdkDragHandle matDialogTitle>
        {{confirmTitle}}
        <button class="btn close-button-icon" mat-icon-button [mat-dialog-close]="2" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h1>
    <div mat-dialog-content>
        {{confirmMessage}}
        <br>
        <p *ngIf="confirmMessage1">
            <br> {{confirmMessage1}}
            <br>
        </p>
        <p *ngIf='is_count'>
            You will be logged out in {{secondsToHms(count)}} minutes
        </p>
    </div>
    <div mat-dialog-actions class="popup_ftr">
        <div class="right_btn_child" *ngIf="!is_count && confirm1 && !is_exportData && !is_fileoverwrite">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" [mat-dialog-close]="true" type="button"> {{confirm1}}</button>
        </div>
        <div class="right_btn_child cancel_confirm" *ngIf="!is_count && confirm2 && !is_exportData && !is_fileoverwrite">
            <button mat-button [mat-dialog-close]="false" class="cancel mat-button" type="button" matbadgecolor="accent">{{confirm2}}
            </button>
        </div>
        <div class="right_btn_child cancel_confirm" *ngIf="!is_count && confirm3 && confirm3 !=''&& !is_exportData && !is_fileoverwrite">
            <button mat-button [mat-dialog-close]="2" class="cancel mat-button" type="button" matbadgecolor="accent">{{confirm3}}
            </button>
        </div>

        <div class="right_btn_child" *ngIf="is_count && confirm1">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" (click)="closePopup()" [mat-dialog-close]="'Continue'" type="button"> {{confirm1}}</button>
        </div>
        <div class="right_btn_child cancel_confirm" *ngIf="is_count && confirm2">
            <button mat-button [mat-dialog-close]="'Logout'" (click)="closePopup()" class="cancel mat-button" type="button" matbadgecolor="accent">{{confirm2}}
            </button>
        </div>
        <div class="right_btn_child" *ngIf="is_exportData && confirm1 && !is_okbutton">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" (click)="exportDataClick()" color="accent" type="button"> {{confirm1}} </button>
        </div>
        <div class="right_btn_child cancel_confirm" *ngIf="(is_exportData && confirm2) || is_okbutton">
            <button mat-button [mat-dialog-close]="true" class="cancel mat-button" type="button" matbadgecolor="accent">{{confirm2}}
            </button>
        </div>
        <!-- for Rename overwrite  -->
        <div class="right_btn_child" *ngIf="is_fileoverwrite && confirm1 ">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" type="button" (click)="overwriteClick('overwrite')"> {{confirm1}} </button>
        </div>
        <div class="right_btn_child" *ngIf="is_fileoverwrite && confirm2">
            <button mat-button class="cancel mat-button" type="button" matbadgecolor="accent" (click)="overwriteClick('rename')">{{confirm2}}
            </button>
        </div>
        <div class="right_btn_child cancel_confirm" *ngIf="is_fileoverwrite && confirm3">
            <button mat-button [mat-dialog-close]="false" class="cancel mat-button" type="button" matbadgecolor="accent">{{confirm3}}
            </button>
        </div>



    </div>
</div>