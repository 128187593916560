<div class=" one " [formGroup]="contactForm" fxLayout="column">
    <h3>Street Address</h3>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS1,'has-warning':errorWarningData.Warning?.ADDRESS1}">
            <mat-label>Address 1</mat-label>
            <input name="ADDRESS1" [formControlName]="'ADDRESS1'" matInput>
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field> -->
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
        [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS1,'has-warning':errorWarningData.Warning?.ADDRESS1}">
            <mat-label>Address 1</mat-label>
            <input type="text" id="addressInput" name="ADDRESS1" formControlName="ADDRESS1" matInput  matTooltip="{{allToolTip.ADDRESS1?.HOVER}}"
                placeholder="Start typing to search for an address" #addressInput [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <!-- (click)="setAutoField(option ,1)" -->
                <mat-option *ngFor="let option of searchData; let i = index" [value]="option.COMBINED"  (onSelectionChange)="setAutoField(option ,1)">
                    {{option.COMBINED}}
                </mat-option>
            </mat-autocomplete>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDRESS1?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDRESS1?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS2,'has-warning':errorWarningData.Warning?.ADDRESS2}">
            <mat-label>Address 2</mat-label>
            <input name="ADDRESS2" [formControlName]="'ADDRESS2'" matInput  matTooltip="{{allToolTip.ADDRESS2?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDRESS2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDRESS2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS3,'has-warning':errorWarningData.Warning?.ADDRESS3}">
            <mat-label>Address 3</mat-label>
            <input name="ADDRESS3" [formControlName]="'ADDRESS3'" matInput  matTooltip="{{allToolTip.ADDRESS3?.HOVER}}"> 
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDRESS3?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.ADDRESS3?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="30" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SUBURB,'has-warning':errorWarningData.Warning?.SUBURB}">
            <mat-label>Suburb</mat-label>
            <input name="SUBURB" [formControlName]="'SUBURB'" matInput  matTooltip="{{allToolTip.SUBURB?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBURB?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBURB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.STATE,'has-warning':errorWarningData.Warning?.STATE}">
            <mat-label>State</mat-label>
            <input name="STATE" [formControlName]="'STATE'" matInput  matTooltip="{{allToolTip.STATE?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.STATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTCODE,'has-warning':errorWarningData.Warning?.POSTCODE}">
            <mat-label>Postcode</mat-label>
            <input name="POSTCODE" [formControlName]="'POSTCODE'" matInput #postalCode  matTooltip="{{allToolTip.POSTCODE?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTCODE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTCODE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.COUNTRY,'has-warning':errorWarningData.Warning?.COUNTRY}">
            <mat-label>Country</mat-label>
            <input name="COUNTRY" [formControlName]="'COUNTRY'" matInput  matTooltip="{{allToolTip.COUNTRY?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COUNTRY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COUNTRY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    </div> -->

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-checkbox name="SAMEASSTREET" #chk [formControlName]="'SAMEASSTREET'"
            (change)="triggerSomeEvent(contactForm)" class=" ">Street Address is Postal Address </mat-checkbox> -->
            <mat-slide-toggle class="pb-fieldspace"   formControlName="SAMEASSTREET" (change)="triggerSomeEvent(contactForm)">Street Address is Postal Address</mat-slide-toggle>
    </div>
    <br>
    <h3>Postal Address</h3>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field appearance="outline" fxFlex="33" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALADDRESS1,'has-warning':errorWarningData.Warning?.POSTALADDRESS1}">
            <mat-label>Address 1</mat-label>
            <input name="POSTALADDRESS1" [formControlName]="'POSTALADDRESS1'" matInput>
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field> -->

        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
        [ngClass]="{'has-error':errorWarningData.Error?.POSTALADDRESS1,'has-warning':errorWarningData.Warning?.POSTALADDRESS1}">
            <mat-label>Address 1</mat-label>
            <input type="text"name="POSTALADDRESS1" id="addressInput1" #addressInput1 [formControlName]="'POSTALADDRESS1'" matInput matTooltip="{{allToolTip.POSTALADDRESS1?.HOVER}}" placeholder="Start typing to search for an address" [matAutocomplete]="auto1">
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <!-- (click)="setAutoField(option , 2)" -->
                <mat-option *ngFor="let option of searchAddressForm2; let i = index" [value]="option.COMBINED" (onSelectionChange)="setAutoField(option ,2)">
                    {{option.COMBINED}}
                </mat-option>
            </mat-autocomplete>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTALADDRESS1?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALADDRESS1?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALADDRESS2,'has-warning':errorWarningData.Warning?.POSTALADDRESS2}">
            <mat-label>Address 2</mat-label>
            <input name="POSTALADDRESS2" [formControlName]="'POSTALADDRESS2'" matInput  matTooltip="{{allToolTip.POSTALADDRESS2?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTALADDRESS2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALADDRESS2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALADDRESS3,'has-warning':errorWarningData.Warning?.POSTALADDRESS3}">
            <mat-label>Address 3</mat-label>
            <input name="POSTALADDRESS3" [formControlName]="'POSTALADDRESS3'" matInput  matTooltip="{{allToolTip.POSTALADDRESS3?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTALADDRESS3?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALADDRESS3?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="30" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALSUBURB,'has-warning':errorWarningData.Warning?.POSTALSUBURB}">
            <mat-label>Suburb</mat-label>
            <input name="POSTALSUBURB" [formControlName]="'POSTALSUBURB'" matInput  matTooltip="{{allToolTip.POSTALSUBURB?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTALSUBURB?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALSUBURB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALSTATE,'has-warning':errorWarningData.Warning?.POSTALSTATE}">
            <mat-label>State</mat-label>
            <input name="POSTALSTATE" [formControlName]="'POSTALSTATE'" matInput  matTooltip="{{allToolTip.POSTALSTATE?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTALSTATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALSTATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALPOSTCODE,'has-warning':errorWarningData.Warning?.POSTALPOSTCODE}">
            <mat-label>Postcode</mat-label>
            <input name="POSTALPOSTCODE" [formControlName]="'POSTALPOSTCODE'" matInput #postalCode  matTooltip="{{allToolTip.POSTALPOSTCODE?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList) ? toolTipList?.POSTALPOSTCODE?.COPYVALUE : '')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALPOSTCODE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="px-4"
            [ngClass]="{'has-error':errorWarningData.Error?.POSTALCOUNTRY,'has-warning':errorWarningData.Warning?.POSTALCOUNTRY}">
            <mat-label>Country</mat-label>
            <input name="POSTALCOUNTRY" [formControlName]="'POSTALCOUNTRY'" matInput  matTooltip="{{allToolTip.POSTALCOUNTRY?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTALCOUNTRY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSTALCOUNTRY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    </div> -->
    <h3>DX Address</h3>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DX,'has-warning':errorWarningData.Warning?.DX}">
            <mat-label>DX</mat-label>
            <input name="DX" [formControlName]="'DX'" matInput  matTooltip="{{allToolTip.DX?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DX?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.DX?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pl-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DXSUBURB,'has-warning':errorWarningData.Warning?.DXSUBURB}">
            <mat-label>Suburb</mat-label>
            <input name="DXSUBURB" [formControlName]="'DXSUBURB'" matInput  matTooltip="{{allToolTip.DXSUBURB?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DXSUBURB?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DXSUBURB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>