import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";
import { MatChipInputEvent } from '@angular/material/chips';
import { ContactDialogComponent } from '../../../contact/contact-dialog/contact-dialog.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ContactSelectDialogComponent } from '@pages/contact/contact-select-dialog/contact-select-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-estate-liability',
  templateUrl: './estate-liability.component.html',
  styleUrls: ['./estate-liability.component.scss']
})
export class EstateLiabilityComponent implements OnInit, OnDestroy {
  @ViewChild('liabilityForm', { static: true }) ngForm: NgForm;
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  dialogTitle: any;
  isLoadingResults: boolean = false;
  action: any;
  isspiner: boolean = false;
  confirmDialogRef: any;
  currentMatter: any;
  ContactDetails: any;
  EstateLiabilityForm:any ={};
  estateLiabilityData:any;
  formChangesSubscription:Subscription;
  windowNameId:any;
  toolTipList: any;
  testBool: boolean=false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('MatterInput2', { static: false }) MatterInputContact: ElementRef<HTMLInputElement>;
  Contactname: any = [];
  matterdata: any;
  searchContact: any;
  exportdatavalueOFContact: any;
  result: any;
  searchContactData;
  Contactlist: any=[];
  selectedContctData: any = [];
  appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub: Subscription;
  private searchTerms = new Subject<string>();
  subscription: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService, public _matDialog: MatDialog,
    private toastr: ToastrService, public dialogRef: MatDialogRef<EstateLiabilityComponent>,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    public globalFunService:GlobalFunctionsService,
    public tooltipService:TooltipService,
    ) {

    this.action = data.action;

    
    this.behaviorService.estateliability$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
          this.estateLiabilityData = result;
      }
    });

    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.currentMatter = result;
    //   } else {
    //     this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
    //   }
    // });
    
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
           const windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
      this.currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
      if (this.currentMatter) {
        // localStorage.setItem('set_active_matters', JSON.stringify(this.currentMatter));
      } else {
        if (result) {
          this.currentMatter = result;
          localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        } else {
            this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
            localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        }
      }
    });

    if (this.action === 'new') {
      this.ContactDetails = data.result;
      this.EstateLiabilityForm.CONTACTGUID = (this.ContactDetails.CONTACTGUID);
      this.EstateLiabilityForm.CONTACTNAME = (this.ContactDetails.CONTACTNAME + ' - ' + this.ContactDetails.SUBURB);
      this.dialogTitle = 'New Liability';
      this.validationColor();
      this.getDefaultEstateLiability()
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Liability';
      this.LoadData();
    }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() { 
    this.formChangesSubscription = this.ngForm.form.valueChanges.subscribe(x => {
      this.changeForControl(x)
    })
    this.setTooltipData()
    this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.selectContactFormSearch(term)) // Switch to new observable on new term
    ).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.exportdatavalueOFContact = response.DATA;
        this.searchContactData = response.DATA.RECORDS;
        this.Contactlist = this.searchContactData;
      }
    });
   }

   /**
    * This function is used to set the tooltip data value
    */
   async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['EstateLiability'] ){
      this.toolTipList  = TooltipData['EstateLiability'];
    }else{
      this.tooltipService.setToolTipData = ('EstateLiability');
     }
  };

  /**
   * This function is used to Toggle For Updates
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to change for control
   * @param formControl -formcontrol value
   */
   changeForControl(formControl){
    const dubObj ={...this.errorWarningDataArray.Error};
    let element = this.errorWarningData.Error;
    for (const [key, value] of Object.entries(dubObj)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element[key];
       dubObj[key] = value;
       this.errorWarningDataArray.Error = {...dubObj}
     } else if (!formControl[KEY]) {
        element[key] = dubObj[key];
     }
    }

    const dubObj_1 ={...this.errorWarningDataArray.Warning};
    let element_1 = this.errorWarningData.Warning;
    for (const [key, value] of Object.entries(dubObj_1)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element_1[key];
       dubObj_1[key] = value;
       this.errorWarningDataArray.Warning = {...dubObj_1}
     } else if (!formControl[KEY]) {
        element_1[key] = dubObj_1[key];
     }
    }
  }

  ngOnDestroy(): void {
      this.formChangesSubscription?.unsubscribe();
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub?.unsubscribe();
      this.subscription?.unsubscribe();
  }

  /**
   * This function is used to set the validation color
   */
  validationColor() {
    let tempError: any = [];
    let tempWarning: any = [];
    // tempError["CONTACTGUID"] = {};
    // tempError["TENANCY"] = {};
    this.errorWarningData = { Error: tempError, Warning: tempWarning };
    this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
  }

  /**
   * This function is used to change the model
   */
  modelChanged(event, fieldName){
    const numbers = this.EstateLiabilityForm[fieldName];
    const numericValue = parseFloat(numbers.replace(/,/g, ''));
    const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
    event.target.value = formattedNumber;
  }

  /**
   * This function is used to load the data value.
   */
  LoadData():void {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({ Action: "GetData", FILTERS: { MATTERGUID: this.currentMatter.MATTERGUID, ESTATELIABILITYGUID: this.estateLiabilityData.ESTATELIABILITYGUID } }, 'estate-liability').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
            this.EstateLiabilityForm = response.DATA.RECORDS[0];
            this.EstateLiabilityForm.AMOUNT = new DecimalPipe('en-GB').transform(this.EstateLiabilityForm.AMOUNT,'1.2-2');
            this.EstateLiabilityForm['SECURED'] = (response.DATA.RECORDS[0]['SECURED'] == 'No' )? false : true;
            this.toolTipList = response.DATA.FIELDTIPS;
            let temTODATE = this.EstateLiabilityForm.DATEINCURRED.split("/");
            this.EstateLiabilityForm.DATEINCURRED =  new Date(temTODATE[1] + "/" + temTODATE[0] + "/" + temTODATE[2]);
            this.Contactname = [ this.EstateLiabilityForm.CONTACTNAME];
        }
        this.isLoadingResults = false;
    }, error => {
        this.isLoadingResults = false;
        this.toastr.error(error);
    });
  }

  /**
   * This function is used to submit the data value 
   * @param form -form value
   */
  OnSubmit(form?:any){
    this.isspiner = true;
    const FormAction = this.action === 'new' ? 'insert' : 'update';
    delete this.EstateLiabilityForm.CONTACTNAME;
    this.EstateLiabilityForm.DATEINCURRED = this.datepipe.transform(this.EstateLiabilityForm.DATEINCURRED, "dd/MM/yyyy");
    this.EstateLiabilityForm.AMOUNT = this.EstateLiabilityForm.AMOUNT ? parseFloat(this.EstateLiabilityForm.AMOUNT.replace(/,/g, '')).toFixed(2) : 0;
    let Data: any = {...this.EstateLiabilityForm,  MATTERGUID: this.currentMatter.MATTERGUID}
    let details = { FormAction: FormAction, VALIDATEONLY: true, Data: Data, };
    this._mainAPiServiceService.getSetData(details, "estate-liability").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 451 && response.STATUS == "warning") {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 450 && response.STATUS == "error") {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
        }
    }, (error) => {
        this.isspiner = false;
        this.toastr.error(error);
    });
  }

  /**
   * This function is used to check the validation data value
   */
  async checkValidation(bodyData: any, details: any) {
      // let errorData: any = [];
      // let warningData: any = [];
      // let tempError: any = [];
      // let tempWarning: any = [];
      // bodyData.forEach(function (value) {
      //     if (value.VALUEVALID == "No") {
      //         errorData.push(value.ERRORDESCRIPTION);
      //         tempError[value.FIELDNAME] = value;
      //     } else if (value.VALUEVALID == "Warning") {
      //         tempWarning[value.FIELDNAME] = value;
      //         warningData.push(value.ERRORDESCRIPTION);
      //     }
      // });
      // this.errorWarningData = { Error: tempError, Warning: tempWarning };
      // this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
      // if (Object.keys(errorData).length != 0) {
      //     this.toastr.error(errorData);
      //     this.isspiner = false;
      // } else if (Object.keys(warningData).length != 0) {
      //     this.isspiner = false;
      //     this.confirmDialogRef = this._matDialog.open(
      //         FuseConfirmDialogComponent,
      //         {
      //             disableClose: true,
      //             width: "100%",
      //             data: warningData,
      //         }
      //     );
      //     this.confirmDialogRef.componentInstance.confirmMessage =
      //         "Are you sure you want to Save?";
      //     this.confirmDialogRef.afterClosed().subscribe((result) => {
      //         if (result) {
      //             this.isspiner = true;
      //             this.saveData(details);
      //         } else {
      //             this.isspiner = false;
      //         }
      //         this.confirmDialogRef = null;
      //     });
      // } else if (
      //     Object.keys(warningData).length == 0 &&
      //     Object.keys(errorData).length == 0
      // ) {
      //     this.saveData(details);
      // }

      this.isspiner = false;
      await this.globalFunService.checkValidation(bodyData, details)
      .subscribe(data => {
        if (data) {
          this.errorWarningData = data.errorWarningData;
          //this.errorWarningDataArray = data.errorWarningData;
          if (data.callback) {
            this.saveData(details);
          }
        }
      });
  } 

  /**
   * This function is used to save the data value
   * @param data -data value
   */
  saveData(data: any):void {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, "estate-liability").subscribe((response) => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            if (this.action !== "edit") {
                this.toastr.success(" save successfully");
            } else {
                this.toastr.success(" update successfully");
            }
            $('#getEstateLiability').click();
            this.dialogRef.close(true);
        } else if (response.CODE == 451 && response.STATUS == "warning") {
            this.isspiner = false;
            this.toastr.warning(response.MESSAGE);
        } else if (response.CODE == 450 && response.STATUS == "error") {
            this.isspiner = false;
            this.toastr.error(response.MESSAGE);
        } else if (response.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
        }
    }, (error) => {
        this.isspiner = false;
        this.toastr.error(error);
    });
  }    

  /**
   * This function is used to Contact the Matter
   */
  ContactMatter():void {
    this.searchContactData = [];
    this.Contactlist = this.searchContactData;

    const dialogRef = this._matDialog.open(ContactSelectDialogComponent, {
      width: '100%', disableClose: true, data: { type: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'MatterPopupComponent');
      if (result) {
        this.EstateLiabilityForm.CONTACTGUID = (result.CONTACTGUID);
        this.EstateLiabilityForm.CONTACTNAME = (result.CONTACTNAME + ' - ' + result.SUBURB);
        this.Contactname=[result.CONTACTNAME];
      }
    });
  }

  /**
   * This function is used to get the Default Estate Liability
   */
  getDefaultEstateLiability():void {
        this._mainAPiServiceService.getSetData({ Action: "Default", DATA: {"CONTACTGUID":"","DATEINCURRED":null,"MATTERGUID":""}}, 'estate-liability').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
          if (response.CODE == 200 && response.STATUS == "success") {
            this.EstateLiabilityForm=response.DATA.DEFAULTVALUES
            this.EstateLiabilityForm['SECURED'] = (response.DATA.DEFAULTVALUES['SECURED'] == 'No' )? false : true;
          }
          this.isLoadingResults = false;
      }, error => {
          this.isLoadingResults = false;
          this.toastr.error(error);
      });
  }
  // ends here ~ get default value for estate liability.

  /**
   * This function is used to selectNewContect
   */
  async selectNewContect(){
    const dialogRef = await this._matDialog.open(ContactDialogComponent, {
      disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true , contect_details : '' , flagNewConactMatter : true}
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.CONTACTGUID) {
          let contectIdIs = await this.getContactNewId();
          this.behaviorService.MatterpopupContactData$.next(contectIdIs);
          this.EstateLiabilityForm['CONTACTGUID'] = result.CONTACTGUID;
          this.EstateLiabilityForm['CONTACTNAME'] = result.CONTACTNAME;
          this.Contactname = [result.CONTACTNAME];
         // this.getPacket({ CONTACTGUID: result.CONTACTGUID});
          // this.closeDialogRef.close(result);
        }else{
          this.behaviorService.MatterpopupContactData$.next(null)
        }
      }
    });
  }

  /**
   * This function is used to get Contact New Id.
   */
  getContactNewId(){
    let contecttId = '';
    let postData = {
      "Action": "GetContactId",
      "VALIDATEONLY": true,
      "Data": {},
    };

    return new Promise((response , reject)=>{
      this.sub = this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
        if(res.CODE == 200){
          contecttId = res.DATA.CONTACTID;
          response(contecttId);
        }else{
          response(null);
        }
      });
    })
  };

  onKeyUp(event: any): void {
    const searchValue = event.target.value;
    this.searchTerms.next(searchValue);
  }

/**
 * This function is used to select the Contact Form Search
 * @param event -event data value
 */
  selectContactFormSearch(event){
    // let searchobj = { FastSearch:'True', Search: event }
    let payload = {
      "ACTION": "GetData",
      "FILTERS": {
        "FASTSEARCH": 1,
        "SEARCH": event
      },
    }
    return this._mainAPiServiceService.getSetData(payload, 'contact')
  }

  /**
   * This function is used to Contact Data Show
   * @param event -event data value
   */
  Contactdatashow(event):void {
    if(this.exportdatavalueOFContact && this.exportdatavalueOFContact.RECORDS){
      this.result=this.exportdatavalueOFContact.RECORDS[0]
      this.EstateLiabilityForm['CONTACTNAME'] = null;
      if (event.input) {
        event.input.value = '';
      } else {
        this.MatterInputContact.nativeElement.value = '';
      }
    } 

    if (this.result) {
      localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
      this.EstateLiabilityForm['CONTACTGUID'] = this.result.CONTACTGUID;
      this.EstateLiabilityForm['CONTACTNAME'] = this.result.CONTACTNAME;
      this.Contactname=[this.result.CONTACTNAME];
      this.data.safeCustodyData.result = this.result;
     // this.getPacket({ CONTACTGUID: this.f.CONTACTGUID.value });
    }
   
    
   
  }

  /**
   * This function is used to remove the contact data
   * @param fruit -data value
   */
  removeContact(fruit: any) {
    const index = this.Contactname.indexOf(fruit);
    if (index >= 0) {
      this.Contactname.splice(index, 1);
     // this.contactForm['COMPANYCONTACTGUID'].setValue('');
      //this.Deliverable = [];
    }
    let sortname = fruit.split('-')[0]
    this.MatterInputContact.nativeElement.value = '';
    this.rmContactdata(sortname)
  };

  /**
   * This function is used to rmcontact data value
   * @param sortname -contact name data value
   */
  rmContactdata(sortname) {
    this.selectedContctData.forEach((element: any, index: any) => {
      if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
        this.selectedContctData.splice(index, 1);
      }
    })
  }


  /**
   * This function is used to addContact data value
   * @param event -event data value
   */
  addContact(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    this.Contactlist.forEach((element: any) => {
      if (element.CONTACTNAME == event.value) {
        // Add our fruit
        const array3 = this.Contactname;
        if (value) {
          if (this.Contactname.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.Contactname = [value]
          }
        }
      }
    });

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
    this.EstateLiabilityForm['CONTACTNAME'] = null;
  };


  /**
   * This function is used to select the Contact data value
   * @param result -data value
   */
  selectedContact(result:any){
    if (result) {
      localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
      this.EstateLiabilityForm['CONTACTGUID'] = result.CONTACTGUID;
      this.EstateLiabilityForm['CONTACTNAME'] = result.CONTACTNAME;
      this.Contactname=[result.CONTACTNAME];
     // this.data.safeCustodyData.result = result;
     // this.getPacket({ CONTACTGUID: this.f.CONTACTGUID.value });
    }
  }
 
 
}
