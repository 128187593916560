import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
// import { SettlementAddressPopupComponent } from 'src/app/main/pages/legal-details/settlement/settlement-address-popup/settlement-address-popup.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-compulsory-acquisition',
    templateUrl: './compulsory-acquisition.component.html',
    styleUrls: ['./compulsory-acquisition.component.scss']
})
export class CompulsoryAcquisitionComponent implements OnInit, OnDestroy {
    matterCompulsoryAcqForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    ChildtableDataSubcription: Subscription;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription


    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData();
    }

    initFormData() {
        this.matterCompulsoryAcqForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCOMPULSORYACQUISITIONGUID: [''],
            CLIENTVALUATION: ['',{updateOn: 'blur'}],
            AUTHORITYVALUATION: ['',{updateOn: 'blur'}],
            FIELDNAME: [''],
            DATEOFACQUISITION: [''],
            COMPENSATIONAMOUNT: ['',{updateOn: 'blur'}],
            DATENOTICESENT: [''],
            DETERMINATIONISSUED: ['']

        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.matterCompulsoryAcqForm.controls['MATTERGUID'].setValue('');
                this.matterCompulsoryAcqForm.controls['MATTERCOMPULSORYACQUISITIONGUID'].setValue('');
                this.matterCompulsoryAcqForm.controls['CLIENTVALUATION'].setValue('');
                this.matterCompulsoryAcqForm.controls['AUTHORITYVALUATION'].setValue('');
                this.matterCompulsoryAcqForm.controls['FIELDNAME'].setValue('');
                this.matterCompulsoryAcqForm.controls['DATEOFACQUISITION'].setValue('');
                this.matterCompulsoryAcqForm.controls['COMPENSATIONAMOUNT'].setValue('');
                this.matterCompulsoryAcqForm.controls['DATENOTICESENT'].setValue('');
                this.matterCompulsoryAcqForm.controls['DETERMINATIONISSUED'].setValue('');
            }
        })

        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.matterCompulsoryAcqForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Compulsory Acquisition" && result.action == 'setData') {
                    this.SaveMatterCompulsoryAcquisition();
                } else if (result.classType == "Compulsory Acquisition" && result.action == 'deleteData') {
                    this.DeleteMatterCompulsoryAcquisition();
                }
            }
        });
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-compulsory-acquisition').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterCompulsoryAcquisitionData = response.DATA.RECORDS[0];

                    let DatePaid = MatterCompulsoryAcquisitionData.DATENOTICESENT.split("/");
                    let DatePaid2 = MatterCompulsoryAcquisitionData.DATEOFACQUISITION.split("/");

                    let DATE = new Date(DatePaid[1] + '/' + DatePaid[0] + '/' + DatePaid[2]);
                    let DATE2 = new Date(DatePaid2[1] + '/' + DatePaid2[0] + '/' + DatePaid2[2]);
                    if (MatterCompulsoryAcquisitionData) {
                        // if(MatterCompulsoryAcquisitionData && this.MatterData.DuplicateResult == true){

                        this.matterCompulsoryAcqForm.patchValue({
                            MATTERGUID: MatterCompulsoryAcquisitionData.MATTERGUID,
                            MATTERCOMPULSORYACQUISITIONGUID: MatterCompulsoryAcquisitionData.MATTERCOMPULSORYACQUISITIONGUID,
                            CLIENTVALUATION: MatterCompulsoryAcquisitionData.CLIENTVALUATION,
                            AUTHORITYVALUATION: MatterCompulsoryAcquisitionData.AUTHORITYVALUATION,
                            FIELDNAME: MatterCompulsoryAcquisitionData.FIELDNAME,
                            DETERMINATIONISSUED: MatterCompulsoryAcquisitionData.DETERMINATIONISSUED,
                            COMPENSATIONAMOUNT: MatterCompulsoryAcquisitionData.COMPENSATIONAMOUNT,
                            DATEOFACQUISITION: DATE2,
                            DATENOTICESENT: DATE
                        });

                        this.matterCompulsoryAcqForm.controls['DATENOTICESENT'].setValue(moment(DATE));
                        this.matterCompulsoryAcqForm.controls['DATEOFACQUISITION'].setValue(moment(DATE2));
                        // }else{

                        //   this.matterCompulsoryAcqForm.patchValue({
                        //     MATTERGUID: MatterCompulsoryAcquisitionData.MATTERGUID,
                        //     MATTERCOMPULSORYACQUISITIONGUID: MatterCompulsoryAcquisitionData.MATTERCOMPULSORYACQUISITIONGUID,
                        //     CLIENTVALUATION: '',
                        //     AUTHORITYVALUATION: '',
                        //     FIELDNAME:'',
                        //     DETERMINATIONISSUED: '',
                        //     COMPENSATIONAMOUNT: '',
                        //     DATEOFACQUISITION: '',
                        //     DATENOTICESENT:  ''
                        //   });

                        //   this.matterCompulsoryAcqForm.controls['DATENOTICESENT'].setValue('');
                        //   this.matterCompulsoryAcqForm.controls['DATEOFACQUISITION'].setValue('');

                        // }
                        // this.matterCompulsoryAcqForm.patchValue({
                        //   MATTERGUID: MatterCompulsoryAcquisitionData.MATTERGUID,
                        //   MATTERCOMPULSORYACQUISITIONGUID: MatterCompulsoryAcquisitionData.MATTERCOMPULSORYACQUISITIONGUID,
                        //   CLIENTVALUATION: MatterCompulsoryAcquisitionData.CLIENTVALUATION,
                        //   AUTHORITYVALUATION: MatterCompulsoryAcquisitionData.AUTHORITYVALUATION,
                        //   FIELDNAME: MatterCompulsoryAcquisitionData.FIELDNAME,
                        //   DETERMINATIONISSUED: MatterCompulsoryAcquisitionData.DETERMINATIONISSUED,
                        //   COMPENSATIONAMOUNT: MatterCompulsoryAcquisitionData.COMPENSATIONAMOUNT,
                        //   DATEOFACQUISITION: DATE2,
                        //   DATENOTICESENT:  DATE
                        // });

                        // this.matterCompulsoryAcqForm.controls['DATENOTICESENT'].setValue(moment(DATE));
                        // this.matterCompulsoryAcqForm.controls['DATEOFACQUISITION'].setValue(moment(DATE2));
                    }
                }
            });
        }
    }

    choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        let adjustmentDate: any = event.value
        this.matterCompulsoryAcqForm.patchValue({
            DATEOFACQUISITION: moment(adjustmentDate, 'DD/MM/YYYY')
        });

    };

    choosedDate1(type: string, event: MatDatepickerInputEvent<Date>) {
        let adjustmentDate: any = event.value
        this.matterCompulsoryAcqForm.controls["DATENOTICESENT"].setValue(adjustmentDate);
    };


    DeleteMatterCompulsoryAcquisition() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-compulsory-acquisition').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterCompulsoryAcquisitionData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERCOMPULSORYACQUISITIONGUID: MatterCompulsoryAcquisitionData.MATTERCOMPULSORYACQUISITIONGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-compulsory-acquisition').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    SaveMatterCompulsoryAcquisition() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.matterCompulsoryAcqForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERCOMPULSORYACQUISITIONGUID;
        };
        formData['DATENOTICESENT'] = formData['DATENOTICESENT'] ? moment(formData['DATENOTICESENT']).format('L') : "";
        formData['DATEOFACQUISITION'] = formData['DATEOFACQUISITION'] ? moment(formData['DATEOFACQUISITION']).format('L') : "";
        const formAction = (formData.MATTERCOMPULSORYACQUISITIONGUID) ? 'Update' : 'Insert';
        delete formData.FIELDNAME;
        const MatterCompulsoryAcquisitionData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(MatterCompulsoryAcquisitionData, 'matter-compulsory-acquisition').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCompulsoryAcquisitionData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCompulsoryAcquisitionData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCompulsoryAcquisitionData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMatterCompulsoryAcquisitionAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMatterCompulsoryAcquisitionAfterVAlidation(details);

        }
    }
    SaveMatterCompulsoryAcquisitionAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-compulsory-acquisition').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }

    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
}
