<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{title}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="TaskForm" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field *ngIf="'' | productType" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>User</mat-label>
                        <input formControlName="UserName" placeholder="User" matInput>
                        <mat-icon class="set_icon" matSuffix (click)='SelectContact()'>
                            <img class="" src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                        <mat-label>Matter</mat-label>
                        <input formControlName="MatterName" placeholder="Matter" matInput>
                        <mat-icon class="mat-icon {{ImgDisAb}}" matSuffix (click)='SelectMatter()'>
                            <img class="" src="assets/icons/web_app/icon_matter_d.ico">
                        </mat-icon>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                        <mat-label>Matter</mat-label>
                        <mat-chip-list #chipList aria-label="Matter selection">
                            <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input 
                                (keydown.Tab)="datashow($event)"
                                formControlName="MatterName" #MatterInput1 [matAutocomplete]="autoMatter"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                            <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                             {{data.SELECTEDDESCRIPTION}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)='SelectMatter()' class="select-Matter-icon">
                            <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.STARTDATE,'has-warning':errorWarningData.Warning?.STARTDATE}" appearance="outline" class="example" fxFlex="25" class="pr-4">
                        <mat-label>Start Date</mat-label>
                        <input formControlName="STARTDATE" (dateInput)="choosedStartDate('input', $event)" (dateChange)="choosedStartDate('change', $event)" matInput [matDatepicker]="picker2" placeholder="Start Date">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.DUEDATE,'has-warning':errorWarningData.Warning?.DUEDATE}" appearance="outline" class="example" fxFlex="25" class="pr-4">
                        <mat-label>Due Date</mat-label>
                        <input formControlName="DUEDATE" (dateInput)="choosedDueDate('input', $event)" (dateChange)="choosedDueDate('change', $event)" matInput [matDatepicker]="picker3" placeholder="Due Date">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.STATUS,'has-warning':errorWarningData.Warning?.STATUS}" fxFlex="25" class="example pr-4" appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="STATUS">
                            <mat-option value="Not Started">Not Started</mat-option>
                            <mat-option value="In Progress">In Progress</mat-option>
                            <mat-option value="Completed">Completed</mat-option>
                            <mat-option value="Waiting on someone else">Waiting on someone else</mat-option>
                            <mat-option value="Deferred">Deferred</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.PRIORITY,'has-warning':errorWarningData.Warning?.PRIORITY}" fxFlex="25" class="example pr-4" appearance="outline">
                        <mat-label>Priority</mat-label>
                        <mat-select formControlName="PRIORITY">
                            <mat-option value="0">Low</mat-option>
                            <mat-option value="1">Medium</mat-option>
                            <mat-option value="2">High</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.PERCENTCOMPLETE,'has-warning':errorWarningData.Warning?.PERCENTCOMPLETE}" appearance="outline" fxFlex="25" class="pr-4 mr-12">
                        <mat-label>% complete</mat-label>
                        <input formControlName="PERCENTCOMPLETE" type="number" min='0' max='100' matInput>
                    </mat-form-field>
                    <mat-checkbox formControlName="REMINDER" (change)="ChekBoxClick($event)" fxFlex="25" class="check_1 pr-4">Reminder </mat-checkbox>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.REMINDERDATE,'has-warning':errorWarningData.Warning?.REMINDERDATE}" appearance="outline" class="example" fxFlex="25" class="pr-4">
                        <input formControlName="REMINDERDATE" (dateInput)="choosedReminderDate('input', $event)" (dateChange)="choosedReminderDate('change', $event)" matInput [matDatepicker]="picker" placeholder="Reminder Date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.REMINDERTIME,'has-warning':errorWarningData.Warning?.REMINDERTIME}" appearance="outline" fxFlex="25" class="pr-4 example">
                        <mat-label>Reminder Time</mat-label>
                        <input formControlName="REMINDERTIME" placeholder="Reminder Time" type="time" matInput>
                    </mat-form-field>
                </div>
<!--                 
                  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="15" class="pr-4">
                      <mat-label>Units</mat-label>
                      <input class="sideText" type="text" matInput formControlName="UNITS" />
                    </mat-form-field>
                    <mat-form-field fxFlex="15" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.UNITTYPE,'has-warning':errorWarningData.Warning?.UNITTYPE}">
                      <mat-label>Unit Type</mat-label>
                      <mat-select #value placeholder="Unit Type" formControlName="UNITTYPE">
                        <mat-option value="Days">Days</mat-option>
                        <mat-option value="Weeks">Weeks</mat-option>
                        <mat-option value="Months">Months</mat-option>
                        <mat-option value="Years">Years</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="15" class="pr-4" appearance="outline">
                      <mat-label>Before/After</mat-label>
                      <mat-select #value placeholder="Before/After" formControlName="ISBEFORE">
                        <mat-option [value]="1">Before</mat-option>
                        <mat-option [value]="0">After</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="25" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.WHICHFIELD,'has-warning':errorWarningData.Warning?.WHICHFIELD}">
                      <mat-label>Which Field</mat-label>
                      <mat-select #value placeholder="Which Field" formControlName="WHICHFIELD">
                        <mat-option *ngFor="let item of WhichFieldData" [value]="item.LOOKUPFULLVALUE">
                          {{item.LOOKUPFULLVALUE}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-checkbox style="margin-top: 4px;" formControlName="DUEDATEISRELATIVE" (change)="ChekBoxClick($event)" fxFlex="25" class="check_1 pr-4">Is Due Data Relative</mat-checkbox>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field fxFlex="25" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ROLEASSIGNEDTO,'has-warning':errorWarningData.Warning?.ROLEASSIGNEDTO}">
                      <mat-label>Assigned To</mat-label>
                      <mat-select #value placeholder="Assigned To" formControlName="ROLEASSIGNEDTO">
                        <mat-option *ngFor="let item of RoleAssignData" [value]="item.LOOKUPFULLVALUE">
                          {{item.LOOKUPFULLVALUE}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4">
                      <mat-label>Template Type</mat-label>
                      <mat-select #value placeholder="Template Type" formControlName="TEMPLATETYPE" (selectionChange)="dataval($event)">
                        <mat-option value="No Template">No Template</mat-option>
                        <mat-option value="Template">Template</mat-option>
                        <mat-option value="Precedent">Precedent</mat-option>
                        <mat-option value="Lexon">Lexon</mat-option>
                        <mat-option value="Court Form">Court Form</mat-option>
                        <mat-option value="Email">Email</mat-option>
                        <mat-option value="Document Pack">Document Pack</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="f.TEMPLATETYPE?.value == 'Template'">
                      <mat-label>Template</mat-label>
                      <input [attr.disabled]="(f.TEMPLATETYPE?.value == 'No Template') ? true : null"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
        
                      <mat-icon  matSuffix (click)="datavalSingle('Template')" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1) && f.TEMPLATETYPE.value != 'No Template'">
                        <img src="assets/icons/web_app/icon_letters_d.ico">
                    </mat-icon>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" *ngIf="f.TEMPLATETYPE?.value == 'Email'" fxFlex="25">
                      <mat-label>Email Template</mat-label>
                      <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
        
                      <mat-icon  matSuffix (click)="datavalSingle('Email')" *ngIf="f.TEMPLATETYPE?.value == 'Email'">
                        <img src="assets/icons/web_app/icon_email_d.ico">
                    </mat-icon>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" *ngIf="f.TEMPLATETYPE?.value == 'Document Pack'" fxFlex="25">
                      <mat-label>Pack Template</mat-label>
                      <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
        
                      <mat-icon  matSuffix (click)="datavalSingle('Document Pack')">
                        <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                    </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="f.TEMPLATETYPE?.value == 'Precedent'" fxFlex="25">
                      <mat-label>Precedent Template</mat-label>
                      <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
        
                      <mat-icon  matSuffix (click)="datavalSingle('Precedent')">
                        <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                    </mat-icon>
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" *ngIf="f.TEMPLATETYPE?.value == 'Lexon'" fxFlex="25">
                      <mat-label>Lexon Template</mat-label>
                      <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
        
                      <mat-icon  matSuffix (click)="datavalSingle('Lexon')">
                        <img src="assets/icons/web_app/Lexon.jpeg">
                    </mat-icon>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" *ngIf="f.TEMPLATETYPE?.value == 'Court Form'" fxFlex="25">
                      <mat-label>Court Form Template</mat-label>
                      <input [attr.disabled]="true"  class="sideText" type="text" matInput formControlName="TEMPLATE" />
        
                      <mat-icon  matSuffix (click)="datavalSingle('Court Form')">
                        <img src="assets/icons/web_app/icon_courtforms_d.ico">
                    </mat-icon>
                    </mat-form-field>
                    
                  </div> -->
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.DESCRIPTION,'has-warning':errorWarningData.Warning?.DESCRIPTION}" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Description</mat-label>
                        <textarea rows="5" formControlName="DESCRIPTION" placeholder="Description" matInput></textarea>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="TaskSave()" [disabled]="isspiner">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>