import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { ContactDialogComponent } from '../../../contact/contact-dialog/contact-dialog.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ContactSelectDialogComponent } from '@pages/contact/contact-select-dialog/contact-select-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';


@Component({
  selector: 'app-estate-assets',
  templateUrl: './estate-assets.component.html',
  styleUrls: ['./estate-assets.component.scss']
})
export class EstateAssetsComponent implements OnInit, OnDestroy {
  @ViewChild('estateForm', { static: true }) ngForm: NgForm;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  dialogTitle: any;
  isLoadingResults: boolean = false;
  action: any;
  isspiner: boolean = false;
  confirmDialogRef: any;
  ContactDetails: any;
  EstateAssetsForm:any = {};
  estateData:any;
  formChangesSubscription:Subscription;
  private _unsubscribeAll$: Subject<void> = new Subject();
  AssetTypeData:any;
  TenancyData:any;
  AssetAccountDescData:any;
  AssetActionData:any;
  currentMatter: any;
  windowNameId:any;
  private searchTerms = new Subject<string>();
  tenantsInCommon : boolean = false;
  TICdeceasedShare : boolean = false;
  TICNameAndAddress : boolean = false;
  selectTenancyValue : any;
  toolTipList: any;
  testBool: boolean=false;
  @ViewChild('MatterInput2', { static: false }) MatterInputContact: ElementRef<HTMLInputElement>;
  Contactname: any = [];
  matterdata: any;
  searchContact: any;
  exportdatavalueOFContact: any;
  result: any;
  searchContactData;
  Contactlist: any=[];
  selectedContctData: any = [];
  appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
  DATETRANSFERREDDATE: string;
  public deathValue: any = 0.00;
  sub2: Subscription;
  subscription: Subscription;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService, public _matDialog: MatDialog,
    private toastr: ToastrService, public dialogRef: MatDialogRef<EstateAssetsComponent>,
    public datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    public globalFunService:GlobalFunctionsService,
    public tooltipService:TooltipService,) {
    this.action = data.action;
    this.behaviorService.estateAssets$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
          this.estateData = result;
      }
    });

    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.currentMatter = result;
    //   } else {
    //     this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
    //   }
    // });
    
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
           const windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
      this.currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
      if (this.currentMatter) {
        // localStorage.setItem('set_active_matters', JSON.stringify(this.currentMatter));
      } else {
        if (result) {
          this.currentMatter = result;
          localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        } else {
            this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
            localStorage.setItem(windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        }
      }
    });

    if (this.action === 'new') {
      this.ContactDetails=data.result;
      this.EstateAssetsForm.CONTACTGUID = (this.ContactDetails.CONTACTGUID);
      this.EstateAssetsForm.CONTACTNAME = (this.ContactDetails.CONTACTNAME + ' - ' + this.ContactDetails.SUBURB);
      this.EstateAssetsForm.VALUEATDEATH = 0.00;
      this.EstateAssetsForm.INTERESTACCRUED = 0.00;
      this.EstateAssetsForm.TOTALVALUE = 0.00;
      this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE = 0.00;
      this.dialogTitle = 'New Asset';
      this.validationColor();
      this.LoadEstateAssetsdata();
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Asset';
      this.LoadData();
    }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() { 

    this.getLookupType('Asset Type');
    this.getLookupType('Tenancy');
    this.getLookupType('Asset Account Desc');
    this.getLookupType('Asset Action');


    this.formChangesSubscription = this.ngForm.form.valueChanges.subscribe(x => {
      this.changeForControl(x)
    })
    this.setTooltipData()

    this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.selectContactFormSearch(term)) // Switch to new observable on new term
    ).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.exportdatavalueOFContact = response.DATA;
        this.searchContactData = response.DATA.RECORDS;
        this.Contactlist = this.searchContactData;
      }else if(response.CODE == 406 && response.STATUS == "error"){
        this.toastr.error(response.MESSAGE);
      }
    });
   }

   /**
    * This function is used to set tooltip Data
    */
   async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['EstateAsset'] ){
      this.toolTipList  = TooltipData['EstateAsset'];
    }else{
      this.tooltipService.setToolTipData = ('EstateAsset');
     }
  };

  /**
   * This function is used to Toggle For Updates 
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to copy the tooltip text
   */
  copyTooltipText(event: any) {
    navigator.clipboard.writeText(event)
  };
  
  /**
   * This functionis used to chenge for control data 
   */
  changeForControl(formControl){
    const dubObj ={...this.errorWarningDataArray.Error};
    let element = this.errorWarningData.Error;
    for (const [key, value] of Object.entries(dubObj)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element[key];
       dubObj[key] = value;
       this.errorWarningDataArray.Error = {...dubObj}
     } else if (!formControl[KEY]) {
        element[key] = dubObj[key];
     }
    }

    const dubObj_1 ={...this.errorWarningDataArray.Warning};
    let element_1 = this.errorWarningData.Warning;
    for (const [key, value] of Object.entries(dubObj_1)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element_1[key];
       dubObj_1[key] = value;
       this.errorWarningDataArray.Warning = {...dubObj_1}
     } else if (!formControl[KEY]) {
        element_1[key] = dubObj_1[key];
     }
    }
  }

  /**
   * This function is used to get the lookup type data value
   * @param lookupType -lookup type data value
   */
  getLookupType(lookupType):void{
    this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': lookupType }}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        if (lookupType == 'Asset Type') {
          this.AssetTypeData = responses.DATA.LOOKUPS;
        } else if (lookupType == 'Tenancy') {
          this.TenancyData = responses.DATA.LOOKUPS;
        } else if (lookupType == 'Asset Account Desc') {
          this.AssetAccountDescData = responses.DATA.LOOKUPS;
        } else if (lookupType == 'Asset Action') {
          this.AssetActionData = responses.DATA.LOOKUPS;
        }
        localStorage.setItem('matter_status_lookups', JSON.stringify(responses.DATA.LOOKUPS));
      } else if (responses.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      // this.isLoadingResults = false;
    });
  }

  ngOnDestroy(): void {
      this.formChangesSubscription?.unsubscribe();
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub2?.unsubscribe();
      this.subscription?.unsubscribe();
  }

  /**
   * This function is used to make the validation color
   */
  validationColor() {
    let tempError: any = [];
    let tempWarning: any = [];
    tempError["CONTACTGUID"] = {};
    tempError["TENANCY"] = {};
    this.errorWarningData = { Error: tempError, Warning: tempWarning };
    this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
  }

  /**
   * This function is used to Load the data value
   */
  LoadData() {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({ Action: "GetData", FILTERS: { MATTERGUID: this.currentMatter.MATTERGUID, ESTATEASSETGUID: this.estateData.ESTATEASSETGUID } }, 'estate-asset').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
            this.EstateAssetsForm = response.DATA.RECORDS[0];
            this.toolTipList = response.DATA.FIELDTIPS;
            // this.EstateAssetsForm.INTERESTACCRUED = new DecimalPipe('en-GB').transform(this.EstateAssetsForm.INTERESTACCRUED,'1.2-2');
            // this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE = new DecimalPipe('en-GB').transform(this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE,'1.2-2');
            // this.EstateAssetsForm.VALUEATDEATH = new DecimalPipe('en-GB').transform(this.EstateAssetsForm.VALUEATDEATH,'1.2-2');
            let temTODATE = this.EstateAssetsForm.DATETRANSFERRED.split("/");
            this.EstateAssetsForm.DATETRANSFERRED =  new Date(temTODATE[1] + "/" + temTODATE[0] + "/" + temTODATE[2]);
            this.Contactname=[this.EstateAssetsForm.CONTACTNAME];
            this.selectTenancy(this.EstateAssetsForm.TENANCY);
        }
        this.isLoadingResults = false;
    }, error => {
        this.isLoadingResults = false;
        this.toastr.error(error);
    });
  }

  /**
   * Load the Estate Assets data value
   */
  LoadEstateAssetsdata():void{
    this._mainAPiServiceService.getSetData({ Action: "Default", DATA: { MATTERGUID: '', ESTATEASSETGUID: '' } }, 'estate-asset').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
         this.EstateAssetsForm=response.DATA.DEFAULTVALUES;  
      }
      this.isLoadingResults = false;
  }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
  });
  }

  /**
   * This function is used to On submit the data value 
   * @param form -form data value
   */
  OnSubmit(form?:any){
    this.isspiner = true;
    const FormAction = this.action === 'new' ? 'insert' : 'update';
    delete this.EstateAssetsForm.CONTACTNAME;
    // Getting Invalid String if we store blank date
    // this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE = this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE ? parseFloat(this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE.replace(/,/g, '')).toFixed(2) : 0;
    if(this.EstateAssetsForm.DATETRANSFERRED && Object.keys(this.EstateAssetsForm.DATETRANSFERRED).length !=0 ){
      this.DATETRANSFERREDDATE = this.datepipe.transform(this.EstateAssetsForm.DATETRANSFERRED, "dd/MM/yyyy");
    }else{
      this.DATETRANSFERREDDATE='';
    }
    
    let Data: any = {...this.EstateAssetsForm,  MATTERGUID: this.currentMatter.MATTERGUID, DATETRANSFERRED:this.DATETRANSFERREDDATE}
    let details = { FormAction: FormAction, VALIDATEONLY: true, Data: Data, };
    this._mainAPiServiceService.getSetData(details, "estate-asset").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 451 && response.STATUS == "warning") {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 450 && response.STATUS == "error") {
            this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
        }
    }, (error) => {
        this.isspiner = false;
        this.toastr.error(error);
    });
  }

  /**
   * This function is used to check the validation.
   */
  async checkValidation(bodyData: any, details: any) {
      // let errorData: any = [];
      // let warningData: any = [];
      // let tempError: any = [];
      // let tempWarning: any = [];
      // bodyData.forEach(function (value) {
      //     if (value.VALUEVALID == "No") {
      //         errorData.push(value.ERRORDESCRIPTION);
      //         tempError[value.FIELDNAME] = value;
      //     } else if (value.VALUEVALID == "Warning") {
      //         tempWarning[value.FIELDNAME] = value;
      //         warningData.push(value.ERRORDESCRIPTION);
      //     }
      // });
      // this.errorWarningData = { Error: tempError, Warning: tempWarning };
      // this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
      // if (Object.keys(errorData).length != 0) {
      //     this.toastr.error(errorData);
      //     this.isspiner = false;
      // } else if (Object.keys(warningData).length != 0) {
      //     this.isspiner = false;
      //     this.confirmDialogRef = this._matDialog.open(
      //         FuseConfirmDialogComponent,
      //         {
      //             disableClose: true,
      //             width: "100%",
      //             data: warningData,
      //         }
      //     );
      //     this.confirmDialogRef.componentInstance.confirmMessage =
      //         "Are you sure you want to Save?";
      //     this.confirmDialogRef.afterClosed().subscribe((result) => {
      //         if (result) {
      //             this.isspiner = true;
      //             this.saveassetsData(details);
      //         } else {
      //             this.isspiner = false;
      //         }
      //         this.confirmDialogRef = null;
      //     });
      // } else if (
      //     Object.keys(warningData).length == 0 &&
      //     Object.keys(errorData).length == 0
      // ) {
      //     this.saveassetsData(details);
      // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.saveassetsData(details);
        }
      }
    });
  } 

/**
 *This fdunction is used to Save the Assets data 
 * @param data 
 */
  saveassetsData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, "estate-asset").subscribe((response) => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            if (this.action !== "edit") {
                this.toastr.success(" save successfully");
            } else {
                this.toastr.success(" update successfully");
            }
            $('#getEstateAssets').click();
            this.dialogRef.close(true);
        } else if (response.CODE == 451 && response.STATUS == "warning") {
            this.isspiner = false;
            this.toastr.warning(response.MESSAGE);
        } else if (response.CODE == 450 && response.STATUS == "error") {
            this.isspiner = false;
            this.toastr.error(response.MESSAGE);
        } else if (response.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
        }
    }, (error) => {
        this.isspiner = false;
        this.toastr.error(error);
    });
  }    

  /**
   * This function is used to set the Contact matter data value
   */
  ContactMatter():void {
    this.searchContactData = [];
    this.Contactlist = this.searchContactData;

    const dialogRef = this._matDialog.open(ContactSelectDialogComponent, {
      width: '100%', disableClose: true, data: { type: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'MatterPopupComponent');
      if (result) {
        this.EstateAssetsForm.CONTACTGUID = (result.CONTACTGUID);
        this.EstateAssetsForm.CONTACTNAME = (result.CONTACTNAME + ' - ' + result.SUBURB);
        this.Contactname=[result.CONTACTNAME];
      }
    });
  }

  /**
   * this function is used to change the model
   */
  modelChanged(event, fieldName):void{
    const numbers = this.EstateAssetsForm[fieldName];
    const numericValue = parseFloat(numbers.replace(/,/g, ''));
    const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
    event.target.value = formattedNumber;
  }

  /**
   * This function is used to calculate the value
   */
  calculate(price , rateOfIntrest , time){
    //let total = (price * rateOfIntrest * time) / 100;
    // if(!price || price == 0){
    //   total = (0 * 1 * time);
    //   this.EstateAssetsForm.TOTALVALUE = total;
    //   this.EstateAssetsForm.INTERESTACCRUED = 0;
    // }else if(!rateOfIntrest || rateOfIntrest == 0){
    //   total = (price * 1 * time);
    //   this.EstateAssetsForm.TOTALVALUE =total;
    // }else{
    //   total = (price * rateOfIntrest * time) / 100;
    //   this.EstateAssetsForm.TOTALVALUE = price + total;
    // }

    //clients wants simple Total.
    let total = 0;
    // price = price ? parseFloat(price.replace(/,/g, '')) : 0;
    // rateOfIntrest = rateOfIntrest ? parseFloat(rateOfIntrest.replace(/,/g, '')) : 0;
    this.EstateAssetsForm.VALUEATDEATH = Number(price?price:0).toFixed(2);
    this.EstateAssetsForm.INTERESTACCRUED = Number(rateOfIntrest?rateOfIntrest:0).toFixed(2)
    // total = ((price)?price:0) + ((rateOfIntrest)?rateOfIntrest:0);
    total = Number(this.EstateAssetsForm.VALUEATDEATH) + Number(this.EstateAssetsForm.INTERESTACCRUED);
    this.EstateAssetsForm.TOTALVALUE = Number(total).toFixed(2);
    this.selectTenancy(this.selectTenancyValue)
  }

/**
 * This function is used to select the tenancy data value  
 * @param value -value data 
 */
  selectTenancy(value){
    this.selectTenancyValue = value;
    if(this.selectTenancyValue == 'Solely by the deceased' ||  this.selectTenancyValue == 'Held as trustee' || this.selectTenancyValue == 'Held outside state'){
        this.TICdeceasedShare = true;
        this.TICNameAndAddress = true;
        this.tenantsInCommon = true;

        this.EstateAssetsForm.TENANTSINCOMMONSHARES = 1;
        this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE = (this.EstateAssetsForm.TOTALVALUE)?this.EstateAssetsForm.TOTALVALUE:0;
    }else if( this.selectTenancyValue == 'With another or others as joint tenants' ||  this.selectTenancyValue == 'Tenants in common in equal shares'){
        this.TICNameAndAddress = false;
        this.TICdeceasedShare = true;
        this.tenantsInCommon = false;

        this.EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE = (this.EstateAssetsForm.TOTALVALUE)?this.EstateAssetsForm.TOTALVALUE:0 / (this.EstateAssetsForm.TENANTSINCOMMONSHARES)?this.EstateAssetsForm.TENANTSINCOMMONSHARES : 0;
    }else if( this.selectTenancyValue == 'Tenants in common in unequal shares'){
        this.TICNameAndAddress = false;
        this.TICdeceasedShare = false;
        this.tenantsInCommon = false;
    }else{
        this.TICNameAndAddress = false;
        this.TICdeceasedShare = false;
        this.tenantsInCommon = false;
    }
  
}

/**
 * This functionnis used to select the New Contact data value
 */
async selectNewContect(){
  const dialogRef = await this._matDialog.open(ContactDialogComponent, {
    disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true , contect_details : '' , flagNewConactMatter : true}
  });

  dialogRef.afterClosed().subscribe(async result => {
    if (result) {
      if (result.CONTACTGUID) {
        let contectIdIs = await this.getContactNewId();
        this.behaviorService.MatterpopupContactData$.next(contectIdIs);
        this.EstateAssetsForm['CONTACTGUID'] = result.CONTACTGUID;
        this.EstateAssetsForm['CONTACTNAME'] = result.CONTACTNAME;
        this.Contactname = [result.CONTACTNAME];
       // this.getPacket({ CONTACTGUID: result.CONTACTGUID});
        // this.closeDialogRef.close(result);
      }else{
        this.behaviorService.MatterpopupContactData$.next(null)
      }
    }
  });
}

/**
 * This function is used to get the new Contact Id
 * @returns 
 */
getContactNewId(){
  let contecttId = '';
  let postData = {
    "Action": "GetContactId",
    "VALIDATEONLY": true,
    "Data": {},
  };

  return new Promise((response , reject)=>{
    this.sub2 = this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
      if(res.CODE == 200){
        contecttId = res.DATA.CONTACTID;
        response(contecttId);
      }else{
        response(null);
      }
    });
  })
};

onKeyUp(event: any): void {
  const searchValue = event.target.value;
  this.searchTerms.next(searchValue);
}

/**
 * This function is used to select the contact form search the data value
 * @param event -event data value
 */
selectContactFormSearch(event){
  // let searchobj = { FastSearch:'True', Search: event }
  let payload = {
    "ACTION": "GetData",
    "FILTERS": {
      "FASTSEARCH": 1,
      "SEARCH": event
    },
  }
  return this._mainAPiServiceService.getSetData(payload, 'contact')
}

/**
 * This functionn is used to Show the Contact data value
 */
Contactdatashow(event) {
  if(this.exportdatavalueOFContact && this.exportdatavalueOFContact.RECORDS){
    this.result=this.exportdatavalueOFContact.RECORDS[0]
    this.EstateAssetsForm['CONTACTNAME'] = null;
    if (event.input) {
      event.input.value = '';
    } else {
      this.MatterInputContact.nativeElement.value = '';
    }
  } 

  if (this.result) {
    localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
    this.EstateAssetsForm['CONTACTGUID'] = this.result.CONTACTGUID;
    this.EstateAssetsForm['CONTACTNAME'] = this.result.CONTACTNAME;
    this.Contactname=[this.result.CONTACTNAME];
    this.data.safeCustodyData.result = this.result;
   // this.getPacket({ CONTACTGUID: this.f.CONTACTGUID.value });
  }
 
  
 
}

/**
 * This function is used to remove the Contact data value
 * @param fruit -data value
 */
removeContact(fruit: any) {
  const index = this.Contactname.indexOf(fruit);
  if (index >= 0) {
    this.Contactname.splice(index, 1);
   // this.contactForm['COMPANYCONTACTGUID'].setValue('');
    //this.Deliverable = [];
  }
  let sortname = fruit.split('-')[0]
  this.MatterInputContact.nativeElement.value = '';
  this.rmContactdata(sortname)
};

/**
 * This function is used to rmContact data value
 * @param sortname -thisis used to sort for name 
 */
rmContactdata(sortname):void {
  this.selectedContctData.forEach((element: any, index: any) => {
    if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
      this.selectedContctData.splice(index, 1);
    }
  })
}

/**
 * 
 * @param event -This function is used to add the Contact data value
 */
addContact(event: MatChipInputEvent): void {
  const value = (event.value || '').trim();
  this.Contactlist.forEach((element: any) => {
    if (element.CONTACTNAME == event.value) {
      // Add our fruit
      const array3 = this.Contactname;
      if (value) {
        if (this.Contactname.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
          this.Contactname = [value]
        }
      }
    }
  });

  // Clear the input value
  if (event.input) {
    event.input.value = '';
  }
  this.EstateAssetsForm['CONTACTNAME'] = null;
};

/**
 * This function is used to select the contact data value 
 */
selectedContact(result:any):void{
  if (result) {
    localStorage.setItem('istrackid', 'SafeCustodyDialogeComponent');
    this.EstateAssetsForm['CONTACTGUID'] = result.CONTACTGUID;
    this.EstateAssetsForm['CONTACTNAME'] = result.CONTACTNAME;
    this.Contactname=[result.CONTACTNAME];
   // this.data.safeCustodyData.result = result;
   // this.getPacket({ CONTACTGUID: this.f.CONTACTGUID.value });
  }
}
 
}
