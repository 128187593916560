import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, OnDestroy, ElementRef, HostListener, } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { fuseAnimations } from "src/@fuse/animations";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ContactSelectDialogComponent } from "../../contact/contact-select-dialog/contact-select-dialog.component";
import { MatterDialogComponent } from "../../time-entries/matter-dialog/matter-dialog.component";
import * as $ from "jquery";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { ToastrService } from "ngx-toastr";
import { MatSort } from "@angular/material/sort";
import { DatePipe, DecimalPipe } from "@angular/common";
import { round } from "lodash";
import { BankingDialogComponent } from "../../banking/banking-dialog.component";
import { TrustMoneyReciptComponent } from '../../Trust Accounts/trust-money/trust-money-recipt/trust-money-recipt.component';
import { Observable, Subject, Subscription } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";

import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { TooltipService } from "@_services/tooltip.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-spend-money-add",
    templateUrl: "./spend-money-add.component.html",
    styleUrls: ["./spend-money-add.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class SpendMoneyAddComponent implements OnInit, OnDestroy {
    filteredOptions: Observable<string[]>;
    getPayourarray: any = [];
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    dataSource: MatTableDataSource<UserData>;
    action: any;
    dialogTitle: string;
    isLoadingResults: boolean;
    spendmoneyForm: FormGroup;
    highlightedRows: any;
    theme_type = localStorage.getItem("theme_type");
    CurrentMatter;
    // selectedColore: string = this.theme_type == "theme-default" ? "rebeccapurple" : "#43a047";
    selectedColore: string = this.theme_type == "theme-default" ? "rebeccapurple" : "#d9d9d9";
    displayedColumnsTime: string[] = ["AMOUNT", "EXPENDITURECLASS", "GST", "NOTE",];
    getDataForTable: any = [];
    paginator: any;
    isspiner: boolean = false;
    classtype: any;
    size = 33.33;
    Bankhide: boolean = true;
    hide: boolean = true;
    tdata: boolean;
    confirmDialogRef: any;
    expac: boolean;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    dataTableHide: string;
    sendItem: any = [];
    Main3btn: string;
    SubMain2btn: string;
    FormAction: string;
    FAmount: any = [];
    GSTValAfterCal: any;
    maxVal: any = "";
    GstTypeDiff: any;
    GSTValForExGst: any;
    FinalTotal: any;
    FGst: any = [];
    FinalTotalGST: any;
    btnClickpurpose: string;
    INDEX: any;
    MainData: any;
    setMainAmount: number;
    setMainGST: number;
    multicheckboxval: number;
    isItemSaveClicked: string;
    FinalExGSTAmount: number;
    itemAmountExGST: number;
    SendMoney_data: any = [];
    SendMoney_dataGUID: any;
    arrayForIndex: any;
    storeDataarray: any = [];
    GSTValForInGst: any;
    FrtSdError: any = [];
    PaidTypeName: any;
    GetSetMixRetry: boolean = true;
    ChartHandlingData: { ClickType: string; UseTrust: string; PopUp: string; Lable: string; };
    windowNameId: any;
    // @Input() toolTipList: any;
    toolTipPostion = "above";
    Object: any
    currentUserData: any = JSON.parse(localStorage.getItem("currentUser"));

    searchMatter: any;
    exportdatavalue: any;
    searchData: any;
    searchContactData;
    matterlist: Observable<string[]>;
    Contactlist: any = []

    mattername: any = [];
    Contactname: any = [];
    selectedData: any = [];
    selectedContctData: any = [];
    toolTipList: any
    separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    @ViewChild('MatterInput2', { static: false }) MatterInputContact: ElementRef<HTMLInputElement>;
    matterdata: any;
    searchContact: any;
    exportdatavalueOFContact: any;
    result: any;
    ExpandDivFlag: boolean = false;
    lengthsize: any;
    ButtonFlag: boolean = true;
    disableAddButtonFlag: boolean = false;
    HideButtonsDiv = false;
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
    testBool: boolean = false;
    CallHostlistner: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub: Subscription;
    sub1: Subscription;
    private searchTerms = new Subject<string>();
    private subscription: Subscription;
    constructor(

        public dialogRef: MatDialogRef<SpendMoneyAddComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _formBuilder: FormBuilder,
        public MatDialog: MatDialog,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        public _matDialog: MatDialog,
        public datepipe: DatePipe,
        public _mainAPiServiceService: MainAPiServiceService,
        public globalFunService: GlobalFunctionsService,
        public tooltipService: TooltipService, public toolbarServiceService: ToolbarServiceService,
        private router: Router
    ) {
        this.Object = Object;
        // const materIDofTab = window.name.split("_");
        // this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        // this.CurrentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));

        /**
                 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
                 * @remarks
                 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
                 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
                 */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    if (result) {
                        this.CurrentMatter = result;
                    } else {
                        this.CurrentMatter = JSON.parse(localStorage.getItem(windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.CurrentMatter));
                    }
                })
            )
            .subscribe();
        localStorage.setItem('istrackid', 'SpandMoneyDialog');
        this.action = _data.action;
        //Global matter selection.
        if (this._data.matteris) {
            this.CurrentMatter = this._data.matteris;
        };

        // this.dialogTitle = this.action === 'edit' ? 'Update Spend Money' : 'Add Spend Money';
        if (this.action === "new") {
            this.dialogTitle = "Add Spend Money ";
        } else if (this.action === "edit") {
            this.dialogTitle = "Update Spend Money";
        } else {
            this.dialogTitle = "Duplicate Spend Money";
        }
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result != null) {
                if (result.MESSAGE == "Not logged in") {
                    this.dialogRef.close(false);
                }
            }
        });
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'SpandMoneyDialog' && result['click'] && this.GetSetMixRetry && (result['data']['SetExpenditure'] || result['data']['expenditure'] || result['data']['GetAccount'])) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'SpandMoneyDialog' && result['click'] && this.GetSetMixRetry == false && result['data']['SetExpenditure']) {
                this.FinalSaveData();
            }

        });

        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(async response => {
            if (response) {
                this.matterdata = await response
            }
        })
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.isLoadingResults = true;
        this.behaviorService.SpendMoneyData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.SendMoney_dataGUID = result;
            }
        });
        // this.getPayor({});
        //for Data Table hideshow
        this.Main3btn = "disabled";
        this.dataTableHide = "false";
        this.spendmoneyForm = this._formBuilder.group({
            DateIncurred: [""],
            Paid: [""],
            DatePaid: [""],
            Amount: [""],
            GST: [""],
            Bankac: [""],
            Notes: [""],
            Type: [""],
            ChequeNo: [""],
            Payee: [""],
            Invoice: [""],
            MultiLineExpense: [""],
            Class: [""],
            Matter: [""],
            AmountIncGST: ["", { updateOn: 'blur' }],
            GSTType: [""],
            GST1: ["", { updateOn: 'blur' }],
            BankacGUID: [""],
            AmountExGST: ["", { updateOn: 'blur' }],
            Expenseac: ["", Validators.required], //add the riquird field
            Note: [""],
            Assetacs: [""],
            Gstac: [""],
            taxac: [""],
            Equityac: [""],
            DatePaidForSend: [""],
            DateIncurredForSend: [""],
            MatterGUID: [""],
            ExpenseacGUID: [""],
            EXPENSEACCOUNTNUMBER: [""],
            BANKACCOUNTNUMBER: [""],
            PAYEECONTACTGUID: [""]
        });
        this.spendmoneyForm.controls["AmountExGST"].disable();
        this.spendmoneyForm.controls["GST"].disable();
        this.spendmoneyForm.controls["Amount"].disable();
        this.isLoadingResults = true;
        if (this.action != "new") {
            $("#expac").addClass("menu-disabled");
            this.expac = true;
            this._mainAPiServiceService.getSetData({ Action: 'Getdata', Filters: { EXPENDITUREGUID: this.SendMoney_dataGUID?.EXPENDITUREGUID } }, 'expenditure').subscribe((response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.SendMoney_data = response.DATA.RECORDS[0];
                    // this.toolTipList = response.DATA.FIELDTIPS
                    //call first row and datatble -> start
                    this.SendMoney_data.EXPENDITUREITEMS.forEach((element) => {
                        element.AMOUNT = Number(element.AMOUNT) + Number(element.GST);
                    });
                    this.getDataForTable = this.SendMoney_data.EXPENDITUREITEMS;
                    this.globallyCalculation();
                    this.highlightedRows = 0;
                    this.getDataForTable.paginator = this.paginator;
                    this.getDataForTable.sort = this.sort;
                    this.spendmoneyForm.controls["GST1"].disable();
                    let Status = (this.SendMoney_data.STATUS && this.SendMoney_data.STATUS == "Paid") ? 'Paid' : 'Unpaid';
                    this.spendmoneyForm.patchValue({
                        Paid: Status,
                        // Paid: this.SendMoney_data.STATUS,
                        Notes: (this.SendMoney_data.STATUS == 'Reversal') ? "" : this.SendMoney_data.NOTE,
                        ChequeNo: this.SendMoney_data.CHEQUENO,
                        Type: this.SendMoney_data.EXPENDITURETYPE,
                        Payee: this.SendMoney_data.PAYEE,
                        PAYEECONTACTGUID: this.SendMoney_data?.PAYEECONTACTGUID,
                        Amount: this.SendMoney_data.AMOUNT + this.SendMoney_data.GST,
                        GST: this.SendMoney_data.GST,
                        BankacGUID: this.SendMoney_data.BANKACCOUNTGUID,
                        Bankac: this.SendMoney_data.BANKACCOUNTNUMBER + " - " + this.SendMoney_data.BANKACCOUNTNAME,
                        Invoice: this.SendMoney_data.SOURCEREFERENCE,
                    });
                    if (this.SendMoney_data && this.SendMoney_data.PAYEE) {
                        this.Contactname = [this.SendMoney_data.PAYEE]
                    } else {
                        this.Contactname = [];
                    }

                    this.Paidtype(this.SendMoney_data.STATUS);
                    //globally value set
                    let ReceiveDATE: any;
                    if (this.SendMoney_data.RECEIVEDDATE) {
                        let DateIncurred = this.SendMoney_data.RECEIVEDDATE.split("/");
                        ReceiveDATE = new Date(DateIncurred[1] + "/" + DateIncurred[0] + "/" + DateIncurred[2]);
                        this.spendmoneyForm.controls["DateIncurred"].setValue(ReceiveDATE);
                        this.spendmoneyForm.controls["DateIncurredForSend"].setValue(this.SendMoney_data.RECEIVEDDATE);
                    }
                    let DATE: any;
                    if (this.SendMoney_data.DATE) {
                        let DatePaid = this.SendMoney_data.DATE.split("/");
                        DATE = new Date(DatePaid[1] + "/" + DatePaid[0] + "/" + DatePaid[2]);
                        this.spendmoneyForm.controls["DatePaid"].setValue(DATE);
                        this.spendmoneyForm.controls["DatePaidForSend"].setValue(this.SendMoney_data.DATE);
                    }
                    // inner item
                    if (this.SendMoney_data.EXPENDITUREITEMS.length != 0) {
                        // this.editMoney(this.SendMoney_data.EXPENDITUREITEMS[0], 0);
                        this.spendmoneyForm.controls["Class"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].EXPENDITURECLASS);
                        this.Classtype(this.SendMoney_data.EXPENDITUREITEMS[0].EXPENDITURECLASS);
                        this.spendmoneyForm.controls["GST1"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].GST.toString());
                        // this.spendmoneyForm.controls['AmountIncGST'].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].AMOUNT);
                        this.spendmoneyForm.controls["AmountIncGST"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].AMOUNT);
                        this.spendmoneyForm.controls["AmountExGST"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].AMOUNT - this.SendMoney_data.EXPENDITUREITEMS[0].GST);

                        if (this.SendMoney_data.STATUS !== 'Reversal') {
                            this.spendmoneyForm.controls["Note"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].NOTE);
                        } else {
                            this.spendmoneyForm.controls["Note"].setValue("");
                        }
                        this.spendmoneyForm.controls["Matter"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].SHORTNAME);
                        this.spendmoneyForm.controls["MatterGUID"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].MATTERGUID);
                        if (this.SendMoney_data.EXPENDITUREITEMS[0].SHORTNAME) {
                            // this.mattername = [this.SendMoney_data.EXPENDITUREITEMS[0].SHORTNAME + '--' + this.SendMoney_data.EXPENDITUREITEMS[0].MATTER]
                            //  this.mattername = [this.SendMoney_data.EXPENDITUREITEMS[0].SELECTEDDESCRIPTION]
                            this.mattername = (!this.SendMoney_data.EXPENDITUREITEMS[0].SELECTEDDESCRIPTION || this.SendMoney_data.EXPENDITUREITEMS[0].SELECTEDDESCRIPTION == '') ? [] : [this.SendMoney_data.EXPENDITUREITEMS[0].SELECTEDDESCRIPTION]; // Edit time set matter.
                        }
                        this.spendmoneyForm.controls["Expenseac"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].EXPENSEACCOUNTNUMBER + " - " + this.SendMoney_data.EXPENDITUREITEMS[0].EXPENSEACCOUNTNAME);
                        this.spendmoneyForm.controls["ExpenseacGUID"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].EXPENSEACCOUNTGUID);
                        if (round(Number(this.f.AmountExGST.value) / 10) == round(this.SendMoney_data.EXPENDITUREITEMS[0].GST)) {
                            this.spendmoneyForm.controls["GSTType"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE);
                            this.GstTypeDiff = this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE;
                            this.GstTypeforSelect(this.GstTypeDiff)
                            this.amountCal();
                            // } else if (this.SendMoney_data.EXPENDITUREITEMS[0].GST == 0) {
                            //     this.spendmoneyForm.controls["GSTType"].setValue("No GST");
                            //     this.GstTypeDiff = "No GST";
                            //     this.amountCal();
                        } else if (this.SendMoney_data.EXPENDITUREITEMS[0].GST == 0) {
                            if (this.SendMoney_data.EXPENDITUREITEMS[0].GST == 0 && this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE === "Less Than 10% GST(Charge GST)" && this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE !== "Less Than 10% GST") {
                                this.spendmoneyForm.controls["GSTType"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE);
                                this.GstTypeDiff = this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE;
                                this.GstTypeforSelect(this.GstTypeDiff)
                                this.amountCal();
                            } else {
                                this.spendmoneyForm.controls["GSTType"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE);
                                this.GstTypeDiff = this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE;
                                this.GstTypeforSelect(this.GstTypeDiff)
                                this.amountCal();
                            }
                        } else if (round(Number(this.f.AmountExGST.value) / 10) != round(this.SendMoney_data.EXPENDITUREITEMS[0].GST)) {
                            this.spendmoneyForm.controls["GSTType"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE);
                            this.GstTypeDiff = this.SendMoney_data.EXPENDITUREITEMS[0].GSTTYPE;
                            this.GstTypeforSelect(this.GstTypeDiff)
                            this.amountCal();
                            // } else if (round(Number(this.f.AmountExGST.value) / 10) != round(this.SendMoney_data.EXPENDITUREITEMS[0].GST)) {
                            //     this.spendmoneyForm.controls["GSTType"].setValue("Less Than 10% GST");
                            //     this.GstTypeDiff = "Less Than 10% GST";
                            //     this.amountCal();
                        } else {
                            this.amountCal();
                        }
                    } else {
                        this.spendmoneyForm.controls["Class"].setValue("");
                        this.spendmoneyForm.controls["GST1"].setValue("");
                        this.spendmoneyForm.controls["AmountIncGST"].setValue("");
                        this.spendmoneyForm.controls["Note"].setValue("");
                        this.spendmoneyForm.controls["Matter"].setValue("");
                        this.spendmoneyForm.controls["MatterGUID"].setValue("");
                    }
                    if (this.SendMoney_data.MULTILINE == 0) {
                        this.spendmoneyForm.controls["MultiLineExpense"].setValue(0);
                        if (this.SendMoney_data.EXPENDITURITEMS && this.SendMoney_data.EXPENDITURITEMS.length != 0) {
                            this.multilineCheckbox();
                        }
                    } else {
                        this.editMoney(this.SendMoney_data.EXPENDITUREITEMS[0], 0);
                        this.spendmoneyForm.controls["MultiLineExpense"].setValue(1);
                        this.multilineCheckbox();
                    }
                } else if (response.MESSAGE == "Not logged in") {
                    this.dialogRef.close(false);
                }
                this.isLoadingResults = false;
            }, (error) => {
                this.toastr.error(error);
            });
        } else {
            this.isLoadingResults = true;
            this._mainAPiServiceService.getSetData({ FormAction: 'default', VALIDATEONLY: true, DATA: {} }, 'SetExpenditure').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                if (res.CODE == 200) {
                    this.isLoadingResults = false;
                    const spendmoneyData = res.DATA.DEFAULTVALUES;
                    this.SendMoney_data = res.DATA.DEFAULTVALUES;

                    // this.toolTipList = res.DATA.FIELDTIPS
                    this.spendmoneyForm.patchValue({
                        BankacGUID: spendmoneyData.BANKACCOUNTGUID,
                        Bankac: spendmoneyData['BANKACCOUNTNUMBER'] + " - " + spendmoneyData['BANKACCOUNTNAME'],
                        Paid: spendmoneyData.STATUS,
                        Type: spendmoneyData.EXPENDITURETYPE,
                        ChequeNo: spendmoneyData.CHEQUENO,
                        DateIncurredForSend: spendmoneyData.DATE_,
                        DatePaidForSend: spendmoneyData.RECEIVEDDATE,
                    });
                    let ReceiveDATE: any;
                    if (spendmoneyData.DATE_) {
                        let DateIncurred = spendmoneyData.DATE_.split("/");
                        ReceiveDATE = new Date(DateIncurred[1] + "/" + DateIncurred[0] + "/" + DateIncurred[2]);
                        this.spendmoneyForm.controls["DateIncurred"].setValue(ReceiveDATE);
                    }
                    let DATE: any;
                    if (spendmoneyData.RECEIVEDDATE) {
                        let DatePaid = spendmoneyData.RECEIVEDDATE.split("/");
                        DATE = new Date(DatePaid[1] + "/" + DatePaid[0] + "/" + DatePaid[2]);
                        this.spendmoneyForm.controls["DatePaid"].setValue(DATE);
                    };
                    if (this.currentUserData && this._data.FLAG == 'NEW MATTER EXPENCE') {
                        this.spendmoneyForm.controls["Matter"].setValue(this.CurrentMatter.SHORTNAME);
                        this.spendmoneyForm.controls["MatterGUID"].setValue(this.CurrentMatter.MATTERGUID);
                        this.mattername = [this.CurrentMatter.SELECTEDDESCRIPTION];
                    };

                    //DatePaid
                    this.spendmoneyForm.controls["DatePaid"].setValue(new Date(), "dd/MM/yyyy");
                    this.spendmoneyForm.controls["DatePaidForSend"].setValue(this.datepipe.transform(new Date(), "dd/MM/yyyy"));
                    //DateIncurred
                    this.spendmoneyForm.controls["DateIncurred"].setValue(new Date(), "dd/MM/yyyy");
                    this.spendmoneyForm.controls["DateIncurredForSend"].setValue(this.datepipe.transform(new Date(), "dd/MM/yyyy"));
                    this.Paidtype(spendmoneyData.STATUS);
                    this.forAddshowpopupData();
                } else if (res.MESSAGE === 'Not logged in') {
                    this.dialogRef.close(false);
                }
                this.CheckdefultValidation(res.DATA.VALIDATIONS);
            }, error => {
                this.isLoadingResults = false;
                this.toastr.error(error);
            });
        }

        this.sub = this.spendmoneyForm.valueChanges.subscribe(formControl => {
            const newFormControl = { ...formControl }
            for (const [key, value] of Object.entries(formControl)) {
                const KEY = key.toLocaleUpperCase();

                if (key == 'Notes') {
                    newFormControl['NOTE'] = value
                }
                if (key == 'AmountIncGST') {
                    newFormControl['AMOUNT'] = value
                }
                if (key == 'Payee') {
                    newFormControl['PAYEE'] = value
                }
                if (key == 'Expenseac') {
                    newFormControl['EXPENDITUREITEMS'] = value
                }
            }
            this.changeForControl(newFormControl);
        });
        this.setTooltipData()
        this.getContactDataUsingBehaviour()

        /**
         * Used to Reduced the more API calls.
         */
        this.sub1 = this.spendmoneyForm.controls.Payee.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((payeeData: string) => {
            this.selectContactFormSearch(payeeData);
        })

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
          });
    }

    /**
     * This function is used to change the amount data
     * @param element - this function is used to get the element data value.
     * @param field - value of the field data.
     */
    amountChange(element, field) {
        const numbers = this.spendmoneyForm.controls[field].value;
        // if(this.PrepareReceiptForm.controls[field].value) {
        //     const formattedAmount = this.currencyPipe.transform(this.PrepareReceiptForm.controls[field].value, this.CurrenctSymbol, 'symbol', '1.2-2');
        //     element.target.value = formattedAmount;
        // }
        if(numbers) {
            const numericValue = parseFloat(numbers.replace(/,/g, '')); // Remove commas and convert to a number
            const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
            element.target.value = formattedNumber;
          }
    }

/**
 * This function is used to getcontact data using the behaviour.
 */
    getContactDataUsingBehaviour():void {
        this.behaviorService.newContactData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            localStorage.setItem('istrackid', 'SpandMoneyDialog');
            this.Contactlist = [];
            if (result) {
                // PAYEECONTACTGUID
                this.spendmoneyForm.controls["Payee"].setValue(result.CONTACTNAME);
                this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(result.CONTACTGUID);
                this.Contactname = [result.CONTACTNAME];
                this.Contactlist = [];
            }
        })
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
        let result = this.MatterInputContact?.nativeElement?.value;
        if (result && result != null && result != undefined && this.CallHostlistner == false) {
            this.Contactdatashow(this.MatterInputContact.nativeElement);
            this.MatterInputContact.nativeElement.value = '';
            // this.spendmoneyForm.controls["Payee"].setValue(null);
            this.Contactlist = []
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.behaviorService.newContactData$.next(null);
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.subscription?.unsubscribe();
    }


/**
 * This function is used to set the tooltip data.
 */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['Expenditure']) {
            this.toolTipList = TooltipData['Expenditure'];
        } else {
            this.tooltipService.setToolTipData = ('Expenditure');
        }
    };

    /**
     * This function is used to Toggle the updates
     */
    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };

    /**
     * This function is used to detect the changes of the formcontrols
     * @param formControl -formcontrol value data
     */
    changeForControl(formControl) {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 }
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }

    }

/**
 * This function is used to For Add show popup data.
 */
    forAddshowpopupData():void {
        this.isItemSaveClicked = "no";
        this.getDataForTable = [];
        this.spendmoneyForm.controls["GST1"].setValue("0.00");
        this.spendmoneyForm.controls["AmountIncGST"].setValue("0.00");
        this.spendmoneyForm.controls["AmountExGST"].setValue("0.00");
        this.spendmoneyForm.controls["GSTType"].setValue("10% GST");
        this.GstTypeDiff = "10% GST";
        this.spendmoneyForm.controls["GST1"].disable();
        this.spendmoneyForm.controls["GST"].setValue(0.0);
        this.spendmoneyForm.controls["Amount"].setValue(0.0);
        this.FinalTotal = 0.0;
        this.FinalTotalGST = 0.0;
        this.GSTValForInGst = "0.00";
        if (this._data.FromWhere == "FromWIP") {
            this.Classtype("Matter Expense");
            this.spendmoneyForm.controls["Class"].setValue("Matter Expense");
        } else {
            this.Classtype("Expense");
            this.spendmoneyForm.controls["Class"].setValue("Expense");
        }
        this.amountCal();
        this.isLoadingResults = false;
    }

    /**
     * This function is used to check default validation.
     * @param bodyData -validation data.
     */
    CheckdefultValidation(bodyData: any):void {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if(value.FIELDNAME==='EXPENDITUREITEMS'){
                value.VALUEVALID = 'No'
            }
            if (value.FIELDNAME == "MATTERGUID") {
                value.VALUEVALID = 'No'
            }
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
    }

    /**
     * This function is used to check the paid type.
     * @param paidvalue -used to get the paidtype.
     */
    Paidtype(paidvalue) {
        this.PaidTypeName = paidvalue;
        if (paidvalue === "Paid") {
            this.Bankhide = false;
            $("#bank").removeClass("menu-disabled");
            this.spendmoneyForm.controls["DatePaid"].enable();
            this.spendmoneyForm.controls["Bankac"].enable();
            this.spendmoneyForm.controls["Type"].enable();
            this.spendmoneyForm.controls["ChequeNo"].enable();
            this.spendmoneyForm.controls["DatePaid"].setValue(new Date(), "dd/MM/yyyy");
            this.spendmoneyForm.controls["DatePaidForSend"].setValue(this.datepipe.transform(new Date(), "dd/MM/yyyy"));
            this.spendmoneyForm.controls["Bankac"].setValue(this.SendMoney_data.BANKACCOUNTNUMBER + " - " + this.SendMoney_data.BANKACCOUNTNAME);
            this.spendmoneyForm.controls["BankacGUID"].setValue(this.SendMoney_data.BANKACCOUNTGUID);
        } else if (paidvalue === "Unpaid") {
            this.Bankhide = true;
            $("#bank").addClass("menu-disabled");
            this.spendmoneyForm.controls["DatePaid"].disable();
            this.spendmoneyForm.controls["Bankac"].disable();
            this.spendmoneyForm.controls["Type"].disable();
            this.spendmoneyForm.controls["ChequeNo"].disable();
            this.spendmoneyForm.controls["Bankac"].setValue("0-0000");
            this.spendmoneyForm.controls["BankacGUID"].setValue("");
            this.spendmoneyForm.controls["DatePaidForSend"].setValue("");
            this.spendmoneyForm.controls["DatePaid"].setValue("");
        }
    }


    /**
     * This function is used to set the class type.
     * @param Classvalue -Classvalue.
     */
    Classtype(Classvalue, type?) {
        this.classtype = Classvalue;
        if (this._data.flag == 'true') {
            this.mattername = [this.matterdata.SELECTEDDESCRIPTION]
        } else {
            //this.mattername = []
        };


        if (Classvalue === "Expense") {
            this.defaultMatterAccount(Classvalue);
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.mattername = [];
            if (this.action == "new") {
                this.hide = true;
                $("#mattersnew").addClass("menu-disabled");
                this.spendmoneyForm.controls["Matter"].disable();
                this.spendmoneyForm.controls["GSTType"].enable();
            } else if (this.action != "new") {
                this.hide = true;
                this.expac = false;
                $("#mattersnew").addClass("menu-disabled");
                this.spendmoneyForm.controls["Matter"].disable();
            }
            this.forCommonEnable();
            // this.GstTypeforSelect('1.1');
            let tempError: any = this.errorWarningData.Error;
        } else if (Classvalue === "Matter Expense") {
            this.hide = false;
            this.expac = false;
            $("#mattersnew").removeClass("menu-disabled");
            this.defaultMatterAccount(Classvalue);
            if (this._data.FromWhere == "FromWIP") {
                this.spendmoneyForm.controls["Matter"].setValue(this.CurrentMatter.SHORTNAME);
                this.spendmoneyForm.controls["MatterGUID"].setValue(this.CurrentMatter.MATTERGUID);
            }
            if (this.currentUserData && this.currentUserData.POPUPSELECTMATTERFIRST && this.action == "new" && type != 'edit_money') {
                if(this.router.url != "/matters" && this.router.url != "/matter-details/work-in-progress") {
                    this.selectMatter();
                }
            }
            this.forCommonEnable();
        } else if (Classvalue === "Capital") {
            this.defaultMatterAccount(Classvalue);
            this.hide = true;
            this.expac = false;
            $("#mattersnew").addClass("menu-disabled");
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.spendmoneyForm.controls["Matter"].disable();
            this.mattername = [];
            this.forCommonEnable();
            // this.GstTypeforSelect('1.1');
            // this.spendmoneyForm.controls['GSTType'].disable();
            let tempError: any = this.errorWarningData.Error;
            if (tempError != undefined) {
                delete tempError["SHORTNAME"];
            }
        } else if (Classvalue === "Pay GST") {
            this.defaultMatterAccount(Classvalue);
            this.hide = true;
            $("#mattersnew").addClass("menu-disabled");
            this.mattername = [];
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.spendmoneyForm.controls["Matter"].disable();
            this.spendmoneyForm.controls["GSTType"].disable();
            this.GstTypeforSelect("No GST");
            let tempError: any = this.errorWarningData.Error;
            if (tempError != undefined) {
                delete tempError["SHORTNAME"];
            }
        } else if (Classvalue === "Pay Tax") {
            this.defaultMatterAccount(Classvalue);
            this.hide = true;
            this.expac = false;
            this.mattername = [];
            $("#mattersnew").addClass("menu-disabled");
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.spendmoneyForm.controls["Matter"].disable();
            this.spendmoneyForm.controls["GSTType"].disable();
            this.GstTypeforSelect("No GST");
            let tempError: any = this.errorWarningData.Error;
            if (tempError != undefined) {
                delete tempError["SHORTNAME"];
            }
        } else if (Classvalue === "Personal") {
            this.defaultMatterAccount(Classvalue);
            this.hide = true;
            this.expac = false;
            this.mattername = [];
            $("#mattersnew").addClass("menu-disabled");
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.spendmoneyForm.controls["Matter"].disable();
            this.spendmoneyForm.controls["GSTType"].disable();
            this.GstTypeforSelect("No GST");
            let tempError: any = this.errorWarningData.Error;
            if (tempError != undefined) {
                delete tempError["SHORTNAME"];
            }
        } else if (Classvalue === "Depreciation") {
            this.defaultMatterAccount(Classvalue);
            this.hide = true;
            this.expac = false;
            this.mattername = [];
            $("#mattersnew").addClass("menu-disabled");
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.spendmoneyForm.controls["Matter"].disable();
            this.spendmoneyForm.controls["GSTType"].disable();
            this.GstTypeforSelect("No GST");
            let tempError: any = this.errorWarningData.Error;
            if (tempError != undefined) {
                delete tempError["SHORTNAME"];
            }
        } else if (Classvalue === "Other") {
            this.defaultMatterAccount(Classvalue);
            this.hide = true;
            this.expac = false;
            this.mattername = [];
            $("#mattersnew").addClass("menu-disabled");
            this.spendmoneyForm.controls["Matter"].setValue("");
            this.spendmoneyForm.controls["MatterGUID"].setValue("");
            this.spendmoneyForm.controls["Matter"].disable();
            this.spendmoneyForm.controls["GSTType"].disable();
            this.GstTypeforSelect("No GST");
            let tempError: any = this.errorWarningData.Error;
            if (tempError != undefined) {
                delete tempError["SHORTNAME"];
            }
        }
    }

    /**
     * This function is used to defaultMatter Account.
     * @param value -MatterAccount data.
     */
    defaultMatterAccount(value) {
        let userData = JSON.parse(localStorage.getItem("currentUser"));
        if (value == 'Matter Expense' || value == "Pay GST" || value == "Pay Tax") {
            this.isLoadingResults = true;
            let accountGuid;
            if (value == 'Matter Expense') {
                accountGuid = userData.DISBURSEMENTACCOUNTGUID;
                const ExpenseacGUID = this.SendMoney_data && this.SendMoney_data.EXPENDITUREITEMS && this.SendMoney_data.EXPENDITUREITEMS[0] ? this.SendMoney_data.EXPENDITUREITEMS[0].EXPENSEACCOUNTGUID : null;
                if (!ExpenseacGUID) {
                    this.getAccount(accountGuid);
                } else {
                    this.isLoadingResults = false;
                    this.spendmoneyForm.controls["Expenseac"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].EXPENSEACCOUNTNUMBER);
                    this.spendmoneyForm.controls["ExpenseacGUID"].setValue(this.SendMoney_data.EXPENDITUREITEMS[0].EXPENSEACCOUNTGUID);
                }
            } else {
                accountGuid = userData.TAXPAYABLEACCOUNTGUID;
                this.getAccount(accountGuid);

            }
            // let accountGuid = value == 'Matter Expense' ? userData.DISBURSEMENTACCOUNTGUID : userData.TAXPAYABLEACCOUNTGUID;

        } else {
            this.spendmoneyForm.controls["Expenseac"].setValue('');
            this.spendmoneyForm.controls["ExpenseacGUID"].setValue('');
        }

    }

    /**
     * This function is used to Get the Account
     * @param accountGuid -accountguid value.
     */
    getAccount(accountGuid):void {
        this._mainAPiServiceService.getSetData({ ACCOUNTGUID: accountGuid }, "GetAccount").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
            let x = response.DATA.ACCOUNTS[0];
            this.spendmoneyForm.controls["Expenseac"].setValue(x.ACCOUNTCLASS + " - " + x.ACCOUNTNUMBER);
            this.spendmoneyForm.controls["ExpenseacGUID"].setValue(x.ACCOUNTGUID);
            this.isLoadingResults = false;
        }, (err) => {
            console.log(err);
        });
    }


    /**
     * This function is used to select the Contact matter data.
     */
    ContactMatter():void {
        this.searchContactData = [];
        this.Contactlist = this.searchContactData;
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
            width: "100%", disableClose: true, data: { type: "", },
        });
        dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem('istrackid', 'SpandMoneyDialog');
            if (result) {
                // PAYEECONTACTGUID
                this.spendmoneyForm.controls["Payee"].setValue(result.CONTACTNAME);
                this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(result.CONTACTGUID);
                this.Contactname = [result.CONTACTNAME];
            }
        });
    }

    /**
     * This function is used to get the payour
     * @param postData -payor data.
     */
    getPayor(postData) {
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData(postData, 'contact').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.getPayourarray = response.DATA.RECORDS;
                this.filteredOptions = this.f.Payee.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
                this.isLoadingResults = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            } else {
                this.isLoadingResults = false;
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }

    /**
     * this function is used to filter the payour Array
     * @param value 
     * @returns 
     */
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.getPayourarray.filter(option => (option.CONTACTNAME.toLowerCase().includes(filterValue)));
    }

    /**
     * This function is used to get the updated payee type
     * @param value 
     */
    PayeeTypeChange(value):void {
        this.spendmoneyForm.controls['Payee'].setValue(value);
    }

    /**
     * This function is used to select the matter data.
     */
    public selectMatter() {
        this.searchData = [];
        this.matterlist = this.searchData;

        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: "100%", disableClose: true, data: null, });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                localStorage.setItem('istrackid', 'SpandMoneyDialog');
                this.spendmoneyForm.controls["Matter"].setValue(result.SHORTNAME);
                this.spendmoneyForm.controls["MatterGUID"].setValue(result.MATTERGUID);
                this.mattername = [result.SELECTEDDESCRIPTION]
            }
        });
    }

    get f() {
        return this.spendmoneyForm.controls;
    }

    /**
     * This function is used to make the forcommonDisabled
     */
    commmonDisabled() {
        this.spendmoneyForm.controls["Class"].disable();
        this.spendmoneyForm.controls["GSTType"].disable();
        this.spendmoneyForm.controls["Matter"].disable();
        this.spendmoneyForm.controls["Note"].disable();
        this.spendmoneyForm.controls["AmountIncGST"].disable();
        this.spendmoneyForm.controls["Expenseac"].disable();
    }

    /**
     * This function is used to make the forcommonEnable
     */
    forCommonEnable() {
        if (this.classtype == "Matter Expense") {
            this.spendmoneyForm.controls["Matter"].enable();
        }
        this.spendmoneyForm.controls["Class"].enable();
        this.spendmoneyForm.controls["GSTType"].enable();
        this.spendmoneyForm.controls["Note"].enable();
        this.spendmoneyForm.controls["AmountIncGST"].enable();
        this.spendmoneyForm.controls["Expenseac"].enable();
    }

    /**
     * This function is used to check the multiline checkbox
     */
    multilineCheckbox() {

        this.HideButtonsDiv = this.f.MultiLineExpense.value

        if (!this.HideButtonsDiv) {
            this.Classtype("Expense");
        }
        this.size = 17;
        if (this.f.MultiLineExpense.value == true) {
            this.SubMain2btn = "disabled";
            this.Main3btn = "enable";
            this.dataTableHide = "yes";
            this.SubMain2btn = "disabled";
            this.Main3btn = "enable";
            this.commonEmptyFiild();
            if (this._data.FromWhere == "FromWIP") {
                this.Classtype("Matter Expense");
                this.spendmoneyForm.controls["Class"].setValue("Matter Expense");
            }
            this.commmonDisabled();
            if (this.action != "new") {
                this.editMoney(this.SendMoney_data.EXPENDITUREITEMS[0], 0);
            }
        } else {

            this.size = 28.33;
            this.Main3btn = "disabled";
            this.SubMain2btn = "enable";
            this.dataTableHide = "false";
            if (this.action == "new") {
                this.commonEmptyFiild();
                if (this._data.FromWhere == "FromWIP") {
                    this.Classtype("Matter Expense");
                    this.spendmoneyForm.controls["Class"].setValue("Matter Expense");
                }
                this.GstTypeforSelect("10% GST");
            } else if (this.action != "new" && this.SendMoney_data.MULTILINE == 1) {
                this.GstTypeforSelect("10% GST");
                this.commonEmptyFiild();
            }
            this.forCommonEnable();
        }
    }

    /**
     * This function is used to set the perticular Gst type and set it's value data.
     * @param val 
     */
    GstTypeforSelect(val) {
        this.GstTypeDiff = val;
        if (val == "Less Than 10% GST") {
            this.spendmoneyForm.controls["GST1"].enable();
        } else if (val == "Less Than 10% GST(Charge GST)") {
            this.spendmoneyForm.controls["GST1"].enable();
        } else if (val == "No GST(Charge GST)") {
            this.spendmoneyForm.controls["GST1"].disable();
            this.spendmoneyForm.controls["GST1"].setValue(0.0);
            this.spendmoneyForm.controls["GSTType"].setValue("No GST(Charge GST)");
        } else if (val == "No GST") {
            this.spendmoneyForm.controls["GST1"].disable();
            this.spendmoneyForm.controls["GST1"].setValue(0.0);
            this.spendmoneyForm.controls["GSTType"].setValue("No GST");
            // } else if (val == "10") {
            //     this.spendmoneyForm.controls["GST1"].disable();
            //     this.spendmoneyForm.controls["GST1"].setValue("10");
        } else if (val == "10% GST") {
            this.spendmoneyForm.controls['GST1'].disable();
            this.spendmoneyForm.controls["GSTType"].enable();
            this.spendmoneyForm.controls["GSTType"].setValue("10% GST");
        }
        this.amountCal();
    }

    /**
     * This function is used to select the spend Money Id.
     */
    selectSpendMoneyId(row) { }

    /**
     * This function is used to make the empty fields
     */
    commonEmptyFiild():void {
        this.spendmoneyForm.controls["GSTType"].setValue("10% GST");
        this.spendmoneyForm.controls["AmountIncGST"].setValue("0.00");
        this.spendmoneyForm.controls["GST1"].setValue("0.00");
        this.spendmoneyForm.controls["AmountExGST"].setValue("0.00");
        this.GSTValForExGst = "0.00";
        this.GSTValForInGst = "0.00";
        this.spendmoneyForm.controls["Class"].setValue("Expense");
        this.spendmoneyForm.controls["Note"].setValue("");
        this.spendmoneyForm.controls["Expenseac"].setValue(" ");
        this.spendmoneyForm.controls["ExpenseacGUID"].setValue(" ");
        this.spendmoneyForm.controls["MatterGUID"].setValue(" ");
        this.spendmoneyForm.controls["Matter"].setValue(" ");
        this.mattername = [];
    }

    /**
     * This function is used to Add the spend money Item data
     */
    AddMoneyItem():void {
        this.commonEmptyFiild();
        this.GstTypeDiff = "10% GST";
        this.SubMain2btn = "enable";
        this.Main3btn = "disabled";
        this.forCommonEnable();
        if (this._data.FromWhere == "FromWIP") {
            this.Classtype("Matter Expense");
            this.spendmoneyForm.controls["Class"].setValue("Matter Expense");
        } else {
            this.Classtype(this.classtype);
        }

    }

    /**
     * This function is used to save the item dialog data
     * @returns 
     */
    SaveItemDialog() {
        this.isItemSaveClicked = "yes";

        if (this.classtype !== 'Matter Expense' || !this.classtype) {
            this.spendmoneyForm.controls["Matter"].disable();
        };
        if (this.btnClickpurpose == "edit") {
            if (this.spendmoneyForm.controls['Expenseac'].value == undefined || this.spendmoneyForm.controls['Expenseac'].value == null || this.spendmoneyForm.controls['Expenseac'].value == '' || this.spendmoneyForm.controls['AmountIncGST'].value == 0 || this.spendmoneyForm.controls['AmountIncGST'].value == undefined) {
                this.FinalSaveData(true);
                return
            }
            this.getDataForTable[this.INDEX].EXPENDITURECLASS = this.f.Class.value;
            this.getDataForTable[this.INDEX].GST = this.f.GST1.value;
            this.getDataForTable[this.INDEX].EXPENSEACCOUNTGUID = (this.action == "edit" && this.getDataForTable[this.INDEX]?.EXPENSEACCOUNTGUID != undefined) ? this.getDataForTable[this.INDEX].EXPENSEACCOUNTGUID : this.f.ExpenseacGUID.value;
            this.getDataForTable[this.INDEX].EXPENSEACCOUNT = this.f.Expenseac.value;
            this.getDataForTable[this.INDEX].EXPENSEACCOUNTNUMBER = this.f.Expenseac.value;
            this.getDataForTable[this.INDEX].AMOUNT = this.f.AmountIncGST.value;
            this.getDataForTable[this.INDEX].MATTERGUID = (this.f.MatterGUID.value) ? this.f.MatterGUID.value : '';
            this.getDataForTable[this.INDEX].SHORTNAME = (this.f.Matter.value) ? this.f.Matter.value : '';
            this.getDataForTable[this.INDEX].NOTE = this.f.Note.value;
            this.getDataForTable[this.INDEX].DESCRIPTION = this.mattername;
            this.getDataForTable[this.INDEX].GSTTYPE = this.f.GSTType.value;
            this.btnClickpurpose = "save";
            this.globallyCalculation();
            this.ExpandDivFlag = false
        } else {
            //add only if condition
            if (this.spendmoneyForm.controls['Expenseac'].value == undefined || this.spendmoneyForm.controls['Expenseac'].value == null || this.spendmoneyForm.controls['Expenseac'].value == '' || this.spendmoneyForm.controls['AmountIncGST'].value == 0 || this.spendmoneyForm.controls['AmountIncGST'].value == undefined) {
                this.FinalSaveData(true);
                return
            }
            if (Object.keys(this.getDataForTable[0]).length == 0) {
                this.getDataForTable.splice(0, 1);
            }
            this.getDataForTable.push({
                EXPENDITURECLASS: this.f.Class.value,
                AMOUNT: this.f.AmountIncGST.value,
                GST: this.f.GST1.value,
                EXPENDITUREGUID: "",
                EXPENSEACCOUNTGUID: this.f.ExpenseacGUID.value,
                EXPENSEACCOUNT: this.f.Expenseac.value,
                EXPENSEACCOUNTNUMBER: this.f.Expenseac.value,
                MATTERGUID: this.f.MatterGUID.value,
                SHORTNAME: this.f.Matter.value,
                NOTE: this.f.Note.value,
                DESCRIPTION: this.mattername[0] ? this.mattername[0] : [],
                GSTTYPE: this.f.GSTType.value
            });
            this.globallyCalculation();
            this.ExpandDivFlag = false
            this.disableAddButtonFlag = false
        }
        this.SubMain2btn = "disabled"; //
        setTimeout(() => {
            this.commmonDisabled();//
            this.classtype = 'Expense';
            this.hide = true;
        }, 500);
        this.Main3btn = "enable";//
        this.highlightedRows = 0;//
        this.editMoney(this.getDataForTable[0], 0);
        this.disableAddButtonFlag = false
        // this.FinalSaveData(true);
    }

    /**
     * This function is used to make the gloable calculation data.
     */
    globallyCalculation():void {
        this.FAmount = [];
        this.FGst = [];
        this.getDataForTable.forEach((element) => {
            this.FAmount.push(Number(element.AMOUNT));
            this.FGst.push(Number(element.GST));
        });
        this.FinalTotal = Number(
            this.FAmount.reduce(function (a = 0, b = 0) {
                return a + b;
            }, 0)
        );
        this.FinalTotalGST = Number(
            this.FGst.reduce(function (a = 0, b = 0) {
                return a + b;
            }, 0)
        );
        // if(this.FinalTotal==null || this.FinalTotal==null || this.FinalTotalGST==null || this.FinalTotalGST==null){
        //   this.spendmoneyForm.controls['Amount'].setValue(0.00);
        //   this.spendmoneyForm.controls['GST'].setValue(0.00);
        // }
        this.spendmoneyForm.controls["Amount"].setValue(
            parseFloat(this.FinalTotal).toFixed(2)
        );
        this.spendmoneyForm.controls["GST"].setValue(
            parseFloat(this.FinalTotalGST).toFixed(2)
        );
    }
    
    clicktitle() { }

    /**
     * This function is used to check the cash type data
     * @param val 
     */
    Cashtype(val) {
        if (val == "Cheque") {
            this.spendmoneyForm.controls["ChequeNo"].setValue("1");
        } else {
            this.spendmoneyForm.controls["ChequeNo"].setValue("0");
        }
    }

    /**
     * This function is used to edit the selected ro data
     */
    editElement() {
        setTimeout(() => {
            this.forCommonEnable();
            this.btnClickpurpose = "edit";
            this.SubMain2btn = "enable";
        }, 200);

    }

    /**
     * This function is used to edit the money
     * @param row -this function is used to edit the row
     * @param index -index of the data.
     */
    editMoney(row, index) {
        this.MainData = row;
        this.INDEX = index;
        if (Object.keys(this.getDataForTable[0]).length == 0) {
            this.spendmoneyForm.controls["AmountIncGST"].setValue(row.AMOUNT);
            this.spendmoneyForm.controls["GST1"].setValue(row.GST);
            this.spendmoneyForm.controls["ExpenseacGUID"].setValue(row.EXPENSEACCOUNTGUID);
            this.spendmoneyForm.controls["GSTType"].setValue("10% GST");
        } else {
            setTimeout(() => {
                this.spendmoneyForm.controls["AmountIncGST"].setValue(row.AMOUNT);
                this.spendmoneyForm.controls["GST1"].setValue(row.GST);
                this.spendmoneyForm.controls["GSTType"].setValue(row.GSTTYPE);
            }, 200);

        }
        this.spendmoneyForm.controls["Class"].setValue(row.EXPENDITURECLASS);
        if(row && row.EXPENDITURECLASS) {
            this.Classtype(row.EXPENDITURECLASS, 'edit_money');
        } else {
            this.Classtype('Expense');
        }
        this.spendmoneyForm.controls["Note"].setValue(row.NOTE);
        this.spendmoneyForm.controls["Matter"].setValue(row.SHORTNAME);
        this.spendmoneyForm.controls["MatterGUID"].setValue(row.MATTERGUID);
        let ExpenseAc: any = (row.EXPENSEACCOUNTNUMBER || row.EXPENSEACCOUNTNAME) ? row.EXPENSEACCOUNTNUMBER + " - " + row.EXPENSEACCOUNTNAME : '';
        // this.spendmoneyForm.controls["Expenseac"].setValue(ExpenseAc);
        this.spendmoneyForm.controls['Expenseac'].setValue(row.EXPENSEACCOUNTNUMBER);
        this.spendmoneyForm.controls["ExpenseacGUID"].setValue(row.EXPENSEACCOUNTGUID);
        if (row && row.DESCRIPTION) {
            (row.DESCRIPTION.length == 0) ? this.mattername = [] : this.mattername = [row.DESCRIPTION];
        } else {
            setTimeout(() => {
                (row && row.SELECTEDDESCRIPTION) ? this.mattername = [row.SELECTEDDESCRIPTION] : this.mattername = [];
            }, 100);
        }

        // this.commmonDisabled();
    }

    /**
     * This function is used to delete the element
     */
    deleteElement():void {
        this.getDataForTable.splice(this.INDEX, 1);
        this.globallyCalculation();
        this.commonEmptyFiild();
        this.commmonDisabled();
        if (this.getDataForTable.length !== 0) {
            this.highlightedRows = 0;
            this.editMoney(this.getDataForTable[0], 0);
        }
        this.ExpandDivFlag = false
        this.disableAddButtonFlag = false
    }

    /**
     * This function is used to cancel the Item dialog
     */
    CancelItemDialog():void {
        this.SubMain2btn = "disabled";
        this.Main3btn = "enable";
        if (this.f.ExpenseacGUID.value == "" && this.GSTValForExGst == 0 && this.action == "new") {
            this.spendmoneyForm.controls["ExpenseacGUID"].setValue(this.getDataForTable[0].EXPENSEACCOUNTGUID);
            this.GSTValForExGst = this.getDataForTable[0].GST;
            this.editMoney(this.getDataForTable[0], 0);
        }
        this.commmonDisabled();
    }

    /**
     * this function is used to calculate the amount data
     */
    amountCal() {
        // let numericValue = this.f.AmountIncGST.value;
        // if(this.f.AmountIncGST.value) {
        //     numericValue = parseFloat(this.f.AmountIncGST.value.replace(/,/g, ''));
        // }
        // let amount = this.f.AmountIncGST.value;
        // let cal: any = (numericValue / 1.1).toFixed(2);
        // if (this.GstTypeDiff == "No GST") {
        //     this.maxVal = "";
        //     this.GSTValForExGst = numericValue;
        //     this.spendmoneyForm.controls["GST1"].setValue(0.0);
        // } else if (this.GstTypeDiff == "10% GST") {
        //     this.maxVal = "";
        //     this.GSTValForExGst = cal;
        //     this.GSTValAfterCal = (numericValue - cal).toFixed(2);
        // } else if (this.GstTypeDiff == "Less Than 10% GST") {
        //     this.GSTValAfterCal = this.f.GST1.value != 0.0 ? this.f.GST1.value : 0.0;
        //     this.GSTValForExGst = this.f.GST1.value != 0.0 ? cal : numericValue;
        //     if (this.f.GST1.value != 0.0) {
        //         if (numericValue - cal <= this.f.GST1.value) {
        //             this.GSTValAfterCal = (numericValue - cal).toFixed(2);
        //         } else {
        //             this.GSTValForExGst = (numericValue - this.f.GST1.value).toFixed(2);
        //         }
        //         this.maxVal = this.f.GST1.value != 0.0 ? (numericValue - cal).toFixed(2) : "";
        //     }
        // }
        let amount = this.f.AmountIncGST.value;
        let cal: any = (this.f.AmountIncGST.value / 1.1).toFixed(2);
        if (this.GstTypeDiff == "No GST") {
            this.maxVal = "";
            this.GSTValForExGst = amount;
            this.spendmoneyForm.controls["GST1"].setValue(0.0);
        }else if(this.GstTypeDiff == "No GST(Charge GST)"){
            this.maxVal = "";
            this.GSTValForExGst = amount;
            this.spendmoneyForm.controls["GST1"].setValue(0.0);
        }
         else if (this.GstTypeDiff == "10% GST") {
            this.maxVal = "";
            this.GSTValForExGst = cal;
            this.GSTValAfterCal = (amount - cal).toFixed(2);
            
        } else if (this.GstTypeDiff == "Less Than 10% GST" || this.GstTypeDiff == "Less Than 10% GST(Charge GST)") {
            this.GSTValAfterCal = this.f.GST1.value != 0.0 ? this.f.GST1.value : 0.0;
            this.GSTValForExGst = this.f.GST1.value != 0.0 ? cal : amount;
            if (this.f.GST1.value != 0.0) {
                if (amount - cal <= this.f.GST1.value) {
                    this.GSTValAfterCal = (amount - cal).toFixed(2);
                } else {
                    this.GSTValForExGst = (amount - this.f.GST1.value).toFixed(2);
                }
                this.maxVal = this.f.GST1.value != 0.0 ? (amount - cal).toFixed(2) : "";
            }
        }
    }

    /**
     * This function is used to calculate the Examount data
     */
    ExamountCall() {
        let amount = this.f.AmountExGST.value;
        let cal: any = (this.f.AmountExGST.value * 1.1).toFixed(2);
        this.GSTValForInGst = cal;
        this.GSTValAfterCal = (cal - amount).toFixed(2);
    }

    /**
     * This function is used to Choosed date for incured
     * @param type -type of the date
     * @param event -event data
     */
    choosedDateForIncurred(type: string, event: MatDatepickerInputEvent<Date>) {
        let begin = this.datepipe.transform(event.value, "dd/MM/yyyy");
        this.spendmoneyForm.controls["DateIncurredForSend"].setValue(begin);
    }

    /**
     * Used to coose the Date for paid.
     * @param type -type of the date.
     * @param event -event data
     */
    choosedDateForPaid(type: string, event: MatDatepickerInputEvent<Date>) {
        let begin = this.datepipe.transform(event.value, "dd/MM/yyyy");
        this.spendmoneyForm.controls["DatePaidForSend"].setValue(begin);
    }

    /**
     * This function is used to AddSpendmoney
     */
    Addspendmoney() {
        this.forCommonEnable();
    }

    /**
     * This function is used to commansendline one data.
     */
    CommonSendOneLineData() {
        this.setMainAmount = Number(this.f.AmountIncGST.value);
        this.setMainGST = Number(this.f.GST1.value);
        this.sendItem.push({
            // AMOUNT: Number(this.f.AmountIncGST.value),
            EXPENDITURECLASS: this.f.Class.value,
            EXPENDITUREGUID: "",
            EXPENDITUREITEMGUID: !this.SendMoney_data.EXPENDITUREITEMS ? "" : this.action == "duplicate" ? "" : this.SendMoney_data.EXPENDITUREITEMS.length != 0 ? this.SendMoney_data.EXPENDITUREITEMS[0].EXPENDITUREITEMGUID : "",
            EXPENSEACCOUNTGUID: (this.f.ExpenseacGUID.value) ? this.f.ExpenseacGUID.value : this.SendMoney_data?.EXPENDITUREITEMS?.[0]?.EXPENSEACCOUNTGUID,
            GSTTYPE: this.f.GSTType.value,
            GST: Number(this.f.GST1.value),
            MATTERGUID: this.f.MatterGUID.value,
            NOTE: this.f.Note.value,
            SHORTNAME: this.f.Matter.value,
            WORKITEMGUID: "",
            AMOUNTEXGST: Number(this.GSTValForExGst),
        });
    }

    /**
     * This function is used to send the multiline data.
     */
    commonSendMultiLineData():void {
        this.setMainAmount = this.FinalTotal;
        this.setMainGST = this.FinalTotalGST;

        // if(Object.keys(this.getDataForTable[0]).length==0){
        //     this.getDataForTable.splice(0,1);
        // }
        this.getDataForTable.forEach((element) => {
            this.sendItem.push({

                // AMOUNT: Number(element.AMOUNT),
                EXPENDITURECLASS: element.EXPENDITURECLASS,
                EXPENDITUREGUID: this.action != "new" ? this.SendMoney_data.EXPENDITUREGUID : "",
                EXPENDITUREITEMGUID: !element.EXPENDITUREITEMGUID ? "" : element.EXPENDITUREITEMGUID,
                EXPENSEACCOUNTGUID: element.EXPENSEACCOUNTGUID,
                GST: Number(element.GST),
                MATTERGUID: element.MATTERGUID,
                NOTE: element.NOTE,
                SHORTNAME: element.SHORTNAME,
                WORKITEMGUID: "",
                AMOUNTEXGST: Number(element.AMOUNT) - Number(element.GST),
            });
        });
    }

    /**
     * This function is used to save the all final data
     * @param isOnlyValidate -check the flag condition data
     * @returns 
     */
    FinalSaveData(isOnlyValidate?) {
        this.GetSetMixRetry = false;
        this.sendItem = [];
        this.FrtSdError = [];
        if (this.action == "new" && this.f.MultiLineExpense.value == false) {
            this.CommonSendOneLineData();
        } else if (this.action == "new" && this.f.MultiLineExpense.value == true && this.isItemSaveClicked == "no") {
            // this.CommonSendOneLineData();
            this.commonSendMultiLineData();
        } else if (this.action == "new" && this.f.MultiLineExpense.value == true && this.isItemSaveClicked == "yes") {
            this.commonSendMultiLineData();
        } else if (this.action != "new" && this.SendMoney_data.MULTILINE == 1 && this.f.MultiLineExpense.value == true) {
            this.commonSendMultiLineData();
        } else if (this.action != "new" && this.SendMoney_data.MULTILINE == 1 && this.f.MultiLineExpense.value == false) {

            this.getDataForTable = [];
            // first push and then get
            // need to remove class from hml and show box
            // parseFloat(this.f.AmountIncGST.value.replace(/,/g, ''));
            this.FinalTotal = Number(this.f.AmountIncGST.value);
            this.FinalTotalGST = Number(this.f.GST1.value);
            this.getDataForTable.push({
                // AMOUNT: Number(this.f.AmountIncGST.value),
                EXPENDITURECLASS: this.f.Class.value,
                EXPENDITUREGUID: "",
                EXPENDITUREITEMGUID: "",
                EXPENSEACCOUNTGUID: this.f.ExpenseacGUID.value,
                EXPENSEACCOUNT: this.f.Expenseac.value,
                GST: Number(this.f.GST1.value),
                MATTERGUID: this.f.MatterGUID.value,
                NOTE: this.f.Note.value,
                SHORTNAME: this.f.Matter.value,
                WORKITEMGUID: "",
                AMOUNT: Number(this.f.AmountIncGST.value),
            });
            this.commonSendMultiLineData();
            setTimeout(() => {
                this.spendmoneyForm.controls["ExpenseacGUID"].setValue(this.getDataForTable[this.INDEX]?.EXPENSEACCOUNTGUID);
            }, 0);
        } else if (this.action != "new" && this.SendMoney_data.MULTILINE == 0 && this.f.MultiLineExpense.value == false) {
            this.CommonSendOneLineData();
        } else if (this.action != "new" && this.SendMoney_data.MULTILINE == 0 && this.f.MultiLineExpense.value == true) {
            this.commonSendMultiLineData();
        }
        // for multiline
        if (this.f.MultiLineExpense.value == false) {
            this.multicheckboxval = 0;
        } else if (this.getDataForTable.length == 1 || this.getDataForTable.length == 0) {
            this.multicheckboxval = 0;
        } else {
            this.multicheckboxval = 1;
        }
        //ammount calculation
        // for ammount field
        this.FinalExGSTAmount = this.setMainAmount - this.setMainGST;
        let warningData: any = [];
        let error = "";
        // if (this.f.Notes.value == "") {
        //     error += "You should enter Notes.\n";
        //     warningData.push("You should enter Notes.\n")
        // }s

        // if (this.f.ExpenseacGUID.value == "") {
        //     error += "You should select a Expense a/c.\n";
        //     warningData.push("You should select a Expense a/c.\n")
        // }
        // if (this.GSTValForExGst == 0 && this.action == "new") {
        //     error += "Amount should not be 0.\n";
        //     warningData.push("Amount should not be 0.\n")
        // }
        if (error != "") {
            //this.toastr.error(error);
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: "100%", data: { errorValidation: warningData }, });
            this.confirmDialogRef.componentInstance.confirmMessage = "Check the Validation Section for Errors/Warnings.";
            return false;
        }
        let Data = {
            EXPENDITUREGUID: this.action == "edit" ? this.SendMoney_data.EXPENDITUREGUID : " ",
            EXPENDITURETYPE: this.f.Type.value,
            STATUS: this.f.Paid.value,
            CHEQUENO: this.f.ChequeNo.value,
            PAYEE: this.f.Payee.value,
            PAYEECONTACTGUID: this.f.PAYEECONTACTGUID.value,
            MULTILINE: this.multicheckboxval,
            AMOUNT: this.FinalExGSTAmount,
            GST: Number(this.setMainGST.toFixed(2)),
            DATE: this.f.DatePaidForSend.value,
            RECEIVEDDATE: this.f.DateIncurredForSend.value,
            BANKACCOUNTGUID: this.f.BankacGUID.value,
            USERCODE: "",
            SOURCEREFERENCE: this.f.Invoice.value,
            NOTE: this.f.Notes.value,
            EXPENDITUREITEMS: this.sendItem,
        };
        if (this.action == "edit") {
            this.FormAction = "update";
        } else if (this.action == "new" || this.action == "duplicate") {
            this.FormAction = "insert";
        }
        this.Setata(Data, isOnlyValidate);
        this.isItemSaveClicked = "no";
    }

    /**
     * INFO---->Add new code into the below code (Number.isNaN(potData.EXPENDITUREITEMS[0])
     *
     *  */

    /**
     * This function is used to set the data
     * @param potData -value of the spendmoney
     * @param isOnlyValidate -check the flag value
     * @returns 
     */
    Setata(potData, isOnlyValidate?) {

        if (Number.isNaN(potData.EXPENDITUREITEMS[0] && (Number.isNaN(potData.EXPENDITUREITEMS[0].AMOUNTEXGST) || Number.isNaN(potData.EXPENDITUREITEMS[0].GST)))) {
            let warningData: any = [];
            let error = "";
            if (this.action == "edit") {
                error += "Amount should not be 0.\n";
                warningData.push("Amount should not be 0.\n")
            }
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: "100%", data: { errorValidation: warningData }, });
            this.confirmDialogRef.componentInstance.confirmMessage = "Check the Validation Section for Errors/Warnings.";
            return false;

        }
        this.isspiner = true;
        let details = { FormAction: this.FormAction, VALIDATEONLY: true, Data: potData, };
        this._mainAPiServiceService.getSetData(details, "SetExpenditure").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
            //array empty of save item
            this.sendItem = [];
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
                this.checkValidation(response.DATA.VALIDATIONS, details, isOnlyValidate);
            } else if (response.CODE == 451 && response.STATUS == "warning") {
                this.checkValidation(response.DATA.VALIDATIONS, details, isOnlyValidate);
            } else if (response.CODE == 450 && response.STATUS == "error") {
                this.checkValidation(response.DATA.VALIDATIONS, details, isOnlyValidate);
            } else if (response.MESSAGE == "Not logged in") {
                this.dialogRef.close(false);
            }
        }, (error) => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation data
     * @param bodyData -value of the bodydata
     * @param details -value of the details
     * @param isOnlyValidate -flag data
     */
    async checkValidation(bodyData: any, details: any, isOnlyValidate?) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //     if (value.VALUEVALID == "No") {
        //         errorData.push(value.ERRORDESCRIPTION);
        //         tempError[value.FIELDNAME] = value;
        //     } else if (value.VALUEVALID == "Warning") {
        //         tempWarning[value.FIELDNAME] = value;
        //         warningData.push(value.ERRORDESCRIPTION);
        //     }
        // });
        // this.errorWarningData = { Error: tempError, Warning: tempWarning };
        // this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //     this.toastr.error(errorData);
        //     this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //     this.isspiner = false;
        //     this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: "100%", data: warningData, });
        //     this.confirmDialogRef.componentInstance.confirmMessage = "Are you sure you want to Save?";
        //     this.confirmDialogRef.afterClosed().subscribe((result) => {
        //         if (result) {
        //             this.isspiner = true;
        //             this.saveSpendMoneyData(details);
        //         } else {
        //             this.isspiner = false;
        //         }
        //         this.confirmDialogRef = null;
        //     });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //     this.saveSpendMoneyData(details);

        // }

        this.isspiner = false;
        await this.globalFunService.checkValidation(bodyData, details).pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback && !isOnlyValidate) {
                        this.saveSpendMoneyData(details);
                    }
                }
            });
    }

    /**
     * This function is used to save the spend money data.
     * @param data -data of the spend money
     */
    saveSpendMoneyData(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, "SetExpenditure").subscribe((response) => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                if (this.action !== "edit") {
                    this.toastr.success(" save successfully");
                    $('#Legal_Details').click();
                } else {
                    this.toastr.success(" update successfully");
                    $('#Legal_Details').click();
                }
                if (response.DATA.PDFFILENAME && response.DATA.PDFFILENAME != '') {
                    let confirmDilog = this.MatDialog.open(TrustMoneyReciptComponent, {
                        width: '100%', data: { action: 'Spend Money', forPDF: 'Yes', PDFURL: response.DATA.PDFFILENAME, reportTitle: 'Spend Money' }
                    });
                    confirmDilog.afterClosed().subscribe(result => {
                        if (result) { }
                        confirmDilog = null;
                    });
                }
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == "warning") {
                this.isspiner = false;
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == "error") {
                this.isspiner = false;
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == "Not logged in") {
                this.dialogRef.close(false);
            } else {
                this.isspiner = false;
            }
        }, (error) => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to open the banking dialog data.
     * @param type -name of the type
     * @param clickType -type of the click
     * @param evt -value of the event
     * @returns 
     */
    BankingDialogOpen(type: any, clickType, evt?: any) {
        if (evt && evt.pointerType == "") {
            return;
        }

        if (type == "") {
            if (this.classtype == "Expense" || this.classtype == "Matter Expense" || this.classtype == "Depreciation") {
                type = "EXPENSE";
            } else if (this.classtype == "Capital") {
                type = "ASSET";
            } else if (this.classtype == "Pay GST") {
                type = "LIABILITY";
            } else if (this.classtype == "Pay Tax") {
                type = "LIABILITY";
            } else if (this.classtype == "Personal") {
                type = "EQUITY";
            } else if (this.classtype == "Other") {
                type = "All";
            }
        }

        // for by default bank account select not trust account
        this.ChartHandlingData = { ClickType: "WithoutTrust", UseTrust: "No", PopUp: "", Lable: "Select Account", };
        localStorage.setItem("ChartURL", JSON.stringify(this.ChartHandlingData));
        this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);
        const dialogRef = this.MatDialog.open(BankingDialogComponent, { disableClose: true, width: "100%", data: { AccountType: type, FromWhere: "spendMonyExpense" }, });
        dialogRef.afterClosed().subscribe((result) => {

            localStorage.setItem('istrackid', 'SpandMoneyDialog');

            if (result) {
                if (clickType == "ExpenseClick") {
                    this.spendmoneyForm.controls["Expenseac"].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER);
                    this.spendmoneyForm.controls["ExpenseacGUID"].setValue(result.ACCOUNTGUID);
                } else {
                    this.spendmoneyForm.controls["Bankac"].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER + " " + result.ACCOUNTNAME);
                    this.spendmoneyForm.controls["BankacGUID"].setValue(result.ACCOUNTGUID);
                }
            }
        });
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * This function is used to get the matter data using the search
     * @param event 
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch:'True', Search: event }
        let searchobj = {
            "Action": "GetData",
            "Filters": { FastSearch: 'True', SearchString: event }
        }
        return this._mainAPiServiceService.getSetData(searchobj, 'matter')

    }

    /**
     * This function is used to show the data.
     * @param event 
     */
    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.spendmoneyForm.controls['Matter'].setValue(null);

                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }
                this.spendmoneyForm.controls["Matter"].setValue(data.SHORTNAME);
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.spendmoneyForm.controls['MatterGUID'].setValue(data.MATTERGUID);
                // this.matterChange('MatterGuid', data.MATTERGUID);
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }

    /**
     * This function is used to remove the mattername data
     * @param fruit 
     */
    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.spendmoneyForm.controls['Matter'].setValue('');
            this.spendmoneyForm.controls['MatterGUID'].setValue('');
        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };

    /**
     * This function is used to remove the selected data.
     * @param sortname 
     */
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    /**
     * This function is used to add the matters data
     * @param event -matters data
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        //  event.chipInput!.clear();

        this.spendmoneyForm.controls['Matter'].setValue(null);
    };

    /**
     * this function is used to make the selected field is an empty
     * @param event -event data
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        // const array3 = this.mattername;
        // if (array3.length <1) {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            // this.mattername.push(event.option.viewValue);
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.spendmoneyForm.controls['Matter'].setValue(null);
        }
        // }
    }

    /**
     * this function is used to select the Matter data. 
     * @param data -matter data.
     */
    selectedmatterguid(data: any) {
        this.spendmoneyForm.controls['MatterGUID'].setValue(data.MATTERGUID);
        this.spendmoneyForm.controls["Matter"].setValue(data.SHORTNAME);
        this.mattername = [data.SELECTEDDESCRIPTION]
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }

    }

    /**
     * This function is used to get the account information.
     * @param clickType -Account information
     */
    getAccountInfo(clickType) {
        this._mainAPiServiceService.getSetData({ AccountNumber: clickType == 'ExpenseClick' ? this.f.Expenseac.value : this.f.Bankac.value }, "GetAccount").pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result.DATA.ACCOUNTS[0]) {
                if (result.DATA.ACCOUNTS[0].ACCOUNTNUMBER != '' && result.DATA.ACCOUNTS[0].ACCOUNTCLASS != '') {
                    if (clickType == "ExpenseClick") {
                        this.spendmoneyForm.controls["Expenseac"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTCLASS + " - " + result.DATA.ACCOUNTS[0].ACCOUNTNUMBER);
                        this.spendmoneyForm.controls["ExpenseacGUID"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTGUID);
                    } else {
                        this.spendmoneyForm.controls["Bankac"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTCLASS + " - " + result.DATA.ACCOUNTS[0].ACCOUNTNUMBER + " " + result.DATA.ACCOUNTS[0].ACCOUNTNAME);
                        this.spendmoneyForm.controls["BankacGUID"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTGUID);
                    }
                } else {
                    if (clickType == "ExpenseClick") {
                        this.spendmoneyForm.controls["Expenseac"].setValue('');
                        this.spendmoneyForm.controls["ExpenseacGUID"].setValue('');
                    } else {
                        this.spendmoneyForm.controls["Bankac"].setValue('');
                        this.spendmoneyForm.controls["BankacGUID"].setValue('');
                    }


                }
            } else {
                if (clickType == "ExpenseClick") {
                    this.spendmoneyForm.controls["Expenseac"].setValue('');
                    this.spendmoneyForm.controls["ExpenseacGUID"].setValue('');
                } else {
                    this.spendmoneyForm.controls["Bankac"].setValue('');
                    this.spendmoneyForm.controls["BankacGUID"].setValue('');
                }
            }

        })

    }

    /**
    * Used For Getting event regarding data.
    * @param searchContactdata --To gat the updated data of PAYOR
    **/

    selectContactFormSearch(searchContactdata: string) {
        this.Contactlist = [];
        let payload = {
            "ACTION": "GetData",
            "FILTERS": {
                "FASTSEARCH": 1,
                "SEARCH": searchContactdata
            },
        };
        this._mainAPiServiceService.getSetData(payload, 'contact').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalueOFContact = response.DATA
                this.searchContactData = response.DATA.RECORDS
                if (response.DATA.RECORDS.length == 0) {
                    this.Contactlist.push({ 'CONTACTNAME': searchContactdata });
                } else {
                    this.Contactlist = this.searchContactData;
                }

            }
        })

    }

/**
 * This function is used to show the contact data
 * @param event-contact data 
 */
    Contactdatashow(event):void {
        this.Contactname = [];
        this.CallHostlistner = true;
        if (this.exportdatavalueOFContact && this.exportdatavalueOFContact.RECORDS && this.exportdatavalueOFContact.RECORDS.length != 0) {
            this.result = this.exportdatavalueOFContact.RECORDS[0]
            this.spendmoneyForm.controls['Payee'].setValue(null);
            this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(null);
            if (event.input) {
                event.input.value = '';
            } else {
                this.MatterInputContact.nativeElement.value = '';
            }
            this.spendmoneyForm.controls["Payee"].setValue(this.result.CONTACTNAME);
            this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(this.result.CONTACTGUID);
            this.Contactname = [this.result.CONTACTNAME];
        } else {
            // this.toolbarServiceService.ContactsDialog('new',this.MatterInputContact.nativeElement.value);
            //this.Contactname = [];
            // this.Contactlist=[];
            this.spendmoneyForm.controls["Payee"].setValue(this.MatterInputContact.nativeElement.value);
            // this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(result.CONTACTGUID);
            this.Contactname = [this.MatterInputContact.nativeElement.value];
            //this.MatterInputContact.nativeElement.value='';


        }
        this.CallHostlistner = false;
    }

    /**
     * This function is used to remove the contact
     * @param fruit 
     */
    removeContact(fruit: any):void {
        const index = this.Contactname.indexOf(fruit);
        if (index >= 0) {
            this.Contactname.splice(index, 1);
            // this.contactForm.controls['COMPANYCONTACTGUID'].setValue('');
            //this.Deliverable = [];
        }
        let sortname = fruit.split('-')[0]
        this.rmContactdata(sortname);
        this.MatterInputContact.nativeElement.value = '';
        this.Contactlist = [];
    };

/**
 * Thisd function is used to remove the contact data.
 * @param sortname -name of the contact.
 */
    rmContactdata(sortname) {
        this.selectedContctData.forEach((element: any, index: any) => {
            if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedContctData.splice(index, 1);
            }
        })
    }

    /**
     * This function is used to Add the new contact
     * @param event -contact data
     */
    addContact(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.Contactlist.forEach((element: any) => {
            if (element.CONTACTNAME == event.value) {
                // Add our fruit
                const array3 = this.Contactname;
                if (value) {
                    if (this.Contactname.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.Contactname = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        this.spendmoneyForm.controls["Payee"].setValue(null);
        this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(null);
    };

    /**
     * This function is used to select the contact.
     * @param result -selected result
     */
    selectedContact(result: any) {
        this.MatterInputContact.nativeElement.value = '';
        if (Object.keys(result).length == 1) {
            //this.toolbarServiceService.ContactsDialog('new',result.CONTACTNAME);
            this.spendmoneyForm.controls["Payee"].setValue(result.CONTACTNAME);
            this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(result.CONTACTGUID);
            this.Contactname = [result.CONTACTNAME];
            //this.Contactlist=[];

        } else {
            if (result) {
                this.spendmoneyForm.controls["Payee"].setValue(result.CONTACTNAME);
                this.spendmoneyForm.controls["PAYEECONTACTGUID"].setValue(result.CONTACTGUID);
                this.Contactname = [result.CONTACTNAME];
            }
        }
    }

    /**
     * This fun ction is used to expand the div
     * @param length 
     */
    ExpandDiv(length):void {
        this.lengthsize = length
        this.ExpandDivFlag = !this.ExpandDivFlag
        if (!this.ExpandDivFlag) {
            this.disableAddButtonFlag = false
        }

    }

    /**
     * This function is used to create spend money.
     */
    createSpendMoney():void {
        this.disableAddButtonFlag = true
        this.getDataForTable.unshift({

        });
        setTimeout(() => {

            this.AddMoneyItem()
            this.highlightedRows = 0;
            this.editMoney(this.getDataForTable[0], 0);
            this.ExpandDiv(0)
        }, 200);
        setTimeout(() => {
            this.spendmoneyForm.controls["Class"].setValue("Expense");
            this.spendmoneyForm.controls["Matter"].disable();
            this.spendmoneyForm.controls["AmountIncGST"].setValue("0.00");
            this.spendmoneyForm.controls["GST1"].setValue("0.00");
        }, 300);

    }

}
export interface UserData {
    AMOUNT: string;
    EXPENDITURECLASS: string;
    GST: string;
    NOTE: string;
}
