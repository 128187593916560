import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { fuseAnimations } from 'src/@fuse/animations';
import * as $ from "jquery";

@Component({
  selector: 'app-tasks-to-matter',
  templateUrl: './tasks-to-matter.component.html',
  styleUrls: ['./tasks-to-matter.component.scss'],
  animations: fuseAnimations
})
export class TasksToMatterComponent implements OnInit, OnDestroy {
  isLoadingResults: boolean = false;
  matterdetailForm: FormGroup;
  Classdata: any[] = [];
  classtype: any = 'General';
  ClassNameForDetail: any = '';
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub1: Subscription;
  TaskdataList: any = [];
  displayedColumns: any = ["PACKDESCRIPTION"];
  columnsToDisplay = [
    "ROLEASSIGNEDTO",
    "DUEDATEDESCRIPTION",
    "TASKDESCRIPTION"
];
  highlightedRowsList: any;
  selectedColore: string = "rgb(217, 217, 217)";
  Task_Pack_Item_dataSource: MatTableDataSource<unknown>;
  SelectedTaskPackItemRow: any;
  highlightedRows: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedMatter: any;
  isDisplay: boolean = false;
  isDisp: boolean = false;
  constructor(private _formBuilder: FormBuilder, private _mainAPiServiceService: MainAPiServiceService, public dialogRef: MatDialogRef<TasksToMatterComponent>, public behaviorService: BehaviorService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.selectedMatter = JSON.parse(localStorage.getItem('set_active_matters'));
    this.matterdetailForm = this._formBuilder.group({
      MATTERCLASS: ['']
    });
    this.getLookupData();
  }

  getLookupData(){
    this.isLoadingResults = true;
   this.sub1 = this._mainAPiServiceService.getSetData({ Action: "getdata", "Filters": { 'LookupType': 'Matter Class', IncludeAll: false } }, 'lookup').subscribe(async responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.isLoadingResults = false;
          this.Classdata = await responses.DATA.LOOKUPS;
          const currentClass = this.selectedMatter ? this.selectedMatter?.MATTERCLASS : this.classtype;
          this.matterdetailForm.controls['MATTERCLASS'].setValue(currentClass);
      } else if (responses.MESSAGE == 'Not logged in') {
          this.isLoadingResults = false;
          this.dialogRef.close(false);
      } else {
          this.isLoadingResults = false;
      }
    });
  }

  Classtype(value: any) {
    value = value == "General" ? 'General' : value;
    this.classtype = value;
    setTimeout(() => {
        let val: any = this.Classdata?.find(c => c['LOOKUPFULLVALUE'] == value);
        this.ClassNameForDetail = val?.LOOKUPFULLVALUE;
    }, 1000);
    this.TaskPackData();
  }

  TaskPackData(): void {
      this.isLoadingResults = true;
      let payload;
      payload = {
        MATTERCLASS: this.matterdetailForm.controls['MATTERCLASS'].value,
      };

      let finalpayload = {
          Action: 'GetData',
          Filters: {
              ...payload,
          },
      };
      this._mainAPiServiceService
          .getSetData(finalpayload, "task-pack")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (res) => {
                  if (res.CODE == 200 && res.STATUS == "success") {
                    this.isLoadingResults = false;
                    this.TaskdataList = new MatTableDataSource(res.DATA.RECORDS);
                    this.highlightedRowsList = res.DATA.RECORDS[0]?.TASKPACKGUID;
                    if(res.DATA.RECORDS.length == 0){
                      this.isDisp = true;
                      this.isDisplay = true;
                      this.Task_Pack_Item_dataSource = new MatTableDataSource([]);
                      this.SelectedTaskPackItemRow = '';
                      this.highlightedRows = '';
                    }
                    if(res.DATA.RECORDS.length > 0){
                      this.isDisp = false;
                      this.clickRow(res.DATA.RECORDS[0]);
                    }
                  } else if (res.STATUS == "error") {
                    this.isLoadingResults = true;
                    this.isDisp = true;
                    this.isDisplay = true;
                  } else if (res.MESSAGE == "Not logged in") {
                    this.isLoadingResults = true;
                    this.dialogRef.close(false);
                  }
              },
              (err) => {
                  this.isLoadingResults = false;
                  this.toastr.error(err);
                  this.isDisp = true;
                  this.isDisplay = true;
              }
          );
  }

  clickRow(Row: any) {
    this.getAllTaskPackItem(Row.TASKPACKGUID);
  }

  getAllTaskPackItem(id) {
    if(id == '' || id == undefined || id == null){
      return
    }
    this.isLoadingResults = true;
    let pastData = {
        Action: "GetData",
        Filters: {
            TASKPACKGUID: id,
        },
    };
    this._mainAPiServiceService
      .getSetData(pastData, "task-pack-item")
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(
          (res) => {
            if (res.CODE == 200 && res.STATUS == "success") {
              if(res.DATA.RECORDS.length == 0) {
                this.isDisplay = true;
              }
              this.Task_Pack_Item_dataSource = new MatTableDataSource(res.DATA.RECORDS);
              this.Task_Pack_Item_dataSource.paginator = this.paginator;
              if (res.DATA.RECORDS[0]) {
                this.isDisplay = false;
                this.SelectedTaskPackItemRow = res.DATA.RECORDS[0];
                this.highlightedRows = res.DATA.RECORDS[0].TASKPACKITEMGUID;
              }
              setTimeout(() => {
                  this.isLoadingResults = false;
              }, 500);
          }
      },
      (err) => {
          this.isLoadingResults = false;
          this.isDisplay = true;
          this.toastr.error(err);
      }
    );
  }

  clickRowTP(Row){
    this.SelectedTaskPackItemRow = Row;
  }

  taskToMatter(): void {
    if(!this.SelectedTaskPackItemRow) {
      return;
    }
    this.isLoadingResults = true;
    let payload;
    payload = {
      MATTERGUID : this.selectedMatter.MATTERGUID,
      TASKPACKGUID : this.SelectedTaskPackItemRow.TASKPACKGUID
    };

    let finalpayload = {
        Action: 'addtaskpacktomatter',
        DATA: {
            ...payload,
        },
    };
    this._mainAPiServiceService
      .getSetData(finalpayload, "task-pack")
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(
        (res) => {
          if (res.CODE == 200 && res.STATUS == "success") {
            this.isLoadingResults = false;
            this.toastr.success(res.MESSAGE);
            $("#refreshLegalTask").click();
            this.dialogRef.close(false);
          } else if (res.STATUS == "error") {
            this.isLoadingResults = false;
          } else if (res.MESSAGE == "Not logged in") {
            this.isLoadingResults = false;
            this.dialogRef.close(false);
          }
      },
      (err) => {
          this.isLoadingResults = false;
          this.toastr.error(err);
      }
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
    this.sub1?.unsubscribe();
  }

}
