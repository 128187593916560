import {
    Component,
    OnInit,
    Inject,
    ViewEncapsulation,
    ViewChild,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    ChangeDetectorRef,
    AfterViewInit,
    ElementRef,
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
    FormGroup,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { fuseAnimations } from "src/@fuse/animations";
import { Subject, Subscription, debounceTime, distinctUntilChanged, fromEvent, map, switchMap, takeUntil } from "rxjs";
import * as $ from "jquery";
import { RatesComponent } from "./rates/rates.component";
import { MattersComponent } from "./matters/matters.component";
import { PeopleComponent } from "./people/people.component";
import { DatePipe } from "@angular/common";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { TooltipService } from "@_services/tooltip.service";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";

@Component({
    selector: "app-contact-dialog",
    templateUrl: "./contact-dialog.component.html",
    styleUrls: ["./contact-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ContactDialogComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    subscription: Subscription;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    action: string;
    isEditRate: boolean = true;
    //contact: string;
    dialogTitle: string;
    FormAction: string;
    abc: string;
    contactguid: string;
    check: any;
    active: any;
    knowbyothername: boolean;
    birthdayreminder: boolean;
    samesstreet: boolean;
    postsameasstreet: string;
    isLoadingResults: boolean = false;
    isspiner: boolean = false;
    editDataCompny: boolean = true;
    namepusharray: any = [];
    rateDetail: any = [];
    contactDetail: any = [];
    allToolTip_Msg: any = {};
    GetSetMixRetry: boolean = true;
    testBool: boolean = false;
    toolTipList: any;
    CONTACTNAMEValueChange: Subscription;
    ContactCategoryData: any = [];
    private _unsubscribeAll$: Subject<void> = new Subject();
    @ViewChild(RatesComponent, { static: false }) userRatechild: RatesComponent;
    @ViewChild(MattersComponent, { static: false })
    matterchild: MattersComponent;
    @ViewChild(PeopleComponent, { static: false })
    peopleComChild: PeopleComponent;
    selectedtabIndex: any = 0;
    isCompanyAddress: boolean = false;
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub8: Subscription;
    subscription5: Subscription;
    COMPANYADDRESSES: any;
    @ViewChild('ContactInput1', { static: false }) ContactInput: ElementRef<HTMLInputElement>;
    private searchTerms = new Subject<string>();
    constructor(
        public globalFunService: GlobalFunctionsService,
        public MatDialog: MatDialog,
        public dialogRef: MatDialogRef<ContactDialogComponent>,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        public _matDialog: MatDialog,
        public behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private datepipe: DatePipe,
        public tooltipService: TooltipService,
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        localStorage.setItem("istrackid", "ContactDialogComponent");
        this.action = _data.action;
        if (this.action == "edit") {
            this.isEditRate = false;
            this.dialogTitle = "Update Contact";
        } else if (this.action == "duplicate") {
            this.dialogTitle = "Duplicate Contact";
            this.isEditRate = true;
        } else {
            this.isEditRate = true;
            this.dialogTitle = "New Contact";
        }
        this.behaviorService.dialogClose$
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((result) => {
                if (result != null) {
                    if (result.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                }
            });

        this.behaviorService.APIretryCall$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (
                (localStorage.getItem("istrackid") ==
                    "ContactDialogComponent" &&
                    this.GetSetMixRetry &&
                    result["click"] &&
                    result["data"]["contact"]) ||
                result["data"]["lookup"]
            ) {
                this.ngOnInit();
            }
            if (
                localStorage.getItem("istrackid") == "ContactDialogComponent" &&
                this.GetSetMixRetry &&
                result["click"] &&
                (result["data"]["contact"] ||
                    result["data"]["lookup"] ||
                    result["data"]["activity-rate"] ||
                    result["data"]["matter"])
            ) {
                this.ngOnInit();
                this.userRatechild.loadRateDate("user-rate", 1);
                this.userRatechild.loadRateDate("activity-rate", 2);
                this.userRatechild.loadRateDate("activity-rate", 3);
                this.matterchild.ngOnInit();
            }
            if (
                localStorage.getItem("istrackid") == "ContactDialogComponent" &&
                this.GetSetMixRetry == false &&
                result["click"] &&
                result["data"]["contact"]
            ) {
                this.ondialogSaveClick();
            }
        });

        this.behaviorService.MatterpopupContactData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                setTimeout(() => {
                    if (this.action != "edit") {
                        this.contactForm.controls["CONTACTID"].setValue(result);
                        this.contactIdTemp = result;
                    }
                }, 1000);
            }
        });

        this.subscription5 = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap(async (term: string) => this.splitname(term)))
            .subscribe();
    }

    common: Common[];
    nameSelected: string;
    value: number;
    contactForm: FormGroup;
    contactIdTemp: any;

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.newContactData$.next(null);
        this.contactForm = this._formBuilder.group({
            PreferedName: [""],
            CONTACTGUID: [""],
            CONTACTCATEGORY: [""],
            CONTACTID: ["", Validators.required],
            CONTACTNAME: ["", Validators.required],
            CONTACTTYPE: ["", Validators.required],
            ACTIVE: [""],
            //Company
            POSITION: [],
            //person
            COMPANYCONTACTGUID: [""],
            SALUTATION: [""],
            COMPANYCONTACTGUIDTEXT: [""],
            TITLE: [""],
            GIVENNAMES: [""],
            NAMETITLE: [""],
            MIDDLENAMES: [""],
            NAMELETTERS: [""],
            FAMILYNAME: [""],
            KNOWNBYOTHERNAME: [""],
            OTHERFAMILYNAME: [""],
            OTHERGIVENNAMES: [""],
            REASONFORCHANGE: [""],
            USEPARENTADDRESS: [""],
            OCCUPATION: [""],
            //Other
            GENDER: [""],
            DATEOFBIRTH: [""],
            DATEOFBIRTHTEXT: [""],
            MARITALSTATUS: [""],
            SPOUSE: [""],
            NUMBEROFDEPENDANTS: [""],
            BIRTHDAYREMINDER: [""],
            TOWNOFBIRTH: [""],
            COUNTRYOFBIRTH: [""],
            DATEOFDEATH: [""],
            DATEOFDEATHTEXT: [""],
            CAUSEOFDEATH: [""],
            DATEOFDEATHRANGE: [""],
            DATEOFDEATHRANGETEXT: [""],
            PLACEOFDEATH: [""],
            //address
            ADDRESS1: [""],
            ADDRESS2: [""],
            ADDRESS3: [""],
            SUBURB: [""],
            STATE: [""],
            POSTCODE: [""],
            COUNTRY: [""],
            SAMEASSTREET: [""],
            POSTALADDRESS1: [""],
            POSTALADDRESS2: [""],
            POSTALADDRESS3: [""],
            POSTALSUBURB: [""],
            POSTALSTATE: [""],
            POSTALPOSTCODE: [""],
            POSTALCOUNTRY: [""],
            DX: [""],
            DXSUBURB: [""],
            //ph/web
            PHONE: [""],
            PHONE2: [""],
            FAX: [""],
            FAX2: [""],
            MOBILE: [""],
            EMAIL: [""],
            EMAIL2: [""],
            ELECTRONICSERVICEEMAIL: [""],
            WEBADDRESS: [""],
            SKYPEUSERNAME: [""],
            //id
            ASSOCIATIONNO: [""],
            HEALTHFUNDPROVIDER: [""],
            HEALTHFUNDNUMBER: [""],
            MEDICARENUMBER: [""],
            PRACTICINGCERTIFICATENO: [""],
            ACN: [""],
            ABN: [""],
            TFN: [""],
            LICENCENO: [""],
            NATIONALNAMESINDEX: [""],
            POWEROFATTORNEYBOOK: [""],
            POWEROFATTORNEYNO: [""],
            MASTERINDEXNO: [""],
            CRIMINALRECORDDATE: [""],
            CRIMINALdate: [],
            LICENCECOUNTRY: [""],
            NATIONALIDENTITYNO: [""],
            NATIONALIDENTITYCOUNTRY: [""],
            FAMILYCOURTLAWYERNO: [""],
            NOTES: [""],
            //rates Tab
            DEFAULTCURRENCYID: [""],
            GSTTYPE: [""],
            SOPHISTICATEDCLIENT: [""],
            LICENCESCANLOCATION: [""],
            LICENCEFILEDATA: [""],
            NATIDENTSCANLOCATION: [""],
            NATIDENTFILEDATA: [""],

            //Bank Details
            BANKACCOUNTNAME:[''],
            BANKADDRESS:[''],
            BANKBSB:[''],
            BANKACCOUNTNUMBER:[''],
            FOREIGNEFTSWIFTCODE:[''],
            FOREIGNEFTIBAN:[''],
            FOREIGNEFTBANKNAME:[''],
            FOREIGNEFTCOUNTRY:[''],
            FOREIGNEFTCURRENCY:['']
        });
        this.common = [
            { Id: 1, Name: "Person" },
            { Id: 2, Name: "Company" },
            { Id: 3, Name: "Party" },
            { Id: 4, Name: "Payee/Payor" },
        ];

        // // START this is used for *CONTACTNAME* Name feild  to call API
        // this.CONTACTNAMEValueChange = this.contactForm.controls[
        //     "CONTACTNAME"
        // ].valueChanges
        //     .pipe(distinctUntilChanged(),
        //      debounceTime(500))
        //     .subscribe((data) => {
        //         const value = this.contactForm.controls["SALUTATION"].value;
        //         // if(this.action != 'edit'){
        //         this.splitname();
        //         // }
        //     });
        // END  this is used for *CONTACTNAME* Name feild  to call API

        if (this._data?.selectedList) {
            this.nameSelected = this._data?.selectedList;
        } else {
            this.nameSelected = "Person";
        }
        if (this.action === "edit" || this.action === "duplicate") {
            let contactguidforbody = {};
            if (this._data.CONTACTGUID && this._data.CONTACTGUID != "") {
                // contactguidforbody = { CONTACTGUID: this._data.CONTACTGUID };
                contactguidforbody = {
                    ACTION: "GetData",
                    FILTERS: {
                        CONTACTGUID: this._data.CONTACTGUID,
                    },
                };
            } else {
                // contactguidforbody = { CONTACTGUID: localStorage.getItem('contactGuid') };
                contactguidforbody = {
                    ACTION: "GetData",
                    FILTERS: {
                        CONTACTGUID: localStorage.getItem("contactGuid"),
                    },
                };
            }
            this.getContact(contactguidforbody);
        } else {
            this.isLoadingResults = true;
            let details = {
                Action: "default",
                VALIDATEONLY: false,
                DATA: {},
                FIELDTIPS: this.allToolTip_Msg,
            };
            this._mainAPiServiceService
                .getSetData(details, "contact")
                .subscribe(
                    (response) => {
                        if (
                            response.CODE == 200 &&
                            (response.STATUS == "OK" ||
                                response.STATUS == "success")
                        ) {
                            let contactData: any = response.DATA.DEFAULTVALUES;
                            this.allToolTip_Msg = response.DATA.FIELDTIPS
                                ? response.DATA.FIELDTIPS
                                : {};
                            this.contactIdTemp = contactData.CONTACTID;
                            this.contactForm.patchValue({
                                // CONTACTTYPE: contactData.BILLING.CONTACTTYPE,
                                // USEPARENTADDRESS: contactData.PARENTGROUP.USEPARENTADDRESS,
                                CONTACTID: contactData.CONTACTID,
                                GSTTYPE: contactData.BILLING.GSTTYPE,
                                DEFAULTCURRENCYID:
                                    contactData.BILLING.DEFAULTCURRENCYID,
                                CONTACTCATEGORY: contactData.CONTACTCATEGORY,
                            });
                        } else if (
                            response.CODE == 451 &&
                            response.STATUS == "warning"
                        ) {
                            this.toastr.warning(response.MESSAGE);
                        } else if (
                            response.CODE == 450 &&
                            response.STATUS == "error"
                        ) {
                            this.toastr.error(response.MESSAGE);
                        } else if (response.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                        this.isLoadingResults = false;
                    },
                    (error) => {
                        this.isLoadingResults = false;
                        this.toastr.error(error);
                    }
                );
            this.contactForm.controls["ACTIVE"].setValue(true);
            let userProduct = JSON.parse(localStorage.getItem("currentUser"));
            // if (userProduct.PRODUCTTYPE == "Barrister") {
            //   this.nameSelected = "Company";
            // }
        }
        this.LookUpAPI();
        //for Company
        if (
            this._data.contect_details ||
            (this._data.contect_details !== "undefined" &&
                this._data.flagNewConactMatter == true)
        ) {
            if (
                this._data.contect_details &&
                this._data.contect_details[0].CONTACTTYPE == "Company"
            ) {
                let getContactData = this._data.contect_details[0];
                this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(
                    getContactData.CONTACTNAME
                );
                //  this.contactForm.controls['USEPARENTADDRESS'].setValue(true);
                setTimeout(() => {
                    // this.contactForm.controls['USEPARENTADDRESS'].patchValue({USEPARENTADDRESS:true})
                    this.isCompanyAddress = true;
                }, 500);
                //  this.contactForm.controls['OCCUPATION'].setValue(getContactData.OCCUPATION);
                //  this.contactForm.controls['SALUTATION'].setValue(getContactData.SALUTATION);
                //  this.contactForm.controls['POSITION'].setValue(getContactData.POSITION);
                //  this.contactForm.controls['FAMILYNAME'].setValue(getContactData.FAMILYNAME);
                this.contactForm.controls["ADDRESS1"].setValue(
                    getContactData.ADDRESS1
                );
                this.contactForm.controls["ADDRESS2"].setValue(
                    getContactData.ADDRESS2
                );
                this.contactForm.controls["ADDRESS3"].setValue(
                    getContactData.ADDRESS3
                );
                this.contactForm.controls["SUBURB"].setValue(
                    getContactData.SUBURB
                );
                this.contactForm.controls["STATE"].setValue(
                    getContactData.STATE
                );
                this.contactForm.controls["POSTCODE"].setValue(
                    getContactData.POSTCODE
                );
                this.contactForm.controls["COUNTRY"].setValue(
                    getContactData.COUNTRY
                );
                let contactguidforbody = {
                    CONTACTGUID: getContactData.CONTACTGUID,
                };
                //this.getContact(contactguidforbody);
                // this.isCompanyAddress = getContactData.ACTIVE == 0 ? false : true;
            }
        }

        let tempError: any = [];
        let tempWarning: any = {};

        // tempError['CONTACTTYPE'] = {};
        tempError["CONTACTNAME"] = {};

        this.errorWarningData = { Error: tempError, Warning: [tempWarning] };
        this.errorWarningDataArray = {
            Error: tempError,
            Warning: [tempWarning],
        };
       this.sub8 = this.contactForm.controls["USEPARENTADDRESS"].valueChanges.subscribe(
            (val) => {
                this.isCompanyAddress = val;
            }
        );
        this.contactForm.valueChanges.subscribe((x: any) => {
            this.changeForControl(x);
        });
        this.setTooltipData();
        setTimeout(() => {
            if (
                this._data &&
                this._data.action == "new" &&
                this._data.ContactName
            ) {
                this.contactForm.controls["CONTACTNAME"].setValue(
                    this._data.ContactName
                );
            }
        }, 1000);
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        // this.contactForm.controls["CONTACTNAME"].valueChanges.pipe(distinctUntilChanged(),debounceTime(500)).subscribe(data=>{
        //     this.splitname();  
        // })

        // fromEvent(this.ContactInput.nativeElement,'keyup').pipe(
        //     map((event: any) => event.target.value),
        //     distinctUntilChanged(),debounceTime(500)
        //   ).subscribe(data=>{
        //     this.splitname();
        //     // this.selectMatterFormSearch(data);
        // })
    }

    ngOnChanges(changes: SimpleChanges) {
        //     this.CONTACTNAMEValueChange = this.contactForm.controls['CONTACTNAME'].valueChanges
        //   .pipe(debounceTime(500))
        //   .subscribe(data => {
        //     // const value = this.contactForm.controls['SALUTATION'].value;
        //     // if(!value){
        //       this.splitname();
        //     // }
        //   });
    }

    /**
     * This function is used to set the tooltip data
     */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem("FieldTooltipData"));
        if (TooltipData && TooltipData["Contact"]) {
            this.toolTipList = TooltipData["Contact"];
        } else {
            this.tooltipService.setToolTipData = "Contact";
        }
    }

    /**
     * This function is used to Get the lookup API data. 
     */
    LookUpAPI():void {
        let ContactCategoryLookups = JSON.parse(
            localStorage.getItem("Contact_Category_lookups_")
        );
        if (ContactCategoryLookups && ContactCategoryLookups != null) {
            this.ContactCategoryData = ContactCategoryLookups;

            //this.contactForm.controls['CONTACTCATEGORY'].setValue( this.ContactCategoryData[0].LOOKUPFULLVALUE);

            this.isLoadingResults = false;
        } else {
           this.sub = this._mainAPiServiceService
                .getSetData(
                    {
                        Action: "getdata",
                        Filters: { LookupType: "contact category" },
                    },
                    "lookup"
                )
                .subscribe((responses) => {
                    if (
                        responses.CODE === 200 &&
                        responses.STATUS === "success"
                    ) {
                        this.ContactCategoryData = responses.DATA.LOOKUPS;
                        //this.contactForm.controls['CONTACTCATEGORY'].setValue( this.ContactCategoryData[0].LOOKUPFULLVALUE);
                        localStorage.setItem(
                            "Contact_Category_lookups_",
                            JSON.stringify(responses.DATA.LOOKUPS)
                        );
                        this.isLoadingResults = false;
                    }
                });
        }
    }

    /**
     * This function is used to toggle for the update
     */
    ToggleForUpadte():void {
        this.testBool = !this.testBool;
        this.setTooltipData();
    }

    /**
     * This function is used to copytooltiptext data
     * @param event -event data value
     */
    copyTooltipText(event: any):void {
        navigator.clipboard.writeText(event);
    }
    data(value: any) {}

    onCategoryChange(event: any) {}

    changeText(event: any) {}

    /**
     * This unction is used to get the contact data value
     * @param contactguidforbody -value data
     */
    getContact(contactguidforbody) {
        this.isLoadingResults = true;
        // let getdetails = { contactguidforbody };
        this._mainAPiServiceService
            .getSetData(contactguidforbody, "contact")
            .subscribe((res) => {
                if (res.MESSAGE == "Not logged in") {
                    this.dialogRef.close(false);
                } else {
                    if (res.DATA.RECORDS[0]) {
                        this.allToolTip_Msg = res.DATA.FIELDTIPS
                            ? res.DATA.FIELDTIPS
                            : {};
                        let getContactData = res.DATA.RECORDS[0];
                        this.COMPANYADDRESSES = getContactData?.COMPANYADDRESSES;
                        this.behaviorService.contactData(getContactData);
                        if (this._data && !this._data.CONTACTGUID) {
                            localStorage.setItem(
                                "contactGuid",
                                getContactData.CONTACTGUID
                            );
                        }
                        localStorage.setItem(
                            "getContact_edit",
                            getContactData.CONTACTGUID
                        );
                        this.editDataCompny =
                            getContactData.COMPANYCONTACTGUID != "" &&
                            getContactData.COMPANYNAME != ""
                                ? false
                                : true;
                        this.nameSelected = getContactData.CONTACTTYPE;
                        this.active =
                            getContactData.ACTIVE == "Inactive" ? false : true;
                        // this.knowbyothername = getContactData.KNOWNBYOTHERNAME == 0 ? true : false;
                        this.birthdayreminder =
                            getContactData.BIRTHDAYREMINDER == 0 ? false : true;
                        if (getContactData.KNOWNBYOTHERNAME == 0) {
                            this.knowbyothername = false;
                            this.contactForm.get("OTHERGIVENNAMES").disable();
                            this.contactForm.get("OTHERFAMILYNAME").disable();
                            this.contactForm.get("REASONFORCHANGE").disable();
                        } else {
                            this.knowbyothername = true;
                            this.contactForm.get("OTHERGIVENNAMES").enable();
                            this.contactForm.get("OTHERFAMILYNAME").enable();
                            this.contactForm.get("REASONFORCHANGE").enable();
                        }
                        if (getContactData.SAMEASSTREET == 1) {
                            this.contactForm.get("POSTALADDRESS1").disable();
                            this.contactForm.get("POSTALADDRESS2").disable();
                            this.contactForm.get("POSTALADDRESS3").disable();
                            this.contactForm.get("POSTALSUBURB").disable();
                            this.contactForm.get("POSTALSTATE").disable();
                            this.contactForm.get("POSTALPOSTCODE").disable();
                            this.contactForm.get("POSTALCOUNTRY").disable();
                            this.samesstreet = true;
                        } else {
                            this.contactForm.get("POSTALADDRESS1").enable();
                            this.contactForm.get("POSTALADDRESS2").enable();
                            this.contactForm.get("POSTALADDRESS3").enable();
                            this.contactForm.get("POSTALSUBURB").enable();
                            this.contactForm.get("POSTALSTATE").enable();
                            this.contactForm.get("POSTALPOSTCODE").enable();
                            this.contactForm.get("POSTALCOUNTRY").enable();
                            this.samesstreet = false;
                        }
                        if (this.action === "edit") {
                            this.contactForm.controls["CONTACTGUID"].setValue(
                                getContactData.CONTACTGUID
                            );
                        }
                        this.contactForm.controls["CONTACTCATEGORY"].setValue(
                            getContactData.CONTACTCATEGORY
                        );
                        this.contactForm.controls["CONTACTNAME"].setValue(
                            getContactData.CONTACTNAME
                        );
                        this.contactForm.controls["CONTACTTYPE"].setValue(
                            getContactData.CONTACTTYPE
                        );
                        this.contactForm.controls[
                            "COMPANYCONTACTGUID"
                        ].setValue(getContactData.COMPANYCONTACTGUID);
                        this.contactForm.controls[
                            "COMPANYCONTACTGUIDTEXT"
                        ].setValue(getContactData.COMPANYNAME);
                        this.contactForm.controls["TITLE"].setValue(
                            getContactData.TITLE
                        );
                        this.contactForm.controls["SALUTATION"].setValue(
                            getContactData.SALUTATION
                        );
                        // this.contactForm.controls['ACTIVE'].setValue(getContactData.ACTIVE);
                        this.contactForm.controls["CONTACTID"].setValue(
                            this.action === "duplicate"
                                ? ""
                                : getContactData.CONTACTID
                        );
                        this.contactIdTemp = getContactData.CONTACTID;
                        this.contactForm.controls["ACTIVE"].setValue(
                            !this.active ? false : true
                        );
                        this.contactForm.controls["USEPARENTADDRESS"].setValue(
                            getContactData.USEPARENTADDRESS == 0 ? false : true
                        );
                        this.contactForm.controls["GIVENNAMES"].setValue(
                            getContactData.GIVENNAMES
                        );
                        this.contactForm.controls["NAMETITLE"].setValue(
                            getContactData.NAMETITLE
                        );
                        this.contactForm.controls["MIDDLENAMES"].setValue(
                            getContactData.MIDDLENAMES
                        );
                        this.contactForm.controls["NAMELETTERS"].setValue(
                            getContactData.NAMELETTERS
                        );
                        this.contactForm.controls["FAMILYNAME"].setValue(
                            getContactData.FAMILYNAME
                        );
                        this.contactForm.controls["KNOWNBYOTHERNAME"].setValue(
                            this.knowbyothername
                        );
                        this.contactForm.controls["OTHERFAMILYNAME"].setValue(
                            getContactData.OTHERFAMILYNAME
                        );
                        this.contactForm.controls["OTHERGIVENNAMES"].setValue(
                            getContactData.OTHERGIVENNAMES
                        );
                        this.contactForm.controls["REASONFORCHANGE"].setValue(
                            getContactData.REASONFORCHANGE
                        );
                        this.contactForm.controls["OCCUPATION"].setValue(
                            getContactData.OCCUPATION
                        );
                        //Company
                        this.contactForm.controls["POSITION"].setValue(
                            getContactData.POSITION
                        );
                        //Other
                        this.contactForm.controls["GENDER"].setValue(
                            getContactData.GENDER
                        );

                        if (getContactData.DATEOFBIRTH) {
                            let DATEOFBIRTH =
                                getContactData.DATEOFBIRTH.split("/");
                            this.contactForm.controls["DATEOFBIRTH"].setValue(
                                new Date(
                                    DATEOFBIRTH[1] +
                                        "/" +
                                        DATEOFBIRTH[0] +
                                        "/" +
                                        DATEOFBIRTH[2]
                                )
                            );
                            this.contactForm.controls[
                                "DATEOFBIRTHTEXT"
                            ].setValue(getContactData.DATEOFBIRTH);
                        }
                        if (getContactData.DATEOFDEATH) {
                            let DATEOFDEATH =
                                getContactData.DATEOFDEATH.split("/");
                            this.contactForm.controls["DATEOFDEATH"].setValue(
                                new Date(
                                    DATEOFDEATH[1] +
                                        "/" +
                                        DATEOFDEATH[0] +
                                        "/" +
                                        DATEOFDEATH[2]
                                )
                            );
                            this.contactForm.controls[
                                "DATEOFDEATHTEXT"
                            ].setValue(getContactData.DATEOFDEATH);
                        }
                        if (getContactData.DATEOFDEATHRANGE) {
                            let DATEOFDEATHRANGE =
                                getContactData.DATEOFDEATHRANGE.split("/");
                            this.contactForm.controls[
                                "DATEOFDEATHRANGE"
                            ].setValue(
                                new Date(
                                    DATEOFDEATHRANGE[1] +
                                        "/" +
                                        DATEOFDEATHRANGE[0] +
                                        "/" +
                                        DATEOFDEATHRANGE[2]
                                )
                            );
                            this.contactForm.controls[
                                "DATEOFDEATHRANGETEXT"
                            ].setValue(getContactData.DATEOFDEATHRANGE);
                        }

                        this.contactForm.controls["PLACEOFDEATH"].setValue(
                            getContactData.PLACEOFDEATH
                        );
                        this.contactForm.controls["MARITALSTATUS"].setValue(
                            getContactData.MARITALSTATUS
                        );
                        this.contactForm.controls["SPOUSE"].setValue(
                            getContactData.SPOUSE
                        );
                        this.contactForm.controls[
                            "NUMBEROFDEPENDANTS"
                        ].setValue(getContactData.NUMBEROFDEPENDANTS);
                        this.contactForm.controls["BIRTHDAYREMINDER"].setValue(
                            this.birthdayreminder
                        );
                        this.contactForm.controls["TOWNOFBIRTH"].setValue(
                            getContactData.TOWNOFBIRTH
                        );
                        this.contactForm.controls["COUNTRYOFBIRTH"].setValue(
                            getContactData.COUNTRYOFBIRTH
                        );
                        //address
                        this.contactForm.controls["ADDRESS1"].setValue(
                            getContactData.ADDRESS1
                        );
                        this.contactForm.controls["ADDRESS2"].setValue(
                            getContactData.ADDRESS2
                        );
                        this.contactForm.controls["ADDRESS3"].setValue(
                            getContactData.ADDRESS3
                        );
                        this.contactForm.controls["SUBURB"].setValue(
                            getContactData.SUBURB
                        );
                        this.contactForm.controls["STATE"].setValue(
                            getContactData.STATE
                        );
                        this.contactForm.controls["POSTCODE"].setValue(
                            getContactData.POSTCODE
                        );
                        this.contactForm.controls["COUNTRY"].setValue(
                            getContactData.COUNTRY
                        );
                        this.contactForm.controls["SAMEASSTREET"].setValue(
                            this.samesstreet
                        );
                        this.contactForm.controls["POSTALADDRESS1"].setValue(
                            getContactData.POSTALADDRESS1
                        );
                        this.contactForm.controls["POSTALADDRESS2"].setValue(
                            getContactData.POSTALADDRESS2
                        );
                        this.contactForm.controls["POSTALADDRESS3"].setValue(
                            getContactData.POSTALADDRESS3
                        );
                        this.contactForm.controls["POSTALSUBURB"].setValue(
                            getContactData.POSTALSUBURB
                        );
                        this.contactForm.controls["POSTALSTATE"].setValue(
                            getContactData.POSTALSTATE
                        );
                        this.contactForm.controls["POSTALPOSTCODE"].setValue(
                            getContactData.POSTALPOSTCODE
                        );
                        this.contactForm.controls["POSTALCOUNTRY"].setValue(
                            getContactData.POSTALCOUNTRY
                        );
                        this.contactForm.controls["DX"].setValue(
                            getContactData.DX
                        );
                        this.contactForm.controls["DXSUBURB"].setValue(
                            getContactData.DXSUBURB
                        );
                        //ph/web
                        this.contactForm.controls["PHONE"].setValue(
                            getContactData.PHONE
                        );
                        this.contactForm.controls["PHONE2"].setValue(
                            getContactData.PHONE2
                        );
                        this.contactForm.controls["FAX"].setValue(
                            getContactData.FAX
                        );
                        this.contactForm.controls["FAX2"].setValue(
                            getContactData.FAX2
                        );
                        this.contactForm.controls["MOBILE"].setValue(
                            getContactData.MOBILE
                        );
                        this.contactForm.controls["EMAIL"].setValue(
                            getContactData.EMAIL
                        );
                        this.contactForm.controls["EMAIL2"].setValue(
                            getContactData.EMAIL2
                        );
                        this.contactForm.controls[
                            "ELECTRONICSERVICEEMAIL"
                        ].setValue(getContactData.ELECTRONICSERVICEEMAIL);
                        this.contactForm.controls["WEBADDRESS"].setValue(
                            getContactData.WEBADDRESS
                        );
                        this.contactForm.controls["SKYPEUSERNAME"].setValue(
                            getContactData.SKYPEUSERNAME
                        );
                        //id
                        this.contactForm.controls["ASSOCIATIONNO"].setValue(
                            getContactData.ASSOCIATIONNO
                        );
                        this.contactForm.controls[
                            "HEALTHFUNDPROVIDER"
                        ].setValue(getContactData.HEALTHFUNDPROVIDER);
                        this.contactForm.controls["HEALTHFUNDNUMBER"].setValue(
                            getContactData.HEALTHFUNDNUMBER
                        );
                        this.contactForm.controls["MEDICARENUMBER"].setValue(
                            getContactData.MEDICARENUMBER
                        );
                        this.contactForm.controls[
                            "PRACTICINGCERTIFICATENO"
                        ].setValue(getContactData.PRACTICINGCERTIFICATENO);
                        this.contactForm.controls["ACN"].setValue(
                            getContactData.ACN
                        );
                        this.contactForm.controls["ABN"].setValue(
                            getContactData.ABN
                        );
                        this.contactForm.controls["TFN"].setValue(
                            getContactData.TFN
                        );
                        this.contactForm.controls["LICENCENO"].setValue(
                            getContactData.LICENCENO
                        );
                        this.contactForm.controls[
                            "NATIONALNAMESINDEX"
                        ].setValue(getContactData.NATIONALNAMESINDEX);
                        this.contactForm.controls[
                            "POWEROFATTORNEYBOOK"
                        ].setValue(getContactData.POWEROFATTORNEYBOOK);
                        this.contactForm.controls["POWEROFATTORNEYNO"].setValue(
                            getContactData.POWEROFATTORNEYNO
                        );
                        this.contactForm.controls["MASTERINDEXNO"].setValue(
                            getContactData.MASTERINDEXNO
                        );

                        this.contactForm.controls["BANKACCOUNTNAME"].setValue(
                            getContactData.BANKACCOUNTNAME
                        );

                        this.contactForm.controls["BANKADDRESS"].setValue(
                            getContactData.BANKADDRESS
                        );

                        this.contactForm.controls["BANKBSB"].setValue(
                            getContactData.BANKBSB
                        );

                        this.contactForm.controls["BANKACCOUNTNUMBER"].setValue(
                            getContactData.BANKACCOUNTNUMBER
                        );

                        this.contactForm.controls["FOREIGNEFTSWIFTCODE"].setValue(
                            getContactData.FOREIGNEFTSWIFTCODE
                        );

                        this.contactForm.controls["FOREIGNEFTIBAN"].setValue(
                            getContactData.FOREIGNEFTIBAN
                        );

                        this.contactForm.controls["FOREIGNEFTBANKNAME"].setValue(
                            getContactData.FOREIGNEFTBANKNAME
                        );

                        this.contactForm.controls["FOREIGNEFTCOUNTRY"].setValue(
                            getContactData.FOREIGNEFTCOUNTRY
                        );

                        this.contactForm.controls["FOREIGNEFTCURRENCY"].setValue(
                            getContactData.FOREIGNEFTCURRENCY
                        );


                        if (
                            getContactData &&
                            getContactData.CRIMINALRECORDDATE
                        ) {
                            let DUEDATE1 =
                                getContactData.CRIMINALRECORDDATE.split("/");
                            this.contactForm.controls[
                                "CRIMINALRECORDDATE"
                            ].setValue(
                                new Date(
                                    DUEDATE1[1] +
                                        "/" +
                                        DUEDATE1[0] +
                                        "/" +
                                        DUEDATE1[2]
                                )
                            );
                            this.contactForm.controls["CRIMINALdate"].setValue(
                                getContactData.CRIMINALRECORDDATE
                            );
                        }
                        this.contactForm.controls["LICENCECOUNTRY"].setValue(
                            getContactData.LICENCECOUNTRY
                        );
                        this.contactForm.controls[
                            "NATIONALIDENTITYNO"
                        ].setValue(getContactData.NATIONALIDENTITYNO);
                        this.contactForm.controls[
                            "NATIONALIDENTITYCOUNTRY"
                        ].setValue(getContactData.NATIONALIDENTITYCOUNTRY);
                        this.contactForm.controls[
                            "FAMILYCOURTLAWYERNO"
                        ].setValue(getContactData.FAMILYCOURTLAWYERNO);
                        this.contactForm.controls["NOTES"].setValue(
                            getContactData.NOTES
                        );
                        this.contactForm.controls[
                            "SOPHISTICATEDCLIENT"
                        ].setValue(
                            getContactData.SOPHISTICATEDCLIENT == 0
                                ? false
                                : true
                        );
                        this.contactForm.controls[
                            "LICENCESCANLOCATION"
                        ].setValue(getContactData.LICENCESCANLOCATION);
                        this.contactForm.controls["LICENCEFILEDATA"].setValue(
                            getContactData.LICENCEFILEDATA
                        );
                        this.contactForm.controls[
                            "NATIDENTSCANLOCATION"
                        ].setValue(getContactData.NATIDENTSCANLOCATION);
                        this.contactForm.controls["NATIDENTFILEDATA"].setValue(
                            getContactData.NATIDENTFILEDATA
                        );

                        //rates
                        this.contactForm.controls["DEFAULTCURRENCYID"].setValue(
                            getContactData.DEFAULTCURRENCYID
                        );
                        this.contactForm.controls["GSTTYPE"].setValue(
                            getContactData.GSTTYPE
                        );
                        this.isLoadingResults = false;
                    } else {
                        this.isLoadingResults = false;

                        this.toastr.error("Can't Find Contact Details");
                        this.dialogRef.close(false);
                    }
                }
            });
    }

    /**
     * This function is used to chenge or control data value
     * @param formControl -ormcontrol data
     */
    changeForControl(formControl) {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = key.toLocaleUpperCase();

            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj };
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 };
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }

    /**
     * This function is used to split the name
     */
    // splitname() {
    //     this.isLoadingResults = true;
    //     // this._mainAPiServiceService.getSetData({ CONTACTNAME: this.f.CONTACTNAME.value }, 'GetNameElements').subscribe(response => {
    //     this.sub1 = this._mainAPiServiceService
    //         .getSetData(
    //             {
    //                 Action: "GetNameElements",
    //                 Data: { CONTACTNAME: this.f.CONTACTNAME.value },
    //             },
    //             "utility"
    //         )
    //         .subscribe((response) => {
    //             if (response.CODE == 200 && response.STATUS == "success") {
    //                 this.contactForm.patchValue({
    //                     FAMILYNAME: (response.DATA.FAMILYNAME && response.DATA.FAMILYNAME !=undefined && response.DATA.FAMILYNAME !=null) ? response.DATA.FAMILYNAME : '',
    //                     FIRSTNAME: (response.DATA.FIRSTNAME && response.DATA.FIRSTNAME !=undefined && response.DATA.FIRSTNAME !=null) ? response.DATA.FIRSTNAME : '',
    //                     GIVENNAMES: (response.DATA.FIRSTNAME && response.DATA.FIRSTNAME !=undefined && response.DATA.FIRSTNAME !=null) ? response.DATA.FIRSTNAME : '',
    //                     LETTERS: (response.DATA.LETTERS && response.DATA.LETTERS !=undefined && response.DATA.LETTERS !=null) ? response.DATA.LETTERS : '',
    //                     MIDDLENAMES: (response.DATA.MIDDLENAMES && response.DATA.MIDDLENAMES !=undefined  && response.DATA.MIDDLENAMES !=null) ? response.DATA.MIDDLENAMES : '',
    //                     TITLE: (response.DATA.TITLE && response.DATA.TITLE !=undefined && response.DATA.TITLE !=null) ? response.DATA.TITLE : '',
    //                     NAMETITLE: (response.DATA.TITLE && response.DATA.TITLE !=undefined && response.DATA.TITLE !=null) ? response.DATA.TITLE : '',
    //                 });
    //                 if (this.action != "edit") {
    //                     this.contactForm.patchValue({
    //                         SALUTATION: response.DATA.SALUTATION,
    //                     });
    //                 }
    //             }
    //             this.isLoadingResults = false;
    //         });
    // }

    splitname(textValue) {
        if((!this.contactForm.controls["OTHERGIVENNAMES"].value && !this.contactForm.controls["OTHERFAMILYNAME"].value) && (this.contactForm.controls['FAMILYNAME'].value == '' || this.contactForm.controls['GIVENNAMES'].value == '')) {
            this.isLoadingResults = true;
            // this._mainAPiServiceService.getSetData({ CONTACTNAME: this.f.CONTACTNAME.value }, 'GetNameElements').subscribe(response => {
            this.sub1 = this._mainAPiServiceService
            .getSetData(
                {
                    Action: "GetNameElements",
                    Data: { CONTACTNAME: textValue }
                },
                "utility"
            )
            .subscribe((response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.contactForm.patchValue({
                        FAMILYNAME: (response.DATA.FAMILYNAME && response.DATA.FAMILYNAME !=undefined && response.DATA.FAMILYNAME !=null) ? response.DATA.FAMILYNAME : '',
                        GIVENNAMES: (response.DATA.FIRSTNAME && response.DATA.FIRSTNAME !=undefined && response.DATA.FIRSTNAME !=null) ? response.DATA.FIRSTNAME : '',
                        NAMELETTERS: (response.DATA.LETTERS && response.DATA.LETTERS !=undefined && response.DATA.LETTERS !=null) ? response.DATA.LETTERS : '',
                        MIDDLENAMES: (response.DATA.MIDDLENAMES && response.DATA.MIDDLENAMES !=undefined  && response.DATA.MIDDLENAMES !=null) ? response.DATA.MIDDLENAMES : '',
                        TITLE: (response.DATA.TITLE && response.DATA.TITLE !=undefined && response.DATA.TITLE !=null) ? response.DATA.TITLE : '',
                        NAMETITLE: (response.DATA.TITLE && response.DATA.TITLE !=undefined && response.DATA.TITLE !=null) ? response.DATA.TITLE : '',
                    });
                    // FIRSTNAME: (response.DATA.FIRSTNAME && response.DATA.FIRSTNAME !=undefined && response.DATA.FIRSTNAME !=null) ? response.DATA.FIRSTNAME : '',
                    // this.contactForm.patchValue({
                    // });
                    if (this.action != "edit") {
                        this.contactForm.patchValue({
                            SALUTATION: response.DATA.SALUTATION,
                        });
                    }
                }
                this.isLoadingResults = false;
            });
        }
    }

    /**
     * This function is used to change the Type 
     * @param val 
     */
    changeType(val) {
        if (val == "Multi Person") {
            this.selectedtabIndex = 0;
        }
    }

    /**
     * This unction is used to chenge the selected index data
     * @param val -val data
     */
    selectedIndexChange(val) {
        // this.selectedtabIndex =val;
    }
    // convenience getter for easy access to form fields
    get f() {
        return this.contactForm.controls;
    }
    /**
     * This Function is used to open the Dialog
     * @returns it's returns the form data
     */
    ondialogSaveClick(): void {
        this.GetSetMixRetry = false;
        this.isspiner = true;
        if (
            this.f.NUMBEROFDEPENDANTS.value < 0 ||
            this.f.NUMBEROFDEPENDANTS.value > 99
        ) {
            this.toastr.error(
                "Number Of Dependants filed not between 0 to  99"
            );
            this.isspiner = false;
            return;
        }
        this.FormAction = this.action !== "edit" ? "insert" : "update";
        // for edit contactGuid
        this.contactguid =
            this.action !== "edit"
                ? ""
                : localStorage.getItem("getContact_edit");
        //for checkbox
        this.postsameasstreet = this.f.SAMEASSTREET.value == true ? "1" : "0";
        let detailsdata = {
           // CONTACTGUID: this.contactguid,
            CONTACTGUID:this.f.CONTACTGUID.value,
            CONTACTNAME: this.f.CONTACTNAME.value,
            CONTACTTYPE: this.f.CONTACTTYPE.value,
            CONTACTCATEGORY: this.f.CONTACTCATEGORY.value,
            CONTACTID: this.f.CONTACTID.value,
            ACTIVE: this.f.ACTIVE.value == true ? "Active" : "Inactive",
            //person
            USEPARENTADDRESS: this.f.USEPARENTADDRESS.value == true ? "1" : "0",
            COMPANYCONTACTGUID: this.f.COMPANYCONTACTGUID.value,
            TITLE: this.f.TITLE.value,
            SALUTATION: this.f.SALUTATION.value,
            GIVENNAMES: this.f.GIVENNAMES.value,
            NAMETITLE: this.f.NAMETITLE.value,
            MIDDLENAMES: this.f.MIDDLENAMES.value,
            NAMELETTERS: this.f.NAMELETTERS.value,
            FAMILYNAME: this.f.FAMILYNAME.value,
            KNOWNBYOTHERNAME: this.f.KNOWNBYOTHERNAME.value == true ? "1" : "0",
            OTHERFAMILYNAME: this.f.OTHERFAMILYNAME.value,
            OTHERGIVENNAMES: this.f.OTHERGIVENNAMES.value,
            REASONFORCHANGE: this.f.REASONFORCHANGE.value,
            COMPANYNAME: this.f.COMPANYCONTACTGUIDTEXT.value,
            OCCUPATION: this.f.OCCUPATION.value,

            //Company
            POSITION: this.f.POSITION.value,
            //others
            GENDER: this.f.GENDER.value,
            DATEOFBIRTH: this.f.DATEOFBIRTHTEXT.value,
            MARITALSTATUS: this.f.MARITALSTATUS.value,
            SPOUSE: this.f.SPOUSE.value,
            NUMBEROFDEPENDANTS: this.f.NUMBEROFDEPENDANTS.value,
            BIRTHDAYREMINDER: this.f.BIRTHDAYREMINDER.value == true ? "1" : "0",
            TOWNOFBIRTH: this.f.TOWNOFBIRTH.value,
            COUNTRYOFBIRTH: this.f.COUNTRYOFBIRTH.value,
            DATEOFDEATH: this.f.DATEOFDEATHTEXT.value,
            CAUSEOFDEATH: this.f.CAUSEOFDEATH.value,
            DATEOFDEATHRANGE: this.f.DATEOFDEATHRANGETEXT.value,
            PLACEOFDEATH: this.f.PLACEOFDEATH.value,

            //address
            ADDRESS1: this.f.ADDRESS1.value,
            ADDRESS2: this.f.ADDRESS2.value,
            ADDRESS3: this.f.ADDRESS3.value,
            SUBURB: this.f.SUBURB.value,
            STATE: this.f.STATE.value,
            POSTCODE: this.f.POSTCODE.value,
            COUNTRY: this.f.COUNTRY.value,
            SAMEASSTREET: this.postsameasstreet,
            POSTALADDRESS1: this.f.POSTALADDRESS1.value,
            POSTALADDRESS2: this.f.POSTALADDRESS2.value,
            POSTALADDRESS3: this.f.POSTALADDRESS3.value,
            POSTALSUBURB: this.f.POSTALSUBURB.value,
            POSTALSTATE: this.f.POSTALSTATE.value,
            POSTALPOSTCODE: this.f.POSTALPOSTCODE.value,
            POSTALCOUNTRY: this.f.POSTALCOUNTRY.value,
            DX: this.f.DX.value,
            DXSUBURB: this.f.DXSUBURB.value,

            //ph/web
            PHONE: this.f.PHONE.value,
            PHONE2: this.f.PHONE2.value,
            FAX: this.f.FAX.value,
            FAX2: this.f.FAX2.value,
            MOBILE: this.f.MOBILE.value,
            EMAIL: this.f.EMAIL.value,
            EMAIL2: this.f.EMAIL2.value,
            ELECTRONICSERVICEEMAIL: this.f.ELECTRONICSERVICEEMAIL.value,
            WEBADDRESS: this.f.WEBADDRESS.value,
            SKYPEUSERNAME: this.f.SKYPEUSERNAME.value,

            //id
            ASSOCIATIONNO: this.f.ASSOCIATIONNO.value,
            HEALTHFUNDPROVIDER: this.f.HEALTHFUNDPROVIDER.value,
            HEALTHFUNDNUMBER: this.f.HEALTHFUNDNUMBER.value,
            MEDICARENUMBER: this.f.MEDICARENUMBER.value,
            PRACTICINGCERTIFICATENO: this.f.PRACTICINGCERTIFICATENO.value,
            ACN: this.f.ACN.value,
            ABN: this.f.ABN.value,
            TFN: this.f.TFN.value,
            LICENCENO: this.f.LICENCENO.value,
            NATIONALNAMESINDEX: this.f.NATIONALNAMESINDEX.value,
            POWEROFATTORNEYBOOK: this.f.POWEROFATTORNEYBOOK.value,
            POWEROFATTORNEYNO: this.f.POWEROFATTORNEYNO.value,
            MASTERINDEXNO: this.f.MASTERINDEXNO.value,
            CRIMINALRECORDDATE: this.f.CRIMINALdate.value,
            LICENCECOUNTRY: this.f.LICENCECOUNTRY.value,
            NATIONALIDENTITYNO: this.f.NATIONALIDENTITYNO.value,
            NATIONALIDENTITYCOUNTRY: this.f.NATIONALIDENTITYCOUNTRY.value,
            FAMILYCOURTLAWYERNO: this.f.FAMILYCOURTLAWYERNO.value,
            NOTES: this.f.NOTES.value,
            SOPHISTICATEDCLIENT:
                this.f.SOPHISTICATEDCLIENT.value == true ? "1" : "0",
            LICENCESCANLOCATION: this.f.LICENCESCANLOCATION.value,
            LICENCEFILEDATA: this.f.LICENCEFILEDATA.value,
            NATIDENTSCANLOCATION: this.f.NATIDENTSCANLOCATION.value,
            NATIDENTFILEDATA: this.f.NATIDENTFILEDATA.value,
            //rates
            DEFAULTCURRENCYID: this.f.DEFAULTCURRENCYID.value,
            GSTTYPE: this.f.GSTTYPE.value,

            // Bank Details

            BANKACCOUNTNAME:this.f.BANKACCOUNTNAME.value,
            BANKADDRESS:this.f.BANKADDRESS.value,
            BANKBSB:this.f.BANKBSB.value,
            BANKACCOUNTNUMBER:this.f.BANKACCOUNTNUMBER.value,
            FOREIGNEFTSWIFTCODE:this.f.FOREIGNEFTSWIFTCODE.value,
            FOREIGNEFTIBAN:this.f.FOREIGNEFTIBAN.value,
            FOREIGNEFTBANKNAME:this.f.FOREIGNEFTBANKNAME.value,
            FOREIGNEFTCOUNTRY:this.f.FOREIGNEFTCOUNTRY.value,
            FOREIGNEFTCURRENCY:this.f.FOREIGNEFTCURRENCY.value,
        };
        let details = {
            Action: this.FormAction,
            VALIDATEONLY: true,
            Data: detailsdata,
        };
        this._mainAPiServiceService
            .getSetData(details, "contact")
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            details
                        );
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            details
                        );
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            details
                        );
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                },
                (error) => {
                    this.isspiner = false;
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to check the validation data 
     * @param bodyData -bodydata value
     * @param details -details data value
     */
    async checkValidation(bodyData: any, details: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'NO') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'WARNING' || value.VALUEVALID == 'Warning') {
        //     warningData.push(value.ERRORDESCRIPTION);
        //     tempWarning[value.FIELDNAME] = value;
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        // this.errorWarningDataArray  = { Error: tempError, warning: tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.saveContectData(details);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.saveContectData(details);
        // }
        this.isspiner = false;
        await this.globalFunService
            .checkValidation(bodyData, details)
            .subscribe((data) => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.saveContectData(details);
                    }
                }
            });
    }

    /**
     * This function is used to save contect data value
     * @param data -data value
     */
    saveContectData(data: any):void {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService
            .getSetData(data, "contact")
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        if (this.action !== "edit") {
                            this.toastr.success("Contact save successfully");
                        } else {
                            this.toastr.success("Contact update successfully");
                        }
                        this.saveRateDetail(response.DATA.CONTACTGUID);
                        // this.saveContactDetail(response.DATA.CONTACTGUID);
                        this.behaviorService.setChildContactTableData({
                            CONTACTGUID: response.DATA.CONTACTGUID,
                            action: "setData",
                            contactType: this.f.CONTACTTYPE.value,
                        });
                        $("#refreshContactTab").click();
                        if (this._data && this._data.isReturn) {
                            let returnData: any = data.Data;
                            returnData.CONTACTGUID = response.DATA.CONTACTGUID;
                            this.dialogRef.close(returnData);
                        } else {
                            this.dialogRef.close({
                                status: "true",
                                data: data,
                            });
                        }
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.toastr.warning(response.MESSAGE);
                        this.isspiner = false;
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                        this.isspiner = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    /**
     * this function is used to get rate details Back
     * @param event -data event
     */
    rateDetailBack(event: any) {
        this.rateDetail.push(event);
    }

    /**
     * This function is used to back to contact detai
     * @param event 
     */
    backContactDetail(event: any) {
        this.contactDetail.push(event);
    }

    /**
     * This function is used to save the contact dialog
     * @param contactGuid -contact id
     */
    saveContactDetail(contactGuid: any) {
        this.contactDetail.forEach((result) => {
            let details: any = {
                Action: "update",
                VALIDATEONLY: false,
                Data: {
                    CONTACTGUID: result.CONTACTGUID,
                    COMPANYCONTACTGUID: contactGuid,
                },
            };
           this.sub2 = this._mainAPiServiceService
                .getSetData(details, "contact")
                .subscribe(
                    (response: { CODE: number; STATUS: string }) => {
                        if (
                            response.CODE == 200 &&
                            (response.STATUS == "OK" ||
                                response.STATUS == "success")
                        ) {
                        }
                    },
                    (error: any) => {
                        console.log(error);
                    }
                );
        });
    }

    /**
     * This function is used save retail details
     * @param contactGuid - contact guid
     */
    saveRateDetail(contactGuid: any) {
        this.rateDetail.forEach((value) => {
            let details: any = {};
            if (value.type == 1) {
                details = {
                    RATETYPE: value.RATETYPE,
                    LINKGUID: contactGuid,
                    USERID: value.USERID,
                    GSTTYPE: value.GSTTYPE,
                    RATEPERDAY_1: value.RATEPERDAY_1,
                    RATEPERDAY_2: value.RATEPERDAY_2,
                    RATEPERDAY_3: value.RATEPERDAY_3,
                    RATEPERDAY_4: value.RATEPERDAY_4,
                    RATEPERDAY_5: value.RATEPERDAY_5,
                    RATEPERDAY_6: value.RATEPERDAY_6,

                    RATEPERHOUR_1: value.RATEPERHOUR_1,
                    RATEPERHOUR_2: value.RATEPERHOUR_2,
                    RATEPERHOUR_3: value.RATEPERHOUR_3,
                    RATEPERHOUR_4: value.RATEPERHOUR_4,
                    RATEPERHOUR_5: value.RATEPERHOUR_5,
                    RATEPERHOUR_6: value.RATEPERHOUR_6,
                };
            } else {
                details = {
                    RATETYPE: value.RATETYPE,
                    LINKGUID: contactGuid,
                    GSTTYPE: value.GSTTYPE,
                    ACTIVITYGUID: value.ACTIVITYGUID,
                    RATEPERUNIT: value.RATEPERUNIT,
                };
            }
          this.sub3 = this._mainAPiServiceService
                .getSetData(
                    {
                        FORMACTION: "insert",
                        VALIDATEONLY: false,
                        DATA: details,
                    },
                    value.APIUrl
                )
                .subscribe(
                    (response: { CODE: number; STATUS: string }) => {
                        if (
                            response.CODE == 200 &&
                            (response.STATUS == "OK" ||
                                response.STATUS == "success")
                        ) {
                        }
                    },
                    (error: any) => {
                        console.log(error);
                    }
                );
        });
    }
    ngOnDestroy() {
        this.CONTACTNAMEValueChange?.unsubscribe();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub8?.unsubscribe();
        this.subscription5?.unsubscribe();
        // this.subscription.unsubscribe();
    }

    /**
     * This funcion is used to
     * @param event 
     */
    changContactId(event) {
        const value = event.target.value;
        if (!value) {
            this.contactForm.controls["CONTACTID"].setValue(this.contactIdTemp);
        }
    }
}

export class Common {
    public Id: Number;
    public Name: string;
}
