import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe, DecimalPipe } from '@angular/common';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { GenerateInvoiceComponent } from '../generate-invoice/generate-invoice.component';
import { MatterDialogComponentForTemplate } from '../../template/matter-dialog/matter-dialog.component';
import { DetailsComponent } from './details/details.component';
import { DiscountIncreaseComponent } from './discount-increase/discount-increase.component';
import { CheckForCreditsComponent } from '../check-for-credits/check-for-credits.component';
import * as $ from 'jquery';
import { TooltipService } from '@_services/tooltip.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription, takeUntil, tap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { Router } from '@angular/router';


@Component({
    selector: 'app-invoice-add-dailog',
    templateUrl: './invoice-add-dailog.component.html',
    styleUrls: ['./invoice-add-dailog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoiceAddDailogComponent implements OnInit, OnDestroy {
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    set_tier_permission: any = JSON.parse(localStorage.getItem("set_tier_permission"));
    InvoiceSelectedTamplate: any
    InvoiceTamplateList: any = []
    defaultLookupValue: any = ''
    RequestProtectedTrustAmount: boolean = false
    errorWarningData: any = {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    addInvoiceForm: FormGroup;
    documentData: any = {};
    isLoadingResults: boolean = false;
    isspiner: boolean = false;
    isFixPrice: any = true;
    isMin: any = true;
    isMax: any = true;
    ORIEXTOTAL: any = 0;
    baseGstPercentage: any = 0;
    ORIGSTTOTAL: any = 0;
    ORIINTOTAL: any = 0;
    OVEEXTOTAL: any = 0.00;
    OVEGSTTOTAL: any = 0;
    OVEINTOTAL: any = 0;
    DISEXAMOUNT: any = 0;
    DISGSTAMOUNT: any = 0;
    DISUINAMOUNT: any = 0;
    WORKITEMS: any;
    showMainPopUp: string = "Yes";
    showMainPopUpTemp: string = "Yes";
    invoiceType: any;
    invoiceTitle: any = 'Prepare Invoice';
    APIcallType: any = '';
    isDisable: boolean = false;
    TotalTitle: any;
    activeTab: any = '0';
    currencyChangeVal: string;
    matterDetail: any = {};
    dueDateDay: any = 0;
    InvoiceAs;
    windowNameId: any;
    tootipData: any = {}
    allItemsTotalObj = {};
    testBool: boolean = false;
    toolTipList: any;
    @ViewChild(DetailsComponent, { static: false }) child: DetailsComponent;
    @ViewChild(DiscountIncreaseComponent, { static: false }) discountchild: DiscountIncreaseComponent;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;
    sub2: Subscription;
    constructor(
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<InvoiceAddDailogComponent>,
        public datepipe: DatePipe,
        public MatDialog: MatDialog,
        private toastr: ToastrService,
        public _matDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private behaviorService: BehaviorService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public tooltipService: TooltipService,
        private router:Router,
        public globalFunService: GlobalFunctionsService,
    ) {
        localStorage.setItem('istrackid', 'InvoiceAddDailogComponent');
        // this.invoiceType = _data.type;
        this.invoiceType = _data.type ? _data.type : _data;
        this.isDisable = this.invoiceType == "preview" ? true : false;
        this.TotalTitle = this.invoiceType == "preview" ? "Total" : "Override The Total";
        this.APIcallType = this.invoiceType == "preview" ? 'preview' : 'default';
        this.invoiceTitle = this.invoiceType == "preview" ? "Prepare Preview Invoice" : 'Prepare Invoice';
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        // this.behaviorService.MatterData$.subscribe(result => {
        //   this.matterDetail = result;
        // });
        this.behaviorService.appPermissionsSetting$
            .subscribe((result) => {
                if (result) {
                    this.appPermissions = result
                }
            })
      /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                        const materIDofTab = window.name.split("_");
                        const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    if (result) {
                        this.matterDetail = result;
                    } else {
                        this.matterDetail = JSON.parse(localStorage.getItem(windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(this.matterDetail));
                    }
                })
            )
            .subscribe();
        // this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {

        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //    // this.matterDetail = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));

        //     //Global matter selection.
        //     // if (this._data.matterIs) {
        //     //     this.matterDetail = this._data.matterIs;
        //     // };

        //     if (this.matterDetail) {
        //     } else {
        //         if (result) {
        //             this.matterDetail = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.matterDetail))
        //         }
        //     }
        // });


        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            let conditionEnable = true;
            if (localStorage.getItem('istrackid') == 'InvoiceAddDailogComponent' && this.invoiceType == 'preview' && result['click'] && (result['data']['matter'] || result['data']['workitem'])) {
                this.ngOnInit();
                this.child.ngOnInit();
            }

            if (localStorage.getItem('istrackid') == 'InvoiceAddDailogComponent' && conditionEnable && this.invoiceType != 'preview' && result['click'] && (result['data']['matter'] || result['data']['workitem'])) {
                this.ngOnInit();
                this.child.ngOnInit();
                this.discountchild.ngOnInit();
                conditionEnable = false;
            }

            if (localStorage.getItem('istrackid') == 'InvoiceAddDailogComponent' && conditionEnable && result['click'] && result['data']['utility']) {
                this.discountchild.calDiscountAPIfun();
            }
            if (localStorage.getItem('istrackid') == 'InvoiceAddDailogComponent' && result['click'] && result['data']['CalcForeignCurrency']) {
                this.currencyChange(this.currencyChangeVal);
            }
            if (localStorage.getItem('istrackid') == 'InvoiceAddDailogComponent' && this.invoiceType == 'preview' && result['click'] && result['data']['SetInvoice']) {
                this.defultInvoiceFunction(false);
            }
            if (localStorage.getItem('istrackid') == 'InvoiceAddDailogComponent' && this.invoiceType != 'preview' && result['click'] && result['data']['SetInvoice']) {
                this.SaveInvoice();
            }
        });

    }

 /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.isLoadingResults = true;
        this.addInvoiceForm = this._formBuilder.group({
            INVOICEDATETEXT: [],
            INVOICEDATE: [],
            DUEDATETEXT: [],
            DUEDATE: [],
            MATTERGUID: [this.matterDetail.MATTERGUID],
            MATTER: [this.matterDetail.SHORTNAME + ' : ' + this.matterDetail.CONTACTNAME + ' : ' + this.matterDetail.MATTER],
            INVOICECODE: [''],
            COMMENT: [''],
            ADDITIONALTEXT: [''],
            INVOICEDVALUEEXGST: [''],
            INVOICEDVALUEINCGST: [''],
            FIXEDRATEEXGST: [''],
            FIXEDRATEINCGST: [''],
            FIXEDRATEEXGSTTOTAL: [''],
            FIXEDRATEINCGSTTOTAL: [''],
            ESTIMATETOTOTALEXGST: [''],
            ESTIMATETOTOTALINCGST: [''],
            ESTIMATEFROMTOTALEXGST: [''],
            ESTIMATEFROMTOTALINCGST: [''],
            //discount
            APPLYTOFEES: [''],
            ORIEXTOTAL: [''],
            ORIGSTTOTAL: [''],
            ORIINTOTAL: [''],
            OVEEXTOTAL: ['',{ updateOn: 'blur' }],
            OVEGSTTOTAL: [''],
            OVEINTOTAL: ['',{ updateOn: 'blur' }],
            WIPEXTOTAL: [''],
            WIPINTOTAL: [''],
            ActivityEXTOTAL: [''],
            ActivityINTOTAL: [''],
            SundryEXTOTAL: [''],
            SundryINTOTAL: [''],
            Percentage: [''],
            amount: ['',{ updateOn: 'blur' }],
            Percentage_type: [''],
            GST_type: [''],
            Discount_type: [''],
            DISEXAMOUNT: [''],
            DISGSTAMOUNT: [''],
            DISUINAMOUNT: [''],
            PROTECTEDTRUSTAMOUNT: ['',{ updateOn: 'blur' }],
            TRUSTBALANCE: [''],
            Fees: [''],
            Sundries: [''],
            MatterExpenses: [''],
            currencyType: ['Default'],
            FOREIGNCURRENCYRATE: [''],
            FOREIGNCURRENCYAMOUNT: [''],
            FOREIGNCURRENCYGST: [''],
            FOREIGNCURRENCYINCGST: [''],
            FEEEARNERS: new FormArray([])
        });
        if (this.isDisable) {
            this.addInvoiceForm.controls['INVOICECODE'].setValue('Preview Invoice');
        }

        let Payload = {
            "Action": "GetData",
            "Filters": {
                MATTERGUID: this.matterDetail.MATTERGUID, GetAllFields: true
            }
        };

        if (!this.RequestProtectedTrustAmount) {
            this.addInvoiceForm.get('PROTECTEDTRUSTAMOUNT').disable();
            this.addInvoiceForm.get('TRUSTBALANCE').disable();
        }
        this._mainAPiServiceService.getSetData(Payload, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                const matterDate = response.DATA.RECORDS[0];
                this.behaviorService.MatterData(response.DATA.RECORDS[0]);
                const inValEx = matterDate?.SUMMARYTOTALS?.INVOICEDVALUEEXGST ? matterDate?.SUMMARYTOTALS?.INVOICEDVALUEEXGST : 0.00;
                const inValIN = matterDate?.SUMMARYTOTALS?.INVOICEDVALUEINCGST ? matterDate?.SUMMARYTOTALS?.INVOICEDVALUEINCGST : 0.00;
                this.addInvoiceForm.controls['APPLYTOFEES'].setValue(true);
                this.addInvoiceForm.controls['Sundries'].setValue(false);
                this.addInvoiceForm.controls['MatterExpenses'].setValue(false);
                // Total invoiced befor this invoice
                this.addInvoiceForm.controls['INVOICEDVALUEEXGST'].setValue(inValEx);
                this.addInvoiceForm.controls['INVOICEDVALUEINCGST'].setValue(inValIN);
                // Total invoiced including this invoice ?
                // this.addInvoiceForm.controls['FIXEDRATEEXGSTTOTAL'].setValue(Number(matterDate.CONVEYANCINGGROUP.TOTALDUE) + Number(matterDate.SUMMARYTOTALS.INVOICEDVALUEEXGST));
                // this.addInvoiceForm.controls['FIXEDRATEINCGSTTOTAL'].setValue(Number(matterDate.CONVEYANCINGGROUP.TOTALDUE) + Number(matterDate.SUMMARYTOTALS.INVOICEDVALUEINCGST));
                //fix
                this.isFixPrice = ((inValEx > matterDate?.BILLINGGROUP?.FIXEDRATEEXGST || matterDate?.BILLINGGROUP?.FIXEDRATEEXGST <= 0)
                    && (inValIN > matterDate?.BILLINGGROUP?.FIXEDRATEEXGST || matterDate?.BILLINGGROUP?.FIXEDRATEEXGST <= 0)) ? false : true;
                this.addInvoiceForm.controls['FIXEDRATEEXGST'].setValue(matterDate?.BILLINGGROUP?.FIXEDRATEEXGST);
                this.addInvoiceForm.controls['FIXEDRATEINCGST'].setValue(matterDate?.BILLINGGROUP?.FIXEDRATEINCGST);
                //Minimum
                const minestimateExVal = matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALEXGST ? matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALEXGST : 0.00;
                const minestimateIncVal = matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALINCGST ? matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALINCGST : 0.00;
                this.isMin = ((inValEx > matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALEXGST || matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALEXGST <= 0)
                    && (inValIN > matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALINCGST || matterDate?.SUMMARYTOTALS?.ESTIMATETOTOTALINCGST <= 0)) ? false : true;
                this.addInvoiceForm.controls['ESTIMATETOTOTALEXGST'].setValue(minestimateExVal);
                this.addInvoiceForm.controls['ESTIMATETOTOTALINCGST'].setValue(minestimateIncVal);
                //Maximum
                this.isMax = ((inValEx > matterDate?.SUMMARYTOTALS?.ESTIMATEFROMTOTALEXGST || matterDate?.SUMMARYTOTALS?.ESTIMATEFROMTOTALEXGST <= 0)
                    && (inValIN > matterDate?.SUMMARYTOTALS?.ESTIMATEFROMTOTALINCGST || matterDate?.SUMMARYTOTALS?.ESTIMATEFROMTOTALINCGST <= 0)) ? false : true;
                this.addInvoiceForm.controls['ESTIMATEFROMTOTALEXGST'].setValue(matterDate?.SUMMARYTOTALS?.ESTIMATEFROMTOTALEXGST);
                this.addInvoiceForm.controls['ESTIMATEFROMTOTALINCGST'].setValue(matterDate?.SUMMARYTOTALS?.ESTIMATEFROMTOTALINCGST);
                if (this.invoiceType != "preview")
                    this.defultInvoiceFunction(true);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, error => {
            this.toastr.error(error);
            this.isLoadingResults = false;
        });

        this.setTooltipData()
        this.loadInvoiceTemplate();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
    }

    /**
     * This function is used to set the Tooltip Data value
     */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['Invoice']) {
            this.toolTipList = TooltipData['Invoice'];
        } else {
            this.tooltipService.setToolTipData = ('Invoice');
        }
    };

    /**
     * This function is used to Request Protected Trust Amount Toggle data value
     */
    RequestProtectedTrustAmountToggle():void {
        this.RequestProtectedTrustAmount = !this.RequestProtectedTrustAmount;
        if (!this.RequestProtectedTrustAmount) {
            this.addInvoiceForm.get('PROTECTEDTRUSTAMOUNT').disable();

        } else {
            this.addInvoiceForm.get('PROTECTEDTRUSTAMOUNT').enable();
        }
    };

    /**
     * This function is used to Toggle for the Update value
     */
    ToggleForUpadte():void {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };

    /**
     * This function is used to change the currency value data
     * @param val -value data value
     */
    currencyChange(val):void {
        this.currencyChangeVal = val;
        if (val == 'Default') {
            this.activeTab = '0'
        } else {
            this.activeTab = '4'
        }
        let sendData = {
            "CURRENCYID": val,
            "INVOICEDATE": this.f.INVOICEDATE.value,
            "BUTSAYEXGST": this.OVEEXTOTAL,
            "BUTSAYGST": this.OVEGSTTOTAL
        }
        this._mainAPiServiceService.getSetData(sendData, 'CalcForeignCurrency').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.addInvoiceForm.controls['FOREIGNCURRENCYRATE'].setValue(response.DATA.FOREIGNCURRENCYRATE);
                this.addInvoiceForm.controls['FOREIGNCURRENCYAMOUNT'].setValue(response.DATA.FOREIGNCURRENCYAMOUNT);
                this.addInvoiceForm.controls['FOREIGNCURRENCYGST'].setValue(response.DATA.FOREIGNCURRENCYGST);
                this.addInvoiceForm.controls['FOREIGNCURRENCYINCGST'].setValue(response.DATA.FOREIGNCURRENCYINCGST);
            }
        }, error => {
            this.toastr.error(error);
        });

    }

    /**
     * This function is used to set the default invoice function data value
     * @param isFirst -isFirst data value
     */
    defultInvoiceFunction(isFirst: boolean) {

        this.isLoadingResults = true;
        const tempPost: any = {
            INVOICECODE: this.f.INVOICECODE.value,
            MATTERGUID: this.f.MATTERGUID.value,
            INVOICEDATE: this.f.INVOICEDATE.value,
            DUEDATE: this.f.DUEDATE.value,
            PRINTEDDATE: '',

            // INVOICETOTAL: this.f.OVEINTOTAL.value,
            INVOICETOTAL: this.f.OVEEXTOTAL.value,
            INVOICETOTALINCGST: this.f.OVEINTOTAL.value,
            GST: this.f.OVEGSTTOTAL.value,
            FOREIGNCURRENCYID: '',
            COMMENT: this.f.COMMENT.value,
            ADDITIONALTEXT: this.f.ADDITIONALTEXT.value,
            WORKITEMS: this.WORKITEMS,
            INVOICEFORMAT: this.defaultLookupValue
        };
        let PostInvoiceEntryData: any
        if (isFirst) {
            PostInvoiceEntryData = { FormAction: 'default', VALIDATEONLY: false, Data: {} };
        } else {
            PostInvoiceEntryData = { FormAction: this.APIcallType, VALIDATEONLY: false, Data: this.APIcallType != 'preview' ? {} : tempPost };
        }
        this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {

            if (response.CODE == 200 && response.STATUS == "success") {
                this.tootipData = response.DATA.FIELDTIPS;
                if (this.isDisable) {
                    if (response.DATA.DOCUMENTS && response.DATA.DOCUMENTS[0]) {
                        this.documentData = response.DATA.DOCUMENTS[0];

                        this.DownloadView(this.documentData);
                    } else {
                        this.toastr.error('Invoice not generated please try it again');
                        this.isLoadingResults = false;
                    }
                } else {
                    let temInvoice = response.DATA.DEFAULTVALUES.INVOICECODE;
                    this.addInvoiceForm.controls['INVOICECODE'].setValue(temInvoice.toString().padStart(8, "0"));
                    let temInvoiceData = response.DATA.DEFAULTVALUES;
                    let tInvoiceDate;
                    let tsueDate;
                    if (temInvoiceData.INVOICEDATE) {
                        let tinvoiceDate = temInvoiceData.INVOICEDATE.split("/");
                        tInvoiceDate = new Date(tinvoiceDate[1] + '/' + tinvoiceDate[0] + '/' + tinvoiceDate[2]);
                        this.addInvoiceForm.controls['INVOICEDATETEXT'].setValue(new Date(tinvoiceDate[1] + '/' + tinvoiceDate[0] + '/' + tinvoiceDate[2]));
                        this.addInvoiceForm.controls['INVOICEDATE'].setValue(temInvoiceData.INVOICEDATE);
                    }
                    if (temInvoiceData.DUEDATE) {
                        let tdueDate = temInvoiceData.DUEDATE.split("/");
                        tsueDate = new Date(tdueDate[1] + '/' + tdueDate[0] + '/' + tdueDate[2]);
                        this.addInvoiceForm.controls['DUEDATETEXT'].setValue(new Date(tdueDate[1] + '/' + tdueDate[0] + '/' + tdueDate[2]));
                        this.addInvoiceForm.controls['DUEDATE'].setValue(temInvoiceData.DUEDATE);
                    }
                    if (tInvoiceDate && tsueDate) {
                        let Difference_In_Time = tsueDate.getTime() - tInvoiceDate.getTime();
                        this.dueDateDay = Difference_In_Time / (1000 * 3600 * 24);
                    }
                }
            }
            this.isLoadingResults = false;
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to change the invoice date value
     */
    invoiceDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
        let futureDate = new Date(this.datepipe.transform(event.value, 'yyyy-MM-dd'));
        futureDate.setDate(futureDate.getDate() + this.dueDateDay);
        this.addInvoiceForm.controls['INVOICEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.addInvoiceForm.controls['DUEDATETEXT'].setValue(futureDate);
        this.addInvoiceForm.controls['DUEDATE'].setValue(this.datepipe.transform(futureDate, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to change the due date value
     */
    dueDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
        this.addInvoiceForm.controls['DUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * Thias function is used to change the discount Amount data value
     */
    changeDiscountAmount(event):void {

        this.addInvoiceForm.controls['DISEXAMOUNT'].setValue(event.DISCOUNTEXGST.toFixed(2));
        this.addInvoiceForm.controls['DISGSTAMOUNT'].setValue(event.DISCOUNTGST.toFixed(2));
        this.addInvoiceForm.controls['DISUINAMOUNT'].setValue(event.DISCOUNTINCGST.toFixed(2));

        const exfinalTotal = Number(this.ORIEXTOTAL - event.DISCOUNTEXGST);
        const GSTfinalTotal = Number(this.ORIGSTTOTAL - event.DISCOUNTGST);
        const infinalTotal = Number(this.ORIINTOTAL - event.DISCOUNTINCGST);

        this.addInvoiceForm.controls['OVEEXTOTAL'].setValue(exfinalTotal.toFixed(2));
        this.addInvoiceForm.controls['OVEGSTTOTAL'].setValue(GSTfinalTotal.toFixed(2));
        this.addInvoiceForm.controls['OVEINTOTAL'].setValue(infinalTotal.toFixed(2));

        this.addInvoiceForm.controls['FIXEDRATEEXGSTTOTAL'].setValue(Number(this.f.INVOICEDVALUEEXGST.value) + Number(this.OVEEXTOTAL));
        this.addInvoiceForm.controls['FIXEDRATEINCGSTTOTAL'].setValue(Number(this.f.INVOICEDVALUEINCGST.value) + Number(this.OVEINTOTAL));


    }

    /**
     * This function is used to change the TotalData out
     */
    changeTotalDataOut(event):void {
        let EXTOTAL: number = 0;
        let INTOTAL: number = 0;
        let TOTALGST: number = 0;
        let WIPEXTOTAL: number = 0;
        let WIPINTOTAL: number = 0;
        let ActivityEXTOTAL: number = 0;
        let ActivityINTOTAL: number = 0;
        let SundryEXTOTAL: number = 0;
        let SundryINTOTAL: number = 0;
        let ActivityGST: number = 0;
        let WORKITEMSData = [];

        this.allItemsTotalObj = {};

        for (let value of event) {
            if (!value.level) {

                // to calculate price and other thing according to FeeEarner

                if (!this.allItemsTotalObj[value.FEEEARNER]) {
                    this.allItemsTotalObj[value.FEEEARNER] = {}
                    this.allItemsTotalObj[value.FEEEARNER]["PRICE"] = 0;
                    this.allItemsTotalObj[value.FEEEARNER]["PRICEINCGST"] = 0;
                }

                if (value.FEEEARNER && this.allItemsTotalObj[value.FEEEARNER]) {
                    this.allItemsTotalObj[value.FEEEARNER]["PRICE"] += parseFloat(value.PRICE)
                    this.allItemsTotalObj[value.FEEEARNER]["PRICEINCGST"] += parseFloat(value.PRICEINCGST)
                }
                // ends here ~ to calculate price and other thing according to FeeEarner

                WORKITEMSData.push({ 'WORKITEMGUID': value.WORKITEMGUID });
                EXTOTAL += Number(value.PRICE);
                INTOTAL += Number(value.PRICEINCGST);
                TOTALGST += Number(value.GST);
                if (value.ITEMTYPEDESC == "WIP" || value.ITEMTYPEDESC == "Activity") {
                    ActivityEXTOTAL += Number(value.PRICE);
                    ActivityINTOTAL += Number(value.PRICEINCGST);
                    ActivityGST = ActivityINTOTAL - ActivityEXTOTAL;
                } else if (value.ITEMTYPEDESC == "Sundry" || value.ITEMTYPEDESC == "Search") {
                    SundryEXTOTAL += Number(value.PRICE);
                    SundryINTOTAL += Number(value.PRICEINCGST);
                } else if (value.ITEMTYPEDESC == "Matter Expense" || value.ITEMTYPEDESC == "Disbursement") {
                    WIPEXTOTAL += Number(value.PRICE);
                    WIPINTOTAL += Number(value.PRICEINCGST);
                }
            }
        }

        // event.forEach(function (value) {
        //   if (!value.level) {

        //     // to calculate price and other thing according to FeeEarner
        //     // if(value.FEEEARNER && this.allItemsTotalObj[value.FEEEARNER]) {
        //     //   this.allItemsTotalObj[value.FEEEARNER]["PRICE"] += value.PRICE
        //     //   this.allItemsTotalObj[value.FEEEARNER]["PRICEINCGST"] += value.PRICE
        //     // } else {
        //     //   this.allItemsTotalObj[value.FEEEARNER] = {}
        //     //   this.allItemsTotalObj[value.FEEEARNER]["PRICE"] = 0;
        //     //   this.allItemsTotalObj[value.FEEEARNER]["PRICEINCGST"] = 0;
        //     // }
        //     // ends here ~ to calculate price and other thing according to FeeEarner

        //     WORKITEMSData.push({ 'WORKITEMGUID': value.WORKITEMGUID });
        //     EXTOTAL += Number(value.PRICE);
        //     INTOTAL += Number(value.PRICEINCGST);
        //     TOTALGST += Number(value.GST);
        //     if (value.ITEMTYPEDESC == "WIP" || value.ITEMTYPEDESC == "Activity") {
        //       ActivityEXTOTAL += Number(value.PRICE);
        //       ActivityINTOTAL += Number(value.PRICEINCGST);
        //       ActivityGST = ActivityINTOTAL - ActivityEXTOTAL;
        //     } else if (value.ITEMTYPEDESC == "Sundry" || value.ITEMTYPEDESC == "Search") {
        //       SundryEXTOTAL += Number(value.PRICE);
        //       SundryINTOTAL += Number(value.PRICEINCGST);
        //     } else if (value.ITEMTYPEDESC == "Matter Expense" || value.ITEMTYPEDESC == "Disbursement") {
        //       WIPEXTOTAL += Number(value.PRICE);
        //       WIPINTOTAL += Number(value.PRICEINCGST);
        //     }
        //   }
        // });





        this.baseGstPercentage = 100 * TOTALGST / EXTOTAL;
        // let FInalGst = Number(globallyVal.INCGSTTOTAL) - Number(globallyVal.EXGSTTOTAL)
        this.WORKITEMS = WORKITEMSData;
        this.ORIEXTOTAL = EXTOTAL.toFixed(2);
        this.ORIGSTTOTAL = TOTALGST.toFixed(2);
        this.ORIINTOTAL = INTOTAL.toFixed(2);
        // this.ORIEXTOTAL = ActivityEXTOTAL.toFixed(2);
        // this.ORIGSTTOTAL = ActivityGST.toFixed(2);
        // this.ORIINTOTAL = ActivityINTOTAL.toFixed(2);
        this.OVEEXTOTAL = EXTOTAL.toFixed(2);
        this.OVEGSTTOTAL = TOTALGST.toFixed(2);
        this.OVEINTOTAL = INTOTAL.toFixed(2);
        this.addInvoiceForm.controls['WIPEXTOTAL'].setValue(WIPEXTOTAL.toFixed(2));
        this.addInvoiceForm.controls['WIPINTOTAL'].setValue(WIPINTOTAL.toFixed(2));
        this.addInvoiceForm.controls['ActivityEXTOTAL'].setValue(ActivityEXTOTAL.toFixed(2));
        this.addInvoiceForm.controls['ActivityINTOTAL'].setValue(ActivityINTOTAL.toFixed(2));
        this.addInvoiceForm.controls['SundryEXTOTAL'].setValue(SundryEXTOTAL.toFixed(2));
        this.addInvoiceForm.controls['SundryINTOTAL'].setValue(SundryINTOTAL.toFixed(2));
        this.addInvoiceForm.controls['FIXEDRATEEXGSTTOTAL'].setValue(Number(this.f.INVOICEDVALUEEXGST.value) + Number(this.OVEEXTOTAL));
        this.addInvoiceForm.controls['FIXEDRATEINCGSTTOTAL'].setValue(Number(this.f.INVOICEDVALUEINCGST.value) + Number(this.OVEINTOTAL));


        // this.changeDiscountAmount({ 'amount': this.f.amount.value, 'Percentage': this.f.Percentage.value, 'Percentage_type': this.f.Percentage_type.value, 'GST_type': this.f.GST_type.value, 'Discount_type': this.f.Discount_type.value });

        this.showMainPopUpTemp = (event.length >= 1 && INTOTAL >= 0) ? 'Yes' : 'No';

        this.OVEEXTOTAL = Number(this.ORIEXTOTAL - this.f.DISEXAMOUNT.value).toFixed(2);
        this.OVEGSTTOTAL = Number(this.ORIGSTTOTAL - this.f.DISGSTAMOUNT.value).toFixed(2);
        this.OVEINTOTAL = Number(this.ORIINTOTAL - this.f.DISUINAMOUNT.value).toFixed(2);
    }

    /**
     * This function is used to change the amount value
     */
    amountChange(element, field):void{
        const numbers = this.addInvoiceForm.controls[field].value;
        if(numbers) {
          const numericValue = parseFloat(numbers.replace(/,/g, '')); // Remove commas and convert to a number
          const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
          element.target.value = formattedNumber;
        }
    }

    /**
     * This function is used to calculate the over ride total value
     * @param type -type value
     */
    calculateOverrideTotal(type: any) {
        const orignalextotal = this.f.ORIEXTOTAL.value;
        const orignalgsttotal = this.f.ORIGSTTOTAL.value;
        const orignalintotal = this.f.ORIINTOTAL.value;
        if (type == "ex") {
            const amountVal = this.f.OVEEXTOTAL.value;
            const gstAmount = amountVal * this.baseGstPercentage / 100;
            this.OVEGSTTOTAL = (Number(gstAmount)).toFixed(2);
            const oveinamount = Number(amountVal) + Number(this.OVEGSTTOTAL);
            this.OVEINTOTAL = oveinamount.toFixed(2);
            this.OVEEXTOTAL = Number(parseFloat(amountVal).toFixed(2));
            /// discount Calculation  Dis=orignal total - overried total
            this.DISEXAMOUNT = (Number(orignalextotal) - Number(this.OVEEXTOTAL)).toFixed(2);
            this.DISGSTAMOUNT = (Number(orignalgsttotal) - Number(this.OVEGSTTOTAL)).toFixed(2);
            this.DISUINAMOUNT = (Number(orignalintotal) - Number(this.OVEINTOTAL)).toFixed(2);

        } else if (type == "in") {
            const inTotal = this.f.OVEINTOTAL.value;
            const amoutVal = inTotal / (this.baseGstPercentage / 100 + 1);
            const gstVal = inTotal - amoutVal;
            this.OVEGSTTOTAL = (Number(gstVal)).toFixed(2);
            this.OVEEXTOTAL = Number(amoutVal).toFixed(2);
            this.OVEINTOTAL = Number(parseFloat(inTotal).toFixed(2));
            /// discount Calculation  Dis=orignal total - overried total

            this.DISEXAMOUNT = (Number(orignalextotal) - Number(this.OVEEXTOTAL)).toFixed(2);
            this.DISGSTAMOUNT = (Number(orignalgsttotal) - Number(this.OVEGSTTOTAL)).toFixed(2);
            this.DISUINAMOUNT = (Number(orignalintotal) - Number(this.OVEINTOTAL)).toFixed(2);
        }




    }

    /**
     * This function is used to select the Due date
     * @param type -type of the date
     * @param event -event value
     */
    selectDueDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.addInvoiceForm.controls['DUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to Select the Invoice Date
     * @param type -type of the Invoice Date
     * @param event -event data value
     */
    SelectInvoiceDate(type: string, event: MatDatepickerInputEvent<Date>):void {
        this.addInvoiceForm.controls['INVOICEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    get f() {
        return this.addInvoiceForm.controls;
    }

    /**
     * This function is used to Get theAccount data value
     */
    GetAccountData():void {
        let data = {
            MatterGuid: this.matterDetail.MATTERGUID,
            UseTrust: true,
            ACCOUNTCLASS: "Matter Ledger"
        }
       this.sub1 = this._mainAPiServiceService.getSetData(data, "GetAccount").subscribe(
            (response) => {
                if (response.CODE == 200 && response.STATUS == "success") {

                } else if (response.MESSAGE == "Not logged in") {
                }
            },
            (err) => {
                this.isLoadingResults = false;
            }
        );
    }

    /**
     * This function is used to disabled Request Protected Trust Amount
     */
    DisabledRequestProtectedTrustAmount(): void {
        if (!this.RequestProtectedTrustAmount) {
            this.addInvoiceForm.get('PROTECTEDTRUSTAMOUNT').disable();
            this.addInvoiceForm.get('TRUSTBALANCE').disable();
        }
    }

    /**
     * This function is used to Save the invoice data value
     */
    SaveInvoice():void {
        this.showMainPopUp = this.showMainPopUpTemp;
        if (this.showMainPopUp == 'Yes') {
            this.isspiner = true;
            this.isLoadingResults = true;
            const PostData: any = {
                // "INVOICEGUID": this.f.ADDITIONALTEXT.value,
                INVOICECODE: this.f.INVOICECODE.value,
                MATTERGUID: this.f.MATTERGUID.value,
                INVOICEDATE: this.f.INVOICEDATE.value,
                DUEDATE: this.f.DUEDATE.value,
                PRINTEDDATE: '',
                //INVOICETOTAL: this.f.OVEINTOTAL.value,
                INVOICETOTAL: this.f.OVEEXTOTAL.value,
                INVOICETOTALINCGST: this.f.OVEINTOTAL.value,
                GST: this.f.OVEGSTTOTAL.value,
                FOREIGNCURRENCYID: '',
                COMMENT: this.f.COMMENT.value,
                ADDITIONALTEXT: this.f.ADDITIONALTEXT.value,
                WORKITEMS: this.WORKITEMS,
                // PROTECTEDTRUSTAMOUNT: this.f.PROTECTEDTRUSTAMOUNT.value
            };
            let PostInvoiceEntryData: any = { FormAction: 'insert', VALIDATEONLY: true, Data: PostData };

            this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(res => {

                PostInvoiceEntryData = { FormAction: 'insert', VALIDATEONLY: true, Data: PostData };
                if (res.CODE == 200 && res.STATUS == 'success') {
                    this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData);
                } else if (res.CODE == 451 && res.STATUS == 'warning') {
                    this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData);
                    this.isLoadingResults = false;
                    this.isspiner = false;
                } else if (res.CODE == 450 && res.STATUS == 'error') {
                    this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData);
                    this.isLoadingResults = false;
                    this.isspiner = false;
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                    this.isspiner = false;
                }
            }, err => {
                this.isspiner = false;
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }

    /**
     * This function is used to check the validation
     */
    async checkValidation(bodyData: any, PostInvoiceEntryData: any) {
        await this.globalFunService.checkValidation(bodyData, PostInvoiceEntryData)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    // this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.saveInvoice(PostInvoiceEntryData);
                    } else {
                        this.isLoadingResults = false;
                        this.isspiner = false;
                    }
                }
            });
    }

    /**
     * This function is used to save the Invoice
     */
    saveInvoice(PostInvoiceEntryData: any):void {

        PostInvoiceEntryData.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
               // $('#refreshInvoiceTab').click()
                $('#Legal_Details').click();

                // this.toastr.success('Save Success');
                this.behaviorService.matterInvoiceData({ INVOICEGUID: res.DATA.INVOICEGUID });
                let CreditinvoiceData = PostInvoiceEntryData.Data;
                CreditinvoiceData.INVOICEGUID = res.DATA.INVOICEGUID;
                if(this.currentUser?.OPENINNEWTAB && (this.router.url == "/matter-details/work-in-progress" || this.router.url == "/matters" || this.router.url == "/matter-details/matter-invoices")) {
                    localStorage.setItem("'INVOICEGUID_"+PostInvoiceEntryData?.Data?.MATTERGUID,res.DATA.INVOICEGUID);
                }
                this.getInvoiceInformation(res.DATA.INVOICEGUID);
                localStorage.setItem('Create_INVOICEGUID_WorkInProgress', CreditinvoiceData.INVOICEGUID)
                CreditinvoiceData.AMOUNTOUTSTANDINGINCGST = PostInvoiceEntryData.Data.INVOICETOTALINCGST;
                this.dialogRef.close(true);
                this.checkForCredits(CreditinvoiceData);
            } else {
                if (res.CODE == 402 && res.STATUS == 'error' && res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
                this.isspiner = false;
            };

            this.isLoadingResults = false;
            this.isspiner = false;
        }, err => {
            this.isspiner = false;
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to get the Invoice Infromation
     * @param id -invoice information id
     */
    getInvoiceInformation(id):void {
       this._mainAPiServiceService.getSetData({ "Action": "GetData", Filters: { 'INVOICEGUID': id } }, 'invoice').subscribe(response => {
            if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
                let invoiceData = response.DATA.RECORDS[0];
                setTimeout(() => {
                    this.behaviorService.matterInvoiceData(invoiceData);
                }, 2000);
            } else if (response.MESSAGE == 'Not logged in') {
            }
        }, error => {
            this.toastr.error(error);
        });


    }

    /**
     * This function is used to Download the view
     * @param documentData -document data value
     */
    DownloadView(documentData):void {
        const dialogRef = this._matDialog.open(MatterDialogComponentForTemplate, {
            width: '100%', disableClose: true, data: { type: 'preview', document: documentData }
        });
        this.isLoadingResults = false;
    }

    /**
     * This function is used to check for credits
     * @param invoiceData -invoiceData
     */
    checkForCredits(invoiceData):void {
        this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { 'MATTERGUID': this.matterDetail.MATTERGUID, CREDITSONLY: true, RECEIPTSANDCREDITS: true } }, 'income').subscribe(res => {
            if ((res.CODE == 200 || res.CODE == '200') && res.STATUS == "success") {
                if (res.DATA.RECORDS[0]) {
                    const dialogRef = this._matDialog.open(CheckForCreditsComponent, { width: '100%', disableClose: true, data: { 'type': 'view', MatterData: this.matterDetail, InvoiceData: invoiceData } });
                    dialogRef.afterClosed().subscribe(result => {
                        this.GenerateInvoicepopup();
                    });
                } else {
                    this.GenerateInvoicepopup()
                }
            } else if ((res.CODE == 406 || res.CODE == '406') && res.STATUS == "error") {
                // if (res.DATA.RECORDS[0]) {
                    // const dialogRef = this._matDialog.open(CheckForCreditsComponent, { width: '100%', disableClose: true, data: { 'type': 'view', MatterData: this.matterDetail, InvoiceData: invoiceData } });
                    // dialogRef.afterClosed().subscribe(result => {
                    //     // this.GenerateInvoicepopup();
                    // });
                    this.GenerateInvoicepopup();
                // } else {
                //     this.GenerateInvoicepopup()
                // }
            }
        }, err => {
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to generate the invoice popup
     */
    GenerateInvoicepopup():void {
        const dialogRef = this._matDialog.open(GenerateInvoiceComponent, {
            width: '100%',
            disableClose: true,
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            $('#refreshInvoiceTab').click()
         });
    }

    /**
     * This function is used to select the Template
     * @param event -data value
     */
    selectTamplate(event: any) {
        this.InvoiceSelectedTamplate = event.value;
    }

    /**
     * This function is used to load the Invoice template
     */
    loadInvoiceTemplate():void {
        this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { LookupType: "invoice formats" } }, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.InvoiceTamplateList = res.DATA.LOOKUPS
                setTimeout(() => {
                    this.defaultLookupValue = this.InvoiceTamplateList[0].LOOKUPGUID
                }, 1000);

            };
        });
    }

}

// check if object is empty or not
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
