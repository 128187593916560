import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatterDialogComponent } from '../matter-dialog/matter-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { BehaviorService } from '@_services/Behavior.service';
import { TimersService } from '@_services/timers.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BulkProgressComponent } from '@pages/template/bulk-progress/bulk-progress.component';
@Component({
  selector: 'app-move-entry',
  templateUrl: './move-entry.component.html',
  styleUrls: ['./move-entry.component.scss']
})
export class MoveEntryComponent implements OnInit {
  WIPData: any;
  PostData: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  dialogRef: MatDialogRef<BulkProgressComponent>;

  constructor(private behaviorService: BehaviorService, public MatDialog: MatDialog,
    private Timersservice: TimersService, private toastr: ToastrService,private router:Router) {
    localStorage.setItem('istrackid', 'MoveEntryComponent');
    this.behaviorService.workInProgress$.subscribe((result) => {
      if (result) {
        this.WIPData = result;
      }
    });
    this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'MoveEntryComponent' &&  result['click'] &&  result['data']['workitem']) {
        this.ReSendData();
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  /**
   * This function is used to Move the Entry data 
   */
  MoveEntry() {
    let selecteddata;
    if(this.router.url == '/invoice/bulk-invoices'){
      this.behaviorService.moveDataEntry$.subscribe(data=>{
        if(data !=null){
          console.log('1', typeof data)
          selecteddata= [data];
      }
      })
  const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
  dialogRef.afterClosed().subscribe(result => {
    // if(result){

    // }
    if (result) {
        this.openBulkProgressbar();
        localStorage.setItem('istrackid', 'MoveEntryComponent');
        let payload = [];
        this.behaviorService.refreshTaggedList.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
          if(data && data.length >0){
            selecteddata=data;
          }
        })
        selecteddata.forEach(element => {
          payload.push({
            "WorkItemGuid": element.WORKITEMGUID,
            "MatterGuid": result.MATTERGUID
          })
        });


      let PostTimeEntryData: any = { FormAction: 'update', "MultiRecord": true, VALIDATEONLY: false, Data: payload };
      this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          localStorage.setItem('Spellcheck','false');
          $('#refreshWorkInprogress').click();
          $('#refreshTimeEntryTab').click();
          $("#refreshreciptsDAta").click();
          $("#refreshtabledetails").click();
          this.behaviorService.checktogglestatus$.next(false);
          this.toastr.success('WIP moved successfully');
          this.dialogRef.close(true);
        } else if (res.CODE == 451 && res.STATUS == 'warning') {
          this.toastr.warning(res.MESSAGE);
        } else if (res.CODE == 450 && res.STATUS == 'error') {
          this.toastr.warning(res.MESSAGE);
        } else if (res.MESSAGE == 'Not logged in') {
          // this.dialogRef.close(false);
        }
        // this.isspiner = false;
      }, err => {
        // this.isspiner = false;
        this.toastr.error(err);
      });
    }
  });
    }else{
      const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
      dialogRef.afterClosed().subscribe(result => {
       
        
        localStorage.setItem('istrackid', 'MoveEntryComponent');
        let moveMatterSelectorData = JSON.parse(localStorage.getItem('SelectedWIPCheckbox'));
      
  
        let payload = [];
        moveMatterSelectorData.forEach(element => {
          
          payload.push({
            "WorkItemGuid": element.WORKITEMGUID,
            "MatterGuid": result.MATTERGUID
          })
        });
  
        if (result) {
          this.PostData = {
            "ADDITIONALTEXT": this.WIPData.ADDITIONALTEXT,
            "COMMENT": this.WIPData.COMMENT,
            "FEEEARNER": this.WIPData.FEEEARNER,
            "ITEMTYPE": this.WIPData.ITEMTYPE,
            "ITEMDATE": this.WIPData.ITEMDATE,
            "ITEMTIME": this.WIPData.ITEMTIME,
            "MATTERGUID": result.MATTERGUID,
            "PRICE": this.WIPData.PRICE,
            "PRICEINCGST": this.WIPData.PRICEINCGST,
            "QUANTITY": this.WIPData.QUANTITY,
            "QUANTITYTYPE": this.WIPData.QUANTITYTYPE,
            "WORKITEMGUID": this.WIPData.WORKITEMGUID
          }
  
          let PostTimeEntryData: any = { FormAction: 'update', "MultiRecord": true, VALIDATEONLY: false, Data: payload };
          this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
              $('#refreshWorkInprogress').click();
              $('#refreshTimeEntryTab').click();
              this.toastr.success('WIP moved successfully');
              // this.dialogRef.close(true);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
              this.toastr.warning(res.MESSAGE);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
              this.toastr.warning(res.MESSAGE);
            } else if (res.MESSAGE == 'Not logged in') {
              // this.dialogRef.close(false);
            }
            // this.isspiner = false;
          }, err => {
            // this.isspiner = false;
            this.toastr.error(err);
          });
        }
      });

    }
  }

  /**
   * This function is used to Resend the Data value
   */
  ReSendData() {

    let PostTimeEntryData: any = { FormAction: 'update', VALIDATEONLY: false, Data: this.PostData };
    this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        $('#refreshWorkInprogress').click();
        $('#refreshTimeEntryTab').click();
        this.toastr.success('WIP moved successfully');
        // this.dialogRef.close(true);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(res.MESSAGE);
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.warning(res.MESSAGE);
      } else if (res.MESSAGE == 'Not logged in') {
        // this.dialogRef.close(false);
      }
      // this.isspiner = false;
    }, err => {
      // this.isspiner = false;
      this.toastr.error(err);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
}


openBulkProgressbar(){
  this.dialogRef = this.MatDialog.open(BulkProgressComponent, {
      width: "100%",
      disableClose: true,
      data: "",
  });

  this.dialogRef.componentInstance.DialogTitle = "Moving Entries";
  this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
      }
  });
}
}
