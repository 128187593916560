import { Component, OnInit, Inject, ViewChild, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from 'src/@fuse/animations';

import { environment } from 'src/environments/environment';
import { MatSort } from '@angular/material/sort';
// import { $ } from 'protractor';
import * as $ from 'jquery';
import { ContactCorresDetailsComponent } from '../../contact/contact-corres-details/contact-corres-details.component';
import { SendEmailComponent } from '../email-templete/send-email/send-email.component';
import { Router } from '@angular/router';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SocketService } from '@_services/socket.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';


@Component({
    selector: 'app-matter-dialog',
    templateUrl: './matter-dialog.component.html',
    styleUrls: ['./matter-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MatterDialogComponentForTemplate implements OnInit, OnDestroy {
    message: string;
    btnText = '';
    progress: any = 5;
    fileExtention: any;
    getDataForTable: any = [];
    isspiner: boolean = false;
    highlightedRows: any;
    theme_type = localStorage.getItem('theme_type');
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    isLoadingResults: boolean = false;
    IsAuth: boolean = false;
    pageSize: any;
    currentMatterData: any;
    MatterDropData: any;
    filterVal: any = { 'Active': '', 'FeeEarner': '', 'SearchString': '' };
    @Input() mattersDetailData;
    filefolder_Name: any;
    base_url: any;
    PacksDocument: any = [];
    CheckCondition: any;
    ForHideShow: string;
    filefolderUrlforEmail: any = [];
    title: string;
    filefolderUrl: any;
    documentData: any = {};
    isRename: boolean = false;
    xFxaxVal: any = 40;
    yflaxCal: any = 60;
    documentType: any;
    filefolderUrlDes: any;
    Title: any;
    emailToadd: any;
    emailBCC: any;
    PacksEmail: any = [];
    emailCC: any;
    emailSUBJECT: any;
    emailCONTENT: any;
    whichTypeTemplate: string;
    isGenerating: boolean = false;
    packDoc: string;
    IsLoading: string;
    arguParam: any;
    // // webSocket = new WebSocket(environment.MainSocketUrl);
    isMultiEmail: boolean = false;
    ApiReportRetryPram2: any;
    ApiReportRetryPram1: any;
    isgenerateForBulk: boolean = false;
    MatterConGuidForBulkDoc: any;
    emailType: any;
    DocumentName: any;
    DocumentData: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    SHOWFLAGDATA: any;
    COUNT: number=0;
    collectSelectedInvoice: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    SAVETOREGISTER: any = this.currentuser.SAVEEMAILSTOREGISTER;
    currentFolder: any = { 'id': '' };
    base64bitDatafile: any = [];
    storeDocGuid: any = [];
    fileNameArray: any = [];
    imageSignature: any;
    errorWarningData: any = {};
    filteredArray: any[] = [];
    filterCount: number=0;
    isShowRegenerateAll: boolean=false;
    SENTEMAILCOUNT: any=0;
    isShowSentEmailCount: boolean=false;
    isDisableSendAll: boolean=false;
    singleLoader: boolean=false;

    constructor(
        private fb: FormBuilder,
        public dialog: MatDialog,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        public dialogRef: MatDialogRef<MatterDialogComponentForTemplate>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private router: Router,
        private socketService: SocketService,
        public _matDialog: MatDialog,
        // private data:TemplateComponent
    ) {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.socketService.openWSConnection(currentUser.SessionToken, 'TemplateGenerateStatus').pipe(takeUntil(this._unsubscribeAll$))
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        this.socketService.getWebSocketDocumentGenerationSubject().subscribe((event) => {
            if (event) {
                this.progress = event.PROGRESS;
                this.btnText = event.DESCRIPTION;
            }
        })
        localStorage.setItem('istrackid', 'MatterDialogComponentForTemplate');
        this.IsLoading = "true";
        //need to call generate template api
        this.base_url = environment.DocumentsUrl;
        if (_data) {
            if (_data.type == 'preview') {
                this.whichTypeTemplate = 'Template';
                this.filefolderUrl = _data.document.FILENAME;
                this.filefolderUrlDes = _data.document.DESCRIPTION;
                this.documentType = _data.document.DOCUMENTTYPE;
                this.documentData = _data.document;
                this.Title = 'Document';
                this.isGenerating = false;
            } else {
                this.Title = _data.Type == 'Email' ? 'Email' : 'Document';
                this.SHOWFLAGDATA=_data?.SHOWFLAGDATA;
                if(this.SHOWFLAGDATA && this.SHOWFLAGDATA !=undefined && this.SHOWFLAGDATA !=''){
                    this.filefolder_Name='Invoice and email'; 
                    this.SHOWFLAGDATA=_data?.SHOWFLAGDATA;                  
                }else{
                    this.filefolder_Name = _data.nameShow ? _data.nameShow : _data.Template;
                    this.SHOWFLAGDATA=undefined;
                }
                this.whichTypeTemplate = _data.knownby;
                this.isGenerating = true;
                if (_data.isMultipleDocument == true) {
                    this.generateForBulk(_data, '');
                } else {
                    this.selectMatter(_data, '');
                }
            }
        }
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        // // this.openWSConnection(currentUser.SessionToken);
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'MatterDialogComponentForTemplate' && result['click'] && result['data']['TemplateGenerate']) {
                this.selectMatter(this.ApiReportRetryPram1, this.ApiReportRetryPram2);
            }
        });

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.getSignature();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

/**
 * This function is used to select the matter
 */
   async selectMatter(ReportData: any, fval,flag?,data?) {
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        // await this.socketService.openSocket();
        this.ApiReportRetryPram1 = ReportData;
        this.ApiReportRetryPram2 = fval;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketDocumentGeneration(uniqueId);
        this.IsAuth = false;
        delete ReportData["knownby"];
        this.isLoadingResults = true;
        if (fval != '') {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        this.DocumentName = ReportData['DocumentName'];
        this.DocumentData = ReportData['DocumentData'];
        let fromType: any = ReportData['fromType'];
        let ExtraData: any = (ReportData['ExtraData']) ? ReportData['ExtraData'] : '';
        delete ReportData['DocumentData'];
        delete ReportData['DocumentName'];
        if (ReportData['ExtraData']) {
            delete ReportData['ExtraData'];
        }
        ReportData.TrackingGuid = uniqueId;
        this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.IsLoading = "false";
                if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM') {
                    this.toastr.success('TemplateGenerate successfully');
                    this.filefolderUrl = response.DATA.DOCUMENTS[0].FILENAME;
                    this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                    this.documentType = response.DATA.DOCUMENTS[0].DOCUMENTTYPE;
                    this.documentData = response.DATA.DOCUMENTS[0];
                } else if (this._data.Type == "Email") {
                    this.toastr.success('EmailGenerate successfully');
                    this.IsLoading = 'false';
                    this.isLoadingResults = false;
                    if (response.DATA.EMAILS.length == 1) {
                        let EmailData: any = response.DATA.EMAILS[0];
                        EmailData.DocumentName = this.DocumentName;
                        EmailData.DocumentData = this.DocumentData;
                        EmailData.fromType = fromType;
                        EmailData.ExtraData = ExtraData;
                        if(flag == undefined  &&  flag !='isMultyCall'){
                            this.PacksEmail = response.DATA.EMAILS;
                            this.SendMain(EmailData);
                            this.dialogRef.close();
                        }else{
                           this.PacksEmail.forEach(element => {
                            if(element.INVOICEGUID == data.INVOICEGUID){
                                element.SHOWINPUT=false;
                                element.MATTERCONTACT=[];
                                element.SHOWICON=false;
                                response.DATA.EMAILS.forEach(val => {
                                    element.ATTACHMENTS =val.ATTACHMENTS;
                                    element.BCCADDRESS=val.BCCADDRESS;
                                    element.CCADDRESS=val.CCADDRESS;
                                    element.CONTENT=val.CONTENT;
                                    element.DocumentData=val.DocumentData;
                                    element.DocumentName=val.DocumentName;
                                    element.ExtraData=val.ExtraData;
                                    element.MATTERGUID=val.MATTERGUID;
                                    element.SELECTEDDESCRIPTION=val.SELECTEDDESCRIPTION;
                                    element.SUBJECT=val.SUBJECT;
                                    element.TOADDRESS=val.TOADDRESS;
                                    element.fromType=val.fromType;
                                }); 
                            }
                           });

                           // This is for calculate the totalEmail count.
                           const filterArray = this.PacksEmail.filter((ele) => ele.SHOWINPUT == false);
                           this.filteredArray = filterArray;

                           // This is for show the Re-Generate All button.
                           let DATA=this.PacksEmail.filter(data=> data.SHOWINPUT == true);
                           if(DATA.length > 0){
                            this.isShowRegenerateAll=true;
                           }else{
                            this.isShowRegenerateAll=false;
                           }
                           
                           // This is for calculate the total count of the generated document.
                           this.COUNT++

                           // This is for calculate the sented email count.
                           let SENTEMAILCOUNT=this.PacksEmail.filter(data=> data.SHOWICON == true);
                           this.SENTEMAILCOUNT=SENTEMAILCOUNT.length;
                        }
                        this.isMultiEmail = false;
                    } else {
                        this.isMultiEmail = true;
                    }
                } else if (this._data.Type == "Pack") {
                    this.toastr.success('PackGenerate successfully');
                    this.IsLoading = 'false';
                    this.PacksEmail = response.DATA.EMAILS;
                    this.PacksDocument = response.DATA.DOCUMENTS;
                }
                if (this._data.Type != "Email") {
                    // // this.webSocket.send("unwatch channel " + currentUser.SessionToken + " TemplateGenerateStatus");
                    // // this.webSocket.close();
                    this.isLoadingResults = false;
                    this.isGenerating = false;
                    $('#refreshWorkInprogress').click();
                    // this.socketService.closeSocket(currentUser.SessionToken, "TemplateGenerateStatus");
                }
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.CODE == 420) {
                this.isLoadingResults = false;
                if(flag && flag !='isMultyCall'){
                    this.dialogRef.close(false);
                }
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.CODE == 428) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                this.arguParam = '';
                this.IsLoading = 'false';
                ReportData.CHECKFORPROMPTS = true;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentuser.UserGuid,
                        "AuthType": "NetEmail"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 432) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                this.arguParam = '';
                ReportData.CHECKFORPROMPTS = true;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentuser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 431) {
                this.IsAuth = true;
                let currentMatter: any;
                this.behaviorService.MatterData$.subscribe(result => {
                    if (result) { currentMatter = result; }
                });
                let currentInvoiceMatter: any;
                this.behaviorService.matterInvoice$.subscribe(result => {
                    if (result) { currentInvoiceMatter = result; }
                });
                let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.MATTERGUID : ReportData.ContextGuid;
                const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                    disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                });
                dialogRefMC.afterClosed().subscribe(result => {
                    if (result) {
                        ReportData.CHECKFORPROMPTS = false;
                        ReportData.ContactGuid = result.PERSONGUID;
                        ReportData.DocumentName = this.DocumentName;
                        ReportData.DocumentData = this.DocumentData;
                        ReportData['ExtraData'] = ExtraData;
                        this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        this.selectMatter(ReportData, 'false');
                    } else {
                        this.isLoadingResults = false;
                    }
                });
                this.isLoadingResults = false;
            } else if (response.STATUS == 'error' || response.CODE == '422') {
                this.isLoadingResults = false;
                if(flag && flag !='isMultyCall'){
                    this.dialogRef.close(false);
                }
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.MESSAGE == 'Not logged in') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            }
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
        });
    }
    // openWSConnection(SessionToken: any) {
    //   // try {
    //   //   let demoSoket = this.webSocket;
    //   //   demoSoket.onopen = (openEvent) => {
    //   //     demoSoket.send("watch channel " + SessionToken + " TemplateGenerateStatus");
    //   //   };
    //   //   demoSoket.onclose = (closeEvent) => {
    //   //   };
    //   //   demoSoket.onerror = (errorEvent) => {
    //   //   };
    //   //   demoSoket.onmessage = (messageEvent) => {
    //   //     let data = JSON.parse(messageEvent.data);
    //   //     let details = JSON.parse(data.value);
    //   //     this.progress = details.PROGRESS;
    //   //     this.btnText = details.DESCRIPTION;
    //   //   };
    //   // } catch (exception) {
    //   //   console.error(exception);
    //   // }
    // }
    fun() { }

/**
 * This function is used to send the Main Email
 */
    SendMain(val,flag?) {
        if(flag =='MultyEmails'){
        val.MULTIEMAIL=true;
        }
        const dialogRef = this.dialog.open(SendEmailComponent, {
            disableClose: true,
            panelClass: '',
            data: { data: val }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == true || result == false) {
                this.dialogRef.close();
            }else{
                if(result && result.data){
                    // This flag is used to showing the email sent count or not
                    this.isShowSentEmailCount=true;
                    this.PacksEmail.forEach(element => {
                     if(element.INVOICEGUID == result.data.INVOICEGUID){
                        if(result.data.AUTHMULTIEMAIL== true){
                            element.SHOWICON=true;
                        }
                     }
                 })

                 // This is for calculate the sented email count.
                 let SENTEMAILCOUNT=this.PacksEmail.filter(data=> data.SHOWICON == true);
                 this.SENTEMAILCOUNT=SENTEMAILCOUNT.length;

                 // This code is used to desable the Send All button.
                 const setSendAllDisable=this.PacksEmail.filter((ele) => ele.SHOWINPUT == false && ele.SHOWICON == true);
                 if(setSendAllDisable.length == this.PacksEmail.length){
                     this.isDisableSendAll=true;
                     return
                 }
                }
              
            }
        });
    }

    /**
     * This function is used to rename the file
     */
    RenameFile() {
        this.isLoadingResults = true;
        this.fileExtention = this.documentData.ORIGFILENAME.substr(this.documentData.ORIGFILENAME.lastIndexOf('.') + 1);
        this._mainAPiServiceService.getSetData({ DOCUMENTGUID: this.documentData.DOCUMENTGUID, NEWDOCUMENTNAME: this.documentData.NEWFILENAME + '.' + this.fileExtention }, 'NetDriveRenameDocument').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toastr.success("Renamed successfully");
                this.isRename = false;
                this.xFxaxVal = 60;
                this.yflaxCal = 40
                this.documentData = response.DATA.DOCUMENTS[0];
                this.filefolderUrl = response.DATA.DOCUMENTS[0].DRIVEFILEWEBURL;
                this.filefolderUrlDes = this.documentData.ORIGFILENAME = response.DATA.DOCUMENTS[0].DOCUMENTNAME;
            } else if (response.CODE == 432 || response.CODE == '432') {
                let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentuser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            }
            this.isLoadingResults = false;
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
        });
    }

    /**
     * this function is used to generate the Bulk messages
     */
    async generateForBulk(ReportData: any, fval) {
        this.isgenerateForBulk = true;
        this.ApiReportRetryPram1 = ReportData;
        this.ApiReportRetryPram2 = fval;

        this.IsAuth = false;
        delete ReportData["knownby"];
        this.isLoadingResults = true;
        if (fval != '') {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let DocumentName: any = ReportData['DocumentName'];
        let DocumentData: any = ReportData['DocumentData'];
        let fromType: any = ReportData['fromType'];
        delete ReportData['DocumentData'];
        delete ReportData['DocumentName'];

        let collectSelectedInvoice =JSON.parse(localStorage.getItem('collectSelectedInvoice'));
        // This is for calculate the length of the invoices
        this.collectSelectedInvoice=collectSelectedInvoice
        if(this.router.url == '/invoice/bulk-invoices'){
            collectSelectedInvoice = JSON.parse(localStorage.getItem('ContextGuidID'));
        // This is for calculate the length of the invoices
            this.collectSelectedInvoice=collectSelectedInvoice
        }else{
            collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));
        // This is for calculate the length of the invoices
            this.collectSelectedInvoice=collectSelectedInvoice
        }
        

        if (collectSelectedInvoice) {
            for (let i = 0; i < collectSelectedInvoice.length; i++) {
                await new Promise<void>(async (next, reject) => {
                    // await this.socketService.openSocket();
                    // // this.openWSConnection(this.currentUser.SessionToken);
                    const uniqueId = this.socketService.generateUniqueId();
                    this.socketService.openSocketDocumentGeneration(uniqueId);
                    ReportData.ContextGuid = collectSelectedInvoice[i].INVOICEGUID;
                    ReportData.TrackingGuid = uniqueId;
                    this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(response => {
                        if (response.CODE == 200 && response.STATUS == "success") {
                            this.IsLoading = "false";
                            if (this._data.Type == "Template" || this._data.Type == 'Court Form' || this._data.Type == 'PRECEDENT FORM') {
                                this.toastr.success('TemplateGenerate successfully');
                                this.filefolderUrl = response.DATA.DOCUMENTS[0].FILENAME;
                                this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DESCRIPTION;
                                this.documentType = response.DATA.DOCUMENTS[0].DOCUMENTTYPE;
                                this.documentData = response.DATA.DOCUMENTS[0];
                            } else if (this._data.Type == "Email") {
                                this.toastr.success('EmailGenerate successfully');
                                // this.PacksEmail = response.DATA.EMAILS;
                                response.DATA.EMAILS.forEach(element => {
                                    element.INVOICECODE = collectSelectedInvoice[i].INVOICECODE;
                                    this.PacksEmail.push(element);
                                    let EmailData: any = element;
                                    element.MATTERCONTACT=[];
                                    EmailData.DocumentName = DocumentName;
                                    EmailData.DocumentData = DocumentData;
                                    EmailData.fromType = fromType;
                                    EmailData.MATTER=collectSelectedInvoice[i]?.MATTER;
                                    EmailData.SHORTNAME=collectSelectedInvoice[i]?.SHORTNAME;
                                    EmailData.SHOWINPUT=false;
                                    EmailData.SHOWICON=false
                                    EmailData.INVOICEGUID=collectSelectedInvoice[i]?.INVOICEGUID
                                });
                                
                                // This is for calculate the total count of the generated document.
                                this.COUNT++

                                // This is for calculate the sented email count.
                                let SENTEMAILCOUNT=this.PacksEmail.filter(data=> data.SHOWICON == true);
                                this.SENTEMAILCOUNT=SENTEMAILCOUNT.length;
                                this.isMultiEmail = false;

                            } else if (this._data.Type == "Pack") {
                                this.toastr.success('PackGenerate successfully');
                                this.IsLoading = 'false';
                                this.PacksEmail = response.DATA.EMAILS;
                                this.PacksDocument = response.DATA.DOCUMENTS;
                            }
                            if (this._data.Type != "Email") {

                                // // this.webSocket.send("unwatch channel " + currentUser.SessionToken + " TemplateGenerateStatus");
                                // // this.webSocket.close();

                                $('#refreshWorkInprogress').click();
                                // this.socketService.closeSocket(currentUser.SessionToken, "TemplateGenerateStatus");
                            }
                            next();
                            this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        } else if (response.CODE == 420) {
                            this.isLoadingResults = false;
                            this.dialogRef.close();
                        } else if (response.CODE == 428) {
                            this.isLoadingResults = false;
                            this.IsAuth = true;
                            this.arguParam = '';
                            this.IsLoading = 'false';
                            ReportData.CHECKFORPROMPTS = true;
            //                 window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + currentUser.SessionToken, 'targetWindow',
            //                     `toolbar=no,
            //    location=no,
            //    status=no,
            //    menubar=no,
            //    scrollbars=yes,
            //    resizable=yes,
            //    width=800,
            //    height=600`);
                            let payload = {
                                "Action": "OAuth2Start",
                                "VALIDATEONLY": false,
                                "Data": {
                                    "USERGUID": this.currentuser.UserGuid,
                                    "AuthType": "NetEmail"
                                }
                            }
                            this._mainAPiServiceService
                            .getSetData(payload, 'oauth-connections')
                            .subscribe((response) => {
                                if(response.CODE == 200 && response.STATUS == "success") {
                                    window.open(
                                        response.DATA[0].AuthorizationUrl,
                                        "targetWindow",
                                        `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=600`
                                    );
                                }
                            });
                        } else if (response.CODE == 432) {
                            this.isLoadingResults = false;
                            this.IsAuth = true;
                            this.arguParam = '';
                            ReportData.CHECKFORPROMPTS = true;
            //                 window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
            //                     `toolbar=no,
            //    location=no,
            //    status=no,
            //    menubar=no,
            //    scrollbars=yes,
            //    resizable=yes,
            //    width=800,
            //    height=600`);
                                let payload = {
                                    "Action": "OAuth2Start",
                                    "VALIDATEONLY": false,
                                    "Data": {
                                        "USERGUID": this.currentuser.UserGuid,
                                        "AuthType": "NetDrive"
                                    }
                                }
                                this._mainAPiServiceService
                                .getSetData(payload, 'oauth-connections')
                                .subscribe((response) => {
                                    if(response.CODE == 200 && response.STATUS == "success") {
                                        window.open(
                                            response.DATA[0].AuthorizationUrl,
                                            "targetWindow",
                                            `toolbar=no,
                                            location=no,
                                            status=no,
                                            menubar=no,
                                            scrollbars=yes,
                                            resizable=yes,
                                            width=800,
                                            height=600`
                                        );
                                    }
                                });
                        } else if (response.CODE == 431) {
                            if(this.SHOWFLAGDATA == 'Invoice and email'){
                                this._mainAPiServiceService.getSetData({Action: "GetData", Filters: { MatterGuid: collectSelectedInvoice[i].MATTERGUID }}, 'matter-contact').subscribe(res => {
                                    if (res.MESSAGE == 'Not logged in') {
                                      
                                    } else if (res.STATUS == "success" && res.CODE == 200) {
                                        this.isShowRegenerateAll=true;
                                      this.PacksEmail.push({
                                      MATTER:collectSelectedInvoice[i].MATTER,
                                      SHORTNAME:collectSelectedInvoice[i].SHORTNAME,
                                      SHOWINPUT:true,
                                      INVOICEGUID:collectSelectedInvoice[i].INVOICEGUID,
                                      MATTERCONTACT:res.DATA.RECORDS,
                                      SHOWICON:false
                                     });  
                                     
                                     // This is for calculate the sented email count.
                                     let SENTEMAILCOUNT=this.PacksEmail.filter(data=> data.SHOWICON == true);
                                     this.SENTEMAILCOUNT=SENTEMAILCOUNT.length;

                                    } else {
                                      this.toastr.error(res.MESSAGE);
                                      this.isLoadingResults = false;
                                    }
                                  });

                                  next();
                            }else{
                                if (this.MatterConGuidForBulkDoc != '') {

                                    this.IsAuth = true;
                                    let currentMatter: any;
                                    this.behaviorService.MatterData$.subscribe(result => {
                                        if (result) { currentMatter = result; }
                                    });
                                    let currentInvoiceMatter: any;
                                    this.behaviorService.matterInvoice$.subscribe(result => {
                                        if (result) { currentInvoiceMatter = result; }
                                    });
                                    let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.MATTERGUID : ReportData.ContextGuid;
                                    const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                                        disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                                    });
                                    dialogRefMC.afterClosed().subscribe(result => {
                                        if (result) {
                                            ReportData.CHECKFORPROMPTS = false;
                                           //ReportData.CHECKFORPROMPTS = true;
                                          
                                            ReportData.ContactGuid = result.PERSONGUID;
                                            this.MatterConGuidForBulkDoc = result.MATTERCONTACTGUID;
                                            this.generateForBulk(ReportData, 'false');
                                            reject();
                                        } else {
                                            this.isLoadingResults = false;
                                        }
                                    });
                                    this.isLoadingResults = false;
                                } else {
                                    next();
                                }

                            }

                        } else if (response.STATUS == 'error' || response.CODE == '422') {
                            this.isLoadingResults = false;
                            this.dialogRef.close(false);
                        } else if (response.MESSAGE == 'Not logged in') {
                            this.isLoadingResults = false;
                            this.dialogRef.close(false);
                        }
                    }, error => {
                        this.isLoadingResults = false;
                        this.toastr.error(error);
                        this.dialogRef.close();
                    });
                });
            }
            this.isLoadingResults = false;
            this.isGenerating = false;
        }
    }

    /**
     * This function is used to on changes the Matter Contact.
     */
    onChangeMatterContact(data){
       this._data.ContactGuid = data.PERSONGUID;
       this._data.CHECKFORPROMPTS=false 
    }

    /**
     * This function is used to get Refresh Data.
     */
    getRefreshData(data){
        if(data.mattercontact == undefined){
         this.toastr.error('Please select a matter contact')
            return
        }
        this._data.ContextGuid=data.INVOICEGUID;
        this.selectMatter(this._data,'false','isMultyCall',data);
    }

    /**
     * This function is used to getSignature data.
     */
    getSignature() {
        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": {} }, 'preference').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                let DefultVal: any = response.DATA;
                // this.SendEmailForm.controls['ckeditorName'].value
                this.imageSignature = `<span class="signature-card">` + DefultVal.SIGNATUREDEFAULT + `</span>`;
                // this.ckeditorValue = this.ckeditorValue + `<span class="signature-card">` + DefultVal.SIGNATUREDEFAULT + `</span>`;
                //this.ckeditorValue = this.DefultData + DefultVal.SIGNATUREDEFAULT;
            }
        }, error => {
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to get the popup data
     */
    async getPopupData(countDATA,flag) {
        let defaultCount=[];
        if(flag == 'single'){
            defaultCount.push(countDATA);
            this.singleLoader=true;
        }else{
            defaultCount = countDATA;
        }

        defaultCount.forEach((element) => {
            element.MULTIEMAIL = true;
        });

        // This is for calculate the totalEmail count.

        const filter = defaultCount.filter((ele) => ele.SHOWINPUT == false);
        this.filteredArray = filter;

        const filterArray = defaultCount.filter((ele) => ele.SHOWINPUT == false && ele.SHOWICON == false);
        //this.filteredArray = filterArray;
    
        if(filterArray.length <= 0){
        this.toastr.error('Please select a matter contact')
        return
    }

    if(flag == 'single'){
        for (let element of filterArray) {
            const currentDate = (new Date().toLocaleString()).replace(/[\. /,:-]+/g, "-");

            if (element.DocumentData) {
                this.storeDocGuid.push({
                    DOCUMENTGUID: (element.DocumentData.DOCUMENTGUID) ? element.DocumentData.DOCUMENTGUID : element.DocumentData
                });
            }

            if (element.DocumentName) {
                this.fileNameArray.push({
                    name: (element.DocumentName.name) ? (element.DocumentName.name) : element.DocumentName
                });
            }

            let passdata: any = {
                TOADDRESS: element.TOADDRESS.toString(),
                CCADDRESS: element.CCADDRESS.toString(),
                BCCADDRESS: element.BCCADDRESS.toString(),
                IMPORTANCE: "high",
                SUBJECT: element.SUBJECT,
                CONTENT: element.CONTENT,
                CONTENTISHTML: true,
                SAVETOREGISTER: this.SAVETOREGISTER,
                FILENAME: element.SUBJECT ? element.SUBJECT + '-' + currentDate : '' + '.eml',
                FOLDERNAME: this.currentFolder.id
            };

            if (!this.base64bitDatafile[0]) {
console.log('CALL FIRST_SECOND',countDATA?.ATTACHMENTS);
let DocumentGuid=[];
if(countDATA?.ATTACHMENTS.length >0){
countDATA?.ATTACHMENTS.forEach(element => {
    DocumentGuid.push({DOCUMENTGUID:element.DOCUMENTGUID});
    passdata.ATTACHMENTLIST = DocumentGuid;
});
}else{
    passdata.ATTACHMENTLIST = this.storeDocGuid;
}

            } else {
                passdata.FILENAME = this.fileNameArray[0].name;
                passdata.FILEDATA = this.base64bitDatafile[0];
            }

            passdata.MATTERGUID = element.MATTERGUID;
            passdata.SIGNATUREDEFAULT = this.imageSignature;
            let details = { "Action": "NetEmailSendEmail", VALIDATEONLY: true, Data: passdata };

            try {
                // Await the API call
                const response = await this._mainAPiServiceService.getSetData(details, 'cloud-email').toPromise();
                
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    await this.checkValidation(response.DATA.VALIDATIONS, details, element);
                    this.singleLoader=false
                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                    this.singleLoader=false
                    await this.checkValidation(response.DATA.VALIDATIONS, details, element);
                } else if (response.CODE == 450 && response.STATUS == 'error') {
                    this.singleLoader=false
                    await this.checkValidation(response.DATA.VALIDATIONS, details, element);
                } else if (response.MESSAGE == 'Not logged in') {
                    // Handle not logged in case
                    this.singleLoader=false
                } else if (response.CODE == "428" && response.STATUS == "error") {
                    this.singleLoader=false
                    this.nativeWindowOpen();
                    this.isspiner = false;
                } else if (response.CODE == 432) {
                    this.nativeDrive();
                    this.isspiner = false;
                    this.singleLoader=false
                }
            } catch (error) {
                this.isspiner = false;
                this.toastr.error(error);
                this.singleLoader=false

            }
        }

    }else{

        
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: true,
            width: "100%",
        });
    
        this.confirmDialogRef.componentInstance.confirmMessage =
            `Are you sure you want to send all ${filterArray.length} emails?`;
    
        const result = await this.confirmDialogRef.afterClosed().toPromise();
    
        if (result) {
            // for (let element of filterArray) {
            //     const currentDate = (new Date().toLocaleString()).replace(/[\. /,:-]+/g, "-");
    
            //     if (element.DocumentData) {
            //         this.storeDocGuid.push({
            //             DOCUMENTGUID: (element.DocumentData.DOCUMENTGUID) ? element.DocumentData.DOCUMENTGUID : element.DocumentData
            //         });
            //     }
    
            //     if (element.DocumentName) {
            //         this.fileNameArray.push({
            //             name: (element.DocumentName.name) ? (element.DocumentName.name) : element.DocumentName
            //         });
            //     }
    
            //     let passdata: any = {
            //         TOADDRESS: element.TOADDRESS.toString(),
            //         CCADDRESS: element.CCADDRESS.toString(),
            //         BCCADDRESS: element.BCCADDRESS.toString(),
            //         IMPORTANCE: "high",
            //         SUBJECT: element.SUBJECT,
            //         CONTENT: element.CONTENT,
            //         CONTENTISHTML: true,
            //         SAVETOREGISTER: this.SAVETOREGISTER,
            //         FILENAME: element.SUBJECT ? element.SUBJECT + '-' + currentDate : '' + '.eml',
            //         FOLDERNAME: this.currentFolder.id
            //     };
    
            //     if (!this.base64bitDatafile[0]) {
            //         passdata.ATTACHMENTLIST = this.storeDocGuid;
            //     } else {
            //         passdata.FILENAME = this.fileNameArray[0].name;
            //         passdata.FILEDATA = this.base64bitDatafile[0];
            //     }
    
            //     passdata.MATTERGUID = element.MATTERGUID;
            //     passdata.SIGNATUREDEFAULT = this.imageSignature;
            //     let details = { "Action": "NetEmailSendEmail", VALIDATEONLY: true, Data: passdata };
    
            //     try {
            //         // Await the API call
            //         const response = await this._mainAPiServiceService.getSetData(details, 'cloud-email').toPromise();
                    
            //         if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            //             await this.checkValidation(response.DATA.VALIDATIONS, details, element);
            //         } else if (response.CODE == 451 && response.STATUS == 'warning') {
            //             await this.checkValidation(response.DATA.VALIDATIONS, details, element);
            //         } else if (response.CODE == 450 && response.STATUS == 'error') {
            //             await this.checkValidation(response.DATA.VALIDATIONS, details, element);
            //         } else if (response.MESSAGE == 'Not logged in') {
            //             // Handle not logged in case
            //         } else if (response.CODE == "428" && response.STATUS == "error") {
            //             this.nativeWindowOpen();
            //             this.isspiner = false;
            //         } else if (response.CODE == 432) {
            //             this.nativeDrive();
            //             this.isspiner = false;
            //         }
            //     } catch (error) {
            //         this.isspiner = false;
            //         this.toastr.error(error);
            //     }
            // }
            for (let element of filterArray) {
                let storeDocGuid = [];
                let fileNameArray = [];
                const currentDate = (new Date().toLocaleString()).replace(/[\. /,:-]+/g, "-");
    
                if (element.DocumentData) {
                    storeDocGuid.push({
                        DOCUMENTGUID: (element.DocumentData.DOCUMENTGUID) ? element.DocumentData.DOCUMENTGUID : element.DocumentData
                    });
                }
    
                if (element.DocumentName) {
                    fileNameArray.push({
                        name: (element.DocumentName.name) ? (element.DocumentName.name) : element.DocumentName
                    });
                }
    
                let passdata: any = {
                    TOADDRESS: element.TOADDRESS.toString(),
                    CCADDRESS: element.CCADDRESS.toString(),
                    BCCADDRESS: element.BCCADDRESS.toString(),
                    IMPORTANCE: "high",
                    SUBJECT: element.SUBJECT,
                    CONTENT: element.CONTENT,
                    CONTENTISHTML: true,
                    SAVETOREGISTER: this.SAVETOREGISTER,
                    FILENAME: element.SUBJECT ? element.SUBJECT + '-' + currentDate : '' + '.eml',
                    FOLDERNAME: this.currentFolder.id
                };
    
                if (!this.base64bitDatafile[0]) {
                    passdata.ATTACHMENTLIST = storeDocGuid;
                } else {
                    passdata.FILENAME = fileNameArray[0].name;
                    passdata.FILEDATA = this.base64bitDatafile[0];
                }
    
                passdata.MATTERGUID = element.MATTERGUID;
                passdata.SIGNATUREDEFAULT = this.imageSignature;
                if(element?.ATTACHMENTS?.length > 0){
                    let DocumentGuid = [];
                    element.ATTACHMENTS.forEach(element => {
                        DocumentGuid.push({DOCUMENTGUID:element.DOCUMENTGUID});
                        passdata.ATTACHMENTLIST = DocumentGuid;
                    });
                }else{
                    passdata.ATTACHMENTLIST = storeDocGuid;
                }
                let details = { "Action": "NetEmailSendEmail", VALIDATEONLY: true, Data: passdata };
    
                try {
                    // Await the API call
                    const response = await this._mainAPiServiceService.getSetData(details, 'cloud-email').toPromise();
                    
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                        await this.checkValidation(response.DATA.VALIDATIONS, details, element);
                    } else if (response.CODE == 451 && response.STATUS == 'warning') {
                        await this.checkValidation(response.DATA.VALIDATIONS, details, element);
                    } else if (response.CODE == 450 && response.STATUS == 'error') {
                        await this.checkValidation(response.DATA.VALIDATIONS, details, element);
                    } else if (response.MESSAGE == 'Not logged in') {
                        // Handle not logged in case
                    } else if (response.CODE == "428" && response.STATUS == "error") {
                        this.nativeWindowOpen();
                        this.isspiner = false;
                    } else if (response.CODE == 432) {
                        this.nativeDrive();
                        this.isspiner = false;
                    }
                } catch (error) {
                    this.isspiner = false;
                    this.toastr.error(error);
                }
            }
        }

    }
    }
    
    /**
     * This function is used to check the validation data
     */
    async checkValidation(bodyData: any, details: any, element) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
    
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            }
            else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
    
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    
        if (Object.keys(errorData).length !== 0) {
            this.isspiner = false;
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length !== 0) {
            this.isspiner = false;
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    
            const result = await this.confirmDialogRef.afterClosed().toPromise();
    
            if (result) {
                await this.SetEmailData(details, element);
            }
            this.confirmDialogRef = null;
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            await this.SetEmailData(details, element);
        }
    }
    
    /**
     * This function is used to set the Email data.
     */
    async SetEmailData(data: any, element) {
        this.isspiner = true;
        this.singleLoader=true
        data.VALIDATEONLY = false;
    
        try {
            const response = await this._mainAPiServiceService.getSetData(data, 'cloud-email').toPromise();
    
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.success('Email sent successfully');
                element.AUTHMULTIEMAIL = true;
                this.singleLoader=false;
                this.PacksEmail.forEach(data => {
                    if (data.INVOICEGUID == element.INVOICEGUID) {
                        if (element.AUTHMULTIEMAIL == true) {
                            data.SHOWICON = true;
                        }
                    }
                });
    
                // This is for calculate the sented email count.
                let SENTEMAILCOUNT=this.PacksEmail.filter(data=> data.SHOWICON == true);
                this.SENTEMAILCOUNT=SENTEMAILCOUNT.length;
                if (this.SAVETOREGISTER) {
                // Add your logic for saving to register if necessary
                }
                this.filterCount++;

                // This flag is used to showing the email sent count or not
                this.isShowSentEmailCount=true;

                // This code is used to desable the Send All button.
                const setSendAllDisable=this.PacksEmail.filter((ele) => ele.SHOWINPUT == false && ele.SHOWICON == true);
                if(setSendAllDisable.length == this.PacksEmail.length){
                    this.isDisableSendAll=true;
                    return
                }

            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.isspiner = false;
                this.singleLoader=false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.isspiner = false;
                this.singleLoader=false;
            } else if (response.MESSAGE == 'Not logged in') {
                // Handle not logged in case
                this.singleLoader=false;
            } else if (response.CODE == "428" && response.STATUS == "error") {
                this.nativeWindowOpen();
                this.isspiner = false;
                this.singleLoader=false;
            } else if (response.CODE == 432) {
                this.nativeDrive();
                this.isspiner = false;
                this.singleLoader=false;
            } else {
                this.isspiner = false;
                this.singleLoader=false;
            }
        } catch (error) {
            this.isspiner = false;
            this.toastr.error(error);
            this.singleLoader=false;
        }
    }

    /**
     * This function is used to nativeWindowOpen data
     */
    nativeWindowOpen() {
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        // window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, 'targetWindow',
        //     `toolbar=no,
        // location=no,
        // status=no,
        // menubar=no,
        // scrollbars=yes,
        // resizable=yes,
        // width=800,
        // height=600`);
        let payload = {
            "Action": "OAuth2Start",
            "VALIDATEONLY": false,
            "Data": {
                "USERGUID": this.currentuser.UserGuid,
                "AuthType": "NetEmail"
            }
        }
        this._mainAPiServiceService
        .getSetData(payload, 'oauth-connections')
        .subscribe((response) => {
            if(response.CODE == 200 && response.STATUS == "success") {
                window.open(
                    response.DATA[0].AuthorizationUrl,
                    "targetWindow",
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                );
            }
        });
    }

    /**
     * This function is used to get the native Drive data value.
     */
    nativeDrive() {
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        // window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //     `toolbar=no,
        // location=no,
        // status=no,
        // menubar=no,
        // scrollbars=yes,
        // resizable=yes,
        // width=800,
        // height=600`);
        let payload = {
            "Action": "OAuth2Start",
            "VALIDATEONLY": false,
            "Data": {
                "USERGUID": this.currentuser.UserGuid,
                "AuthType": "NetDrive"
            }
        }
        this._mainAPiServiceService
        .getSetData(payload, 'oauth-connections')
        .subscribe((response) => {
            if(response.CODE == 200 && response.STATUS == "success") {
                window.open(
                    response.DATA[0].AuthorizationUrl,
                    "targetWindow",
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                );
            }
        });
    }

    /**
     * This function is used to reGenerate the Document
     */
    async reGenerateDocument(data) {
        let unselectedMatterContactsRecord = [];
        let selectedMatterContactRecords = [];
    
        // Filter data based on the condition
        data.forEach(element => {
            if (element?.MATTERCONTACT?.length > 0) {
                unselectedMatterContactsRecord.push(element);
            } else {
                selectedMatterContactRecords.push(element);
            }
        });
        // Filter matter contacts
        let selectedMatterContact = unselectedMatterContactsRecord.filter(data => data?.mattercontact);
    
        // API CALLING START HERE
        if (selectedMatterContact.length > 0) {
            try {
                // Iterate over each selectedMatterContact sequentially
                for (let i = 0; i < selectedMatterContact.length; i++) {
                    this._data.ContextGuid = selectedMatterContact[i].INVOICEGUID;
                    this._data.ContactGuid = selectedMatterContact[i].mattercontact.PERSONGUID;
                    this._data.MATTERCONTACTGUID = selectedMatterContact[i].mattercontact.MATTERCONTACTGUID;
                    this._data.CHECKFORPROMPTS = false;
    
                    // Await the completion of each API call before proceeding to the next one
                    await this.RegenerateAll(this._data, 'false', 'isMultyCall', selectedMatterContact[i]);
                }
                this.toastr.success("Documents regenerated successfully.");
            } catch (error) {
                this.toastr.error("Error while regenerating documents: " + error.message);
            }
        } else {
            this.toastr.error("Please select a matter contact.");
        }
    }
    
    /**
     * This function is used to Regenerate all Document.
     */
    async RegenerateAll(ReportData: any, fval, flag?, data?): Promise<void> {
        this.ApiReportRetryPram1 = ReportData;
        this.ApiReportRetryPram2 = fval;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketDocumentGeneration(uniqueId);
        this.IsAuth = false;
        delete ReportData["knownby"];
        this.isLoadingResults = true;
    
        if (fval != '') {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
    
        this.DocumentName = ReportData['DocumentName'];
        this.DocumentData = ReportData['DocumentData'];
        let fromType: any = ReportData['fromType'];
        let ExtraData: any = (ReportData['ExtraData']) ? ReportData['ExtraData'] : '';
        delete ReportData['DocumentData'];
        delete ReportData['DocumentName'];
    
        if (ReportData['ExtraData']) {
            delete ReportData['ExtraData'];
        }
    
        ReportData.TrackingGuid = uniqueId;
    
        // Wrapping the API call in a Promise to use async/await
        return new Promise((resolve, reject) => {
            this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.IsLoading = "false";
                    if (this._data.Type == "Email") {
                        this.toastr.success('EmailGenerate successfully');
                        this.IsLoading = 'false';
                        this.isLoadingResults = false;
                        if (response.DATA.EMAILS.length == 1) {
                            let EmailData: any = response.DATA.EMAILS[0];
                            EmailData.DocumentName = this.DocumentName;
                            EmailData.DocumentData = this.DocumentData;
                            EmailData.fromType = fromType;
                            EmailData.ExtraData = ExtraData;
                            
                            this.PacksEmail.forEach(element => {
                                if (element.INVOICEGUID == data.INVOICEGUID) {
                                    element.SHOWINPUT = false;
                                    element.MATTERCONTACT = [];
                                    element.SHOWICON = false;
                                    response.DATA.EMAILS.forEach(val => {
                                        element.ATTACHMENTS = val.ATTACHMENTS;
                                        element.BCCADDRESS = val.BCCADDRESS;
                                        element.CCADDRESS = val.CCADDRESS;
                                        element.CONTENT = val.CONTENT;
                                        element.DocumentData = val.DocumentData;
                                        element.DocumentName = val.DocumentName;
                                        element.ExtraData = val.ExtraData;
                                        element.MATTERGUID = val.MATTERGUID;
                                        element.SELECTEDDESCRIPTION = val.SELECTEDDESCRIPTION;
                                        element.SUBJECT = val.SUBJECT;
                                        element.TOADDRESS = val.TOADDRESS;
                                        element.fromType = val.fromType;
                                    });
                                }
                            });
    
                           // This is for calculate the totalEmail count.
                           const filterArray = this.PacksEmail.filter((ele) => ele.SHOWINPUT == false);
                           this.filteredArray = filterArray;

                           // This is for calculate the sented email count.
                            let SENTEMAILCOUNT=this.PacksEmail.filter(data=> data.SHOWICON == true);
                            this.SENTEMAILCOUNT=SENTEMAILCOUNT.length;

                            // This is for show the Re-Generate All button.
                            let DATA = this.PacksEmail.filter(data => data.SHOWINPUT == true);
                            if (DATA.length > 0) {
                                this.isShowRegenerateAll = true;
                            } else {
                                this.isShowRegenerateAll = false;
                            }

                            // This is for calculate the total count of the generated document.
                            this.COUNT++;
                            this.isMultiEmail = false;
                        } else {
                            this.isMultiEmail = true;
                        }
                    }
                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                    resolve(); // Resolve the promise on success
                } else if (response.CODE == 420 || response.STATUS == 'error' || response.CODE == '422' || response.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                    reject(new Error('Error in document generation')); // Reject the promise on failure
                }
            }, error => {
                this.isLoadingResults = false;
                this.toastr.error(error);
                this.dialogRef.close();
                reject(error); // Reject the promise on error
            });
        });
    }
}
