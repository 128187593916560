<!-- [formGroup]="matterdetailForm" -->
<form name="CompensationForm" id="CompensationForm" [formGroup]="CompensationForm" fxLayout="column"
    fxLayoutAlign="start" class="one" fxFlex="1 0 auto">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE,'has-warning':errorWarningData.Warning?.ACCIDENTDATE}">
            <mat-label>Accident Date</mat-label>
            <input (dateInput)="AccidentDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="ACCIDENTDATETEXT"
                (dateChange)="AccidentDateClick('change', $event)" matInput [matDatepicker]="AccidentDate">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.ACCIDENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE2,'has-warning':errorWarningData.Warning?.ACCIDENTDATE2}">
            <mat-label>Accident Date 2</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATE2TEXT" (dateInput)="AccidentDate2Click('input', $event)" (dateChange)="AccidentDate2Click('change', $event)" matInput [matDatepicker]="AccidentDate2">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate2"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate2></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE3,'has-warning':errorWarningData.Warning?.ACCIDENTDATE3}">
            <mat-label>Accident Date 3</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATE3TEXT" (dateInput)="AccidentDate3Click('input', $event)" (dateChange)="AccidentDate3Click('change', $event)" matInput [matDatepicker]="AccidentDate3">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate3"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate3></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE4,'has-warning':errorWarningData.Warning?.ACCIDENTDATE4}">
            <mat-label>Accident Date 4</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATE4TEXT" (dateInput)="AccidentDate4Click('input', $event)" (dateChange)="AccidentDate4Click('change', $event)" matInput [matDatepicker]="AccidentDate4">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate4"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate4></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.EXPIRATIONDATE,'has-warning':errorWarningData.Warning?.EXPIRATIONDATE}">
            <mat-label>Expiry Date</mat-label>
            <input (dateInput)="ExpiryDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.EXPIRATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="EXPIRATIONDATETEXT"
                (dateChange)="ExpiryDateClick('change', $event)" matInput [matDatepicker]="ExpiryDate">
            <mat-datepicker-toggle matSuffix [for]="ExpiryDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpiryDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.EXPIRATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXPIRATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPIRATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DATEOFNOTICEOFINJURY,'has-warning':errorWarningData.Warning?.DATEOFNOTICEOFINJURY}">
            <mat-label>Notice of Injury</mat-label>
            <input (dateInput)="NoticeofInjuryClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.DATEOFNOTICEOFINJURY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="DATEOFNOTICEOFINJURYTEXT"
                (dateChange)="NoticeofInjuryClick('change', $event)" matInput [matDatepicker]="NoticeofInjury">
            <mat-datepicker-toggle matSuffix [for]="NoticeofInjury"></mat-datepicker-toggle>
            <mat-datepicker #NoticeofInjury></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.DATEOFNOTICEOFINJURY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFNOTICEOFINJURY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFNOTICEOFINJURY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLACEOFINJURY,'has-warning':errorWarningData.Warning?.PLACEOFINJURY}">
            <mat-label>Place of Injury</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PLACEOFINJURY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="PLACEOFINJURY">
        <div  *ngIf="testBool && toolTipList.PLACEOFINJURY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLACEOFINJURY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLACEOFINJURY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INJURYDESCRIPTION,'has-warning':errorWarningData.Warning?.INJURYDESCRIPTION}">
            <mat-label>Injury Desc.</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.INJURYDESCRIPTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="INJURYDESCRIPTION">
        <div  *ngIf="testBool && toolTipList.INJURYDESCRIPTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INJURYDESCRIPTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INJURYDESCRIPTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.HOWDIDINJURYOCCUR,'has-warning':errorWarningData.Warning?.HOWDIDINJURYOCCUR}">
            <mat-label>Cause of Injury</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.COURT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="HOWDIDINJURYOCCUR">
        <div  *ngIf="testBool && toolTipList.HOWDIDINJURYOCCUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.HOWDIDINJURYOCCUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.HOWDIDINJURYOCCUR?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="pr-4">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LITIGATIONFUNDER,'has-warning':errorWarningData.Warning?.LITIGATIONFUNDER}">
            <mat-label>Lit. Funder</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.LITIGATIONFUNDER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="LITIGATIONFUNDER">
        <div  *ngIf="testBool && toolTipList.LITIGATIONFUNDER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LITIGATIONFUNDER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LITIGATIONFUNDER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <section class="example-section" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.NUMBEROFSHARES,'has-warning':errorWarningData.Warning?.NUMBEROFSHARES}">
            <mat-checkbox matTooltip="{{(toolTipList)?toolTipList.COURT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="TRANSFERREDFROMOTHERSOLICITOR">Transferred From Other Solicitor</mat-checkbox>
        </section>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.OTHERSOLICITORNAME,'has-warning':errorWarningData.Warning?.OTHERSOLICITORNAME}">
            <mat-label>Solicitor Name</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.OTHERSOLICITORNAME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="OTHERSOLICITORNAME">
        <div  *ngIf="testBool && toolTipList.OTHERSOLICITORNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OTHERSOLICITORNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERSOLICITORNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEDAWARD,'has-warning':errorWarningData.Warning?.ESTIMATEDAWARD}">
            <mat-label>Est. Award</mat-label>
            <input matInput style="text-align: right;" [value]="CompensationForm.get('ESTIMATEDAWARD').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.ESTIMATEDAWARD?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="ESTIMATEDAWARD" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.ESTIMATEDAWARD" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEDAWARD.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEDAWARD?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CLAIMNUMBER,'has-warning':errorWarningData.Warning?.CLAIMNUMBER}">
            <mat-label>Claim Number</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CLAIMNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="CLAIMNUMBER">
        <div  *ngIf="testBool && toolTipList.CLAIMNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLAIMNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLAIMNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INVESTIGATIONDATE,'has-warning':errorWarningData.Warning?.INVESTIGATIONDATE}">
            <mat-label>Investigation Date</mat-label>
            <input (dateInput)="InvestigationClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList?.INVESTIGATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="INVESTIGATIONDATETEXT"
                (dateChange)="InvestigationClick('change', $event)" matInput name="Investigation"
                [matDatepicker]="Investigation" matTooltip="{{allToolTip_Msg?.INVESTIGATIONDATE || ''}}">
            <mat-datepicker-toggle matSuffix [for]="Investigation"></mat-datepicker-toggle>
            <mat-datepicker #Investigation></mat-datepicker>
        <div  *ngIf="testBool && toolTipList?.INVESTIGATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList?.INVESTIGATIONDATE?.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVESTIGATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SETTLEMENTDATE,'has-warning':errorWarningData.Warning?.SETTLEMENTDATE}">
            <mat-label>Settlement Date</mat-label>
            <input (dateInput)="SettlementDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList?.SETTLEMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="SETTLEMENTDATETEXT"
                (dateChange)="SettlementDateClick('change', $event)" matInput name="SettlementDate"
                [matDatepicker]="SettlementDate" matTooltip="{{allToolTip_Msg?.SETTLEMENTDATE || ''}}">
            <mat-datepicker-toggle matSuffix [for]="SettlementDate"></mat-datepicker-toggle>
            <mat-datepicker #SettlementDate></mat-datepicker>
        <div  *ngIf="testBool && toolTipList?.SETTLEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList?.SETTLEMENTDATE?.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</form>