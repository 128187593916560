import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-property-details-expandation',
    templateUrl: './property-details-expandation.component.html',
    styleUrls: ['./property-details-expandation.component.scss']
})
export class PropertyDetailsExpandationComponent implements OnInit, OnDestroy {
    MatterPropertyDetailsForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};

    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    APIDataSubcription_2: Subscription
    APIDataSubcription_3: Subscription
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    ParishLookupData: any = [];
    LocalityLookupData: any = [];
    CouncilLookupsData: any = [];
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData();
    }

    /**
     * This function is used to init thr form data value.
     */
    initFormData() {
        this.MatterPropertyDetailsForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERPROPERTYGUID: [''],
            //Crown Allotment
            CROWNALLOTMENT: [''],
            CROWNBLOCK: [''],
            CROWNSECTION: [''],
            CROWNPORTION: [''],
            CROWNSUBDIVISION: [''],
            CROWNPARISH: [''],
            //Council
            SPI: [''],
            COUNCILPROPERTYNUMBER: [''],
            COUNCIL: [''],
            LOCALITY: [''],
            PARISH: [''],
            //Address
            ADDRESS1: [''],
            BUILDINGNAME: [''],
            UNITNUMBER: [''],
            STREETNUMBER: [''],
            STREETNUMBERTO: [''],
            STREETNAME: [''],
            STREETTYPE: [''],
            SUBURB: [''],
            STATE: [''],
            POSTCODE: [''],
            NATUREOFBUILDING: [''],
            //Title
            VOLUME: [''],
            FOLIOID: [''],
            LOTS: [''],
            PLANTYPE: [''],
            PLANNUMBER: [''],
            VOLUME2: [''],
            FOLIOID2: [''],
            LOT2: [''],
            PLANTYPE2: [''],
            PLANNUMBER2: [''],
            VOLUME3: [''],
            FOLIOID3: [''],
            LOT3: [''],
            PLANTYPE3: [''],
            PLANNUMBER3: [''],
            VOLUME4: [''],
            FOLIOID4: [''],
            LOT4: [''],
            PLANTYPE4: [''],
            PLANNUMBER4: [''],
            VOLUME5: [''],
            FOLIOID5: [''],
            LOT5: [''],
            PLANTYPE5: [''],
            PLANNUMBER5: [''],
            LOTSECTION: [''],
            BLOCK: [''],
            STAGENUMBER: [''],
            REDEVELOPMENTNUMBER: [''],
            LOTSTITLE: [''],

            // Parent Title
            PARENTVOLUME: [''],
            PARENTFOLIOID: [''],
            PARENTLOT: [''],
            PARENTPLANTYPE: [''],
            PARENTPLANNUMBER: [''],
            PARENTCROWNALLOTMENT: [''],
            PARENTCROWNBLOCK: [''],
            PARENTCROWNSECTION: [''],
            PARENTCROWNPORTION: [''],

            // Strata
            STRATAPLANNUMBER: [''],
            BYLAWTYPE: [''],
            BYLAWNO: [''],
            SPECIALRESOLUTIONDATE: [''],
            SPECIALRESOLUTIONDATETEXT: [''],
            AGGREGATIONOFENTITLEMENT: [''],
            TOTALUNITS: [''],
        });
    }
    
      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.APIDataSubcription_3 = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MatterPropertyDetailsForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                // classType 29 & 30 for business purchase & business Sale.
                if ((result.classType == "Compulsory Acquisition" || result.classType == "Strata" || result.classType == "Leasing" || result.classType == "Property Purchase" || result.classType == "Property Sale" || result.classType == "Business Sale" || result.classType == "Business Purchase") && result.action == 'setData') {
                    this.SaveMatterPropertyDetails();
                } else if ((result.classType == "Compulsory Acquisition" || result.classType == "Strata" || result.classType == "Leasing" || result.classType == "Property Purchase" || result.classType == "Property Sale" || result.classType == "Business Sale" || result.classType == "Business Purchase") && result.action == 'deleteData') {
                    this.DeleteMatterPropertyDetails();
                }
            }
        });

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data) {
                this.mapPropertyDetailsFields();
            }
        })

        if (this.action == "edit") {
            this.mapPropertyDetailsFields()
        }
    }

    ngOnDestroy(): void {
        this.APIDataSubcription_3?.unsubscribe();
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
        this.APIDataSubcription_2?.unsubscribe();

    }

    /**
     * This function is used to get the map property details
     */
    mapPropertyDetailsFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            forkJoin([
                this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Parish' } }, 'lookup'),
                this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Locality' } }, 'lookup'),
                this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Council' } }, 'lookup')
            ]).pipe(
                tap(([parish, locality, council]) => {

                    if (parish.CODE === 200 && parish.STATUS === 'success') {
                        this.ParishLookupData = parish.DATA.LOOKUPS;
                    } else if (parish.MESSAGE == 'Not logged in') {
                        this.dialogRef.close(false);
                    }

                    if (locality.CODE === 200 && locality.STATUS === 'success') {
                        this.LocalityLookupData = locality.DATA.LOOKUPS;
                    } else if (locality.MESSAGE == 'Not logged in') {
                        this.dialogRef.close(false);
                    }

                    if (council.CODE === 200 && council.STATUS === 'success') {
                        this.CouncilLookupsData = council.DATA.LOOKUPS;
                    } else if (council.MESSAGE == 'Not logged in') {
                        this.dialogRef.close(false);
                    }
                }),
                switchMap(() => this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-property')),
                tap(response => {
                    if (response.CODE == 200 && response.STATUS == 'success') {
                        let MatterPropertyDetailsData = response.DATA.RECORDS[0];

                        let _PARISH = Object.assign(this.ParishLookupData);
                        let _LOCALITY = Object.assign(this.LocalityLookupData);
                        let _COUNCIL = Object.assign(this.CouncilLookupsData);

                        let PARISH = _PARISH.filter((e) => e.LOOKUPFULLVALUE == MatterPropertyDetailsData.PARISH || e.LOOKUPGUID == MatterPropertyDetailsData.PARISH);
                        let COUNCIL = _COUNCIL.filter((e) => e.LOOKUPFULLVALUE == MatterPropertyDetailsData.COUNCIL || e.LOOKUPGUID == MatterPropertyDetailsData.COUNCIL);
                        let LOCALITY = _LOCALITY.filter((e) => e.LOOKUPFULLVALUE == MatterPropertyDetailsData.LOCALITY || e.LOOKUPGUID == MatterPropertyDetailsData.LOCALITY);

                        this.toolTipList = response.DATA.FIELDTIPS;
                        if (MatterPropertyDetailsData) {
                            this.MatterPropertyDetailsForm.patchValue({
                                MATTERGUID: MatterPropertyDetailsData.MATTERGUID,
                                MATTERPROPERTYGUID: MatterPropertyDetailsData.MATTERPROPERTYGUID,
                                //Crown Allotment
                                CROWNALLOTMENT: MatterPropertyDetailsData.CROWNALLOTMENT,
                                CROWNBLOCK: MatterPropertyDetailsData.CROWNBLOCK,
                                CROWNSECTION: MatterPropertyDetailsData.CROWNSECTION,
                                CROWNPORTION: MatterPropertyDetailsData.CROWNPORTION,
                                CROWNSUBDIVISION: MatterPropertyDetailsData.CROWNSUBDIVISION,
                                CROWNPARISH: MatterPropertyDetailsData.CROWNPARISH,
                                //Council
                                SPI: MatterPropertyDetailsData.SPI,
                                COUNCILPROPERTYNUMBER: MatterPropertyDetailsData.COUNCILPROPERTYNUMBER,
                                //Old
                                // COUNCIL: MatterPropertyDetailsData.COUNCIL,
                                // LOCALITY: MatterPropertyDetailsData.LOCALITY,
                                // PARISH: MatterPropertyDetailsData.PARISH,

                                //New
                                COUNCIL: (COUNCIL.length !== 0) ? COUNCIL[0].LOOKUPFULLVALUE : MatterPropertyDetailsData.COUNCIL,
                                LOCALITY: (LOCALITY.length !== 0) ? LOCALITY[0].LOOKUPFULLVALUE : MatterPropertyDetailsData.LOCALITY,
                                PARISH: (PARISH.length !== 0) ? PARISH[0].LOOKUPFULLVALUE : MatterPropertyDetailsData.PARISH,
                                //Address
                                ADDRESS1: MatterPropertyDetailsData.ADDRESS1,
                                BUILDINGNAME: MatterPropertyDetailsData.BUILDINGNAME,
                                UNITNUMBER: MatterPropertyDetailsData.UNITNUMBER,
                                STREETNUMBER: MatterPropertyDetailsData.STREETNUMBER,
                                STREETNUMBERTO: MatterPropertyDetailsData.STREETNUMBERTO,
                                STREETNAME: MatterPropertyDetailsData.STREETNAME,
                                STREETTYPE: MatterPropertyDetailsData.STREETTYPE,
                                SUBURB: MatterPropertyDetailsData.SUBURB,
                                STATE: MatterPropertyDetailsData.STATE,
                                POSTCODE: MatterPropertyDetailsData.POSTCODE,
                                NATUREOFBUILDING: MatterPropertyDetailsData.NATUREOFBUILDING,

                                //Title
                                VOLUME: MatterPropertyDetailsData.VOLUME,
                                FOLIOID: MatterPropertyDetailsData.FOLIOID,
                                LOTS: MatterPropertyDetailsData.LOTS,
                                PLANTYPE: MatterPropertyDetailsData.PLANTYPE,
                                PLANNUMBER: MatterPropertyDetailsData.PLANNUMBER,
                                VOLUME2: MatterPropertyDetailsData.VOLUME2,
                                FOLIOID2: MatterPropertyDetailsData.FOLIOID2,
                                LOT2: MatterPropertyDetailsData.LOT2,
                                PLANTYPE2: MatterPropertyDetailsData.PLANTYPE2,
                                PLANNUMBER2: MatterPropertyDetailsData.PLANNUMBER2,
                                VOLUME3: MatterPropertyDetailsData.VOLUME3,
                                FOLIOID3: MatterPropertyDetailsData.FOLIOID3,
                                LOT3: MatterPropertyDetailsData.LOT3,
                                PLANTYPE3: MatterPropertyDetailsData.PLANTYPE3,
                                PLANNUMBER3: MatterPropertyDetailsData.PLANNUMBER3,
                                VOLUME4: MatterPropertyDetailsData.VOLUME4,
                                FOLIOID4: MatterPropertyDetailsData.FOLIOID4,
                                LOT4: MatterPropertyDetailsData.LOT4,
                                PLANTYPE4: MatterPropertyDetailsData.PLANTYPE4,
                                PLANNUMBER4: MatterPropertyDetailsData.PLANNUMBER4,
                                VOLUME5: MatterPropertyDetailsData.VOLUME5,
                                FOLIOID5: MatterPropertyDetailsData.FOLIOID5,
                                LOT5: MatterPropertyDetailsData.LOT5,
                                PLANTYPE5: MatterPropertyDetailsData.PLANTYPE5,
                                PLANNUMBER5: MatterPropertyDetailsData.PLANNUMBER5,
                                LOTSECTION: MatterPropertyDetailsData.LOTSECTION,
                                BLOCK: MatterPropertyDetailsData.BLOCK,
                                STAGENUMBER: MatterPropertyDetailsData.STAGENUMBER,
                                REDEVELOPMENTNUMBER: MatterPropertyDetailsData.REDEVELOPMENTNUMBER,
                                LOTSTITLE: MatterPropertyDetailsData.LOTSTITLE,

                                // Parent Title
                                PARENTVOLUME: MatterPropertyDetailsData.PARENTVOLUME,
                                PARENTFOLIOID: MatterPropertyDetailsData.PARENTFOLIOID,
                                PARENTLOT: MatterPropertyDetailsData.PARENTLOT,
                                PARENTPLANTYPE: MatterPropertyDetailsData.PARENTPLANTYPE,
                                PARENTPLANNUMBER: MatterPropertyDetailsData.PARENTPLANNUMBER,
                                PARENTCROWNALLOTMENT: MatterPropertyDetailsData.PARENTCROWNALLOTMENT,
                                PARENTCROWNBLOCK: MatterPropertyDetailsData.PARENTCROWNBLOCK,
                                PARENTCROWNSECTION: MatterPropertyDetailsData.PARENTCROWNSECTION,
                                PARENTCROWNPORTION: MatterPropertyDetailsData.PARENTCROWNPORTION,

                                // Strata
                                STRATAPLANNUMBER: MatterPropertyDetailsData.STRATAPLANNUMBER,
                                BYLAWTYPE: MatterPropertyDetailsData.BYLAWTYPE,
                                BYLAWNO: MatterPropertyDetailsData.BYLAWNO,
                                SPECIALRESOLUTIONDATE: MatterPropertyDetailsData.SPECIALRESOLUTIONDATE,
                                AGGREGATIONOFENTITLEMENT: MatterPropertyDetailsData.AGGREGATIONOFENTITLEMENT,
                                TOTALUNITS: MatterPropertyDetailsData.TOTALUNITS,
                            });
                            if (MatterPropertyDetailsData.SPECIALRESOLUTIONDATE) {
                                let SplitedDate = MatterPropertyDetailsData.SPECIALRESOLUTIONDATE.split("/");
                                this.MatterPropertyDetailsForm.patchValue({
                                    SPECIALRESOLUTIONDATETEXT: new Date(SplitedDate[1] + '/' + SplitedDate[0] + '/' + SplitedDate[2])
                                });
                            }
                        }
                    }
                })
            ).subscribe();

            // Old
            // this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'MatterProperty').subscribe(response => {
            //   if (response.CODE == 200 && response.STATUS == 'success') {
            //     let MatterPropertyDetailsData = response.DATA.RECORDS[0];
            //     this.toolTipList = response.DATA.FIELDTIPS;
            //     if (MatterPropertyDetailsData) {
            //       this.MatterPropertyDetailsForm.patchValue({
            //         MATTERGUID: MatterPropertyDetailsData.MATTERGUID,
            //         MATTERPROPERTYGUID: MatterPropertyDetailsData.MATTERPROPERTYGUID,
            //         //Crown Allotment
            //         CROWNALLOTMENT: MatterPropertyDetailsData.CROWNALLOTMENT,
            //         CROWNBLOCK: MatterPropertyDetailsData.CROWNBLOCK,
            //         CROWNSECTION: MatterPropertyDetailsData.CROWNSECTION,
            //         CROWNPORTION: MatterPropertyDetailsData.CROWNPORTION,
            //         CROWNSUBDIVISION: MatterPropertyDetailsData.CROWNSUBDIVISION,
            //         CROWNPARISH: MatterPropertyDetailsData.CROWNPARISH,
            //         //Council
            //         SPI: MatterPropertyDetailsData.SPI,
            //         COUNCILPROPERTYNUMBER: MatterPropertyDetailsData.COUNCILPROPERTYNUMBER,
            //         COUNCIL: MatterPropertyDetailsData.COUNCIL,
            //         LOCALITY: MatterPropertyDetailsData.LOCALITY,
            //         PARISH: MatterPropertyDetailsData.PARISH,
            //         //Address
            //         BUILDINGNAME: MatterPropertyDetailsData.BUILDINGNAME,
            //         UNITNUMBER: MatterPropertyDetailsData.UNITNUMBER,
            //         STREETNUMBER: MatterPropertyDetailsData.STREETNUMBER,
            //         STREETNUMBERTO: MatterPropertyDetailsData.STREETNUMBERTO,
            //         STREETNAME: MatterPropertyDetailsData.STREETNAME,
            //         STREETTYPE: MatterPropertyDetailsData.STREETTYPE,
            //         SUBURB: MatterPropertyDetailsData.SUBURB,
            //         STATE: MatterPropertyDetailsData.STATE,
            //         POSTCODE: MatterPropertyDetailsData.POSTCODE,
            //         NATUREOFBUILDING: MatterPropertyDetailsData.NATUREOFBUILDING,

            //         //Title
            //         VOLUME: MatterPropertyDetailsData.VOLUME,
            //         FOLIOID: MatterPropertyDetailsData.FOLIOID,
            //         LOTS: MatterPropertyDetailsData.LOTS,
            //         PLANTYPE: MatterPropertyDetailsData.PLANTYPE,
            //         PLANNUMBER: MatterPropertyDetailsData.PLANNUMBER,
            //         VOLUME2: MatterPropertyDetailsData.VOLUME2,
            //         FOLIOID2: MatterPropertyDetailsData.FOLIOID2,
            //         LOT2: MatterPropertyDetailsData.LOT2,
            //         PLANTYPE2: MatterPropertyDetailsData.PLANTYPE2,
            //         PLANNUMBER2: MatterPropertyDetailsData.PLANNUMBER2,
            //         VOLUME3: MatterPropertyDetailsData.VOLUME3,
            //         FOLIOID3: MatterPropertyDetailsData.FOLIOID3,
            //         LOT3: MatterPropertyDetailsData.LOT3,
            //         PLANTYPE3: MatterPropertyDetailsData.PLANTYPE3,
            //         PLANNUMBER3: MatterPropertyDetailsData.PLANNUMBER3,
            //         VOLUME4: MatterPropertyDetailsData.VOLUME4,
            //         FOLIOID4: MatterPropertyDetailsData.FOLIOID4,
            //         LOT4: MatterPropertyDetailsData.LOT4,
            //         PLANTYPE4: MatterPropertyDetailsData.PLANTYPE4,
            //         PLANNUMBER4: MatterPropertyDetailsData.PLANNUMBER4,
            //         VOLUME5: MatterPropertyDetailsData.VOLUME5,
            //         FOLIOID5: MatterPropertyDetailsData.FOLIOID5,
            //         LOT5: MatterPropertyDetailsData.LOT5,
            //         PLANTYPE5: MatterPropertyDetailsData.PLANTYPE5,
            //         PLANNUMBER5: MatterPropertyDetailsData.PLANNUMBER5,
            //         LOTSECTION: MatterPropertyDetailsData.LOTSECTION,
            //         BLOCK: MatterPropertyDetailsData.BLOCK,
            //         STAGENUMBER: MatterPropertyDetailsData.STAGENUMBER,
            //         REDEVELOPMENTNUMBER: MatterPropertyDetailsData.REDEVELOPMENTNUMBER,
            //         LOTSTITLE: MatterPropertyDetailsData.LOTSTITLE,

            //         // Parent Title
            //         PARENTVOLUME: MatterPropertyDetailsData.PARENTVOLUME,
            //         PARENTFOLIOID: MatterPropertyDetailsData.PARENTFOLIOID,
            //         PARENTLOT: MatterPropertyDetailsData.PARENTLOT,
            //         PARENTPLANTYPE: MatterPropertyDetailsData.PARENTPLANTYPE,
            //         PARENTPLANNUMBER: MatterPropertyDetailsData.PARENTPLANNUMBER,
            //         PARENTCROWNALLOTMENT: MatterPropertyDetailsData.PARENTCROWNALLOTMENT,
            //         PARENTCROWNBLOCK: MatterPropertyDetailsData.PARENTCROWNBLOCK,
            //         PARENTCROWNSECTION: MatterPropertyDetailsData.PARENTCROWNSECTION,
            //         PARENTCROWNPORTION: MatterPropertyDetailsData.PARENTCROWNPORTION,

            //         // Strata
            //         STRATAPLANNUMBER: MatterPropertyDetailsData.STRATAPLANNUMBER,
            //         BYLAWTYPE: MatterPropertyDetailsData.BYLAWTYPE,
            //         BYLAWNO: MatterPropertyDetailsData.BYLAWNO,
            //         SPECIALRESOLUTIONDATE: MatterPropertyDetailsData.SPECIALRESOLUTIONDATE,
            //         AGGREGATIONOFENTITLEMENT: MatterPropertyDetailsData.AGGREGATIONOFENTITLEMENT,
            //         TOTALUNITS: MatterPropertyDetailsData.TOTALUNITS,
            //       });
            //       if (MatterPropertyDetailsData.SPECIALRESOLUTIONDATE) {
            //         let SplitedDate = MatterPropertyDetailsData.SPECIALRESOLUTIONDATE.split("/");
            //         this.MatterPropertyDetailsForm.patchValue({
            //           SPECIALRESOLUTIONDATETEXT: new Date(SplitedDate[1] + '/' + SplitedDate[0] + '/' + SplitedDate[2])
            //         });
            //       }
            //     }
            //   }
            // });
        }
    }

    /**
     * This funtion is used delete the matter property matter data.
     */
    DeleteMatterPropertyDetails() {
        this.APIDataSubcription = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-property').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterPropertyDetailsData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERPROPERTYGUID: MatterPropertyDetailsData.MATTERPROPERTYGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-property').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

    /**
     * This function is used to save Matter Property Details
     */
    SaveMatterPropertyDetails() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MatterPropertyDetailsForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERPROPERTYGUID;
        }
        delete formData.SPECIALRESOLUTIONDATETEXT;
        delete formData.LOTSTITLE;
        delete formData.ADDRESS1;
        const formAction = (formData.MATTERPROPERTYGUID) ? 'Update' : 'Insert';
        const MatterPropertyDetailsData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };

        this._mainAPiServiceService.getSetData(MatterPropertyDetailsData, 'matter-property').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterPropertyDetailsData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterPropertyDetailsData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterPropertyDetailsData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation data value.
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMatterPropertyDetailsAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMatterPropertyDetailsAfterVAlidation(details);

        }
    }

    /**
     * This function is used to Save the matter property details data value.
     */
    SaveMatterPropertyDetailsAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.APIDataSubcription_2 = this._mainAPiServiceService.getSetData(data, 'matter-property').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to get the text data value.
     */
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }


}
