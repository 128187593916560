import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ContactCorresDetailsComponent } from '../../../contact/contact-corres-details/contact-corres-details.component';
import * as $ from 'jquery';
import { SendEmailComponent } from '../../email-templete/send-email/send-email.component';
import { SelectEmailPopupComponent } from '../select-email-popup/select-email-popup.component';
import { Router } from '@angular/router';
import { MatterDialogComponentForTemplate } from '../../matter-dialog/matter-dialog.component';
import { MatterFolderDailogComponent } from 'src/app/layout/components/toolbar/matter-folder-dailog/matter-folder-dailog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SocketService } from '@_services/socket.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-generate-packs-popup',
    templateUrl: './generate-packs-popup.component.html',
    styleUrls: ['./generate-packs-popup.component.scss']
})
export class GeneratePacksPopupComponent implements OnInit, OnDestroy {
    btnText = '';
    progress: any = 5;
    isLoadingResults: boolean = false;
    IsAuth: boolean = false;
    filefolder_Name: any;
    base_url: any;
    whichTypeTemplate: string;
    fileExtention: any;
    isGenerating: boolean = false;
    isPDFEmailLoadingResults: boolean = false;
    isPDFLoadingResults: boolean = false;
    arguParam: any;
    // webSocket = new WebSocket(environment.MainSocketUrl);
    PacksEmail: any = [];
    PacksDocument: any = [];
    ApiRegRetryparam1: any;
    ApiRegRetryparam2: any;
    documentDataIndex: any = 99999;
    xFxaxVal: any = 55;
    yflaxCal: any = 45;
    filefolderUrlDes: any;
    SafeCustodyData: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    windowNameId: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        public dialog: MatDialog,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        public dialogRef: MatDialogRef<GeneratePacksPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private router: Router,
        private sanitizer: DomSanitizer,
        private socketService: SocketService
        // private data:TemplateComponent
    ) {
        // this.socketService.openWSConnection(this.currentUser.SessionToken, 'TemplateGenerateStatus').pipe(takeUntil(this._unsubscribeAll$))
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        this.socketService.getWebSocketDocumentGenerationSubject().subscribe((event) => {
            if (event) {
                this.progress = event.PROGRESS;
                this.btnText = event.DESCRIPTION;
            }
        })
        localStorage.setItem('istrackid', 'GeneratePacksPopupComponent');
        this.base_url = environment.DocumentsUrl;
        if (_data) {
            this.filefolder_Name = _data.nameShow ? _data.nameShow : _data.Template;
            this.whichTypeTemplate = _data.knownby;
            this.isGenerating = true;
            // this.openWSConnection(this.currentUser.SessionToken);
            this.selectMatter(_data, '');
        }
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'GenerateDocumentPopupComponent' && result['click'] && result['data']['TemplateGenerate']) {
                // this.openWSConnection(this.currentUser.SessionToken);
                this.selectMatter(this.ApiRegRetryparam1, this.ApiRegRetryparam2);
            }
        });
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to send Main data
     */
    SendMain(val) {
        const dialogRef = this.dialog.open(SendEmailComponent, {
            disableClose: true, panelClass: '', data: { data: val }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close();
            }
        });
    }

    /**
     * This function is used to sanitize the url 
     */
    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    /**
     * This function is used to select the matter
     */
    async selectMatter(ReportData: any, fval) {
        // await this.socketService.openSocket();
        this.IsAuth = false;
        delete ReportData["knownby"];
        this.isLoadingResults = true;
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketDocumentGeneration(uniqueId);
        if (fval != '') {
            ReportData.CHECKFORPROMPTS = false;
        } else {
            ReportData.CHECKFORPROMPTS = true;
        }
        ReportData.TrackingGuid = uniqueId;
        this._mainAPiServiceService.SetDocuments(ReportData, 'TemplateGenerate').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (this._data.Type == "Pack") {
                    this.toastr.success('PackGenerate successfully');
                    this.PacksEmail = response.DATA.EMAILS;
                    this.PacksDocument = response.DATA.DOCUMENTS;
                }
                if (this.PacksEmail.length == 1) {
                    this.SendMain(this.PacksEmail[0]);
                }
                // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " TemplateGenerateStatus");
                // this.webSocket.close();
                this.isLoadingResults = false;
                this.isGenerating = false;
                $('#refreshWorkInprogress').click();
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                // this.socketService.closeSocket(this.currentUser.SessionToken, "TemplateGenerateStatus");
            } else if (response.CODE == 420) {
                this.isLoadingResults = false;
                this.dialogRef.close();
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.CODE == 428) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                this.arguParam = '';
                ReportData.CHECKFORPROMPTS = true;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetEmail"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 432) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                this.arguParam = '';
                ReportData.CHECKFORPROMPTS = true;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 431) {
                this.IsAuth = true;
                let currentMatter: any;
                // this.behaviorService.MatterData$.subscribe(result => {
                //   if (result) { currentMatter = result; }
                // });
                this.behaviorService.MatterData$.subscribe(result => {
                    const materIDofTab = window.name.split("_");
                    const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                    currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                    if (currentMatter) {
                    } else {
                        if (result) {
                            currentMatter = result;
                            localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                        }
                    }
                });
                let currentInvoiceMatter: any;
                this.behaviorService.matterInvoice$.subscribe(result => {
                    if (result) { currentInvoiceMatter = result; }
                });
                let MatterGuid = (ReportData.Context == "Invoice" && this.router.url == "/invoice/sub-invoices") ? currentInvoiceMatter.MATTERGUID : ReportData.Context == "Invoice" ? currentMatter.MATTERGUID : ReportData.ContextGuid;
                const dialogRefMC = this.dialog.open(ContactCorresDetailsComponent, {
                    disableClose: true, width: '100%', data: { ContextGuid: MatterGuid, 'callType': 'select', isTemplate: 'Template' },
                });
                dialogRefMC.afterClosed().subscribe(result => {
                    if (result) {
                        ReportData.CHECKFORPROMPTS = false;
                        ReportData.ContactGuid = result.PERSONGUID;
                        this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
                        this.selectMatter(ReportData, 'false');
                    } else {
                        this.isLoadingResults = false;
                    }
                });
                this.isLoadingResults = false;
            } else if (response.STATUS == 'error' || response.CODE == '422') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            } else if (response.MESSAGE == 'Not logged in') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
                this.socketService.disconnectWebSocketDocumentGeneration(uniqueId);
            }
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
        });
    }
    // openWSConnection(SessionToken: any) {
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " TemplateGenerateStatus");
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //     };
    //     demoSoket.onerror = (errorEvent) => {
    //     };
    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }

    /**
     * This function is used to convert the pdf data
     */
    convertToPDF(indexD: any, ismail: any) {
        let documentData = this.PacksDocument[indexD];
        if (ismail) {
            this.isPDFEmailLoadingResults = true;
        } else {
            this.isPDFLoadingResults = true;
        }
        let postData: any = {
            DOCUMENTLIST: [{ DOCUMENTGUID: documentData.DOCUMENTGUID, DOCUMENTPATH: documentData.DOWNLOAD }],
        };
        if (this.router.url == "/create-document/matter-template" || this.router.url == "/create-document/packs-matter-template") {
            let currentMatter: any;
            // this.behaviorService.MatterData$.subscribe(result => {
            //   if (result) { currentMatter = result; }
            // });
            this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                const materIDofTab = window.name.split("_");
                const windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
                currentMatter = JSON.parse(localStorage.getItem(windowNameId ? windowNameId : 'set_active_matters'));
                if (currentMatter) {
                } else {
                    if (result) {
                        currentMatter = result;
                        localStorage.setItem(windowNameId || 'set_active_matters', JSON.stringify(currentMatter))
                    }
                }
            });
            postData.MATTERGUID = currentMatter.MATTERGUID;
        }
        this._mainAPiServiceService.getSetData({ VALIDATEONLY: false, Data: postData }, 'NetDriveConvertToPDF').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toastr.success("PDF generate successfully");
                let pdfData: any = response.DATA.DOCUMENTS[0];
                pdfData.isPdf = true;
                let tempPak: any = [];
                this.PacksDocument.forEach((element, index) => {
                    tempPak.push(element);
                    if (indexD == index) {
                        tempPak.push(pdfData);
                    }
                });
                this.PacksDocument = tempPak;
                this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DOCUMENTNAME;
                if (ismail) {
                    this.ToEmailthedocument(indexD + 1);
                }
            } else if (response.CODE == 432 || response.CODE == '432') {
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 428) {
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetEmail"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == 447 || response.CODE == '447') {
                this.toastr.error(response.MESSAGE);
                this.isLoadingResults = false
                this.isGenerating = false;
                this.dialogRef.close();
            }
            if (ismail) {
                this.isPDFEmailLoadingResults = false;
            } else {
                this.isPDFLoadingResults = false;
            }
        }, error => {
            if (ismail) {
                this.isPDFEmailLoadingResults = false;
            } else {
                this.isPDFLoadingResults = false;
            }
            this.toastr.error(error);
            this.dialogRef.close();
        });
    }

    /**
     * This function is used to to Emailge the document
     */
    ToEmailthedocument(index: any) {
        let documentData = this.PacksDocument[index];
        let DocumentData: any = { DOCUMENTGUID: documentData.DOCUMENTGUID };
        let DocumentName: any = { name: documentData.ORIGFILENAME, index: 9999, };
        const dialogRef = this.dialog.open(SelectEmailPopupComponent, { width: '100%', disableClose: true });
        this.behaviorService.SafeCustody$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.SafeCustodyData = result;
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let EmailtemplateData: any = '';
                this.behaviorService.EmailGenerateData$.subscribe(result => {
                    if (result) {
                        EmailtemplateData = result;
                    }
                });
                if (this.router.url == "/create-document/invoice-template" || this.router.url == "/create-document/packs-invoice-template" || this.router.url == "/invoice/sub-invoices" || this.router.url == "/time-billing/matter-invoices") {
                    let invoiceGUid = localStorage.getItem('edit_invoice_id');
                    let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'invoice' }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/create-document/matter-template" || this.router.url == "/create-document/packs-matter-template" || this.router.url == "/matters") {
                    let matterData = JSON.parse(localStorage.getItem('set_active_matters'));
                    let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'matter' }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/create-document/receive-money-template" || this.router.url == "/create-document/packs-receive-money-template" || this.router.url == "/receive-money" || this.router.url == "/time-billing/receipts-credits") {
                    let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
                    let passdata = { 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/create-document/contact-template" || this.router.url == "/create-document/packs-contact-template") {
                    let ContactGuID = localStorage.getItem('contactGuid');
                    let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                } else if (this.router.url == "/create-document/email-safe-custody-template" || this.router.url == "/create-document/packs-safe-custody-template") {
                    let passdata = { 'Context': "Safe Custody", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName }
                    this.ForEmailDialogOpen(passdata);
                }

            }
        });
    }

    /**
     * This function is used to open the Email Dialog
     */
    ForEmailDialogOpen(passdata) {
        const dialogRef = this.dialog.open(MatterDialogComponentForTemplate, { width: '100%', disableClose: true, data: passdata });
        dialogRef.afterClosed().subscribe(result => { });
    }

    /**
     * This function is used to convert the pdf to email
     */
    ToPDFAndEmail(index: any) {
        let documentData = this.PacksDocument[index];
        let exten = documentData.ORIGFILENAME.substr(documentData.ORIGFILENAME.lastIndexOf('.') + 1);
        if (exten == 'pdf') {
            this.ToEmailthedocument(index);
        } else {
            this.convertToPDF(index, true);
        }
    }

    /**
     * This function is used to Rename the file
     */
    RenameFile() {
        let documentData = this.PacksDocument[this.documentDataIndex];
        this.isLoadingResults = true;
        this.fileExtention = documentData.ORIGFILENAME.substr(documentData.ORIGFILENAME.lastIndexOf('.') + 1);
        let newName: any = documentData.NEWFILENAME.replace(/\.[^/.]+$/, "");
        this._mainAPiServiceService.getSetData({ DOCUMENTGUID: documentData.DOCUMENTGUID, NEWDOCUMENTNAME: newName + '.' + this.fileExtention }, 'NetDriveRenameDocument').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toastr.success("Renamed successfully");
                this.xFxaxVal = 55;
                this.yflaxCal = 45;
                this.PacksDocument[this.documentDataIndex] = response.DATA.DOCUMENTS[0];
                this.filefolderUrlDes = response.DATA.DOCUMENTS[0].DOCUMENTNAME;
                this.documentDataIndex = 99999;
            } else if (response.CODE == 432 || response.CODE == '432') {
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            }
            this.isLoadingResults = false;
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
        });
    }

    /**
     * This function is used to 
     */
    RenameFileData(index) {
        let documentData = this.PacksDocument[index];
        let dialogRef: any = this.dialog.open(MatterFolderDailogComponent, {
            width: '100%', data: { docData: documentData, type: 'rename', isPopup: true }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.PacksDocument[index] = result.DOCUMENTS[0];
                this.filefolderUrlDes = result.DOCUMENTS[0].DOCUMENTNAME;
                this.documentDataIndex = 99999;
            }
        });
    }

}
