import { fuseAnimations } from 'src/@fuse/animations';
import { SortingDialogComponent } from 'src/app/main/sorting-dialog/sorting-dialog.component';
import {Sort} from '@angular/material/sort';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ElementRef,
    HostListener,
    Renderer2,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { ContactDialogComponent } from "./contact-dialog/contact-dialog.component";

import { ResizeEvent } from "angular-resizable-element";
import * as moment from "moment";
import { FilterSearchOptions } from "src/app/_constant/dynamic-search-option.const";
import { MatSelect } from "@angular/material/select";
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
    resizableMousemove: () => void;
  resizableMouseup: () => void;
    highlightedRows: any;
    ColumnsObj = [];
    dateColFilter = [];
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    contectTitle = this.theme_type == "theme-default" ? "Solicitor" : "Client";
    displayedColumns: string[];
    tempColobj: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    datanull: null;
    isLoadingResults: boolean = false;
    contactFilter: FormGroup;
    pageSize: any;
    Contactdata;
    selectedVal: any;
    isDisplay: boolean = false;
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    sortingDefaultState: any = {};
    sortactive: any;
    sortDirection: any;
    isGetallData: any = false;
    filterVals: any = {
        active: "",
        FirstLetter: "a",
        SEARCH: "",
        ContactType: "",
    };
    sortingMatterState: any = {};
    isCallDownloadFunction: boolean = false;
    activeOptionalFilter: any;
    optionalFilterList = [];
    activeFilters: any;
    clearFilters = false;
    contacttypevalue: any;
    FILTERS: FormArray;
    FILTERSLIST: FormArray;
    _filterList = [];
    addFilter;
    selectedFilterIs: any;
    FirstLettervalue: any;
    searchvalue: any;
    optionalDateFilterPayload = [];
    optionalFilters = [];
    optionalFilterAppply = true;
    favoriteSeason: string;
    saveAdvanceFilter = [];

    totalApplicableFilterCount: number = 0;
    detectedChangeRef = Math.random();
    private _unsubscribeAll$: Subject<void> = new Subject();

    @ViewChild("searchField", { static: false })
    searchField: ElementRef<HTMLInputElement>;
    seasons = [
        {
            data: "All Contacts",
            id: "all",
        },
        {
            data: "Active Contacts",
            id: "1",
        },
        {
            data: "Inactive Contacts",
            id: "0",
        },
    ];
    contact = [
        {
            data: "All Contacts",
            id: "all",
        },
        {
            data: "People",
            id: "People",
        },
        {
            data: "Company",
            id: "Company",
        },
        {
            data: "Party",
            id: "Party",
        },
        {
            data: "Payee",
            id: "Payee",
        },
        {
            data: "Person",
            id: "Person",
        },
        {
            data: "Payee/Payor",
            id: "Payee/Payor",
        },
        {
            data: "Multi Person",
            id: "Multi Person",
        },
    ];

    ListofLetter = [
        {
            data: "All",
            id: "All",
        },
        {
            data: "A",
            id: "A",
        },
        {
            data: "B",
            id: "B",
        },
        {
            data: "C",
            id: "C",
        },
        {
            data: "D",
            id: "D",
        },
        {
            data: "E",
            id: "E",
        },
        {
            data: "F",
            id: "F",
        },
        {
            data: "G",
            id: "G",
        },
        {
            data: "H",
            id: "H",
        },
        {
            data: "I",
            id: "I",
        },
        {
            data: "J",
            id: "J",
        },
        {
            data: "K",
            id: "K",
        },
        {
            data: "L",
            id: "L",
        },
        {
            data: "M",
            id: "M",
        },
        {
            data: "N",
            id: "N",
        },
        {
            data: "O",
            id: "O",
        },
        ,
        {
            data: "P",
            id: "P",
        },
        {
            data: "Q",
            id: "Q",
        },
        {
            data: "R",
            id: "R",
        },
        {
            data: "S",
            id: "S",
        },
        {
            data: "T",
            id: "T",
        },
        {
            data: "U",
            id: "U",
        },
        {
            data: "V",
            id: "V",
        },
        {
            data: "W",
            id: "W",
        },
        {
            data: "X",
            id: "X",
        },
        {
            data: "Y",
            id: "Y",
        },
        {
            data: "Z",
            id: "Z",
        },
    ];
    showFiltersList: boolean = true;
    COLOR: any;
    class: any;
    GloablePayload: { ACTION: string; FILTERS: any; AdvancedFilters: any; };

    constructor(
        private dialog: MatDialog,
        private TableColumnsService: TableColumnsService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        private _mainAPiServiceService: MainAPiServiceService,
        private cd: ChangeDetectorRef,
        private behaviorService: BehaviorService,
        private SortingbehaviorService: SortingBehaviourService,
        private cdf: ChangeDetectorRef,private hyperlink:HyperlinkNavigationService
        ,
        private renderer: Renderer2
    ) {
        localStorage.setItem("istrackid", "ContactScreenComponent");
        this.SortingbehaviorService.contactScreenSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (result) => {
                if (result) {
                    this.sortingDefaultState = result;
                    localStorage.setItem(
                        "contact_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingDefaultState = JSON.parse(
                        localStorage.getItem("contact_screen_sorting")
                    );
                }
            }
        );

        this.addFilter = {
            OPERATION: "",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: "",
        };

        this.SortingbehaviorService.contactFilterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.filterVals = result;
            }
        });

        this.contactFilter = this._formBuilder.group({
            ContactType: [""],
            active: ["all"],
            FirstLetter: [""],
            search: [""],
            FILTERS: new FormArray([]),
            FILTERSLIST: new FormArray([]),
            MAINFILTER: [""],
        });
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (
                localStorage.getItem("istrackid") == "ContactScreenComponent" &&
                result["click"] &&
                result["data"]["contact"]
            ) {
                if (!localStorage.getItem("contact_list_columns")) {
                    this.getTableFilter();
                    this.getFilter();
                }
                const advanceFilterPayload = this.loadAdvanceFilterPayload();
                // this.filterVals.AdvancedFilters = advanceFilterPayload ;
                if (
                    advanceFilterPayload == "" ||
                    advanceFilterPayload == undefined ||
                    advanceFilterPayload == null
                ) {
                    delete this.filterVals.AdvancedFilters;
                } else {
                    this.filterVals.AdvancedFilters = advanceFilterPayload;
                }
                this.LoadData(this.filterVals);
            }
        });
        this.hyperlink.setColorSetting();
        this.hyperlink.setColorSettingWithHyperlink()
        this.behaviorService.ColorSystemSetting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            this.COLOR=data
        })


        this.behaviorService.loaderDATA$.subscribe(data=>{
            this.isLoadingResults=data;
            this.cdf.detectChanges();
        })
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        this.behaviorService.permissionCheckforHeight$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            this.class=data
           })

        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        }
        this.getTableFilter();
        this.getFilter();
        if (this.filterVals) {
            this.contactFilter.patchValue({
                active:
                    this.filterVals.active == ""
                        ? "all"
                        : this.filterVals.active,
                ContactType:
                    this.filterVals.ContactType == ""
                        ? "all"
                        : this.filterVals.ContactType,
                FirstLetter:
                    this.filterVals.FirstLetter == ""
                        ? "all"
                        : this.filterVals.FirstLetter,
                search: this.filterVals.SEARCH ? this.filterVals.SEARCH : "",
            });
        } else {
            this.filterVals = {
                active: "",
                FirstLetter: "a",
                SEARCH: "",
                ContactType: "",
            };
            this.contactFilter.patchValue({
                active: "all",
                ContactType: "all",
                FirstLetter: "a",
            });
        }
        if (this.currentUser.initialwindow == "Trust") {
            this.contactFilter.patchValue({
                active: "all",
                ContactType: "all",
                FirstLetter: "all",
            });
            this.filterVals.active = "";
            this.filterVals.ContactType = "";
            this.filterVals.FirstLetter = "";
        }
        this.SortingbehaviorService.setContactFilterData(this.filterVals);

        // this.SortingbehaviorService.matterScreenSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
        //     if (result) {
        //         this.sortingMatterState = result;
        //         localStorage.setItem(
        //             "matter_screen_sorting",
        //             JSON.stringify(result)
        //         );
        //     } else {
        //         this.sortingMatterState = JSON.parse(
        //             localStorage.getItem("matter_screen_sorting")
        //         );
        //     }
        // });

        if (this.currentUser && this.currentUser.SORTBYSURNAME) {
            this.sortactive = "CONTACTNAME";
            this.sortDirection = "asc";
        } else {
            this.sortactive = "CONTACTNAME";
            this.sortDirection = "asc";
        }
        // if (this.sortingMatterState) {
        //     this.sortactive = this.sortingMatterState.active;
        //     this.sortDirection = this.sortingMatterState.direction;
        // }
        const advanceFilterPayload = this.loadAdvanceFilterPayload();
        //this.filterVals.AdvancedFilters = advanceFilterPayload;
        if (
            advanceFilterPayload == "" ||
            advanceFilterPayload == undefined ||
            advanceFilterPayload == null
        ) {
            delete this.filterVals.AdvancedFilters;
        } else {
            this.filterVals.AdvancedFilters = advanceFilterPayload;
        }
        this.LoadData(this.filterVals);
        this.setDefaultAdvanceFilter();
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
        this.behaviorService.resizeTableForAllView();
        const behaviorService = this.behaviorService;
        $(window).resize(function () {
            behaviorService.resizeTableForAllView();
        });
        this.cdf.detectChanges();

        $("content").addClass("inner-scroll");
        $(".example-containerdata").css(
            "height",
            $(window).height() -
                ($("#filter-form-menu-wrap").height() + 265) +
                "px"
        );
        $(window).resize(function () {
            $(".example-containerdata").css(
                "height",
                $(window).height() -
                    ($("#filter-form-menu-wrap").height() + 265) +
                    "px"
            );
        });
    }

    /**
     * This function is used to Change the Pagination
     * @param event - event value
     */
    onPaginateChange(event):void {
        this.setDefaultWidth(this.displayedColumns, 0);
        this.pageSize.contact_page = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to get table filter data
     */
    getTableFilter():void {
        let contactListColumns: any = JSON.parse(
            localStorage.getItem("contact_list_columns")
        );
        if (contactListColumns && contactListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(
                contactListColumns.ColumnsObj
            );
            this.tempColobj = data.tempColobj;
            this.displayedColumns = contactListColumns.displayedColumns;
            this.ColumnsObj = contactListColumns.ColumnsObj;
            this.dateColFilter = data.dateCol;
            localStorage.setItem('contactjustification',JSON.stringify(data?.colobj));
        } else {
            this.TableColumnsService.getTableFilter("contacts", "").pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        let data = this.TableColumnsService.filtertableColum(
                            response.DATA.RECORDS
                        );
                        this.tempColobj = data.tempColobj;
                        this.displayedColumns = data.showcol;
                        this.ColumnsObj = data.colobj;
                        localStorage.setItem('contactjustification',JSON.stringify(data?.colobj));
                        this.dateColFilter = data.dateCol;
                        localStorage.setItem(
                            "contact_list_columns",
                            JSON.stringify({
                                ColumnsObj: data.colobj,
                                displayedColumns: data.showcol,
                            })
                        );
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
        }
    }
    
    /**
     * This function is used to on Resize end
     * @param event -event data
     * @param columnName -column name
     */
  onResizeEnd(event: ResizeEvent, columnName): void {
    this.TableColumnsService.SaveWidthData(this.ColumnsObj, 'contacts', '');
   
}

/**
 * This function is used to onResizing data value.
 * @param event -event data value
 * @param columnName -column name
 */
onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(
                "mat-column-" + columnName
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(
                (col) => col.COLUMNID === columnName
            );
            this.ColumnsObj[indexData]["WIDTH"] = event.rectangle.width;
            localStorage.setItem(
                "contact_list_columns",
                JSON.stringify({
                    ColumnsObj: this.ColumnsObj,
                    displayedColumns: this.displayedColumns,
                })
            );
           
        }
        
    }

    /**
     * This function is used to set default width
     * @param displayedColumns -displayed columns
     * @param timeout -timeout value
     */
    setDefaultWidth(displayedColumns, timeout):void {
        setTimeout(() => {
            displayedColumns.forEach((element) => {
                let temWidth = this.tempColobj[element]["WIDTH"];
                const cssValue = temWidth + "px";
                const columnElts = document.getElementsByClassName(
                    "mat-column-" + element
                );
                for (let i = 0; i < columnElts.length; i++) {
                    const currentEl = columnElts[i] as HTMLDivElement;
                    currentEl.style.visibility = "inherit";
                    currentEl.style.width = cssValue;
                }
            });
        }, timeout);
    }
    get f() {
        return this.contactFilter.controls;
    }

    /**
     * This function is used to refresh the Contact tab data
     * @param isAllData -isAll data.
     */
    refreshContactTabData(isAllData: any):void {  
        this.filterVals = JSON.parse(localStorage.getItem("contact_Filter"));
        if (isAllData) {
            this.filterVals.GETALLDATA = this.isGetallData;
        }
        // this.LoadData(this.filterVals);
        this.addOptionalFilter(this.filterVals);
        localStorage.setItem("contact_Filter", JSON.stringify(this.filterVals));
    }

    /**
     * This function is used to load the Advanced filter payload
     * @returns -data
     */
    loadAdvanceFilterPayload() {
        let PayloadForAdvFilter = JSON.parse(
            localStorage.getItem("Contact_Advance_Filter")
        );
        if (PayloadForAdvFilter) {
            return PayloadForAdvFilter.map((advFilterData) => {
                if (advFilterData && advFilterData.FilterHeadings) {
                    delete advFilterData.FilterHeadings.value.COLUMNID;
                    return advFilterData.FilterHeadings.value;
                }
            });
        } else {
            return null;
        }
    }

    /**
     * This function ios used to get the main data
     * @param data -data value
     */
    LoadData(data) {
        // if(data.active=='All Contacts'){
        //   data.active=''
        // }
        const AdvancedFilters = data.AdvancedFilters ? data.AdvancedFilters : []; 
        const advanceFilterPayload = this.loadAdvanceFilterPayload();
        let Payload = {
            "ACTION": "GetData",
            FILTERS: data,
            AdvancedFilters: AdvancedFilters
        }
        if(AdvancedFilters.length == 0){
            delete Payload.AdvancedFilters
        }
        delete Payload.FILTERS.AdvancedFilters;
        this.Contactdata = [];
        this.isLoadingResults = true;
        this.GloablePayload=Payload;
        this._mainAPiServiceService.getSetData(Payload, "contact").pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.Contactdata = new MatTableDataSource(
                        response.DATA.RECORDS
                    );
                    // if (this.currentUser && this.currentUser.SORTBYSURNAME) {
                    //   this.Contactdata.data.sort((a, b) => a['CONTACTNAMESORT'] - b['CONTACTNAMESORT']);
                    // }
                    this.Contactdata.paginator = this.paginator;
                    this.behaviorService.contactData(null);
                    if (response.DATA.RECORDS[0]) {
                        this.behaviorService.contactData(
                            response.DATA.RECORDS[0]
                        );
                        localStorage.setItem(
                            "contactGuid",
                            response.DATA.RECORDS[0].CONTACTGUID
                        );
                        // localStorage.setItem('contactData',  JSON.stringify(response.DATA.RECORDS[0]));
                        this.isDisplay = false;
                        this.highlightedRows =
                            response.DATA.RECORDS[0].CONTACTGUID;
                    } else {
                        this.isDisplay = true;
                    }
                    this.isGetallData = response.DATA.TOOMANYRECORDS;
                    this.sortingDate();
                    this.isLoadingResults = false;
                    setTimeout(() => {
                        this.setDefaultWidth(this.displayedColumns, 500);
                    }, 1000);

                    if (this.isCallDownloadFunction == true) {
                        setTimeout(() => {
                            this.downloadFile();
                            this.isCallDownloadFunction = false;
                        }, 1000);
                    }
                } else if (
                    response.CODE == 406 &&
                    response.MESSAGE == "Permission denied"
                ) {
                    this.Contactdata = new MatTableDataSource([]);
                    this.Contactdata.paginator = this.paginator;
                    this.Contactdata.sort = this.sort;
                    this.isLoadingResults = false;
                    setTimeout(() => {
                        this.setDefaultWidth(this.displayedColumns, 500);
                    }, 1000);
                }
            },
            (err) => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            }
        );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * This unction is used to Open the contact dialog
     */
    ContactsDialog():void {
        const dialogRef = this.dialog.open(ContactDialogComponent, {
            disableClose: true,
            width: "100%",
            data: { action: "edit" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) $("#refreshContactTab").click();
        });
    }

   /**
    * This function is used to edit the contact data value
    * @param val -val data
    * @param row -row data value
    */
    editContact(val, row):void {
        localStorage.setItem("contactGuid", val);
        localStorage.setItem("contactData", JSON.stringify(row));
    }

    /**
     * This function is used to sort the date.
     */
    sortingDate() {
        this.Contactdata.sortingDataAccessor = (item, property) => {
            let FildeValue = this.dateColFilter;
            if (FildeValue.includes(property)) {
                if (
                    item[property] &&
                    (property == "CREATEDDATEANDTIME" ||
                        property == "MODIFIEDDATEANDTIME")
                ) {
                    let momentDate = moment(
                        moment(item[property], "DD/MM/YYYY hh:mm A")
                    ).format("YYYY-MM-DD HH:mm:ss");
                    let timestamp = Date.parse(momentDate);
                    return timestamp / 1000;
                } else if (
                    item[property] &&
                    (property != "CREATEDDATEANDTIME" ||
                        property != "MODIFIEDDATEANDTIME")
                ) {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(
                        tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                    );
                    let newDate = new Date(Sd);
                    return newDate;
                }
                return item[property];
            } else {
                if (property == "FAMILYNAME") {
                    return item["CONTACTNAMESORT"].toLowerCase() || '';;
                }
                return item[property].toLowerCase() || '';;
            }
        };
        // proper shorting for date
        this.Contactdata.sort = this.sort;
    }

    /**
     * This function is used to sort the data
     * @param sort -sort value
     */
    sortData(sort: Sort) {
        this.SortingbehaviorService.contactScreenSorting(sort);
        this.setDefaultWidth(this.displayedColumns, 0);
    }

    /**
     * This function is used to open the Matter dialog
     */
    openDialog():void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            data: this.ColumnsObj,
            type: "contacts",
            list: "",
        };
        //open pop-up
        const dialogRef = this.dialog.open(
            SortingDialogComponent,
            dialogConfig
        );
        //Save button click
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tempColobj = result.tempColobj;
                this.displayedColumns = result.columObj;
                this.ColumnsObj = result.columnameObj;
                this.dateColFilter = result.dateCol;
                localStorage.setItem('contactjustification',JSON.stringify(result?.columnameObj));
                localStorage.setItem(
                    "contact_list_columns",
                    JSON.stringify({
                        displayedColumns: result.columObj,
                        ColumnsObj: result.columnameObj,
                    })
                );
                if (!result.columObj) {
                    this.Contactdata = new MatTableDataSource([]);
                    this.Contactdata.paginator = this.paginator;
                    this.Contactdata.sort = this.sort;
                    this.isDisplay = true;
                } else {
                    let filterVals = JSON.parse(
                        localStorage.getItem("contact_Filter")
                    );
                    this.filterVals.active = filterVals.active;
                    this.filterVals.FirstLetter = filterVals.FirstLetter;
                    this.SortingbehaviorService.setContactFilterData(
                        this.filterVals
                    );
                    // this.LoadData(this.filterVals);]
                    this.addOptionalFilter(this.filterVals);
                }
            }
        });
    }

    /**
     * this unction is used to change the contact
     */
    ContactChange() {
        this.filterVals = JSON.parse(localStorage.getItem("contact_Filter"));
        setTimeout(() => {
            this.filterVals.active =
                this.f.active.value == "all" ? "" : this.f.active.value;
            this.SortingbehaviorService.setContactFilterData(this.filterVals);
            //this.LoadData(this.filterVals);
            this.addOptionalFilter(this.filterVals);
            localStorage.setItem(
                "contact_Filter",
                JSON.stringify(this.filterVals)
            );
        }, 500);
    }

    /**
     * This function is used to change the contact type
     */
    ContactTypeChange() {
        this.filterVals = JSON.parse(localStorage.getItem("contact_Filter"));
        let value = this.contacttypevalue;
        this.filterVals.ContactType = value == "all" ? "" : value;
        this.SortingbehaviorService.setContactFilterData(this.filterVals);
        this.addOptionalFilter(this.filterVals);
        localStorage.setItem("contact_Filter", JSON.stringify(this.filterVals));
    }

    /**
     * This unction is used to change the contact value
     */
    Contactvalue() {
        this.filterVals = JSON.parse(localStorage.getItem("contact_Filter"));
        let value = this.FirstLettervalue;
        this.filterVals.FirstLetter = value != "all" ? value : "";
        this.SortingbehaviorService.setContactFilterData(this.filterVals);
        // this.LoadData(this.filterVals);
        this.addOptionalFilter(this.filterVals);
        localStorage.setItem("contact_Filter", JSON.stringify(this.filterVals));
    }

    /**
     * This function is used to get the searching information
     */
    onSearch() {
        this.filterVals.SEARCH = this.f.search.value;
        this.SortingbehaviorService.setContactFilterData(this.filterVals);
        // this.LoadData(this.filterVals);
        this.addOptionalFilter(this.filterVals);
    }
    ngOnDestroy(): void {
        if (localStorage.getItem("contact_Filter")) {
            this.filterVals.SEARCH = "";
            this.SortingbehaviorService.setContactFilterData(this.filterVals);
        }
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to download the excell data
     * @param data -data which we need to download
     */
    downloadFile(data?) {
        const sortedData = this.Contactdata.sortData(
            this.Contactdata.filteredData,
            this.Contactdata.sort
        );
        this.TableColumnsService.exportDataIntoCsv(
            this.displayedColumns,
            sortedData,
            "Contact_data_",
            this.tempColobj
        );
        // this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, data, "Contact_data_", this.tempColobj);
    }

    /**
     * This function is used to Download the data 
     */
    DownloadAllData() {
        const requestdata = {
            ContactType: "",
            FirstLetter: "",
            GETALLDATA: true,
            SEARCH: "",
            active: "",
        };
        this.isGetallData = true;
        this.isCallDownloadFunction = true;
        // this.LoadData(requestdata)
        this.addOptionalFilter(requestdata);
    }

    /**
     * This function is used to go to back to the filter
     * @param id -filter id
     */
    backToFilter(id) {
        $("#" + id).removeClass("active");
        setTimeout(() => {
            $(".mat-menu-content").removeClass("active");
            $(".mat-menu-content").removeClass("mat-menu-content_height");
        }, 50);

        if (this.FILTERS) {
            this.FILTERSLIST = this.contactFilter.get(
                "FILTERSLIST"
            ) as FormArray;
            this.FILTERSLIST.reset();
        }
    }

    /**
     * This function is used to set the prevent default
     * @param event -event data value
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * This function is used to prevent Default or contact data
     */
    preventDefaultforContact(event):void {
        event.stopPropagation();
    }

    /**
     * This function is used to get the active current filter 
     * @param id -id data
     * @param data -data value
     */
    activeCurrentFilter(id, data?: any) {
        $("#" + id).addClass("active");
        setTimeout(() => {
            $(".mat-menu-content").addClass("active");
            $(".mat-menu-content").addClass("mat-menu-content_height");
        }, 200);

        //this.matterFilterForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
        this.selectedFilterIs = "";
        this.activeOptionalFilter = data;
        this.contactFilter.controls["MAINFILTER"].reset();
    }

    /**
     * This function is used to get the filter data
     */
    getFilter():void {
        let opFilterList = JSON.parse(
            localStorage.getItem("contact_list_columns")
        );
        const filterData = this.TableColumnsService.getDynamicFilter(
            opFilterList,
            this.optionalFilterList,
            "contact_list_columns"
        );
        this.defaultSetFilter();
        this.refreshFilterList();
        setTimeout(()=>{
            this.detectedChangeRef = Math.random();
        },500)
    }

    /**
     * this function is used to refresh the filter list
     */
    refreshFilterList() {
        //AdvanceFilter Refresh
        this.totalApplicableFilterCount = 0;
        const advDynamicFilter = JSON.parse(
            localStorage.getItem("Contact_Advance_Filter")
        );
        this.optionalFilterList.map((x) => {
            if (x.FILTERTYPE != "HIDDEN") {
                this.totalApplicableFilterCount += 1;
            }

            if (
                advDynamicFilter &&
                advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
                    -1
            ) {
                x.FILTERAPPLY = true;
            }
        });
    }

    /**
     * This unction is used to default set filter
     */
    defaultSetFilter() {
        if (JSON.parse(localStorage.getItem("Contact_config"))) {
            this.activeFilters = JSON.parse(
                localStorage.getItem("Contact_config")
            );
            //  this.activeFilters.active = this.filterVals.active;
            if (this.filterVals.active == 1) {
                this.activeFilters.activeDesc = "Active Contacts";
                this.activeFilters.active = this.filterVals.active;
            }
            if (this.filterVals.active == 0) {
                this.activeFilters.activeDesc = "Inactive Contacts";
                this.activeFilters.active = this.filterVals.active;
            }
            if (
                this.filterVals.active == "all" ||
                this.filterVals.active == ""
            ) {
                this.activeFilters.activeDesc = "All Contacts";
                this.activeFilters.active = this.filterVals.active;
            }
            if (this.filterVals.active == "All Contacts") {
                this.activeFilters.activeDesc = "All Contacts";
                this.activeFilters.active = "all";
            }

            if (
                this.filterVals.ContactType == "all" ||
                this.filterVals.ContactType == ""
            ) {
                this.activeFilters.contactType = "All Contacts";
            } else {
                this.activeFilters.contactType = this.filterVals.ContactType;
            }

            if (
                this.filterVals.FirstLetter == "all" ||
                this.filterVals.FirstLetter == ""
            ) {
                this.activeFilters.firstLetter = "All";
            } else {
                this.activeFilters.firstLetter = this.filterVals.FirstLetter;
            }

            this.activeFilters.Search = this.filterVals.SEARCH;
        } else {
            this.activeFilters = {};
            
            // this.activeFilters.active = this.f.active.value;
            // this.activeFilters.contactType = this.f.ContactType.value;
            // this.activeFilters.firstLetter = this.f.FirstLetter.value;
            // this.activeFilters.Search = this.f.search.value;
            if (this.filterVals.active == 1) {
                this.activeFilters.activeDesc = "Active Contacts";
                this.activeFilters.active = this.filterVals.active;
            }
            if (this.filterVals.active == 0) {
                this.activeFilters.activeDesc = "Inactive Contacts";
                this.activeFilters.active = this.filterVals.active;
            }
            if (this.filterVals.active == "") {
                this.activeFilters.activeDesc = "All Contacts";
                this.activeFilters.active = this.filterVals.active;
            }
            if (this.filterVals.active == "All Contacts") {
                this.activeFilters.activeDesc = "All Contacts";
                this.activeFilters.active = "all";
            }

            this.activeFilters.contactType = this.filterVals.ContactType == ''?"All Contacts":this.filterVals.ContactType;
            this.activeFilters.firstLetter = this.filterVals.FirstLetter == ''?"All":this.filterVals.FirstLetter;
            this.activeFilters.Search = this.filterVals.SEARCH;
        }
    }

    /**
     * This function is used to set the filter config  
     * @param activefilter -active filter data 
     * @param filterBy -filter data
     */
    Filterconfig(activefilter, filterBy) {
        if (filterBy == "Active") {
            this.activeFilters.active = activefilter.id;
            this.activeFilters.activeDesc = activefilter.data;
        }
        if (filterBy == "ContactType") {
            this.contacttypevalue = activefilter.id;
            this.activeFilters.contactType = activefilter.data;
        }
        if (filterBy == "FirstLetter") {
            this.FirstLettervalue = activefilter.id;
            this.activeFilters.firstLetter = activefilter.data;
        }
        if (filterBy == "search") {
            this.activeFilters.Search = this.f.search.value;
        }
        localStorage.setItem(
            "Contact_config",
            JSON.stringify(this.activeFilters)
        );
    }

    /**
     * This function is is used to set the default focus
     * @param fieldRefVar 
     */
    setFocus(fieldRefVar) {
        this.searchField.nativeElement.focus();
    }

    /**
     * This function is used to clear the filter
     * @param name -name of the filter
     * @param filterBy -filterBy data
     */
    clearFilter(name, filterBy) {
        delete this.activeFilters[name];
        this.activeFilters
            ? JSON.parse(localStorage.getItem("Contact_config"))
            : {};
        let resetFilter = JSON.parse(localStorage.getItem("contact_Filter"));
        this.clearFilters = true;
        if (filterBy == "Active") {
            this.contactFilter.controls["active"].setValue("all");
            this.activeFilters.activeDesc = "All Contacts";
            resetFilter.active = "";
        }
        if (filterBy == "ContactType") {
            this.contactFilter.controls["ContactType"].setValue("all");
            resetFilter.ContactType = "";
            this.activeFilters.contactType = "All Contacts";
        }
        if (filterBy == "FirstLetter") {
            this.contactFilter.controls["FirstLetter"].setValue("all");
            resetFilter.FirstLetter = "";
            this.activeFilters.firstLetter = "All";
        }
        if (filterBy == "search") {
            this.contactFilter.controls["search"].setValue("");
            resetFilter.SEARCH = "";
        }
        localStorage.setItem("contact_Filter", JSON.stringify(resetFilter));
        localStorage.setItem(
            "Contact_config",
            JSON.stringify(this.activeFilters)
        );
        this.addOptionalFilter(resetFilter);
    }

    /**
     * This function is used to addoptional filter data
     * @param prevFilter -prevfilter data value
     */
    addOptionalFilter(prevFilter?: any) {
        const Filter = prevFilter
            ? JSON.parse(JSON.stringify(prevFilter))
            : JSON.parse(localStorage.getItem("contact_Filter"));
        delete Filter.activeDesc;
        delete Filter.unbilledWorkDesc;
        delete Filter.FILTERINDEX;
        delete Filter.FILTERAPPLAY;

        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.contactFilter.value.FILTERS)
        );
        const finalPayload = ArrayResetOptional.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                return e;
            }
        });

        // Filter.FILTERS = finalPayload;

        Filter.AdvancedFilters = finalPayload;
        if (
            (this.FILTERS && this.FILTERS.controls.length == 0) ||
            finalPayload.length == 0
        ) {
            // delete Filter.FILTERS;
            delete Filter.AdvancedFilters;
        }
        this.reload(Filter);
    }
    /**
     * This function is used to reload the data value
     * @param filterVal -filter value data
     */
    reload(filterVal) {
        this.LoadData(filterVal);
    }

    /**
     * This function is used to add the optional filter data
     * @param FilterData -filter data value
     * @param isIndex -indec number
     * @param OpretionIs -0operation name
     * @param evt 0evt name
     * @param isDynamic -boolean value data
     * @returns -data value
     */
    applayOptionalfilter(
        FilterData,
        isIndex,
        OpretionIs?: any,
        evt?: any,
        isDynamic?
    ) {
        if (isDynamic == "isDynamic") {
            this.cdf.detectChanges();
        }
        let saveAdvancefilterData = JSON.parse(
            localStorage.getItem("Contact_Advance_Filter")
        );

        const field1Val =
            this.contactFilter.controls.FILTERS["controls"][isIndex].controls[
                "VALUE"
            ].value;
        const field2Val =
            this.contactFilter.controls.FILTERS["controls"][isIndex].controls[
                "VALUE2"
            ].value;
        const operationVal =
            this.contactFilter.controls.FILTERS["controls"][isIndex].controls[
                "OPERATION"
            ].value;
        const filterTypeVal =
            this.contactFilter.controls.FILTERS["controls"][isIndex].controls[
                "FILTERTYPE"
            ].value;

        if (
            !this.TableColumnsService.checkValidation(
                field1Val,
                field2Val,
                operationVal,
                filterTypeVal
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }

        // ends here ~ new code for validation

        //addData.selectedFilter = OpretionIs.triggerValue;
        let addData = JSON.parse(JSON.stringify(FilterData));
        addData.selectedFilter = OpretionIs.triggerValue;

        const applyFilterData = this.TableColumnsService.Optionalfilter(
            addData,
            isIndex,
            this.optionalDateFilterPayload,
            this.contactFilter
        );
        this.optionalDateFilterPayload = applyFilterData;
        if (saveAdvancefilterData) {
            if (!saveAdvancefilterData[isIndex]) {
                this.saveAdvanceFilter.push(FilterData);
                saveAdvancefilterData = saveAdvancefilterData.concat(
                    this.saveAdvanceFilter[isIndex]
                );
            }

            saveAdvancefilterData[isIndex].AdvancedFilter.VALUE = field1Val;
            saveAdvancefilterData[isIndex].AdvancedFilter.VALUE2 = field2Val;
            saveAdvancefilterData[isIndex].AdvancedFilter.OPERATION =
                operationVal;
            saveAdvancefilterData[isIndex].AdvancedFilter.FILTERTYPE =
                filterTypeVal;

            saveAdvancefilterData[isIndex].FilterHeadings =
                applyFilterData[isIndex];
            localStorage.setItem(
                "Contact_Advance_Filter",
                JSON.stringify(saveAdvancefilterData)
            );
        }
        this.doFilter(this.optionalDateFilterPayload);
    }

    /**
     * this function is used to do the filter 
     * @param mainFilter -main filter data value 
     */
    doFilter(mainFilter: any) {
        const payloadData = JSON.parse(JSON.stringify(mainFilter));
        const finalPayload = payloadData
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    delete e["value"].COLUMNID;
                    return e["value"];
                }
            });
        const doFilter = JSON.parse(localStorage.getItem("contact_Filter"));
        //const doFilter = JSON.parse(JSON.stringify(this.filterVals));
        // doFilter.FILTERS = finalPayload;
        doFilter.AdvancedFilters = finalPayload;

        delete doFilter.activeDesc;
        delete doFilter.unbilledWorkDesc;
        delete doFilter.FILTERINDEX;
        delete doFilter.FILTERAPPLAY;
        this.reload(doFilter);
    }

    /**
     * This function is used to reset optional filter data value
     * @param event -event data value
     * @param type -type data value
     * @param isindex -isindex data value
     */
    resetOptionalFilter(event, type, isindex) {
        let index = this.optionalFilters.findIndex(
            (e) => e.COLUMNID == event.COLUMNID
        );

        event.FILTERAPPLY = false; //for filter hide/show from optional filter list.
        // original code
        // this.optionalFilterList.map((x)=>{
        //   if(this.optionalFilters.findIndex((e)=> e.COLUMNID == x.COLUMNID) !== -1){
        //     x.FILTERAPPLY = false;
        //   };
        // });
        // ends here ~ original code
            
       

        // fixed code to show filter in filter list
        setTimeout(() => {
          this.optionalFilterList.map((x)=>{
            if(this.optionalFilters.findIndex((e)=> x.COLUMNID == event.COLUMNID) !== -1){
              x.FILTERAPPLY = false;
            };
          });

          this.optionalFilterList = this.optionalFilterList
        }, 100);
        // ends here ~ fixed code to show filter in filter list


        this.optionalFilters[isindex]["FILTERAPPLY"] = false;

        setTimeout(()=>{
            this.detectedChangeRef = Math.random()
        },500)

        this.saveAdvanceFilter.splice(index, 1);
        this.optionalFilters.splice(index, 1);
        this.optionalDateFilterPayload.splice(isindex, 1);
        this.FILTERS.controls.splice(isindex, 1);
        this.contactFilter.value.FILTERS.splice(isindex, 1);
        //Delete Filters
        const resetFilter = JSON.parse(localStorage.getItem("contact_Filter"));

        delete resetFilter.activeDesc;
        delete resetFilter.unbilledWorkDesc;
        delete resetFilter.FILTERINDEX;
        delete resetFilter.FILTERAPPLAY;

        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.contactFilter.value.FILTERS)
        );

        //Advance Filter.
        this.TableColumnsService.refrshAdvanceFilter(event, "Contact");
        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });

        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                if (e.FILTERTYPE == "DATE") {
                    let date1 =
                        e && e.VALUE !== ""
                            ? moment(e.VALUE).format("DD/MM/YYYY")
                            : "";
                    let date2 =
                        e && e.VALUE2 !== "" && e.OPERATION == "is between"
                            ? moment(e.VALUE2).format("DD/MM/YYYY")
                            : "";

                    if (e.OPERATION == "is in the last") {
                        e.VALUE = e.VALUE;
                        e.VALUE2 = e.VALUE2;
                    } else {
                        e.VALUE = date1;
                        e.VALUE2 = date2;
                    }
                }

                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }
                return e;
            }
        });

        const advanceFilterPayload = this.loadAdvanceFilterPayload();
        resetFilter.AdvancedFilters =
            finalPayload.length !== 0
                ? finalPayload
                : advanceFilterPayload
                ? advanceFilterPayload
                : [];

        if (resetFilter.AdvancedFilters.length == 0) {
            delete resetFilter.AdvancedFilters;
            localStorage.removeItem("Contact_Advance_Filter");
        }
        resetFilter.AdvancedFilters = finalPayload;
        if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
            // delete resetFilter.FILTERS;
            delete resetFilter.AdvancedFilters;
        }
        this.reload(resetFilter);
        // this.reload(finalBasicPayload);
    }

    /**
     * This function is used to date filter config
     * @param event -event data value
     */
    dateFilterconfig(event):void {
        this.optionalFilterAppply = false;
    }

    /**
     * This function is used to add dynamic filter
     */
    addDynamicFilter(event, type?) {
        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        

        event.FILTERAPPLY = true; //for filter hide/show from optional filter list.
        if (!type) {
            this.optionalFilters.push(event);
        }
        // OPTIONAL FILTER
        this.FILTERS = this.contactFilter.get("FILTERS") as FormArray;
        this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));

        setTimeout(()=>{
            this.detectedChangeRef = Math.random()
        },500)
    }

    /**
     * This function is used to create the OPFilter
     * @param type -type of the filter
     * @returns -data returns
     */
    createOPFilter(type): FormGroup {
        return this._formBuilder.group({
            OPERATION:
                type == "DATE"
                    ? "is in the last"
                    : type == "CURRENCY" || type == "NUMBER"
                    ? "is equal to"
                    : "contains",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: type,
        });
    }

    // optionalDateFilter(event, filterIs) {
    //   filterIs.selectedFilter = event.value;
    // }

    /**
     * This function is used to set the optional date filter
     * @param event -event data value
     * @param filterIs -filterIs value data
     * @param val -value data
     * @param index -index number data
     */
    optionalDateFilter(event, filterIs, val, index) {
        filterIs.selectedFilter = event.value;
        this.selectedFilterIs = event.value;
        if (index != -1) {
            this.contactFilter.controls.FILTERS["controls"][index].controls[
                "VALUE"
            ].reset();
            this.contactFilter.controls.FILTERS["controls"][index].controls[
                "VALUE2"
            ].reset();
        }

        this.addFilter["VALUE"] = "";
        this.addFilter["VALUE2"] = "";
    }

    /**
     * This function is used to reset the filter data  
     */
    resetFilter() {
        // reset FILTERAPPLY value
        this.optionalFilterList.forEach((filter, index) => {
            filter.FILTERAPPLY = false;
        });
        // ends here ~ reset FILTERAPPLY value

        let resetFilter = JSON.parse(
            localStorage.getItem("contact_Filter_After_Reset_Filter")
        );
        localStorage.removeItem("Contact_Advance_Filter");
        this.saveAdvanceFilter = [];
        this.contactFilter.patchValue({
            active:
                resetFilter.active == "" ||
                resetFilter.active == "All Contacts" ||
                resetFilter.active == "all"
                    ? "all"
                    : resetFilter.active,
            ContactType:
                resetFilter.ContactType == "" ? "all" : resetFilter.ContactType,
            FirstLetter:
                resetFilter.FirstLetter == "" ? "all" : resetFilter.FirstLetter,
            search: resetFilter.SEARCH ? resetFilter : "",
        });
        this.optionalFilters = [];
        this.optionalDateFilterPayload = [];
        if (this.FILTERS) {
            this.FILTERS.reset();
            // this.FILTERSLIST.reset();
        }

        setTimeout(() => {
            this.activeFilters = {};
            if (resetFilter.active == 1) {
                this.activeFilters.activeDesc = "Active Contacts";
                this.activeFilters.active = resetFilter.active;
            }
            if (resetFilter.active == 0) {
                this.activeFilters.activeDesc = "Inactive Contacts";
                this.activeFilters.active = resetFilter.active;
            }
            if (resetFilter.active == "all" || resetFilter.active == "") {
                this.activeFilters.activeDesc = "All Contacts";
                this.activeFilters.active = resetFilter.active;
            }
            if (resetFilter.active == "All Contacts") {
                this.activeFilters.activeDesc = "All Contacts";
                this.activeFilters.active = "all";
                resetFilter.active = "";
            }

            this.activeFilters.contactType = resetFilter.ContactType;
            this.activeFilters.firstLetter = resetFilter.FirstLetter;
            this.activeFilters.Search = resetFilter.SEARCH;
            localStorage.setItem("contact_Filter", JSON.stringify(resetFilter));
            this.LoadData(resetFilter);
        }, 500);

        setTimeout(()=>{
            this.detectedChangeRef = Math.random()
        },500)
    }

    activeFilter = FilterSearchOptions.activeFilter;

    unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter;

    dateFilter = FilterSearchOptions.dateFilter;

    currencyFilter = FilterSearchOptions.currencyFilter;

    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;

    @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;
    
    /**
     * This unction is used to addnew filter
     * @param event -event data value
     */
    addNewFilter(event) {
        setTimeout(() => {
            // this.showFilterOption = true;
            this.operationFilter.options.first.select();
        }, 200);
        if (this.FILTERSLIST) {
            this.FILTERSLIST.controls.splice(0, 1);
            //this.FILTERSLIST.reset();
        }

        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        this._filterList = [event];
        this.addFilter.FILTERTYPE = event.FILTERTYPE;

        // OPTIONAL FILTER
        this.FILTERSLIST = this.contactFilter.get("FILTERSLIST") as FormArray;
        this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

        this.addFilter.OPERATION =
            event.FILTERTYPE == "DATE"
                ? "is in the last"
                : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
                ? "is equal to"
                : "contains";
    }

    /**
     * This function is used to add filters dynamic
     * @param IsfilterData -isfilter data value
     * @param oprationalFilter -optional filtr data value
     * @param evt -event data value
     * @returns -data value
     */
    async addfilter(IsfilterData, oprationalFilter, evt) {
        // alert(" >> value changed >> ")

        if (
            !this.TableColumnsService.checkValidation(
                this.addFilter.VALUE,
                this.addFilter.VALUE2,
                this.addFilter.OPERATION,
                IsfilterData.FILTERTYPE
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
        await this.addDynamicFilter(IsfilterData);

        let sIndex = this.optionalFilters.length - 1;

        this.contactFilter.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
        this.contactFilter.value.FILTERS[sIndex].VALUE2 = this.addFilter.VALUE2;
        this.contactFilter.value.FILTERS[sIndex].OPERATION =
            this.addFilter.OPERATION;
        this.contactFilter.value.FILTERS[sIndex].FILTERTYPE =
            IsfilterData.FILTERTYPE;
        IsfilterData.AdvancedFilter = this.contactFilter.value.FILTERS[sIndex];
        let advFilters = JSON.parse(
            localStorage.getItem("Contact_Advance_Filter")
        );
        if (advFilters) {
            this.saveAdvanceFilter = advFilters;
        }

        this.saveAdvanceFilter.push(IsfilterData);
        localStorage.setItem(
            "Contact_Advance_Filter",
            JSON.stringify(this.saveAdvanceFilter)
        );

        if (this.FILTERS.length == 1) {
            this.contactFilter.patchValue({
                FILTERS: [
                    {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    },
                ],
            });
        } else {
            this.FILTERS.value.forEach((filterData, index) => {
                if (sIndex == index) {
                    filterData = {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    };
                }
            });

            this.contactFilter.patchValue({
                FILTERS: this.FILTERS.value,
            });
        }

        //After getting data appy filter.
        this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

        $(".example-containerdata").css(
            "height",
            $(window).height() -
                ($("#filter-form-menu-wrap").height() + 265) +
                "px"
        );
    }

    /**
     * This function is used to set the filter UI
     * @param filter -filter data value
     * @param index -index number
     */
    setFilterUi(filter, index) {
        this.selectedFilterIs =
            this.optionalDateFilterPayload[index]["value"]["OPERATION"];
    }

    /**
     * This function is used to search the data 
     * @param evt -searching value
     */
    onSearchNew(evt) {
        if (evt["key"] == "Enter") {
            this.onSearch();
        }
    }

    /**
     * this function is used to set the Deault Advanced filter
     */
    async setDefaultAdvanceFilter() {
        // Advance Filter If Already have.
        let advanceFiltes = JSON.parse(
            localStorage.getItem("Contact_Advance_Filter")
        );
    
        if (advanceFiltes) {
            this.optionalDateFilterPayload = [];
            this.optionalFilters = advanceFiltes;
            await advanceFiltes.forEach(async (filterData, index) => {
                await this.addDynamicFilter(
                    filterData.AdvancedFilter,
                    "default"
                );

                this.FILTERS.value[index].OPERATION = await filterData
                    .AdvancedFilter.OPERATION;
                this.FILTERS.value[index].FILTERTYPE = await filterData
                    .AdvancedFilter.FILTERTYPE;
                this.FILTERS.value[index].VALUE = await filterData
                    .AdvancedFilter.VALUE;
                this.FILTERS.value[index].VALUE2 = await filterData
                    .AdvancedFilter.VALUE2;
                await this.optionalDateFilterPayload.push(
                    filterData.FilterHeadings
                );

                await this.contactFilter.patchValue({
                    FILTERS: this.FILTERS.value,
                });
            });
        }
    }

    // onResizeColumnSetting(event , index){
    //     //event : selected column.
    //     //index : selected column index.
    //     //renderer : dont make this comman it's give issue import in your component.
    //     //displayedColumns : displayedColumns name's (Table Header names).
    //     //matTableRef : most importent please import in component wise don't make it comman.
    //     // last field is localstorage name which use to store columnObj
    
    //     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'contact_list_columns');
    //   };
    
      //For resize this fuction is most impotent don't make it comman it's give issue in future.
      @HostListener('window:resize', ['$event'])
      onResize(event) {
         this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
      }


      /**
 * This function is used to getNewExportData;
 */
     async getNewExportData(){
        let active;
        let direction
        let contactjustification=JSON.parse(localStorage.getItem('contactjustification'));
        if(this.sortingDefaultState == null){
          active=this.sortactive;
          direction=this.sortDirection
        }else{
            active=this.sortingDefaultState.active;
            direction=this.sortingDefaultState?.direction
        }

        let columnname=[];
        if( active !== undefined && direction !==undefined &&  active !== '' && direction !==''){
            columnname=contactjustification.filter(data=>data.COLUMNID == active);
        }else{
            columnname.push({COLUMNNAME:''});
            direction='';
        }
        
         this.behaviorService.loaderDATA$.next(true);
        let advanceFilters=(this.GloablePayload?.AdvancedFilters !=undefined) ? this.GloablePayload?.AdvancedFilters :''
        this.TableColumnsService.getNewExportData("contacts","","contact",this.GloablePayload?.FILTERS,advanceFilters,columnname[0]?.COLUMNNAME,direction) 
    }
}
