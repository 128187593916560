

<div class="main" *ngIf="bulkInvoiceWorkItems$ | async as bulkInvoiceWorkItems">
<div class="main" *ngIf="bulkInvoicegetEXandINC_GST$ | async as bulkInvoicegetEXandINC_GST">

<div *ngIf="SingleMatterData$ | async as SingleMatterData">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
    </div>   
<div fxLayout="row">
    <div fxFlex="90" fxLayoutAlign="start">
        <div class="display-flex align-center flex-wrap">
            <h2>
                <a class="pointer-cursor flex-shrink-0" (click)="openMatter()">
                    <p class="info hyper-link matter-detail-text-link mt-0 mb-0 underline"
                        [ngClass]="HyperlinkColor">{{(bulkInvoiceWorkItems?.invoiceBulkWorkItems)
                        ? bulkInvoiceWorkItems?.invoiceBulkWorkItems?.SHORTNAME
                        :'---'}}</p>
                </a>
            </h2>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h2>
                <p class="info mt-0 mb-0 infobox overFlow pointer-cursor flex-shrink-0">{{(bulkInvoiceWorkItems?.invoiceBulkWorkItems) ?
                    bulkInvoiceWorkItems?.invoiceBulkWorkItems.MATTER :'---'}}</p>
            </h2>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h2>
                <a class="pointer-cursor flex-shrink-0 overFlow-contact">
                    <p
                        class="info contact-name pointer-cursor matter-detail-text-link mt-0 mb-0 underline"
                        (click)="openContactName(bulkInvoiceWorkItems?.invoiceBulkWorkItems?.CONTACTGUID)"
                        [ngClass]="HyperlinkColor">{{(bulkInvoiceWorkItems?.invoiceBulkWorkItems) ? bulkInvoiceWorkItems?.invoiceBulkWorkItems?.CONTACTNAME:'---'}}</p>
                </a>
            </h2>
            
        </div>
    </div>
    <div class="close-icon" fxFlex="10" fxLayoutAlign="end center" >
        <mat-icon (click)="close('BulkInvoicingWorkItemPanel')">clear</mat-icon>
    </div>
</div>

<div fxLayout="row" class="justify-content-between">
<div class="" fxFlex="35">

<div fxFlex="37"  *ngIf="currentuserdata?.PRODUCTTYPE == 'Solicitor'">
    <div fxLayout="column">
        <div class="party-headdata">
        <h3><b>Owner</b></h3>
        </div>
        <div class="party-data">
            <h3>{{(bulkInvoiceWorkItems?.invoiceBulkWorkItems) ? bulkInvoiceWorkItems?.invoiceBulkWorkItems?.OWNERNAME:' '}}</h3>
        </div>
    </div>
</div>
<div fxFlex="35" style="margin-left: 30px;"  *ngIf="currentuserdata?.PRODUCTTYPE == 'Solicitor'">
    <div fxLayout="column">
        <div class="party-headdata">
        <h3><b>Primary Fee Earner</b></h3>
        </div>
        <div class="party-data">
            <h3>{{(bulkInvoiceWorkItems?.invoiceBulkWorkItems) ? bulkInvoiceWorkItems?.invoiceBulkWorkItems?.PRIMARYFEEEARNERNAME:' '}}</h3>
        </div>
    </div>
</div>

</div>
<div class="justify-content-end" fxFlex="65">
    <div fxFlex="12" class="header-bar">
        <div fxLayout="column">
            <div class="party-headdata">
            <h3><b>Previously Billed</b></h3>
            </div>
            <div class="party-data">
                <h3>{{bulkInvoiceWorkItems?.invoiceBulkWorkItems?.INVOICEDVALUEINCGST | toFixedAmount :true}}</h3>
            </div>
        </div>
    </div>
<div fxFlex="12" class="header-bar">
    <div fxLayout="column">
        <div class="party-headdata">
        <h3><b>Unbilled (Ex)</b></h3>
        </div>
        <div class="party-data">
            <h3>{{bulkInvoiceWorkItems?.invoiceBulkWorkItems?.UNBILLEDVALUEEXGST | toFixedAmount :true}}</h3>
        </div>
    </div>
</div>
<div fxflex="12" class="header-bar" >
    <div fxLayout="column">
        <div class="party-headdata">
        <h3><b>Unbilled (Inc)</b></h3>
        </div>
        <div class="party-data">
            <h3>{{bulkInvoiceWorkItems?.invoiceBulkWorkItems?.UNBILLEDVALUEINCGST | toFixedAmount :true}}</h3>
        </div>
    </div>
</div>
<div fxFlex="12" class="header-bar">
    <div fxLayout="column">
        <div class="party-headdata">
        <h3><b>Outstanding(Inc)</b></h3>
        </div>
        <div class="party-data">
            <h3>{{bulkInvoiceWorkItems?.invoiceBulkWorkItems?.OUTSTANDINGVALUEINCGST | toFixedAmount :true}}</h3>
        </div>
    </div>
</div>
</div>
</div>

<div fxlayout="row" class="justify-content-between gap-10 bulk-list">
    <form style="width: 23%">
        <mat-slide-toggle
  [(ngModel)]="isTaggingEnabled"
  (change)="ToggleForUpadte()"
  matTooltip="Turn on mass updates to enter tagging mode which gives you the ability to Delete, Move or Write Off WIP in bulk."
  matTooltipPosition="above"
  style="margin-top: 25px;"
>
  Mass Updates
</mat-slide-toggle>
    </form>
    <div fxFlex="50"  class="display-flex gap-10 py-12 ml-16 minw-46 bulk-sub-list">
        <!-- <div fxLayout="column">
            <div>
            <h3><b>Outstanding(Inc)</b></h3>
            </div>
            <div >
                <h3>{{bulkInvoiceWorkItems?.invoiceBulkWorkItems?.OUTSTANDINGVALUEINCGST | toFixedAmount :true}}</h3>
            </div>
        </div> -->
        <ng-container>

        <!-- <section
            [ngClass]="(appPermissions[1]?.Edit==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
            <button mat-button
                (click)="toolbarServiceService.matterpopup('edit')"
                [ngClass]="(appPermissions[1]?.Edit==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'"
                id="editMatter">
                <section
                    class="large-button-container">
                    <mat-icon class="main-icon">
                        <img
                            src="assets/icons/web_app/icon_matter_edit_d.ico">
                    </mat-icon>
                    <div class="main-text">
                        <span>Edit<br> Matter</span>
                    </div>
                </section>
            </button>
        </section> -->
        <!-- <ng-container *ngIf="!Checktaggingstatus"> -->
        <ng-container *ngIf="!Checktaggingstatus">
        <section>
            <button mat-button  class="large-button p-0" (click)="callingPreview(bulkInvoiceWorkItems,SingleMatterData)">

                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                    </mat-icon>
                    <div class="main-text">
                        <span>Preview<br>Invoice</span>
                    </div>
                </section>
            </button>
        </section>

        <section >
            <button mat-button  class="large-button p-0" (click)="SaveInvoice('Create Bulk Invoice',bulkInvoiceWorkItems,SingleMatterData)">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                    </mat-icon>
            <div class="main-text">
                    <span>Create<br>Invoice</span>
            </div>
                        </section>
                    </button>
                </section>
                <section >
                    <button mat-button class="large-button p-0" (click)="SaveInvoice('Create+Generate Bulk Invoice',bulkInvoiceWorkItems,SingleMatterData)">
                        <section class="large-button-container">
                            <mat-icon class="main-icon">
                                <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                            </mat-icon>
                            <div class="main-text">
                                <span>Create +<br>Gen. Invoice</span>
                            </div>
                        </section>
                    </button>
                </section>
                <section>
                    <button mat-button class="large-button p-0" (click)="SaveInvoice('Create+Email Bulk Invoice',bulkInvoiceWorkItems,SingleMatterData)">

                        <section class="large-button-container">
                            <mat-icon class="main-icon">
                                <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                            </mat-icon>
                            <div class="main-text">
                                <span>Create +<br>Email Invoice</span>
                            </div>

                        </section>
                    </button>
                </section>
                <section class="ribbon-section time-entries-wrap">
                    <mat-nav-list >
                        <a mat-list-item class="gap-3"
                            [ngClass]="((appPermissions && appPermissions[7]?.Access==0) || isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                            (click)="ReCalcTimeEntriClick()">
                            <mat-icon>
                                <img class="timer-icon"
                                    src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                            </mat-icon>
                            <span>Re Calc Time Entries</span>
                        </a>
                        
                        <a mat-list-item class="gap-3"
                            [ngClass]="((appPermissions && appPermissions[7]?.Access==0) || isWIPData == 'empty')?'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                           (click)="searchAndReplaceWorkItemOnClick()" >
                            <mat-icon>
                                <img class="timer-icon"
                                    src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                            </mat-icon>
                            <span>Find/Replace</span>
                        </a>
                    </mat-nav-list>
                </section>
</ng-container>

        <ng-container *ngIf="Checktaggingstatus">
            <section>
                <button mat-button  class="large-button p-0" (click)="deleteTimeEntry()" [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || isshowButtons == false || showdeleteentries == false) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
    
                    <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Entries</span>
                                                    </div>
                                                </section>
                </button>
            </section>
    
            <section >
                <button mat-button  class="large-button p-0" (click)="moveWIP()" [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty' || isshowButtons == false) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                    <section class="large-button-container">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                        </mat-icon>
                <div class="main-text">
                        <span>Move Entries</span>
                </div>
                            </section>
                        </button>
            </section>

                    <section *ngIf="DisEnTimeEntryToolbar == 'write_off'" >
                        <button [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || isshowButtons == false) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" mat-button class="large-button p-0" (click)="WriteOffTimeEntry('WIP')">
                            <section class="large-button-container">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                </mat-icon>
                                <div class="main-text">
                                    <span>Write Off Entries</span>
                                </div>
                            </section>
                        </button>
                    </section>

                    <section *ngIf="DisEnTimeEntryToolbar == 'undo'" >
                        <button [ngClass]="(appPermissions[7]?.Create==0 || isWIPData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" mat-button class="large-button p-0" (click)="UndoWriteOffTimeEntry('WIP')">
                            <section class="large-button-container">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                </mat-icon>
                                <div class="main-text">
                                    <span>Undo - Write Off Entries</span>
                                </div>
                            </section>
                        </button>
                    </section>
        </ng-container>
    </ng-container>
    </div>
    <div class="justify-content-end" fxFlex="50">
    <div fxFlex="16.6" class="sub-head">
        <div fxLayout="column">
            <div class="party-headdata">
            <h3><b>Total (Ex)</b></h3>
            </div>
            <div >
                <h3>{{(bulkInvoicegetEXandINC_GST?.invoiceBulkgetEXandINC_GST?.EXGSTTOTAL) | toFixedAmount :true}}</h3>
            </div>
        </div>
    </div>
    <div fxFlex="16.6" class="sub-head">
        <div fxLayout="column">
            <div class="party-headdata">
            <h3><b>GST</b></h3>
            </div>
            <div >
                <h3>{{(bulkInvoiceWorkItems?.invoiceBulkWorkItems?.UNBILLEDVALUEINCGST - bulkInvoiceWorkItems?.invoiceBulkWorkItems?.UNBILLEDVALUEEXGST) | toFixedAmount :true}}</h3>
            </div>
        </div>
    </div>
    <div fxFlex="16.6" class="sub-head">
        <div fxLayout="column">
            <div class="party-headdata">
            <h3><b>Total (Inc)</b></h3>
            </div>
            <div >
                <h3>{{(bulkInvoicegetEXandINC_GST?.invoiceBulkgetEXandINC_GST?.INCGSTTOTAL) | toFixedAmount :true}}</h3>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
</div>

<div *ngIf="bulkInvoiceWorkItems?.invoiceBulkWorkItems && bulkInvoiceWorkItems?.invoiceBulkWorkItems !=null">
<app-details class="invoice_add_dialoug_form" [MattersData]="bulkInvoiceWorkItems?.invoiceBulkWorkItems" ></app-details>
</div>
</div>
</div>
